define("adept-iq/pods/components/workspace-navbar-reconcile/component", ["exports", "adept-iq/pods/components/workspace-navbar-reconcile/stub", "moment", "adept-iq/config/mapped-permIds", "lodash"], function (_exports, _stub, _moment, _mappedPermIds, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['workspace-navbar'],
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    reconcileLambda: Ember.inject.service(),
    timeFormat: 'm-d-Y',
    submitBtnClicked: false,
    submitBtnEnabled: Ember.computed.empty('reconcile.reconcileDate'),
    searchOptionEnabled: Ember.computed.readOnly('submitBtnClicked'),
    providerOptionEnabled: Ember.computed.readOnly('submitBtnClicked'),
    routeOptionEnabled: Ember.computed.empty('selectedProvider'),
    disableAddRouteOption: Ember.computed.empty('selectedProvider'),
    routeOptions: Ember.computed('reconcile.reconcileRouteMap', 'selectedProvider', function () {
      const selectedProviderId = this.get('selectedProvider.id');
      const reconcileRouteMap = this.get('reconcile.reconcileRouteMap') || {};
      const reconcileRoutes = reconcileRouteMap[selectedProviderId] || [];
      return reconcileRoutes.map(route => {
        return {
          'id': route.id,
          'routeId': route.trackingid
        };
      });
    }),
    searchOptions: Ember.computed('selectedSearchMode', function () {
      const searchModes = _stub.default.searchOptions;
      return this.enableDisableOption(searchModes);
    }),

    enableDisableOption(options) {
      const optionArray = [];
      options.map(opt => {
        const option = {
          disabled: false,
          name: opt
        };

        switch (opt) {
          case 'Client':
            option.disabled = !this.isSearchByClientAllowed;
            break;

          case 'Driver':
            option.disabled = !this.isSearchByDriverAllowed;
            break;

          case 'Vehicle':
            option.disabled = !this.isSearchByVehicleAllowed;
            break;

          case 'Address':
            option.disabled = !this.isSearchByAddressAllowed;
            break;

          case 'Trip':
            option.disabled = !this.isSearchByTripAllowed;
            break;

          default:
            break;
        }

        optionArray.push(option);
      });
      return optionArray;
    },

    updateReconcileSearchRoute: Ember.observer('reconcile.selectedSearchRoute', function () {
      const selectedSearchRoute = this.get('reconcile.selectedSearchRoute');

      if (selectedSearchRoute) {
        this.set('selectedRoute', selectedSearchRoute);
      }
    }),
    updatePanel: Ember.observer('reconcile.selectedSearchedTrip', function () {
      const selectedRowData = Ember.get(this, 'reconcile.selectedSearchedTrip');
      Ember.set(this, 'selectedSearchMode', null);

      if (selectedRowData) {
        this.send('onChangeTripDate', 'selectedDateTimeRage.date', [(0, _moment.default)(selectedRowData.get('plannedStartTime')).format('MM-DD-YYYY')]);
        this.send('onProviderOptionSelect', {
          id: selectedRowData.get('providername')
        });
        this.send('onRouteOptionSelect', {
          routeId: selectedRowData.get('routeId')
        });
      }
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().subtract(1, 'day').format('MM-DD-YYYY'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('MM-DD-YYYY')
      });
      this.configurePermission();
      this.set('providerOptions', this.get('store').peekAll('provider'));
      this.resetTripFromMap();
    },

    configurePermission() {
      Ember.set(this, 'isAddNewRouteAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.addNewRouteRecon, null));
      Ember.set(this, 'isCloseDateAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.closeEditDateRecon, null));
      Ember.set(this, 'isReopenDateAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.reopenEditDateRecon, null));
      Ember.set(this, 'isSearchByClientAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByClientRecon, null));
      Ember.set(this, 'isSearchByDriverAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByDriverRecon, null));
      Ember.set(this, 'isSearchByVehicleAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByVehicleRecon, null));
      Ember.set(this, 'isSearchByAddressAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByAddrRecon, null));
      Ember.set(this, 'isSearchByTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByTripRecon, null));
    },

    didInsertElement() {
      this._super(...arguments);
    },

    callbackHandler(optionId) {
      if (optionId === 'reconcileAddNewRoute') {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetMapFlag', true);
      }
    },

    resetTripFromMap() {
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });
    },

    async fetchRouteRelatedInfo() {
      await this.fetchReconcileRVD();
      const routeResponseFromTripDate = await this.reconcileLambda.loadReconcileRoutesInMongoDb.perform();
      await this.reconcileLambda.loadReconcileTripsInMongoDb.perform();
      this.set('reconcile.routeResponseFromTripDate', routeResponseFromTripDate.data);
    },

    async fetchReconcileRVD() {
      const response = await this.reconcileLambda.getRouteVehicleDriversByTripDate.perform();
      const reconcileRouteMap = (0, _lodash.groupBy)(response.map(rvd => rvd.route), 'providername');
      this.set('reconcile.reconcileRouteMap', reconcileRouteMap);
      this.set('reconcile.reconcileRVDResponse', response);
    },

    actions: {
      onSearchOptionSelect(option) {
        const reconcile = Ember.get(this, 'reconcile');
        this.resetTripFromMap();
        Ember.set(this, 'selectedMode', option);
        Ember.set(this, 'selectedSearchMode', option.name.toLowerCase());
        this.reconcile.closePopups();
        reconcile.toggleProperty('showSearchPopUp');
        Ember.set(this, 'reconcile.reconcileRouteInfo', null);
        this.set('selectedRoute', null);
        Ember.set(this, 'selectedProvider', null);
        Ember.set(this, 'reconcile.reconcileTripDetails', null);
      },

      onProviderOptionSelect(option) {
        this.resetTripFromMap();
        this.setProperties({
          selectedProvider: option,
          'reconcile.reconcileProviderName': option.id,
          selectedSearchMode: null,
          selectedRoute: null,
          'reconcile.reconcileSelectedRouteId': null,
          'reconcile.reconcileTripDetails': null,
          'reconcile.reconcileRouteInfo': null,
          'reconcile.reconcileRouteOptions': this.get('routeOptions'),
          'reconcile.selectedProvider': option
        });
        this.reconcile.closePopups();
      },

      async onRouteOptionSelect(option) {
        this.resetTripFromMap();
        Ember.set(this, 'selectedRoute', option);
        this.set('reconcile.reconcileSelectedRouteId', option.routeId);
        this.set('reconcile.reconcileSelectedRoute', option.id);
        Ember.set(this, 'selectedSearchMode', null);
        this.reconcile.closePopups();
        Ember.set(this, 'reconcile.reconcileTripDetails', null);
        Ember.set(this, 'reconcile.reconcileRouteInfo', null);
        await this.get('reconcile').fetchReconcileRouteInfo(option.id);
      },

      onChangeTripDate(valuePath, value) {
        this.resetTripFromMap();
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
        this.setProperties({
          selectedSearchMode: null,
          selectedRoute: null,
          selectedProvider: null,
          selectedServiceMode: null,
          submitBtnClicked: false,
          'reconcile. reconcileRouteMap': null,
          'reconcile.reconcileDate': (0, _moment.default)(value[0]).format('MM-DD-YYYY'),
          'reconcile.reconcileTripDetails': null,
          'reconcile.reconcileRouteInfo': null,
          'reconcile.reconcileSelectedRouteId': null,
          'reconcile.reconcileProviderName': null,
          'reconcile.selectedProvider': null
        });
        this.reconcile.closePopups();
      },

      onSubmitButtonClick() {
        this.set('submitBtnClicked', true);
        this.fetchRouteRelatedInfo();
      },

      onAddRouteButtonClick() {
        const provider = Ember.get(this, 'selectedProvider') ? Ember.get(this, 'selectedProvider') : '';
        const routeId = Ember.get(this, 'selectedRoute') ? Ember.get(this, 'selectedRoute').routeId : '';

        if (provider) {
          this.get('workspace').pushState('reconcileAddNewRoute', {
            provider,
            routeId,
            action: this.callbackHandler.bind(this, 'reconcileAddNewRoute')
          });
        }
      },

      reopenDatesClick() {
        this.get('workspace').pushState('reconcileOpenDates', {});
      },

      closeDatesClick() {
        this.get('workspace').pushState('reconcileCloseDates', {});
      },

      closePopupHandler() {
        if (this.reconcile.closePopupHandler) {
          this.reconcile.closePopupHandler();
        }
      }

    }
  });

  _exports.default = _default;
});