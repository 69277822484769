define("adept-iq/pods/components/side-drawer/system-config/speed-region-groups/table/speed-region-groups/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'speed-region-group',
    title: 'Speed Region Sets',
    tooltipTitle: 'System Configuration',
    defaultSortId: 'id',
    defaultSortAsc: true,
    selectFirstRow: true,
    queryModelData: true,
    columns: [{
      id: 'name',
      index: 1,
      type: 'string',
      label: 'Name',
      valuePath: 'name',
      defaultWidth: 300,
      searchable: true
    }, {
      id: 'start',
      index: 2,
      type: 'date',
      label: 'Start',
      valuePath: 'start',
      defaultWidth: 300,
      format: 'MM/DD/YYYY hh:mm A',
      exportFormat: 'MM-DD-YYYY HH:mm',
      prefixExportDateFormat: ' ',
      searchable: true
    }, {
      id: 'end',
      index: 3,
      type: 'date',
      label: 'End',
      valuePath: 'end',
      defaultWidth: 300,
      format: 'MM/DD/YYYY hh:mm A',
      exportFormat: 'MM-DD-YYYY HH:mm',
      prefixExportDateFormat: ' ',
      prefixDateFormat: ' ',
      searchable: true
    }]
  };
  _exports.default = _default;
});