define("adept-iq/pods/components/side-drawer/scheduling-add-break/component", ["exports", "adept-iq/utils/unwrapProxy", "ember-changeset", "moment", "ember-concurrency", "adept-iq/models/break-type", "adept-iq/models/place-category-type", "adept-iq/mixins/async-schedule-operation", "adept-iq/pods/components/side-drawer/scheduling-add-break/config"], function (_exports, _unwrapProxy, _emberChangeset, _moment, _emberConcurrency, _breakType, _placeCategoryType, _asyncScheduleOperation, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const extraPlannedDurationOptions = {
    min: 1
  };

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['scheduling-add-break-side-drawer'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    geocode: Ember.inject.service(),
    coreEntityPopulator: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    route: Ember.computed.readOnly('stackFrame.options.route'),
    extraPlannedDurationOptions,
    displayName: Ember.computed.alias('stackFrame.options.displayName'),
    isEditMode: Ember.computed.alias('stackFrame.options.isEditMode'),
    breakTypes: null,
    fields: _config.fields,
    isFloatingBreakType: Ember.computed.equal('routeBreakChangeSet.breakType.name', _breakType.FLOATING_BREAK),
    isFixedBreakType: Ember.computed.equal('routeBreakChangeSet.breakType.name', _breakType.FIXED_BREAK),
    routeBreakChangeSet: Ember.computed('route', function () {
      const route = this.get('route');
      const isEditMode = this.get('isEditMode');

      if (isEditMode) {
        return this.setEditingProperties(route);
      }

      return this.setDefaultProperties(route);
    }),
    showClearButton: Ember.computed('routeBreakChangeSet.requestTime', function () {
      const requestTime = (0, _moment.default)(this.get('routeBreakChangeSet.requestTime'));
      return requestTime.isValid();
    }),
    calcEndTime: Ember.computed('routeBreakChangeSet.{requestTime,plannedDuration}', function () {
      const requestTime = this.get('routeBreakChangeSet.requestTime');
      const plannedDuration = parseInt(this.get('routeBreakChangeSet.plannedDuration'), 10) || 0;
      const calcEndTime = new Date(requestTime);

      if (Ember.isEmpty(requestTime)) {
        return null;
      }

      calcEndTime.setMinutes(requestTime.getMinutes() + plannedDuration);
      return calcEndTime;
    }),
    plannedDurationSeconds: Ember.computed('routeBreakChangeSet.plannedDuration', function () {
      const plannedDuration = this.get('routeBreakChangeSet.plannedDuration') ? this.get('routeBreakChangeSet.plannedDuration') : '0'; //convert plannedDuration into seconds.Payload request except  plannedDuration in seconds

      return Math.floor(plannedDuration * 60);
    }),
    projectedEndDate: Ember.computed('routeBreakChangeSet.{plannedDuration,requestTime}', function () {
      const plannedDuration = this.get('routeBreakChangeSet.plannedDuration') ? this.get('routeBreakChangeSet.plannedDuration') : '0';
      const requestTime = this.get('routeBreakChangeSet.requestTime');
      if (!requestTime) return;
      const requestTimeMoment = (0, _moment.default)(requestTime);
      const estimatedEndMoment = requestTimeMoment.clone();
      return estimatedEndMoment.add(parseInt(plannedDuration, 10), 'minutes').toDate();
    }),
    canSave: Ember.computed('routeBreakChangeSet.{plannedDuration,requestTime,notes,breakType,place.address}', function () {
      const isEditMode = this.get('isEditMode');
      if (!isEditMode) return true;
      const record = this.get('routeBreakChangeSet');
      const {
        isChangesHappened
      } = this.createChangeValueRecord(record);
      return isChangesHappened;
    }),

    orderedStopsWithGaragesAndBreaks(route) {
      const routeBreaks = route.get('routeBreaks').toArray();
      const orderedStops = route.get('tripStops').map(tripStop => {
        return {
          id: tripStop.get('id'),
          stopStartTime: (0, _moment.default)(tripStop.get('plannedEta')),
          type: 'stop',
          stopType: tripStop.get('stopType'),
          slack: tripStop.get('plannedSlackTime'),
          ordinal: tripStop.get('plannedRouteOrdinal')
        };
      });
      routeBreaks.forEach(routeBreak => {
        orderedStops.push({
          id: routeBreak.get('id'),
          stopStartTime: (0, _moment.default)(routeBreak.get('estimatedStart')),
          type: 'break',
          stopType: 'pick',
          slack: 0,
          ordinal: routeBreak.get('plannedRouteOrdinal')
        });
        orderedStops.push({
          id: routeBreak.get('id'),
          stopStartTime: (0, _moment.default)(routeBreak.get('estimatedEnd')),
          type: 'break',
          stopType: 'drop',
          slack: routeBreak.get('plannedSlackTime'),
          ordinal: routeBreak.get('plannedRouteOrdinal') + 1
        });
      });
      const stopOrdinals = orderedStops.map(stop => {
        return stop.ordinal;
      });
      const maxOrdinal = Math.max(...stopOrdinals); // Rotue start and end time

      orderedStops.push({
        stopStartTime: (0, _moment.default)(route.get('plannedStartTime')),
        type: 'garage',
        stopType: 'start',
        slack: route.get('plannedSlackTime'),
        ordinal: 0
      });
      orderedStops.push({
        stopStartTime: (0, _moment.default)(route.get('plannedEndTime')),
        type: 'garage',
        stopType: 'end',
        slack: 0,
        ordinal: maxOrdinal + 1
      });
      const sortedOrderedStops = orderedStops.sort((a, b) => {
        // sort by ordinal
        if (a.ordinal < b.ordinal) {
          return -1;
        }

        if (a.ordinal > b.ordinal) {
          return 1;
        }

        return 0;
      });
      sortedOrderedStops.map((stop, index) => {
        stop.ordinal = index;
      });
      return sortedOrderedStops;
    },

    findNewBreakRoutePosition(route) {
      const currentTime = (0, _moment.default)();
      const routePlannedStartTimeMoment = (0, _moment.default)(route.get('plannedStartTime'));
      const routePlannedStartTime = routePlannedStartTimeMoment.isAfter(currentTime) ? routePlannedStartTimeMoment : currentTime;
      const routeplannedEndTime = (0, _moment.default)(route.get('plannedEndTime'));
      const estimatedStartTime = (0, _moment.default)(this.get('routeBreakChangeSet.requestTime'));
      const estimatedEndTime = (0, _moment.default)(this.get('projectedEndDate'));
      const orderedStopsWithGaragesAndBreaks = this.get('orderedStopsWithGaragesAndBreaks')(route) || [];
      const lastIndex = orderedStopsWithGaragesAndBreaks.length - 1;

      if (Ember.isEmpty(orderedStopsWithGaragesAndBreaks)) {
        return 1;
      }

      let routeOrdinal = -1;
      let onBoardCount = 0;
      orderedStopsWithGaragesAndBreaks.forEach((currentStop, currentIndex) => {
        const isLastStop = currentIndex === lastIndex;
        const isFirstStop = currentIndex === 0;
        const stopStartTime = currentStop.stopStartTime.startOf('minute'); // if break end time is before the first stop

        if (isFirstStop) {
          if (estimatedEndTime.isBefore(stopStartTime) && estimatedStartTime.isAfter(routePlannedStartTime)) {
            routeOrdinal = 1;
          }
        } // continue if we have not found a spot


        if (routeOrdinal === -1) {
          if (isLastStop) {
            // if it is the last stop and requested time is after the current stop
            if (estimatedStartTime.isAfter(stopStartTime) && estimatedEndTime.isBefore(routeplannedEndTime)) {
              routeOrdinal = currentStop.routeOrdinal + 1;
            }
          } else {
            if (currentStop.stopType === 'pick') {
              onBoardCount++;
            }

            if (currentStop.stopType === 'drop') {
              onBoardCount--;
            }

            const slack = currentStop.slack;
            const stopEndTime = stopStartTime.clone().add(slack, 'minutes').startOf('second'); // if there are no passenger on board and the break range fits between
            // the current stop and the next

            if (onBoardCount === 0 && estimatedStartTime.isSameOrAfter(stopStartTime) && estimatedStartTime.isSameOrBefore(stopEndTime) && estimatedEndTime.isSameOrAfter(stopStartTime) && estimatedEndTime.isSameOrBefore(stopEndTime)) {
              routeOrdinal = currentStop.ordinal + 1;
            }
          }
        }
      });
      return routeOrdinal;
    },

    isValidateDriverBreak() {
      const errors = [];
      const breakTypeName = this.get('routeBreakChangeSet.breakType.name');
      const location = (0, _unwrapProxy.unwrapProxy)(this.get('routeBreakChangeSet.place.location'));
      const plannedDuration = this.get('routeBreakChangeSet.plannedDuration');
      const requestTime = (0, _moment.default)(this.get('routeBreakChangeSet.requestTime'));
      const routePlannedStartTime = (0, _moment.default)(this.get('routeBreakChangeSet.route.plannedStartTime'));
      const routeActualEndTime = (0, _moment.default)(this.get('routeBreakChangeSet.route.plannedEndTime'));
      const route = (0, _unwrapProxy.unwrapProxy)(this.get('routeBreakChangeSet.route'));
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
      const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

      const routeBreakChangeSet = this.get('routeBreakChangeSet');
      const isEditMode = this.get('isEditMode');
      const breakMessage = isEditMode ? 'edit break' : 'add break';

      if (!plannedDuration) {
        errors.push('Duration is Required');
      }

      if (!requestTime.isValid()) {
        errors.push('Start Time is required');
      }

      if (!breakTypeName) {
        errors.push('Break type is required');
      }

      let changeSetRequestTime = requestTime;
      let changeSetPlannedDuration = plannedDuration;
      let changeSetBreakType = routeBreakChangeSet.get('breakType');

      if (isEditMode) {
        ({
          changeSetRequestTime,
          changeSetPlannedDuration,
          changeSetBreakType
        } = this.createChangeValueRecord(routeBreakChangeSet));
      }

      const routeOrdinal = !changeSetRequestTime ? 0 : this.get('findNewBreakRoutePosition').bind(this)(route);

      if (routeOrdinal === -1) {
        errors.push(`Cannot ${breakMessage}. Ensure no trip overlap and sufficient slack time.`);
      }

      if (breakTypeName && breakTypeName === _breakType.FIXED_BREAK && (!location || Ember.isEmpty(location.get('lat'))) && changeSetBreakType) {
        errors.push('Address for fixed break is required');
      }

      if (requestTime.isValid() && requestTime.isBefore(routePlannedStartTime) && changeSetRequestTime) {
        errors.push('Break start time must be after the route\'s planned start time.');
      }

      if (requestTime.isAfter(routeActualEndTime) && changeSetRequestTime) {
        errors.push('Break end time must be before the route\'s actual end time.');
      }

      if (plannedDuration > routeMaxExtension && changeSetPlannedDuration) {
        errors.push(`Duration exceeds the route end time beyond max route extension (${routeMaxExtension})`);
      }

      this.set('errors', errors);
      return !errors.length;
    },

    didInsertElement() {
      const breakTypes = this.get('store').peekAll('break-type');
      this.set('breakTypes', breakTypes); // side panel width for add-break

      this.setSideDrawerWidth('600px');
      this.loadCurrentState();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');
      const routeBreakChangeSet = this.get('routeBreakChangeSet');

      if (currentState) {
        routeBreakChangeSet.set('breakType', currentState.breakType);
        routeBreakChangeSet.set('place', currentState.place);
        routeBreakChangeSet.set('place.address', currentState.address);
        routeBreakChangeSet.set('place.location', currentState.location);
        routeBreakChangeSet.set('requestTime', currentState.requestTime);
        routeBreakChangeSet.set('plannedDuration', currentState.plannedDuration);
        routeBreakChangeSet.set('notes', currentState.notes);
        routeBreakChangeSet.execute();
      }
    },

    //set default requested time is 5 minutes greater than  plannedStartTime
    getRequestedTime(plannedStartTime) {
      const year = plannedStartTime.getFullYear();
      const month = plannedStartTime.getMonth();
      const day = plannedStartTime.getDate();
      const hours = plannedStartTime.getHours();
      const minutes = plannedStartTime.getMinutes() + 5;
      const seconds = plannedStartTime.getSeconds();
      return new Date(year, month, day, hours, minutes, seconds);
    },

    setDefaultProperties(record) {
      const trips = (0, _unwrapProxy.unwrapProxy)(record.get('trips'));
      const routeBreaks = (0, _unwrapProxy.unwrapProxy)(record.get('routeBreaks'));
      const plannedDuration = 15;
      const requestTime = this.getRequestedTime(record.get('plannedStartTime'));
      const floatingBreakType = this.get('store').peekAll('break-type').findBy('id', _breakType.FLOATING_BREAK);
      const tripStops = trips.reduce((arr, trip) => {
        const stops = trip.get('stops');
        stops.forEach(stop => arr.push(stop));
        return arr;
      }, []);
      let lastStop = tripStops.sortBy('plannedRouteOrdinal').get('lastObject'); // if no-trip-stop is present find a last route-break by plannedRouteOrdinal

      if (!lastStop) {
        lastStop = routeBreaks.sortBy('plannedRouteOrdinal').get('lastObject');
      }

      const driverBreak = Ember.Object.create({
        estimatedStart: new Date(),
        estimatedEnd: new Date(),
        requestTime,
        route: record,
        schedule: record.get('schedule'),
        notes: '',
        plannedDuration: plannedDuration,
        promisedStart: new Date(),
        place: null,
        breakType: floatingBreakType,
        plannedRouteOrdinal: lastStop ? lastStop.get('plannedRouteOrdinal') + 1 : 1,
        state: 'planned',
        status: 'scheduled'
      });
      const placeModel = this.getDefaultPlaceModel();
      driverBreak.set('place', placeModel);
      this.set('pendingRecord', driverBreak);
      const pendingRecord = this.get('pendingRecord');
      return new _emberChangeset.default(pendingRecord);
    },

    setEditingProperties(record) {
      const placeModel = record.get('place.id') ? (0, _unwrapProxy.unwrapProxy)(record.get('place')) : this.getDefaultPlaceModel();
      record.set('place', placeModel);
      return new _emberChangeset.default(record);
    },

    routeBreakAsync: (0, _emberConcurrency.task)(function* (fixedRouteBreakInfo) {
      const isEditMode = this.get('isEditMode');
      const routeBreakChangeSet = this.get('routeBreakChangeSet');
      const route = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('route'));
      const routeBreakId = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('id'));
      const schedule = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('route.schedule'));
      const scheduleId = parseInt(schedule.get('id'), 10);
      const destinationRouteId = parseInt(route.get('id'), 10);
      let requestTime = (0, _moment.default)(routeBreakChangeSet.get('requestTime'));
      let plannedDuration = routeBreakChangeSet.get('plannedDurationSeconds');
      let notes = routeBreakChangeSet.get('notes');

      if (isEditMode) {
        ({
          changeSetRequestTime: requestTime,
          changeSetPlannedDuration: plannedDuration,
          changeSetNotes: notes
        } = this.createChangeValueRecord(routeBreakChangeSet));
      }

      let result;
      let operation = 'addBreak';

      try {
        const operationData = {
          scheduleId,
          destinationRouteId,
          break: {
            requestTime: requestTime,
            plannedDuration,
            notes
          }
        };

        if (isEditMode) {
          delete operationData.destinationRouteId;
          operationData.break.id = routeBreakId;
          operation = 'editBreak';
        } // NYAAR-19922: we need to send value of breakStop if the breakType is fixed (for edit payload), orElse the breakType is stroed as floating


        if (this.get('isFixedBreakType')) {
          operationData.break.breakStop = {
            'placeId': fixedRouteBreakInfo.placeId,
            xyCoord: {
              x: fixedRouteBreakInfo.lat,
              y: fixedRouteBreakInfo.lng
            },
            geoNode: fixedRouteBreakInfo.geoNode
          };
        }

        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, operation, options);

        if (!result) {
          throw new Error('Unable to insert break.');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    getDefaultPlaceModel() {
      const store = this.get('store');
      const locationModel = store.createRecord('location');
      const addressModel = store.createRecord('address', {
        locations: [locationModel]
      });
      const placeModel = store.createRecord('place', {
        address: addressModel,
        location: locationModel
      });
      return placeModel;
    },

    validateBreakTime() {
      const tooltip = this.get('tooltip');
      const workspace = this.get('workspace');
      const routeBreakChangeSet = this.get('routeBreakChangeSet');
      const route = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('route'));
      const trips = route.get('trips').map(t => t).sort((a, b) => a.get('drop.plannedEta') - b.get('drop.plannedEta'));
      const breakStartTime = (0, _moment.default)(routeBreakChangeSet.get('requestTime'));
      const isFloatingBreakType = this.get('isFloatingBreakType');
      const isBreakInMiddleOfTrip = workspace.get('isBreakInMiddleOfTrip').bind(workspace);
      const isValidBreakTime = workspace.get('isValidBreakTime').bind(workspace);
      const breakEndTime = (0, _moment.default)(this.get('projectedEndDate'));
      const isEditMode = this.get('isEditMode');
      const breakMessage = isEditMode ? 'Editing the break' : 'Adding the break';
      let changeSetRequestTime = breakStartTime;
      let changeSetPlannedDuration = routeBreakChangeSet.get('plannedDurationSeconds');

      if (isEditMode) {
        ({
          changeSetRequestTime,
          changeSetPlannedDuration
        } = this.createChangeValueRecord(routeBreakChangeSet));
      }

      const breakMiddleInfo = {
        trips: trips,
        breakTime: breakStartTime,
        pickKey: 'pick',
        dropKey: 'drop',
        plannedEta: 'plannedEta'
      };
      this.set('invalidRequestTime', false);

      if (changeSetRequestTime) {
        if (isBreakInMiddleOfTrip(breakMiddleInfo)) {
          tooltip.pushConfirmation({
            tip: `Unable to save. ${breakMessage} will place the break in the middle of a trip.`,
            title: isEditMode ? 'Edit Driver Break' : 'Add Driver Break',
            primaryActionText: 'OK',
            hasOverlay: true,
            primaryAction: () => {
              tooltip.reset();
            }
          });
          this.set('invalidRequestTime', true);
          return false;
        }
      }

      if (!changeSetPlannedDuration) return true;
      const breakTimeInfo = {
        trips: trips,
        breakEndTime,
        pickKey: 'pick',
        dropKey: 'drop',
        plannedEta: 'plannedEta',
        nextTravelTime: 'plannedTravelTimeToNext'
      };

      if (isFloatingBreakType && !isValidBreakTime(breakTimeInfo)) {
        tooltip.pushConfirmation({
          tip: `Unable to save. ${breakMessage} results in not enough time to travel from break to the next stop.`,
          title: isEditMode ? 'Edit Driver Break' : 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      breakMiddleInfo.breakTime = breakEndTime;

      if (isBreakInMiddleOfTrip(breakMiddleInfo)) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. The break\'s end time will extend past the ETA of the stop after the break',
          title: isEditMode ? 'Edit Driver Break' : 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      return true;
    },

    async validateBreakPlace() {
      const workspace = this.get('workspace');
      const tooltip = this.get('tooltip');
      const routeBreakChangeSet = this.get('routeBreakChangeSet');
      const route = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('route'));
      const trips = route.get('trips').map(t => t).sort((a, b) => a.get('drop.plannedEta') - b.get('drop.plannedEta'));
      const breakEndTime = (0, _moment.default)(this.get('projectedEndDate'));
      const validaBreakPlace = workspace.get('validaBreakPlace').bind(workspace);
      const breakPlace = routeBreakChangeSet.get('place');
      const isEditMode = this.get('isEditMode');
      const breakMessage = isEditMode ? 'Editing the break' : 'Adding the break';

      if (isEditMode) {
        const {
          changeSetFreeFormAddress
        } = this.createChangeValueRecord(routeBreakChangeSet);
        if (!changeSetFreeFormAddress) return true;
      }

      const breakTimeInfo = {
        trips: trips,
        breakEndTime,
        pickKey: 'pick',
        dropKey: 'drop',
        plannedEta: 'plannedEta',
        breakPlace
      };
      const isValidBreakPlace = await validaBreakPlace(breakTimeInfo);
      this.set('invalidRequestTime', false);

      if (!isValidBreakPlace) {
        tooltip.pushConfirmation({
          tip: `Unable to save. ${breakMessage} results in not enough time to travel from break to the next stop.`,
          title: isEditMode ? 'Edit Driver Break' : 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      return true;
    },

    /**
     * NYAAR-19922: creating the object of values which are changed during editing
     * @param {*Object} record
     * @returns {Object}
     */
    createChangeValueRecord(record) {
      const changedRouteBreakValue = {};
      const changedValuesInRecord = (0, _unwrapProxy.unwrapProxy)(record.changes);
      changedValuesInRecord.forEach(routeBreak => {
        changedRouteBreakValue[routeBreak.key] = routeBreak.value;
      });
      const changeSetRequestTime = changedRouteBreakValue.requestTime ? (0, _moment.default)(changedRouteBreakValue.requestTime).format() : changedRouteBreakValue.requestTime;
      const changeSetPlannedDuration = changedRouteBreakValue.plannedDurationSeconds;
      const changeSetNotes = changedRouteBreakValue.notes;
      const changeSetBreakType = changedRouteBreakValue.breakType;
      const changeSetFreeFormAddress = changedRouteBreakValue['place.address.freeformAddress'];
      const isChangesHappened = !!changedValuesInRecord.length;
      return {
        changeSetRequestTime,
        changeSetPlannedDuration,
        changeSetNotes,
        changeSetBreakType,
        changeSetFreeFormAddress,
        isChangesHappened
      };
    },

    //for resetting the break address marker
    resetMarker() {
      const activePickMarker = this.get('geocode.activePickMarker');

      if (activePickMarker) {
        activePickMarker.remove();
        this.set('geocode.activePickMarker', null);
      }
    },

    actions: {
      onBreakTypeChange(val) {
        Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
        const record = this.get('routeBreakChangeSet');
        record.set('breakType', val);
        const routeBreakType = val.name;

        if (routeBreakType === _breakType.FLOATING_BREAK) {
          Ember.$('.leaflet-marker-pane img').remove();
          const placeModel = this.getDefaultPlaceModel();
          record.set('place', placeModel);
          record.execute();
        }
      },

      onCellValueChange(record, valuePath, value, options) {
        record.set(`${valuePath}.${options.valuePath}`, value);
        record.execute();
      },

      onChangeTime(record, valuePath, value) {
        const momentTime = (0, _moment.default)(value);
        const dateValue = momentTime.isValid() ? momentTime.toDate() : null;
        record.set(valuePath, dateValue);
      },

      onRefreshButtonClick() {
        const routeBreakChangeSet = this.get('routeBreakChangeSet');
        const placeModel = this.getDefaultPlaceModel();
        const floatingBreakType = this.get('store').peekAll('break-type').findBy('id', _breakType.FLOATING_BREAK);
        const requestTime = this.getRequestedTime(routeBreakChangeSet.get('route.plannedStartTime'));
        routeBreakChangeSet.set('breakType', floatingBreakType);
        routeBreakChangeSet.set('place', placeModel);
        routeBreakChangeSet.set('requestTime', requestTime);
        routeBreakChangeSet.set('plannedDuration', 15);
        routeBreakChangeSet.set('notes', null);
        this.isValidateDriverBreak();
        routeBreakChangeSet.execute();
        this.resetMarker();
      },

      async onSaveClick() {
        const coreEntityPopulator = this.get('coreEntityPopulator');
        const store = this.get('store');
        const record = this.get('routeBreakChangeSet');
        const requestTime = record.get('requestTime');
        const plannedDurationSeconds = this.get('plannedDurationSeconds');
        const estimatedEnd = this.get('projectedEndDate');
        const notifications = this.get('notifications');
        const tooltip = this.get('tooltip');
        const routeBreakAsync = this.get('routeBreakAsync');
        const isFixedBreakType = this.get('isFixedBreakType');
        const onCloseClick = this.get('onCloseClick');
        const refreshTableContent = this.get('activeContext.topActiveContext').get('refreshTableContent');
        const fixedRouteBreakInfo = {};
        const isEditMode = this.get('isEditMode');
        const routeId = this.get('route.id');
        let message = '';
        if (!this.isValidateDriverBreak() || !this.validateBreakTime()) return;
        const isValidBreakPlace = this.get('isFixedBreakType') ? await this.validateBreakPlace() : true;
        if (isFixedBreakType && !isValidBreakPlace) return;
        record.set('plannedDurationSeconds', plannedDurationSeconds);
        record.set('estimatedStart', requestTime);
        record.set('estimatedEnd', estimatedEnd);
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        const placeCategoryType = store.peekRecord('place-category-type', _placeCategoryType.PASSENGER_PLACE_CATEGORY_TYPE);
        place.set('placeCategoryType', placeCategoryType);

        if (isFixedBreakType) {
          try {
            await location.save();
            await address.save();
            await place.save();
          } catch (e) {
            console.error(e); //eslint-disable-line
          }

          fixedRouteBreakInfo.placeId = parseInt(place.id, 10);
          fixedRouteBreakInfo.lat = location.get('lat');
          fixedRouteBreakInfo.lng = location.get('lng');
          fixedRouteBreakInfo.geoNode = parseInt(location.get('geoNode'), 10);
        }

        return tooltip.pushConfirmation({
          tip: 'Would you like to commit your changes?',
          title: isEditMode ? 'Edit Break' : 'Add Break',
          primaryActionText: 'Commit',
          primaryAction: () => {
            return routeBreakAsync.perform(fixedRouteBreakInfo).then(() => {
              message = `Break successfully added to route ${record.get('route.name')}`;
              if (isEditMode) message = 'SUCCESSFULLY EDITED DRIVER BREAK';
              notifications.success(message);
              onCloseClick();
              Ember.run.later(this, function () {
                coreEntityPopulator.populateRtsRoute(routeId);
                Ember.run.later(this, function () {
                  this.get('workspaceContext').manualReloadContext();
                }, 3000);
              }, 3000);
              refreshTableContent.perform(['route', 'route-break']);
            }).catch(() => {
              message = `Break failed to be added to route ${record.get('route.name')}`;
              if (isEditMode) message = 'FAILED TO EDIT DRIVER BREAK';
              notifications.warning(message);
            }).finally(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
      },

      onDurationChange(path, value) {
        this.set(path, value);
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const record = this.get('routeBreakChangeSet');
        const breakType = record.get('breakType');
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(record.get('place.address'));
        const location = (0, _unwrapProxy.unwrapProxy)(record.get('place.location'));
        const requestTime = record.get('requestTime');
        const plannedDuration = record.get('plannedDuration');
        const notes = record.get('notes'); // save current manual settings

        currentState = {
          breakType,
          place,
          address,
          location,
          requestTime,
          plannedDuration,
          notes
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});