define("adept-iq/serializers/metric", ["exports", "adept-iq/serializers/-ss-schedulingService", "lodash"], function (_exports, _ssSchedulingService, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.strategyName)) {
        resourceHash.relationships.strategyName.data.type = 'engine-strategy';
        resourceHash.relationships.strategy = (0, _lodash.cloneDeep)(resourceHash.relationships.strategyName);
        delete resourceHash.relationships.strategyName;
      }

      return this._super(modelClass, resourceHash);
    }

  });

  _exports.default = _default;
});