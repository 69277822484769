define("adept-iq/pods/components/rider-management-widget/rider-notification/component", ["exports", "adept-iq/utils/validator", "ember-concurrency"], function (_exports, _validator, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notifications-main'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    rider: Ember.inject.service(),
    toggleValue: false,
    isOpen: false,
    isOpened: Ember.computed.oneWay('isOpen'),
    title: null,
    disableToggleSwitch: false,
    phoneNumberOptions: Ember.computed('riderPhoneTypes', 'riderPhoneNumbers.[]', async function () {
      const riderPhoneTypes = this.get('riderPhoneTypes.values') || [];
      const filterPhoneTypes = riderPhoneTypes.filter(phoneType => phoneType);
      return filterPhoneTypes.map(phoneType => phoneType.value.slice(0, 14).replace(/[\\(\\)']+/g, '').replace(' ', '-'));
    }),
    phoneNotification: Ember.computed('riderChangeSet.phoneNotification', function () {
      const phoneNotification = this.get('riderChangeSet.phoneNotification');
      const hyphenatedNumber = [phoneNotification.slice(0, 3), '-', phoneNotification.slice(3, 6), '-', phoneNotification.slice(6)].join('');
      return hyphenatedNumber;
    }),
    isPhoneNotification: Ember.computed('riderChangeSet.phoneNotificationEnabled', function () {
      return this.get('riderChangeSet.phoneNotificationEnabled');
    }),
    smsNotification: Ember.computed('riderChangeSet.smsNotification', function () {
      const smsNotification = this.get('riderChangeSet.smsNotification');
      const hyphenatedNumber = [smsNotification.slice(0, 3), '-', smsNotification.slice(3, 6), '-', smsNotification.slice(6)].join('');
      return hyphenatedNumber;
    }),
    isSmsNotification: Ember.computed('riderChangeSet.smsNotificationEnabled', function () {
      return this.get('riderChangeSet.smsNotificationEnabled');
    }),
    emailNotification: Ember.computed('riderChangeSet.emailNotification', function () {
      return this.get('riderChangeSet.emailNotification');
    }),
    isEmailNotification: Ember.computed('riderChangeSet.emailNotificationEnabled', function () {
      return this.get('riderChangeSet.emailNotificationEnabled');
    }),

    async init() {
      this._super(...arguments);

      const riderRecord = this.get('riderChangeSet');
      const riderPhoneTypes = riderRecord.get('formattedPhoneNumbers');
      this.onEditRiderNotificationRecord = this.onEditRiderNotificationRecord.bind(this);
      this.onUndoRiderNotificationRecord = this.onUndoRiderNotificationRecord.bind(this);
      const onEditRiderNotificationRecord = this.get('onEditRiderNotificationRecord');
      const onUndoRiderNotificationRecord = this.get('onUndoRiderNotificationRecord');
      const onSaveRiderNotificationRecord = this.get('onSaveRiderNotificationRecord');
      this.set('toggleValue', true);

      if (riderRecord.get('status') === 'Archived') {
        this.set('disableToggleSwitch', true);
        this.set('toggleValue', false);
      }

      const riderMailPreferenceAddresses = this.setupMailPreferenceAddresses();
      this.setProperties({
        riderPhoneTypes,
        riderMailPreferenceAddresses
      });
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRiderNotificationRecord,
          newAction: null,
          deleteAction: null,
          editAction: onEditRiderNotificationRecord,
          undoAction: onUndoRiderNotificationRecord,
          isNewBtnDisabled: true,
          isEditBtnDisabled: false,
          isDeleteBtnDisabled: true,
          isEditing: false
        });
      });
    },

    onSaveRiderNotificationRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const riderChangeSet = this.get('riderChangeSet');
      const emailNotificationEnabled = riderChangeSet.get('emailNotificationEnabled');
      const emailNotification = riderChangeSet.get('emailNotification').trim();
      const emailValid = !emailNotificationEnabled || (0, _validator.isEmailValid)(emailNotification);
      const phoneNotificationEnabled = riderChangeSet.get('phoneNotificationEnabled');
      const phoneNotification = riderChangeSet.get('phoneNotification').trim();
      const smsNotificationEnabled = riderChangeSet.get('smsNotificationEnabled');
      const smsNotification = riderChangeSet.get('smsNotification').trim();
      const errors = [];

      if (phoneNotificationEnabled && Ember.isBlank(phoneNotification)) {
        errors.push({
          validation: 'Phone number is required.'
        });
      }

      if (emailNotificationEnabled && Ember.isBlank(emailNotification)) {
        errors.push({
          validation: 'Email is required.'
        });
      }

      if (!emailValid) {
        errors.push({
          validation: `"${emailNotification}" is not a valid email.`
        });
      }

      if (smsNotificationEnabled && Ember.isBlank(smsNotification)) {
        errors.push({
          validation: 'SMS number is required.'
        });
      }

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
      } else {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              await riderChangeSet.save();
              const riderMailPreferenceAddresses = this.get('riderMailPreferenceAddresses');
              riderMailPreferenceAddresses.map(async preferenceAddress => {
                const model = preferenceAddress.model;

                if (model.get('hasDirtyAttributes')) {
                  await model.save();
                }
              });
              notifications.success('Rider notification successfully edited'); // activity log for editing rider notification screen

              const riderId = riderChangeSet.get('id');
              const riderExternalId = riderChangeSet.get('riderId');
              const actions = [{
                activity: 'Edit Rider Record',
                loggingType: 'user',
                details: `Edited information for rider ${riderExternalId}.`
              }];
              this.get('rider').createEditRiderActivityLog(riderId, actions);
              this.set('editAPI.isEditBtnDisabled', false);
              this.set('editAPI.isEditing', false);
              tooltip.reset();
            } catch (e) {
              notifications.warning('Rider notification failed to save');
              tooltip.reset();
              console.warn(e); // eslint-disable-line
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
        yield Promise.resolve();
      }
    }),

    setupMailPreferenceAddresses() {
      const riderMailPreferenceAddresses = [];
      const riderChangeSet = this.get('riderChangeSet');
      const primaryAddress = riderChangeSet.get('primaryAddresses.firstObject');
      const riderContacts = riderChangeSet.get('riderContacts');
      const emergencyContact = riderContacts.find(riderContact => riderContact.get('contactType') === 'emergency');
      const alternativeContact = riderContacts.find(riderContact => riderContact.get('contactType') === 'alternative');

      if (Ember.isPresent(primaryAddress)) {
        riderMailPreferenceAddresses.push({
          addressCategory: 'Primary Address',
          addressSummary: primaryAddress.get('place.address.aliasPlaceAddress'),
          enabled: primaryAddress.get('mailPreferenceEnabled'),
          model: primaryAddress
        });
      }

      if (Ember.isPresent(emergencyContact)) {
        riderMailPreferenceAddresses.push({
          addressCategory: 'Emergency Contact Address',
          addressSummary: emergencyContact.get('address'),
          enabled: emergencyContact.get('mailPreferenceEnabled'),
          model: emergencyContact
        });
      }

      if (Ember.isPresent(alternativeContact)) {
        riderMailPreferenceAddresses.push({
          addressCategory: 'Alternative Contact Address',
          addressSummary: alternativeContact.get('address'),
          enabled: alternativeContact.get('mailPreferenceEnabled'),
          model: alternativeContact
        });
      }

      let enabledMailPreferenceCount = 0;
      this.set('title', '');
      riderMailPreferenceAddresses.forEach(ele => {
        if (ele.enabled) {
          enabledMailPreferenceCount += 1;

          if (enabledMailPreferenceCount === 1) {
            this.set('title', ele.addressCategory);
          }
        }

        if (enabledMailPreferenceCount > 1) {
          this.set('title', 'Multiple Addresses');
        }
      });
      return riderMailPreferenceAddresses;
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    onEditRiderNotificationRecord() {
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isEditing', true);
    },

    onUndoRiderNotificationRecord() {
      const riderChangeSet = this.get('riderChangeSet');
      const riderMailPreferenceAddresses = this.get('riderMailPreferenceAddresses');
      riderMailPreferenceAddresses.forEach(riderMailPreferenceAddress => {
        riderMailPreferenceAddress.model.rollbackAttributes();
      });
      riderChangeSet.rollback();
      this.set('riderMailPreferenceAddresses', this.setupMailPreferenceAddresses());
      this.set('editAPI.isEditBtnDisabled', false);
      this.set('editAPI.isEditing', false);
    },

    actions: {
      onPhoneCheckboxChecked(event) {
        const enabled = event.target.checked;
        const riderChangeSet = this.get('riderChangeSet');
        riderChangeSet.set('phoneNotificationEnabled', enabled);
      },

      onEmailCheckboxChecked(event) {
        const enabled = event.target.checked;
        const riderChangeSet = this.get('riderChangeSet');
        const emailNotification = riderChangeSet.get('emailNotification');

        if (Ember.isBlank(emailNotification)) {
          riderChangeSet.set('emailNotification', riderChangeSet.get('email'));
        }

        riderChangeSet.set('emailNotificationEnabled', enabled);
      },

      onSmsCheckboxChecked(event) {
        const enabled = event.target.checked;
        const riderChangeSet = this.get('riderChangeSet');
        riderChangeSet.set('smsNotificationEnabled', enabled);
      },

      async onPhoneTypeChange(selected) {
        const riderChangeSet = this.get('riderChangeSet');
        riderChangeSet.set('phoneNotification', selected.replaceAll('-', ''));
      },

      onSMSTypeChange(selected) {
        const riderChangeSet = this.get('riderChangeSet');
        riderChangeSet.set('smsNotification', selected.replaceAll('-', ''));
      },

      createNewPhoneNumber(option) {
        const riderChangeSet = this.get('riderChangeSet');
        riderChangeSet.set('phoneNotification', option);
      },

      createNewSmsNumber(option) {
        const riderChangeSet = this.get('riderChangeSet');
        riderChangeSet.set('smsNotification', option);
      },

      toggle() {
        const tooltip = this.get('tooltip');
        const riderChangeSet = this.get('riderChangeSet');

        if (this.get('selectedToggleButtonType') === 'after') {
          this.set('toggleValue', true);
          riderChangeSet.set('allowNotification', true);
        } else {
          this.set('toggleValue', false);
          riderChangeSet.set('allowNotification', false);
        }

        if (!this.get('toggleValue')) {
          tooltip.pushConfirmation({
            title: 'Rider Management',
            tip: 'Are you sure you want to stop sending notifications to rider?',
            primaryActionText: 'OK',

            primaryAction() {
              tooltip.reset();
            },

            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              this.set('selectedToggleButtonType', 'after');
              this.set('toggleValue', true);
              riderChangeSet.set('allowNotification', true);
              tooltip.reset();
              riderChangeSet.save();
            }
          });
        }

        riderChangeSet.save();
      },

      onOpen() {
        this.toggleSelect();
      },

      onClose() {
        this.toggleSelect();
      },

      onClickAddressCheckBox(addressTypeOptions, event) {
        const checkboxCheckedLength = Ember.$('input[name=checkbox_addressTypeOptions]:checked').length;
        const checkboxChecked = Ember.$('input[name=checkbox_addressTypeOptions]:checked');
        const multipleCheckboxChecked = 'Multiple Addresses';
        const riderNotificationAddresses = this.get('riderMailPreferenceAddresses');
        const riderNotificationAddressesOptions = riderNotificationAddresses.map(notificationAddress => notificationAddress.addressCategory);
        const enabled = event.target.checked;
        const model = addressTypeOptions.model;

        if (checkboxCheckedLength > 1) {
          this.set('title', multipleCheckboxChecked);
        } else if (checkboxCheckedLength === 0) {
          this.set('title', '');
        } else if (checkboxCheckedLength === 1) {
          const selectedCheckboxName = checkboxChecked[0].id;
          const selectedCheckboxId = selectedCheckboxName.split('checkbox_addressTypeOptions')[1];
          this.set('title', riderNotificationAddressesOptions.objectAt(selectedCheckboxId));
        }

        if (enabled) {
          Ember.set(addressTypeOptions, 'enabled', true);
        } else {
          Ember.set(addressTypeOptions, 'enabled', false);
        }

        model.set('mailPreferenceEnabled', enabled);
      }

    }
  });

  _exports.default = _default;
});