define("adept-iq/serializers/location", ["exports", "adept-iq/serializers/-address"], function (_exports, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _address.default.extend({
    keyForAttribute: function (attr) {
      return attr;
    },

    serialize(snapshot, options) {
      const json = this._super(snapshot, options);

      const {
        geoNode
      } = json.data.attributes;

      if (json.data && json.data.relationships && json.data.relationships.address) {
        delete json.data.relationships.address;
      }

      if (Ember.isNone(geoNode)) {
        json.data.attributes.geoNode = 0;
      }

      return json;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      const {
        modelName
      } = primaryModelClass; // eslint-disable-next-line no-param-reassign

      id = this.extractId(primaryModelClass, payload.data);

      if (store.hasRecordForId(modelName, id)) {
        const record = store.peekRecord(modelName, id);
        store.unloadRecord(record);
        return this._super(store, primaryModelClass, payload, id, requestType);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});