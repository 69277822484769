define("adept-iq/classes/active-contexts/base/base", ["exports", "ember-data", "adept-iq/config/filter-types", "adept-iq/config/server-relationships", "adept-iq/utils/graph", "adept-iq/utils/sorts", "adept-iq/utils/unwrapProxy", "adept-iq/utils/filters", "lodash", "ember-concurrency"], function (_exports, _emberData, _filterTypes, _serverRelationships, _graph, _sorts, _unwrapProxy, _filters, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(Ember.Evented, {
    store: null,
    session: null,
    workspace: null,
    workspaceContext: null,
    user: null,
    checkedItems: null,
    refreshedModelNames: null,
    refreshInProgress: false,
    // overridden values based on extending sub classes
    // computed.readOnly('workspaceContext._structuredWorkspace')
    workspaceData: null,
    graph: null,
    mapGraph: null,
    mapNodes: null,
    nodes: null,
    emptyImplicitHash: null,
    widget: null,
    coreEntityPopulator: null,

    init() {
      this._super(...arguments);

      this.set('checkedItems', []);
      this.set('refreshedModelNames', []);
      this.set('graph', {});
      this.set('nodes', []);

      this._onWorkspaceContextChange = modelNames => {
        Ember.run.schedule('actions', this, 'onWorkspaceContextChange', modelNames);
      };

      this.get('workspaceContext').on('change', this._onWorkspaceContextChange);
    },

    reset() {
      this.set('checkedItems', []);
      this.notifyPropertyChange('implicitData');
    },

    destroy() {
      this.get('workspaceContext').off('change', this._onWorkspaceContextChange);

      this._super(...arguments);
    },

    async query(modelNames) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return await Ember.RSVP.resolve(this.queryLocal(modelNames, params));
    },

    queryLocal(modelNames) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let isServerDataDependency = arguments.length > 2 ? arguments[2] : undefined;

      if (isServerDataDependency) {
        return Promise.resolve([]);
      }

      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const activeData = this.get('structuredActiveData');
      if (!activeData) return [];
      const records = modelNames.reduce((acu, modelName) => {
        const data = Ember.makeArray(activeData[modelName]);
        acu.push(...data);
        return acu;
      }, []);
      const aggregatedData = records.filter(filterFn).sort(compareFn);
      return aggregatedData;
    },

    refreshAll() {
      const modelNames = this.get('nodes').mapBy('modelName');
      this.refreshTableContent.perform(modelNames);
    },

    // do not refresh if it is already on the list of
    // refresh to do
    addUniqueModelNamesRefresh(modelNames) {
      const newModelNames = Ember.makeArray(modelNames); // remove any models that aren't actively being listened for

      const consumedModels = this.get('consumedModelNames');

      const filteredModelNames = _lodash.default.filter(newModelNames, model => consumedModels.includes(model));

      const refreshedModelNames = this.get('refreshedModelNames');
      filteredModelNames.forEach(modelName => {
        if (!refreshedModelNames.includes(modelName)) {
          refreshedModelNames.pushObject(modelName);
        }
      });
    },

    refreshTableContent: (0, _emberConcurrency.task)(function* (modelNames) {
      this.addUniqueModelNamesRefresh(modelNames);
      yield this.startRefreshQueue();
    }).restartable(),

    toggleHighLight(record, checked) {
      if (record.get('isDestroyed')) return;
      record.set('isHighLighted', checked);
    },

    isRecordChecked(record) {
      if (!Ember.isNone(record)) {
        const checkedItems = this.get('checkedItems');
        const modelName = record.constructor.modelName;
        const modelId = record.get('id');
        return checkedItems.any(item => {
          return item.modelName === modelName && item.record.get('id') === modelId;
        });
      }

      return false;
    },

    isMultiRecordsChecked(record) {
      if (!Ember.isNone(record)) {
        const checkedItems = this.get('checkedItems');
        const modelName = record.constructor.modelName;
        let numRecordsChecked = 0;
        checkedItems.forEach(item => {
          if (item.modelName === modelName) {
            numRecordsChecked++;
          }
        });
        return numRecordsChecked > 1;
      }

      return false;
    },

    clearCheckedItems() {
      this.set('checkedItems', []);
      this.notifyPropertyChange('implicitData');
      this.refreshAll();
    },

    setRecordsChecked(records, checked) {
      const store = this.get('store');
      const checkedItems = this.get('checkedItems').slice();
      Ember.makeArray(records).forEach(record => {
        const isChecked = this.isRecordChecked(record);
        const storeRecord = store.peekRecord(record.constructor.modelName, record.get('id'));

        if (isChecked === checked) {
          return;
        }

        this.toggleHighLight(storeRecord, checked);

        if (checked) {
          checkedItems.push({
            modelName: record.constructor.modelName,
            record: storeRecord
          });
        } else {
          const item = checkedItems.findBy('record', storeRecord);
          checkedItems.removeObject(item);
        }
      });
      this.set('checkedItems', checkedItems);
      this.notifyPropertyChange('implicitData');
      const modelNames = records.mapBy('constructor.modelName').uniq();
      modelNames.forEach(modelName => {
        const relatedModelNames = this._getRelatedModelNames(modelName);

        this.addUniqueModelNamesRefresh(relatedModelNames);
      });
      this.refreshAll();
    },

    replaceCheckedRecordsByType(records) {
      const modelNames = Ember.makeArray(records).mapBy('constructor.modelName').uniq(); // uncheck any records with modelName matching an argument element

      const checkedItems = this.get('checkedItems').reject(_ref => {
        let {
          record
        } = _ref;
        return modelNames.includes(record.constructor.modelName);
      });
      records.forEach(record => {
        checkedItems.pushObject({
          modelName: record.constructor.modelName,
          record
        });
      });
      this.set('checkedItems', checkedItems);
      modelNames.forEach(modelName => {
        const relatedModelNames = this._getRelatedModelNames(modelName);

        this.addUniqueModelNamesRefresh(relatedModelNames);
      });
      this.notifyPropertyChange('implicitData');
    },

    _getRelatedModelNames(modelName) {
      const nodes = this.get('nodes');
      const graph = this.get('graph');
      const modelNode = nodes.findBy('modelName', modelName); // queue the neighbours of this modelName's active context node

      const queue = Ember.makeArray(modelNode.links).map(_ref2 => {
        let {
          nodeId
        } = _ref2;
        return graph[nodeId];
      }); // don't revisit originating node

      const visited = [modelNode];

      const getNeighbours = _ref3 => {
        let {
          links
        } = _ref3;
        return Ember.makeArray(links).map(_ref4 => {
          let {
            nodeId
          } = _ref4;
          return graph[nodeId];
        });
      };

      const modelNames = [];
      /*eslint-disable */

      const visitNode = _ref5 => {
        let {
          modelName
        } = _ref5;
        modelNames.push(modelName);
      };
      /*eslint-enable */
      // trigger refresh events for related models


      (0, _graph.breadthFirstSearch)({
        queue,
        visited,
        getNeighbours,
        visitNode
      });
      return modelNames;
    },

    // Staggered column widget refresh so UI does not become
    // unresponsive
    startRefreshQueue() {
      setTimeout(() => {
        const refreshInProgress = this.get('refreshInProgress');

        if (!refreshInProgress) {
          const refreshedModelNames = this.get('refreshedModelNames');

          if (refreshedModelNames.length > 0) {
            this.set('refreshInProgress', true);
            this.trigger('refresh', [refreshedModelNames[0]]);
            this.get('refreshedModelNames').shift();
            this.startRefreshQueue();
          }
        }
      }, 10);
    },

    // only used for server-side queries
    _buildActiveContextParams(modelName) {
      const filters = [];
      const includes = [];

      _serverRelationships.serverRelationships[modelName].forEach(relationship => {
        const items = this.get('checkedItems');
        const relatedItems = items.filterBy('modelName', relationship.modelName);

        if (Ember.isEmpty(relatedItems)) {
          return;
        }

        let path; // push necessary includes for filter

        if (relationship.type === 'belongsTo') {
          path = `${relationship.path}Id`;
          const pathSegments = relationship.path.split('.');

          if (pathSegments.length > 1) {
            const parentPath = pathSegments.slice(0, -1).join('.');
            includes.push(parentPath);
          }
        }

        if (relationship.type === 'hasMany') {
          path = `${relationship.path}.id`;
          includes.push(relationship.path);
        } // build filter node


        const values = relatedItems.mapBy('record.id');
        const filter = (0, _filters.buildValueFilterNode)(_filterTypes.uuidIn, path, values);
        filters.push(filter);
      });

      const filter = (0, _filters.buildCompoundFilterNode)('and', filters);
      return {
        filter,
        includes
      };
    },

    _buildTimeFilter(modelName) {
      const graph = this.get('graph');
      const filters = [];
      const node = graph[modelName]; // select entities that overlap time window, not just that are contained

      if (node.leftTimeConstraint) {
        const endDate = this.get('workspace.endDate').toISOString();
        const filter = (0, _filters.buildValueFilterNode)(_filterTypes.dateLte, node.leftTimeConstraint, [endDate]);
        filters.push(filter);
      }

      if (node.rightTimeConstraint) {
        const startDate = this.get('workspace.startDate').toISOString();
        const filter = (0, _filters.buildValueFilterNode)(_filterTypes.dateGte, node.rightTimeConstraint, [startDate]);
        filters.push(filter);
      }

      return (0, _filters.buildCompoundFilterNode)('and', filters);
    },

    // this fires when the workspace context is modified
    onWorkspaceContextChange(modelNames) {
      const nodes = this.get('nodes');
      const activeContextModelNames = nodes.mapBy('modelName');
      const didChange = modelNames.any(modelName => {
        return activeContextModelNames.includes(modelName);
      });

      if (didChange) {
        // the debounce value needs to be higher than the one
        // in workspace-context forceRefresh
        this.refreshAll();
      }
    },

    // Local Traversal BFS
    _makeRecordSet(data) {
      return data.reduce((obj, _ref6) => {
        let {
          modelName,
          record
        } = _ref6;
        let arr = obj[modelName];

        if (!arr) {
          arr = [];
          obj[modelName] = arr;
        }

        arr.push(record);
        return obj;
      }, {});
    },

    _localTraversalGetRelatedRecords(recordSet, modelName, link) {
      return recordSet[modelName].reduce((arr, record) => {
        let relation = null;

        if (record instanceof _emberData.default.Model) {
          relation = (0, _unwrapProxy.unwrapProxy)(record.get(link.path));
        }

        if (!relation) return arr;

        switch (link.type) {
          case 'belongsTo':
            {
              if (!relation.get('isRemoved')) arr.push(relation);
              break;
            }

          case 'hasMany':
            /*eslint-disable */
            relation.forEach(record => {
              record = (0, _unwrapProxy.unwrapProxy)(record);

              if (record && !record.get('isRemoved')) {
                arr.push(record);
              }
            });
            break;

          default:
            /*eslint-disable */
            throw `unknown relationship type '${link.type}'`;

          /*eslint-enable */
        }

        return arr;
      }, []);
    },

    /**
     * Safely adds records of a single type to a record set in place.
     * @param {RecordSet} recordSet
     * @param {string} modelName
     * @param {Array} records
     * @returns {Boolean} whether or not records were added
     */
    _augmentRecordSet(recordSet, modelName, records) {
      let collection = recordSet[modelName];

      if (!collection) {
        collection = [];
        recordSet[modelName] = collection;
      }

      const lengthBefore = collection.length;
      records.forEach(record => {
        collection.addObject(record);
      });
      return collection.length - lengthBefore;
    },

    /**
     * Traverses the domain relationship graph; this is not a true BFS!
     * @param {RecordSet} recordSet - the set of records to augment
     * @param {ModelNode[]} q - queue of model nodes to visit
     * @param {ModelNode[]} blackList - model nodes to never visit
     */
    _expandGraph() {
      let recordSet = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let q = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let blackList = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      let graph = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      // don't mutate input queue

      /*eslint-disable */
      q = q.slice();
      /*eslint-enable */
      // for each node, keep track of which nodes we came from

      const predecessors = {};

      while (q.length > 0) {
        const sourceNode = q.shift();
        /*eslint-disable */

        if (blackList.includes(sourceNode)) continue;
        /*eslint-enable */
        // eslint-disable-next-line no-loop-func

        Ember.makeArray(sourceNode.links).forEach(link => {
          const targetNode = graph[link.nodeId]; // don't backtrack across hasManys

          if (link.type === 'hasMany') {
            const ids = predecessors[sourceNode.id] || [];
            if (ids.includes(targetNode.id) || predecessors[targetNode.id]) return;
          }

          const records = this._localTraversalGetRelatedRecords(recordSet, sourceNode.modelName, link);

          const count = this._augmentRecordSet(recordSet, targetNode.modelName, records); // only [re-]visit target if we actually added something new


          if (count > 0) {
            q.addObject(targetNode);
            predecessors[targetNode.id] = predecessors[targetNode.id] || [];
            predecessors[targetNode.id].push(sourceNode.id);
          }
        });
      }

      return recordSet;
    },

    /**
     * Computes the union of two or more record sets.
     * @param {RecordSet[]} recordSets - record sets to combine
     */
    _unionRecordSets() {
      for (var _len = arguments.length, recordSets = new Array(_len), _key = 0; _key < _len; _key++) {
        recordSets[_key] = arguments[_key];
      }

      return recordSets.reduce((obj, recordSet) => {
        Object.entries(recordSet).forEach(_ref7 => {
          let [modelName, records] = _ref7;
          let collection = obj[modelName];

          if (!collection) {
            collection = [];
            obj[modelName] = collection;
          }

          if (Ember.isEmpty(records)) return;
          records.forEach(record => {
            if (collection.includes(record)) return;
            collection.push(record);
          });
        });
        return obj;
      }, {});
    },

    /**
     * Computes the intersection of two record sets used by implicit and active contexts.
     * @param {RecordSet[]} recordSets - record sets to intersect
     */
    _intersectRecordSets() {
      for (var _len2 = arguments.length, recordSets = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        recordSets[_key2] = arguments[_key2];
      }

      return recordSets.reduce((obj, recordSet, i) => {
        if (i === 0) {
          Object.entries(recordSet).forEach(_ref8 => {
            let [modelName, records] = _ref8;
            obj[modelName] = records.slice();
          });
          return obj;
        }

        Object.entries(recordSet).forEach(_ref9 => {
          let [modelName, records] = _ref9;
          let collection = obj[modelName];

          if (Ember.isEmpty(collection)) {
            collection = records || [];
            obj[modelName] = collection;
          } // find existing records that are not in the new set


          const missingRecords = collection.reduce((arr, record) => {
            if (records.includes(record)) return arr;
            arr.push(record);
            return arr;
          }, []); // remove those from the existing set

          missingRecords.forEach(record => {
            const index = collection.indexOf(record);
            collection.splice(index, 1);
          });
        });
        return obj;
      }, {});
    },

    /**
     * Computes the intersection of two record sets be used by compute map context.
     * @param {RecordSet[]} recordSets - record sets to intersect
     */
    _mapIntersectRecordSets(allData, recordSets) {
      return recordSets.reduce((acu, set) => {
        Object.entries(set).forEach(_ref10 => {
          let [modelName, records] = _ref10;

          const intersected = _lodash.default.intersection(allData[modelName], records); // find the intersection between the complete data


          acu[modelName] = _lodash.default.union(intersected, acu[modelName]); // other record set may be seperate graphs merge any missing records
        });
        return acu;
      }, {});
    },

    /**
     * Computes the Implicit Context graph induced by a given selection of entities.
     * @param {RecordSet} allData - the complete data set
     * @param {Object[]} selectedData - the entities that have been "checked"
     */
    _computeImplicitContext(allData, selectedData) {
      const nodes = this.get('nodes');
      const graph = this.get('graph'); // implicit context is empty until something explicitly selected

      if (Ember.isEmpty(selectedData)) return {};
      let intersection = null;

      const selectedRecordSet = this._makeRecordSet(selectedData);

      try {
        // compute induced subgraphs of each entity type group
        const recordSets = Object.keys(selectedRecordSet).map(modelName => {
          const modelNode = nodes.findBy('modelName', modelName);
          const recordSet = {}; // for *implicit* data, we seed with only the selected records; this results
          // in a smaller data set when only one type is selected

          recordSet[modelName] = selectedRecordSet[modelName];
          const queue = [];
          Ember.makeArray(modelNode.links).forEach(link => {
            // get related records of _selected_ items only
            const records = this._localTraversalGetRelatedRecords(selectedRecordSet, modelName, link);

            const linkModelName = graph[link.nodeId].modelName;
            recordSet[linkModelName] = records;
            const node = graph[link.nodeId];
            queue.push(node);
          });
          const visited = [modelNode]; // push related records as far as they will go

          const expandedRecordSet = this._expandGraph(recordSet, queue, visited, graph); // zero out any unvisited nodes *in this graph component*


          (0, _graph.breadthFirstSearch)({
            queue,
            visited,
            getNeighbours: _ref11 => {
              let {
                links
              } = _ref11;
              return Ember.makeArray(links).map(_ref12 => {
                let {
                  nodeId
                } = _ref12;
                return graph[nodeId];
              });
            },

            /*eslint-disable */
            visitNode: _ref13 => {
              let {
                modelName
              } = _ref13;
              expandedRecordSet[modelName] = expandedRecordSet[modelName] || [];
            }
            /*eslint-enable */

          });
          return expandedRecordSet;
        });
        intersection = this._intersectRecordSets(allData, ...recordSets);
      } catch (error) {
        console.log('Exception in computeImplicitContext in local-traversal.js' + error); // eslint-disable-line no-console
      }

      return intersection;
    },

    /**
     * Computes the Implicit Context graph induced by a given selection of entities.
     * @param {RecordSet} allData - the complete data set
     * @param {Object[]} selectedData - the entities that have been "checked"
     */
    _computeMapContext(allData, selectedData) {
      const nodes = this.get('mapNodes');
      const graph = this.get('mapGraph'); // implicit context is empty until something explicitly selected

      if (Ember.isEmpty(selectedData)) return {};
      let intersection = null;

      const selectedRecordSet = this._makeRecordSet(selectedData);

      try {
        // compute induced subgraphs of each entity type group
        const recordSets = Object.keys(selectedRecordSet).map(modelName => {
          const modelNode = nodes.findBy('modelName', modelName);
          const recordSet = {}; // for *implicit* data, we seed with only the selected records; this results
          // in a smaller data set when only one type is selected

          recordSet[modelName] = selectedRecordSet[modelName];
          const queue = [];
          Ember.makeArray(modelNode.links).forEach(link => {
            // get related records of _selected_ items only
            const records = this._localTraversalGetRelatedRecords(selectedRecordSet, modelName, link);

            const linkModelName = graph[link.nodeId].modelName;
            recordSet[linkModelName] = records;
            const node = graph[link.nodeId];
            queue.push(node);
          });
          const visited = [modelNode]; // push related records as far as they will go

          const expandedRecordSet = this._expandGraph(recordSet, queue, visited, graph); // zero out any unvisited nodes *in this graph component*


          (0, _graph.breadthFirstSearch)({
            queue,
            visited,
            getNeighbours: _ref14 => {
              let {
                links
              } = _ref14;
              return Ember.makeArray(links).map(_ref15 => {
                let {
                  nodeId
                } = _ref15;
                return graph[nodeId];
              });
            },

            /*eslint-disable */
            visitNode: _ref16 => {
              let {
                modelName
              } = _ref16;
              expandedRecordSet[modelName] = expandedRecordSet[modelName] || [];
            }
            /*eslint-enable */

          });
          return expandedRecordSet;
        });
        intersection = this._mapIntersectRecordSets(allData, recordSets);
      } catch (error) {
        console.log('Exception in computeImplicitContext in local-traversal.js' + error); // eslint-disable-line no-console
      }

      return intersection;
    },

    /**
     * Computes the Active Context graph induced by a given selection of entities.
     * @param {RecordSet} allData - the complete data set
     * @param {Object[]} selectedData - the entities that have been "checked"
     */
    _computeActiveContext(allData, selectedData) {
      let union = null;
      let intersection = null;
      const nodes = this.get('nodes');
      const graph = this.get('graph');

      const selectedRecordSet = this._makeRecordSet(selectedData);

      try {
        // compute induced subgraphs of each entity type group
        const recordSets = Object.keys(selectedRecordSet).map(modelName => {
          const modelNode = nodes.findBy('modelName', modelName);
          const recordSet = {}; // for *full* active context, we seed with all data of this type

          recordSet[modelName] = allData[modelName];
          const queue = [];
          Ember.makeArray(modelNode.links).forEach(link => {
            // get related records of _selected_ items only
            const records = this._localTraversalGetRelatedRecords(selectedRecordSet, modelName, link);

            const linkModelName = graph[link.nodeId].modelName;
            recordSet[linkModelName] = records;
            const node = graph[link.nodeId];
            queue.push(node);
          });
          const visited = [modelNode]; // push related records as far as they will go

          const expandedRecordSet = this._expandGraph(recordSet, queue, visited, graph); // zero out any unvisited nodes *in this graph component*


          (0, _graph.breadthFirstSearch)({
            queue,
            visited,
            getNeighbours: _ref17 => {
              let {
                links
              } = _ref17;
              return Ember.makeArray(links).map(_ref18 => {
                let {
                  nodeId
                } = _ref18;
                return graph[nodeId];
              });
            },

            /*eslint-disable */
            visitNode: _ref19 => {
              let {
                modelName
              } = _ref19;
              expandedRecordSet[modelName] = expandedRecordSet[modelName] || [];
            }
            /*eslint-enable */

          });
          return expandedRecordSet;
        });
        intersection = this._intersectRecordSets(allData, ...recordSets); // add the selected records back in where missing

        union = this._unionRecordSets(intersection, selectedRecordSet);
      } catch (error) {
        console.log('Exception in computeActiveContext in local-traversal.js' + error); // eslint-disable-line no-console
      }

      return union;
    }

  });

  _exports.default = _default;
});