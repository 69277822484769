define("adept-iq/serializers/route", ["exports", "adept-iq/serializers/-ss-schedulingService", "moment"], function (_exports, _ssSchedulingService, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    attrs: {
      otp: {
        serialize: false
      },
      otpValue: {
        serialize: false
      },
      odometer: {
        serialize: false
      },
      actualStartTimestamp: {
        serialize: false
      },
      actualEndTimestamp: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments); // because we modify status coming in from route-exec-event
      // scheduling service only accepts normal or breakdown
      // we don't want to PATCH/PUT the status. This is controlled by
      // the service
      // we don't want to PATCH the isAdjusted.


      if (json.data.id && json.data.attributes) {
        delete json.data.attributes.status;
        delete json.data.attributes.isAdjusted;
        delete json.data.attributes.hasRouteTemplateAdjusted;
        delete json.data.attributes.hasRouteTemplateConsolidated;
        delete json.data.attributes.isDriverBreakRemoved;
      } // affinites must be converted into numbers to be valid
      // safest conversion is using JSON.parsed for "[1,2,3]" -> [1,2,3]


      if (json.data.attributes.affinities) {
        json.data.attributes.affinities = JSON.parse(json.data.attributes.affinities);
      }

      if (json.data && json.data.relationships) {
        delete json.data.relationships.dispatchRoute;
        delete json.data.relationships.provider;
        delete json.data.relationships.etaRoute;
        delete json.data.relationships.navigationPolyline;
        delete json.data.relationships.scheduledPolyline;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.relationships.providerName.data.id
          }
        };
        resourceHash.relationships = relationships;
      }

      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.attributes.providerName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.providerName;
      }

      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.routeTemplateId)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.routeTemplate = {
          data: {
            type: 'route-template',
            id: resourceHash.attributes.routeTemplateId
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.routeTemplateId;
      }

      if (!Ember.isNone(resourceHash.attributes)) {
        const {
          plannedStartTime,
          plannedEndTime,
          plannedStartDateTime,
          plannedEndDateTime
        } = resourceHash.attributes;

        if (plannedStartTime && plannedStartDateTime && !_moment.default.utc(plannedStartTime).isValid()) {
          resourceHash.attributes.plannedStartTime = plannedStartDateTime;
        }

        if (plannedEndTime && plannedEndDateTime && !_moment.default.utc(plannedEndTime).isValid()) {
          resourceHash.attributes.plannedEndTime = plannedEndDateTime;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};
      payload.data.relationships = relationships; // TODO: we need to store navigation polyline from widgetRecord

      if (Ember.isPresent(payload.data.attributes.lock)) {
        const scheduleRouteLock = {
          type: 'lock',
          id: payload.data.id,
          attributes: {
            entityType: 'route',
            entityId: payload.data.id,
            timeToLive: payload.data.attributes.lock.ttl,
            expirationTime: payload.data.attributes.lock.expiredAt,
            status: 'locked',
            persist: payload.data.attributes.lock.persist
          },
          relationships: {
            route: {
              data: {
                type: 'route',
                id: payload.data.id
              }
            },
            owner: {
              data: {
                type: 'sso-user',
                id: payload.data.attributes.lock.user
              }
            }
          }
        };
        relationships.scheduleRouteLock = {
          data: {
            type: 'lock',
            id: payload.data.id
          }
        };
        payload.included = payload.included || [];
        payload.included.push(scheduleRouteLock);
      } else if (Ember.isPresent(payload.data.attributes.isLocked) && !payload.data.attributes.isLocked) {
        const scheduleRouteLock = {
          type: 'lock',
          id: payload.data.id,
          attributes: {
            entityType: 'route',
            entityId: payload.data.id,
            status: 'unlocked'
          },
          relationships: {
            route: {
              data: {
                type: 'route',
                id: payload.data.id
              }
            }
          }
        };
        relationships.scheduleRouteLock = {
          data: {
            type: 'lock',
            id: payload.data.id
          }
        };
        payload.included = payload.included || [];
        payload.included.push(scheduleRouteLock);
      }

      return payload;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'findRecord') {
        const relationships = payload.data.relationships ? payload.data.relationships : {};

        if (!Ember.isNone(relationships) && Ember.isNone(relationships.trips)) {
          relationships.trips = {
            data: []
          };
          payload.data.relationships = relationships;
        }
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});