define("adept-iq/pods/components/iq-widgets/booking-trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/booking-trips-widget/config", "adept-iq/pods/components/iq-widgets/booking-trips-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds", "adept-iq/models/trip"], function (_exports, _component, _config, _avlmConfig, _environment, _unwrapProxy, _mappedPermIds, _trip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['view-alltrips-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    canShowHeader: true,
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
      this.getbookingTripsRecords = this.getbookingTripsRecords.bind(this);
      this.set('getRecords', this.getbookingTripsRecords);
    },

    getbookingTripsRecords() {
      const filteredCurrentTrips = this.get('booking.filteredCurrentTrips') || [];
      const uniqTrips = this.get('booking').uniqTrips(filteredCurrentTrips);
      return uniqTrips;
    },

    didReceiveAttrs() {
      this._super(...arguments);

      setTimeout(() => {
        this.selectAndDeselectTableRows(false);
      }, 100);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('cancelAction', {
        id: 'cancel',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: trip => {
          const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
          const segment = (0, _unwrapProxy.unwrapProxy)(trip.get('segment'));
          booking.set('timestamp', new Date());
          const cancelTripPanelDetails = {
            displayName: 'Cancel Trip',
            trip,
            booking,
            segment,
            tripId
          };
          this.get('workspace').pushState('cancelTripForm', cancelTripPanelDetails);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
    },

    selectAndDeselectTableRows(isRow) {
      const tripsWidgetTableRows = this.get('table.rows');
      tripsWidgetTableRows.setEach('selected', isRow);
      tripsWidgetTableRows.setEach('isChecked', isRow);
    },

    actions: {
      onRowClick() {},

      onCheckAll() {
        const isRowHeadChecked = true;
        this.selectAndDeselectTableRows(isRowHeadChecked);
      },

      onUncheckAll() {
        const isRowHeadUnchecked = false;
        this.selectAndDeselectTableRows(isRowHeadUnchecked);
      },

      onRowRightClick(row) {
        const rowStatus = row.get('status');

        const cancelStatus = rowStatus === _trip.TRIP_CANCELED_STATUS.toLowerCase();

        if (!cancelStatus) this.set('dynamicSingleActions', [this.get('cancelAction')]);

        this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});