define("adept-iq/pods/components/reconcile-search-passenger/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentTableHeader = 'reconcile-search-passenger/table-header';
  const activeInactivePassengerDot = 'reconcile-search-passenger/active-passenger-cell';
  var _default = {
    title: 'searchPassenger',
    PASSENGER_ID: 1,
    FIRST_NAME: 2,
    LAST_NAME: 3,
    searchPassengerColumns: [{
      width: '1px',
      resizable: false,
      cellComponent: 'table/cells/radio-button'
    }, {
      label: 'Status',
      valuePath: 'status',
      dataType: 'text',
      resizable: true,
      cellComponent: activeInactivePassengerDot,
      component: componentTableHeader
    }, {
      label: 'Passenger ID',
      valuePath: 'id',
      dataType: 'number',
      resizable: true,
      component: componentTableHeader
    }, {
      label: 'First Name',
      valuePath: 'firstName',
      dataType: 'text',
      resizable: true,
      component: componentTableHeader
    }, {
      label: 'Last Name',
      valuePath: 'lastName',
      dataType: 'text',
      resizable: true,
      component: componentTableHeader
    }, {
      label: 'Phone Number',
      valuePath: 'formattedPhoneNumber',
      dataType: 'number',
      resizable: true,
      component: componentTableHeader
    }, {
      label: 'DOB',
      valuePath: 'dateOfBirth',
      dataType: 'date',
      format: 'MM-DD-YYYY',
      resizable: true,
      component: componentTableHeader
    }]
  };
  _exports.default = _default;
});