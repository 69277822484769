define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-vehicle/component", ["exports", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-vehicle/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reconcileLambda: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    vehicleTable: null,
    selectedVehicle: null,
    searchButtonEnabled: Ember.computed.empty('selectedVehicle'),
    clearButtonEnabled: Ember.computed.empty('selectedVehicle'),
    config: _config.default,

    init() {
      this._super(...arguments);

      this.set('fetchRoutesByVehicle', this.get('fetchRoutesByVehicle').bind(this));
      this.set('config.title', '');
    },

    vehicleOptions: Ember.computed('reconcile.reconcileRVDResponse', function () {
      const reconcileVehicles = this.get('reconcile.reconcileRVDResponse');
      const vehicleOptions = reconcileVehicles.map(vehicle => {
        return {
          id: vehicle.vehicle.id,
          callsign: vehicle.vehicle.callsign
        };
      });
      return vehicleOptions;
    }),

    async fetchRoutesByVehicle() {
      const vehicleId = this.get('selectedVehicle.id');
      const searchMode = this.get('selectedSearchMode').toLowerCase();

      if (!vehicleId || !searchMode) {
        return [];
      }

      const routes = await this.reconcileLambda.getReconcileRoutesAndTripsInfoByVehicleId.perform(searchMode, vehicleId);

      if (!routes.length) {
        this.set('config.title', '');
        this.set('config.noRecordsFound', true);
      } else {
        this.set('config.title', `${Ember.String.capitalize(searchMode)}`);
        this.set('config.noRecordsFound', false);
      }

      return routes.map(res => {
        return { ...res,
          tripDate: this.get('reconcile.reconcileDate')
        };
      });
    },

    getVehicleOptions(rvdResponse, selectedOption) {
      return rvdResponse.filter(rvDriver => rvDriver.vehicle.callsign !== selectedOption.callsign).map(vehicle => {
        return {
          id: vehicle.vehicle.id,
          callsign: vehicle.vehicle.callsign
        };
      });
    },

    actions: {
      onSearchRoutesByVehicle() {
        this.get('vehicleTable').refreshData();
      },

      onOptionsSelect(item, option) {
        Ember.set(this, item, option);
        this.notifyPropertyChange(item);
        const rvdResponse = this.get('reconcile.reconcileRVDResponse');
        const vehicleOptions = this.getVehicleOptions(rvdResponse, option);
        this.set('vehicleOptions', vehicleOptions);
      },

      clearSearchVehicle() {
        this.set('selectedVehicle', '');
        this.get('vehicleTable').clearData();
        this.set('config.title', '');
        this.set('config.noRecordsFound', false);
        this.set('routeId', null);
        const rvdResponse = this.get('reconcile.reconcileRVDResponse');
        const vehicleOptions = this.getVehicleOptions(rvdResponse, this.get('selectedVehicle'));
        this.set('vehicleOptions', vehicleOptions);
      }

    }
  });

  _exports.default = _default;
});