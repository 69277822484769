define("adept-iq/serializers/trip", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      const relationships = resourceHash.relationships || {};

      if (Ember.isNone(relationships.pick) && !Ember.isNone(resourceHash.attributes.pickId)) {
        relationships.pick = {
          data: {
            type: 'trip-stop',
            id: resourceHash.attributes.pickId
          }
        };
      }

      if (Ember.isNone(relationships.drop) && !Ember.isNone(resourceHash.attributes.dropId)) {
        relationships.drop = {
          data: {
            type: 'trip-stop',
            id: resourceHash.attributes.dropId
          }
        };
      }

      if (Ember.isNone(relationships.route) && !Ember.isNone(resourceHash.attributes.routeId)) {
        relationships.route = {
          data: {
            type: 'route',
            id: resourceHash.attributes.routeId
          }
        };
      }

      resourceHash.relationships = relationships;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      payload.data.relationships = payload.data.relationships || {};
      return payload;
    }

  });

  _exports.default = _default;
});