define("adept-iq/pods/components/side-drawer/system-config/component", ["exports", "adept-iq/utils/remove-focus", "adept-iq/pods/components/side-drawer/system-config/speed-region-groups/component", "adept-iq/config/mapped-permIds"], function (_exports, _removeFocus, _component, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SYSTEM_CONFIG_COMPONENTS = ['contract-management', 'route-master', 'providers', 'places', 'speed-region-groups'];
  const UNSAVED_CHANGES_CONFIRMATION = 'You have unsaved changes that will be discarded. Close Anyway?';
  const SECURITY_PERM_IDS = {
    'Cancel Trips': _mappedPermIds.default.manageCancelTrips || -1,
    'Book Trips': _mappedPermIds.default.manageBookTrips || -1,
    'Canned Messages': _mappedPermIds.default.manageCannedMessages || -1,
    'DB Purge Parameters': _mappedPermIds.default.manageDBPurgeParameters || -1,
    'Driving Configuration': _mappedPermIds.default.manageDrivingConfiguration || -1,
    'Fixed Route Engine Parameters': _mappedPermIds.default.manageFixedRouteEngineParameters || -1,
    'Passwords': _mappedPermIds.default.manageDriverAppAdminPassword || -1,
    'Route Parameters': _mappedPermIds.default.manageRouteParameters || -1,
    'Scheduling': _mappedPermIds.default.manageScheduling || -1,
    'Scheduling Parms': _mappedPermIds.default.manageSchedulingParms || -1,
    'System Configuration': _mappedPermIds.default.manageSystemConfiguration || -1,
    'Road Supervisor Configuration': _mappedPermIds.default.manageRoadSupervisorConfiguration || -1,
    'User Accounts': _mappedPermIds.default.manageUserAccounts || -1
  };

  var _default = Ember.Component.extend({
    tagName: '',
    tooltip: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    workspace: Ember.inject.service(),
    user: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    widget: Ember.inject.service(),
    title: 'System Configuration',
    componentName: 'systemConfigurations',
    // gets passed in by `side-drawer-container`
    onShrinkClick: null,
    isSearchEnabled: false,
    currentComponentPath: Ember.computed.alias('systemConfig.currentComponentPath'),
    currentRegionSetType: '',
    // speed or blockout
    isContractManagementAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editContractManagement);
    }),
    isRouteMasterAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return this.get('permissionLayer').permInUserHash(_mappedPermIds.default.manageRouteSchedule);
    }),
    isPlaceAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editPlaces);
    }),
    isProvidersAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editService);
    }),
    isSpeedRegionsAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return false; // TODO: enable the permission
      // return this.get('permissionLayer').permInUserHash(mappedPermIds.manageSpeedRegions);
    }),
    isBlockoutRegionsAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return this.get('permissionLayer').permInUserHash(_mappedPermIds.default.manageBlockoutRegions);
    }),
    isAdaBoundariesAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return false; // TODO: enable the permission
      // return this.get('permissionLayer').permInUserHash(mappedPermIds.manageADABoundaries);
    }),
    isSearchEffective: Ember.computed.readOnly('systemConfig.isSearchEffective'),
    searchText: Ember.computed.alias('systemConfig.searchText'),
    allRootNodes: Ember.computed.readOnly('systemConfig.rootNodes'),
    rootNodes: Ember.computed('allRootNodes.[]', function () {
      //check if user has the permission to view and edit the configuration options
      const systemConfig = this.get('allRootNodes');

      if (systemConfig) {
        const permissionRootNodes = systemConfig.filter(node => {
          const permissionRestriction = SECURITY_PERM_IDS[node.label];
          return !permissionRestriction || this.get('permissionLayer').permInUserHash(permissionRestriction);
        });
        return permissionRootNodes;
      }
    }),
    isProcessing: Ember.computed.readOnly('systemConfig.isProcessing'),
    isModified: Ember.computed.readOnly('systemConfig.isModified'),
    isError: Ember.computed.readOnly('systemConfig.isError'),
    isScheduler: Ember.computed.readOnly('user.isScheduler'),
    hasChanges: Ember.computed.readOnly('systemConfig.hasChanges'),
    showRightPanel: Ember.computed.alias('systemConfig.showRightPanel'),

    async init() {
      this._super(...arguments);

      this.set('workspace.systemConfigInProgress', true);
      this.unsavedChangesModal = this.get('unsavedChangesModal').bind(this); // bypass refresh when side panel is being unshrunk

      if (!this.get('systemConfig.isShrinkClick')) {
        this.get('systemConfig').refresh();
      }

      this.set('systemConfig.isShrinkClick', false);
      await this.get('widget').getFutureBookings();
      await this.get('widget').getFutureSubscriptions();

      if (SYSTEM_CONFIG_COMPONENTS.includes(this.get('systemConfig.currentComponentPath'))) {
        this.set('systemConfig.showRightPanel', true);
      }
    },

    didInsertElement() {
      this.setSideDrawerWidth('auto');
    },

    willDestroyElement() {
      this.set('systemConfig.searchText', null);
      this.set('showRightPanel', false);

      this._super(...arguments);

      this.set('systemConfig.currentComponentPath', null);
    },

    focusSearchInput() {
      Ember.$('.system-config-search-input').focus();
    },

    switchingRegion() {
      (0, _removeFocus.cursorWait)();
      this.set('switchingRegionType', true);
      Ember.run.later(() => {
        this.set('switchingRegionType', false);
        (0, _removeFocus.cursorDefault)();
      }, _component.WAIT_TIME);
    },

    unsavedChangesModal(changeFlags, message, action) {
      const tooltip = this.get('tooltip');

      if (changeFlags.filter(changeFlag => changeFlag).length > 0) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          tip: message,
          primaryActionText: 'OK',
          primaryAction: () => {
            tooltip.reset();
            action();
          },
          secondaryActionText: 'Cancel',
          hasOverlay: true,
          secondaryAction: () => {
            tooltip.reset();
          }
        });
      } else {
        action();
      }
    },

    actions: {
      onSaveClick() {
        this.get('systemConfig').save();
      },

      onRefreshClick() {
        this.unsavedChangesModal([this.get('isModified')], UNSAVED_CHANGES_CONFIRMATION, () => {
          this.get('systemConfig').refresh();
        });
      },

      onCloseClick() {
        this.unsavedChangesModal([this.get('isModified'), this.get('hasChanges')], UNSAVED_CHANGES_CONFIRMATION, () => {
          this.set('workspace.systemConfigInProgress', false);
          this.set('showRightPanel', false);
          this.set('systemConfig.searchText', null);
          this.set('systemConfig.currentComponentPath', null);
          this.get('systemConfig').refresh();
          this.set('systemConfig.hasChanges', false);
          this.onCloseClick();
        });
      },

      onSearchClick() {
        const isSearchEnabled = this.get('isSearchEnabled');

        if (isSearchEnabled) {
          this.set('isSearchEnabled', false);
          return;
        }

        this.set('searchText', '');
        this.set('isSearchEnabled', true);
        Ember.run.scheduleOnce('afterRender', this, 'focusSearchInput');
      },

      showContractPanel() {
        this.unsavedChangesModal([this.get('hasChanges')], UNSAVED_CHANGES_CONFIRMATION, () => {
          this.setSideDrawerWidth('auto');
          this.setProperties({
            'showRightPanel': true,
            'currentComponentPath': 'contract-management'
          });
        });
      },

      showRouteMasterPanel() {
        this.unsavedChangesModal([this.get('hasChanges')], UNSAVED_CHANGES_CONFIRMATION, () => {
          this.setSideDrawerWidth('auto');
          this.setProperties({
            'showRightPanel': true,
            'currentComponentPath': 'route-master'
          });
        });
      },

      showProviderPanel() {
        this.unsavedChangesModal([this.get('hasChanges')], UNSAVED_CHANGES_CONFIRMATION, () => {
          this.setSideDrawerWidth('auto');
          this.setProperties({
            'showRightPanel': true,
            'currentComponentPath': 'providers'
          });
        });
      },

      showPlacePanel() {
        this.unsavedChangesModal([this.get('hasChanges')], UNSAVED_CHANGES_CONFIRMATION, () => {
          this.setSideDrawerWidth('auto');
          this.setProperties({
            'showRightPanel': true,
            'currentComponentPath': 'places'
          });
        });
      },

      showSpeedRegionGroupPanel() {
        this.unsavedChangesModal([this.get('hasChanges')], UNSAVED_CHANGES_CONFIRMATION, () => {
          // do not switch right away
          if (this.get('switchingRegionType')) {
            return;
          }

          this.switchingRegion();

          if (this.get('currentComponentPath') === 'speed-region-groups') {
            this.set('currentRegionSetType', 'speed');
          } else {
            this.setProperties({
              'showRightPanel': true,
              'currentComponentPath': 'speed-region-groups',
              'currentRegionSetType': 'speed'
            });
          }
        });
      },

      showBlockoutRegionGroupPanel() {
        this.unsavedChangesModal([this.get('hasChanges')], UNSAVED_CHANGES_CONFIRMATION, () => {
          // do not switch right away
          if (this.get('switchingRegionType')) {
            return;
          }

          this.switchingRegion();

          if (this.get('currentComponentPath') === 'speed-region-groups') {
            this.set('currentRegionSetType', 'blockout');
          } else {
            this.setProperties({
              'showRightPanel': true,
              'currentComponentPath': 'speed-region-groups',
              'currentRegionSetType': 'blockout'
            });
          }
        });
      },

      onConfigShrinkClick() {
        this.set('showRightPanel', false);
        this.set('systemConfig.isShrinkClick', true);
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});