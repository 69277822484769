define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/tables/dot-inspection/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'vehicle-dot-inspection',
    title: 'DOT Inspection',
    defaultSortId: 'providerID',
    defaultSortAsc: true,
    selectFirstRow: true,
    queryModelData: true,
    tooltipTitle: 'Manage Vehicle',
    columns: [{
      id: 'providerID',
      index: 1,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider.name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'vehicleLicensePlate',
      index: 2,
      type: 'text',
      label: 'Vehicle License Plate',
      valuePath: 'vehicle.licensePlate',
      defaultWidth: 125,
      searchable: true
    }, {
      id: 'inspectionDate',
      index: 3,
      type: 'date',
      label: 'Inspection Date',
      valuePath: 'inspectionDate',
      defaultWidth: 70,
      searchable: true,
      format: 'MM/DD/YYYY'
    }, {
      id: 'mileage',
      index: 4,
      type: 'text',
      label: 'Mileage',
      valuePath: 'mileage',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'nextInspectionDate',
      index: 5,
      type: 'date',
      label: 'Next Inspection Date',
      valuePath: 'nextInspectionDate',
      defaultWidth: 70,
      searchable: true,
      format: 'MM/DD/YYYY'
    }, {
      id: 'notes',
      index: 6,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'failedDOT',
      index: 7,
      type: 'text',
      label: 'Failed DOT',
      valuePath: 'failedDot',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'oosDate',
      index: 8,
      type: 'date',
      label: 'OOS Date',
      valuePath: 'oosDate',
      defaultWidth: 70,
      searchable: true,
      format: 'MM/DD/YYYY'
    }, {
      id: 'vehicleInspectionCategory',
      index: 9,
      type: 'text',
      label: 'Vehicle Inspection Category',
      valuePath: 'inspectionCategory.description',
      defaultWidth: 70,
      searchable: true,
      format: 'MM/DD/YYYY'
    }]
  };
  _exports.default = _default;
});