define("adept-iq/pods/index/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    user: Ember.inject.service(),

    model() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        Promise.all([this.getDashboard(), this.getLastOptimizedSchedule()]).then(_ref => {
          let [dashboard, schedule] = _ref;
          resolve({
            dashboard,
            schedule
          });
        }).catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching model data:', error);
          reject(error);
        });
      });
    },

    getLastOptimizedSchedule() {
      return this.store.findAll('schedule', {
        reload: true
      }).then(SSchedules => {
        //const filteredSchedules  = SSchedules.filter((item) => item.get('type') === 'primary' && item.get('status') === 'scheduled');
        if (SSchedules.length > 0) {
          const sortedSchedules = SSchedules.sortBy('id').reverse();
          return sortedSchedules.get('firstObject');
        }

        return null;
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching schedules:', error);
        return null;
      });
    },

    getDashboard() {
      const DEFAULT_DASHBOARD_ID = 'workspaces-schedule/schedule';
      return this.store.findAll('dashboard').then(dashboards => {
        const defaultDashboard = dashboards.findBy('id', DEFAULT_DASHBOARD_ID);
        return defaultDashboard || dashboards.firstObject;
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching dashboards:', error);
        return null;
      });
    },

    afterModel(model) {
      const {
        dashboard,
        schedule
      } = model;

      if (schedule) {
        this.transitionTo('dashboard', dashboard.role, dashboard.id);
      } else {
        this.transitionTo('landing-page');
      }
    }

  });

  _exports.default = _default;
});