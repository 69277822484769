define("adept-iq/serializers/vehicle", ["exports", "adept-iq/serializers/-vehicle", "lodash"], function (_exports, _vehicle, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vehicle.default.extend({
    attrs: {},

    serialize() {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        json.data.relationships = json.data.relationships || {};
        const {
          vehicleType,
          provider
        } = json.data.relationships;

        if (!Ember.isNone(vehicleType)) {
          vehicleType.data.type = 'vehicleTypeName';
          json.data.relationships.vehicleTypeName = json.data.relationships.vehicleType;
          delete json.data.relationships.vehicleType;
        }

        if (!Ember.isNone(provider)) {
          provider.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }
      }

      return json;
    },

    // currently vehicles included payload returns vehicleTypeName & providerName
    // this means the console will spew a bunch of warning messages for missing these models
    // we currently don't have a way to remove these problems especially we can't traverse the array as that in itself is a code smell
    // @FIXME: find a way to address this data situation
    // I left this code as a reminder for this small low priority problem
    // normalizeResponse: function(store, primaryModelClass, payload) {
    //   delete payload.included;
    //   return payload;
    // },
    normalizeResponse(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      delete payload.included;
      return this._super(...arguments);
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};
      const {
        vehicleTypeName
      } = resourceHash.relationships;

      if (vehicleTypeName) {
        if (vehicleTypeName.links) {
          delete vehicleTypeName.links;
        }

        resourceHash.relationships.vehicleTypeName.data.type = 'vehicle-type';
        resourceHash.relationships.vehicleType = (0, _lodash.cloneDeep)(resourceHash.relationships.vehicleTypeName);
        delete resourceHash.relationships.vehicleTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};

      if (Ember.isPresent(payload.data.attributes.type)) {
        relationships.vehicleType = {
          data: {
            type: 'vehicle-type',
            id: payload.data.attributes.type,
            name: payload.data.attributes.type,
            displayName: payload.data.attributes.type
          }
        };
        delete payload.data.attributes.type;
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});