define("adept-iq/pods/components/aws-file-download/component", ["exports", "aws-sdk", "adept-iq/config/api-urls"], function (_exports, _awsSdk, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),

    async getSignedUrl(filePath) {
      _awsSdk.default.config.update({
        accessKeyId: _apiUrls.API.awsUploadConfig.accessKeyId,
        secretAccessKey: _apiUrls.API.awsUploadConfig.secretAccessKey,
        region: _apiUrls.API.awsUploadConfig.region
      });

      const s3 = new _awsSdk.default.S3();
      const params = {
        Bucket: _apiUrls.API.awsUploadConfig.bucketName,
        Key: filePath,
        Expires: 60 // URL expiration time in seconds

      };
      return new Promise((resolve, reject) => {
        s3.getSignedUrl('getObject', params, (err, url) => {
          if (err) {
            reject(err);
          } else {
            resolve(url);
          }
        });
      });
    },

    async getS3Object(filePath) {
      _awsSdk.default.config.update({
        accessKeyId: _apiUrls.API.awsUploadConfig.accessKeyId,
        secretAccessKey: _apiUrls.API.awsUploadConfig.secretAccessKey,
        region: _apiUrls.API.awsUploadConfig.region
      });

      const s3 = new _awsSdk.default.S3();
      const params = {
        Bucket: _apiUrls.API.awsUploadConfig.bucketName,
        Key: filePath
      };

      try {
        const data = await s3.getObject(params).promise();
        return data.Body;
      } catch (error) {
        throw new Error(`Failed to get object from S3: ${error.message}`);
      }
    },

    async downloadFile(filePath, fileName) {
      try {
        const data = await this.getS3Object(filePath);
        const url = window.URL.createObjectURL(new Blob([data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a); // Stop the event from bubbling up to parent elements

        a.addEventListener('click', event => {
          event.stopPropagation();
        });
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        throw error;
      }
    },

    actions: {
      async triggerFileDownload() {
        try {
          if (this.showSpinner) {
            this.workspace.set('isGlobalSpinnerVisible', true);
          }

          if (this.preDownloadAction) {
            const isActionSuccessful = await this.preDownloadAction();

            if (!isActionSuccessful) {
              throw new Error('Pre-download action failed');
            }
          }

          const userTenantName = localStorage.getItem('userTenantName');

          if (!userTenantName) {
            throw new Error('User tenant name not found');
          }

          const fileName = this.get('fileName');
          const folderPath = this.get('folderPath') || 'templates';
          const filePath = `${userTenantName}/${folderPath}/${fileName}`;
          await this.downloadFile(filePath, fileName);

          if (this.onSuccess) {
            this.onSuccess();
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error during file download:', error);
          this.notifications.warning('Failed to download the file. Please try again.');

          if (this.onFailure) {
            this.onFailure();
          }
        } finally {
          if (this.showSpinner) {
            this.workspace.set('isGlobalSpinnerVisible', false);
          }
        }
      }

    }
  });

  _exports.default = _default;
});