define("adept-iq/models/trip", ["exports", "ember-data", "adept-iq/utils/format-text-extension", "adept-iq/mixins/otp-label", "adept-iq/utils/unwrapProxy"], function (_exports, _emberData, _formatTextExtension, _otpLabel, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRIP_WILLCALL_STATUS = _exports.TRIP_WAITLIST_STATUS = _exports.TRIP_UNSCHEDULED_STATUS = _exports.TRIP_SCHEDULED_STATUS = _exports.TRIP_REQUESTED_STATUS = _exports.TRIP_NOSHOW_STATUS = _exports.TRIP_NOSHOWREQUESTED_STATUS = _exports.TRIP_LATE_CANCELED_STATUS = _exports.TRIP_LATECANCELED_STATUS = _exports.TRIP_ILLEGAL_STATUS = _exports.TRIP_FAILED_STATUS = _exports.TRIP_ENROUTE_STATUS = _exports.TRIP_DISPATCH_STATUS = _exports.TRIP_COMPLETED_STATUS = _exports.TRIP_CANCELED_STATUS = _exports.TRIP_CALLBACK_STATUS = _exports.TRIP_ARCHIVED_STATUS = _exports.TRIP_ACTIVE_STATUS = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  // TRIP STATUS Notes
  // Requested :  New booking (eligibility verified) and Client (NYCT) agrees to fulfil the trip.
  // Promised :  time Promised (not scheduled) using Demand Smoother.
  // Scheduled : Time Promised and Assigned to Route & Provider (Service Mode / booking with eHail API)
  // Waitlisted : Trip could not be assigned to a route  - either it failed auto scheduling or dispatcher manually Waitlist-ed a Trip.
  // Dispatched : Route on which Trip was assigned now assigned to Vehicle / Driver
  // En route : Trip's route has started but the vehicle is not yet at the trip's pick up stop. Trip not started. (OTW to perform a pick)
  // Active : Pick performed
  // Completed : Drop performed
  // No showed  : Trip's pickup stop was saved as no-show
  // No show requested: driver has requested that dispatch save trip as noshow
  // Callback : More info needed from the passenger to confirm the Booking. Booking is saved and results in a Trip but not Scheduled yet.
  // Will call : Booking failed to be acknowledged and no Promise time added. Trip is saved in IQ but not yet scheduled. an orphaned booking.
  // Failed : Route or Trip failed for other reasons. Can be system failure. Need System Error codes to define this Status further.
  // Canceled : Trip was canceled
  // Late canceled : Trip was cancelled late (as per config definition)
  // Archived: TBD Driver/dispatcher has ended the trip's route.
  // Illegal : no vehicle to service the trip or Max Ride time violated or Leg of A (was on Voucher first or Broker, and then comes back to ADEPT to schedule) forcing promise time of Leg B to be violated
  // The following trip statuses will be displayed in IQ for version R4.x:
  // Scheduled: trip has a route ID and is not completed. In R4.x version only scheduled trips are exported from ADEPT 4.
  // Waitlisted: trip has no route ID
  // Active: the trip's pick stop has been arrived and departed
  // Completed: the trip's drop stop has been arrived and departed
  // Canceled: Trip was canceled. In R4.x version the trip must be cancelled in ADEPT 4 and then the status is sent to IQ.
  // Illegal: engine tried but was unable to schedule the trip.
  const convertStatusDisplay = {
    scheduled: 'Scheduled',
    requested: 'Requested',
    promised: 'Promised',
    dispatched: 'Dispatched',
    active: 'Active',
    completed: 'Completed',
    callback: 'Callback',
    willCall: 'Will call',
    failed: 'Failed',
    canceled: 'Canceled',
    cancelled: 'Canceled',
    lateCancelled: 'Late Cancelled',
    archived: 'Archived',
    unscheduled: 'Unscheduled',
    waitlisted: 'Waitlisted',
    illegal: 'Illegal',
    noShowRequest: 'No Show Request',
    noShow: 'No Show',
    'No Show': 'No Show',
    'En Route': 'En Route',
    'A': 'Active',
    'C': 'Completed'
  }; // Trip status coming from iqux service based on route exec events

  const TRIP_CANCELED_STATUS = 'Canceled';
  _exports.TRIP_CANCELED_STATUS = TRIP_CANCELED_STATUS;
  const TRIP_LATE_CANCELED_STATUS = 'Late canceled';
  _exports.TRIP_LATE_CANCELED_STATUS = TRIP_LATE_CANCELED_STATUS;
  const TRIP_WAITLIST_STATUS = 'Waitlisted';
  _exports.TRIP_WAITLIST_STATUS = TRIP_WAITLIST_STATUS;
  const TRIP_NOSHOW_STATUS = 'No showed';
  _exports.TRIP_NOSHOW_STATUS = TRIP_NOSHOW_STATUS;
  const TRIP_SCHEDULED_STATUS = 'Scheduled';
  _exports.TRIP_SCHEDULED_STATUS = TRIP_SCHEDULED_STATUS;
  const TRIP_UNSCHEDULED_STATUS = 'Unscheduled';
  _exports.TRIP_UNSCHEDULED_STATUS = TRIP_UNSCHEDULED_STATUS;
  const TRIP_ILLEGAL_STATUS = 'Illegal';
  _exports.TRIP_ILLEGAL_STATUS = TRIP_ILLEGAL_STATUS;
  const TRIP_ENROUTE_STATUS = 'En route';
  _exports.TRIP_ENROUTE_STATUS = TRIP_ENROUTE_STATUS;
  const TRIP_ACTIVE_STATUS = 'Active';
  _exports.TRIP_ACTIVE_STATUS = TRIP_ACTIVE_STATUS;
  const TRIP_COMPLETED_STATUS = 'Completed';
  _exports.TRIP_COMPLETED_STATUS = TRIP_COMPLETED_STATUS;
  const TRIP_NOSHOWREQUESTED_STATUS = 'No show requested';
  _exports.TRIP_NOSHOWREQUESTED_STATUS = TRIP_NOSHOWREQUESTED_STATUS;
  const TRIP_CALLBACK_STATUS = 'Callback';
  _exports.TRIP_CALLBACK_STATUS = TRIP_CALLBACK_STATUS;
  const TRIP_WILLCALL_STATUS = 'Will call';
  _exports.TRIP_WILLCALL_STATUS = TRIP_WILLCALL_STATUS;
  const TRIP_FAILED_STATUS = 'Failed';
  _exports.TRIP_FAILED_STATUS = TRIP_FAILED_STATUS;
  const TRIP_LATECANCELED_STATUS = 'Late canceled';
  _exports.TRIP_LATECANCELED_STATUS = TRIP_LATECANCELED_STATUS;
  const TRIP_ARCHIVED_STATUS = 'Archived';
  _exports.TRIP_ARCHIVED_STATUS = TRIP_ARCHIVED_STATUS;
  const TRIP_DISPATCH_STATUS = 'Dispatched';
  _exports.TRIP_DISPATCH_STATUS = TRIP_DISPATCH_STATUS;
  const TRIP_REQUESTED_STATUS = 'Requested'; // Trip status coming from scheduling trip model

  _exports.TRIP_REQUESTED_STATUS = TRIP_REQUESTED_STATUS;
  const SCHEDULING_TRIP_COMPLETED_STATUS = 'completed';
  const SCHEDULING_TRIP_SCHEDULED_STATUS = 'scheduled';
  const UNSCHEDULING_TRIP_SCHEDULED_STATUS = 'unscheduled';
  const SCHEDULING_TRIP_WAITLISTED_STATUS = 'waitlisted';
  const SCHEDULING_TRIP_NOSHOW_STATUS = 'noShow';
  const SCHEDULING_TRIP_ILLEGAL_STATUS = 'illegal';
  const SCHEDULING_TRIP_CALLBACK_STATUS = 'callback';
  const MANUAL_SCHEDULING_MODE = 'manual';

  var _default = Model.extend(_otpLabel.default, {
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tripId: attr('string'),
    anchor: attr('string'),
    routeId: attr('number'),
    routeName: attr('string'),
    dropOffAddress: attr('string'),
    pickUpAddress: attr('string'),
    serviceStartTime: attr('date'),
    serviceEndTime: attr('date'),
    promiseTime: attr('date'),
    requestTime: attr('date'),
    status: attr('string'),
    // 	'unscheduled', 'scheduled', 'waitlisted', 'illegal'
    traits: attr('string'),
    capacityRequirements: attr('string'),
    otp: attr('string'),
    schedulingMode: attr('string'),
    statusReason: attr('string'),
    pickId: attr('number'),
    dropId: attr('number'),
    otpValue: attr('number'),
    plannedDirectDistance: attr('number'),
    currentProviderName: attr('string'),
    schedule: belongsTo('schedule'),
    route: belongsTo('route'),
    subscription: belongsTo('subscription'),
    booking: belongsTo('booking'),
    segment: belongsTo('segment'),
    reason: belongsTo('no-show-reason-category'),
    pick: belongsTo('trip-stop', {
      inverse: 'pickTripStop'
    }),
    drop: belongsTo('trip-stop', {
      inverse: 'dropTripStop'
    }),
    //stopPoints: hasMany('stop-point'),
    routeAssignments: hasMany('trip-route-assignment'),
    provider: belongsTo('provider'),
    // populated by trips widget iqux service
    noShowRequestReasonCode: attr('string'),
    paymentType: attr('string'),
    fare: attr('number'),
    maxRideTime: attr('number'),
    failedStatus: attr('string'),
    tripStatus: Ember.computed('status', function () {
      const status = this.get('status') || '';
      return status.charAt(0).toUpperCase() + status.slice(1);
    }),
    rider: Ember.computed.alias('segment.leg.rider'),
    dispatchRoute: Ember.computed.alias('pickStopPoint.dispatchRoute'),
    travelNeedCounts: Ember.computed.readOnly('segment.leg.travelNeedCounts'),
    vehicleCapacityCounts: Ember.computed.readOnly('segment.leg.vehicleCapacityCounts'),
    isCompleted: Ember.computed.equal('status', SCHEDULING_TRIP_COMPLETED_STATUS),
    isScheduled: Ember.computed.equal('status', SCHEDULING_TRIP_SCHEDULED_STATUS),
    isUnScheduledTrip: Ember.computed.equal('status', UNSCHEDULING_TRIP_SCHEDULED_STATUS),
    isWaitlisted: Ember.computed.equal('status', SCHEDULING_TRIP_WAITLISTED_STATUS),
    isNoShow: Ember.computed.equal('status', SCHEDULING_TRIP_NOSHOW_STATUS),
    isIllegal: Ember.computed.equal('status', SCHEDULING_TRIP_ILLEGAL_STATUS),
    isCallback: Ember.computed.equal('status', SCHEDULING_TRIP_CALLBACK_STATUS),
    isManualSchedulingMode: Ember.computed.equal('schedulingMode', MANUAL_SCHEDULING_MODE),
    isPickPerformed: Ember.computed.readOnly('pickStopPoint.isPerformed'),
    isDropPerformed: Ember.computed.readOnly('dropStopPoint.isPerformed'),
    stops: Ember.computed('pick', 'drop', function () {
      const pick = (0, _unwrapProxy.unwrapProxy)(this.get('pick'));
      const drop = (0, _unwrapProxy.unwrapProxy)(this.get('drop'));
      return [pick, drop];
    }),
    polyline: Ember.computed('pick.{lat,lng}', 'drop.{lat,lng}', function () {
      // Use stop points to generate polyline
      const dropStopPoint = this.get('drop');
      const pickStopPoint = this.get('pick');

      if ([dropStopPoint, pickStopPoint].any(Ember.isNone)) {
        return null;
      }

      const pickLat = pickStopPoint.get('lat');
      const pickLng = pickStopPoint.get('lng');
      const dropLat = dropStopPoint.get('lat');
      const dropLng = dropStopPoint.get('lng');

      if ([pickLat, pickLng, dropLat, dropLng].any(Ember.isNone)) {
        return null;
      }

      return [[dropLat, dropLng], [pickLat, pickLng]];
    }),
    pickStopPoint: Ember.computed('stopPoints', function () {
      return this.get('stopPoints').findBy('stopType', 'pick');
    }),
    dropStopPoint: Ember.computed('stopPoints', function () {
      return this.get('stopPoints').findBy('stopType', 'drop');
    }),
    formattedTravelNeeds: Ember.computed('segment.leg.legTravelNeeds.[]', function () {
      const equipments = this.get('segment.leg.legTravelNeeds');
      const travelNeedsJson = {};
      let travelNeeds = [];

      if (!Ember.isEmpty(equipments)) {
        equipments.forEach(function (item) {
          const count = item.get('count');
          const name = item.get('travelNeedType.name');
          const displayName = item.get('travelNeedType.displayName');
          if (travelNeedsJson[name]) travelNeedsJson[name].count += count;else travelNeedsJson[name] = {
            count,
            displayName
          };
        });
        travelNeeds = Object.keys(travelNeedsJson).map(item => {
          if (travelNeedsJson[item].count > 0) {
            return (0, _formatTextExtension.formatTextExtension)('valueItem', `${travelNeedsJson[item].count} ${travelNeedsJson[item].displayName} `);
          }

          return (0, _formatTextExtension.formatTextExtension)('valueItem', `${travelNeedsJson[item].displayName} `);
        });
      }

      travelNeeds.push((0, _formatTextExtension.formatTextExtension)('mainItem', ''));
      return {
        values: travelNeeds
      };
    }),
    formattedServiceNeeds: Ember.computed('segment.leg.legServiceNeeds.[]', function () {
      const legServiceNeeds = this.get('segment.leg.legServiceNeeds');
      const serviceNeedsJson = {};
      let serviceNeeds = [];

      if (!Ember.isEmpty(legServiceNeeds)) {
        legServiceNeeds.forEach(function (item) {
          const count = 1;
          const name = item.get('serviceNeedType.name');
          const displayName = item.get('serviceNeedType.displayName');
          if (serviceNeedsJson[name]) serviceNeedsJson[name].count += count;else serviceNeedsJson[name] = {
            count,
            displayName
          };
        });
        serviceNeeds = Object.keys(serviceNeedsJson).map(item => {
          if (serviceNeedsJson[item].count > 1) {
            return (0, _formatTextExtension.formatTextExtension)('valueItem', `${serviceNeedsJson[item].count} ${serviceNeedsJson[item].displayName} `);
          }

          return (0, _formatTextExtension.formatTextExtension)('valueItem', ` ${serviceNeedsJson[item].displayName} `);
        });
      }

      serviceNeeds.push((0, _formatTextExtension.formatTextExtension)('mainItem', ''));
      return {
        values: serviceNeeds
      };
    }),
    noOfTravelNeeds: Ember.computed.alias('formattedTravelNeeds.length'),
    // currently status is a lowercase string
    // @TODO remove capitalize once status are more standardized
    mapId: Ember.computed('computedStatus', function () {
      const status = this.get('computedStatus');
      if (Ember.isPresent(status)) return Ember.String.capitalize(status);
      return null;
    }),
    // Used in maps for display and mapId
    computedStatus: Ember.computed('status', function () {
      const status = this.get('status') ? this.get('status').toLowerCase() : this.get('status');
      const result = convertStatusDisplay[status];
      return result ? result : '';
    })
  });

  _exports.default = _default;
});