define("adept-iq/pods/components/rider-management-widget/rider-eligibility/eligibility-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'rider-management-widget/rider-eligibility',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'rider-eligibility',
    rowSelectionType: 'radio',
    defaultSortAsc: false,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Service Reason Eligibility',
    columns: [{
      id: 'Type',
      index: 0,
      type: 'text',
      isKey: true,
      label: 'Eligibility Category',
      valuePath: 'eligibilityCategory.displayName',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 250
    }, {
      id: 'StartDate',
      index: 2,
      type: 'date',
      label: 'StartDate',
      valuePath: 'from',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'EndDate',
      index: 3,
      type: 'date',
      label: 'EndDate',
      valuePath: 'to',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }]
  };
  _exports.default = _default;
});