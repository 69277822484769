define("adept-iq/serializers/rider", ["exports", "adept-iq/serializers/-rms-riderManagement", "moment"], function (_exports, _rmsRiderManagement, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {
      externalId: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (!Ember.isNone(json.data.attributes.dateOfBirth)) {
        json.data.attributes.dateOfBirth = (0, _moment.default)(json.data.attributes.dateOfBirth).format('YYYY-MM-DD');
      }

      if (Ember.isEmpty(json.data.attributes.phoneNotification)) {
        delete json.data.attributes.phoneNotification;
      }

      if (Ember.isEmpty(json.data.attributes.emailNotification)) {
        delete json.data.attributes.emailNotification;
      }

      if (Ember.isEmpty(json.data.attributes.smsNotification)) {
        delete json.data.attributes.smsNotification;
      }

      if (!Ember.isNone(json.data.relationships)) {
        const fareCategoryRelationship = json.data.relationships.fareCategory;

        if (!Ember.isNone(fareCategoryRelationship)) {
          fareCategoryRelationship.data.type = 'fareCategoryName';
          json.data.relationships.fareCategoryName = json.data.relationships.fareCategory;
          delete json.data.relationships.fareCategory;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.thumbnail)) {
        // convert to the format we saved it because endpoint changes it to a different format from database
        const thumbnail = resourceHash.attributes.thumbnail;
        const data = thumbnail ? String.fromCharCode(...thumbnail.data) : null;

        if (!Ember.isNone(data)) {
          resourceHash.attributes.thumbnail = data;
        }
      }

      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.dateOfBirth)) {
        resourceHash.attributes.dateOfBirth = (0, _moment.default)(resourceHash.attributes.dateOfBirth).format();
      }

      if (resourceHash.relationships && resourceHash.relationships.phoneNumbers) {
        resourceHash.relationships.riderPhoneNumbers = resourceHash.relationships.phoneNumbers;
        delete resourceHash.relationships.phoneNumbers;
      }

      if (resourceHash.relationships && resourceHash.relationships.eligibilities) {
        resourceHash.relationships.riderEligibilities = resourceHash.relationships.eligibilities;
        delete resourceHash.relationships.eligibilities;
      }

      if (resourceHash.relationships && resourceHash.relationships.suspensions) {
        resourceHash.relationships.riderSuspensions = resourceHash.relationships.suspensions;
        delete resourceHash.relationships.suspensions;
      }

      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.fareCategoryName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.fareCategory = {
          data: {
            type: 'fare-category',
            id: resourceHash.relationships.fareCategoryName.data.id
          }
        };
        delete resourceHash.relationships.fareCategoryName;
      }

      return this._super(...arguments);
    },

    normalizeResponse(store, primaryModelClass, payload) {
      // convert these types name because of changes to api services
      // names.
      if (!Ember.isNone(payload.included) && !Ember.isNone(payload.included.length)) {
        payload.included = payload.included.filter(hash => hash.type !== 'fundingAgencyName');

        for (let i = 0; i < payload.included.length; i++) {
          const include = payload.included[i];

          if (include.type === 'travelNeedTypeName') {
            include.type = 'travelNeedType';
          }

          if (include.type === 'passengerTypeName') {
            include.type = 'passengerType';
          }

          if (include.type === 'eligibilityCategoryName') {
            include.type = 'eligibilityCategory';
          }

          if (include.type === 'riderPlaceTypeName') {
            include.type = 'riderPlaceType';
          }

          if (include.type === 'riderContactInfo') {
            if (include.attributes.contactType) {
              include.attributes.contactType = include.attributes.contactType.name;
            }
          }

          if (include.type === 'fareCategory' || include.type === 'fareCategoryName' || include.type === 'cs-fare-category') {
            include.type = 'fare-category';
          }

          if (include.type === 'serviceNeedTypeName') {
            include.type = 'serviceNeedType';
          }
        }
      }

      this._super(...arguments); // future refactore required to bring config naming consistent to the convention.
      // re-transformation needed, after line above fareCategory becomes cs-fare-category, so the following is needed
      // to transform the type back to fare-category


      if (!Ember.isNone(payload.included) && !Ember.isNone(payload.included.length)) {
        for (let i = 0; i < payload.included.length; i++) {
          const include = payload.included[i];

          if (include.type === 'fareCategory' || include.type === 'fareCategoryName' || include.type === 'cs-fare-category') {
            include.type = 'fare-category';
          }

          if (include.type === 'cs-service-need-type') {
            include.type = 'service-need-type';
          }
        }
      }

      return payload;
    }

  });

  _exports.default = _default;
});