define("adept-iq/pods/components/iq-widgets/stops-widget/scheduleConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/stops-widget',
    rowComponent: 'iq-widgets/stops-widget/stops-row',
    modelName: 'trip-stop',
    defaultSortId: 'ordinal',
    defaultSortAsc: true,
    title: 'Stops',
    // ember-react-widget properties
    widgetName: 'stopWidget',
    selectedRowLimit: 100,
    maxLimitByRouteSelection: 5,
    maxLimitWithoutRouteAssociation: 20,
    restrictSwapColumns: false,
    columns: [{
      id: 'vehicleId',
      index: 1,
      type: 'uuid',
      label: 'Vehicle ID',
      valuePath: 'routeName',
      valuePreview: '1',
      hidden: false,
      isMapLabelVisible: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'ordinal',
      index: 2,
      type: 'number',
      label: 'Ordinal',
      valuePath: 'plannedRouteOrdinal',
      searchable: true,
      hidden: false,
      defaultWidth: 70
    }, {
      id: 'jobId',
      index: 3,
      type: 'uuid',
      label: 'Job ID',
      valuePath: 'tripId',
      editable: true,
      defaultWidth: 100,
      highlightable: true,
      hidden: false
    }, {
      id: 'eta',
      index: 4,
      type: 'time',
      label: 'ETA',
      valuePath: 'plannedEta',
      format: 'HH:mm',
      hidden: false,
      defaultWidth: 50,
      highlightable: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'type',
      index: 5,
      type: 'text',
      label: 'Type',
      valuePath: 'stopTypeDisplay',
      searchable: false,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'timeToNext',
      index: 6,
      type: 'number',
      label: 'Time To Next',
      valuePath: 'plannedTravelTimeToNext',
      hidden: false,
      defaultWidth: 100,
      highlightable: true
    }, {
      id: 'distanceToNext',
      index: 7,
      type: 'number',
      label: 'Distance To Next',
      valuePath: 'plannedDistanceToNext',
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'waitTime',
      index: 8,
      type: 'number',
      label: 'Wait Time',
      valuePath: 'dwell',
      searchable: false,
      hidden: false,
      defaultWidth: 100
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'id'
        }]
      },
      routeWidget: {
        links: [{
          type: 'eq',
          field: 'routeId',
          valuePath: 'id'
        }]
      }
    }
  };
  _exports.default = _default;
});