define("adept-iq/pods/components/iq-widgets/new-booking-form/travel-needs/component", ["exports", "ember-concurrency", "adept-iq/config/mapped-permIds", "adept-iq/models/travel-need-type"], function (_exports, _emberConcurrency, _mappedPermIds, _travelNeedType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const travelNeedsToFilter = [];
  const MAX_LOAD_TIME = 15;
  const ELIGIBLE_TRAVEL_NEEDS_FOR_OVERRIDE = ['wheelchair', 'liftRequired'];

  var _default = Ember.Component.extend({
    classNames: ['travel-needs-form'],
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    fareCalc: Ember.inject.service(),
    travelNeedTypes: null,
    passengerTypes: null,
    ambulatoryCount: 1,
    countPath: 'count',
    pcaTravelNeed: null,
    travelNeedTypePath: 'travelNeedType',
    previousSelectedCompanionCount: 0,
    travelNeedTypeOptions: null,
    // need to populate this,
    totalRidersCount: 0,
    bigSeats: null,
    editLoadUnloadBookingGranted: false,
    disableLoadAndUnloadTimeEditing: Ember.computed('booking.isOpenErrorModal', 'isFieldDisabled', function () {
      return this.get('booking.isOpenErrorModal') || this.get('isFieldDisabled');
    }),
    isAllLegs: Ember.computed.alias('booking.isAllLegs'),
    isShowSpecialPermission: false,
    haveSpecialPermission: false,
    specialPermissionTitle: 'Booking - Wheelchair Override',
    bodyMessage: 'Override privileges are required to assign a lift and/or wheelchair to a trip for a client that is not approved for this equipment.',
    overrideWheelchairRequest: _mappedPermIds.default.overrideWheelchairRequest,
    overrideTravelNeed: null,
    isFieldDisabled: Ember.computed('disableRequestDate', 'isEditingUnscheduledBooking', function () {
      return this.get('disableRequestDate') || this.get('booking.isEditingUnscheduledBooking');
    }),
    // Reg-NYAAR-18969 - Based on the AllLegs checked
    travelNeedLegs: Ember.computed('isAllLegs', function () {
      const isAllLegs = this.get('isAllLegs');
      const legs = this.get('legs');

      if (isAllLegs) {
        return [legs.firstObject];
      }

      return legs;
    }),

    init() {
      this._super(...arguments);

      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const passengerTypes = this.store.peekAll('passenger-type');
      const maximumCompanionConfig = this.store.peekRecord('cs-config-item', 'config-Book_Trips/maximum_companions');
      const maximumCompanionValue = maximumCompanionConfig ? parseInt(maximumCompanionConfig.get('value'), 10) : 1;
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const companionArray = [...Array(maximumCompanionValue + 1).keys()];
      this.set('booking.travelNeedItems', travelNeedTypes);
      this.setProperties({
        companionOptions: companionArray,
        bigSeats: bigSeatsTN.map(tr => tr.get('name').toUpperCase()),
        passengerTypes,
        travelNeedTypes,
        travelNeedTypeOptions: travelNeedTypes,
        totalRidersCount: this.get('booking.selectedRiders').length
      });
      this.getAndSetCompanionTravelNeed();
      this.countDisabledObserver();
      this.validateLoadAndUnLoadTimeToBeNumber();
      this.validateLoadAndUnLoadTime();
      this.loadTimeValue();
      this.unloadTimeValue();
      this.set('editLoadUnloadBookingGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editLoadUnloadBooking, null));
    },

    didInsertElement() {
      this._super(...arguments);

      this.addCompanionAmbulatoryIfAbsent();
    },

    countDisabledObserver: Ember.observer('legs.@each.legTravelNeeds', 'legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      this.get('legs').forEach(leg => {
        leg.get('legTravelNeeds').forEach(legTravelNeed => {
          this.setTravelNeedTypeCount(legTravelNeed);
        });
      });
    }),

    addCompanionAmbulatoryIfAbsent() {
      this.get('legs').forEach(leg => {
        const ambulatory = leg.get('legTravelNeeds').find(travelNeed => {
          return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
        });

        if (!ambulatory) {
          const ambulatoryTravelNeedType = this.store.peekAll('travel-need-type').find(tntype => {
            return tntype.get('name').toUpperCase() === 'AMBULATORY';
          });

          if (ambulatoryTravelNeedType) {
            const ambulatoryTravelNeedRecord = this.store.createRecord('leg-travel-need', {
              count: 0,
              travelNeedType: ambulatoryTravelNeedType,
              passengerType: this.store.peekRecord('passenger-type', 'client')
            });
            leg.get('legTravelNeeds').pushObject(ambulatoryTravelNeedRecord);
          }
        }
      });
    },

    // get wheelchair count for single and multiple riders NYAAR-11938
    getAndSetWheelChairSeatCount() {
      let wheelChairCount = 0;
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'wheelchair') {
            wheelChairCount += riderTravelNeed.cnt;
          }
        });
      });
      this.get('legs').forEach(leg => {
        const wheelChairTravelNeed = leg.get('legTravelNeeds').find(travelNeed => {
          return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
        });

        if (wheelChairTravelNeed) {
          wheelChairTravelNeed.set('count', wheelChairCount);
        }
      });
    },

    getAndSetCompanionTravelNeed() {
      this.get('legs').forEach(leg => {
        const companionTravelNeed = this.getCompanionTravelNeed(leg);

        if (companionTravelNeed) {
          const companionCount = companionTravelNeed.get('count');
          this.get('booking').setCompanionCount(companionCount);
        }
      });
    },

    loadTimeValue: Ember.observer('legs.@each.loadTime', function () {
      this.get('legs').forEach(leg => {
        //converting the loadTime to seconds
        const loadTime = leg.get('loadTime') * 60;
        const segments = leg.get('segments');
        segments.forEach(segment => {
          segment.set('pick.dwell', loadTime);
        });
      });
    }),
    unloadTimeValue: Ember.observer('legs.@each.unloadTime', function () {
      this.get('legs').forEach(leg => {
        //converting the unloadTime to seconds
        const unloadTime = leg.get('unloadTime') * 60;
        const segments = leg.get('segments');
        segments.forEach(segment => {
          segment.set('drop.dwell', unloadTime);
        });
      });
    }),
    pcaNotEligible: Ember.computed('booking.selectedRiders.@each.{travelNeeds}', function () {
      const totalPcaEligibleRiders = this.getNoOfPcaEligibleRiders();
      const notEligible = totalPcaEligibleRiders === 0;
      return notEligible;
    }),
    pcaStateChecked: Ember.computed('booking.selectedRiders.@each.{travelNeeds}', function () {
      const totalPcaRider = this.getNoOfPcaEligibleRiders();
      const checked = totalPcaRider > 0;
      return checked;
    }),
    availableTravelNeedTypes: Ember.computed('legs.@each.legTravelNeeds.[]', function () {
      const allTravelTypes = this.store.peekAll('travel-need-type');
      return this.get('legs').map(leg => {
        const existingTravelNeedsTypes = leg.get('legTravelNeeds').map(travelNeed => travelNeed.get('travelNeedTypeNameUppercase'));
        const travelNeedTypesOptionsThatShouldNotBeInOptions = existingTravelNeedsTypes.filter(tr => tr).concat(travelNeedsToFilter);
        const filteredArray = allTravelTypes.filter(travelType => {
          return travelType.name && !travelNeedTypesOptionsThatShouldNotBeInOptions.includes(travelType.name.toUpperCase()) || travelType.name && this.get('bigSeats').includes(travelType.name.toUpperCase());
        });
        return filteredArray;
      });
    }),
    validateLoadAndUnLoadTime: Ember.observer('legs.@each.loadTime', 'legs.@each.unloadTime', function () {
      this.get('legs').forEach((leg, index) => {
        const loadTime = leg.get('loadTime');
        const unLoadTime = leg.get('unloadTime');

        if (loadTime > MAX_LOAD_TIME || unLoadTime > MAX_LOAD_TIME) {
          this.get('setLoadAndUnLoadTimeFlag')(false);
          this.get('booking').showErrorMessage(`Load/Unload time value must be 15 min or less in Leg Trip ${index + 1}.`);
        } else {
          this.get('setLoadAndUnLoadTimeFlag')(true);
        }
      });
    }),
    validateLoadAndUnLoadTimeToBeNumber: Ember.observer('legs.@each.loadTime', 'legs.@each.unloadTime', function () {
      this.get('legs').forEach((leg, index) => {
        const loadTime = leg.get('loadTime');
        const unLoadTime = leg.get('unloadTime');
        const isLoadUnloadTimesNumber = this.get('booking').isNumber(loadTime) && this.get('booking').isNumber(unLoadTime);

        if (isLoadUnloadTimesNumber) {
          this.get('setIsLoadAndUnLoadTimeNumberFlag')(true);
        } else {
          this.get('setIsLoadAndUnLoadTimeNumberFlag')(false);
          this.get('booking').showErrorMessage(`Please enter valid numbers for load/unload time in Leg Trip ${index + 1}.`);
        }
      });
    }),
    //NYAAR-12930  verify if pca and serviceAnimal are present in the config item
    isPcaPresentInConfig: Ember.computed('legTravelNeeds.[]', function () {
      return this.get('booking').isCheckPca();
    }),

    /**
     * NYAAR-18694 Travel needs count are not getting updated on adding new travel needs after doing undo on Create Booking
     * @param travelNeed
     */
    setTravelNeedTypeCount(travelNeed) {
      const isEditMode = this.get('isEditMode');
      const isConsumable = travelNeed.get('travelNeedType.isConsumable');
      let isCountDisabled = true;

      if (!isEditMode && isConsumable) {
        isCountDisabled = false;
      }

      travelNeed.set('isCountDisabled', isCountDisabled);
    },

    getNoOfPcaEligibleRiders() {
      const pcaEligibleRiders = [];
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'pca') {
            pcaEligibleRiders.push(rider);
          }
        });
      });
      return pcaEligibleRiders.length;
    },

    getCompanionTravelNeed(leg) {
      return leg.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'COMPANION';
      });
    },

    getPermission: (0, _emberConcurrency.task)(function* () {
      this.set('isShowSpecialPermission', true);
      yield (0, _emberConcurrency.waitForProperty)(this, 'isShowSpecialPermission', p => p === false);
    }),

    // check for vehicle capacity type of wheelchair and lift required travel needs only
    async isTravelNeedTypeAllowed(travelNeedType) {
      const overrideWheelchairRequestGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideWheelchairRequest, null);
      const riderTravelNeeds = this.get('riderTravelNeeds');
      const travelNeedTypeId = travelNeedType.get('vehicleCapacityType.id');
      const needsValidation = [_travelNeedType.LIFTREQUIRED_TRAVELNEED_TYPE, _travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE].includes(travelNeedTypeId); // we don't require validation for other travel need types except for liftRequired and wheelchair

      if (!needsValidation) {
        return true;
      }

      const existInRiderTravelNeeds = riderTravelNeeds.filter(riderTravelNeed => riderTravelNeed.get('vehicleCapacityType.id') === travelNeedTypeId); // exist in the rider's travel needs

      if (existInRiderTravelNeeds.length > 0) {
        return true;
      } // does not exist in rider's travel needs but user have overrideWheelchairRequest permission


      if (overrideWheelchairRequestGranted) {
        return true;
      } // get permission from another user


      await this.get('getPermission').perform();
      return this.get('haveSpecialPermission');
    },

    async changeTravelNeedType(travelNeed, travelNeedType) {
      const travelNeedTypePath = this.get('travelNeedTypePath');
      const isTravelNeedTypeAllowed = await this.isTravelNeedTypeAllowed(travelNeedType);

      if (isTravelNeedTypeAllowed) {
        travelNeed.set(travelNeedTypePath, travelNeedType);
      }
    },

    //set the overrided travel need name
    setTravelNeedNameForOverride(travelNeedType) {
      if (ELIGIBLE_TRAVEL_NEEDS_FOR_OVERRIDE.includes(travelNeedType.name)) {
        this.set('overrideTravelNeed', travelNeedType.name);
      }
    },

    actions: {
      onTravelNeedCountChange(travelNeed, value) {
        travelNeed.set('count', value);
        this.get('updateFare')();
      },

      async onTravelNeedChange(travelNeed, travelNeedType) {
        this.setTravelNeedNameForOverride(travelNeedType);
        await this.changeTravelNeedType(travelNeed, travelNeedType);
        this.setTravelNeedTypeCount(travelNeed);
        this.get('updateFare')();
      },

      onLoadTimeChange(leg, loadTime) {
        const loadTimeInSeconds = loadTime * 60;
        const segments = leg.get('segments');
        segments.forEach(segment => {
          segment.set('pick.dwell', loadTimeInSeconds);
        });
      },

      onUnLoadTimeChange(leg, unloadTime) {
        const unloadTimeInSeconds = unloadTime * 60;
        const segments = leg.get('segments');
        segments.forEach(segment => {
          segment.set('drop.dwell', unloadTimeInSeconds);
        });
      },

      onAddTravelNeed(legIndex) {
        const store = this.get('store');
        const leg = this.get('legs').objectAt(legIndex);
        const newLegTravelNeed = store.createRecord('leg-travel-need', {
          count: 0,
          travelNeedType: null,
          passengerType: store.peekRecord('passenger-type', 'client')
        });
        leg.get('legTravelNeeds').pushObject(newLegTravelNeed);
        this.get('updateFare')();
      },

      onRemoveTravelNeed(legTravelNeed, legIndex) {
        const leg = this.get('legs').objectAt(legIndex);
        leg.get('legTravelNeeds').removeObject(legTravelNeed);
        this.notifyPropertyChange('legs.@each.legTravelNeeds.[]');
        this.get('updateFare')();
      },

      onAllLegsChange(event) {
        this.set('booking.isAllLegs', event.target.checked);
      }

    }
  });

  _exports.default = _default;
});