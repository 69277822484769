define("adept-iq/models/driver", ["exports", "ember-data", "moment", "adept-iq/utils/format-text-extension", "adept-iq/utils/workspace-availability", "adept-iq/config/environment"], function (_exports, _emberData, _moment, _formatTextExtension, _workspaceAvailability, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    workspace: Ember.inject.service(),
    driverId: attr('string'),
    firstName: attr('string'),
    middleName: attr('string'),
    lastName: attr('string'),
    notes: attr('string'),
    ssn: attr('string'),
    // social security number
    active: attr('boolean', {
      defaultValue: true
    }),
    placeholder: attr('boolean'),
    fingerprint: attr('boolean'),
    birthDate: attr('iso-date', {
      defaultValue: null
    }),
    startDateDs870: attr('iso-date', {
      defaultValue: null
    }),
    seniorityDate: attr('iso-date', {
      defaultValue: null
    }),
    transportPassenger: attr('string'),
    lastPhysical: attr('iso-date', {
      defaultValue: null
    }),
    peDate1: attr('iso-date', {
      defaultValue: null
    }),
    peDate2: attr('iso-date', {
      defaultValue: null
    }),
    // generated from IQUX-service
    rvdStartTime: attr('date'),
    rvdEndTime: attr('date'),
    rvdHistory: attr('object'),
    inProgress: attr('boolean'),
    routeStatus: attr('string'),
    isCompleted: attr('boolean', {
      defaultValue: false
    }),
    preferredVehicle: belongsTo('vehicle', {
      inverse: null
    }),
    vehicle: belongsTo('vehicle'),
    //Driver Look Up's
    driverPosition: belongsTo('driver-position-name'),
    driverPhysicalType: belongsTo('driver-physical-type-name'),
    cdlClassificationName: belongsTo('classification-name'),
    employmentStatus: belongsTo('employment-status-name'),
    driverTerminationReason: belongsTo('termination-reason-name'),
    provider: belongsTo('provider'),
    address: belongsTo('address'),
    license: belongsTo('driver-license'),
    driverHealths: hasMany('driver-health'),
    driverLogs: hasMany('driver-log'),
    driverProviders: hasMany('driver-provider'),
    driverAvailabilities: hasMany('driver-availability'),
    driverSuspensions: hasMany('driver-suspension'),
    driverAttributes: hasMany('driver-attribute'),
    phoneNumbers: hasMany('driver-phone-number'),
    routeVehicleDrivers: hasMany('route-vehicle-driver'),
    cannedMessages: hasMany('avlm-canned-message'),
    emergencyContacts: hasMany('driver-emergency-contact'),
    badgeNumber: Ember.computed.alias('driverId'),
    preferredVehicleName: Ember.computed.alias('preferredVehicle.name'),
    workspaceStartTime: Ember.computed.alias('workspace.startDate'),
    workspaceEndTime: Ember.computed.alias('workspace.endDate'),
    latestVehicle: Ember.computed.alias('latestRouteVehicleDriver.vehicle'),
    latestDsRoute: Ember.computed.alias('latestRouteVehicleDriver.route'),
    providerName: Ember.computed.alias('provider.id'),
    fullName: Ember.computed('firstName', 'middleName', 'lastName', function () {
      return `${Ember.get(this, 'firstName')} ${Ember.get(this, 'middleName') || ''} ${Ember.get(this, 'lastName')}`.replace(/\s+/, ' ');
    }),
    middleInitial: Ember.computed('middleName', function () {
      const middleName = this.get('middleName');
      if (!middleName || !middleName.length) return '';
      return middleName[0];
    }),
    latestRouteVehicleDriver: Ember.computed('routeVehicleDrivers.@each.startTime', function () {
      const routeVehicleDrivers = this.get('routeVehicleDrivers');
      if (!routeVehicleDrivers) return [];
      return routeVehicleDrivers.sortBy('startTime').get('lastObject');
    }),
    currentRouteVehicleDrivers: Ember.computed('routeVehicleDrivers.@each.startTime', 'workspaceStartTime', 'workspaceEndTime', function () {
      let routeVehicleDrivers = this.get('routeVehicleDrivers');
      const startTime = this.get('workspaceStartTime');
      const endTime = this.get('workspaceEndTime');
      if (!routeVehicleDrivers) return [];
      routeVehicleDrivers = routeVehicleDrivers.filter(routeVehicleDriver => (0, _workspaceAvailability.processTimeConstraints)(routeVehicleDriver.get('startTime'), routeVehicleDriver.get('endTime'), startTime, endTime));
      return routeVehicleDrivers;
    }),
    currentVehicle: Ember.computed.alias('currentDriverShift.vehicle'),
    // TODO: this also needs to depend on current time
    currentDriverShift: Ember.computed('routeVehicleDrivers.@each.route', function () {
      const now = (0, _moment.default)();
      const routeVehicleDrivers = Ember.get(this, 'routeVehicleDrivers');

      if (!Ember.isEmpty(routeVehicleDrivers)) {
        return routeVehicleDrivers.find(routeVehicleDriver => {
          const plannedStartTime = routeVehicleDriver.get('route.plannedStartTime');
          const plannedEndTime = routeVehicleDriver.get('route.plannedEndTime');
          return (0, _moment.default)(plannedEndTime).isSameOrAfter(now) && (0, _moment.default)(plannedStartTime).isSameOrBefore(now);
        });
      }

      return false;
    }),
    // used by breakdown replacement
    // volatile to make sure we get the correct availability
    // base on current time
    currentDriverAvailability: Ember.computed('driverAvailabilities.@each.{startTime,endTime}', function () {
      const now = (0, _moment.default)();
      const driverAvailabilities = this.get('driverAvailabilities');

      if (!Ember.isEmpty(driverAvailabilities)) {
        return driverAvailabilities.find(driverAvailability => {
          const plannedStartTime = driverAvailability.get('startTime');
          const plannedEndTime = driverAvailability.get('endTime');
          return (0, _moment.default)(plannedEndTime).isSameOrAfter(now) && (0, _moment.default)(plannedStartTime).isSameOrBefore(now);
        });
      }
    }).volatile(),

    isDriverAvailable(startTime, endTime) {
      let retVal = true;
      const driverShifts = Ember.get(this, 'driverShifts');

      if (!Ember.isEmpty(driverShifts)) {
        driverShifts.forEach(driverShift => {
          if (startTime.isSameOrAfter(driverShift.get('plannedStartTime')) && startTime.isSameOrBefore(driverShift.get('plannedEndTime')) || endTime.isSameOrAfter(driverShift.get('plannedStartTime')) && endTime.isSameOrBefore(driverShift.get('plannedEndTime'))) {
            retVal = false;
          }
        });
      }

      return retVal;
    },

    vehicleId: Ember.computed('currentDriverShift.vehicle.name', function () {
      return this.get('currentDriverShift.vehicle.name');
    }),
    routeId: Ember.computed('currentDriverShift.route.name', function () {
      return this.get('currentDriverShift.route.id');
    }),
    formattedAvailabilities: Ember.computed('driverAvailabilities.[]', 'active', function () {
      const active = this.get('active');
      const formattedObject = {
        header: null,
        values: []
      };
      const availabilities = [];
      const driverAvailabilities = this.get('driverAvailabilities');

      if (!Ember.isEmpty(driverAvailabilities)) {
        driverAvailabilities.forEach(function (item) {
          const startTime = (0, _moment.default)(item.get('startTime'));
          const endTime = (0, _moment.default)(item.get('endTime'));
          const now = (0, _moment.default)();

          if (active && now.isBetween(startTime, endTime)) {
            formattedObject.header = null;
            availabilities.push((0, _formatTextExtension.formatTextExtension)('mainItem', `${startTime.format(_environment.default.dateTimeFormat.dateMoment)} - ${endTime.format(_environment.default.dateTimeFormat.dateMoment)}`));

            if (item.monday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Monday ${item.formattedShiftStart} -${item.formattedShiftEnd}`));
            }

            if (item.tuesday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Tuesday  ${item.formattedShiftStart} -${item.formattedShiftEnd}`));
            }

            if (item.wednesday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Wednesday ${item.formattedShiftStart} -${item.formattedShiftEnd}`));
            }

            if (item.thursday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Thursday ${item.formattedShiftStart} -${item.formattedShiftEnd}`));
            }

            if (item.friday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Friday ${item.formattedShiftStart} -${item.formattedShiftEnd}`));
            }

            if (item.saturday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Saturday ${item.formattedShiftStart} -${item.formattedShiftEnd}`));
            }

            if (item.sunday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Sunday ${item.formattedShiftStart} -${item.formattedShiftEnd}`));
            }
          }

          formattedObject.values = availabilities;
        });
      }

      return formattedObject;
    }),
    formattedPhoneNumbers: Ember.computed('phoneNumbers.[]', function () {
      const formattedObject = {
        header: null,
        values: []
      };
      const phoneNumbers = [];
      const formattedPhoneNumber = this.get('phoneNumbers');

      if (!Ember.isEmpty(formattedPhoneNumber)) {
        formattedPhoneNumber.forEach(function (item) {
          formattedObject.header = item.type;
          phoneNumbers.push((0, _formatTextExtension.formatTextExtension)('mainItem', `${item.type}`));
          phoneNumbers.push((0, _formatTextExtension.formatTextExtension)('valueItem', item.get('formattedPhoneNumber')));
          formattedObject.values = phoneNumbers;
        });
      }

      return formattedObject;
    }),
    firstPhoneNumber: Ember.computed('formattedPhoneNumbers', function () {
      const formattedPhoneNumbers = this.get('formattedPhoneNumbers.values').filter(value => value.className === 'valueItem');

      if (!Ember.isEmpty(formattedPhoneNumbers)) {
        return formattedPhoneNumbers.get('firstObject.value');
      }
    }),
    phoneNumber: Ember.computed('phoneNumbers.[]', function () {
      return this.get('phoneNumbers.firstObject');
    })
  });

  _exports.default = _default;
});