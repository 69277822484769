define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reconcile: Ember.inject.service(),
    reconcileDate: Ember.computed.readOnly('reconcile.reconcileDate'),
    vehicleOptions: Ember.computed.alias('reconcile.reconcileVehicles'),
    routeId: null,
    canSelect: Ember.computed('routeId', function () {
      const routeId = Ember.get(this, 'routeId');
      if (routeId && routeId !== null) return true;
      return false;
    }),

    init() {
      this._super(...arguments);

      this.set('onRowClick', this.actions.onRowClick.bind(this));
    },

    actions: {
      onRowClick(selectedRow) {
        if (Ember.isEmpty(selectedRow)) return;
        Ember.set(this, 'routeId', null);
        this.notifyPropertyChange('routeId'); // for route payload

        const routeId = selectedRow.get('routeid');
        selectedRow.set('routeId', routeId);
        Ember.set(this, 'routeId', routeId); //set(this, 'selectedSearchedTrip', selectedRow);

        this.notifyPropertyChange('routeId');
      },

      async onSelectRouteClick() {
        const routeId = Ember.get(this, 'routeId');
        const reconcile = this.get('reconcile');
        await reconcile.fetchReconcileRouteInfo(routeId); // we need to send trackingId from the backend for ClientId, Address, Trip

        if (routeId) {
          reconcile.set('selectedSearchRoute', {
            'id': routeId,
            'routeId': routeId
          });
        }

        reconcile.toggleProperty('showSearchPopUp');
      },

      cancel() {
        Ember.set(this, 'selectedSearchMode', null);
        this.get('reconcile').toggleProperty('showSearchPopUp');
      }

    }
  });

  _exports.default = _default;
});