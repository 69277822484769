define("adept-iq/adapters/raas-api-call-record", ["exports", "adept-iq/adapters/-raasService"], function (_exports, _raasService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raasService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    urlForFindRecord(id) {
      const resouceName = 'raas-async-operation';
      const raasServiceHost = this.get('host');
      const baseUrl = `${raasServiceHost}/${resouceName}/${id}`;
      return baseUrl;
    },

    urlForFindAll() {
      const resourceName = 'raas-async-operation';
      const raasServiceHost = this.get('host');
      const baseUrl = `${raasServiceHost}/${resourceName}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});