define("adept-iq/pods/components/iq-widgets/routes-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'routeId',
        type: 'text',
        label: 'Route Id',
        valuePath: 'route.name',
        editable: false
      }]
    }, {
      title: 'Edit Route',
      fields: [{
        id: 'name',
        type: 'text',
        label: 'Route Name',
        valuePath: 'route.name',
        readonlyCell: true
      }, {
        id: 'date',
        type: 'date',
        label: 'Date',
        valuePath: 'route.plannedStartTime',
        format: 'Y-m-d',
        // date flatpickr uses a different formating than moment
        readonlyCell: true
      }, {
        id: 'start',
        type: 'datetimeflatpickr',
        label: 'Start Time',
        valuePath: 'latestRouteVehicleDriver.startTime',
        readonlyCell: false,
        hidden: false,
        extra: {
          routeStartFlag: 'routeStartFlag',
          minTimePath: 'plannedTimeMin',
          maxTimePath: 'firstStopMaxTime'
        }
      }, {
        id: 'end',
        type: 'datetimeflatpickr',
        label: 'End Time',
        editable: true,
        hidden: false,
        valuePath: 'latestRouteVehicleDriver.endTime',
        readonlyCell: false,
        extra: {
          minTimePath: 'lastStopTime',
          maxTimePath: 'plannedTimeMax'
        }
      }, {
        id: 'tripLimit',
        type: 'number',
        label: 'Trip Limit',
        valuePath: 'route.tripLimit',
        readonlyCell: false,
        editable: true,
        extra: {
          setLimit: true,
          min: 0,
          max: 10
        }
      }, {
        id: 'distanceLimit',
        type: 'number',
        label: 'Distance Limit',
        valuePath: 'route.distanceLimit',
        readonlyCell: false,
        editable: true,
        extra: {
          setLimit: true,
          min: 0,
          max: 100
        }
      }, {
        id: 'vehicleId',
        type: 'number',
        label: 'Vehicle ID',
        valuePath: 'assignedVehicle.name',
        readonlyCell: true,
        editable: true
      }, {
        id: 'driverId',
        type: 'text',
        label: 'Driver ID',
        valuePath: 'assignedDriver.driverId',
        editable: true,
        readonlyCell: true
      }]
    }]
  };
  _exports.default = _default;
});