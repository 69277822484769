define("adept-iq/pods/components/iq-widgets/fleet-manager-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NO_OP = () => {};

  var _default = Ember.Component.extend({
    classNames: ['fleet-manager-container'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    editAPI: null,
    vehicleRecord: null,
    selectedVehicleRecord: Ember.observer('tableRef.checkedRows.firstObject', function () {
      const tableRef = this.get('tableRef');
      if (!tableRef) return null;
      const record = tableRef.get('checkedRows.firstObject');
      return this.updateSelectedVehicleRecord(record);
    }),

    updateSelectedVehicleRecord() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (!record) {
        this.set('vehicleRecord', null);
        return null;
      }

      this.set('vehicleRecord', record);
      return record;
    },

    async init() {
      this._super(...arguments); // Initializing editAPI with bound actions and settings


      this.set('editAPI', Ember.Object.create({
        isNewBtnDisabled: false,
        isNewBtnFocused: false,
        isEditBtnDisabled: false,
        isDeleteBtnDisabled: false,
        isSaveBtnDisabled: true,
        isSaveBtnFocused: false,
        isUploadCSVBtnDisabled: false,
        isUndoBtnDisable: true,
        isEditing: false,
        isNewRecord: false,
        editAction: NO_OP,
        undoAction: NO_OP,
        deleteAction: NO_OP,
        saveAction: NO_OP,
        newAction: NO_OP
      }));
    },

    actions: {
      updateTableRef(tableRef) {
        this.set('tableRef', tableRef);
      },

      onXButtonClick() {
        this.get('workspace').popState('openFleetManagement');
      }

    }
  });

  _exports.default = _default;
});