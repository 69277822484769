define("adept-iq/pods/components/iq-widgets/passengers-booking-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/passengers-booking-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'rider-external',
    defaultSortId: 'passengerId',
    defaultSortAsc: false,
    hideWidgetControls: true,
    hideHeader: true,
    checkBoxColumn: true,
    isFormControls: true,
    title: 'Passengers',
    columns: [{
      id: 'passengerStatus',
      index: 0,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 50,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      },
      cellComponent: 'iq-widgets/passengers-booking-widget/active-passenger-cell'
    }, {
      id: 'passengerId',
      index: 1,
      type: 'uuid',
      isKey: true,
      label: 'Passenger ID',
      valuePath: 'riderId',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'passengerFirstName',
      index: 2,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'passengerLastName',
      index: 3,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 60,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'passengerMiddleName',
      index: 4,
      type: 'text',
      label: 'Middle Name',
      valuePath: 'middleName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 60,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'passengerPhoneNumber',
      index: 5,
      type: 'text',
      label: 'Phone Number',
      valuePath: 'formattedMainPhoneNumber',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 60,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'passcode',
      index: 6,
      type: 'text',
      label: 'Passcode',
      valuePath: 'passcode',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'passengerDateOfBirth',
      index: 7,
      type: 'date',
      label: 'DOB',
      valuePath: 'dateOfBirth',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 50,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'passengerEligibilityStart',
      index: 8,
      type: 'date',
      label: 'Eligibility Start',
      valuePath: 'eligibilityStart',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 80,
      format: 'MM/DD/YYYY'
    }, {
      id: 'passengerEligibilityEnd',
      index: 9,
      type: 'date',
      label: 'Eligibility End',
      valuePath: 'eligibilityEnd',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 80,
      format: 'MM/DD/YYYY'
    }]
  };
  _exports.default = _default;
});