define("adept-iq/models/metric", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    routes: attr('number'),
    illegalTrips: attr('number'),
    unusedRoutes: attr('number'),
    scheduledTrips: attr('number'),
    breakTrips: attr('number'),
    manualTrips: attr('number'),
    brokerTrips: attr('number'),
    waitlistedTrips: attr('number'),
    riders: attr('number'),
    revenueHours: attr('number'),
    revenueMiles: attr('number'),
    totalHours: attr('number'),
    totalMiles: attr('number'),
    percentageTripsRidesharing: attr('number'),
    percentageTimeSingle: attr('number'),
    percentageTimeRidesharing: attr('number'),
    percentageTimeEmpty: attr('number'),
    percentageTimeOnBreak: attr('number'),
    averageRideTimePerTrip: attr('number'),
    schedule: belongsTo('schedule'),
    userName: attr('string'),
    strategy: belongsTo('engine-strategy'),
    strategyName: Ember.computed('strategy.displayName', function () {
      return this.get('strategy.displayName') || 'N/A';
    }),
    unscheduledTrips: Ember.computed('breakTrips', 'manualTrips', 'brokerTrips', 'waitlistedTrips', 'illegalTrips', function () {
      const waitlistedTrips = this.get('waitlistedTrips') || 0;
      const illegalTrips = this.get('illegalTrips') || 0;
      return waitlistedTrips + illegalTrips;
    })
  });

  _exports.default = _default;
});