define("adept-iq/pods/components/iq-widgets/vehicles-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/vehicles-widget/config", "adept-iq/pods/components/iq-widgets/vehicles-widget/scheduleConfig", "adept-iq/pods/components/iq-widgets/vehicles-widget/avlmConfig", "lodash", "adept-iq/config/api-urls", "adept-iq/config/environment", "adept-iq/models/avlm-emergency-message", "adept-iq/config/mapped-permIds", "adept-iq/utils/vehicle-info", "adept-iq/utils/filters", "adept-iq/config/filter-types", "adept-iq/models/dashboard", "moment"], function (_exports, _component, _config, _scheduleConfig, _avlmConfig, _lodash, _apiUrls, _environment, _avlmEmergencyMessage, _mappedPermIds, _vehicleInfo, _filters, _filterTypes, _dashboard, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];
  const ROUTE_NAME = 'routeName';
  const DRIVER = 'driverId';
  const pathToModel = {
    'type': 'vehicle-type'
  };

  var _default = _component.default.extend({
    classNames: ['vehicles-widget'],
    classNameBindings: ['isActiveClass'],
    editModal: Ember.inject.service(),
    breakdownComponent: 'iq-widgets/vehicles-breakdown-form-widget',
    vehicleTypeDict: null,
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    user: Ember.inject.service(),
    workspace: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),
    loadVehicleTypes: function () {
      const vehicleTypes = this.store.peekAll('vehicleType');
      const vehicleTypeDict = {};
      vehicleTypes.forEach(vehicleType => {
        vehicleTypeDict[vehicleType.get('id')] = vehicleType;
      });
      this.set('vehicleTypeDict', vehicleTypeDict);
    },
    isActiveClass: Ember.computed('table.rows', 'table.columns.@each.{id}', function () {
      if (this.user.isRoadSupEnable()) {
        if (this.get('table.columns').isAny('id')) {
          const columnArray = this.get('table.columns');
          columnArray.filter(obj => {
            if (obj.get('id') === 'name') {
              obj.set('classNames', 'vehicle-widget-id-custom-class');
            }
          });
          return ['vehicle-widget-gear-icon'];
        }
      }

      return;
    }),

    init() {
      const isScheduleDashboard = this.get('isScheduleDashboard');

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      if (isScheduleDashboard) {
        this.set('config', _scheduleConfig.default);
      }

      this._super(...arguments);
    },

    // 1. Create a new place in the Address service that represents the breakdown location, address and place
    // 2. POST breakdown vehicle to vehicle service
    async saveBreakdownVehicleFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      this.set('editModal.errors', []);

      for (const record of records) {
        let breakdownResult;
        const breakdownPlace = record.get('breakdownPlace');
        const breakdownTime = record.get('breakdownTime');
        const route = record.get('latestRouteVehicleDriver.dispatchRoute.content');

        try {
          const locationResponse = await locationAdapter.postLocation(breakdownPlace.get('location'));
          const address = await addressAdapter.postAddress(breakdownPlace.get('address'));
          await addressAdapter.postAddressLocation(locationResponse, address);
          const place = await placeAdapter.postPlace(breakdownPlace, address.data.id, locationResponse.data.id, 'ad_hoc');

          if (route) {
            breakdownResult = await dispatchRouteAdapter.postBreakdown(route, place.data.id, record);
            this.get('notifications').success(`VEHICLE ${record.name} BREAKDOWN CONFIRMED`);
          } else {
            throw new Error(`dispatchRoute for vehicle ${record.name} was missing`);
          }
        } catch (error) {
          let msg = `Vehicle ${record.name} breakdown confirmation failed`;
          if (error.message && (error.message.includes('modified by another request') || error.message.includes('Unable to lock entities'))) msg = `Breakdown Failed, BECAUSE ITS ROUTE ${record.get('currentRouteName')} HAS BEEN MODIFIED BY ANOTHER REQUEST.`;
          this.get('notifications').warning(msg);
        } finally {
          // We separate the breakdown report and rvd endtime update to avoid rvd endtime update throw breakdown report error.
          if (breakdownResult) {
            const rvd = record.get('latestRouteVehicleDriver');
            const dispatchRouteId = rvd.get('dispatchRoute.id');
            const newDispatchRoute = breakdownResult.included ? breakdownResult.included.find(includedItem => includedItem.type === 'dispatchRoute' && includedItem.id === dispatchRouteId) : null;

            const newDispatchRouteVersion = _lodash.default.get(newDispatchRoute, 'attributes.version');

            if ((0, _moment.default)(breakdownTime).isBefore((0, _moment.default)(rvd.get('startTime')))) {
              let actualStartTime = (0, _moment.default)(route.get('route.actualStartTimestamp')).toDate();

              if ((0, _moment.default)(breakdownTime).isBefore((0, _moment.default)(actualStartTime))) {
                actualStartTime = breakdownTime;
              }

              rvd.set('startTime', actualStartTime);
            }

            rvd.set('endTime', breakdownTime); // set brokendown route to brakedown time

            rvd.set('dispatchRouteVersion', newDispatchRouteVersion);
            await rvd.save();
          }
        }
      }
    },

    async removeBreakdownVehicleFunction(record) {
      const notifications = this.get('notifications');
      const title = 'Remove Breakdown';
      const tooltip = this.get('tooltip');
      const isRouteCompleted = !record.get('latestRoute') || record.get('latestRoute.isCompleted');
      let confirmationMessage = `Are you sure you want to remove breakdown from vehicle ${record.get('name')} ?`;

      if (!isRouteCompleted) {
        confirmationMessage = `The vehicle's route is not Completed. ${confirmationMessage}`;
      }

      tooltip.pushConfirmation({
        tip: confirmationMessage,
        warning: !isRouteCompleted,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          try {
            notifications.success(`Successfully removed breakdown status for Vehicle ${record.get('name')} `);
            record.set('status', 'operational');
            record.set('active', true);
            await record.save();
            this.createUserVehicleRemoveBreakdownLog(record);
          } catch (e) {
            notifications.warning(`Failed to remove breakdown status for Vehicle ${record.get('name')}`);
          } finally {
            tooltip.reset();
          }
        },
        secondaryActionText: 'Cancel',
        secondaryAction: async () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    async createUserVehicleRemoveBreakdownLog(vehicle) {
      const type = 'genericLogging';
      const actualTime = (0, _moment.default)().toDate();
      const userId = this.session.data.authenticated.userId;
      const vehicleInfo = Ember.Object.create({
        context: {
          vehicle: vehicle
        }
      });
      const userInfo = {
        content: {
          actualTime: actualTime,
          type: type,
          userId: userId
        }
      };
      const userLogging = {
        activity: 'Breakdown Remove',
        loggingType: 'user',
        details: `Removed breakdown status for vehicle ${vehicle.name}.`
      };
      const vehicleLogging = {
        activity: 'Breakdown Removed',
        loggingType: 'vehicle',
        details: `Breakdown status removed for vehicle ${vehicle.name}.`
      };
      const actionKey = {
        [type]: [userLogging, vehicleLogging]
      };
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      activityLogAdapter.createUserVehicleActivity(userInfo, vehicleInfo, actionKey);
    },

    didInsertElement() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      switch (role) {
        case _dashboard.SCHEDULE_ROLENAME:
          this._initScheduleSingleActions();

          break;

        case _dashboard.DISPATCH_ROLENAME:
        default:
          this._initDispatchSingleActions();

          break;
      }
    },

    getVehicleModel(vehicleId) {
      let model = 'vehicle';

      if (_environment.default.APP.avlmLite) {
        model = 'avlm-vehicle';
      }

      const vehicleData = this.get('store').peekRecord(model, vehicleId);
      return vehicleData;
    },

    _initDispatchSingleActions() {
      this.get('tableActions').push({
        id: 'print',
        permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver],
        name: 'Broadcast Message',
        action: function () {
          const vehicleRecords = this.get('store').peekAll('vehicle');
          const vehicles = vehicleRecords.map(vehicleRecord => this.getVehicleModel(vehicleRecord.id));
          this.sendMessagesToVehicles(vehicles, 'bulkMessage');
        }.bind(this)
      });
      this.get('tableActions').unshiftObject({
        id: 'newVehicle',
        permId: _mappedPermIds.default.newVehicle,
        name: 'New Vehicle',
        action: function () {
          const store = this.get('store');
          const origin = store.createRecord('location');
          const destination = store.createRecord('location');
          const startAddress = store.createRecord('address');
          const endAddress = store.createRecord('address');
          const startPlace = store.createRecord('place', {
            address: startAddress,
            location: origin
          });
          const endPlace = store.createRecord('place', {
            address: endAddress,
            location: destination
          });
          const vehicleTypes = store.peekAll('vehicle-type');
          const newModel = store.createRecord('vehicle');
          newModel.set('startGarageCopy', startPlace);
          newModel.set('endGarageCopy', endPlace);
          newModel.set('shiftBreaks', []);
          newModel.set('vehicleType', vehicleTypes.firstObject);
          newModel.set('active', true);
          this.get('records').pushObject(newModel);
          this.set('editModal.editableRecords', []);
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this), null, null, {
            componentName: 'addVehicle'
          });
        }.bind(this)
      });
      this.set('sendMessageAction', {
        id: 'sendMessage',
        permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver],
        name: 'Send Message',
        action: vehicle => {
          this.sendMessagesToVehicles([vehicle], 'singleMessage');
        }
      });
      this.set('vehicleBreakdown', {
        id: 'vehicleBreakdown',
        permId: _mappedPermIds.default.vehicleBreakdown,
        name: 'Report Breakdown',
        action: model => {
          const store = this.get('store');
          const dsVehicleModel = model;
          const breakdownComponent = this.get('breakdownComponent'); // vehicle breakdown fields

          const location = store.createRecord('location');
          const address = store.createRecord('address'); // TODO: how does zone types fit in the address

          address.set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
          const place = store.createRecord('place', {
            address: address,
            location: location
          });

          if (dsVehicleModel.get('lat') && dsVehicleModel.get('lng')) {
            place.set('location.lat', dsVehicleModel.get('lat'));
            place.set('location.lng', dsVehicleModel.get('lng'));
          }

          dsVehicleModel.set('breakdownPlace', place);
          dsVehicleModel.set('breakdownTime', new Date());
          dsVehicleModel.set('breakdownDescription', '');
          dsVehicleModel.set('breakdownType', null);
          this.get('editModal').open(breakdownComponent, [dsVehicleModel], this.get('saveBreakdownVehicleFunction').bind(this), null, null, {
            componentName: 'vehicleBreakdown',
            closeOnError: true
          });
        }
      });
      this.set('removeVehicleBreakdown', {
        id: 'vehicleBreakdown',
        permId: _mappedPermIds.default.accessDispatchWorkspace,
        name: 'Remove Breakdown',
        action: model => {
          this.removeBreakdownVehicleFunction(model);
        }
      });
      this.set('resetDeviceAction', {
        id: 'resetDevice',
        permId: _mappedPermIds.default.resetDeviceAction,
        name: 'Reset device',
        action: model => {
          return new Promise((resolve, reject) => {
            const vehicleInfo = (0, _vehicleInfo.vehicleInfoData)();
            const prepareVehicleInfo = (0, _vehicleInfo.prepareVehicleInfoData)(vehicleInfo, model);
            const notifications = this.get('notifications');
            const title = 'Reset Device';
            this.get('tooltip').pushConfirmation({
              tip: 'This will disassociate the device ID from the vehicle.',
              title,
              primaryActionText: 'Proceed',
              primaryAction: () => {
                //sending PATCH to avlm/vehicle-info/{id} requires the payload id/ URL id to equal the avlm.vehicle.id
                const session = this.get('session');
                return this.get('ajax').request(_apiUrls.API.avlmService.host + `/vehicle-info/?callSign=${model.name}`, {
                  method: 'GET',
                  contentType: 'text',
                  headers: {
                    'Authorization': `Bearer ${session.data.authenticated.token}`
                  }
                }).then(response => {
                  // if there is no device ID, inform the user and return
                  if (!response.data[0].attributes.device || !response.data[0].attributes.device.hardwareId) {
                    notifications.info('There is no device associated with this vehicle.');
                    return;
                  }

                  prepareVehicleInfo.data.id = response.data[0].id;
                  const json = JSON.stringify(prepareVehicleInfo);
                  return this.get('ajax').request(_apiUrls.API.avlmService.host + `/vehicle-info/${response.data[0].id}`, {
                    method: 'PATCH',
                    contentType: 'text',
                    headers: {
                      'Authorization': `Bearer ${session.data.authenticated.token}`
                    },
                    data: json
                  }).then(() => {
                    notifications.success('RESET DEVICE SUCCESSFULL');
                  });
                }).then(() => {
                  this.get('tooltip').reset();
                }).catch(function (error) {
                  notifications.warning('Error: Reset did not work correctly');
                  this.get('tooltip').reset();
                  reject(error);
                });
              },
              secondaryActionText: 'Cancel',
              secondaryAction: () => {
                this.get('tooltip').reset();
                return;
              }
            });
          });
        }
      });
      this.set('acknowledgeAction', {
        id: 'emergencyAcknowledge',
        permId: _mappedPermIds.default.emergencyAcknowledge,
        name: 'Acknowledge Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyAcknowledgeActionAvlmLite(vehicle);
          } else {
            this.emergencyAcknowledgeAction(vehicle);
          }
        }
      });
      this.set('cancelAction', {
        id: 'emergencyCancel',
        permId: _mappedPermIds.default.emergencyCancel,
        name: 'Cancel Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyCancelActionAvlmLite(vehicle);
          } else {
            this.emergencyCancelAction(vehicle);
          }
        }
      });
      this.set('resolveAction', {
        id: 'emergencyResolve',
        permId: _mappedPermIds.default.emergencyResolve,
        name: 'Resolve Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyResolveActionAvlmLite(vehicle);
          } else {
            this.emergencyResolveAction(vehicle);
          }
        }
      });
      this.set('navigateToAction', {
        id: 'navigateTo',
        permId: _mappedPermIds.default.navigateIntercept,
        name: 'Navigate To',
        action: vehicle => {
          this.get('roadSupervisor').navigateToVehicle(vehicle);
        }
      });
      this.set('bulkActions', [{
        id: 'bulkMessage',
        permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver],
        name: 'Bulk Message',
        action: vehicles => {
          this.sendMessagesToVehicles(vehicles, 'bulkMessage');
        }
      }]);
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogVehicle,
        name: 'View Activity Log',
        action: vehicle => {
          const name = vehicle.get('name');
          this.get('workspace').pushState('vehicleActivityLog', {
            displayName: `Vehicle #${name} Activity Log`,
            vehicle
          });
        },
        disableOnLocked: false
      });
    },

    _initScheduleSingleActions() {
      this.get('tableActions').push({
        id: 'print',
        permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver],
        name: 'Broadcast Message',
        action: function () {
          const vehicleRecords = this.get('store').peekAll('vehicle');
          const vehicles = vehicleRecords.map(vehicleRecord => this.getVehicleModel(vehicleRecord.id));
          this.sendMessagesToVehicles(vehicles, 'bulkMessage');
        }.bind(this)
      });
      this.get('tableActions').unshiftObject({
        id: 'newVehicle',
        permId: _mappedPermIds.default.newVehicle,
        name: 'New Vehicle',
        action: function () {
          const store = this.get('store');
          const origin = store.createRecord('location');
          const destination = store.createRecord('location');
          const startAddress = store.createRecord('address');
          const endAddress = store.createRecord('address');
          const startPlace = store.createRecord('place', {
            address: startAddress,
            location: origin
          });
          const endPlace = store.createRecord('place', {
            address: endAddress,
            location: destination
          });
          const vehicleTypes = store.peekAll('vehicle-type');
          const newModel = store.createRecord('vehicle');
          newModel.set('startGarageCopy', startPlace);
          newModel.set('endGarageCopy', endPlace);
          newModel.set('shiftBreaks', []);
          newModel.set('vehicleType', vehicleTypes.firstObject);
          newModel.set('active', true);
          this.get('records').pushObject(newModel);
          this.set('editModal.editableRecords', []);
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      });
      this.set('editAction', {
        id: 'edit',
        permId: _mappedPermIds.default.editVehicle,
        name: 'Edit',
        action: model => {
          const store = this.get('store');
          const vehicleModel = model;
          const editComponent = this.get('editComponent');

          if (!Ember.isNone(vehicleModel.get('startGarage.id'))) {
            const origin = vehicleModel.get('startGarage');
            origin.set('type', 'pick');
            const location = store.createRecord('location', origin.get('location.content').toJSON());
            const address = store.createRecord('address', origin.get('address.content').toJSON());
            address.set('zoneName', origin.get('address.zoneName.content')); // user copy of place/address/location so it does not modify the other vehicle's garage places

            const startPlaceCopy = store.createRecord('place', {
              geocodingMethod: origin.get('geocodingMethod'),
              address: address,
              location: location
            });
            vehicleModel.set('startGarageCopy', startPlaceCopy);
          } else {
            const location = store.createRecord('location');
            const address = store.createRecord('address');
            address.set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
            const startPlaceCopy = store.createRecord('place', {
              address: address,
              location: location
            });
            vehicleModel.set('startGarageCopy', startPlaceCopy);
          }

          if (!Ember.isNone(vehicleModel.get('endGarage.id'))) {
            const destination = vehicleModel.get('endGarage');
            destination.set('type', 'pick');
            const location = store.createRecord('location', destination.get('location.content').toJSON());
            const address = store.createRecord('address', destination.get('address.content').toJSON());
            address.set('zoneName', destination.get('address.zoneName')); // user copy of place/address/location so it does not modify the other vehicle's garage places

            const endPlaceCopy = store.createRecord('place', {
              geocodingMethod: destination.get('geocodingMethod'),
              address: address,
              location: location
            });
            vehicleModel.set('endGarageCopy', endPlaceCopy);
          } else {
            const location = store.createRecord('location');
            const address = store.createRecord('address');
            address.set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
            const endPlaceCopy = store.createRecord('place', {
              address: address,
              location: location
            });
            vehicleModel.set('endGarageCopy', endPlaceCopy);
          } // shift break in scheduling not being sent with GET shift-break


          if (Ember.isNone(vehicleModel.get('shiftBreaks.firstObject.location.tomtomFormattedAddress'))) {
            const shiftBreaks = vehicleModel.get('shiftBreaks') || [];
            shiftBreaks.forEach(shiftBreak => {
              const fixedBreakLocation = this.get('store').createRecord('location');
              shiftBreak.set('location', fixedBreakLocation);
            });
          }

          this.get('editModal').open(editComponent, [vehicleModel], this.get('saveRecordFunction').bind(this));
        }
      });
      this.set('vehicleBreakdown', {
        id: 'vehicleBreakdown',
        permId: _mappedPermIds.default.vehicleBreakdown,
        name: 'Report Breakdown',
        action: model => {
          const store = this.get('store');
          const dsVehicleModel = model;
          const breakdownComponent = this.get('breakdownComponent'); // vehicle breakdown fields

          const location = store.createRecord('location');
          const address = store.createRecord('address'); // TODO: how does zone types fit in the address

          address.set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
          const place = store.createRecord('place', {
            address: address,
            location: location
          });

          if (dsVehicleModel.get('lat') && dsVehicleModel.get('lng')) {
            place.set('location.lat', dsVehicleModel.get('lat'));
            place.set('location.lng', dsVehicleModel.get('lng'));
          }

          dsVehicleModel.set('breakdownPlace', place);
          dsVehicleModel.set('breakdownTime', new Date());
          dsVehicleModel.set('breakdownDescription', '');
          dsVehicleModel.set('breakdownType', null);
          this.get('editModal').open(breakdownComponent, [dsVehicleModel], this.get('saveBreakdownVehicleFunction').bind(this));
        }
      });
      this.set('resetDeviceAction', {
        id: 'resetDevice',
        permId: _mappedPermIds.default.resetDeviceAction,
        name: 'Reset device',
        action: model => {
          return new Promise((resolve, reject) => {
            const vehicleInfo = (0, _vehicleInfo.vehicleInfoData)();
            const prepareVehicleInfo = (0, _vehicleInfo.prepareVehicleInfoData)(vehicleInfo, model);
            const notifications = this.get('notifications');
            const title = 'Reset Device';
            this.get('tooltip').pushConfirmation({
              tip: 'This will disassociate the device ID from the vehicle.',
              title,
              primaryActionText: 'Proceed',
              primaryAction: () => {
                //sending PATCH to avlm/vehicle-info/{id} requires the payload id/ URL id to equal the avlm.vehicle.id
                const session = this.get('session');
                return this.get('ajax').request(_apiUrls.API.avlmService.host + `/vehicle-info/?callSign=${model.name}`, {
                  method: 'GET',
                  contentType: 'text',
                  headers: {
                    'Authorization': `Bearer ${session.data.authenticated.token}`
                  }
                }).then(response => {
                  // if there is no device ID, inform the user and return
                  if (!response.data[0].attributes.device || !response.data[0].attributes.device.hardwareId) {
                    notifications.info('There is no device associated with this vehicle.');
                    return;
                  }

                  prepareVehicleInfo.data.id = response.data[0].id;
                  const json = JSON.stringify(prepareVehicleInfo);
                  return this.get('ajax').request(_apiUrls.API.avlmService.host + `/vehicle-info/${response.data[0].id}`, {
                    method: 'PATCH',
                    contentType: 'text',
                    headers: {
                      'Authorization': `Bearer ${session.data.authenticated.token}`
                    },
                    data: json
                  }).then(() => {
                    notifications.success('RESET DEVICE SUCCESSFULL');
                  });
                }).then(() => {
                  this.get('tooltip').reset();
                }).catch(function (error) {
                  notifications.warning('Error: Reset did not work correctly');
                  this.get('tooltip').reset();
                  reject(error);
                });
              },
              secondaryActionText: 'Cancel',
              secondaryAction: () => {
                this.get('tooltip').reset();
                return;
              }
            });
          });
        }
      });
      this.set('acknowledgeAction', {
        id: 'emergencyAcknowledge',
        permId: _mappedPermIds.default.emergencyAcknowledge,
        name: 'Acknowledge Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyAcknowledgeActionAvlmLite(vehicle);
          } else {
            this.emergencyAcknowledgeAction(vehicle);
          }
        }
      });
      this.set('cancelAction', {
        id: 'emergencyCancel',
        permId: _mappedPermIds.default.emergencyCancel,
        name: 'Cancel Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyCancelActionAvlmLite(vehicle);
          } else {
            this.emergencyCancelAction(vehicle);
          }
        }
      });
      this.set('resolveAction', {
        id: 'emergencyResolve',
        permId: _mappedPermIds.default.emergencyResolve,
        name: 'Resolve Emergency',
        action: vehicle => {
          if (_environment.default.APP.avlmLite) {
            this.emergencyResolveActionAvlmLite(vehicle);
          } else {
            this.emergencyResolveAction(vehicle);
          }
        }
      });
      this.set('navigateToAction', {
        id: 'navigateTo',
        permId: _mappedPermIds.default.navigateIntercept,
        name: 'Navigate To',
        action: vehicle => {
          this.get('roadSupervisor').navigateToVehicle(vehicle);
        }
      });
      this.set('bulkActions', [{
        id: 'bulkMessage',
        permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver],
        name: 'Bulk Message',
        action: vehicles => {
          this.sendMessagesToVehicles(vehicles, 'bulkMessage');
        }
      }]);
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogVehicle,
        name: 'View Activity Log',
        action: vehicle => {
          const vehicleId = vehicle.get('name');
          this.get('workspace').pushState('vehicleActivityLog', {
            displayName: `Vehicle #${vehicleId} Activity Log`,
            vehicle
          });
        },
        disableOnLocked: false
      });
    },

    avlmDriverApps: Ember.computed('table.rows.@each.avlmDriverApp', function () {
      const tableRows = this.get('table.rows') || [];
      const avlmDriverApps = tableRows.map(row => {
        return row.get('record.avlmDriverApp.content');
      });
      return avlmDriverApps;
    }),
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected}', 'avlmDriverApps.@each.deviceHardwareId', 'selectedWidgetRecord', function () {
      const columns = Ember.get(this, 'config').columns;
      const sendMessageAction = this.get('sendMessageAction');
      const resetDeviceAction = this.get('resetDeviceAction');
      const cancelAction = this.get('cancelAction');
      const acknowledgeAction = this.get('acknowledgeAction');
      const resolveAction = this.get('resolveAction');
      const activityLogAction = this.get('activityLogAction');
      const vehicleBreakdown = this.get('vehicleBreakdown');
      const removeVehicleBreakdown = this.get('removeVehicleBreakdown');
      const row = this.getSelectedCoreModel();
      const actions = [activityLogAction];
      const role = this.get('workspace.dashboardModel.role');
      let isAssignedDriverRoute = false;

      if (!Ember.isEmpty(row) && columns && columns.length !== 0) {
        const routeName = columns.filter(column => column.id === ROUTE_NAME)[0];
        const driver = columns.filter(column => column.id === DRIVER)[0];
        if (row.get(routeName.mapValuePath) && row.get(driver.mapValuePath)) isAssignedDriverRoute = true;
      } // Cannot run vehicle breakdown for the route already in breakdown


      if (!row.get('isPlaceholder') && row.get('vehicleEntityStatus') !== 'breakdown' && row.get('routeStatus') !== 'breakdown' && row.get('inProgress') === true && row.get('latestRoute.hasStarted')) {
        actions.push(vehicleBreakdown);
      }

      if (role === _dashboard.DISPATCH_ROLENAME && row.get('vehicleEntityStatus') === 'breakdown') {
        actions.push(removeVehicleBreakdown);
      }

      if (isAssignedDriverRoute && role === _dashboard.DISPATCH_ROLENAME) {
        actions.push(sendMessageAction);
      }

      if (!row) return []; // add Reset Device for all vehicle except placeholder vehicles

      if (!row.get('isPlaceholder')) {
        actions.push(resetDeviceAction);
      } // add acknowledge, cancel actions for recent emergencies


      if (row.get('isEmergency')) {
        actions.push(acknowledgeAction, cancelAction);
      } // add resolved, cancel actions for approved emergencies
      else if (row.get('isApprovedEmergency')) {
        actions.push(resolveAction, cancelAction);
      } // navigation to moving vehicles is disabled until further notice
      //
      // const navigateToAction = this.get('navigateToAction');
      // actions.push(navigateToAction);


      return actions;
    }),

    async sendMessagesToVehicles(vehicles, messageType) {
      const draftMessages = vehicles.map(vehicle => {
        const trueVehicle = vehicle;
        const vehicleId = trueVehicle.get('id');
        const vehicleName = trueVehicle.get('name');
        const assignedDriver = trueVehicle.get('latestDriver');
        const driverId = assignedDriver ? assignedDriver.get('id') : null;
        let badgeNumber = null;

        if (assignedDriver) {
          if (trueVehicle.get('driverBadgeNumber')) {
            badgeNumber = trueVehicle.get('driverBadgeNumber');
          } else if (assignedDriver.get('badgeNumber')) {
            badgeNumber = assignedDriver.get('badgeNumber');
          } else {
            badgeNumber = assignedDriver.get('driver.badgeNumber');
          }
        }

        if (messageType === 'singleMessage' && !badgeNumber) {
          this.get('notifications').warning(`Can not send message due to missing driver id on vehicle id = ${vehicleName}`);
          return null;
        }

        if (messageType === 'bulkMessage' && !badgeNumber) {
          // if some vehicle missing driver just return null
          return null;
        }

        trueVehicle.set('driver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: trueVehicle.get('latestDriver.fullName'),
          badgeNumber: badgeNumber
        });
        trueVehicle.set('vehicle', {
          id: vehicleId,
          name: vehicleName
        });
        const routeId = trueVehicle.get('latestRouteVehicleDriver.route.id') || trueVehicle.get('currentRoutePk');
        const routeName = trueVehicle.get('latestRouteVehicleDriver.dispatchRoute.route.name') || trueVehicle.get('currentRouteName');
        trueVehicle.set('route', {
          id: routeId,
          name: routeName
        });
        trueVehicle.set('messageNr', '0');

        if (_environment.default.APP.avlmLite) {
          trueVehicle.set('avlmDriver', {
            id: driverId,
            endPointType: 'recipient'
          });
          trueVehicle.set('avlmVehicle', {
            id: vehicleId
          });
        }

        return trueVehicle;
      }).filter(vehicle => !Ember.isNone(vehicle));

      if (messageType === 'singleMessage' && draftMessages.firstObject) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      } else if (messageType === 'bulkMessage') {
        if (draftMessages.length < vehicles.length) {
          this.get('notifications').warning('MESSAGE COULD NOT BE SENT TO SOME VEHICLES WHICH DO NOT HAVE DRIVER ASSOCIATED.');
        }

        if (!Ember.isEmpty(draftMessages)) {
          this.get('workspace').pushState('addNewMessage', {
            draftMessages
          });
        }
      }
    },

    _sendEmergencyMessage(message, successNotification, errorNotification) {
      const formatedMessage = {
        data: {
          type: 'cannedMessage',
          attributes: {
            messageNr: '0',
            body: message.body,
            createdTime: new Date(),
            priority: message.priority,
            responseType: {
              statusCode: _avlmEmergencyMessage.CANNED_RESPONSE_TYPE_CODES[message.responseTypeText],
              status: message.responseTypeText
            },
            type: 'D',
            driver: {
              id: message.driver.id,
              endPointType: 'recipient'
            },
            vehicle: {
              id: message.vehicleId
            }
          }
        }
      };

      if (message.body === 'acknowledged' || message.body === 'canceled' || message.body === 'resolved') {
        formatedMessage.data.attributes.replyToMsgId = message.replyToMsgId;
      }

      const json = JSON.stringify(formatedMessage);
      const session = this.get('session');
      return this.get('ajax').post(_apiUrls.API.avlmService.host + '/canned-message', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).then(() => {
        this.get('notifications').success(successNotification);
      }).catch(function
        /*error*/
      () {
        this.get('notifications').warning(errorNotification);
      });
    },

    async emergencyAcknowledgeAction(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const tooltip = this.get('tooltip');
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'acknowledged',
        responseTypeText: 'acknowledged',
        responseMessage: 'The message is for Acknowledged',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Acknowledge Emergency Message

      tooltip.reset();
      return this._sendEmergencyMessage(newEmergencyMessage, 'Acknowledged Emergency Message Successfully', 'Acknowledge Emergency Message Failed');
    },

    async emergencyAcknowledgeActionAvlmLite(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const tooltip = this.get('tooltip');
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'acknowledged',
        responseTypeText: 'acknowledged',
        responseMessage: 'The message is for Acknowledged',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Acknowledge Emergency Message

      tooltip.reset();
      return this._sendEmergencyMessage(newEmergencyMessage, 'Acknowledged Emergency Message Successfully', 'Acknowledge Emergency Message Failed');
    },

    async emergencyCancelAction(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'canceled',
        responseTypeText: 'canceled',
        responseMessage: 'The message is for Resolved',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Cancel Emergency Message

      const tooltip = this.get('tooltip');
      const tip = 'This will cancel the driver\'s emergency message. Proceed?';
      const title = 'Cancel Emergency';
      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          tooltip.reset();
          return this._sendEmergencyMessage(newEmergencyMessage, 'Canceled Emergency Message Successfully', 'Canceled Emergency Message Failed');
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
          return;
        }
      });
    },

    async emergencyCancelActionAvlmLite(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'canceled',
        responseTypeText: 'canceled',
        responseMessage: 'The message is for Resolved',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Cancel Emergency Message

      const tooltip = this.get('tooltip');
      const self = this;
      tooltip.pushConfirmation({
        tip: 'This will cancel the driver\'s emergency message. Proceed?',
        primaryActionText: 'Yes',
        secondaryActionText: 'No',

        primaryAction() {
          self._sendEmergencyMessage(newEmergencyMessage, 'Canceled Emergency Message Successfully', 'Canceled Emergency Message Failed');

          tooltip.reset();
          return;
        },

        secondaryAction: () => {
          tooltip.reset();
          return;
        }
      });
    },

    async emergencyResolveAction(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const tooltip = this.get('tooltip');
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'resolved',
        responseTypeText: 'resolved',
        responseMessage: 'The message is for Resolved',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Acknowledge Emergency Message

      tooltip.reset();
      return this._sendEmergencyMessage(newEmergencyMessage, 'Resolved Emergency Message Successfully', 'Resolved Emergency Message Failed');
    },

    async emergencyResolveActionAvlmLite(vehicle) {
      const iqVehicle = vehicle;
      const replyToMsgId = iqVehicle.get('latestReceivedEmergencyMessage') ? iqVehicle.get('latestReceivedEmergencyMessage').get('replyToMsgId') : null;
      if (!replyToMsgId) return this.get('notifications').warning('Could not resolve emergency message due to missing reply id');
      let driverId = iqVehicle.get('latestDriver.id');
      driverId = driverId ? driverId : 0;
      const tooltip = this.get('tooltip');
      const newEmergencyMessage = {
        // TODO: should this be `driver.driverId`? latest driver not found
        driver: {
          id: `${driverId}`,
          firstName: `${iqVehicle.get('latestDriver.firstName')}`,
          lastName: `${iqVehicle.get('latestDriver.lastName')}`,
          endPointType: 'recipient'
        },
        messageNr: '0',
        replyToMsgId: replyToMsgId,
        driverEndPointType: 'recipient',
        vehicleId: iqVehicle.get('id'),
        routeId: iqVehicle.get('latestRouteVehicleDriver.route.id'),
        body: 'resolved',
        responseTypeText: 'resolved',
        responseMessage: 'The message is for Resolved',
        priority: 'emergency'
      }; // Update URL with correct endpoint and post data
      // Acknowledge Emergency Message

      tooltip.reset();
      return this._sendEmergencyMessage(newEmergencyMessage, 'Resolved Emergency Message Successfully', 'Resolved Emergency Message Failed');
    },

    applyTransformationData(widgetRecords) {
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          if (!_environment.default.APP.avlmLite) {
            this.applyVehicleTypeTransformation(widgetRecord);
            this.applyOtpStatusTransformation(widgetRecord);
            this.applyProviderTransformation(widgetRecord);
            this.applyVehicleTypeNameTransformation(widgetRecord);
          }

          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    applyVehicleTypeTransformation(widgetRecord) {
      if (!this.get('vehicleTypeDict')) {
        this.loadVehicleTypes();
      }

      const vehicleTypeDict = this.get('vehicleTypeDict');
      const vehicleType = vehicleTypeDict[widgetRecord.type];

      if (vehicleType) {
        widgetRecord.noOfAmbulatorySeats = vehicleType.get('noOfAmbulatoryFlexSeats');
        widgetRecord.noOfWheelChairSeats = vehicleType.get('noOfWheelChairFlexSeats');
      }
    },

    applyProviderTransformation(widgetRecord) {
      if (widgetRecord.provider) {
        const provider = this.store.peekRecord('provider', widgetRecord.provider);
        const providerName = provider ? provider.get('name') : widgetRecord.provider;
        widgetRecord.providerNameString = providerName;
      }
    },

    applyVehicleTypeNameTransformation(widgetRecord) {
      if (widgetRecord.type) {
        const type = this.store.peekRecord('vehicle-type', widgetRecord.type);
        const name = type ? type.get('name') : widgetRecord.type;
        widgetRecord.typeName = name;
      }
    },

    applyFilterQueryConversion(filters) {
      this._super(...arguments);

      filters.forEach(filter => {
        this.applyVehicleCapacityFilterConversion(filter);
        this.applyVehicleTypeFilterConversion(filter);
      });
    },

    applyVehicleTypeFilterConversion(filter) {
      if (!filter || filter.local.path !== 'type') {
        return;
      }

      const modelName = pathToModel[filter.local.path];
      const modelTypeMapper = this.get('store').peekAll(modelName).map(record => {
        return {
          id: record.id,
          idLowerCase: record.id.trim().toLowerCase(),
          name: record.name
        };
      });
      const newValues = (0, _filters.applyFilterByID)(filter.local.values, filter.type, modelTypeMapper);
      filter.local.values = newValues;
      filter.rql.values = newValues;
    },

    applyVehicleCapacityFilterConversion(filter) {
      if (!filter || filter.local.path !== 'noOfAmbulatorySeats' && filter.local.path !== 'noOfWheelChairSeats') {
        return;
      }

      const type = filter.local.path === 'noOfAmbulatorySeats' ? 'ambulatory' : 'wheelchair';
      const vehicleTypeDict = this.get('vehicleTypeDict');

      const compatibleVehicleTypes = _lodash.default.filter(vehicleTypeDict, vehicleType => {
        return vehicleType.noOfFlexSeats.some(flexSeat => {
          filter.local.path = type;
          const filterFunction = (0, _filters.buildFilterFunction)(filter);
          return filterFunction(flexSeat);
        });
      }).map(vehicle => vehicle.name);

      if (compatibleVehicleTypes.length > 0) {
        filter.type = _filterTypes.enumStringEq.id;
        filter.local.path = 'type';
        filter.rql.path = 'type';
        filter.local.values = [compatibleVehicleTypes];
        filter.rql.values = [compatibleVehicleTypes];
      }
    }

  });

  _exports.default = _default;
});