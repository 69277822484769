define("adept-iq/pods/components/iq-widgets/trips-widget/scheduleConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/trips-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'trip',
    defaultSortId: 'name',
    defaultSortAsc: false,
    title: 'Trips',
    // ember-react-widget properties
    widgetName: 'tripWidget',
    selectedRowLimit: 50,
    maxLimitByRouteSelection: 5,
    maxLimitWithoutRouteAssociation: 20,
    restrictSwapColumns: false,
    columns: [{
      id: 'name',
      index: 1,
      type: 'uuid',
      label: 'Trip ID',
      valuePath: 'tripId',
      valuePreview: '1',
      hidden: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'routeId',
      index: 2,
      type: 'text',
      label: 'Route ID',
      valuePath: 'routeName',
      valuePreview: '1',
      hidden: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'status',
      index: 3,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      hidden: false,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 80
    }, {
      id: 'anchor',
      index: 4,
      type: 'text',
      label: 'Anchor',
      valuePath: 'anchor',
      mapValuePath: 'anchor',
      hidden: false,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'pickUpAddress',
      index: 5,
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'pickUpAddress',
      hidden: false,
      searchable: false,
      defaultWidth: 120
    }, {
      id: 'dropOffAddress',
      index: 7,
      type: 'text',
      label: 'Dropoff Address',
      valuePath: 'dropOffAddress',
      hidden: false,
      searchable: false,
      defaultWidth: 120
    }, {
      id: 'requestTime',
      index: 8,
      type: 'time',
      label: 'Request Time',
      valuePath: 'requestTime',
      valuePreview: '09:00',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 100,
      searchable: false,
      cellItem: 'TimeUserCellItem'
    }, {
      id: 'serviceStartTime',
      index: 9,
      type: 'time',
      label: 'Service Start Time',
      valuePath: 'serviceStartTime',
      valuePreview: '09:00',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 120,
      searchable: false,
      cellItem: 'TimeUserCellItem'
    }, {
      id: 'serviceEndTime',
      index: 10,
      type: 'time',
      label: 'Service End Time',
      valuePath: 'serviceEndTime',
      valuePreview: '09:00',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 120,
      searchable: false,
      cellItem: 'TimeUserCellItem'
    }, {
      id: 'capacityRequirements',
      index: 11,
      type: 'text',
      label: 'Capacity Requirements',
      toolTipPath: 'capacityRequirements',
      hidden: false,
      searchable: false,
      defaultWidth: 150,
      cellItem: 'TooltipUserCell',
      cellDesc: 'Requirements'
    }, {
      id: 'traits',
      index: 12,
      type: 'text',
      label: 'Traits',
      toolTipPath: 'traits',
      hidden: false,
      searchable: false,
      defaultWidth: 70,
      cellItem: 'TooltipUserCell',
      cellDesc: 'Traits'
    }],
    traversal: {
      routeWidget: {
        links: [{
          type: 'eq',
          field: 'routeId',
          valuePath: 'id'
        }]
      },
      stopWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'tripPk'
        }]
      }
    },
    implicit: {
      conditions: []
    }
  };
  _exports.default = _default;
});