define("adept-iq/pods/components/iq-widgets/playback-widget/component", ["exports", "lodash", "adept-iq/pods/components/iq-widgets/map-widget/component", "adept-iq/config/environment", "adept-iq/pods/components/iq-widgets/playback-widget/config", "adept-iq/pods/components/iq-widgets/playback-widget/avlmConfig", "ember-light-table", "adept-iq/mixins/fetch-vehicle-events", "adept-iq/config/icon-paths", "moment", "adept-iq/config/mapped-permIds", "papaparse", "ember-concurrency"], function (_exports, _lodash, _component, _environment, _config, _avlmConfig, _emberLightTable, _fetchVehicleEvents, _iconPaths, _moment, _mappedPermIds, _papaparse, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const toPixels = x => `${x}px`;

  var _default = _component.default.extend(_fetchVehicleEvents.default, {
    workspace: Ember.inject.service(),
    interaction: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    editModal: Ember.inject.service(),
    classNames: ['playback-widget'],
    layoutName: 'components/iq-widgets/playback-widget',
    classNameBindings: ['testPlaybackWidget'],
    testPlaybackWidget: false,
    config: _config.default,
    animSpeed: null,
    isSlideMoving: false,
    totolTimeInSeconds: null,
    durationInSeconds: null,
    isVehicleEventSearchEnabled: false,
    isVehicleSearchEnabled: false,
    // custom playback map
    isSearchingLocation: false,
    searchCenter: null,
    // tables
    vehiclesTable: null,
    vehiclesSearchableColumns: null,
    vehicleEventsTable: null,
    vehicleEventsSearchableColumns: null,
    rightClickActionArray: null,
    inlineRowMenu: null,
    inlineRowMenuPosition: null,
    dummyVehicleOptions: null,
    dummyRouteOptions: null,
    dummyDriverOptions: null,
    filteredEvents: null,
    // map values
    allEvents: null,
    // table search parameters
    isSearchEnabled: false,
    activeSearchTable: null,
    // default property for anime x button
    animDisable: false,
    // mixin
    bufferSize: 200,
    eventTableFilterDone: false,
    vehicleTableFilterDone: false,
    previousFilterSetting: null,
    previousFilterSettingVehicleTable: null,
    playbackFilterSettingClosed: Ember.computed.readOnly('workspace.playbackFilterSettingClosed'),
    vehicleTableFilterSettingClosed: Ember.computed.readOnly('workspace.playbackFilterSettingClosedofVehicleGrid'),
    widgetState: Ember.computed.readOnly('workspace.topOptions.widget._state'),
    vehicleRowSelection: Ember.computed('vehicleRowsSelection', function () {
      let row = null;

      if (this.vehicleRowsSelection) {
        row = this.vehicleRowsSelection[0];
      }

      return row;
    }),
    vehicleRowSelectionObserver: Ember.observer('vehicleRowSelection', function () {
      if (this.get('vehicleRowSelection')) {
        this.actions.onVehicleRowClick(this.get('vehicleRowSelection'), this);
      }
    }),
    currentSelection: Ember.computed('rowSelection', function () {
      let row = null;

      if (this.rowSelection) {
        row = this.rowSelection[0];
      }

      return row;
    }),
    currentSelectionObserver: Ember.observer('currentSelection', function () {
      if (this.get('currentSelection')) {
        this.actions.onEventRowClick(this.get('currentSelection'), this);
      }
    }),
    columns: Ember.computed('widgetState', 'playbackFilterSettingClosed', function () {
      if (this.get('playbackFilterSettingClosed')) {
        return this.get('previousFilterSetting');
      }

      let columns = this.get('config.vehicleEventsTableColumns');
      const widgetState = this.get('widgetState');

      if (widgetState && widgetState.hasOwnProperty('columns')) {
        _lodash.default.pickBy(widgetState.columns, (value, key) => {
          if (value.hidden) {
            columns = columns.filter(function (column) {
              return column.id !== key;
            });
          }
        }); // eslint-disable-next-line ember/no-side-effects


        this.set('previousFilterSetting', columns);
        return columns;
      } // eslint-disable-next-line ember/no-side-effects


      this.set('previousFilterSetting', columns);
      return columns;
    }),
    rows: Ember.computed('vehicleEvents', 'eventTableFilterDone', function () {
      let rows = [];

      if (this.get('vehicleEvents')) {
        rows = this.get('vehicleEvents');
      }

      if (this.get('eventTableFilterDone')) {
        rows = this.get('eventTableFilterDone');
      }

      return rows;
    }),
    vehicleColumns: Ember.computed('widgetState', 'vehicleTableFilterSettingClosed', function () {
      if (this.get('vehicleTableFilterSettingClosed')) {
        return this.get('previousFilterSettingVehicleTable');
      }

      let columns = this.get('config.vehiclesTableColumns');
      const widgetState = this.get('widgetState');

      if (widgetState && widgetState.hasOwnProperty('columns')) {
        _lodash.default.pickBy(widgetState.columns, (value, key) => {
          if (value.hidden) {
            columns = columns.filter(function (column) {
              return column.id !== key;
            });
          }
        }); // eslint-disable-next-line ember/no-side-effects


        this.set('previousFilterSettingVehicleTable', columns);
        return columns;
      } // eslint-disable-next-line ember/no-side-effects


      this.set('previousFilterSettingVehicleTable', columns);
      return columns;
    }),
    vehicleRows: Ember.computed('radiusVehicles', 'vehicleTableFilterDone', function () {
      let rows = [];

      if (this.get('radiusVehicles')) {
        rows = this.get('radiusVehicles');
      }

      if (this.get('vehicleTableFilterDone')) {
        rows = this.get('vehicleTableFilterDone');
      }

      return rows;
    }),
    activeEvents: Ember.computed('vehicleEvents', 'filteredEvents', function () {
      const events = this.get('vehicleEvents');
      const filteredEvents = this.get('filteredEvents');
      if (Ember.isNone(events)) return [];else if (!Ember.isNone(filteredEvents)) return filteredEvents;
      return events.filter(event => event.isChecked);
    }),
    mapResetOnVehicleEvents: Ember.computed.readOnly('vehicleEvents', function () {
      if (Ember.get(this, 'initialMapLayers') !== null) Ember.get(this, 'map').eachLayer(layer => {
        if (!Ember.get(this, 'initialMapLayers').includes(layer._leaflet_id)) {
          layer.remove();
        }
      });
      return;
    }),
    sliderIndexMax: Ember.computed('activeEvents', function () {
      const events = this.get('activeEvents');
      return events.length > 0 ? events.length - 1 : 0;
    }),
    elapsedTimeObserver: Ember.observer('sliderIndex', 'elapsedTime', function () {
      const activeEvents = this.get('activeEvents');
      const sliderIndex = this.get('sliderIndex');
      if (Ember.isEmpty(activeEvents) || Ember.isNone(sliderIndex) || sliderIndex >= activeEvents.length) return;
      const currentEvent = activeEvents[this.get('sliderIndex')];
      const elapsedTime = this.get('elapsedTime');
      const milisec = (0, _moment.default)(currentEvent.get('timestamp')).valueOf() - (0, _moment.default)(this.get('activeEvents.firstObject.timestamp')).valueOf() + elapsedTime;
      this.set('durationInSeconds', milisec / 1000);
    }),
    activeStartTime: Ember.computed('activeEvents.firstObject.time', function () {
      return this.get('activeEvents.firstObject.time') || 'Start Time';
    }),
    activeEndTime: Ember.computed('activeEvents.lastObject.time', function () {
      return this.get('activeEvents.lastObject.time') || 'End Time';
    }),
    currentTimeSilder: Ember.computed('durationInSeconds', function () {
      const timeStamp = Ember.get(this, 'activeEvents.firstObject.timestamp');
      const durationInSeconds = Ember.get(this, 'durationInSeconds');
      if (Ember.isEmpty(timeStamp) || durationInSeconds === null || durationInSeconds === 0) return;
      return (0, _moment.default)(timeStamp).add(durationInSeconds, 'seconds').format('hh:mm A');
    }),
    // Caption over slider to move along with slider
    currentWidght: Ember.computed('durationInSeconds', 'totolTimeInSeconds', function () {
      // Margin-left is set to 512px
      const margin = 512; // max Margin-left to make it equal to slider motion 877px

      const maxMargin = 365;
      const totolTimeInSeconds = Ember.get(this, 'totolTimeInSeconds');
      const durationInSeconds = Ember.get(this, 'durationInSeconds');
      let finalMargin = null;
      if (totolTimeInSeconds === null || totolTimeInSeconds === 0 || durationInSeconds === null || durationInSeconds === 0) finalMargin = margin.toString() + 'px';else finalMargin = (margin + maxMargin * durationInSeconds / totolTimeInSeconds).toString() + 'px';
      return finalMargin;
    }),
    isReconciled: Ember.computed.alias('activeEvents.firstObject.isReconciled'),
    polylinePoints: Ember.computed('activeEvents', function () {
      const events = this.get('activeEvents');
      const points = events.reduce((arr, event) => {
        arr.push(event.latlng);
        return arr;
      }, []);
      return points;
    }),
    movingMarker: Ember.computed('activeEvents.@each.{latlng}', function () {
      const events = this.get('activeEvents');
      const vehicleIcon = window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5]
      });
      let movingMarker, previousPointsTime;

      if (!Ember.isEmpty(events)) {
        events.forEach(event => {
          if (!movingMarker) {
            movingMarker = window.L.Marker.movingMarker([event.latlng], 0, {
              autostart: false,
              loop: false,
              icon: vehicleIcon,
              rotationAngle: null,
              rotationOrigin: '11.5px 11.5px'
            });
            movingMarker.on('click', function () {
              if (this.get('targetRow')) {
                const currentRow = this.get('targetRow.content');
                const formattedEventType = 'Vehicle <br/>';
                let label = formattedEventType.bold(); // NOTE: DON'T DELETE SPPED CODE
                // speed will be implemented later therefore commented the code, not removed the code.
                // let speed = 'N/A';
                // if (currentRow.speed) {
                //   speed = currentRow.speed;
                // }
                // if (currentRow.speed === 0) {
                //   speed = 0;
                // }

                let routeName = 'N/A';

                if (currentRow.routeName) {
                  routeName = currentRow.routeName;
                }

                label = label + 'Vehicle ID: ' + currentRow.vehicleId + '<br/>'; // speed will be implemented later therefore commented the code, not removed the code.
                // label = label + 'Vehicle Speed: ' + speed + '<br/>';

                label = label + 'Route: ' + routeName + '<br/>';
                movingMarker.unbindPopup();
                movingMarker.bindPopup(label, {
                  closeButton: true
                }).openPopup();
              }
            }.bind(this));
            previousPointsTime = (0, _moment.default)(event.timestamp).valueOf();
          } else {
            const currentPointsTime = (0, _moment.default)(event.timestamp).valueOf();
            movingMarker.addLatLng(event.latlng, currentPointsTime - previousPointsTime);
            previousPointsTime = currentPointsTime;
          }
        });
      }

      return movingMarker;
    }),
    radiusMarkersGroup: Ember.computed('radiusVehicles.@each.{latlng,heading}', function () {
      const radiusVehicles = this.get('radiusVehicles');
      const vehicleIcon = window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5]
      });
      const markers = window.L.markerClusterGroup();

      if (!Ember.isEmpty(radiusVehicles)) {
        radiusVehicles.forEach(vehicle => {
          const marker = window.L.marker(vehicle.latlng, {
            icon: vehicleIcon,
            rotationAngle: vehicle.heading / 2,
            rotationOrigin: '11.5px 11.5px'
          });
          markers.addLayer(marker);
          vehicle.set('marker', marker);
        });
        return markers;
      }

      return null;
    }),
    isRunning: Ember.computed('_movingMarker', function () {
      const movingMarker = this.get('_movingMarker');

      if (movingMarker) {
        return movingMarker.isRunning();
      }

      return false;
    }),
    layers: Ember.computed.readOnly('mapService.topMapContext.layers'),
    updateLayers: Ember.observer('layers', function () {
      if (this.get('mapService.topMapContext.refreshMap')) {
        this.set('mapService.topMapContext.refreshMap', false);
        this.updateLabels();
      }
    }),
    // Tabs
    activeTabModeId: Ember.computed.alias('activeContext.topActiveContext.activeTabModeId'),
    // data
    vehicles: Ember.computed('workspaceData', function () {
      const workspaceData = this.get('workspaceData');
      if (Ember.isNone(workspaceData)) return [];
      const vehicleConfig = this.get('config.vehicle');
      const vehicles = workspaceData[vehicleConfig.model];
      return vehicles;
    }),
    routes: Ember.computed('workspaceData', function () {
      const workspaceData = this.get('workspaceData');
      if (Ember.isNone(workspaceData)) return [];
      const routeConfig = this.get('config.route');
      const routes = workspaceData[routeConfig.model];
      return routes;
    }),
    events: null,
    markerLocation: null,
    tooLargeData: Ember.computed.alias('activeContext.topActiveContext.tooLargeData'),
    radiusVehicles: Ember.computed.alias('activeContext.topActiveContext.radiusVehicles'),
    vehicleEvents: Ember.computed.alias('activeContext.topActiveContext.vehicleEvents'),
    selectedAddress: Ember.computed.alias('activeContext.topActiveContext.selectedAddress'),
    selectedRadius: Ember.computed.alias('activeContext.topActiveContext.selectedRadius'),
    selectedDateTimeRage: Ember.computed.alias('activeContext.topActiveContext.selectedDateTimeRage'),
    selectedProvider: Ember.computed.alias('activeContext.topActiveContext.selectedProvider'),
    selectedRoute: Ember.computed.alias('activeContext.topActiveContext.selectedRoute'),
    selectedVehicle: Ember.computed.alias('activeContext.topActiveContext.selectedVehicle'),
    selectedRadiusVehicle: Ember.computed.alias('activeContext.topActiveContext.selectedRadiusVehicle'),
    selectedDriver: Ember.computed('selectedRoute', 'selectedVehicle', function () {
      const routeConfig = this.get('config.route');
      const vehicleConfig = this.get('config.vehicle');
      const driverConfig = this.get('config.driver');
      const route = this.get('selectedRoute.model');
      let driver;

      if (Ember.isPresent(route)) {
        driver = route.get(routeConfig.driverPath);

        if (Ember.isPresent(driver)) {
          return {
            id: driver.get(driverConfig.indexPath),
            model: driver
          };
        }
      }

      const vehicle = this.get('selectedVehicle.model');

      if (Ember.isPresent(vehicle)) {
        driver = vehicle.get(vehicleConfig.driverPath);

        if (Ember.isPresent(driver)) {
          return {
            id: driver.get(driverConfig.indexPath),
            model: driver
          };
        }
      }

      return null;
    }),

    clearAll() {
      Ember.set(this, 'selectedProvider', null);
      Ember.set(this, 'selectedRoute', null);
      Ember.set(this, 'selectedVehicle', null);
      Ember.set(this, 'selectedDriver', null);
      Ember.set(this, 'selectedAddress', null);
      Ember.set(this, 'selectedRadius', null);
      Ember.set(this, 'nextEvent', null);
      Ember.set(this, 'animDisable', false);
      Ember.set(this, 'filteredEvents', null);
      this.set('tooLargeData', '');
      this.notifyPropertyChange('filteredEvents');
      this.get('fetchVehiclesTask').perform();
      this.get('fetchRoutesTask').perform();
      this.get('fetchDriversTask').perform();
      this.get('fetchVehiclesTask').perform();
      this.get('fetchRoutesTask').perform();
      this.get('fetchDriversTask').perform();
      this.set('selectedRadiusVehicle', null);
      this.set('totolTimeInSeconds', null);
      this.set('durationInSeconds', null);
      this.set('animSpeed', 1);
      this.searchCenterReset();
      this.set('pbounds', null);
      if (Ember.get(this, 'initialMapLayers') !== null) Ember.get(this, 'map').eachLayer(layer => {
        if (!Ember.get(this, 'initialMapLayers').includes(layer._leaflet_id)) {
          layer.remove();
        }
      }); // TODO: see if we need to clear events when hit clear button.

      if (!Ember.isEmpty(Ember.get(this, 'vehicleEvents'))) Ember.set(this, 'vehicleEvents', []);
      if (!Ember.isEmpty(Ember.get(this, 'radiusVehicles'))) Ember.set(this, 'radiusVehicles', []);
      this.notifyPropertyChange('radiusVehicles');
      this.notifyPropertyChange('vehicleEvents');
      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().hours(0).minutes(0).format('YYYY-MM-DD'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('YYYY-MM-DD')
      });
    },

    workspaceData: Ember.computed.alias('activeContext.topActiveContext.workspaceData'),
    providerSortKeys: ['name'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    providerOptions: Ember.computed.sort('activeContext.topActiveContext.selectedProviders', 'providerSortKeys'),
    // providerOptions: computed('routes', 'dummyProviderOptions', function() {
    //   const dummyOptions = this.get('dummyProviderOptions');
    //   const options = [];
    //   return options.concat(dummyOptions).sortBy('name');
    // }),
    routeOptions: Ember.computed('routes', 'dummyRouteOptions', function () {
      const routes = this.get('routes');
      const routeConfig = this.get('config.route');
      const routeNameConfig = this.get('config.routeName');
      const dummyOptions = this.get('dummyRouteOptions');
      const options = [];
      if (Ember.isNone(routes)) return options.concat(dummyOptions).sortBy('id');
      routes.map(route => {
        options.push({
          id: route.get(routeConfig.indexPath),
          name: route.get(routeNameConfig.indexPath)
        });
      });
      return options.concat(dummyOptions).sortBy('id');
    }),
    vehicleOptions: Ember.computed('vehicles', 'dummyVehicleOptions', function () {
      const vehicles = this.get('vehicles');
      const vehicleConfig = this.get('config.vehicle');
      const dummyOptions = this.get('dummyVehicleOptions');
      const options = [];
      if (Ember.isNone(vehicles)) return options.concat(dummyOptions).sortBy('id');
      vehicles.map(vehicle => {
        options.push({
          id: vehicle.get(vehicleConfig.indexPath)
        });
      });
      return options.concat(dummyOptions).sortBy('id');
    }),
    driverOptions: Ember.computed('drivers', 'dummyDriverOptions', function () {
      const drivers = this.get('drivers');
      const driversConfig = this.get('config.driver');
      const dummyOptions = this.get('dummyDriverOptions');
      const options = [];
      if (Ember.isNone(drivers)) return options.concat(dummyOptions).sortBy('id');
      drivers.map(driver => {
        options.push({
          id: driver.get(driversConfig.indexPath)
        });
      });
      return options.concat(dummyOptions).sortBy('id');
    }),
    selectedRadiusVehicleCount: Ember.computed('selectedRadiusVehicle', 'radiusVehicles', function () {
      const selectedVehicle = Ember.get(this, 'selectedRadiusVehicle');
      const radiusVehicles = Ember.get(this, 'radiusVehicles');
      return (selectedVehicle ? [selectedVehicle].length : 0).toString() + '/' + radiusVehicles.length.toString();
    }),

    markerPinRegion(coord) {
      const map = this.get('map');
      let radius = this.get('selectedRadius.value') | 1;
      radius = radius * 1609.344;
      return window.L.circle(coord, {
        radius
      }).addTo(map.native);
    },

    onMarkerRadiusChanged(radius) {
      const searchCenter = this.get('searchCenter');

      if (searchCenter) {
        searchCenter.setRadius(radius * 1609.344);
      }
    },

    init() {
      Ember.set(this, 'initialMapLayers', null);
      Ember.set(this, 'allEvents', null);
      Ember.set(this, 'nextEvent', null);
      this.get('fetchVehiclesTask').perform();
      this.get('fetchRoutesTask').perform();
      this.get('fetchDriversTask').perform();
      const activeTabModeId = this.get('activeTabModeId');

      if (this.get('activeEvents') && this.get('activeEvents').length === 0 && activeTabModeId === 'byVehicle') {
        Ember.set(this, 'selectedDateTimeRage', {
          today: (0, _moment.default)().hours(0).minutes(0).format('YYYY-MM-DD'),
          date: null,
          starttime: null,
          endtime: null,
          startforendtime: (0, _moment.default)().startOf('day').toISOString(),
          startofday: (0, _moment.default)().startOf('day').toISOString(),
          endofday: (0, _moment.default)().endOf('day').toISOString(),
          endDateSelection: (0, _moment.default)().subtract(7, 'years').format('YYYY-MM-DD')
        });
      }

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      const config = this.get('config');
      const vehiclesTable = new _emberLightTable.default(config.vehiclesTableColumns);
      this.set('vehiclesTable', vehiclesTable);
      const vehicleSearchableColumns = [];
      config.vehiclesTableColumns.map(item => {
        if (item.searchable) vehicleSearchableColumns.push(item.valuePath);
      });
      const vehicleEventsTable = new _emberLightTable.default(config.vehicleEventsTableColumns);
      this.set('vehicleEventsTable', vehicleEventsTable);
      const vehicleEventSearchableColumns = [];
      config.vehicleEventsTableColumns.map(item => {
        if (item.searchable) vehicleEventSearchableColumns.push(item.valuePath);
      });
      Ember.set(this, 'vehicleSearchableColumns', vehicleSearchableColumns);
      Ember.set(this, 'vehicleEventSearchableColumns', vehicleEventSearchableColumns); // TODO: move these two to active context.

      this.set('durationInSeconds', 0);
      this.set('animSpeed', 1);
      Ember.set(this, 'dummyProviderOptions', [{
        id: 1,
        name: 'MVP'
      }, {
        id: 2,
        name: 'BCH'
      }, {
        id: 3,
        name: 'MVT'
      }, {
        id: 5,
        name: 'EPC'
      }, {
        id: 4,
        name: 'MAG'
      }]);
      Ember.set(this, 'dummyVehicleOptions', []);
      Ember.set(this, 'dummyRouteOptions', []);
      Ember.set(this, 'dummyDriverOptions', []);

      if (this.get('selectedAddress')) {
        Ember.run.scheduleOnce('afterRender', this, 'restoreSearchCenter');
      }

      if (this.get('radiusVehicles')) {
        Ember.run.scheduleOnce('afterRender', this, 'updateVehiclesTable');
      }

      if (this.get('vehicleEvents')) {
        Ember.run.scheduleOnce('afterRender', this, 'updateVehicleEventsTable');
      }
    },

    didInsertElement() {
      this._super(...arguments); // Need to remove this env condition once reconcile-workspace is ready for avlm mode.


      if (!_environment.default.APP.avlmLite) {
        this.createContextMenuOptions();
      }

      this.createButtonMenuOptions();
      this.onWidgetStateChange();
    },

    getMockTripData() {
      const routeId = 5178750;
      const tripId = '226077387';
      const trip = Ember.Object.create({
        id: tripId,
        externalId: tripId,
        avlmRoute: {
          id: routeId
        },
        route: {
          id: routeId
        }
      });
      return trip;
    },

    getMockRouteData() {
      const routeId = 5178750;
      const route = Ember.Object.create({
        id: routeId
      });
      return route;
    },

    createContextMenuOptions() {
      const config = this.get('config');
      Ember.set(this, 'rightClickActionArray', []);
      Ember.set(this, 'routeActivityLogAction', {
        id: 'routeActivityLog',
        name: 'Route Activity Log',
        permId: _mappedPermIds.default.activityLogRoute,
        action: vehicleEvent => {
          const routeId = Ember.get(vehicleEvent, 'routeId');
          const route = Ember.Object.create({
            id: routeId,
            routeId: routeId,
            adaptor: config.activitylog.adaptor,
            driver: config.driver.model
          });
          Ember.get(this, 'workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeId} Activity Log`,
            route
          });
        }
      });
      Ember.set(this, 'tripActivityLogAction', {
        id: 'tripActivityLog',
        name: 'Trip Activity Log',
        permId: _mappedPermIds.default.activityLog,
        action: vehicleEvent => {
          const tripId = Ember.get(vehicleEvent, 'tripId');
          const trip = Ember.Object.create({
            id: tripId,
            externalId: tripId,
            tripId: tripId,
            adaptor: config.activitylog.adaptor,
            driver: config.driver.model
          });
          Ember.get(this, 'workspace').pushState('tripActivityLog', {
            displayName: `Trip ${tripId} Activity Log`,
            trip
          });
        }
      });
      this.rightClickActionArray.push(Ember.get(this, 'routeActivityLogAction'));
      this.rightClickActionArray.push(Ember.get(this, 'tripActivityLogAction'));
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    updateVehiclesTable: Ember.observer('radiusVehicles', function () {
      const radiusVehicles = this.get('radiusVehicles');
      if (Ember.isNone(radiusVehicles)) return;
      this.get('vehiclesTable').setRows(radiusVehicles); // Update Table selected

      const rows = this.get('vehiclesTable.rows.content');
      const selectedRow = rows.findBy('selected');
      const selectedRadiusVehicle = this.get('selectedRadiusVehicle');

      if (!selectedRow && selectedRadiusVehicle) {
        const row = rows.findBy('id', selectedRadiusVehicle.get('id'));
        row.set('selected', true);
      } // Update Map


      const map = this.get('map');
      if (Ember.get(this, 'initialMapLayers') !== null) map.eachLayer(layer => {
        if (!Ember.get(this, 'initialMapLayers').includes(layer._leaflet_id)) {
          layer.remove();
        }
      });

      if (this.get('_radiusMarkersGroup')) {
        map.native.removeLayer(this.get('_radiusMarkersGroup'));
        this.set('_radiusMarkersGroup', null);
      }

      const radiusMarkersGroup = this.get('radiusMarkersGroup');

      if (Ember.isPresent(radiusMarkersGroup)) {
        this.set('_radiusMarkersGroup', radiusMarkersGroup);
        map.native.addLayer(radiusMarkersGroup);
      }
    }),
    updateVehicleEventsTable: Ember.observer('vehicleEvents', function () {
      const vehicleEvents = this.get('vehicleEvents');
      this.get('vehicleEventsTable').setRows(vehicleEvents);

      if (vehicleEvents && vehicleEvents.length > 0) {
        this.notifyPropertyChange('activeEvents');
        const widget = this.get('widget');

        if (widget && widget.state && widget.state.columns) {
          const columns = widget.state.columns;
          let bool = true;
          columns.map(data => {
            if (data && (data.filterValues || data.filterType && data.filterType.id === 'empty' || data.filterTypeId === 'empty')) {
              bool = false;
              this.updateTableColumns();
              return;
            }
          });

          if (bool) {
            this.setTableRows(vehicleEvents);
          }
        } else {
          this.setTableRows(vehicleEvents);
        }
      } else {
        this.setTableRows(vehicleEvents);
      }
    }),
    updatePlayback: Ember.observer('activeEvents', function () {
      const points = this.get('polylinePoints');
      const activeEvents = Ember.get(this, 'activeEvents');
      this.updateLabels();

      if (!Ember.isEmpty(points)) {
        if (Ember.get(this, 'initialMapLayers') === null) {
          const fetchLayerIds = [];
          Ember.get(this, 'map').eachLayer(layer => {
            fetchLayerIds.push(layer._leaflet_id);
          });
          Ember.set(this, 'initialMapLayers', fetchLayerIds);
        } else {
          Ember.get(this, 'map').eachLayer(layer => {
            if (!Ember.get(this, 'initialMapLayers').includes(layer._leaflet_id)) {
              layer.remove();
            }
          });
        }

        const polyline = window.L.polyline(points, {
          color: '#A9B2B5',
          dashArray: '2 9',
          opacity: 1,
          weight: 5
        });
        const directionalMarkers = window.L.markerClusterGroup();
        const directionalPoints = activeEvents.filter((value, index) => index % 50 === 0 && value.type === 'GPS Ping');
        const directionIcon = window.L.icon({
          iconUrl: _iconPaths.default.vehicleEvents.arrow,
          iconSize: [10, 10],
          iconAnchor: [3, 3]
        });
        directionalPoints.forEach(vehicle => {
          const marker = window.L.marker([vehicle.lat, vehicle.lng], {
            icon: directionIcon,
            rotationAngle: vehicle.heading / 2,
            rotationOrigin: '1px 1px'
          });
          directionalMarkers.addLayer(marker);
        });
        const nextEvent = activeEvents[0];
        const data = activeEvents.filter(event => event.type !== 'GPS Ping');
        const bounds = window.L.latLngBounds(points);
        const events = data.map(event => {
          event.isPerformed = false;
          return event;
        });
        Ember.set(this, 'allEvents', events);
        Ember.set(this, 'nextEvent', nextEvent);
        Ember.set(this, '_directionalMarkerLayer', directionalMarkers);
        this.set('pbounds', bounds);
        this.set('polyline', polyline);
        this.set('totolTimeInSeconds', ((0, _moment.default)(this.get('activeEvents.lastObject.timestamp')).valueOf() - (0, _moment.default)(this.get('activeEvents.firstObject.timestamp')).valueOf()) / 1000);
      } else {
        // If empty points, let us keep bounds
        this.set('polyline', null);
      }

      this.updateContent(activeEvents);
    }),
    sliderIndex: null,
    elapsedTime: 0,
    //targetRow: null,
    targetRow: Ember.computed('vehicleEventsTable', function () {
      let rows = null;

      if (this.get('vehicleEventsTable') && this.get('vehicleEventsTable.rows.content')) {
        rows = this.get('vehicleEventsTable.rows.content');
        return rows[0];
      }

      return rows;
    }),
    sliderIndexObserver: Ember.observer('sliderIndex', function () {
      if (this.get('bufferSize') <= 4000) {
        const bufferSize = this.get('bufferSize') + 200;
        this.set('bufferSize', bufferSize);
      }

      const rows = this.get('vehicleEventsTable.rows.content');
      const sliderIndex = this.get('sliderIndex');
      if (Ember.isEmpty(rows) || Ember.isNone(sliderIndex)) return;
      const currentRow = rows[sliderIndex];
      const previousRow = rows.findBy('selected');

      if (currentRow.get('isChecked')) {
        if (previousRow) {
          previousRow.set('selected', false);
        }

        currentRow.set('selected', true);
        this.set('targetRow', currentRow); //this.set('rowSelection', currentRow);

        const allRows = document.querySelector('.vehicle-event-table-body tbody').querySelectorAll('tr');
        allRows.forEach(row => row.classList.remove('is-selected'));
        allRows[sliderIndex].classList.add('is-selected');

        if (sliderIndex > 1) {
          allRows[sliderIndex - 1].scrollIntoView(true, {
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    }),
    onWidgetStateChange: Ember.observer('widget.state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),

    setTableRows(vehicleEvents) {
      this.get('vehicleEventsTable').setRows(vehicleEvents);
      this.notifyPropertyChange('activeEvents');
    },

    updateTableColumns() {
      const state = this.get('widget.state') || {};
      const config = this.get('config') || {};
      const tableName = this.get('tableName') || null;
      let columns = this.get('vehiclesTable.columns') || [];

      if (tableName === 'vehicleEventsTable') {
        columns = this.get('vehicleEventsTable.columns') || [];
      }

      const selectedRow = this.get('selectedRadiusVehicle');
      let shouldResize = false;
      let scrollLeft;
      const body = this.$('.column-widget-body');

      if (body) {
        scrollLeft = this.$('.column-widget-body').scrollLeft();
      }

      if (columns) {
        columns.forEach(column => {
          const sortId = state.sortId || config.defaultSortId;

          if (config.modelName === 'avlm-alert') {
            config.defaultSortId = 'priorityId';
          }

          if (Ember.isPresent(sortId) && sortId === column.get('id') || config.modelName === 'avlm-alert' && Ember.isPresent(sortId) && sortId === 'priorityId' && column.get('id') === 'priority') {
            let sortAsc;

            if (Ember.isPresent(state.sortAsc)) {
              sortAsc = state.sortAsc;
            } else if (Ember.isPresent(config.defaultSortAsc)) {
              sortAsc = config.defaultSortAsc;
            } else {
              sortAsc = true;
            }

            column.set('sorted', true);
            column.set('ascending', sortAsc);
          } else {
            column.set('sorted', false);
          }
        });
      }

      const columnsHash = state.columns || {};
      Object.entries(columnsHash).forEach(_ref => {
        let [id, columnState] = _ref;
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (Ember.isPresent(columnState.hidden)) {
          column.set('hidden', columnState.hidden);
          shouldResize = true;
        }

        if (Ember.isPresent(columnState.index)) {
          // +/- 1 accounts for checkbox column
          const oldIndex = columns.indexOf(column) - 1;

          if (columnState.index !== oldIndex) {
            const swapColumn = columns.objectAt(1 + columnState.index);
            columns.replace(1 + columnState.index, 1, [column]);
            columns.replace(1 + oldIndex, 1, [swapColumn]);
            shouldResize = true;
          }
        }

        const wasFiltered = column.get('isFiltered');
        ['filterTypeId', 'filterValues', 'filterType'].forEach(prop => {
          // allow null value to overwrite
          if (!columnState.hasOwnProperty(prop)) {
            return;
          }

          column.set(prop, columnState[prop]);
        });

        if (wasFiltered || column.get('isFiltered')) {
          Ember.run.scheduleOnce('afterRender', this, 'refreshData');
        }

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
          shouldResize = true;
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }

      if (!Ember.isEmpty(tableName)) {
        const table = this.get(tableName) || null;
        const resultModel = [];

        if (table) {
          const searchColumn = columns.filter(column => {
            if (column.filterType && column.filterType !== null && column.filterValues && column.filterValues !== null) return column;
          });
          Ember.get(this, tableName === 'vehicleEventsTable' ? 'vehicleEvents' : 'radiusVehicles').map(item => {
            if (Ember.isEmpty(searchColumn)) resultModel.push(item);else {
              let bool = true;
              searchColumn.forEach(column => {
                if (column.filterType && column.filterType.exp && column.filterType.exp(item[column.valuePath], column.filterValues)) return;
                bool = false;
              });
              if (bool) resultModel.push(item);
            }
          });

          if (resultModel) {
            const rows = [...new Set(resultModel)];

            if (tableName === 'vehicleEventsTable') {
              Ember.set(this, 'filteredEvents', rows.toArray());
              this.notifyPropertyChange('filteredEvents');
            }

            table.setRowsSynced(rows.toArray());

            if (tableName === 'vehiclesTable' && selectedRow && selectedRow.get('id')) {
              const row = table.rows.content.findBy('id', selectedRow.get('id'));

              if (row) {
                row.set('selected', true);
              }
            }
          }
        }
      }

      this.set('eventTableFilterDone', this.vehicleEventsTable.rows.content);
      this.set('vehicleTableFilterDone', this.vehiclesTable.rows.content);
      Ember.run.debounce(this, 'refreshData', 250);
    },

    refreshData() {
      if (this.get('isDestroyed')) return;
      this.get('fetchDataTask').perform().then(() => {
        if (this.get('activeContext.topActiveContext.refreshInProgress')) {
          this.set('activeContext.topActiveContext.refreshInProgress', false);
        }
      }).catch(err => {
        if (this.get('activeContext.topActiveContext.refreshInProgress')) {
          this.set('activeContext.topActiveContext.refreshInProgress', false); // eslint-disable-next-line no-console

          console.log('Error occoured when refreshing the Data' + err);
        }

        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    fetchDataTask: (0, _emberConcurrency.task)(function* () {// const records = yield this.fetchDataQuery();
      // this.set('records', records);
    }).restartable(),

    updateContent(activeEvents) {
      const map = Ember.get(this, 'map');
      let events = [];

      if (activeEvents && activeEvents.lenght === 0) {
        Ember.set(this, 'allEvents', null);
      } else events = activeEvents;

      if (!this.get('map')) return;

      if (this.get('_polyline')) {
        this.get('_polyline').remove(map.native);
        this.set('_polyline', null);
      }

      if (this.get('_playedPolyline')) {
        this.get('_playedPolyline').remove(map.native);
        this.set('_playedPolyline', null);
      }

      if (this.get('_nextEventPolyline')) {
        this.get('_nextEventPolyline').remove(map);
        this.set('_nextEventPolyline', null);
      }

      if (this.get('_movingMarker')) {
        map.native.removeLayer(this.get('_movingMarker'));
        this.set('_movingMarker', null);
      }

      if (this.get('_eventsMarkers') && this.get('_eventsMarkers').length !== 0 && events.length === 0) {
        map.eachLayer(layer => {
          if (layer.myCustomID) {
            layer.remove();
          }
        });
        Ember.set(this, '_eventsMarkers', null);
        Ember.set(this, 'allEvents', null);
      }

      if (this.get('_directionalMarkerLayer') && events.length === 0) {
        map.eachLayer(layer => {
          if (layer._leaflet_id === Ember.get(this, '_directionalMarkerLayer')._leaflet_id || !Ember.get(this, 'initialMapLayers').includes(layer._leaflet_id)) {
            layer.remove();
          }
        });
        Ember.set(this, '_directionalMarkerLayer', null);
      }

      const polyline = this.get('polyline');
      const pbounds = this.get('pbounds');
      const movingMarker = this.get('movingMarker');
      const eventMarkers = Ember.get(this, '_eventsMarkers');
      const directionalMarkers = Ember.get(this, '_directionalMarkerLayer');
      this.set('_polyline', polyline);
      this.set('_movingMarker', movingMarker);

      if (polyline) {
        polyline.addTo(map.native);
      }

      if (pbounds) {
        map.native.fitBounds(pbounds);

        const filteredArray = polyline._latlngs.filter(function (data) {
          return data !== null;
        });

        polyline._latlngs = filteredArray;
        polyline.addTo(map);
      }

      if (pbounds && pbounds.isValid()) {
        map.fitBounds(pbounds);
      }

      if (movingMarker) {
        const layer = movingMarker.addTo(map.native);
        layer.on('lineUpdate', this.get('updateIndex').bind(this));
        layer.on('end', this.get('playbackEnd').bind(this));
      }

      if (eventMarkers) {
        eventMarkers.map(marker => {
          marker.addTo(map);
        });
      }

      if (directionalMarkers) {
        map.addLayer(directionalMarkers);
      }

      this.set('sliderIndex', null);
      this.set('elapsedTime', 0);
    },

    getIconInfo(eventType, state) {
      let name = null;

      if (state === 'pending') {
        switch (eventType) {
          case 'pick':
            name = _iconPaths.default.vehicleEvents.pickPlanned;
            break;

          case 'wheelchair-pick':
            name = _iconPaths.default.vehicleEvents.pickPlannedWheelchair;
            break;

          case 'wheelchair-drop':
            name = _iconPaths.default.vehicleEvents.dropPlannedWheelchair;
            break;

          case 'offBreak':
            name = _iconPaths.default.vehicleEvents.break;
            break;

          case 'onBreak':
            name = _iconPaths.default.vehicleEvents.break;
            break;

          case 'drop':
            name = _iconPaths.default.vehicleEvents.dropPlanned;
            break;

          case 'pullOut':
          case 'pullIn':
            name = _iconPaths.default.vehicleEvents.garagePlanned;
            break;

          case 'noShow':
            name = _iconPaths.default.vehicleEvents.noshow;
            break;

          default:
            break;
        }
      } else if (state === 'performed') {
        switch (eventType) {
          case 'pick':
            name = _iconPaths.default.vehicleEvents.pickCompleted;
            break;

          case 'wheelchair-pick':
            name = _iconPaths.default.vehicleEvents.pickCompleted;
            break;

          case 'wheelchair-drop':
            name = _iconPaths.default.vehicleEvents.dropCompletedWheelchair;
            break;

          case 'onBreak':
            name = _iconPaths.default.vehicleEvents.break;
            break;

          case 'offBreak':
            name = _iconPaths.default.vehicleEvents.break;
            break;

          case 'drop':
            name = _iconPaths.default.vehicleEvents.dropCompleted;
            break;

          case 'pullOut':
          case 'pullIn':
            name = _iconPaths.default.vehicleEvents.garageCompleted;
            break;

          case 'noShow':
            name = _iconPaths.default.vehicleEvents.noshow;
            break;

          default:
            break;
        }
      }

      return name;
    },

    updatePlayedPolyline(e) {
      const map = this.get('map');
      const activeEvents = this.get('activeEvents').filter(event => event.latlng !== null);
      const allEvents = Ember.get(this, 'allEvents');

      if (e.lineIndex > 0) {
        const playedEvents = activeEvents.slice(0, e.lineIndex + 1);

        if (playedEvents.length > 0) {
          const playedPoints = [];
          playedEvents.map(event => playedPoints.push(event.latlng));

          if (playedEvents.length > 2) {
            const point = window.L.interpolatePosition(playedEvents[playedEvents.length - 2], playedEvents[playedEvents.length - 1], e.currentDuration, e.elapsedTime); // Replace the last points

            playedPoints[playedPoints.length - 1] = point;
          }

          const playedPolyline = window.L.polyline(playedPoints, {
            color: '#555e63',
            dashArray: '2 9',
            opacity: 1,
            weight: 5
          });

          if (this.get('_playedPolyline')) {
            this.get('_playedPolyline').remove(map);
          }

          this.set('_playedPolyline', playedPolyline);

          if (playedPolyline) {
            playedPolyline.addTo(map);
          }
        }
      }

      if (activeEvents.length > 0 && e.lineIndex > 0 && allEvents) {
        const filterEvents = allEvents.filter(event => event.timestamp <= activeEvents[e.lineIndex].timestamp && !event.isPerformed && event.type !== 'GPS Ping');

        if (filterEvents.length !== 0) {
          const timestamps = [];
          filterEvents.map(stopData => {
            timestamps.push(stopData.timestamp);
          });
          allEvents.map(event => {
            if (timestamps.includes(event.timestamp)) {
              event.isPerformed = true;
            }
          });
          Ember.set(this, 'allEvents', allEvents);
          this.addEventMarkerOnMap();
        }
      } // reset all events


      if (e.lineIndex === 0 && allEvents) {
        allEvents.map(event => {
          event.isPerformed = false;
          return event;
        });
        Ember.set(this, 'allEvents', allEvents);
        this.addEventMarkerOnMap();
      }
    },

    // Creates planned poly line till next pick or drop
    updateEventPolyline(e) {
      if (e.lineIndex >= 0) {
        const activeEvents = this.get('activeEvents').filter(event => event.latlng !== null);
        const map = this.get('map');
        const nextEvent = Ember.get(this, 'nextEvent');
        const events = activeEvents.slice(e.lineIndex);
        const nextEventValue = events.findIndex(event => event.type === 'pick' || event.type === 'drop' || event.type === 'wheelchair-pick' || event.type === 'wheelchair-drop');

        if (nextEventValue !== -1 && activeEvents[e.lineIndex].eventId >= nextEvent.eventId) {
          const nextEvents = events.slice(0, nextEventValue + 1);

          if (nextEvents.length > 0) {
            const nextPoints = [];
            nextEvents.map(event => nextPoints.push(event.latlng));

            if (nextEvents.length > 2) {
              const point = window.L.interpolatePosition(nextEvents[nextEvents.length - 2], nextEvents[nextEvents.length - 1], e.currentDuration, e.elapsedTime); // Replace the last points

              nextPoints[nextPoints.length - 1] = point;
            }

            const nextEventPolyline = window.L.polyline(nextPoints, {
              color: '#3697c7',
              dashArray: '2 9',
              opacity: 1,
              weight: 5
            });

            if (nextEventPolyline) {
              nextEventPolyline.addTo(map.native);
            }

            if (Ember.get(this, '_nextEventPolyline')) {
              Ember.get(this, '_nextEventPolyline').remove(map.native);
            }

            Ember.set(this, '_nextEventPolyline', nextEventPolyline); // set next event item

            Ember.set(this, 'nextEvent', events[nextEventValue]);
          } else if (activeEvents.length - 2 < e.lineIndex) {
            Ember.get(this, '_nextEventPolyline').remove(map.native);
          }
        }
      }
    },

    updateIndex(e) {
      // If slider is dragged, stop updating sliderIndex
      if (!this.get('isSlideMoving')) {
        // lineIndex is index for duration i to i+1
        if (this.get('sliderIndex') !== e.lineIndex) {
          this.set('sliderIndex', e.lineIndex);
        }

        if (e.elapsedTime) {
          this.set('elapsedTime', e.elapsedTime);
        }

        this.updateEventPolyline(e);
        this.updatePlayedPolyline(e);
      }
    },

    // Update events and planned polyline on backword button
    updateEventsandPlannedPolyline(e) {
      const allEvents = Ember.get(this, 'allEvents');
      const activeEvents = Ember.get(this, 'activeEvents');
      const map = this.get('map');
      const events = activeEvents.slice(e.lineIndex);
      const nextEventValue = events.findIndex(event => event.type === 'pick' || event.type === 'drop' || event.type === 'wheelchair-pick' || event.type === 'wheelchair-drop');
      const filterEvents = allEvents.filter(event => event.timestamp > activeEvents[e].timestamp && event.isPerformed);

      if (filterEvents.length !== 0) {
        const timestamps = [];
        filterEvents.map(stopData => {
          timestamps.push(stopData.timestamp);
        });
        allEvents.map(event => {
          if (timestamps.includes(event.timestamp)) {
            event.isPerformed = false;
          }
        });
        Ember.set(this, 'allEvents', allEvents);
        this.addEventMarkerOnMap();
      }

      if (nextEventValue !== -1) {
        const nextEvents = events.slice(0, nextEventValue + 1);

        if (nextEvents.length > 0) {
          const nextPoints = [];
          nextEvents.map(event => nextPoints.push(event.latlng));

          if (nextEvents.length > 2) {
            const point = window.L.interpolatePosition(nextEvents[nextEvents.length - 2], nextEvents[nextEvents.length - 1], e.currentDuration, e.elapsedTime); // Replace the last points

            nextPoints[nextPoints.length - 1] = point;
          }

          const nextEventPolyline = window.L.polyline(nextPoints, {
            color: '#3697c7',
            dashArray: '2 9',
            opacity: 1,
            weight: 5
          });

          if (nextEventPolyline) {
            nextEventPolyline.addTo(map);
          }

          if (Ember.get(this, '_nextEventPolyline')) {
            Ember.get(this, '_nextEventPolyline').remove(map);
          }

          Ember.set(this, '_nextEventPolyline', nextEventPolyline);
          Ember.set(this, 'nextEvent', events[nextEventValue]);
        }
      }
    },

    playbackEnd() {
      this.set('sliderIndex', this.get('sliderIndexMax'));
      this.notifyPropertyChange('isRunning');
    },

    updateMapView() {},

    restoreSearchCenter() {
      const address = this.get('selectedAddress');

      if (address) {
        this.setSearchCenter(window.L.latLng(address.get('lat'), address.get('lng')));
      }
    },

    // overwirte mapwidgets
    updateMap() {
      // don't try to update until map is initialized
      if (!this.get('map')) return;
      const map = this.get('map');
      map.native.on('zoom', this._mapZoomMethodHandler);
    },

    reCenterMapView() {
      const map = this.get('map');
      const pbounds = this.get('pbounds');
      let zoom = map.getZoom(); // get user's current zoom level

      if (!map) return;

      if (pbounds) {
        map.native.fitBounds(pbounds);
      } else {
        const centerLat = this.get('centerLat');
        const centerLng = this.get('centerLng');
        zoom = zoom >= this.get('zoom') ? this.get('zoom') : zoom;
        map.move(centerLat, centerLng, zoom);
      }
    },

    setSearchCenter(coord) {
      if (this.get('searchCenter')) {
        this.get('searchCenter').remove();
      }

      this.set('searchCenter', this.markerPinRegion(coord));
      this.set('markerLocation', { ...coord,
        lon: coord.lng
      });
    },

    searchCenterReset() {
      if (this.get('searchCenter')) {
        this.get('searchCenter').remove();
      }

      this.setProperties({
        searchCenter: null
      });
    },

    updateLabels() {
      const layers = Ember.get(this, 'layers');
      const activeTooltipLabels = [];
      const activeMarkersArray = [];
      let displayAlways = false;

      if (this.get('_eventsMarkers') && this.get('_eventsMarkers').length !== 0) {
        Ember.get(this, 'map').eachLayer(layer => {
          if (layer.myCustomID) {
            layer.remove();
          }
        });
        Ember.set(this, '_eventsMarkers', null);
      }

      if (layers) {
        layers.map(data => {
          if (data && data.id === 'vehicle-events') {
            const vehicleEventLegendsData = data.labels;
            const vehicleEventLabelsData = data.types;
            vehicleEventLegendsData.map(item => {
              if (item && item.isVisible) {
                activeTooltipLabels.push(item.id);
              }
            });
            vehicleEventLabelsData.map(item => {
              if (item && item.isVisible) {
                activeMarkersArray.push(item.filterValue);
              }
            });

            if (data.selectedDisplay === 'Always') {
              displayAlways = true;
            }

            if (this.get('movingMarker') && displayAlways) {
              setTimeout(function () {
                if (this.get('targetRow')) {
                  const currentRow = this.get('targetRow.content');
                  const formattedEventType = 'Vehicle <br/>';
                  let label = formattedEventType.bold(); // NOTE: DON'T DELETE SPPED CODE
                  // speed will be implemented later therefore commented the code, not removed the code.
                  // let speed = 'N/A';
                  // if (currentRow.speed) {
                  //   speed = currentRow.speed;
                  // }
                  // if (currentRow.speed === 0) {
                  //   speed = 0;
                  // }

                  let routeName = 'N/A';

                  if (currentRow.routeName) {
                    routeName = currentRow.routeName;
                  }

                  label = label + 'Vehicle ID: ' + currentRow.vehicleId + '<br/>'; // speed will be implemented later therefore commented the code, not removed the code.
                  // label = label + 'Vehicle Speed: ' + speed + '<br/>';

                  label = label + 'Route: ' + routeName + '<br/>';
                  this.get('movingMarker').unbindPopup();
                  this.get('movingMarker').bindPopup(label, {
                    closeButton: true
                  }).openPopup();
                }
              }.bind(this), 500);
            }

            if (this.get('movingMarker') && displayAlways === false) {
              setTimeout(() => {
                this.get('movingMarker').closePopup();
              }, 500);
            }

            Ember.get(this, 'mapService').setDisplayMapIconLabelAllTime(data.id, displayAlways);
          }
        });
        Ember.set(this, 'displayAlways', displayAlways);
        Ember.set(this, 'activeTooltipLabels', activeTooltipLabels);
        Ember.set(this, 'activeMarkersArray', activeMarkersArray);
        this.addEventMarkerOnMap();
      }
    },

    onClick(item) {
      const formattedEventType = item.type === 'offBreak' || item.type === 'onBreak' ? 'Break <br/>' : item.type.charAt(0).toUpperCase() + item.type.slice(1) + '<br/>';
      let label = formattedEventType.bold();
      const activeTooltipLabels = Ember.get(this, 'activeTooltipLabels');
      activeTooltipLabels.map(val => {
        switch (val) {
          case 'eventType':
            label = label + 'Event Type: ' + item.eventType + '<br/>';
            break;

          case 'timestamp':
            label = label + 'Date: ' + item.timestamp.toISOString().split('T')[0] + '<br/>';
            break;

          case 'stopType':
            label = label + 'Stop Type: ' + item.stopType + '<br/>';
            break;

          case 'time':
            label = label + 'Time: ' + item.time + '<br/>';
            break;

          case 'vehicleId':
            label = label + 'Vehicle ID: ' + item.vehicleId + '<br/>';
            break;

          case 'passengerId':
            label = label + 'Passenger ID: ' + item.passengerId + '<br/>';
            break;

          case 'lat':
            label = label + 'Latitude: ' + item.lat + '<br/>';
            break;

          case 'lng':
            label = label + 'Longitude: ' + item.lng + '<br/>';
            break;

          case 'speed':
            label = label + 'Speed: ' + item.speed + '<br/>';
            break;

          case 'heading':
            label = label + 'Heading: ' + item.heading + '<br/>';
            break;

          case 'passengerFName':
            label = label + 'Passenger First Name: ' + item.passengerFName + '<br/>';
            break;

          case 'passengerLName':
            label = label + 'Passenger Last Name: ' + item.passengerLName + '<br/>';
            break;

          case 'odo':
            label = label + 'Odometer: ' + item.odo + '<br/>';
            break;

          case 'route':
            label = label + 'Route: ' + item.routeName + '<br/>';
            break;

          case 'stopAddress':
            label = label + 'Address: ' + item.stopAddress + '<br/>';
            break;

          case 'trip':
            label = label + 'Trip: ' + item.tripId + '<br/>';
            break;

          default:
            break;
        }
      });
      return label;
    },

    addEventMarkerOnMap() {
      const allEvents = Ember.get(this, 'allEvents');
      const map = Ember.get(this, 'map');
      const displayAlways = Ember.get(this, 'displayAlways'); // const markersGroup = tomtom.L.markerClusterGroup();

      if (allEvents && allEvents.length !== 0) {
        const eventMarkers = [];
        let customMarkerId = null;
        allEvents.map(stopData => {
          if (stopData && stopData.lat && stopData.lng && Ember.get(this, 'activeMarkersArray').includes(stopData.type)) {
            customMarkerId = stopData.eventId;
            const iconUrl = this.getIconInfo(stopData.type, stopData.isPerformed ? 'performed' : 'pending');

            if (iconUrl !== null) {
              const pintPointIcon = {
                icon: window.L.icon({
                  iconUrl: iconUrl,
                  iconSize: [20, 20],
                  iconAnchor: [11.5, 11.5]
                })
              };
              const markers = window.L.marker([stopData.lat, stopData.lng], pintPointIcon);
              markers.myCustomID = customMarkerId;

              if (Ember.get(this, '_eventsMarkers')) {
                map.eachLayer(layer => {
                  if (layer.myCustomID) {
                    layer.remove();
                  }
                });
                Ember.set(this, '_eventsMarkers', null);
              } // map.addLayer(markers);


              markers.addTo(map);

              if (!displayAlways) {
                markers.on('click', () => {
                  const labels = this.onClick(stopData);
                  markers.unbindPopup();
                  markers.bindPopup(labels, {
                    closeButton: true
                  }).openPopup();
                });
              } else {
                const labels = this.onClick(stopData);
                markers.unbindPopup();
                markers.bindPopup(labels, {
                  closeButton: true,
                  autoClose: false
                }).openPopup();
              }

              eventMarkers.push(markers);
            }
          }
        }); // if (displayAlways) map.addLayer(markersGroup);

        Ember.set(this, '_eventsMarkers', eventMarkers);
      }
    },

    canSearch: Ember.computed('activeTabModeId', 'selectedVehicle', 'selectedProvider', 'selectedAddress', 'selectedRadius', 'selectedDateTimeRage.{date,starttime,endtime}', function () {
      const selectedVehicleId = this.get('selectedVehicle.id');
      const selectedProvider = this.get('selectedProvider.name');
      const selectedAddress = this.get('selectedAddress');
      const selectedRadius = this.get('selectedRadius');
      const selectedStarttime = this.get('selectedDateTimeRage.starttime');
      const selectedendtime = this.get('selectedDateTimeRage.endtime');
      const activeTabModeId = this.get('activeTabModeId');

      if (activeTabModeId === 'byVehicle' && Ember.isNone(selectedProvider)) {
        return false;
      }

      if (activeTabModeId === 'byVehicle' && Ember.isNone(selectedVehicleId)) {
        return false;
      }

      if (activeTabModeId === 'byLocation' && (Ember.isNone(selectedAddress) || Ember.isNone(selectedRadius))) {
        return false;
      }

      if (selectedStarttime && selectedendtime && (0, _moment.default)(selectedStarttime) < (0, _moment.default)(selectedendtime)) {
        return true;
      }

      return false;
    }),
    searchByLocation: Ember.computed('activeTabModeId', function () {
      const activeTabModeId = this.get('activeTabModeId');
      if (activeTabModeId === 'byLocation') return true;
      return false;
    }),
    canPlay: Ember.computed('activeEvents', function () {
      const activeEvents = this.get('activeEvents');
      if (activeEvents.length > 1) return true;
      return false;
    }),

    createButtonMenuOptions() {
      this.set('buttonActionOptions', [{
        id: 'print',
        name: 'Download',
        action: param => {
          this.onDownloadClick(param);
        }
      }]);
    },

    onDownloadClick(tableName) {
      const table = this.get(tableName);
      const columns = table.get('columns') || [];
      const rows = table.get('rows') || [];
      const fields = columns.reduce((arr, column) => {
        const id = column.get('id');

        if (['checkbox', 'spacer', 'radio'].includes(id)) {
          return arr;
        }

        const label = column.get('label');
        arr.push(label);
        return arr;
      }, []);
      const data = rows.map(row => {
        return columns.reduce((arr, column) => {
          const id = column.get('id');

          if (['checkbox', 'spacer', 'radio'].includes(id)) {
            return arr;
          }

          const valuePath = column.get('valuePath');
          const value = row.get(valuePath);
          arr.push(value);
          return arr;
        }, []);
      });

      const csv = _papaparse.default.unparse({
        fields,
        data
      }, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"'
      });

      const link = document.createElement('a');
      const timestamp = (0, _moment.default)().format('YYYY-MM-DD_HH-MM-SS');
      const {
        title
      } = this.get('config');
      const filename = `${timestamp}_${title}.csv`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    filterTableData(searchText) {
      const tableName = this.get('activeSearchTable');

      if (!Ember.isEmpty(tableName)) {
        const table = this.get(tableName);
        const tableColumns = tableName === 'vehicleEventsTable' ? Ember.get(this, 'vehicleEventSearchableColumns') : Ember.get(this, 'vehicleSearchableColumns');
        const search = searchText !== null ? searchText.trim().toLowerCase().split('=') : [];
        const resultModel = [];
        Ember.get(this, tableName === 'vehicleEventsTable' ? 'vehicleEvents' : 'radiusVehicles').map(item => {
          if (Ember.isEmpty(searchText)) resultModel.push(item);

          if (search && search.length > 1 && search[1] && search[1] !== '' && tableColumns.join(',').toLowerCase().includes(search[0].trim().toLowerCase().replace(' ', ''))) {
            const colm = tableColumns.map(column => column.toLowerCase());
            const index = colm.indexOf(search[0].trim().toLowerCase().replace(' ', ''));
            if (index !== -1 && item[tableColumns[index]] && item[tableColumns[index]].toString().toLowerCase().indexOf(search[1].trim().toLowerCase()) !== -1) resultModel.push(item);
          } else if (search.length === 1) tableColumns.filter(column => {
            if (item[column] && item[column].toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
              resultModel.push(item);
              return;
            }
          });
        });

        if (resultModel) {
          const rows = [...new Set(resultModel)];

          if (tableName === 'vehicleEventsTable') {
            Ember.set(this, 'filteredEvents', rows.toArray());
            this.notifyPropertyChange('filteredEvents');
          }

          table.setRowsSynced(rows.toArray());

          if (this.get('activeSearchTable') === 'vehicleEventsTable') {
            this.set('eventTableFilterDone', rows.toArray());
          }

          if (this.get('activeSearchTable') === 'vehiclesTable') {
            this.set('vehicleTableFilterDone', rows.toArray());
          }
        }
      }
    },

    actions: {
      onDownloadClose(action, dropdown, param) {
        dropdown.actions.close();
        if (action.action) action.action(param);
      },

      onProviderOptionSelect(option) {
        Ember.set(this, 'selectedProvider', option);
        this.get('fetchVehiclesTask').perform();
        this.get('fetchRoutesTask').perform();
        this.get('fetchDriversTask').perform();
        Ember.set(this, 'selectedRoute', null);
        Ember.set(this, 'selectedVehicle', null);
      },

      onRouteOptionsSelect(option) {
        Ember.set(this, 'selectedRoute', option);
      },

      onDriverOptionsSelect(option) {
        Ember.set(this, 'selectedDriver', option);
      },

      onVehicleOptionsSelect(option) {
        Ember.set(this, 'selectedVehicle', option);
      },

      onVehicleKeydown(dropdown, e) {
        if (e.keyCode !== 13) return;
        const vehicleId = e.target.value;

        if (vehicleId.length > 0 && dropdown.results.length === 0) {
          const dummyOption = {
            id: vehicleId,
            model: null
          };
          this.get('dummyVehicleOptions').pushObject(dummyOption);
          this.notifyPropertyChange('dummyVehicleOptions');
          this.set('selectedVehicle', dummyOption);
        }
      },

      onRouteKeydown(dropdown, e) {
        if (e.keyCode !== 13) return;
        const routeId = e.target.value;

        if (routeId.length > 0 && dropdown.results.length === 0) {
          const dummyOption = {
            id: routeId,
            model: null
          };
          this.get('dummyRouteOptions').pushObject(dummyOption);
          this.notifyPropertyChange('dummyRouteOptions');
          this.set('selectedRoute', dummyOption);
        }
      },

      onRadiusOptionsSelect(option) {
        this.set('selectedRadius', option);
        this.onMarkerRadiusChanged(option.value);
      },

      onValueChange(valuePath, value) {
        this.set(valuePath, value);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());

        if (valuePath === 'selectedDateTimeRage.starttime') {
          this.set('selectedDateTimeRage.startforendtime', momentTime.toISOString());
        }

        if (valuePath === 'selectedDateTimeRage.date') {
          this.set('selectedDateTimeRage.startime', null);
          this.set('selectedDateTimeRage.endtime', null);
          this.set('selectedDateTimeRage.startforendtime', momentTime.startOf('day').toISOString());
          this.set('selectedDateTimeRage.startofday', momentTime.startOf('day').toISOString());
          this.set('selectedDateTimeRage.endofday', momentTime.endOf('day').toISOString());
        }
      },

      onClearButtonClick() {
        this.clearAll();
      },

      onSearchButtonClick() {
        this.set('totolTimeInSeconds', null);
        this.set('durationInSeconds', null);
        this.set('animSpeed', 1);
        Ember.set(this, 'animDisable', false);
        this.searchCenterReset();
        this.set('pbounds', null);
        this.set('selectedRadiusVehicle', null);
        Ember.set(this, 'filteredEvents', null);
        this.set('tooLargeData', '');
        this.notifyPropertyChange('filteredEvents');
        if (!Ember.isEmpty(this.get('vehicleEvents'))) Ember.set(this, 'vehicleEvents', []);
        if (!Ember.isEmpty(this.get('radiusVehicles'))) Ember.set(this, 'radiusVehicles', []);
        this.notifyPropertyChange('vehicleEvents');
        this.notifyPropertyChange('radiusVehicles');

        if (this.get('activeTabModeId') === 'byVehicle') {
          this.get('fetchEventsTask').perform();
        } else {
          this.get('fetchRadiusVehiclesTask').perform();
        }
      },

      // eslint-disable-next-line consistent-this
      onVehicleRowClick(row, self) {
        const rows = self.get('vehiclesTable.rows.content');
        const selectedRow = rows.findBy('selected');
        const vehicleIcon = window.L.icon({
          iconUrl: _iconPaths.default.vehicles.vehicle,
          iconSize: [23, 23],
          iconAnchor: [11.5, 11.5]
        });
        const selectedVehicleIcon = window.L.icon({
          iconUrl: _iconPaths.default.vehicles.vehicle,
          iconSize: [23, 23],
          iconAnchor: [11.5, 11.5],
          shadowUrl: _iconPaths.default.maps.highlight,
          shadowSize: [27, 27],
          shadowAnchor: [13.5, 13.5]
        });
        if (selectedRow && row.get('id') === selectedRow.get('id')) return;

        if (!Ember.isEmpty(self.get('vehicleEvents'))) {
          Ember.set(self, 'vehicleEvents', []);
          Ember.set(self, 'filteredEvents', null);
          self.notifyPropertyChange('vehicleEvents');
        }

        rows.forEach(_row => {
          if (_row.get('id') === row.get('id')) {
            _row.content.marker.native.setIcon(selectedVehicleIcon);
          } else {
            _row.content.marker.native.setIcon(vehicleIcon);
          }
        });
        self.set('selectedRadiusVehicle', row.content);
        self.notifyPropertyChange('selectedRadiusVehicle');
        self.get('fetchEventsTask').perform();
      },

      onToggleCheckbox() {
        this.notifyPropertyChange('activeEvents', 'sliderIndexMax');
      },

      // eslint-disable-next-line consistent-this
      onEventRowClick(row, self) {
        const rows = self.get('vehicleEventsTable.rows.content');
        const activeEvents = self.get('activeEvents');
        let index = 0;

        for (const _row of rows) {
          if (_row === row) {
            break;
          }

          if (_row.get('isChecked')) {
            index++;
          }
        }

        if (self.get('_movingMarker')) {
          if (index === activeEvents.length - 1) {
            self.get('movingMarker').stop();
          } else {
            self.get('_movingMarker').setTo(index);
          }

          self.set('sliderIndex', index);
          self.set('elapsedTime', 0);
        }
      },

      onEventColumnClick() {},

      setInlineRowMenu(dropdown) {
        Ember.set(this, 'inlineRowMenu', dropdown);
      },

      onSingleRowAction(action) {
        const model = Ember.get(this, 'lastRowClicked');
        this.inlineRowMenu.actions.close();

        if (action.action) {
          action.action(model);
        }
      },

      onRowRightClick(row, event) {
        Ember.set(this, 'inlineRowMenuPosition', () => {
          return {
            style: {
              top: event.clientY,
              left: event.clientX
            }
          };
        });
        Ember.set(this, 'lastRowClicked', Ember.get(row, 'content'));
        this.inlineRowMenu.actions.open();
      },

      onCheckAll() {
        const vehicleEvents = this.get('vehicleEvents');
        vehicleEvents.forEach(event => {
          event.set('isChecked', true);
        });
        this.notifyPropertyChange('activeEvents');
      },

      onUncheckAll() {
        const vehicleEvents = this.get('vehicleEvents');
        vehicleEvents.forEach(event => {
          event.set('isChecked', false);
        });
        this.notifyPropertyChange('activeEvents');
      },

      onLocationButtonClick() {
        this.set('isSearchingLocation', !this.get('isSearchingLocation'));

        if (this.get('isSearchingLocation')) {
          Ember.$('html,body,.tomtom-map').addClass('pinpoint-cursor');
          this.get('map').on('click', e => {
            this.setSearchCenter(e.latlng);
            this.set('isSearchingLocation', false);
            Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
            this.get('map').off('click');
          });
        } else {
          Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
          this.get('map').off('click');
        }
      },

      //Implemented the pop up during search mode switching and clearing/resetting the fields
      selectCurrentModeTab(mode) {
        if (this.get('isVehicleSearchEnabled')) {
          this.toggleProperty('isVehicleSearchEnabled');
        }

        if (this.get('isVehicleEventSearchEnabled')) {
          this.toggleProperty('isVehicleEventSearchEnabled');
        }

        this.set('activeTabModeId', mode);
        this.clearAll();
        Ember.set(this, 'switching', null);
      },

      selectCurrentModeTabSearching(mode) {
        if (mode === 'byVehicle') {
          Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
          this.get('map').off('click');
          this.set('isSearchingLocation');
        }

        const currentTab = Ember.get(this, 'activeTabModeId');
        if (currentTab !== mode) Ember.set(this, 'switching', 'searchLocation');
      },

      cancel() {
        Ember.set(this, 'switching', '');
        Ember.set(this, 'isReconciled', false);
      },

      onPlayButtonClick() {
        const currentMarker = this.get('_movingMarker.native');

        if (currentMarker) {
          if (currentMarker.isEnded()) {
            this.set('sliderIndex', null);
            this.set('elapsedTime', 0);
            this.toggleProperty('animDisable');
          }

          if (currentMarker.isRunning()) {
            currentMarker.pause();
            this.toggleProperty('animDisable');
          } else {
            // Set speed everytime to avoid new marker not get speed setting.
            currentMarker.setSpeed(this.get('animSpeed'));
            currentMarker.start();

            if (Ember.get(this, 'animDisable')) {
              this.toggleProperty('animDisable');
            }
          }

          this.notifyPropertyChange('isRunning');
        }
      },

      onForwardButtonClick() {
        const sliderIndex = this.get('sliderIndex');

        if (this.get('_movingMarker')) {
          const forwardIndex = Math.min(sliderIndex + 10, this.get('activeEvents').length - 2);
          this.get('_movingMarker.native').setTo(forwardIndex);
          this.set('sliderIndex', forwardIndex);
          this.set('elapsedTime', 0);
        }
      },

      onBackwardButtonClick() {
        const sliderIndex = this.get('sliderIndex');

        if (this.get('_movingMarker')) {
          const backwardIndex = Math.max(sliderIndex - 10, 0);
          this.get('_movingMarker.native').setTo(backwardIndex);
          this.set('sliderIndex', backwardIndex);
          this.set('elapsedTime', 0);
          this.updateEventsandPlannedPolyline(backwardIndex);
        }
      },

      onSpeedButtonClick() {
        if (this.get('animSpeed') >= 8) {
          this.set('animSpeed', 1);
        } else {
          this.set('animSpeed', this.get('animSpeed') * 2);
        }

        if (this.get('_movingMarker')) {
          this.get('_movingMarker.native').setSpeed(this.get('animSpeed'));
        }

        this.set('sliderIndex', this.get('sliderIndex'));
      },

      onSliderAction(e) {
        if (e === 'sliderStart') {
          this.set('isSlideMoving', true);
        } else if (e === 'sliderStop') {
          this.set('isSlideMoving', false);
        }
      },

      onSliderValueChange(value) {
        const marker = this.get('_movingMarker');

        if (marker) {
          if (marker.native.isEnded() || !marker.native.isStarted()) {
            // If animate done, just restart it.
            marker.native.start();
            marker.native.pause();
          }

          marker.native.setTo(value);
          this.set('sliderIndex', value);
        }
      },

      onFilterButtonClick() {
        this.get('workspace').pushState('filterMapWidget', {
          displayName: 'Map Layers',
          action: this.actions.mapWidgetHandler.bind(this)
        }, {
          replaceSelf: true
        });
      },

      mapWidgetHandler() {
        this.updateLabels();
      },

      onTableSearchClick(param) {
        this.send('onSearchTextChange', null);
        this.toggleProperty(param === 'vehicleEventsTable' ? 'isVehicleEventSearchEnabled' : 'isVehicleSearchEnabled');
        this.set('activeSearchTable', param);
      },

      onSearchTextChange(searchText) {
        Ember.run.debounce(this, 'filterTableData', searchText, 300);
      },

      onTableColumnFilterButtonClick(tableName) {
        const columns = [];
        const workspace = this.get('workspace');
        const widget = this.get('widget');
        const config = this.get('config');
        let tableColumns = [];

        if (tableName === 'vehicleEventsTable') {
          tableColumns = this.get('vehicleEventsTable.columns') || [];
          Ember.set(this, 'tableName', 'vehicleEventsTable');
        } else {
          tableColumns = this.get('vehiclesTable.columns') || [];
          Ember.set(this, 'tableName', 'vehiclesTable');
        }

        const displayName = `${config.title} Filters`; // close sidebar if _this_ widget's filter is currently open
        // @TODO: define and use unique keys rather than `displayName` for this

        if (displayName === workspace.get('topStateDisplayName')) {
          workspace.popState();
          return;
        }

        let topState = workspace.get('topState');

        while (topState === 'playbackFilterColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        tableColumns.forEach(column => {
          column.set('filterTypes', config.filters[column.dataType] || []);
          const columnConfig = column.config;

          if (columnConfig) {
            if (!columnConfig.unAvailable) {
              columns.push(column);
            }
          } else if (column && column.id) {
            columns.push(column);
          }
        });
        workspace.pushState('playbackFilterColumnWidget', {
          columns,
          widget,
          displayName
        });
      },

      onRemoveSearchClick(param) {
        this.toggleProperty(param === 'vehicleEventsTable' ? 'isVehicleEventSearchEnabled' : 'isVehicleSearchEnabled');
      }

    }
  });

  _exports.default = _default;
});