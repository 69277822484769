define("adept-iq/services/tooltip", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    or
  } = Ember.computed; // it does not matter what actions are placed into the three buttons,
  // but when used together its good to know the location of each button
  // button location as shown resembling the named variables third - secondary - primary

  var _default = Ember.Service.extend({
    confirmations: null,
    tip: readOnly('confirmations.lastObject.tip'),
    subTips: readOnly('confirmations.lastObject.subTips'),
    title: readOnly('confirmations.lastObject.title'),
    primaryActionText: readOnly('confirmations.lastObject.primaryActionText'),
    secondaryActionText: readOnly('confirmations.lastObject.secondaryActionText'),
    thirdActionText: readOnly('confirmations.lastObject.thirdActionText'),
    primaryAction: readOnly('confirmations.lastObject.primaryAction'),
    secondaryAction: readOnly('confirmations.lastObject.secondaryAction'),
    thirdAction: readOnly('confirmations.lastObject.thirdAction'),
    closeAction: readOnly('confirmations.lastObject.closeAction'),
    hasOverlay: readOnly('confirmations.lastObject.hasOverlay'),
    // covers interface so user must confirm or cancel
    warning: readOnly('confirmations.lastObject.warning'),
    errors: readOnly('confirmations.lastObject.errors'),
    // array of errors to display
    errorMainMessage: readOnly('confirmations.lastObject.errorMainMessage'),
    // Message above the errors
    isRunning: or('primaryActionTask.isRunning', 'secondaryActionTask.isRunning'),
    isDisabled: readOnly('confirmations.lastObject.isDisabled'),

    init() {
      this._super(...arguments);

      this.set('confirmations', []);
    },

    // `drop()` might be better than `enqueue()` here, but this ensures that
    // tasks won't be cancelled by accident during messy transitions
    primaryActionTask: (0, _emberConcurrency.task)(function* () {
      const primaryAction = this.get('primaryAction');

      if (primaryAction) {
        try {
          yield primaryAction();
        } catch (e) {
          // TODO: allow user to try again without reset
          throw new Error(e);
        }
      }
    }).enqueue(),
    secondaryActionTask: (0, _emberConcurrency.task)(function* () {
      const secondaryAction = this.get('secondaryAction');

      if (secondaryAction) {
        try {
          yield secondaryAction();
        } catch (e) {
          // TODO: allow user to try again without reset
          throw new Error(e);
        }
      }
    }).enqueue(),
    thirdActionTask: (0, _emberConcurrency.task)(function* () {
      const thirdAction = this.get('thirdAction');

      if (thirdAction) {
        try {
          yield thirdAction();
        } catch (e) {
          // TODO: allow user to try again without reset
          throw new Error(e);
        }
      }
    }).enqueue(),
    closeActionTask: (0, _emberConcurrency.task)(function* () {
      const closeAction = this.get('closeAction');
      const primaryAction = this.get('primaryAction');
      const secondaryAction = this.get('secondaryAction');

      if (closeAction) {
        try {
          yield closeAction();
        } catch (e) {
          // TODO: allow user to try again without reset
          throw new Error(e);
        }
      } else if (primaryAction && !secondaryAction) {
        // if only one primaryAction, make close call the primaryAction.
        try {
          yield primaryAction();
        } catch (e) {
          // TODO: allow user to try again without reset
          throw new Error(e);
        }
      }
    }).enqueue(),

    pushConfirmation(options) {
      this.popConfirmation(); //to remove a previous confirmation object

      this.get('confirmations').pushObject(options);
    },

    popConfirmation() {
      const confirmations = this.get('confirmations');

      if (confirmations && confirmations.length) {
        return this.get('confirmations').popObject();
      }
    },

    reset() {
      this.setProperties({
        confirmations: []
      });
    }

  });

  _exports.default = _default;
});