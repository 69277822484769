define("adept-iq/pods/components/workspace-navbar/notification-failure-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tifq3Z9I",
    "block": "{\"symbols\":[\"notificationFailureResult\"],\"statements\":[[7,\"div\"],[11,\"class\",\"notification-failure-result-modal\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mb-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row no-gutters\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-md-10 offset-md-1 mt-1\"],[9],[0,\"\\n        \"],[7,\"table\"],[11,\"class\",\"table table-bordered table-striped wigt-tbl\"],[9],[0,\"\\n          \"],[7,\"thead\"],[9],[0,\"\\n          \"],[7,\"tr\"],[9],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"Rider ID, Trip ID\"],[10],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"Phone Number\"],[10],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"Error Code\"],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"tbody\"],[9],[0,\"\\n          \\n\"],[4,\"each\",[[23,[\"notificationFailureResults\"]]],null,{\"statements\":[[0,\"            \"],[7,\"tr\"],[9],[0,\"\\n              \"],[7,\"td\"],[9],[1,[22,1,[\"tripId\"]],false],[10],[0,\"\\n              \"],[7,\"td\"],[9],[1,[22,1,[\"phoneNumber\"]],false],[10],[0,\"\\n              \"],[7,\"td\"],[9],[1,[22,1,[\"errorCode\"]],false],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/workspace-navbar/notification-failure-results/template.hbs"
    }
  });

  _exports.default = _default;
});