define("adept-iq/pods/components/side-drawer/add-edit-schedule-route/component", ["exports", "ember-concurrency", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/config/api-urls", "lodash", "adept-iq/mixins/fetchAssignableVehicleDrivers", "adept-iq/models/route-template", "adept-iq/models/zone"], function (_exports, _emberConcurrency, _moment, _unwrapProxy, _apiUrls, _lodash, _fetchAssignableVehicleDrivers, _routeTemplate, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^\d*$/;
  const REMOVE_TEXT_FORMAT = /[^0-9]+/g;
  const REMOVE_SPECIAL_FORMAT = /[^a-zA-Z0-9]/g;
  const FUTURE_BOOKING_TIMELINE = 'config-Book_Trips/Future_Booking_Timeline';
  const DEFAULT_TRIP_LIMIT = 9;
  const DEFAULT_DISTANCE_LIMIT = 99;
  const MAX_ROUTE_VALUE = 2147483647;
  const IN_ACTIVE_STATUS = 'inactive';
  const extraTripLimitOptions = {
    min: 1,
    max: 1
  };
  const extraDistanceLimitOptions = {
    min: 1,
    max: 2
  };

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    tagName: '',
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    vehicles: null,
    providers: null,
    schedules: null,
    selectedVehicle: null,
    scheduleOption: null,
    extraDistanceLimitOptions,
    extraTripLimitOptions,
    currentState: null,
    onShrinkClick: null,
    onCloseClick: null,
    usePlaceHolderTask: true,
    // used by fetchVehicle Mixin
    timeFormat: 'm-d-Y h:i K',
    bulkRouteIds: Ember.computed.alias('stackFrame.options.bulkRouteIds'),
    route: Ember.computed.alias('stackFrame.options.routeModel'),
    displayName: Ember.computed.alias('stackFrame.options.displayName'),
    routeName: Ember.computed.alias('route.name'),
    type: Ember.computed.alias('route.type'),
    schedulingMode: Ember.computed.alias('route.schedulingMode'),
    optimizationFlags: Ember.computed.alias('route.optimizationFlags'),
    tripLimit: Ember.computed.alias('route.tripLimit'),
    distanceLimit: Ember.computed.alias('route.distanceLimit'),
    affinities: Ember.computed.alias('route.affinities'),
    isKeeperType: Ember.computed.equal('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER),
    isAdjustBreak: true,
    isConsolidateRoute: true,
    availableVehicles: Ember.computed.readOnly('vehicles'),
    minScheduleDate: null,
    maxScheduleDate: null,
    zoneTableRef: null,
    selectedSchedule: Ember.computed.alias('workspace.currentSchedule'),
    selectedStartDate: Ember.computed.readOnly('workspace.startDate'),
    provider: Ember.computed.readOnly('selectedProvider'),
    // used by fetchVehicle Mixin
    selectedProvider: Ember.computed({
      set(key, value) {
        this.set('selectedVehicle');
        return value;
      }

    }),
    isEditMode: Ember.computed.notEmpty('route.id'),
    isNewRoute: Ember.computed.alias('route.isNewRoute'),
    isBulkEdit: Ember.computed.gt('bulkRouteIds.length', 1),
    hasAllSelectedValues: Ember.computed('routeName', 'selectedProvider', 'selectedSchedule', 'selectedVehicle', 'startTime', 'endTime', 'tripLimit', 'distanceLimit', 'isStartTimeBefore', 'hasZones', 'hasRouteChanged', 'isBulkEdit', function () {
      const {
        routeName,
        selectedProvider,
        selectedSchedule,
        selectedVehicle,
        startTime,
        endTime,
        tripLimit,
        distanceLimit,
        isStartTimeBefore,
        hasZones,
        hasRouteChanged,
        schedulingMode,
        isBulkEdit
      } = this.getProperties('routeName', 'selectedProvider', 'selectedSchedule', 'selectedVehicle', 'startTime', 'endTime', 'tripLimit', 'distanceLimit', 'isStartTimeBefore', 'hasZones', 'hasRouteChanged', 'schedulingMode', 'isBulkEdit');

      if (isBulkEdit) {
        return Ember.isPresent(schedulingMode);
      }

      return routeName && selectedProvider && selectedSchedule && selectedSchedule && selectedVehicle && startTime && endTime && tripLimit && distanceLimit && isStartTimeBefore && hasZones && hasRouteChanged;
    }),
    //user must select at least one  zone and selected zone affinity value other than no-service.
    hasZones: Ember.computed('zoneTableRef.table.rows.@each.selected', 'selectedAffinityType.type', function () {
      const zones = this.get('isEditMode') ? this.get('zoneTableRef.table.rows') : this.get('zoneTableRef.checkedRows');
      return Ember.isPresent(zones) && zones.some(zone => zone.get('convertAffinityValue') !== _zone.AFFINITY_NO_SERVICE.type);
    }),
    startTime: Ember.computed('isEditMode', 'isBulkEdit', function () {
      const isBulkEdit = this.get('isBulkEdit');
      const isEditMode = this.get('isEditMode');
      const selectedStartDate = this.get('selectedStartDate');

      if (isBulkEdit) {
        return null;
      }

      if (isEditMode) {
        return this.get('route.plannedStartTime');
      }

      return (0, _moment.default)(selectedStartDate).startOf('day').toISOString();
    }),
    endTime: Ember.computed('isEditMode', 'isBulkEdit', function () {
      const isBulkEdit = this.get('isBulkEdit');
      const isEditMode = this.get('isEditMode');
      const selectedStartDate = this.get('selectedStartDate');

      if (isBulkEdit) {
        return null;
      }

      if (isEditMode) {
        return this.get('route.plannedEndTime');
      }

      return (0, _moment.default)(selectedStartDate).endOf('day').toISOString();
    }),
    isStartTimeBefore: Ember.computed('startTime', 'endTime', 'isBulkEdit', function () {
      const isBulkEdit = this.get('isBulkEdit');

      if (isBulkEdit || (0, _moment.default)(this.startTime).isBefore(this.endTime)) {
        this.setStartTimeError();
        return true;
      }

      this.setStartTimeError('End Time must be after Start Time.');
      return false;
    }),
    isDisabled: Ember.computed('route', 'isEditMode', 'isBulkEdit', function () {
      const isEditMode = this.get('isEditMode');
      const isScheduled = this.get('route.isScheduled');
      const isNewRoute = this.get('isNewRoute');
      const isBulkEdit = this.get('isBulkEdit');
      return isBulkEdit || isNewRoute || isEditMode && isScheduled;
    }),

    setStartTimeError(message) {
      this.set('errorMessage', message);
    },

    hasRouteChanged: Ember.computed('selectedVehicle', 'selectedProvider', 'startTime', 'endTime', 'type', 'optimizationFlags', 'affinities', 'tripLimit', 'distanceLimit', 'schedulingMode', function () {
      if (!this.get('isEditMode')) return true;
      return this.isRouteChanged();
    }),

    isRouteChanged() {
      const {
        selectedProvider,
        selectedVehicle,
        startTime,
        endTime,
        schedulingMode,
        type,
        affinities,
        optimizationFlags,
        tripLimit,
        distanceLimit
      } = this.getProperties('selectedProvider', 'selectedVehicle', 'startTime', 'endTime', 'schedulingMode', 'type', 'affinities', 'optimizationFlags', 'tripLimit', 'distanceLimit');
      const routeInfo = {
        startTime,
        endTime,
        providerId: selectedProvider.get('id'),
        vehicleId: selectedVehicle.get('id'),
        schedulingMode,
        optimizationFlags,
        type,
        affinities,
        tripLimit,
        distanceLimit
      };
      const previousRouteInfo = this.get('previousRouteInfo');
      return JSON.stringify(previousRouteInfo) !== JSON.stringify(routeInfo);
    },

    init() {
      this._super();

      this.set('timePlaceHolder', 'MM-DD-YYYY HH:MM');
      this.set('schedulingModeList', [_routeTemplate.MANUAL_SCHEDULING_MODE, _routeTemplate.AUTOMATIC_SCHEDULING_MODE]);
      this.set('affinityTypes', [_zone.AFFINITY_PREFERRED, _zone.AFFINITY_NORMAL, _zone.AFFINITY_LAST_RESORT, _zone.AFFINITY_NO_SERVICE]);
      const store = this.get('store');
      const zones = store.peekAll('zone');
      const futureBookingTimeline = store.peekRecord('cs-config-item', FUTURE_BOOKING_TIMELINE);
      this.set('minScheduleDate', (0, _moment.default)().startOf('day').add(1, 'days').toISOString());
      this.set('maxScheduleDate', (0, _moment.default)().endOf('day').add(parseInt(futureBookingTimeline.get('value'), 10), 'days').toISOString());
      this.set('availableZones', zones);
      this.set('selectedProvider', this.get('route.provider'));
      this.set('selectedVehicle', this.get('route.vehicle'));
      this.set('isAdjustBreak', _routeTemplate.ADJUST_BREAK_FLAG & this.get('optimizationFlags'));
      this.set('isConsolidateRoute', _routeTemplate.CONSOLIDATE_ROUTE_FLAG & this.get('optimizationFlags'));
      this.setupHeaderActions();
      this.setDisableDates();
      this.setPreviousRouteInfo();
    },

    setPreviousRouteInfo() {
      const currentState = this.get('currentState'); // we want to keep the original previousRouteInfo and not create a new one

      if (currentState && currentState.previousRouteInfo) {
        this.set('previousRouteInfo', currentState.previousRouteInfo);
        return;
      }

      const route = this.get('route');
      const previousRouteInfo = {
        startTime: route.get('plannedStartTime'),
        endTime: route.get('plannedEndTime'),
        providerId: route.get('provider.id'),
        vehicleId: route.get('vehicle.id'),
        schedulingMode: route.get('schedulingMode'),
        optimizationFlags: route.get('optimizationFlags'),
        type: route.get('type'),
        affinities: route.get('affinities'),
        tripLimit: route.get('tripLimit'),
        distanceLimit: route.get('distanceLimit')
      };
      this.set('previousRouteInfo', previousRouteInfo);
    },

    setDisableDates() {
      const currentDate = new Date();
      const schedules = this.get('store').peekAll('schedule');
      const futureExportedSchedules = schedules.filter(schedule => {
        const {
          start,
          isExported
        } = schedule;
        return isExported && (0, _moment.default)(start).isAfter(currentDate);
      });
      const disableDates = futureExportedSchedules.reduce((acc, exportedSchedule) => {
        acc.push(exportedSchedule.get('start'));
        return acc;
      }, []);
      this.set('disableDates', disableDates);
    },

    didInsertElement() {
      this.loadOptionsTask.perform();
      this.loadCurrentState();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.setCurrentState();
    },

    async loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        const schedule = (0, _unwrapProxy.unwrapProxy)(currentState.selectedSchedule);
        this.set('route', currentState.route);
        this.set('selectedProvider', currentState.selectedProvider);
        this.set('selectedVehicle', currentState.selectedVehicle);
        this.set('route.schedule', schedule);
        this.set('route.vehicle', currentState.selectedVehicle);
        this.set('selectedAffinityType', currentState.selectedAffinityType);
        this.set('startTime', currentState.startTime);
        this.set('endTime', currentState.endTime);
        this.set('errorMessage', currentState.errorMessage);
        await (0, _emberConcurrency.timeout)(1000);
        this.setSelectedZones(currentState.selectedZoneIds);
      }
    },

    setSelectedZones() {
      let selectedZoneIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const zones = this.get('zoneTableRef.table.rows') || [];
      zones.forEach(zone => {
        const zoneId = parseInt(zone.get('id'), 10);

        if (selectedZoneIds.includes(zoneId)) {
          zone.set('selected', true);
        }
      });
    },

    loadOptionsTask: (0, _emberConcurrency.task)(function* () {
      yield this.fetchPlaceHolderVehiclesTask.perform();
      yield this.fetchProvidersTask.perform();
    }),
    fetchProvidersTask: (0, _emberConcurrency.task)(function* () {
      const providers = yield this.store.findAll('provider');
      const filterProviderTypes = providers.filter(provider => !provider.get('isBrokerProviderType') && !(provider.get('status').toLowerCase() === IN_ACTIVE_STATUS)); //NYAAR-16257 NYAAR-19623

      this.set('providers', filterProviderTypes);
    }),

    async patchBookingRoute(bookingRouteId, primaryRoute) {
      const session = this.get('session');
      const id = bookingRouteId;
      const attributes = {
        affinities: primaryRoute.get('affinities'),
        distanceLimit: primaryRoute.get('distanceLimit'),
        optimizationFlags: primaryRoute.get('optimizationFlags'),
        plannedEndTime: primaryRoute.get('plannedEndTime'),
        plannedStartTime: primaryRoute.get('plannedStartTime'),
        schedulingMode: primaryRoute.get('schedulingMode'),
        status: primaryRoute.get('status'),
        tripLimit: primaryRoute.get('tripLimit'),
        type: primaryRoute.get('type')
      };
      const relationships = {
        vehicle: {
          data: {
            id: primaryRoute.get('vehicle.id'),
            type: 'vehicle'
          }
        }
      };
      const json = {
        id,
        type: 'route',
        attributes: attributes,
        relationships: relationships
      };
      return this.get('ajax').patch(_apiUrls.API.schedulingService.host + '/route/' + id, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      }).then(response => {
        return response;
      }).catch(error => {
        return error;
      });
    },

    // Update the booking route
    // Must be done like this as not to import into the ember store
    // We simply want to duplicate changes to this booking route from primary route.
    async saveRouteForBookingType(route) {
      const session = this.get('session');
      const scheduleName = route.get('schedule.name');
      const routeName = route.get('name'); // get the booking schedule

      const schedules = await this.get('ajax').request(_apiUrls.API.schedulingService.host + `/schedule?filter=eq(name,${scheduleName}B)`, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      if (schedules.data.length === 0) return;
      const bookingScheduleId = schedules.data[0].id; // get the booking route

      const routeBookingSchedules = await this.get('ajax').request(_apiUrls.API.schedulingService.host + `/route?filter=and(eq('name','${routeName}'),eq('scheduleId','${bookingScheduleId}'))`, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      if (routeBookingSchedules.data.length === 0) return;
      const routeBookingScheduleId = routeBookingSchedules.data[0].id; // update the booking route

      this.patchBookingRoute(routeBookingScheduleId, route);
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      const {
        selectedProvider,
        selectedVehicle,
        selectedSchedule,
        route,
        startTime,
        endTime
      } = this.getProperties('selectedProvider', 'selectedVehicle', 'selectedSchedule', 'route', 'startTime', 'endTime');
      const store = this.get('store');
      const routeAdapter = store.adapterFor('route');
      const schedules = this.get('store').peekAll('schedule'); //get schedule with given start date/time

      const filteredSchedule = schedules.filter(schedule => schedule.get('start') <= startTime).sortBy('start').get('lastObject');
      let schedule = (0, _unwrapProxy.unwrapProxy)(selectedSchedule); // if it is in add mode, use a schedule that matches the start/end date/time

      if (!this.get('isEditMode') && Ember.isPresent(filteredSchedule)) {
        schedule = filteredSchedule;
      }

      route.set('vehicle', selectedVehicle);
      route.set('provider', selectedProvider);
      route.set('schedule', (0, _unwrapProxy.unwrapProxy)(schedule));
      route.set('plannedStartTime', startTime);
      route.set('plannedEndTime', endTime);

      try {
        if (this.get('isEditMode')) {
          return yield routeAdapter.updateScheduleRoute(route).then(() => {
            this.saveRouteForBookingType(route).catch(() => {
              return;
            });
            return;
          });
        }

        return yield routeAdapter.createScheduleRoute(route);
      } catch (e) {
        throw e;
      }
    }),

    resetSelections() {
      const isBulkEdit = this.get('isBulkEdit');

      if (isBulkEdit) {
        this.set('route.schedulingMode');
      }

      this.setProperties({
        routeName: null,
        selectedVehicle: null,
        startTime: null,
        endTime: null,
        selectedProvider: null,
        errorMessage: null,
        tripLimit: isBulkEdit ? 0 : DEFAULT_TRIP_LIMIT,
        distanceLimit: isBulkEdit ? 0 : DEFAULT_DISTANCE_LIMIT,
        selectedAffinityType: null
      });
      const zoneTableRef = this.get('zoneTableRef');
      this.set('route.affinities', []);
      zoneTableRef.refreshData();
      zoneTableRef.onUncheckAll();
    },

    configureOptimizationFlags(checked, bitFlag) {
      const optimizationFlags = this.get('optimizationFlags');

      if (checked) {
        this.set('optimizationFlags', optimizationFlags | bitFlag); // turn bit on
      } else {
        this.set('optimizationFlags', optimizationFlags & ~bitFlag); // turn bit off
      }
    },

    setupHeaderActions() {
      this.set('onRefreshClick', this.get('onRefreshClick').bind(this));
      this.set('onUndoClick', this.get('onUndoClick').bind(this));

      if (this.get('isEditMode')) {
        this.set('onRefreshClick', null);
      }

      if (!this.get('isEditMode')) {
        this.set('onUndoClick', null);
      }
    },

    onRefreshClick() {
      this.loadOptionsTask.perform();
      this.resetSelections();
    },

    onUndoClick() {
      const zones = this.get('availableZones');
      const zoneTableRef = this.get('zoneTableRef');
      this.get('route').rollbackAttributes();
      this.set('selectedProvider', this.get('route.provider'));
      this.set('selectedVehicle', this.get('route.vehicle'));
      this.set('isAdjustBreak', _routeTemplate.ADJUST_BREAK_FLAG & this.get('route.optimizationFlags'));
      this.set('isConsolidateRoute', _routeTemplate.CONSOLIDATE_ROUTE_FLAG & this.get('route.optimizationFlags'));
      this.set('selectedVehicle', this.get('route.vehicle'));
      this.set('startTime', this.get('route.plannedStartTime'));
      this.set('endTime', this.get('route.plannedEndTime'));
      this.set('affinities', JSON.parse(this.get('route.affinities')));
      this.set('selectedAffinityType', null);
      const affinities = this.get('affinities');
      zones.forEach(zone => {
        const index = zone.get('zoneId') - 1;
        zone.set('affinityValue', affinities[index]);
      });
      zoneTableRef.onUncheckAll();
      this.setPreviousRouteInfo();
    },

    // NYAAR-16513 maximum route name value length 10 digit and below 2,147,483,647.
    validateRouteNameLimit() {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Route Name',
        hasoverlay: true,
        tip: 'Route Name should not exceeds the maximum value 2147483647',
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        }
      });
    },

    saveSchedulingModeTask: (0, _emberConcurrency.task)(function* () {
      const {
        bulkRouteIds,
        schedulingMode
      } = this.getProperties('bulkRouteIds', 'schedulingMode');
      const store = this.get('store');
      const routeAdapter = store.adapterFor('route');
      const bulkRoutes = bulkRouteIds.map(routeId => {
        return this.store.peekRecord('route', routeId);
      });

      try {
        return yield routeAdapter.updateBulkEditRoute(bulkRoutes, schedulingMode);
      } catch (e) {
        throw e;
      }
    }),

    saveBulkEdit() {
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const title = 'Bulk Edit Route';
      const tip = 'Are you sure you want to change Scheduling Mode for the routes?';
      const successMsg = 'Bulk edit was successful.';
      const failureMsg = 'There was a problem updating the route, please try again.';
      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          tooltip.reset();
          return this.saveSchedulingModeTask.perform().then(() => {
            this.onCloseClick();
            notifications.success(successMsg);
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              default:
                message = failureMsg; // eslint-disable-next-line no-console

                console.error(error);
                break;
            }

            this.set('errorMessage', error.message);
            notifications.warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => tooltip.reset()
      });
    },

    //to store the backupdata
    setCurrentState() {
      let currentState = this.get('currentState');
      const previousRouteInfo = this.get('previousRouteInfo');
      const {
        selectedProvider,
        selectedVehicle,
        selectedSchedule,
        route,
        selectedAffinityType,
        startTime,
        endTime,
        zoneTableRef,
        errorMessage
      } = this.getProperties('selectedProvider', 'selectedVehicle', 'selectedSchedule', 'route', 'selectedAffinityType', 'startTime', 'endTime', 'zoneTableRef', 'errorMessage');
      const zones = zoneTableRef.get('checkedRows') || [];
      const selectedZoneIds = zones.map(zone => parseInt(zone.get('id'), 10)).filter(zoneId => Ember.isPresent(zoneId) && !(0, _lodash.isNaN)(zoneId));
      currentState = {
        selectedProvider,
        selectedVehicle,
        selectedSchedule,
        route,
        selectedAffinityType,
        startTime,
        endTime,
        selectedZoneIds,
        errorMessage,
        previousRouteInfo
      };
      this.set('currentState', currentState);
    },

    actions: {
      onRouteNameInput(name) {
        let finalStringValue;

        if (!NUMBER_FORMAT.test(this.get('routeName'))) {
          finalStringValue = name.replace(REMOVE_SPECIAL_FORMAT, '');
          this.set('routeName', finalStringValue.replace(REMOVE_TEXT_FORMAT, ''));
          return this.notifyPropertyChange('routeName');
        }

        this.set('routeName', name);
      },

      onChangeTime(valuePath, value) {
        this.set(valuePath, value[0]);
      },

      onIsKeeperTypeChange(event) {
        const isChecked = event.target.checked;

        if (isChecked) {
          this.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER);
        } else {
          this.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_REGULAR);
        }
      },

      onAdjustDriverBreakCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.ADJUST_BREAK_FLAG);
      },

      onConsolidateCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.CONSOLIDATE_ROUTE_FLAG);
      },

      onAffinitySelect(option) {
        const availableZones = this.get('availableZones');
        const zones = this.get('zoneTableRef.checkedRows');
        const zoneIds = availableZones.map(zone => parseInt(zone.get('id'), 10)).filter(zoneId => Ember.isPresent(zoneId) && !(0, _lodash.isNaN)(zoneId));
        const affinities = this.get('affinities') || [];
        const maxZoneId = Math.max(...zoneIds, affinities.length);
        const newSize = Math.max(maxZoneId, affinities.length);
        const newAffinities = Array(newSize).fill(0);
        this.set('selectedAffinityType', option);
        zones.setEach('affinityValue', option.val); // configured affinities to contain all imaginable zone changes

        affinities.forEach((aff, index) => {
          newAffinities[index] = aff;
        });
        zones.forEach(zone => {
          //affinities index start from [0-16] and zone Ids starts from 1-17
          const zoneId = zone.get('zoneId') - 1;
          newAffinities[zoneId] = option.val;
        });
        this.set('affinities', newAffinities);
      },

      onLimitInputChange(path, value) {
        this.set(path, value);
      },

      save() {
        const isBulkEdit = this.get('isBulkEdit');
        const hasAllSelectedValues = this.get('hasAllSelectedValues');
        const routeName = this.get('routeName');

        if (isBulkEdit) {
          this.saveBulkEdit();
          return;
        }

        if (parseInt(routeName, 10) > MAX_ROUTE_VALUE) {
          this.validateRouteNameLimit();
          return false;
        }

        if (hasAllSelectedValues) {
          // reset error message
          this.set('errorMessage');
        } else {
          // show error message, missing properties
          this.set('errorMessage', 'Please fill out all of the fields before saving.');
          return false;
        }

        const tooltip = this.get('tooltip');
        const saveTask = this.get('saveTask');
        const notifications = this.get('notifications');
        const title = this.get('isEditMode') ? `Edit Route ${routeName}` : 'Add New Route';
        const tip = this.get('isEditMode') ? `Edit route Name ${routeName}` : `Create route Name ${routeName}`;
        const successMsg = this.get('isEditMode') ? `Route ${routeName} was edited successfully` : `Successfully created new route ${routeName}`;
        const failureMsg = this.get('isEditMode') ? 'There was a problem updating the route, please try again.' : 'There was a problem creating the route, please try again.';
        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveTask.perform().then(() => {
              this.onCloseClick();
              notifications.success(successMsg);
              tooltip.reset();
            }).catch(error => {
              let message;

              switch (error.status) {
                case 400:
                  message = 'There was a problem with one of the fields. Please check over the form and try again.';
                  break;

                case 409:
                  message = 'Unable to save. Route Name duplicates existing Route Name.';
                  break;

                default:
                  message = failureMsg; // eslint-disable-next-line no-console

                  console.error(error);
                  break;
              }

              this.set('errorMessage', message);
              notifications.warning(message);
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => tooltip.reset()
        });
      },

      onCloseClick() {
        this._super(...arguments);

        if (this.get('isEditMode')) {
          this.get('route').rollbackAttributes();
        }

        this.onCloseClick();
      },

      onShrinkClick() {
        this.setCurrentState();
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});