define("adept-iq/serializers/rider-eligibility", ["exports", "adept-iq/serializers/rider", "adept-iq/serializers/-rms-riderManagement"], function (_exports, _rider, _rmsRiderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {
      isSoftDeleted: {
        serialize: false
      },
      origEligCateName: {
        serialize: false
      },
      origEligCondName: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (json.data && json.data.attributes) {
        // startDate and endDate should be set to start and end of day.
        const startDate = new Date(json.data.attributes.from);
        const endDate = new Date(json.data.attributes.to);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        json.data.attributes.from = startDate.toISOString();
        json.data.attributes.to = endDate.toISOString();
        json.data.type = 'riderEligibility';

        if (!Ember.isNone(json.data.relationships)) {
          const eligibilityCategoryRelationship = json.data.relationships.eligibilityCategory;
          const eligibilityConditionRelationship = json.data.relationships.eligibilityCondition;

          if (!Ember.isNone(eligibilityCategoryRelationship)) {
            eligibilityCategoryRelationship.data.type = 'eligibilityCategoryName';
            json.data.relationships.eligibilityCategoryName = json.data.relationships.eligibilityCategory;
            delete json.data.relationships.eligibilityCategory;
          }

          if (!Ember.isNone(eligibilityConditionRelationship)) {
            eligibilityConditionRelationship.data.type = 'eligibilityConditionName';
            json.data.relationships.eligibilityConditionName = json.data.relationships.eligibilityCondition;
            delete json.data.relationships.eligibilityCondition;
          }

          const riderRelationship = json.data.relationships.rider;

          if (!Ember.isNone(riderRelationship)) {
            riderRelationship.data.type = 'rider';
          }
        }
      }

      return json;
    },

    payloadKeyFromModelName(modelName) {
      return modelName;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.eligibilityCategoryName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.eligibilityCategory = {
          data: {
            type: 'eligibility-category',
            id: resourceHash.relationships.eligibilityCategoryName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.eligibilityCategoryName;
      }

      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.eligibilityConditionName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.eligibilityCondition = {
          data: {
            type: 'eligibility-condition',
            id: resourceHash.relationships.eligibilityConditionName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.eligibilityConditionName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      const riderSerializer = new _rider.default();

      if (!Ember.isNone(payload.included) && !Ember.isNone(payload.included.length)) {
        for (let i = 0; i < payload.included.length; i++) {
          const include = payload.included[i];

          if (include.type === 'rider') {
            riderSerializer.normalizeResponse(store, primaryModelClass, {
              data: include
            });
          }

          if (include.type === 'eligibilityCategory' || include.type === 'eligibilityCategoryName') {
            include.type = 'eligibility-category';
          }

          if (include.type === 'eligibilityCondition' || include.type === 'eligibilityConditionName') {
            include.type = 'eligibility-condition';
          }
        }
      }

      return payload;
    }
  });

  _exports.default = _default;
});