define("adept-iq/pods/components/iq-widgets/column-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/column-widget/component", "adept-iq/config/column-types", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _columnTypes, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const logger = {
    log: () => null,
    warn: console.warn // eslint-disable-line no-console

  };

  var _default = _component.default.extend({
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    classNames: ['iqux-column-widget'],
    layoutName: 'components/generic-widgets/column-widget',
    defaultIncludes: null,

    didInsertElement() {
      this._super(...arguments);

      this._onActiveContextRefresh = modelNames => {
        if (this.get('isDestroyed')) return;
        const names = Ember.makeArray(this.get('config.modelName'));

        if (!modelNames.includes(...names)) {
          // not refreshing this model. give control back
          this.set('activeContext.topActiveContext.refreshInProgress', false);
          return;
        }

        Ember.run.schedule('afterRender', this, 'refreshData');
      };

      this._onActiveContextClear = () => {
        this.clearData();
      };

      this._onActiveContextRemoveBindings = () => {
        this.get('activeContext.topActiveContext').off('refresh', this._onActiveContextRefresh);
        this.get('activeContext.topActiveContext').off('clear', this._onActiveContextClear);
        this.get('activeContext.topActiveContext').off('unbind', this._onActiveContextRemoveBindings);
      };

      if (this.get('activeContext.topActiveContext')) {
        this.get('activeContext.topActiveContext').on('refresh', this._onActiveContextRefresh);
        this.get('activeContext.topActiveContext').on('clear', this._onActiveContextClear);
        this.get('activeContext.topActiveContext').on('unbind', this._onActiveContextRemoveBindings);
        this.refreshData();
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('activeContext.topActiveContext') && this.get('activeContext.topActiveContext').has('unbind')) {
        this._onActiveContextRemoveBindings();
      }

      this._onActiveContextRefresh = null;
      this._onActiveContextClear = null;
      this._onActiveContextRemoveBindings = null;
    },

    setupFilterType() {
      const searchText = this.get('searchText');
      const res = searchText.split('=');

      if (Ember.isEmpty(res[0]) || Ember.isEmpty(res[1])) {
        return [this.buildSearchFilter(), this.buildColumnFilter()];
      }

      return [this.buildSearchFilterByParameter(), this.buildColumnFilter()];
    },

    /**
     * In the booking workspace booking widgets, subscription widgets, and booking trip widgets data have come from
     * the booking service, getRecord method was overridden by those widgets component because If user search the record,
     * we should filter entire records
     * @returns {*|Array}
     */
    getRecords(data) {
      const getAllRecords = data;
      return getAllRecords.get('records') || [];
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };
      const self = this;

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelNames = Ember.makeArray(this.get('config.modelName')); //TODO need to remove future release

        if (this.get('config.isFormControls')) {
          const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
          const filterFn = (0, _filters.buildFilterFunction)(params.filter);

          if (self.get('state.modelName') === this.get('config.modelName')) {
            return this.get('getRecords')(self).filter(filterFn).sort(compareFn);
          }

          return this.get('records');
        }

        return this.get('activeContext.topActiveContext').query(modelNames, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    buildSortParam() {
      const config = this.get('config');
      const state = this.get('state');
      let columnConfig; // user-specified sort order always takes precedence

      if (Ember.isPresent(state.sortId)) {
        columnConfig = config.columns.findBy('id', state.sortId);

        if (!columnConfig) {
          // this can occur naturally if the user sorts by a column, saves, and
          // that column is later removed from the widget's configuration
          logger.warn(`workspace configuration specifies that ${config.title} widget should be sorted by column with id '${state.sortId}', but no such column exists`);
        }
      } // fall back to default sort order; this is optional in widget config


      if (!columnConfig && Ember.isPresent(config.defaultSortId)) {
        columnConfig = config.columns.findBy('id', config.defaultSortId);

        if (!columnConfig) {
          logger.warn(`${config.title} widget is configured with default sort ID '${config.defaultSortId}', but has no column with matching ID`);
        }
      } // collection will be unsorted; this is expected when there is no specified
      // workspace sort or widget default sort


      if (!columnConfig) {
        return null;
      }

      const columnType = _columnTypes.default.findBy('id', columnConfig.type);

      if (!columnType) {
        logger.warn(`tried to sort by column with ID '${columnConfig.id}', but its type '${columnConfig.type}' is not a valid column type`);
        return null;
      }

      if (!columnType.compare) {
        logger.warn(`column type '${columnType.id}' does not have a compare function configured`);
        return null;
      }

      let sortAsc = true;

      if (Ember.isPresent(state.sortAsc)) {
        sortAsc = state.sortAsc;
      } else if (Ember.isPresent(config.defaultSortAsc)) {
        sortAsc = config.defaultSortAsc;
      }

      return {
        path: columnConfig.valuePath,
        asc: sortAsc,
        compare: columnType.compare
      };
    },

    buildSearchFilterByParameter() {
      const searchText = this.get('searchText');
      const res = searchText.split('=');
      const attr = res[0].toLowerCase().trim();
      const value = res[1].trim();
      const columns = this.get('table.columns') || [];

      function configFilter(config) {
        return config.label.toLowerCase().trim() === attr;
      }

      if (Ember.isEmpty(searchText)) {
        return null;
      }

      const searchableConfigs = columns.rejectBy('hidden').mapBy('config').compact().filter(configFilter);
      const args = searchableConfigs.map(config => {
        const columnType = _columnTypes.default.findBy('id', config.type) || {};
        const filterType = columnType.searchFilterType;
        const valuePath = config.alternateValuePath ? config.alternateValuePath : config.valuePath;
        return (0, _filters.buildValueFilterNode)(filterType, valuePath, [value], config.format);
      });
      return (0, _filters.buildCompoundFilterNode)('or', args);
    },

    buildSearchFilter() {
      const searchText = this.get('searchText');
      const columns = this.get('table.columns') || [];

      if (Ember.isEmpty(searchText)) {
        return null;
      }

      const searchableConfigs = columns.rejectBy('hidden').mapBy('config').compact().filterBy('searchable');
      const args = searchableConfigs.map(config => {
        const columnType = _columnTypes.default.findBy('id', config.type) || {};
        const filterType = columnType.searchFilterType;
        const valuePath = config.alternateValuePath ? config.alternateValuePath : config.valuePath;
        return (0, _filters.buildValueFilterNode)(filterType, valuePath, [searchText], config.format);
      });
      return (0, _filters.buildCompoundFilterNode)('or', args);
    },

    buildColumnFilter() {
      const quickFilters = this.get('quickFilters');
      const columns = this.get('table.columns') || [];
      let args = columns.map(column => {
        return column.get('filterNode');
      });

      if (Ember.isPresent(quickFilters)) {
        quickFilters.forEach(filter => {
          if (filter.active) {
            args = args.concat(filter.filters);
          }
        });
      }

      return (0, _filters.buildCompoundFilterNode)('and', args);
    }

  });

  _exports.default = _default;
});