define("adept-iq/pods/components/side-drawer/unperform-no-show/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/mixins/version-check"], function (_exports, _emberConcurrency, _unwrapProxy, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = Ember.computed;

  var _default = Ember.Component.extend(_versionCheck.default, {
    classNames: ['perform-stop-drawer'],
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    noShowTime: null,
    noShowNotes: '',
    errorText: null,
    unperformTitle: 'Unperform No Show',
    unperformMessage: 'Unperforming Stop will NOT change Promise Time.',

    init() {
      this._super(...arguments);

      const noShowTime = this.get('stopPoint.actualDepartTime');
      this.set('noShowTime', noShowTime);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    stopPoint: readOnly('stackFrame.options.stopPoint'),
    dispatchRouteVersionSnapshot: readOnly('stackFrame.options.dispatchRouteVersionSnapshot'),
    title: readOnly('stackFrame.options.title'),
    trip: readOnly('stopPoint.trip'),
    route: readOnly('stopPoint.route'),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      const noShowTime = this.get('stopPoint.actualDepartTime');
      this.set('noShowTime', noShowTime);
      this.set('noShowNotes', '');
      yield (0, _emberConcurrency.timeout)(30);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const stopPoint = (0, _unwrapProxy.unwrapProxy)(this.get('stopPoint'));
      const trip = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('trip'));
      const otherStopPoint = (0, _unwrapProxy.unwrapProxy)(trip.get('dropStopPoint'));
      const noShowTime = this.get('noShowTime');
      const noShowNotes = this.get('noShowNotes');
      const arriveTimestamp = stopPoint.get('actualArriveTime');
      const routeExecEventAdapter = store.adapterFor('route-exec-event'); // eslint-disable-next-line no-unused-vars

      let avlmVersion = null;

      if (stopPoint.get('isNoShow')) {
        const tripNoShowAdapter = store.adapterFor('trip-noshow');
        avlmVersion = yield routeExecEventAdapter.createUndoNoShowEvent(stopPoint, noShowTime, noShowNotes, avlmVersion);
        yield tripNoShowAdapter.deleteTripNoShow(trip);

        if (otherStopPoint.get('isPerformed') || otherStopPoint.get('isNoShow')) {
          avlmVersion = yield routeExecEventAdapter.createUndoNoShowEvent(otherStopPoint, noShowTime, noShowNotes, avlmVersion);
        } // if this stop point have been arrived before then change state back to arrive


        if (arriveTimestamp) {
          stopPoint.set('arriveTimestamp', arriveTimestamp);
          avlmVersion = yield routeExecEventAdapter.createStopArriveEvent(stopPoint, avlmVersion);
        }
      }

      yield this.get('versionUpdateOperation').perform();
    }).drop(),
    actions: {
      noop() {
        return false;
      },

      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      async onPerformButtonClick() {
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);

        try {
          // Check dispatchRoute version first
          await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this));
          await lockRouteAction(); // Reset error text

          this.set('errorText');
          const tip = 'Unperforming No Show';
          const message = 'Unperformed No Show';
          const title = 'Unperform';
          this.get('tooltip').pushConfirmation({
            tip: tip,
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('saveTask').perform().then(() => {
                this.get('notifications').success(`${message} successfully`);
              }).catch(response => {
                const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
                let messageText = 'Unperformed noshow failed';

                if (response.status === 400 || response.status === 500) {
                  if (payloadMessage.errorDetail.includes('Execution version is different')) {
                    messageText = `UNPERFORM NOSHOW FAILED. THE ROUTE (${this.get('route.name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
                  }
                }

                this.get('notifications').warning(`${messageText}`);
              }).finally(async () => {
                await unlockRouteAction(0);
                this.get('tooltip').reset();
                return this.onCloseClick();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: async () => {
              await unlockRouteAction(0);
              this.get('tooltip').reset();
              return;
            },
            closeAction: async () => {
              await unlockRouteAction(0);
            }
          });
        } catch (e) {
          await unlockRouteAction();
        }
      }

    }
  });

  _exports.default = _default;
});