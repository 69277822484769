define("adept-iq/pods/components/iq-widgets/message-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/message-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'avlm-canned-message',
    title: 'Messages',
    defaultSortId: 'sentTime',
    defaultSortAsc: false,
    widgetName: 'messageWidget',
    selectedRowLimit: 5,
    columns: [{
      id: 'read',
      index: 9,
      type: 'text',
      label: 'Read',
      valuePath: 'isRead',
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'status',
      index: 2,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      searchable: true
    }, {
      id: 'deliveredTime',
      index: 1,
      type: 'date',
      label: 'Delivered Time',
      valuePath: 'deliveredTime',
      format: 'MM-DD-YYYY hh:mm A',
      searchable: true,
      defaultWidth: 200,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'time',
      index: 0,
      type: 'date',
      label: 'Sent Time',
      valuePath: 'sentTime',
      format: 'MM-DD-YYYY hh:mm A',
      searchable: true,
      defaultWidth: 200,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'message',
      index: 3,
      type: 'text',
      label: 'Message',
      valuePath: 'message',
      searchable: true,
      defaultWidth: 100,
      defaultTabletWidth: 220
    }, {
      id: 'driverId',
      index: 6,
      type: 'uuid',
      label: 'Driver ID',
      valuePath: 'driverId',
      searchable: true,
      defaultWidth: 40,
      defaultTabletWidth: 120
    }, {
      id: 'lastName',
      index: 5,
      type: 'text',
      label: 'Driver Last Name',
      valuePath: 'driverLastName',
      searchable: true,
      defaultWidth: 75,
      defaultTabletWidth: 170
    }, {
      id: 'firstName',
      index: 8,
      type: 'text',
      label: 'Driver First Name',
      valuePath: 'driverFirstName',
      searchable: true,
      defaultWidth: 60,
      defaultTabletWidth: 170
    }, {
      id: 'vehicleId',
      index: 4,
      type: 'text',
      label: 'Vehicle Id',
      valuePath: 'vehicleName',
      searchable: true,
      defaultWidth: 40,
      defaultTabletWidth: 120
    }, {
      id: 'routeName',
      index: 7,
      type: 'uuid',
      label: 'Route Name',
      valuePath: 'routeName',
      searchable: true,
      defaultWidth: 40,
      defaultTabletWidth: 220
    }, {
      id: 'responseType',
      index: 10,
      type: 'text',
      label: 'Response Type',
      valuePath: 'responseTypeLabel',
      searchable: true,
      defaultWidth: 175
    }, {
      id: 'responseMessage',
      index: 11,
      type: 'text',
      label: 'Response Received',
      valuePath: 'responseReceived',
      searchable: true,
      defaultWidth: 175
    }, {
      id: 'ackTime',
      index: 12,
      type: 'date',
      label: 'Response Received Time',
      format: 'MM-DD-YYYY hh:mm A',
      valuePath: 'responseReceivedTime',
      searchable: true,
      defaultWidth: 200,
      cellItem: 'DateLocalCellItem'
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'eq',
          field: 'tripId',
          valuePath: 'id'
        }]
      },
      driverWidget: {
        links: [{
          type: 'eq',
          field: 'driverPk',
          valuePath: 'id'
        }]
      },
      vehicleWidget: {
        links: [{
          type: 'eq',
          field: 'vehicleId',
          valuePath: 'id'
        }]
      },
      alertWidget: {
        links: [{
          type: 'eq',
          field: 'vehicleId',
          valuePath: 'vehicleId'
        }]
      },
      stopWidget: {
        links: [{
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'routeName'
        }]
      },
      routeWidget: {
        links: [{
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'name'
        }]
      }
    }
  };
  _exports.default = _default;
});