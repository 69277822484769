define("adept-iq/pods/components/iq-widgets/subscription-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/subscription-widget/config", "adept-iq/pods/components/iq-widgets/subscription-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds", "moment", "lodash", "adept-iq/utils/unwrapProxy"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds, _moment, _lodash, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['subscription-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    canShowHeader: true,
    config: _config.default,
    defaultIncludes: INCLUDES,
    editAction: null,
    deleteAction: null,
    subscriptionComponent: 'iq-widgets/subscription-form',
    deleteSubscriptionComponent: 'iq-widgets/delete-subscriptions-form',
    cancelSubscriptionComponent: 'iq-widgets/cancel-subscription-trips',
    subscriptionActivityLogComponent: 'side-drawer/activity-log/subscription-activity-log',

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.onSubscriptionEdit = this.onSubscriptionEdit.bind(this);
      this.getCurrentSubscriptions = this.getCurrentSubscriptions.bind(this);
      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
      this.set('getRecords', this.getCurrentSubscriptions);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('editAction', {
        id: 'edit',
        name: 'Edit Subscription',
        permId: [_mappedPermIds.default.editSubscription],
        action: this.onSubscriptionEdit
      });
      this.set('deleteAction', {
        id: 'delete',
        name: 'Delete Subscription',
        permId: _mappedPermIds.default.deleteSubscription,
        action: model => {
          const deleteSubscriptionComponent = this.get('deleteSubscriptionComponent');
          this.get('editModal').open(deleteSubscriptionComponent, [model]);
        }
      });
      this.set('cancelAction', {
        id: 'cancel',
        name: 'Cancel Subscription Trips',
        permId: _mappedPermIds.default.cancelSubscriptionTrips,
        action: model => {
          const cancelSubscriptionsComponent = this.get('cancelSubscriptionComponent');
          this.get('editModal').open(cancelSubscriptionsComponent, [model]);
        }
      });
      this.set('viewActivityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLog,
        name: 'View Activity Log',
        action: subscription => {
          const id = subscription.get('id');
          this.get('workspace').pushState('subscriptionActivityLog', {
            displayName: `Subscription ${id} Activity Log`,
            subscription
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      setTimeout(() => {
        if (this.get('booking.passengerRowSelected')) {
          this.selectAndDeselectTableRows(false);
          this.filterTripsForselectedSubscriptions();
          this.set('booking.passengerRowSelected', false);
        }
      }, 1000);
    },

    getCurrentSubscriptions() {
      const currentSubscriptions = this.get('booking.currentSubscriptions') || [];
      return currentSubscriptions;
    },

    async onSubscriptionEdit(subscription) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const travelNeedNames = subscription.get('subscriptionTravelNeeds').map(tn => tn.get('travelNeedType.id'));
        const isDuplicated = new Set(travelNeedNames).size !== travelNeedNames.length;
        const subscriptionPassengerId = subscription.get('rider.id');

        if (isDuplicated) {
          const travelNeedsBySubIdQuery = `in(subscriptionId,(${subscription.get('id')}))`;
          const subTravelNeeds = await this.get('store').query('subscription-travel-need', {
            filter: travelNeedsBySubIdQuery
          });
          subscription.set('subscriptionTravelNeeds', subTravelNeeds);
        }

        if (!subscription.get('exclusions.firstObject')) {
          const exclusion = this.get('store').createRecord('subscription-exclusion');
          subscription.set('exclusions', [exclusion]);
        }

        const subscriptionExclusion = subscription.get('exclusions');

        if (subscriptionExclusion.length > 1) {
          const exlusionRecord = subscriptionExclusion.filter(exclusion => exclusion.id !== null);
          subscription.set('exclusions', exlusionRecord);
        }

        if (subscription.get('exclusions.firstObject.startDate') && subscription.get('exclusions.firstObject.endDate')) {
          subscription.set('exclusions.firstObject.suspend', true);
        }

        const pickPlace = subscription.get('origin');
        const dropPlace = subscription.get('destination');
        const {
          createdPickPlace,
          createdDropPlace
        } = this.createPickAndDropPlaceRecord(pickPlace, dropPlace);
        const pickZName = pickPlace.get('address.zoneName.id');
        const dropZName = dropPlace.get('address.zoneName.id');
        const pickAddress = createdPickPlace.get('address.beNomadAddress');
        const dropAddress = createdDropPlace.get('address.beNomadAddress');
        const pickGeoNode = await this.get('geocode').getGeonode(pickAddress);
        const dropGeoNode = await this.get('geocode').getGeonode(dropAddress);
        const pickZone = this.store.peekRecord('zone', parseInt(pickZName, 10));
        const dropZone = this.store.peekRecord('zone', parseInt(dropZName, 10));
        const pickGeoZoneName = pickGeoNode.zone && pickGeoNode.zone.zoneDisplayName ? pickGeoNode.zone.zoneDisplayName : '';
        const dropGeoZoneName = dropGeoNode.zone && dropGeoNode.zone.zoneDisplayName ? dropGeoNode.zone.zoneDisplayName : '';
        const pickZoneName = this.store.createRecord('zone-type', {
          name: pickZone && pickZone.get('name') || pickGeoZoneName,
          displayName: pickZone && pickZone.get('displayName') || pickGeoZoneName
        });
        const dropZoneName = this.store.createRecord('zone-type', {
          name: dropZone && dropZone.get('name') || dropGeoZoneName,
          displayName: dropZone && dropZone.get('displayName') || dropGeoZoneName
        });
        createdPickPlace.set('address.zoneName', pickZoneName);
        createdDropPlace.set('address.zoneName', dropZoneName);
        createdPickPlace.set('address.zName', pickZone && pickZone.get('displayName') || pickGeoZoneName);
        createdDropPlace.set('address.zName', dropZone && dropZone.get('displayName') || dropGeoZoneName);
        const leg = this.store.createRecord('leg', {
          requestTime: subscription.get('requestTime'),
          anchor: subscription.get('anchor'),
          purpose: subscription.get('purpose'),
          notes: subscription.get('legNotes'),
          serviceWindow: subscription.get('serviceWindow'),
          fundingAgency: subscription.get('fundingAgency')
        });
        const segment = this.store.createRecord('segment', {
          anchor: subscription.get('anchor'),
          fare: subscription.get('fare'),
          fareType: subscription.get('fareType'),
          pick: this.store.createRecord('segment-stop', {
            type: 'pick',
            place: createdPickPlace,
            notes: subscription.get('originNotes')
          }),
          drop: this.store.createRecord('segment-stop', {
            type: 'drop',
            place: createdDropPlace,
            notes: subscription.get('destinationNotes')
          }),
          leg: leg
        });
        const dummyBookingForOpeningForm = this.store.createRecord('booking', {
          provider: subscription.get('provider'),
          subscription: subscription
        });
        leg.set('segments', [segment]);
        dummyBookingForOpeningForm.set('legs', [leg]);
        const isBookingEditMode = true;
        const newBookingComponent = this.get('subscriptionComponent');
        const selectedRiders = this.get('booking.selectedRiders').filter(rider => rider.get('id') === subscriptionPassengerId);
        const riderId = selectedRiders.get('firstObject.id');
        await this.store.queryRecord('rider-external', riderId);
        this.set('booking.activeBooking', dummyBookingForOpeningForm);
        const hasFutureTrips = this.hasFutureTripsForSelectedSubscription(subscription.get('id'));
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('editModal').open(newBookingComponent, [isBookingEditMode, selectedRiders.get('firstObject'), dummyBookingForOpeningForm, hasFutureTrips]);
      } catch (e) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('notifications').warning('error while editing subscription' + e.message);
      }
    },

    createPickAndDropPlaceRecord(pickPlace, dropPlace) {
      const pickPlaceCategoryType = (0, _unwrapProxy.unwrapProxy)(pickPlace.get('placeCategoryType'));
      const dropPlaceCategoryType = (0, _unwrapProxy.unwrapProxy)(dropPlace.get('placeCategoryType'));
      const pickPlaceAddress = (0, _unwrapProxy.unwrapProxy)(pickPlace.get('address')).toJSON();
      const dropPlaceAddress = (0, _unwrapProxy.unwrapProxy)(dropPlace.get('address')).toJSON();
      const pickPlaceLocation = (0, _unwrapProxy.unwrapProxy)(pickPlace.get('location')).toJSON();
      const dropPlaceLocation = (0, _unwrapProxy.unwrapProxy)(dropPlace.get('location')).toJSON();
      const createdPickAddress = this.store.createRecord('address', pickPlaceAddress);
      const createdDropAddress = this.store.createRecord('address', dropPlaceAddress);
      const createdPickLocation = this.store.createRecord('location', pickPlaceLocation);
      const createdDropLocation = this.store.createRecord('location', dropPlaceLocation);
      const createdPickPlace = this.store.createRecord('place', {
        address: createdPickAddress,
        location: createdPickLocation,
        placeCategoryType: pickPlaceCategoryType
      });
      const createdDropPlace = this.store.createRecord('place', {
        address: createdDropAddress,
        location: createdDropLocation,
        placeCategoryType: dropPlaceCategoryType
      });
      return {
        createdPickPlace,
        createdDropPlace
      };
    },

    selectAndDeselectTableRows(isRow) {
      const subscriptionWidgetTableRows = this.get('table.rows');
      subscriptionWidgetTableRows.setEach('selected', isRow);
      subscriptionWidgetTableRows.setEach('isChecked', isRow);
    },

    filterTripsForselectedSubscriptions() {
      const subscriptionRows = this.get('table.rows');
      const selectedSubsciptions = subscriptionRows.filter(row => row.get('isChecked'));
      const selectedSubscriptionIds = selectedSubsciptions.map(sub => sub.get('id'));
      const subscriptionBookings = this.get('booking.currentBookings').filter(booking => selectedSubscriptionIds.some(subscriptionId => subscriptionId === booking.get('subscription.id')));
      const selectedBookingIds = subscriptionBookings.map(booking => booking.get('id'));
      const filteredTrips = this.get('booking.currentTrips').filter(trip => selectedBookingIds.some(bookingId => bookingId === trip.get('booking.id')));
      this.set('booking.filteredCurrentTrips', filteredTrips);
    },

    hasFutureTripsForSelectedSubscription(subscriptionId) {
      const subscriptionBookings = this.get('booking.currentBookings').filter(booking => subscriptionId === booking.get('subscription.id'));
      const selectedBookingIds = subscriptionBookings.map(booking => booking.get('id'));
      const filteredTrips = this.get('booking.currentTrips').filter(trip => selectedBookingIds.some(bookingId => bookingId === trip.get('booking.id')));
      const currentDate = (0, _moment.default)().startOf('day');
      if (!filteredTrips || Ember.isEmpty(filteredTrips)) return false;
      return filteredTrips.some(trip => !(0, _moment.default)(trip.get('tripStartTime')).isBefore(currentDate));
    },

    filterTripsForUncheckedRow(subscriptionId) {
      const currentTrips = this.get('booking.filteredCurrentTrips');
      const bookingRows = this.get('bookingTableRef.table.rows');
      const selectedBookings = bookingRows.filter(bookingRow => bookingRow.get('isChecked'));
      const selectedBookingIds = selectedBookings.map(booking => booking.get('id'));
      return currentTrips.filter(trip => {
        if (selectedBookingIds.includes(trip.get('booking.id'))) {
          return true;
        }

        return trip.get('booking.subscription.id') !== subscriptionId;
      });
    },

    actions: {
      onRowClick(row) {
        const isChecked = row.get('isChecked');
        const currentTrips = this.get('booking.filteredCurrentTrips');
        const subscriptionId = row.get('id');

        if (isChecked) {
          const filteredTrips = this.get('booking.currentTrips').filter(trip => trip.get('booking.subscription.id') === subscriptionId);
          this.set('booking.filteredCurrentTrips', (0, _lodash.uniq)(currentTrips.concat(filteredTrips)));
          return;
        }

        const filteredTrips = this.filterTripsForUncheckedRow(subscriptionId);
        this.set('booking.filteredCurrentTrips', filteredTrips);
      },

      onRowRightClick(row) {
        if (row.get('status') === 'cancelled') {
          this.set('dynamicSingleActions', [this.get('deleteAction'), this.get('viewActivityLogAction')]);
        } else {
          this.set('dynamicSingleActions', [this.get('editAction'), this.get('deleteAction'), this.get('cancelAction'), this.get('viewActivityLogAction')]);
        }

        this._super(...arguments);
      },

      onCheckAll() {
        const isRowHeadChecked = true;
        this.selectAndDeselectTableRows(isRowHeadChecked);
        this.filterTripsForselectedSubscriptions();
      },

      onUncheckAll() {
        const isRowHeadUnchecked = false;
        const bookingRows = this.get('bookingTableRef.table.rows');
        const isEveryRowChecked = bookingRows.every(bookingRow => bookingRow.get('isChecked'));
        this.selectAndDeselectTableRows(isRowHeadUnchecked);

        if (!isEveryRowChecked) {
          this.set('booking.filteredCurrentTrips', []);
        }
      }

    }
  });

  _exports.default = _default;
});