define("adept-iq/pods/components/iq-widgets/routes-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/routes-widget',
    rowComponent: 'iq-widgets/routes-widget/routes-row',
    modelName: 'avlm-route',
    title: 'Routes',
    defaultSortId: 'name',
    defaultSortAsc: true,
    widgetName: 'routeWidget',
    selectedRowLimit: 5,
    columns: [{
      id: 'name',
      type: 'text',
      // adept 4 is no longer part of the system, route's will include characters
      label: 'Name',
      valuePath: 'name',
      valuePreview: '22',
      editable: true,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'vehicle',
      type: 'text',
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'driverId',
      type: 'text',
      label: 'Driver ID',
      valuePath: 'driverId',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'driverLastName',
      type: 'text',
      label: 'Driver Last Name',
      valuePath: 'driverLastName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'driverFirstName',
      type: 'text',
      label: 'Driver First Name',
      valuePath: 'driverFirstName',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'status',
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      valuePreview: 'Planned',
      searchable: true,
      editable: true,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'plannedStartTime',
      type: 'date',
      label: 'Planned Start Time',
      valuePath: 'plannedStartDateTime',
      valuePreview: '09:00 AM',
      editable: true,
      hidden: true,
      format: 'hh:mm A',
      searchable: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'actualStartTime',
      type: 'date',
      label: 'Actual Start Time',
      valuePath: 'actualStartTime',
      valuePreview: '09:05 AM',
      hidden: true,
      format: 'hh:mm A',
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'plannedBreaks',
      type: 'number',
      label: 'Planned Breaks',
      valuePath: 'plannedBreaksCount',
      cellDesc: 'Driver Breaks',
      hidden: true,
      unAvailable: true
    }, {
      id: 'actualBreaks',
      type: 'enum',
      label: 'Actual Breaks',
      valuePath: 'actualBreaks',
      cellDesc: 'Driver Breaks',
      hidden: true,
      unAvailable: true
    }, {
      id: 'vehicleType',
      type: 'text',
      label: 'Vehicle Type',
      valuePath: 'vehicleType',
      hidden: true,
      unAvailable: true
    }, {
      id: 'plannedEndTime',
      type: 'date',
      label: 'Planned End Time',
      valuePath: 'plannedEndDateTime',
      valuePreview: '04:00 PM',
      editable: true,
      format: 'hh:mm A',
      hidden: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'actualEndTime',
      type: 'date',
      label: 'Actual End Time',
      valuePath: 'actualEndTime',
      valuePreview: '04:35 PM',
      format: 'hh:mm A',
      hidden: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'plannedStartDate',
      type: 'date',
      label: 'Planned Start Date',
      valuePath: 'plannedStartDate',
      valuePreview: '2018-05-15',
      editable: true,
      format: 'MM-DD-YYYY',
      hidden: true,
      cellItem: 'DateCellItem'
    }, {
      id: 'actualStartDate',
      type: 'date',
      label: 'Actual Start Date',
      valuePath: 'actualStartDate',
      valuePreview: '2018-05-15',
      format: 'MM-DD-YYYY',
      hidden: true,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'plannedEndDate',
      type: 'date',
      label: 'Planned End Date',
      valuePath: 'plannedEndDate',
      valuePreview: '2018-05-15',
      editable: true,
      format: 'MM-DD-YYYY',
      hidden: true,
      cellItem: 'DateCellItem'
    }, {
      id: 'actualEndDate',
      type: 'date',
      label: 'Actual End Date',
      valuePath: 'actualEndDate',
      valuePreview: '2018-05-15',
      format: 'MM-DD-YYYY',
      hidden: true,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'otpStatus',
      type: 'string',
      label: 'OTP Status',
      valuePath: 'otpStatus',
      valuePreview: 'On Time',
      searchable: true,
      highlightable: true,
      cellItem: 'OTPCellItem'
    }, {
      id: 'otp',
      type: 'number',
      label: 'OTP',
      valuePath: 'otp',
      hidden: true,
      highlightable: true
    }, {
      id: 'provider',
      type: 'string',
      label: 'Provider',
      valuePath: 'provider',
      valuePreview: 'MAG',
      hidden: true,
      highlightable: false
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'stringEq',
          field: 'name',
          valuePath: 'routeName'
        }]
      },
      vehicleWidget: {
        links: [{
          type: 'stringEq',
          field: 'name',
          valuePath: 'routeName'
        }]
      },
      alertWidget: {
        links: [{
          type: 'eq',
          field: 'vehicleId',
          valuePath: 'vehicleId'
        }]
      },
      stopWidget: {
        links: [{
          type: 'stringEq',
          field: 'name',
          valuePath: 'routeName'
        }]
      },
      messageWidget: {
        links: [{
          type: 'eq',
          field: 'vehicleId',
          valuePath: 'vehicleId'
        }]
      }
    }
  };
  _exports.default = _default;
});