define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-driver/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    title: 'Driver',
    defaultSortId: 'driverId',
    defaultSortAsc: true,
    reconcileSearchTables: true,
    showSelectedRowLength: false,
    noRecordsFound: false,
    columns: [{
      label: 'Provider ID',
      valuePath: 'providerName',
      width: '85px',
      id: 'providerName',
      index: 0,
      type: 'text',
      resizable: true,
      minResizeWidth: 50,
      searchable: true
    }, {
      label: 'Trip Date',
      id: 'tripDate',
      valuePath: 'tripDate',
      index: 1,
      type: 'text',
      width: '100px',
      resizable: true,
      minResizeWidth: 100,
      searchable: true
    }, {
      label: 'Zone',
      id: 'zone',
      valuePath: 'zoneName',
      index: 2,
      type: 'text',
      width: '100px',
      resizable: true,
      minResizeWidth: 100,
      searchable: true
    }, {
      label: 'Route ID',
      valuePath: 'routeid',
      id: 'routeid',
      index: 3,
      type: 'number',
      width: '60px',
      resizable: true,
      minResizeWidth: 60,
      searchable: true
    }, {
      id: 'routeType',
      label: 'Route Type',
      valuePath: 'routeType',
      index: 4,
      type: 'text',
      width: '80px',
      resizable: true,
      minResizeWidth: 80,
      searchable: true
    }, {
      id: 'driverId',
      index: 5,
      type: 'number',
      label: 'Driver ID',
      valuePath: 'driverId',
      width: '85px',
      resizable: true,
      minResizeWidth: 147,
      searchable: true
    }, {
      id: 'vehicleId',
      index: 6,
      type: 'number',
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      width: '85px',
      resizable: true,
      minResizeWidth: 147,
      searchable: true
    }, {
      label: 'Actual Start Time',
      valuePath: 'actualStartTime',
      id: 'actualStartTime',
      index: 7,
      type: 'time',
      format: 'hh:mm A',
      width: '80px',
      resizable: true,
      minResizeWidth: 80
    }, {
      label: 'Actual End Time',
      valuePath: 'actualEndTime',
      id: 'actualDepartTime',
      index: 8,
      type: 'time',
      format: 'hh:mm A',
      width: '80px',
      resizable: true,
      minResizeWidth: 80
    }]
  };
  _exports.default = _default;
});