define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-trip/component", ["exports", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-trip/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    config: _config.default,
    reconcileLambda: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    tripTable: null,
    selectedTripId: null,
    searchButtonEnabled: Ember.computed.empty('selectedTripId'),
    clearButtonEnabled: Ember.computed.empty('selectedTripId'),

    init() {
      this._super(...arguments);

      this.set('fetchRoutesByTrip', this.get('fetchRoutesByTrip').bind(this));
      this.set('config.title', '');
    },

    updateSearchProperty(value, item) {
      Ember.set(this, item, value);
      this.notifyPropertyChange(item);
    },

    async fetchRoutesByTrip() {
      const selectedTripId = this.get('selectedTripId');
      const searchMode = this.get('selectedSearchMode');

      if (!selectedTripId || !searchMode) {
        return [];
      }

      const routes = await this.reconcileLambda.getReconcileRoutesAndTripsInfoByTripId.perform(searchMode.toLowerCase(), selectedTripId);

      if (!routes.length) {
        this.set('config.title', '');
        this.set('config.noRecordsFound', true);
      } else {
        this.set('config.title', `${Ember.String.capitalize(searchMode)}`);
        this.set('config.noRecordsFound', false);
      }

      const updatedResponse = routes.map(res => {
        return { ...res,
          tripDate: this.get('reconcile.reconcileDate')
        };
      });
      return updatedResponse;
    },

    actions: {
      onSearchRoutesByTrip() {
        this.get('tripTable').refreshData();
      },

      onInputTextChange(valuePath, searchText) {
        Ember.run.debounce(this, 'updateSearchProperty', searchText, valuePath, 200);
      },

      clearSearchTrip() {
        this.set('selectedTripId', null);
        this.get('tripTable').clearData();
        this.set('config.title', '');
        this.set('config.noRecordsFound', false);
        this.set('routeId', null);
      }

    }
  });

  _exports.default = _default;
});