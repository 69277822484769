define("adept-iq/pods/components/side-drawer/add-new-route/component", ["exports", "ember-concurrency", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/mixins/fetchAssignableVehicleDrivers"], function (_exports, _emberConcurrency, _moment, _unwrapProxy, _fetchAssignableVehicleDrivers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^\d*$/;
  const IN_ACTIVE_STATUS = 'inactive';

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    tagName: '',
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    vehicles: null,
    providers: null,
    schedules: null,
    componentName: 'createRoute',
    selectedVehicle: null,
    selectedSchedule: null,
    scheduleOption: null,
    hasAllSelectedValues: Ember.computed.and('routeName', 'selectedProvider', 'selectedSchedule', 'selectedVehicle', 'startTime', 'endTime'),
    onShrinkClick: null,
    onCloseClick: null,
    usePlaceHolderTask: true,
    // used by fetchVehicle Mixin
    dispatchSchedule: null,
    selectedDateTime: null,
    timeFormat: 'm-d-Y h:i K',
    route: Ember.computed.alias('stackFrame.options.newRouteModel'),
    startTime: Ember.computed.alias('route.plannedStartTime'),
    endTime: Ember.computed.alias('route.plannedEndTime'),
    routeName: Ember.computed.alias('route.name'),
    availableVehicles: Ember.computed.readOnly('vehicles'),
    provider: Ember.computed.readOnly('selectedProvider'),
    // used by fetchVehicle Mixin
    selectedProvider: Ember.computed({
      set(key, value) {
        this.set('selectedDriver');
        this.set('selectedVehicle');
        return value;
      }

    }),
    availabilityEndTime: Ember.computed('endTime', 'schedule.end', 'startTime', function () {
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEndTime = this.get('endTime') || this.get('schedule.end') || plannedStart.clone().endOf('day');
      return plannedEndTime;
    }),
    availableSchedules: Ember.computed('schedules.[]', 'startTime', 'endTime', function () {
      let schedules = this.get('schedules');
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEnd = (0, _moment.default)(this.get('endTime'));
      const minDate = this.get('stackFrame.options.minDate');
      const date = (0, _moment.default)(minDate);
      if (!schedules) return null; // only exported schedules

      schedules = schedules.filter(schedule => {
        const dsSchedule = (0, _unwrapProxy.unwrapProxy)(schedule.get('dispatchSchedule'));
        return Ember.isPresent(dsSchedule);
      }); // make sure any existing schedule fits new route planned times

      const sortedSchedules = schedules.filter(schedule => {
        const start = (0, _moment.default)(schedule.get('start'));
        const end = (0, _moment.default)(schedule.get('end'));

        if (plannedStart.isValid() && plannedEnd.isValid()) {
          return start.isSameOrBefore(plannedStart) && end.isSameOrAfter(plannedEnd);
        } // list all schedules having end later than current.


        return end.isAfter(date);
      }).sortBy('name');
      return sortedSchedules;
    }),
    currentSchedule: Ember.computed('availableSchedules', function () {
      const availableSchedules = this.get('availableSchedules') ? this.get('availableSchedules').sortBy('start') : null;

      if (!Ember.isPresent(availableSchedules)) {
        return null;
      }

      const schedule = availableSchedules.firstObject;
      this.onSelScheduleTimeChange(schedule);
      const start = (0, _moment.default)(schedule.get('start'));
      const end = (0, _moment.default)(schedule.get('end'));
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEnd = (0, _moment.default)(this.get('availabilityEndTime'));

      if (!(start.isAfter(plannedEnd) || end.isBefore(plannedStart))) {
        return schedule;
      }

      return null;
    }),

    init() {
      this._super();

      this.set('timePlaceHolder', 'YYYY-MM-DD HH:MM');
      this.setDateTimeRange();
    },

    didInsertElement() {
      this.loadOptionsTask.perform().then(() => {
        this.set('selectedSchedule', this.get('currentSchedule'));
        this.set('selectedDateTime.minDateSelection', this.get('currentSchedule.start'));
      });
    },

    loadOptionsTask: (0, _emberConcurrency.task)(function* () {
      yield this.fetchPlaceHolderVehiclesTask.perform();
      yield this.fetchProvidersTask.perform();
      yield this.fetchSchedulesTask.perform();
    }),
    fetchProvidersTask: (0, _emberConcurrency.task)(function* () {
      const providers = yield this.store.findAll('provider');
      const userProviders = this.get('session.data.authenticated.tokenInfo.providerNames');
      const userHasAllProviders = userProviders.includes('ALL');
      const filterProviderTypes = providers.filter(provider => {
        const providerName = provider.get('name');
        const ProviderStatus = provider.get('status').toLowerCase();
        return ProviderStatus !== IN_ACTIVE_STATUS && (userHasAllProviders || userProviders.includes(providerName));
      });
      this.set('providers', filterProviderTypes);
    }),
    fetchSchedulesTask: (0, _emberConcurrency.task)(function* () {
      // eslint-disable-next-line
      const filterQuery = `eq(type,'primary')`;
      const schedules = yield this.store.query('schedule', {
        filter: filterQuery
      });
      this.set('schedules', schedules);
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const {
        selectedProvider,
        selectedVehicle,
        selectedSchedule,
        route
      } = this.getProperties('selectedProvider', 'selectedVehicle', 'selectedSchedule', 'route');
      const store = this.get('store');
      const routeAdapter = store.adapterFor('route');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      const rvdAdapter = store.adapterFor('route-vehicle-driver');
      const dispatchSchedule = (0, _unwrapProxy.unwrapProxy)(selectedSchedule.get('dispatchSchedule'));
      route.set('vehicle', selectedVehicle);
      route.set('provider', selectedProvider);
      route.set('schedule', (0, _unwrapProxy.unwrapProxy)(selectedSchedule));

      try {
        const scheduleRouteResponse = yield routeAdapter.createScheduleRoute(route);
        const dispatchRouteResponse = yield dispatchRouteAdapter.createDispatchRoute(scheduleRouteResponse, dispatchSchedule);
        const rvdResponse = yield rvdAdapter.createRouteVehicleDriver(dispatchRouteResponse, route);
        return rvdResponse;
      } catch (e) {
        throw e;
      }
    }),

    resetSelections() {
      this.setProperties({
        routeName: null,
        selectedVehicle: null,
        selectedSchedule: null,
        startTime: null,
        endTime: null,
        selectedProvider: null,
        errorMessage: null
      });
      this.setDateTimeRange();
    },

    onSelScheduleTimeChange(schedule) {
      const selectedScheduleStartTime = (0, _moment.default)(schedule.get('start'));
      const plannedStart = (0, _moment.default)(this.get('startTime'));

      if (plannedStart.isBefore(selectedScheduleStartTime)) {
        Ember.run.schedule('afterRender', () => {
          this.set('startTime', selectedScheduleStartTime.toDate());
          this.set('selectedDateTime.startTime', (0, _moment.default)(schedule.get('start')).toISOString());
        });
      }
    },

    setDateTimeRange() {
      const date = this.get('stackFrame.options.minDate');
      const endDate = this.get('dispatchSchedule.end');
      const startTime = this.get('route.plannedStartTime');
      const minDate = (0, _moment.default)(date).toISOString() < (0, _moment.default)(startTime).toISOString() ? (0, _moment.default)(date).toISOString() : (0, _moment.default)(startTime).toISOString();
      this.set('selectedDateTime', {
        minDateSelection: minDate,
        maxDateSelection: (0, _moment.default)(endDate).toISOString(),
        startTime: (0, _moment.default)(startTime).toISOString()
      });
    },

    actions: {
      onSelectScheduleOption(option) {
        this.set('selectedSchedule', option);
        const scheduleStart = option.get('start');
        this.set('selectedDateTime.minDateSelection', scheduleStart);
      },

      onRouteNameInput(name) {
        if (!NUMBER_FORMAT.test(name) || name.length > 10) {
          return this.notifyPropertyChange('routeName');
        }

        this.set('routeName', name);
        return name;
      },

      onChangeTime(valuePath, value) {
        this.set(valuePath, value[0]); // Clear the schedule selection and reselect it.

        this.set('selectedSchedule', this.get('currentSchedule'));
      },

      refresh() {
        this.resetSelections();
        this.loadOptionsTask.perform().then(() => {
          this.set('selectedSchedule', this.get('currentSchedule'));
          this.set('selectedDateTime.minDateSelection', this.get('currentSchedule.start'));
        });
      },

      save() {
        const tooltip = this.get('tooltip');
        const saveTask = this.get('saveTask');
        const routeName = this.get('routeName');
        const hasAllSelectedValues = this.get('hasAllSelectedValues');
        const notifications = this.get('notifications');
        const title = 'Add New Route';
        const plannedStart = this.get('startTime');
        const plannedEndTime = this.get('endTime');
        const routeMaxTimeLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeMaxTimeLimit');
        const routeMaxTimeLimit = routeMaxTimeLimitParam ? parseInt(routeMaxTimeLimitParam.get('value'), 10) : 720; // minutes

        const routeTimeDiff = (0, _moment.default)(plannedEndTime).diff((0, _moment.default)(plannedStart), 'minutes');

        if (routeTimeDiff > routeMaxTimeLimit) {
          this.set('errorMessage', `Route length of ${routeTimeDiff} exceeds config Route Max Time Limit of ${routeMaxTimeLimit} minutes`);
          return false;
        }

        if (!isNaN(parseInt(routeName, 10)) && (parseInt(routeName, 10) > 2147483648 || parseInt(routeName, 10) < 1)) {
          this.set('errorMessage', 'Route ID must be between 1 to 2147483648');
          return false;
        }

        if (plannedStart > plannedEndTime) {
          this.set('errorMessage', 'start time must always be less than the end time');
          return false;
        }

        if (hasAllSelectedValues) {
          // reset error message
          this.set('errorMessage');
        } else {
          // show error message, missing properties
          this.set('errorMessage', 'Please fill out all of the fields before saving.');
          return false;
        }

        tooltip.pushConfirmation({
          tip: `Create route Name ${routeName}`,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveTask.perform().then(() => {
              this.onCloseClick();
              notifications.success(`Successfully created new route ${routeName}`);
              tooltip.reset();
            }).catch(error => {
              let message;

              switch (error.status) {
                case 400:
                  message = 'There was a problem with one of the fields. Please check over the form and try again.';
                  break;

                case 409:
                  message = `The route name ${this.get('routeName')} is already taken, please try another.`;
                  break;

                default:
                  message = 'There was a problem creating the route, please try again.'; // eslint-disable-next-line no-console

                  console.error(error);
                  break;
              }

              this.set('errorMessage', message);
              notifications.warning(message);
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => tooltip.reset()
        });
      }

    }
  });

  _exports.default = _default;
});