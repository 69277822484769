define("adept-iq/pods/components/card-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PtVqc27v",
    "block": "{\"symbols\":[\"@iconPath\",\"@altText\",\"@onClick\",\"@customClasses\",\"@title\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card-item\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"card \",[22,4,[]]]]],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"image-container\"],[9],[0,\"\\n      \"],[7,\"img\"],[12,\"src\",[22,1,[]]],[11,\"class\",\"card-img-top p-4\"],[12,\"alt\",[22,2,[]]],[9],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[22,3,[]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"image-container\"],[9],[0,\"\\n      \"],[7,\"img\"],[12,\"src\",[22,1,[]]],[11,\"class\",\"card-img-top p-4\"],[12,\"alt\",[22,2,[]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-body text-center\"],[9],[0,\"\\n      \"],[7,\"h5\"],[11,\"class\",\"card-title\"],[9],[1,[22,5,[]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/card-item/template.hbs"
    }
  });

  _exports.default = _default;
});