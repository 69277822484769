define("adept-iq/pods/components/iq-widgets/subscription-form/component", ["exports", "moment", "adept-iq/config/mapped-permIds", "adept-iq/pods/components/generic-widgets/base-widget/component", "lodash", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "adept-iq/utils/geolib"], function (_exports, _moment, _mappedPermIds, _component, _lodash, _unwrapProxy, _polyline, _geolib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  const {
    bemap
  } = window;
  const PICK = 'pick';
  const DROP = 'appt';
  const PHONE_NUMBER_LENGTH = 10;

  const convertDate = str => {
    var date = new Date(str);
    var mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  const SUSPENSIONDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'holiday'];

  var _default = _component.default.extend({
    classNames: ['new-booking-form-side-drawer'],
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    booking: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    fareCalc: Ember.inject.service(),
    subscriptionService: Ember.inject.service('subscription'),
    subscriptionLogGeneration: Ember.inject.service('subscription-activitylog-generation'),
    isProgressShown: false,
    futureTripsWarning: false,
    riderToBook: null,
    serviceNeeds: null,
    subscription: null,
    futureTimelineDate: null,
    futureBookingTimeline: null,
    defaultFare: 0,
    companionFare: 0,
    isLoadAndUnLoadTimeValid: true,
    isLoadAndUnLoadTimeNumber: false,
    travelNeedCollapseState: 'expanded',
    passengerCollapseState: 'collapsed',
    serviceNeedCollapseState: 'collapsed',
    // Checking the leg date eligibility
    selectedLegDate: null,
    isEligiblitiyCheck: false,
    isOpenErrorModal: Ember.computed.alias('booking.isOpenErrorModal'),
    errorMessage: Ember.computed.alias('booking.errorMessage'),
    canSave: Ember.computed.alias('booking.canSave'),
    isEditMode: false,
    hasFutureTrips: false,
    passengerInfo: null,
    legTravelNeeds: null,
    subscriptionServiceNeeds: null,
    effectiveRecurrencePattern: null,
    legsInBooking: Ember.computed.alias('activeLegsInBooking'),
    pickupPostalAddress: null,
    dropoffPostalAddress: null,
    pickAddressText: null,
    dropAddressText: null,
    selectedRiders: null,
    disablePhoneNumber: null,
    phoneNumbers: null,
    previousCompanionCount: null,
    isComputingDistance: false,
    distancePt1: null,
    distancePt2: null,
    selectedCompanionCount: Ember.computed.alias('booking.selectedCompanionCount'),
    // eslint-disable-next-line ember/use-brace-expansion
    riderFullNames: Ember.computed('booking.selectedRiders.[]', function () {
      return this.get('editableRecords')[1].get('fullName');
    }),
    noOfActiveRiders: Ember.computed('booking.selectedRiders.[]', function () {
      return this.get('booking.selectedRiders').length;
    }),
    passengerListTitle: Ember.computed('noOfActiveRiders', function () {
      return 'Passenger List (1)';
    }),
    bookingPanelTitle: Ember.computed('isEditMode', function () {
      return this.get('isEditMode') ? 'Edit Subscription' : 'Create Subscription';
    }),
    activeLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', function () {
      return this.get('booking.activeBooking.legs');
    }),
    activeLegsInBookingForMapObserver: Ember.observer('booking.activeBooking.legs.[]', function () {
      this.booking.setActiveLegsInBooking(this.get('activeLegsInBooking'));
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    tripLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', 'booking.activeBooking.legs', function () {
      const tripLegs = this.get('booking.activeBooking.legs').filterBy('purpose', 'trip');
      return tripLegs.sortBy('legOrdinal');
    }),
    isSubscription: Ember.computed('subscription.latestRecurrence.recurring', function () {
      return this.get('subscription.latestRecurrence.recurring');
    }),
    addressesOfRiderToBook: Ember.computed('riderToBook', function () {
      return this.get('riderToBook.places').map(place => {
        if (Ember.typeOf(place) === 'object') {
          return this.store.createRecord('address', {
            freeformAddress: '',
            // we give this empty because nyct does give this field,full address in address model checks for this
            streetNumber: place.address.streetNumber,
            streetAddress: place.address.streetAddress,
            notes: place.address.notes,
            premise: place.address.premise,
            region: place.address.region,
            locality: place.address.locality,
            subLocality: place.address.locality,
            postalCode: place.address.postalCode,
            lat: place.address.lat,
            lng: place.address.lng,
            zName: place.address.zName
          });
        }

        return this.store.createRecord('address', {
          freeformAddress: place.get('address.freeformAddress') || '',
          streetNumber: place.get('address.streetNumber'),
          streetAddress: place.get('address.streetAddress'),
          notes: place.get('address.notes'),
          premise: place.get('address.premise'),
          region: place.get('address.region'),
          locality: place.get('address.locality'),
          subLocality: place.get('address.subLocality'),
          postalCode: place.get('address.postalCode'),
          lat: place.get('location.lat'),
          lng: place.get('location.lng'),
          zName: place.get('address.zName')
        });
      });
    }),
    popUpHeaderTitle: Ember.computed('isEditMode', function () {
      return this.get('isEditMode') ? 'Edit Subscription' : 'Create Subscription';
    }),

    init() {
      this._super(...arguments);

      this.configureAddTripPermission();
      const records = this.get('editableRecords');
      this.set('isEditMode', records[0]);
      this.set('subscription', records[2].get('subscription'));
      this.activeLegsInBookingForMapObserver();

      if (this.get('isEditMode')) {
        this.initEditMode();
      } else {
        this.initAddMode();
      }

      const futureBookingTimeline = this.get('store').peekRecord('cs-config-item', 'config-Book_Trips/Future_Booking_Timeline');
      const previousSubscriptionDays = {};
      this.set('futureBookingTimeline', parseInt(futureBookingTimeline.get('value'), 10));
      this.syncPickAndDrop = this.syncPickAndDrop.bind(this);
      this.onFirstLegDateChange = this.onFirstLegDateChange.bind(this);
      this.updateFare = this.updateFare.bind(this);
      this.setupManualSubscription = this.setupManualSubscription.bind(this);
      this.set('companionFare', this.get('booking').getSysConfigCompanionFare());
      this.setDefaultFare();
      this.combineServiceNeedsForGroupBooking();
      this.set('booking.previousSubscriptionStartDate', this.get('subscription.startDate'));
      this.set('booking.previousSubscriptionEndDate', this.get('subscription.endDate'));
      this.set('booking.previousSubscriptionSuspendStartDate', this.get('subscription.exclusions.firstObject.startDate'));
      this.set('booking.previousSubscriptionSuspendEndDate', this.get('subscription.exclusions.firstObject.endDate'));
      this.set('subscriptionService.previousSchedulingMode', this.get('subscription.schedulingMode'));
      this.set('subscriptionService.previousOriginPlannedEta', this.get('subscription.originPlannedEta'));
      this.set('subscriptionService.previousDestinationPlannedEta', this.get('subscription.destinationPlannedEta'));
      this.set('subscriptionService.previousRouteTemplate', this.get('subscription.routeTemplate'));
      SUSPENSIONDAYS.forEach(day => {
        const subscriptionDay = `subscription.latestRecurrence.${day}`;

        if (Ember.isPresent(this.get('subscription.latestRecurrence'))) {
          previousSubscriptionDays[day] = this.get(subscriptionDay);
        }
      });
      this.set('subscriptionService.previousSubscriptionDays', previousSubscriptionDays);
      this.set('selectedRiders', this.get('booking.selectedRiders'));
      this.setDefaultBookingData();
    },

    setDefaultBookingData() {
      const isEditMode = this.get('isEditMode');
      const store = this.get('store');
      const records = this.get('editableRecords');
      const selectedRidersLength = this.get('booking.noOfSelectedRiders');
      const riderTravelNeeds = selectedRidersLength > 1 ? this.get('booking').reGroupLegtravelneedsForGroupBooking() : this.get('booking.selectedRiders.firstObject.travelNeeds');
      const legTravelNeeds = isEditMode ? this.get('legTravelNeeds') : this.get('booking').createLegTravelNeeds(riderTravelNeeds).toArray();
      const bookingRecord = (0, _lodash.clone)(records[2]);
      const loadTime = records[1].loadTime;
      const unLoadTime = records[1].unloadTime;
      const notes = records[1].notes;
      this.set('riderTravelNeeds', riderTravelNeeds.map(riderTravelNeed => {
        const travelNeedType = store.peekAll('travel-need-type').find(tntype => {
          return tntype.get('id') === riderTravelNeed.type;
        });
        return travelNeedType;
      }));
      let formattedPhoneNumber;

      if (records[1].notificationPhoneNumbers.length > 1) {
        const areaCode = records[1].notificationPhoneNumbers[0].areaCode;
        const phoneNumber = records[1].notificationPhoneNumbers[0].phoneNumber;
        formattedPhoneNumber = `${areaCode}-${phoneNumber}`;
      }

      const ambulatoryTravelNeed = legTravelNeeds.find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
      });
      let ambulatorySeatCount = 0;

      if (isEditMode) {
        ambulatorySeatCount = Ember.isPresent(ambulatoryTravelNeed) ? ambulatoryTravelNeed.get('count') : 0;
      } else {
        ambulatorySeatCount = this.get('booking').calculateAmbulatoryCount(legTravelNeeds);
      }

      if (ambulatoryTravelNeed) {
        ambulatoryTravelNeed.set('count', ambulatorySeatCount);
      }

      this.set('previousCompanionCount', this.get('booking').getTravelNeedsCompanion(legTravelNeeds).map(legTravelNeed => {
        return {
          count: legTravelNeed.get('count'),
          travelNeedTypeNameUppercase: legTravelNeed.get('travelNeedTypeNameUppercase')
        };
      }));
      const rider = this.get('selectedRiders');
      const riderPhoneNumber = rider.get('firstObject.formattedPhoneNumber');
      const record = Ember.Object.create({
        legs: [].concat(bookingRecord.get('legs').toArray()),
        booking: bookingRecord,
        legTravelNeeds: legTravelNeeds.toArray(),
        loadTime: loadTime,
        unLoadTime: unLoadTime,
        notes: notes,
        formattedPhoneNumber: formattedPhoneNumber,
        riderPhoneNumber: riderPhoneNumber,
        rider: rider
      });
      record.set('legSegments', this.createClonedLegSegments(record.get('legs')));
      record.set('travelNeeds', this.createClonedTravelNeeds(record.get('legTravelNeeds')));
      record.set('subscription', this.createSuspensionRecord(this.get('subscription')));
      this.set('defaultBookingData', record);
      this.createClonedPhoneNumbers(this.get('defaultBookingData'));
      this.set('defaultBookingData.phoneNumbers', this.get('phoneNumbers'));

      if (this.get('isEditMode')) {
        const defaultActivityLogData = this.get('subscriptionLogGeneration').subscriptionDataForActivityLog(record.get('booking.subscription'), record.get('subscription'));
        this.set('subscriptionService.defaultActivityLogData', defaultActivityLogData);
      }
    },

    createPlaceRecord(place) {
      const store = this.get('store');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const addressAttr = address.toJSON();
      const placeAttr = place.toJSON();
      const clonedAddress = store.createRecord('address', addressAttr);
      const clonePlace = store.createRecord('place', placeAttr);
      clonePlace.set('address', clonedAddress); // if clone the location it will update the location in map

      clonePlace.set('location', place.get('location'));
      return clonePlace;
    },

    /**
    * To save the previous subscription pick and drop phone number for future use(undo,etc)
    * @param {*} defaultBookingData
    */
    createClonedPhoneNumbers(defaultBookingData) {
      let pickPhoneNumber = '';
      let dropPhoneNumber = '';
      let phoneNumbers = [...defaultBookingData.get('booking.subscription.phoneNumbers').toArray()]; //To check whether the phone number is entered or empty

      if (phoneNumbers.length) {
        const previousPickPhoneNumberRecord = phoneNumbers.get('firstObject');
        const previousDropPhoneNumberRecord = phoneNumbers.get('lastObject');
        pickPhoneNumber = previousPickPhoneNumberRecord.toJSON();
        dropPhoneNumber = previousDropPhoneNumberRecord.toJSON();
        pickPhoneNumber.compareKey = 'formattedPhoneNumber';
        dropPhoneNumber.compareKey = 'formattedPhoneNumber';
        phoneNumbers = [{
          pickPhoneNumber: pickPhoneNumber,
          dropPhoneNumber: dropPhoneNumber
        }];
      }

      this.set('phoneNumbers', phoneNumbers);
    },

    createClonedLegSegments(legs) {
      const legSegments = legs.map(leg => {
        const pickPlace = this.createPlaceRecord((0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick.place')));
        const dropPlace = this.createPlaceRecord((0, _unwrapProxy.unwrapProxy)(leg.get('segments.lastObject.drop.place')));
        const fare = leg.get('segments.firstObject.fare');
        const pickNotes = leg.get('segments.firstObject.pick.notes');
        const dropNotes = leg.get('segments.lastObject.drop.notes');
        const pickPlaceAddress = (0, _unwrapProxy.unwrapProxy)(pickPlace.get('address')).toJSON();
        const dropPlaceAddress = (0, _unwrapProxy.unwrapProxy)(dropPlace.get('address')).toJSON();
        const pickPlaceLocation = (0, _unwrapProxy.unwrapProxy)(pickPlace.get('location')).toJSON();
        const dropPlaceLocation = (0, _unwrapProxy.unwrapProxy)(dropPlace.get('location')).toJSON();
        const pickZoneName = leg.get('segments.firstObject.pick.place.address.zoneName.name');
        const dropZoneName = leg.get('segments.lastObject.drop.place.address.zoneName.name');
        return Ember.Object.create({
          pickPlace: pickPlace,
          dropPlace: dropPlace,
          pickPlaceAddress: pickPlaceAddress,
          dropPlaceAddress: dropPlaceAddress,
          pickPlaceLocation: pickPlaceLocation,
          dropPlaceLocation: dropPlaceLocation,
          fare: fare,
          pickNotes: pickNotes,
          dropNotes: dropNotes,
          serviceWindow: leg.get('serviceWindow'),
          fundingAgency: leg.get('fundingAgency'),
          tripPurpose: leg.get('tripPurpose'),
          anchor: leg.get('anchor'),
          requestTime: leg.get('requestTime'),
          pickZoneName: pickZoneName,
          dropZoneName: dropZoneName
        });
      });
      return legSegments;
    },

    createClonedTravelNeeds(legTravelNeed) {
      const legTravelNeeds = legTravelNeed.map(travelNeed => {
        return Ember.Object.create({
          count: travelNeed.get('count'),
          travelNeedType: travelNeed.get('travelNeedType'),
          passengerType: travelNeed.get('passengerType'),
          travelNeedTypeNameUppercase: travelNeed.get('travelNeedTypeNameUppercase'),
          compareKey: 'travelNeedTypeNameUppercase'
        });
      });
      return legTravelNeeds;
    },

    createSuspensionRecord(subscription) {
      const suspensionStatus = subscription.get('status') ? subscription.get('status') : 'active';
      const suspensionType = subscription.get('exclusions.firstObject.type') ? subscription.get('exclusions.firstObject.type') : 'suspension';
      const effectiveRecurrencePattern = subscription.get('latestRecurrence');
      this.set('effectiveRecurrencePattern', effectiveRecurrencePattern);
      const isSuspensionOnSunday = Ember.isPresent(effectiveRecurrencePattern) ? effectiveRecurrencePattern.get('sunday') : false;
      const isSuspensionOnMonday = Ember.isPresent(effectiveRecurrencePattern) ? effectiveRecurrencePattern.get('monday') : false;
      const isSuspensionOnTuesday = Ember.isPresent(effectiveRecurrencePattern) ? effectiveRecurrencePattern.get('tuesday') : false;
      const isSuspensionOnWednesday = Ember.isPresent(effectiveRecurrencePattern) ? effectiveRecurrencePattern.get('wednesday') : false;
      const isSuspensionOnThursday = Ember.isPresent(effectiveRecurrencePattern) ? effectiveRecurrencePattern.get('thursday') : false;
      const isSuspensionOnFriday = Ember.isPresent(effectiveRecurrencePattern) ? effectiveRecurrencePattern.get('friday') : false;
      const isSuspensionOnSaturday = Ember.isPresent(effectiveRecurrencePattern) ? effectiveRecurrencePattern.get('saturday') : false;
      const isSuspensionOnHoliday = Ember.isPresent(effectiveRecurrencePattern) ? effectiveRecurrencePattern.get('holiday') : false;
      const isSuspended = subscription.get('exclusions.firstObject.suspend');
      const noSharing = subscription.get('noSharing') ? subscription.get('noSharing') : 0;
      const suspensionRecord = Ember.Object.create({
        status: suspensionStatus,
        type: suspensionType,
        isSuspended: isSuspended,
        noSharing: noSharing,
        sunday: isSuspensionOnSunday,
        monday: isSuspensionOnMonday,
        tuesday: isSuspensionOnTuesday,
        wednesday: isSuspensionOnWednesday,
        thursday: isSuspensionOnThursday,
        friday: isSuspensionOnFriday,
        saturday: isSuspensionOnSaturday,
        holiday: isSuspensionOnHoliday
      });
      return suspensionRecord;
    },

    willDestroyElement() {
      this.set('booking.selectedCompanionCount', 0);
      this.set('booking.isOpenErrorModal', false);
      this.set('booking.previousSubscriptionStartDate', null);
      this.set('booking.previousSubscriptionEndDate', null);
      this.set('booking.previousSubscriptionSuspendStartDate', null);
      this.set('booking.previousSubscriptionSuspendEndDate', null);
      this.set('subscriptionService.previousSchedulingMode', null);
      this.set('subscriptionService.previousOriginPlannedEta', null);
      this.set('subscriptionService.previousDestinationPlannedEta', null);
      this.set('subscriptionService.previousRouteTemplate', null);
      this.set('subscriptionService.previousSubscriptionDays', null);
      this.set('booking.bookingMap', null);

      this._super(...arguments);
    },

    initEditMode() {
      const record = this.get('editableRecords');
      const subscriptionTravelNeeds = this.get('subscription.subscriptionTravelNeeds').toArray();
      const clonedLegTravelNeeds = [...subscriptionTravelNeeds];
      const legTravelNeeds = clonedLegTravelNeeds;
      const subscriptionServiceNeeds = this.get('subscription.subscriptionServiceNeeds');
      this.set('riderToBook', record[1]);
      this.set('hasFutureTrips', record[3]);
      this.set('legTravelNeeds', legTravelNeeds);
      this.set('subscriptionServiceNeeds', subscriptionServiceNeeds);
    },

    initAddMode() {
      const record = this.get('editableRecords');
      const travelNeeds = record[2].legs.firstObject.legTravelNeeds;
      const subscription = record[2].get('subscription');
      const subscriptionServiceNeeds = subscription.get('subscription.subscriptionServiceNeeds');
      const store = this.get('store');
      const rider = store.peekRecord('rider', record[1].id);
      const subscriptionTravelneeds = travelNeeds.map(travelNeed => {
        return store.createRecord('subscription-travel-need', {
          count: travelNeed.get('count'),
          travelNeedType: travelNeed.get('travelNeedType'),
          passengerType: travelNeed.get('passengerType')
        });
      });
      this.set('riderToBook', record[1]);
      this.set('legTravelNeeds', subscriptionTravelneeds);
      this.set('subscriptionServiceNeeds', subscriptionServiceNeeds);
      subscription.set('fareCategory', rider.get('fareCategory'));
    },

    duplicateWideSeats(legTravelNeeds) {
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('name').toUpperCase());
      const bigSeatTravelneeds = legTravelNeeds.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      bigSeatTravelneeds.forEach(tr => {
        const wideTravelNeed = bigSeatsTN.find(travelNeed => {
          return travelNeed.get('travelNeedTypeNameUppercase') === tr.get('name');
        });
        const configCount = parseInt(wideTravelNeed.get('vehicleCapacityCount'), 10);

        if (tr.count > configCount) {
          const count = tr.count / configCount;

          for (let i = 1; i < count; i++) {
            const newLegTravelNeed = this.store.createRecord('subscription-travel-need', {
              count: configCount,
              travelNeedType: tr.get('travelNeedType'),
              passengerType: tr.get('passengerType')
            });
            legTravelNeeds.pushObject(newLegTravelNeed);
          }
        }
      });
      return legTravelNeeds;
    },

    onNoteChange(value) {
      this.set('riderToBook.notes', value);
    },

    checkIfFullAddressPresent(address) {
      return !Ember.isEmpty(address.get('fullAddress'));
    },

    syncPickAndDrop() {
      const legsInBooking = this.get('tripLegsInBooking').toArray();
      this.syncLegsInBooking(legsInBooking); // last trip leg's drop should first return trip leg's pick

      if (this.get('returnTripLegsInBooking.length')) {
        const lastTripLeg = this.get('tripLegsInBooking.lastObject');
        const firstReturnLeg = this.get('returnTripLegsInBooking.firstObject');
        const prevDrop = lastTripLeg.get('segments.firstObject.drop.place');
        const currPick = firstReturnLeg.get('segments.firstObject.pick');
        const previousDrop = this.addressRecordCreation(prevDrop);
        currPick.set('place', previousDrop);
      }

      const returnLegsInBooking = this.get('returnTripLegsInBooking').toArray();
      this.syncLegsInBooking(returnLegsInBooking);
      const activelegsInBooking = this.get('activeLegsInBooking');
      this.booking.setActiveLegsInBooking(activelegsInBooking);
    },

    syncLegsInBooking(legsInBooking) {
      if (legsInBooking.length <= 1) {
        return;
      }

      for (let i = 1; i < legsInBooking.length; i++) {
        const previousLeg = legsInBooking[i - 1];
        const currentLeg = legsInBooking[i];
        const prevDrop = previousLeg.get('segments.firstObject.drop.place');
        const currPick = currentLeg.get('segments.firstObject.pick');
        const pickPlace = this.addressRecordCreation(prevDrop);
        currPick.set('place', pickPlace);
      }
    },

    /**
     * address record creation
     */
    addressRecordCreation(prevDrop) {
      const pickAddress = this.store.createRecord('address', {
        freeformAddress: prevDrop.get('address.freeformAddress') || '',
        streetNumber: prevDrop.get('address.streetNumber'),
        streetAddress: prevDrop.get('address.streetAddress'),
        notes: prevDrop.get('address.notes'),
        premise: prevDrop.get('address.premise'),
        region: prevDrop.get('address.region'),
        locality: prevDrop.get('address.locality'),
        subLocality: prevDrop.get('address.subLocality'),
        postalCode: prevDrop.get('address.postalCode'),
        zName: prevDrop.get('address.zName')
      });
      const pickLocation = this.store.createRecord('location', {
        lat: prevDrop.get('location.lat'),
        lng: prevDrop.get('location.lng'),
        geoNode: prevDrop.get('location.geoNode')
      });
      const pickPlace = this.store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      return pickPlace;
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.edit-widget-modal').width('400');
      Ember.$('.booking-map-container').css({
        'width': 'calc(95% - ' + Ember.$('.edit-widget-modal').width() + 'px)'
      }).css({
        'height': 'calc(100% - 20%)',
        'left': Ember.$('.edit-widget-modal').width() + 'px)'
      });
      this.set('travelNeedCollapseState', 'collapsed');
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    getNewLegDate(prevLeg) {
      const bufferMinutes = 2;
      const rTime = prevLeg.get('requestTime');
      const currentDateAndTime = (0, _moment.default)(rTime);
      const newMomentDate = (0, _moment.default)();
      const currentYear = currentDateAndTime.year();
      const currentMonth = currentDateAndTime.month();
      const currentDay = currentDateAndTime.date();
      const currentHour = currentDateAndTime.hour();
      const currentMinutes = currentDateAndTime.minutes();
      const currentSeconds = currentDateAndTime.seconds();
      newMomentDate.year(currentYear);
      newMomentDate.month(currentMonth);
      newMomentDate.date(currentDay);
      newMomentDate.hour(currentHour);
      newMomentDate.minutes(currentMinutes + bufferMinutes);
      newMomentDate.seconds(currentSeconds);
      const selectedDateObjectWithTime = newMomentDate.toDate();
      return selectedDateObjectWithTime;
    },

    configureAddTripPermission() {
      const record = this.get('editableRecords')[1];
      const sameDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createSameDayBookings, null);
      const FutureDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createFutureBookings, null);
      let maxDate = null;
      let minDate = null;

      if (sameDayTripPermGranted && !FutureDayTripPermGranted) {
        maxDate = (0, _moment.default)().endOf('day');
      }

      if (FutureDayTripPermGranted && !sameDayTripPermGranted) {
        minDate = (0, _moment.default)().startOf('day').add(1, 'day').toDate();
      }

      record.set('tripMaxDate', maxDate);
      record.set('tripMinDate', minDate);
    },

    async createLegSegmentRecord(isReturn) {
      const store = this.get('store');
      const serviceWindow = store.peekRecord('service-window', 'GENERAL');
      const fareType = store.peekAll('fare-type').firstObject;
      const rider = this.get('booking.selectedRiders.firstObject');
      const legTravelNeeds = this.get('legTravelNeeds');
      let date;

      if (!isReturn || !this.get('returnTripLegsInBooking.lastObject.requestTime')) {
        const legToUse = this.get('tripLegsInBooking.lastObject');
        date = this.getNewLegDate(legToUse);
      } else {
        const legToUse = this.get('returnTripLegsInBooking.lastObject');
        date = this.getNewLegDate(legToUse);
      }

      const pickAddress = store.createRecord('address');
      const pickLocation = store.createRecord('location');
      const pickPlace = store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      const dropAddress = store.createRecord('address');
      const dropLocation = store.createRecord('location');
      const dropPlace = store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      const leg = store.createRecord('leg', {
        requestTime: date,
        anchor: 'pick',
        purpose: isReturn ? 'return' : 'trip',
        notes: '',
        serviceWindow
      });
      const legOrdinalForNewLeg = 1;
      const fareAmount = await this.get('fareCalc').calcFareByRiderIdAndTravelNeeds(rider, legTravelNeeds);
      const segment = store.createRecord('segment', {
        promiseTime: date,
        anchor: 'Pick',
        fare: fareAmount,
        travelMode: 'paratransit',
        segmentType: 2,
        noSharing: 0,
        legOrdinal: legOrdinalForNewLeg,
        pick: store.createRecord('segment-stop', {
          type: 'pick',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: pickPlace
        }),
        drop: store.createRecord('segment-stop', {
          type: 'drop',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: dropPlace
        }),
        fareType: fareType,
        leg: leg
      });
      leg.set('segments', [segment]);
      return leg;
    },

    getLegTypeField(isReturn) {
      return isReturn ? 'returnTripLegsInBooking' : 'tripLegsInBooking';
    },

    // we are just checking using postalCode because both nyct and tomtom returns that consistently
    validateIfPickAndDropArePresentInAllLegs() {
      let allLegsHavePickAndDrop = true;
      this.get('legsInBooking').forEach(leg => {
        if (leg.get('segments.firstObject.pick.place.address.postalCode') && leg.get('segments.firstObject.drop.place.address.postalCode')) {
          allLegsHavePickAndDrop = true;
        } else {
          allLegsHavePickAndDrop = false;

          if (leg.get('segments.firstObject.pick.place.address.postalCode')) {
            const pickPostalCode = leg.get('segments.firstObject.pick.place.address.postalCode');
            this.set('pickupPostalAddress', pickPostalCode);
          }

          if (leg.get('segments.firstObject.drop.place.address.postalCode')) {
            const dropPostalCode = leg.get('segments.firstObject.drop.place.address.postalCode');
            this.set('dropoffPostalAddress', dropPostalCode);
          }
        }
      });
      return allLegsHavePickAndDrop;
    },

    validateFromToDatesNotToBePastDates(startDate, endDate, type) {
      const currentDate = (0, _moment.default)().startOf('day');
      const prevStartDate = type === 'Subscription' ? this.get('booking.previousSubscriptionStartDate') : this.get('booking.previousSubscriptionSuspendStartDate');
      const prevEndDate = type === 'Subscription' ? this.get('booking.previousSubscriptionEndDate') : this.get('booking.previousSubscriptionSuspendEndDate'); // prevent user to select previous date on editMode only allow current date and after for subscription

      if (!this.get('booking').validateDateNotToBePastDate(startDate, currentDate, prevStartDate, type, 'From')) {
        return false;
      }

      if (!this.get('booking').validateDateNotToBePastDate(endDate, currentDate, prevEndDate, type, 'To')) {
        return false;
      }

      return true;
    },

    validateFromToDates(startDate, endDate, type) {
      if (Ember.isEmpty(startDate) || Ember.isNone(startDate) || Ember.isEmpty(endDate) || Ember.isNone(endDate)) {
        this.get('booking').showErrorMessage(`Please select ${type} From date and To date.`);
        return false;
      }

      if (!(0, _moment.default)(convertDate(startDate)).isSameOrBefore((0, _moment.default)(convertDate(endDate)))) {
        this.get('booking').showErrorMessage(`${type} From date must be same or before To date.`);
        return false;
      }

      return true;
    },

    validateForAtleastOneDaySelected() {
      const recurrencePattern = this.get('effectiveRecurrencePattern');
      return recurrencePattern.get('sunday') || recurrencePattern.get('monday') || recurrencePattern.get('tuesday') || recurrencePattern.get('wednesday') || recurrencePattern.get('thursday') || recurrencePattern.get('friday') || recurrencePattern.get('saturday') || recurrencePattern.get('holiday');
    },

    validateSeatAndPassengerCount() {
      const noOfRiders = this.get('booking').noOfSelectedRiders;
      const noOfPassengers = noOfRiders;
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('name').toUpperCase());
      const bigSeatTravelneeds = this.get('legTravelNeeds').filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const wheelchairTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
      });
      const ambulatoryTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
      });
      const wheelchaircount = wheelchairTravelNeed ? parseInt(wheelchairTravelNeed.count, 10) : 0;
      const ambulatoryCount = ambulatoryTravelNeed ? parseInt(ambulatoryTravelNeed.count, 10) : 0;
      const noOfSeats = ambulatoryCount + wheelchaircount + bigSeatTravelneeds.length;

      if (noOfPassengers !== noOfSeats) {
        return false;
      }

      return true;
    },

    validateEmptyTravelNeeds() {
      return this.get('legTravelNeeds').toArray().some(tr => !tr.get('travelNeedTypeNameUppercase'));
    },

    /**
     *  NYAAR-18964 The validation should be applied on the save tick icon and it shows the warning pop up less then 10 Digit phone number.
     * @returns {boolean}
     * @param {phoneNumber}
     */
    validatePhoneNumber(phoneNumber) {
      let valid = true;

      if (phoneNumber.length < PHONE_NUMBER_LENGTH) {
        valid = false;
      }

      return valid;
    },

    validateRecords() {
      let valid = true;
      this.set('editModal.errors', []);
      const firstLeg = this.get('legsInBooking.firstObject');
      const pickAddress = firstLeg.get('segments.firstObject.pick.place.address.postalCode');
      const dropAddress = firstLeg.get('segments.firstObject.drop.place.address.postalCode');
      const requestTime = firstLeg.get('requestTime');
      const formattedPhoneNumber = this.get('editableRecords')[1].get('formattedPhoneNumber');
      const requestType = firstLeg.get('anchor');
      const isManualSchedulingMode = this.get('subscription.isManualSchedulingMode');

      if (this.get('isSubscription')) {
        const startDate = this.get('subscription.startDate');
        const endDate = this.get('subscription.endDate');

        if (!this.validateFromToDates(startDate, endDate, 'Subscription')) {
          valid = false;
        }

        if (!this.validateFromToDatesNotToBePastDates(startDate, endDate, 'Subscription')) {
          valid = false;
        }

        if (!this.validateForAtleastOneDaySelected()) {
          this.get('booking').showErrorMessage('Please select at least one day from weekdays.');
          valid = false;
        }
      }

      if (this.get('subscription.exclusions.firstObject.suspend')) {
        const startDate = this.get('subscription.exclusions.firstObject.startDate');
        const endDate = this.get('subscription.exclusions.firstObject.endDate');

        if (!this.validateFromToDates(startDate, endDate, 'Suspend')) {
          valid = false;
        }

        if (!this.validateFromToDatesNotToBePastDates(startDate, endDate, 'Suspend')) {
          valid = false;
        }
      }

      if (isManualSchedulingMode) {
        const originPlannedEta = this.get('subscription.originPlannedEta');
        const destinationPlannedEta = this.get('subscription.destinationPlannedEta');
        const routeTemplate = this.get('subscription.routeTemplate.content');

        if (Ember.isEmpty(routeTemplate)) {
          this.get('booking').showErrorMessage('Please select a manual route.');
          valid = false;
        }

        if (Ember.isEmpty(originPlannedEta) || Ember.isEmpty(destinationPlannedEta)) {
          this.get('booking').showErrorMessage('Please select a start time and end time.');
          valid = false;
        }
      }

      if (!this.get('isLoadAndUnLoadTimeValid')) {
        this.get('booking').showErrorMessage('Load/Unload time value must be 15 min or less.');
        valid = false;
      }

      if (!this.get('isLoadAndUnLoadTimeNumber')) {
        this.get('booking').showErrorMessage('Please enter valid numbers for load/unload time.');
        valid = false;
      } //NYAAR-20220-User is able to create subscription without selecting request type


      if (Ember.isEmpty(requestType) || Ember.isNone(requestType)) {
        this.get('booking').showErrorMessage('Request type is missing. Please select a request type.');
        valid = false;
      }

      if (Ember.isEmpty(requestTime) || Ember.isNone(requestTime)) {
        this.get('booking').showErrorMessage('Request time is missing. Please enter a valid time.');
        valid = false;
      }

      if (Ember.isEmpty(pickAddress) || Ember.isNone(pickAddress)) {
        this.get('booking').showErrorMessage('Unknown Address. Please enter a valid Pickup Leg address.');
        valid = false;
      }

      if (Ember.isEmpty(dropAddress) || Ember.isNone(dropAddress)) {
        this.get('booking').showErrorMessage('Unknown Address. Please enter a valid DropOff Leg address.');
        valid = false;
      }

      if (!this.validateIfPickAndDropArePresentInAllLegs()) {
        if (!this.get('pickupPostalAddress') && !this.get('dropoffPostalAddress')) {
          this.get('booking').showErrorMessage('Unknown Address. Please enter a valid address.');
        } else {
          if (!this.get('pickupPostalAddress')) {
            this.get('booking').showErrorMessage('Unknown Address. Please enter a valid Pickup Leg address.');
          }

          if (!this.get('dropoffPostalAddress')) {
            this.get('booking').showErrorMessage('Unknown Address. Please enter a valid DropOff Leg address.');
          }
        }

        valid = false;
      }

      if (!this.validateLegRequestTimeAfterPrevLegRequestTime()) {
        this.get('booking').showErrorMessage('The leg trip time must come after the previous trip request time.');
        valid = false;
      }

      if (!this.validateEligibility(firstLeg)) {
        this.set('selectedLegDate', (0, _moment.default)(firstLeg.get('requestTime')).format('M/D/Y'));
        this.get('booking').showErrorMessage(`${this.get('riderToBook.firstName')} ${this.get('riderToBook.lastName')}
      has no client eligibility records for the date \n  ${this.get('selectedLegDate')} . Please select another date.`);
        valid = false;
      }

      if (this.validateEmptyTravelNeeds()) {
        this.get('booking').showErrorMessage('Please remove empty Travel needs to proceed.');
        valid = false;
      }

      if (!this.validatePhoneNumber(formattedPhoneNumber)) {
        this.get('booking').showErrorMessage('Please enter 10 digit in the Phone number field.');
        valid = false;
      }

      if (this.validateSubscriptionPhoneNumber()) {
        this.get('booking').showErrorMessage('Please enter 10 digit in the Phone number field.');
        valid = false;
      }

      return valid;
    },

    // when the date of the first leg changes . all the date should change
    onFirstLegDateChange() {
      const rTime = this.get('legsInBooking.firstObject.requestTime');
      const firstLegDateAndTime = (0, _moment.default)(rTime);
      const firstLegYear = firstLegDateAndTime.year();
      const firstLegMonth = firstLegDateAndTime.month();
      const firstLegDay = firstLegDateAndTime.date();

      for (let i = 1; i < this.get('legsInBooking').length; i++) {
        const leg = this.get('legsInBooking').objectAt(i);
        const legDateAndTime = (0, _moment.default)(leg.get('requestTime'));
        legDateAndTime.year(firstLegYear);
        legDateAndTime.month(firstLegMonth);
        legDateAndTime.date(firstLegDay);
        const legDateObjectWithTime = legDateAndTime.toDate();
        leg.set('requestTime', legDateObjectWithTime);
      }
    },

    validateLegTimeBasedOnAnchor(leg, isReturn) {
      const bookingFieldType = this.getLegTypeField(isReturn);
      const legsInPanel = this.get(bookingFieldType);
      let currentLeg = legsInPanel.objectAt(0);
      const FORMAT = 'mm-dd-yyyy';

      for (let i = 0; i < legsInPanel.length - 1; i++) {
        const legToCompare = legsInPanel.objectAt(i + 1);
        const currentLegRequestTime = (0, _moment.default)(currentLeg.get('requestTime'));
        const legToCompareRequestTime = (0, _moment.default)(legToCompare.get('requestTime'));

        if (legToCompare.get('anchor').toLowerCase() === PICK) {
          const currentFormattedTime = currentLegRequestTime.format(FORMAT);
          const compareFormattedTime = legToCompareRequestTime.format(FORMAT);

          if (currentFormattedTime !== compareFormattedTime) {
            return false;
          }
        }

        if (legToCompare.get('anchor').toLowerCase() === DROP) {
          const dayAfterTwo = currentLegRequestTime.clone().add(1, 'days');

          if (!currentLegRequestTime.isBetween(currentLegRequestTime, dayAfterTwo)) {
            return false;
          }
        }

        currentLeg = legToCompare;
      }

      return true;
    },

    validateEligibility(leg) {
      const requestTimeOfLeg = leg.get('requestTime');
      const momentedRequestTime = (0, _moment.default)(requestTimeOfLeg);
      const eligibleEndDate = (0, _moment.default)(this.get('riderToBook').get('eligibilityEnd'), 'DD/MM/YYYY').add(1, 'days');
      const eligibleStartDate = (0, _moment.default)(this.get('riderToBook').get('eligibilityStart'), 'DD/MM/YYYY'); // Checking the rider eligibility

      if (!(momentedRequestTime.isSameOrAfter(eligibleStartDate) && momentedRequestTime.isSameOrBefore(eligibleEndDate))) {
        return false;
      }

      return true;
    },

    validateLegRequestTimeAfterPrevLegRequestTime() {
      const legsInBooking = this.get('tripLegsInBooking').toArray();

      if (legsInBooking.length <= 1) {
        return true;
      }

      for (let i = 1; i < legsInBooking.length; i++) {
        const prevLeg = legsInBooking[i - 1];
        const prevlegRequestime = prevLeg.get('requestTime');
        const curLeg = legsInBooking[i];
        const curLegRequestTime = curLeg.get('requestTime'); //Compares each leg requestTime with previous leg requestTime

        if ((0, _moment.default)(curLegRequestTime) <= (0, _moment.default)(prevlegRequestime)) {
          return false;
        }
      }

      return true;
    },

    checkIfLegIsFirstLeg(leg) {
      const legsInBooking = this.get('activeLegsInBooking');
      const indexOfLeg = legsInBooking.indexOf(leg);

      if (indexOfLeg > 0) {
        return false;
      }

      return true;
    },

    combineServiceNeedsForGroupBooking() {
      const serviceNeeds = this.get('booking.selectedRiders').map(rider => rider.serviceNeeds);
      const uniqServiceNeeds = (0, _lodash.uniq)((0, _lodash.flattenDeep)(serviceNeeds));
      this.set('serviceNeeds', uniqServiceNeeds);
    },

    //subscription phone number validation
    validateSubscriptionPhoneNumber() {
      const subscription = this.get('subscription');
      const phoneNumbers = subscription.get('phoneNumbers');
      const maxPhoneNumberLength = 10;
      const filteredPhoneNumbers = phoneNumbers.filter(number => {
        return number && number.get('fullPhoneNumber').length > 0 && number.get('fullPhoneNumber').length < maxPhoneNumberLength;
      });
      return filteredPhoneNumbers.length;
    },

    async validateFundingAgency() {
      const activeLegs = this.get('activeLegsInBooking');
      const subscription = this.get('subscription');
      const subscriptionStartDate = (0, _moment.default)(subscription.get('startDate'));
      const subscriptionEndDate = (0, _moment.default)(subscription.get('endDate'));
      const riderId = this.get('selectedRiders.firstObject.id');
      const localRiderRecord = await this.store.findRecord('rider', riderId);
      const riderFundings = localRiderRecord.get('riderFundings');
      return activeLegs.every(leg => {
        const legFundingAgency = leg.get('fundingAgency');
        const legFundingAgencyName = legFundingAgency.get('name');

        if (Ember.isPresent(legFundingAgencyName)) {
          const legRiderFundings = riderFundings.map(currRiderFunding => {
            if (currRiderFunding.get('fundingAgency.name') === legFundingAgencyName) {
              return currRiderFunding;
            }

            return null;
          }).compact();
          return legRiderFundings.any(legRiderFunding => {
            const startDate = (0, _moment.default)(legRiderFunding.get('startDate')).startOf('day');
            const endDate = (0, _moment.default)(legRiderFunding.get('endDate')).endOf('day');

            if (subscriptionStartDate.isValid() && subscriptionEndDate.isValid() && (!subscriptionStartDate.isBetween(startDate, endDate) || !subscriptionEndDate.isBetween(startDate, endDate))) {
              return false;
            }

            return true;
          });
        }

        return true;
      });
    },

    async confirmAndSaveSubscription() {
      const tooltip = this.get('tooltip');
      const title = this.get('isEditMode') ? 'Edit Subscription' : 'Create Subscription';

      if (await this.validateFundingAgency()) {
        this.saveSubscription();
      } else {
        tooltip.pushConfirmation({
          tip: "The subscription's date range exceeds the selected Funding Agency's date range. ADEPT will continue to assign the selected Funding Agency to subscription trips even if the trip occurs outside the Funding Agency date range. ",
          title: title,
          primaryActionText: 'Ok',
          primaryAction: () => {
            return this.saveSubscription();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
            return;
          }
        });
      }
    },

    async saveSubscription() {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const isEditMode = this.get('isEditMode');
      const subscriptionData = this.get('subscription');
      let subscriptionId = null;

      try {
        if (isEditMode) {
          const exclusion = this.get('subscription.exclusions.firstObject');

          if (Ember.isNone(exclusion.get('startDate'))) {
            exclusion.deleteRecord();
          }

          subscriptionId = subscriptionData.get('id');
          await this.get('subscriptionService').prepareSubscriptionUpdateRawData(subscriptionData);
          const subscriptionFormData = this.createSuspensionRecord(this.get('subscription'));
          const updatedActivityLogData = this.get('subscriptionLogGeneration').subscriptionDataForActivityLog(this.get('subscription'), subscriptionFormData);
          this.set('subscriptionService.updatedActivityLogData', updatedActivityLogData);
        }

        const subscription = await this.get('subscriptionService').saveSubscription(this.get('subscription'), isEditMode, this.get('legTravelNeeds'));

        if (!isEditMode) {
          const subscriptionServiceNeeds = this.get('subscriptionServiceNeeds') ? this.get('subscriptionServiceNeeds').toArray() : [];
          subscriptionId = subscription.data.id;

          if (subscriptionServiceNeeds.length > 0) {
            await this.get('subscriptionService').saveSubscriptionServiceNeeds(subscriptionId, subscriptionServiceNeeds);
          }
        } //subscription phone number save


        this.get('subscriptionService').saveSubscriptionPhoneNumber(subscriptionId, subscriptionData);
        const message = isEditMode ? 'Subscription successfully edited.' : 'Subscription saved.';
        const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
        const subscriptions = await this.get('apiContext').loadSubscriptionsForSelectedExternalRiders(); //roll back address record(NYAAR-18629)

        this.resetModelAttribute();
        this.set('booking.currentBookings', bookings);
        this.set('booking.currentTrips', trips);
        this.set('booking.currentSubscriptions', subscriptions);
        this.get('editModal').close();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('notifications').success(message);
      } catch (err) {
        const message = this.get('isEditMode') ? 'Subscription failed to be edited.' : `Failed to create Subscription  ${err.message}`;
        this.get('notifications').warning(message);
        this.get('editModal').close();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    updateFare() {
      this.get('booking.activeBooking.legs').forEach(async leg => {
        const legTravelNeeds = this.get('legTravelNeeds');
        const rider = this.get('booking.selectedRiders.firstObject');
        const segments = leg.get('segments');
        const fareCategoryName = Ember.isPresent(this.get('subscription.fareCategory.id')) ? this.get('subscription.fareCategory.id').capitalize() : null;
        const fareAmount = await this.get('fareCalc').calcFareByRiderIdAndTravelNeeds(rider, legTravelNeeds, true, fareCategoryName);
        segments.forEach(segment => {
          segment.set('fare', fareAmount);
        });
      });
    },

    setDefaultFare() {
      const fare = this.get('legsInBooking.firstObject.segments.firstObject.fare');
      const regularFare = this.get('booking').getSysConfigRegularFare();
      const guestFare = this.get('booking').getSysConfigCompanionFare();
      const activeRiders = this.get('noOfActiveRiders'); // when REG or Guest fare is 0 return original fare

      if (regularFare === 0 || guestFare === 0) {
        if (activeRiders > 1) {
          this.set('defaultFare', regularFare * activeRiders);
          return;
        }

        this.set('defaultFare', regularFare);
        return;
      }

      const companionCount = this.get('booking').getTravelNeedsCompanionCount(this.get('legTravelNeeds'));
      const defaultFare = fare / (activeRiders + companionCount);

      if (activeRiders > 1) {
        this.set('defaultFare', defaultFare * activeRiders);
      } else {
        this.set('defaultFare', defaultFare);
      }
    },

    resetAddressAndLocation(legSegment, leg) {
      const store = this.get('store');
      const pickPlace = (0, _unwrapProxy.unwrapProxy)(legSegment.get('pickPlace'));
      const dropPlace = (0, _unwrapProxy.unwrapProxy)(legSegment.get('dropPlace'));
      const clonedPickAddress = store.createRecord('address', legSegment.get('pickPlaceAddress'));
      const clonedPickLocation = store.createRecord('location', legSegment.get('pickPlaceLocation'));
      const clonedDropAddress = store.createRecord('address', legSegment.get('dropPlaceAddress'));
      const clonedDropLocation = store.createRecord('location', legSegment.get('dropPlaceLocation'));
      pickPlace.set('address', clonedPickAddress);
      pickPlace.set('location', clonedPickLocation);
      dropPlace.set('address', clonedDropAddress);
      dropPlace.set('location', clonedDropLocation);
      leg.set('segments.firstObject.pick.place', pickPlace);
      leg.set('segments.lastObject.drop.place', dropPlace);
    },

    /**
    * Reset subscription pick and drop phoneNumber while clicking undo button and click on x close button in booking side panel.
    * its working in create Subscription and edit Subscription.
    */
    resetPhoneNumber() {
      let phoneNumbers = this.get('phoneNumbers'); //To check whether the phone number is entered or empty

      if (phoneNumbers.length) {
        const pickPhoneNumber = this.get('phoneNumbers.firstObject.pickPhoneNumber');
        const dropPhoneNumber = this.get('phoneNumbers.firstObject.dropPhoneNumber');
        phoneNumbers = this.createSubscriptionPhoneNumRecord(pickPhoneNumber, dropPhoneNumber);
      }

      this.set('subscription.phoneNumbers', phoneNumbers);
    },

    resetModelAttribute() {
      this.get('booking.activeBooking.legs').forEach(leg => {
        if (!leg) {
          return;
        }

        const pickAddressId = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick.place.address.id'));
        const dropAddressId = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.drop.place.address.id'));

        if (pickAddressId) {
          const pickAddressRecord = this.get('store').peekRecord('address', pickAddressId);
          pickAddressRecord.rollbackAttributes();
        }

        if (dropAddressId) {
          const dropAddressRecord = this.get('store').peekRecord('address', dropAddressId);
          dropAddressRecord.rollbackAttributes();
        }
      });
    },

    /**
     * Reset phoneNumber and Notes while clicking undo button and click on x close button in booking side panel
     * its working in create Subscription and edit Subscription.
     */
    resetPassengerListPhoneNumberAndNotes(defaultBookingData) {
      const notes = defaultBookingData.get('notes');
      const rider = defaultBookingData.get('rider');
      const riderPhoneNumber = defaultBookingData.get('riderPhoneNumber');
      rider.forEach(riderData => {
        riderData.set('notes', notes);
        riderData.set('formattedPhoneNumber', riderPhoneNumber);
      });
    },

    resetCompanionCountFields(defaultBookingData) {
      const legTravelNeeds = defaultBookingData.get('legTravelNeeds').toArray();
      this.set('legTravelNeeds', legTravelNeeds);
      const newLegTravelNeed = defaultBookingData.get('travelNeeds');
      const companionCount = this.get('booking').getTravelNeedsCompanionCount(newLegTravelNeed);
      const companions = this.get('previousCompanionCount') || [];
      legTravelNeeds.forEach(travelNeed => {
        if (this.booking.get('isCompanionTravelNeed')(travelNeed)) {
          const previousCompanion = companions.find(companion => {
            return companion.travelNeedTypeNameUppercase === travelNeed.get('travelNeedTypeNameUppercase');
          });
          const previousCompanionCount = Ember.isPresent(previousCompanion) ? previousCompanion.count : 0;
          travelNeed.set('count', previousCompanionCount);
        }
      });
      this.get('booking').setCompanionCount(companionCount);
    },

    resetFieldStates() {
      if (this.get('travelNeedCollapseState') === 'expanded') this.set('travelNeedCollapseState', 'collapsed');
      if (this.get('passengerCollapseState') === 'expanded') this.set('passengerCollapseState', 'collapsed');
      if (this.get('serviceNeedCollapseState') === 'expanded') this.set('serviceNeedCollapseState', 'collapsed');
    },

    resetSuspensionDaysFields(defaultBookingData) {
      const subscription = defaultBookingData.get('subscription');
      const previousSubscriptionDays = this.get('subscriptionService.previousSubscriptionDays');
      this.set('subscription.startDate', this.get('booking.previousSubscriptionStartDate'));
      this.set('subscription.endDate', this.get('booking.previousSubscriptionEndDate'));
      this.set('subscription.exclusions.firstObject.startDate', this.get('booking.previousSubscriptionSuspendStartDate'));
      this.set('subscription.exclusions.firstObject.endDate', this.get('booking.previousSubscriptionSuspendEndDate'));
      this.set('subscription.exclusions.firstObject.type', subscription.get('type'));
      this.set('subscription.status', subscription.get('status'));
      this.set('subscription.exclusions.firstObject.suspend', subscription.get('isSuspended'));
      this.set('subscription.noSharing', subscription.get('noSharing'));
      this.set('subscription.schedulingMode', this.get('subscriptionService.previousSchedulingMode'));
      this.set('subscription.originPlannedEta', this.get('subscriptionService.previousOriginPlannedEta'));
      this.set('subscription.destinationPlannedEta', this.get('subscriptionService.previousDestinationPlannedEta'));
      this.set('subscription.routeTemplate', this.get('subscriptionService.previousRouteTemplate'));
      this.set('subscriptionService.pickTime');
      this.set('subscriptionService.dropTime');
      this.set('subscriptionService.pickTimeMoment');
      this.set('subscriptionService.dropTimeMoment');
      SUSPENSIONDAYS.forEach(day => {
        const subscriptionDay = `subscription.latestRecurrence.${day}`;

        if (Ember.isPresent(this.get('subscription.latestRecurrence')) && Ember.isPresent(previousSubscriptionDays)) {
          this.set(subscriptionDay, previousSubscriptionDays[day]);
        }
      });
    },

    /**
     *NYAAR-19273: Creating a record in subscription-phone-number model for pick and drop phone number
     * @param {*} pickPhoneNumber
     * @param {*} dropPhoneNumber
     * @returns subscription phone number model
     */
    createSubscriptionPhoneNumRecord(pickPhoneNumber, dropPhoneNumber) {
      const pickPhoneNumberRecord = this.get('store').createRecord('subscription-phone-number', pickPhoneNumber);
      const dropPhoneNumberRecord = this.get('store').createRecord('subscription-phone-number', dropPhoneNumber);
      return [pickPhoneNumberRecord, dropPhoneNumberRecord];
    },

    resetFieldValues() {
      const defaultBookingData = this.get('defaultBookingData');
      const loadTime = defaultBookingData.get('loadTime');
      const unLoadTime = defaultBookingData.get('unLoadTime');
      const legs = defaultBookingData.get('legs').toArray();
      const legSegments = defaultBookingData.get('legSegments').toArray();
      const legSegment = legSegments[0];
      const leg = legs[0];
      const legTravelNeeds = defaultBookingData.get('travelNeeds').toArray();
      this.set('legTravelNeeds', legTravelNeeds);
      leg.set('segments.lastObject.drop.notes', legSegment.get('dropNotes'));
      leg.set('segments.firstObject.pick.notes', legSegment.get('pickNotes'));
      leg.set('segments.firstObject.fare', legSegment.get('fare'));
      leg.set('serviceWindow', legSegment.get('serviceWindow'));
      leg.set('fundingAgency', legSegment.get('fundingAgency'));
      leg.set('anchor', legSegment.get('anchor'));
      leg.set('requestTime', legSegment.get('requestTime'));
      leg.set('segments.firstObject.pick.dwell', loadTime);
      leg.set('segments.firstObject.drop.dwell', unLoadTime);
      this.set('pickAddressText', '');
      this.set('dropAddressText', '');
      this.resetAddressAndLocation(legSegment, leg);
      this.resetSuspensionDaysFields(defaultBookingData);
      this.resetModelAttribute();
      this.resetPassengerListPhoneNumberAndNotes(defaultBookingData);
      this.resetPhoneNumber();
      this.resetFieldStates();
      this.set('booking.activeBooking.legs', legs);
    },

    setDistances(coord) {
      if (this.get('distancePt1') === null) {
        this.set('distancePt1', this.markerPinPoint(coord));
      } else if (this.get('distancePt2') === null) {
        this.set('distancePt2', this.markerPinPoint(coord));
        const map = this.get('booking.bookingMap');
        let distance = (0, _geolib.getDistance)(this.get('distancePt1.native._latlng'), coord); // eslint-disable-line
        //convert from metre -> feet -> mile

        distance = distance * 3.281 / 5280;
        this.set('computedDistance', `${distance.toFixed(3)} mi`);
        const polyLineOptions = _polyline.default['dottedDistance']; // eslint-disable-line

        const distancePoints = [{
          lat: this.get('distancePt1.native._latlng.lat'),
          lon: this.get('distancePt1.native._latlng.lng')
        }, {
          lat: this.get('distancePt2.native._latlng.lat'),
          lon: this.get('distancePt2.native._latlng.lng')
        }];
        const distancePolyline = new bemap.Polyline(distancePoints, {
          style: polyLineOptions
        });
        map.addPolyline(distancePolyline);
        this.set('distancePolyline', distancePolyline);
      } else {
        this.distanceReset();
      }
    },

    distanceReset() {
      const map = this.get('booking.bookingMap');
      const distancePt1 = this.get('distancePt1');
      const distancePt2 = this.get('distancePt2');
      const distancePolyline = this.get('distancePolyline');

      if (distancePt1) {
        map.removeMarker(distancePt1);
      }

      if (distancePt2) {
        map.removeMarker(distancePt2);
      }

      if (distancePolyline) {
        map.removePolyline(distancePolyline);
      }

      this.setProperties({
        distancePt1: null,
        distancePt2: null,
        computedDistance: null,
        distancePolyline: null
      });
    },

    markerPinPoint(coord) {
      const map = this.get('booking.bookingMap');
      if (!coord) return;
      const bemapCoords = new bemap.Coordinate(coord.lng, coord.lat);
      const pintPointIcon = {
        icon: {
          src: '/map-icons/icon-pinpoint.svg',
          anchorX: 0.50,
          anchorY: 1,
          height: 23,
          width: 23,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: 1
        }
      };
      const bemapIcon = new bemap.Icon(pintPointIcon.icon);
      const marker = new bemap.Marker(bemapCoords, {
        icon: bemapIcon
      });
      map.addMarker(marker);
      return marker;
    },

    setupManualSubscription() {
      const subscription = this.get('subscription');
      const isManualSchedulingMode = subscription.get('isManualSchedulingMode');
      const originPlannedEta = subscription.get('originPlannedEta');
      const destinationPlannedEta = subscription.get('destinationPlannedEta');

      if (isManualSchedulingMode && Ember.isPresent(originPlannedEta) && Ember.isPresent(destinationPlannedEta)) {
        this.set('subscriptionService.pickTimeMoment', (0, _moment.default)(originPlannedEta));
        this.set('subscriptionService.dropTimeMoment', (0, _moment.default)(destinationPlannedEta));
        this.set('subscriptionService.pickTime', this.get('subscriptionService.pickTimeMoment').format('HH:mm'));
        this.set('subscriptionService.dropTime', this.get('subscriptionService.dropTimeMoment').format('HH:mm'));
      }
    },

    actions: {
      onUndoClick() {
        this.resetFieldValues();
        this.setDefaultBookingData();
        this.setupManualSubscription();
      },

      onSaveClick() {
        this.set('canSave', true);

        if (this.validateRecords()) {
          if (this.get('isEditMode') && this.get('hasFutureTrips')) {
            const timelineDate = (0, _moment.default)().startOf('day').add(this.get('futureBookingTimeline'), 'days');
            const month = timelineDate.format('MMMM');
            const day = timelineDate.format('D');
            this.set('futureTimelineDate', `${month} ${day}`);
            this.toggleProperty('futureTripsWarning');
          } else {
            this.confirmAndSaveSubscription();
          }
        }
      },

      onFutureTripsModalClose() {
        this.toggleProperty('futureTripsWarning');
      },

      onFutureTripsModalConfirm() {
        this.toggleProperty('futureTripsWarning');
        this.confirmAndSaveSubscription();
      },

      onXButtonClick() {
        this.toggleProperty('isProgressShown');
      },

      onProgressNoClick() {
        this.resetFieldValues();
        this.toggleProperty('isProgressShown');
        this.get('editModal').close();
      },

      onCloseClick() {
        this.toggleProperty('isProgressShown');
      },

      onApplyClick() {
        if (this.validateRecords()) {
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          this.get('geocode').activateGeocode(false);
          this.set('addressRecord', null);
          this.get('service').apply();
        }
      },

      validateRequestDateTime(leg) {
        this.set('canSave', true);
        this.set('errorMessage', '');

        if (!this.validateEligibility(leg)) {
          this.set('selectedLegDate', (0, _moment.default)(leg.get('requestTime')).format('M/D/Y'));
          this.get('booking').showErrorMessage(`${this.get('riderToBook.firstName')} ${this.get('riderToBook.lastName')}
        has no client eligibility records for the date \n  ${this.get('selectedLegDate')} . Please select another date.`);
          return;
        }

        if (!this.validateLegRequestTimeAfterPrevLegRequestTime()) {
          this.get('booking').showErrorMessage('The leg trip time must come after the previous trip request time.');
          return;
        }
      },

      async onYesButtonClick() {
        if (this.validateRecords()) {
          this.confirmAndSaveSubscription();
        }
      },

      onWarningModalClose() {
        this.set('isOpenErrorModal', false);
        this.set('disablePhoneNumber', false);
      },

      setLoadAndUnLoadTimeFlag(isLoadAndUnLoadTimeValid) {
        this.set('isLoadAndUnLoadTimeValid', isLoadAndUnLoadTimeValid);
      },

      setIsLoadAndUnLoadTimeNumberFlag(isLoadAndUnLoadTimeNumber) {
        this.set('isLoadAndUnLoadTimeNumber', isLoadAndUnLoadTimeNumber);
      },

      onDistanceButtonClick() {
        this._bodyClickFunction = event => {
          this.actions.onBodyClickFunction(event, this);
        };

        document.body.addEventListener('click', this._bodyClickFunction);
        this.set('isComputingDistance', true);
        this.get('booking.bookingMap').native.on('click', e => {
          this.setDistances(e.latlng);
        });
        Ember.$('.tomtom-map').addClass('pinpoint-cursor');
      },

      onBodyClickFunction(e, thisObject) {
        const node = e.target;
        let isInPage = true;

        if (document.getElementsByClassName('map-widget')[0]) {
          isInPage = document.getElementsByClassName('map-widget')[0].contains(node);
        }

        if (isInPage === false) {
          thisObject.distanceReset();
        }
      },

      onDistanceClose() {
        document.body.removeEventListener('click', this._bodyClickFunction);
        this.set('isComputingDistance', false);
        this.distanceReset();
        this.get('booking.bookingMap.native').off('click');
        Ember.$('.tomtom-map').removeClass('pinpoint-cursor');
      },

      onDistanceReset() {
        this.distanceReset();
      }

    }
  });

  _exports.default = _default;
});