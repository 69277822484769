define("adept-iq/pods/components/rider-management-widget/rider-activity-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/jhy1YRT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-md-12 p-0\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row m-0 mb-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"activity-table\"],[9],[0,\"\\n        \"],[1,[27,\"rider-management-widget/rider-activity-log/activity-log-table\",null,[[\"tableRef\",\"OnTableRowClick\",\"riderId\"],[[23,[\"tableRef\"]],[23,[\"OnTableRowClick\"]],[23,[\"riderId\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/rider-management-widget/rider-activity-log/template.hbs"
    }
  });

  _exports.default = _default;
});