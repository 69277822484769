define("adept-iq/pods/components/side-drawer/permissions-edit-user/component", ["exports", "ember-concurrency", "adept-iq/mixins/password-validation", "adept-iq/mixins/role-provider-validation", "lodash"], function (_exports, _emberConcurrency, _passwordValidation, _roleProviderValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_passwordValidation.default, _roleProviderValidation.default, {
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    errorMessages: null,
    userDetails: null,
    selectedRoles: null,
    title: '',
    visiblePassword: false,
    isFormUpdated: false,
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    currentUser: Ember.computed.readOnly('stackFrame.options.user'),
    hasOneProvider: Ember.computed.lte('providersList.length', 1),
    canUndo: Ember.computed.alias('isFormUpdated'),
    canSave: Ember.computed.and('isFormUpdated', 'isPasswordValidOrUnchanged', 'hasOneSelectedRoles', 'hasOneSelectedProviders'),
    userRecords: Ember.computed.readOnly('stackFrame.options.userRecords'),
    providersTitle: Ember.computed('providersList.@each.isChecked', function () {
      const providers = this.get('providersList') || [];
      const checkedProviders = providers.filter(p => p.isChecked);

      if (checkedProviders.length <= 0) {
        return 'Select Providers';
      } else if (checkedProviders.length === 1) {
        return checkedProviders[0].providerName;
      } else if (checkedProviders.length === providers.length) {
        return 'All Providers';
      }

      return 'Multiple';
    }),
    // isSaveEnabled: computed.and('userDetails.{firstName,lastName,username,password}', 'selectedStatus', 'selectedRoles'),
    // canSave: computed('isSaveEnabled', 'title', 'isEditingStart', function() {
    //   return get(this, 'isSaveEnabled') && get(this, 'title') !== 'Select Providers' && get(this, 'isEditingStart');
    // }),
    statusOptions: Ember.computed('selectedStatus', function () {
      return ['Active', 'Inactive'];
    }),
    roleOptions: Ember.computed('selectedRoles', function () {
      const roles = [];
      const rolesList = Ember.get(this, 'store').peekAll('perm-sso-role');
      rolesList.map(role => {
        if (role.id) {
          roles.push(role);
        }
      });
      return roles;
    }),

    init() {
      this._super(...arguments);

      this.resetUserProperties();
      this.setSideDrawerWidth('490px');
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('systemConfig').set('sideDrawerPreventCollapse', true);
    },

    didDestroyElement() {
      this.get('systemConfig').set('sideDrawerPreventCollapse', null);
    },

    async resetUserProperties() {
      this.setProperties({
        userDetails: {
          id: this.currentUser.get('id'),
          firstName: this.currentUser.get('firstName'),
          lastName: this.currentUser.get('lastName'),
          emailId: this.currentUser.get('emailId'),
          userName: this.currentUser.get('userName'),
          password: this.currentUser.get('password')
        },
        errorMessages: [],
        isFormUpdated: false,
        selectedRoles: this.getDefaultRoles(),
        selectedStatus: Ember.String.capitalize(this.currentUser.calculatedStatus),
        visiblePassword: false
      });
      await this.fillProviders();
      this.set('userInitialProviders', _lodash.default.cloneDeep(this.get('providersList')));
    },

    getDefaultRoles() {
      const rolesList = this.currentUser.roles;
      const roleOptions = this.get('roleOptions');
      if (Ember.isEmpty(rolesList)) return null;
      return roleOptions.filter(option => rolesList.some(role => role.roleName === option.id));
    },

    async fillProviders() {
      const providers = [];
      const adaptor = 'perm-sso-user';
      const permUserAdapter = this.get('store').adapterFor(adaptor);
      const providersList = await permUserAdapter.getSSOProvider();
      if (Ember.isEmpty(providersList)) return;
      const userProviders = this.currentUser.providers;
      const userProvidersDict = userProviders.reduce((dict, p) => {
        dict[p.id] = true;
        return dict;
      }, {});
      providersList.map(provider => {
        providers.push(Ember.Object.create({
          id: provider.id,
          displayName: provider.provider === 'ALL' ? 'Select All' : provider.provider,
          providerName: provider.provider,
          isChecked: userProvidersDict[provider.provider] || userProvidersDict.ALL || false
        }));
      });
      this.set('providersList', providers);
    },

    compareArray(_arr1, _arr2) {
      if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
        return false;
      } // .concat() to not mutate arguments


      const arr1 = _arr1.concat().sort();

      const arr2 = _arr2.concat().sort();

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }

      return true;
    },

    isValid() {
      const nameAlphanumericRegex = /^[a-z\d]+$/i;
      const usernameAlphanumericRegex = /^[a-z\d_.@]+$/i;
      let isValid = true;
      Ember.set(this, 'errorMessages', []); // Open once allowed to edit all this
      // if (isEmpty(this.userDetails.userName)) {
      //   this.errorMessages.push('Please enter user name');
      //   isValid = false;
      // }
      // if (isEmpty(this.userDetails.password)) {
      //   this.errorMessages.push('Please enter password');
      //   isValid = false;
      // }
      // if (isEmpty(this.title) || this.title === 'Select Providers') {
      //   this.errorMessages.push('Please enter providers');
      //   isValid = false;
      // }
      // if (isEmpty(this.selectedRoles)) {
      //   this.errorMessages.push('Please enter roles');
      //   isValid = false;
      // }

      if (Ember.isEmpty(this.selectedStatus)) {
        this.errorMessages.push('Please enter status');
        isValid = false;
      }

      if (!Ember.isEmpty(this.userDetails.emailId)) {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (!emailRegex.test(this.userDetails.emailId)) {
          this.errorMessages.push('Please enter valid email id');
          isValid = false;
        }
      } else if (this.userDetails && this.get('userRecords')) {
        if (this.get('userRecords').filter(data => data && data.id !== this.userDetails.id).some(data => data && data.userName === this.userDetails.userName)) {
          this.errorMessages.push('* Duplicated username');
          isValid = false;
        }
      }

      if (Ember.isEmpty(this.userDetails.firstName) || Ember.isEmpty(this.userDetails.lastName) || !nameAlphanumericRegex.test(this.userDetails.firstName) || !nameAlphanumericRegex.test(this.userDetails.lastName) || this.userDetails.firstName.length > 32 || this.userDetails.lastName.length > 32) {
        isValid = false;
        this.errorMessages.push('* Firstname and lastname can only have alphanumeric characters i.e. (a-z, A-Z, 0-9)  and max length up to 32 characters and can not be empty');
        return;
      }

      if (Ember.isEmpty(this.userDetails.userName) || !usernameAlphanumericRegex.test(this.userDetails.userName) || this.userDetails.userName.length > 50) {
        isValid = false;
        this.errorMessages.push('* Username can only have alphanumeric characters i.e. (a-z, A-Z, 0-9), underscore (_), dot(.), at sign(@) and max length up to 50 characters and can not be empty.');
        return;
      } //check if any change has been made to the user details


      const currentUserRoleIds = this.getRoleIdsByRoleList(this.currentUser.roles);
      const roleIds = this.getSelectedRoleIds() || [];
      const sameRoles = this.compareArray(currentUserRoleIds, roleIds);
      const userDetailsPassword = this.userDetails.password || '';
      const currentUserPassword = this.currentUser.password || '';
      const formHasUpdated = this.userDetails.firstName !== this.currentUser.firstName || this.userDetails.lastName !== this.currentUser.lastName || this.userDetails.userName !== this.currentUser.userName || userDetailsPassword !== currentUserPassword || this.selectedStatus !== this.currentUser.calculatedStatus || !_lodash.default.isEqual(this.get('providersList'), this.userInitialProviders) || !sameRoles;

      if (!formHasUpdated) {
        isValid = false;
        this.errorMessages.push('No changes have been made to the user details!');
        return;
      }

      return isValid;
    },

    saveUser() {
      return Ember.get(this, 'editUserTask').perform().then(() => {
        if (this.callBack) {
          this.callBack(true);
        }

        this.get('notifications').success('Edited user Successfully');
        this.get('workspace').popState('permissionsEditUser');
      }).catch(error => {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          case 404:
            message = 'There was a problem in edit user, requested user not exists';
            break;

          default:
            message = 'There was a problem in edit user, please try again.';
            break;
        }

        this.errorMessages.push(message);
        this.get('notifications').warning(message);
      });
    },

    getSelectedProviders() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return [];
      const results = this.get('providersList').filterBy('isChecked', true);
      return results.map(p => {
        if (p.id !== 'selectAll') {
          return p.id;
        }
      });
    },

    handleCheckUncheckSelectAll() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;
      let results = providersList.filterBy('isChecked', false);

      if (results.length === 1 && results[0].providerName === 'ALL') {
        results[0].set('isChecked', true);
      } else {
        results = providersList.filterBy('providerName', 'ALL');

        if (results.length > 0) {
          results[0].set('isChecked', false);
        }
      }
    },

    handleCheckUncheckProviders(provider, isChecked) {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;

      if (provider.providerName === 'ALL') {
        providersList.map(item => {
          item.set('isChecked', isChecked);
        });
      } else {
        providersList.map(item => {
          if (provider.id === item.id) {
            item.set('isChecked', isChecked);
          }
        });
        this.handleCheckUncheckSelectAll();
      }
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    getSelectedRoleIds() {
      if (Ember.isEmpty(this.selectedRoles)) return;
      return this.selectedRoles.map(role => role.roleName);
    },

    getRoleIdsByRoleList(roleList) {
      return roleList.map(role => role.roleName);
    },

    getSelectedStatus() {
      return this.selectedStatus ? this.selectedStatus.toLowerCase() : this.currentUser.calculatedStatus.toLowerCase();
    },

    editUserTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const userAdapter = store.adapterFor('perm-sso-user');
        const recordedRoles = Ember.get(this, 'store').peekAll('perm-sso-role').map(role => role.id);

        if (this.userDetails.firstName !== this.currentUser.firstName || this.userDetails.lastName !== this.currentUser.lastName) {
          const payload = {
            firstName: this.userDetails.firstName,
            lastName: this.userDetails.lastName // username: this.userDetails.userName,
            // password: this.userDetails.password,
            // providers: [0]

          };
          yield userAdapter.patchUser(payload, this.currentUser.userId);
        } // edit user username and password


        if (this.userDetails.userName !== this.currentUser.userName || this.userDetails.password !== this.currentUser.password) {
          const payload = {
            username: this.userDetails.userName,
            password: this.userDetails.password
          };
          yield userAdapter.updateUsernameAndPassword(payload, this.currentUser.userId);
        } // edit user providers


        const providersList = this.get('providersList');
        const isAllProviders = providersList.some(provider => provider.providerName === 'ALL' && provider.isChecked);

        if (!Ember.isEmpty(providersList)) {
          for (let i = 0; i < providersList.length; i++) {
            if (isAllProviders) {
              if (providersList[i].providerName === 'ALL') {
                yield userAdapter.updateProviders(this.currentUser.userId, providersList[i].id);
              } else {
                yield userAdapter.deleteProviders(this.currentUser.userId, providersList[i].id);
              }
            } else if (providersList[i].isChecked === true) {
              yield userAdapter.updateProviders(this.currentUser.userId, providersList[i].id);
            } else {
              yield userAdapter.deleteProviders(this.currentUser.userId, providersList[i].id);
            }
          }
        }

        if (this.selectedStatus && this.selectedStatus.toLowerCase() !== this.currentUser.calculatedStatus) {
          //edit user status
          yield userAdapter.updateStatusByUser(this.currentUser.userId, this.getSelectedStatus()); //end
        } // edit user roles


        const currentUserRoles = yield userAdapter.getRolesByUser(this.currentUser.userId);
        const currentUserRoleIds = this.getRoleIdsByRoleList(currentUserRoles.UserRoleRoles);
        if (Ember.isEmpty(currentUserRoles)) return;
        const roleIds = this.getSelectedRoleIds();
        const differenceInRoles = this.compareArray(currentUserRoleIds, roleIds);

        if (!differenceInRoles) {
          const removeRoles = _lodash.default.difference(currentUserRoleIds, roleIds) || [];
          const addRoles = _lodash.default.difference(roleIds, currentUserRoleIds) || [];

          if (!Ember.isEmpty(removeRoles)) {
            for (let i = 0; i < removeRoles.length; i++) {
              yield userAdapter.deleteRolesByUser(this.currentUser.userId, removeRoles[i]);
            }
          }

          if (!Ember.isEmpty(addRoles)) {
            const invalidSelectedRoles = !addRoles.every(addRole => {
              const role = recordedRoles.find(recordedRole => recordedRole === addRole);
              return !!role;
            });
            if (invalidSelectedRoles) throw Error('Invalid roles provided');

            for (let i = 0; i < addRoles.length; i++) {
              yield userAdapter.updateRolesByUser(this.currentUser.userId, addRoles[i]);
            }
          } //end

        }
      } catch (error) {
        throw error;
      }
    }),

    filterTextInput(event) {
      const alphanumeric = /[A-Za-z0-9]/;
      var keyChar = String.fromCharCode(event.which || event.keyCode);
      return alphanumeric.test(keyChar);
    },

    filterUserNameInput(event) {
      const alphanumeric = /[A-Za-z0-9_.@]/;
      var keyChar = String.fromCharCode(event.which || event.keyCode);
      return alphanumeric.test(keyChar);
    },

    showToolTip() {
      const tooltip = this.get('tooltip');
      const tip = 'Do you want to edit the user?';
      tooltip.pushConfirmation({
        title: 'Edit User',
        tip,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return Ember.get(this, 'editUserTask').perform().then(() => {
            if (this.callBack) {
              this.callBack(true);
            }

            this.get('notifications').success('Edited user Successfully');
            this.get('workspace').popState('permissionsEditUser');
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              case 404:
                {
                  const pattern = /(Key \(roleId\)=\()(.*)(\))( is not present in table)/g;

                  if (pattern.test(error.message)) {
                    message = 'SELECTED ROLE IS NO LONGER AVAILABLE, PLEASE SELECT ANOTHER ROLE AND TRY AGAIN';
                  } else {
                    message = 'There was a problem in edit user, requested user not exists';
                  }

                  break;
                }

              default:
                message = error.message.startsWith('Invalid roles provided') ? 'Selected role is no longer available, please select another role and try again' : 'There was a problem in edit user, please try again.';
                break;
            }

            this.errorMessages.push(message);
            this.get('notifications').warning(message);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    formOnChange() {
      const currentUserRoleIds = this.getRoleIdsByRoleList(this.currentUser.roles);
      const roleIds = this.getSelectedRoleIds() || [];
      const sameRoles = this.compareArray(currentUserRoleIds, roleIds);
      const userDetailsPassword = this.userDetails.password || '';
      const currentUserPassword = this.currentUser.password || '';
      const isFormUpdated = this.userDetails.firstName !== this.currentUser.firstName || this.userDetails.lastName !== this.currentUser.lastName || this.userDetails.userName !== this.currentUser.userName || userDetailsPassword !== currentUserPassword || this.selectedStatus !== this.currentUser.calculatedStatus || !_lodash.default.isEqual(this.get('providersList'), this.userInitialProviders) || !sameRoles;
      this.set('isFormUpdated', isFormUpdated);
    },

    actions: {
      onChangeStatus(option) {
        Ember.set(this, 'selectedStatus', option);
        this.formOnChange();
      },

      onChangeRole(option) {
        Ember.set(this, 'selectedRoles', option);
        this.formOnChange();
      },

      onUndoClick() {
        this.resetUserProperties();
      },

      onInputChange(option, value) {
        switch (option) {
          case 'firstName':
            Ember.set(this, 'userDetails.firstName', value);
            break;

          case 'lastName':
            Ember.set(this, 'userDetails.lastName', value);
            break;

          case 'emailId':
            Ember.set(this, 'userDetails.emailId', value);
            break;

          case 'userName':
            Ember.set(this, 'userDetails.userName', value);
            break;

          case 'password':
            Ember.set(this, 'userDetails.password', value);
            break;

          default:
            break;
        }

        this.formOnChange();
      },

      onSaveClick() {
        if (this.isValid()) {
          Ember.set(this, 'errorMessages', []);
          this.showToolTip();
        }
      },

      onCloseClick() {},

      onToggleSelect() {
        this.toggleSelect();
      },

      onOpenProvider() {
        this.toggleSelect();
      },

      onCloseProvider() {
        this.toggleSelect();
      },

      onProviderCheckboxClick(provider, event) {
        this.handleCheckUncheckProviders(provider, event.target.checked);
        this.formOnChange();
      },

      cancel() {
        if (this.callBack) {
          this.callBack(false);
        }
      },

      onToggleVisiblePasswordClick() {
        const visiblePassword = this.get('visiblePassword');
        this.set('visiblePassword', !visiblePassword);
      }

    }
  });

  _exports.default = _default;
});