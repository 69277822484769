define("adept-iq/pods/components/iq-widgets/routes-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/routes-widget/scheduleConfig", "adept-iq/utils/find-row-record", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds", "adept-iq/mixins/version-check", "adept-iq/mixins/async-schedule-operation", "adept-iq/config/api-urls"], function (_exports, _component, _scheduleConfig, _findRowRecord, _unwrapProxy, _mappedPermIds, _versionCheck, _asyncScheduleOperation, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-console */
  var _default = _component.default.extend(_asyncScheduleOperation.default, _versionCheck.default, {
    classNames: ['routes-widget', 'data-test-routes-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    providersList: Ember.computed.alias('workspaceContext._selectedProviders'),
    disableRightClick: true,
    config: _scheduleConfig.default,
    providersDict: Ember.computed('providersList', function () {
      const ProvidersList = this.get('providersList');
      const dict = {};
      ProvidersList.forEach(provider => {
        dict[provider.get('id')] = provider.get('name');
      });
      return dict;
    }),

    init() {
      this.set('config', _scheduleConfig.default);

      this._super(...arguments);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'refreshData');
    },

    didInsertElement() {
      this._super(...arguments);

      this._initScheduleSingleActions();
    },

    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),

    /* actions defined on right-click on rows*/

    /*dynamicSingleActions: computed('table.rows.@each.{selected,status}', 'selectedWidgetRecord', function() {
      return this._dynamicScheduleActions();
    }),*/
    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'routes');
      event.dataTransfer.setData('text', dragId);
    },

    dragEnd() {
      this.get('dragCoordinator').clearSourceRows();
    },

    _initScheduleSingleActions() {
      this.set('tableActions', [{
        id: 'print',
        permIds: [_mappedPermIds.default.downloadRoutes],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);
      this.set('activityLog', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogRoute,
        name: 'Activity Log',
        action: model => {
          const routeName = model.get('route.name');
          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeName} Activity Log`,
            route: (0, _unwrapProxy.unwrapProxy)(model.get('route'))
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
    },

    _dynamicScheduleActions() {
      const activityLog = this.get('activityLog');
      const row = this.getSelectedCoreModel();
      if (!row) return []; //const selectedWidgetRecord = this.get('selectedWidgetRecord');

      const genericActions = [activityLog];
      return genericActions;
    },

    // ember-react-table overwrites
    applyTransformationData(widgetRecords) {
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyVehicleInfoTransformation(widgetRecord);
          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    applyVehicleInfoTransformation(widgetRecord) {
      const vehicleId = widgetRecord.vehicleId;
      const vehicleRecord = this.store.peekRecord('vehicle', vehicleId);

      if (vehicleRecord) {
        widgetRecord.startGarage = vehicleRecord.get('startGarageDisplayName');
        widgetRecord.endGarage = vehicleRecord.get('endGarageDisplayName');
        const vehicleType = vehicleRecord.get('vehicleType');

        if (vehicleType) {
          widgetRecord.vehicleTypeDisplayName = vehicleType.get('displayName'); // ToolTip VehicleType - Extract and format vehicle capacity information for each vehicleType

          const vehicleCapacityConfigs = vehicleType.get('vehicleCapacityConfigs');

          if (vehicleCapacityConfigs) {
            const groupedConfigs = vehicleCapacityConfigs.reduce((acc, config) => {
              const group = config.group.toString();

              if (!acc[group]) {
                acc[group] = [];
              }

              acc[group].push(config);
              return acc;
            }, {}); // Sort group keys numerically

            const sortedGroups = Object.keys(groupedConfigs).sort((a, b) => Number(a) - Number(b));

            if (sortedGroups.length > 0) {
              const firstGroup = groupedConfigs[sortedGroups[0]];
              const capacityInfo = firstGroup.map(config => `${config.count} ${config.vehicleCapacityTypeDisplayName}`).join(', ');
              widgetRecord.vehicleCapacity = capacityInfo;
            }
          }
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      const rowStyleHandler = '';
      return rowStyleHandler;
    },

    actions: {
      async generateScheduleManifest() {
        try {
          const currentSchedule = this.get('workspace.currentSchedule');
          const session = this.get('session');
          const token = session.data.authenticated.token;

          if (currentSchedule && currentSchedule.status === 'scheduled') {
            const scheduleId = currentSchedule.id;
            const url = `${_apiUrls.API.schedulingService.host}/schedule-manifest/${scheduleId}`;
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            });
            return response.ok;
          }
        } catch (error) {
          console.error('Fetch error:', error);
          return false;
        }
      }

    }
  });

  _exports.default = _default;
});