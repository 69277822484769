define("adept-iq/pods/components/side-drawer/add-widget/component", ["exports", "adept-iq/config/widget-groups", "adept-iq/utils/widget-placement", "lodash", "adept-iq/models/dashboard", "adept-iq/config/widget-types"], function (_exports, _widgetGroups, _widgetPlacement, _lodash, _dashboard, _widgetTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    tagName: '',
    title: 'Add Widgets',
    componentName: 'configureWorkspace',
    widgetSections: null,
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),

    init() {
      this._super(...arguments);

      this.configureWidgetsOnPermission();
    },

    configureWidgetsOnPermission() {
      this.set('widgetSections', _lodash.default.cloneDeep(_widgetGroups.default));
      let widgetSections = this.get('widgetSections') || [];
      const configPermissions = this.get('workspace.configPermissions');
      const userPermHash = this.get('permissionLayer.userPermissionHash');
      widgetSections.forEach(widgetSection => {
        let widgetTypesAllowed = [];

        if (widgetSection.widgetTypes) {
          widgetSection.widgetTypes.forEach(widgetType => {
            const configPermission = configPermissions[widgetType.id];
            const userPerm = widgetType.permId;
            const role = this.get('workspace.dashboardModel.role');

            if (!Ember.isNone(configPermission) && configPermission.show && userPermHash[userPerm] && !this.isScheduleRestrictWidget(widgetType)) {
              widgetTypesAllowed.push(widgetType);
            } // remove Schedule Metrics widget other than Scheduling workspace.


            if (role !== _dashboard.SCHEDULE_ROLENAME) {
              widgetTypesAllowed = _lodash.default.reject(widgetTypesAllowed, widget => widget.id === _widgetTypes.scheduleWidgetType.id);
            } //keep only user/role widget in Permission workspace.


            if (role === _dashboard.PERMISSIONS_ROLENAME) {
              widgetTypesAllowed = _lodash.default.reject(widgetTypesAllowed, widget => widget.id !== _widgetTypes.permUsersWidgetType.id && widget.id !== _widgetTypes.permRolesWidgetType.id);
            } else {
              widgetTypesAllowed = _lodash.default.reject(widgetTypesAllowed, widget => widget.id === _widgetTypes.permUsersWidgetType.id || widget.id === _widgetTypes.permRolesWidgetType.id);
            }
          });
        }

        widgetSection.widgetTypes.splice(0, widgetSection.widgetTypes.length);
        widgetTypesAllowed.forEach(widgetType => {
          widgetSection.widgetTypes.push(widgetType);
        });

        if (widgetSection.widgetTypes.length <= 0) {
          widgetSections = _lodash.default.reject(widgetSections, function (currWidgetSection) {
            return currWidgetSection.id === widgetSection.id;
          });
          this.set('widgetSections', widgetSections);
        }
      });
    },

    //In schedule workspace  should not show message and alert widgets
    isScheduleRestrictWidget(widgetType) {
      return this.get('isScheduleDashboard') && _dashboard.SCHEDULE_WORSPACE_RESTRICT_WIDGETS.includes(widgetType.id);
    },

    actions: {
      addWidget(widgetType) {
        // TODO: move this logic into dashboard model after grid settings have
        // have been migrated
        const dashboard = this.get('workspace.dashboard');
        const gridWidth = this.get('workspace.gridWidth');
        const dashboardModel = this.get('workspace.dashboardModel');
        const {
          minWidth,
          minHeight,
          defaultWidth,
          defaultHeight
        } = widgetType;
        const locations = (0, _widgetPlacement.computeWidgetPlacements)(dashboard, gridWidth, minWidth, minHeight); // might want to rank locations rather than taking the first available

        const {
          x,
          y,
          maxWidth,
          maxHeight
        } = locations[0];
        dashboard.addWidget({
          typeId: widgetType.id,
          width: Math.min(defaultWidth, maxWidth),
          height: Math.min(defaultHeight, maxHeight),
          x,
          y
        }); // adding a widget will be committed to the dashboard Model that way for maximizing or minimizing the widget will be kept

        dashboardModel.commitInstance(dashboard);
      },

      onShrinkClick() {
        this.onShrinkClick();
      },

      onCloseClick() {
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});