define("adept-iq/pods/components/iq-widgets/schedule-column-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/schedule-column-widget',
    modelName: 'metric',
    title: 'Schedule Metrics',
    defaultSortId: 'timestamp',
    defaultSortAsc: true,
    columns: [{
      id: 'routes',
      index: 1,
      type: 'number',
      label: '# Routes',
      valuePath: 'routes',
      sortable: false,
      searchable: true,
      defaultWidth: 90,
      resizable: true
    }, {
      id: 'unusedRoutes',
      index: 2,
      type: 'number',
      label: '# Unused Routes',
      valuePath: 'unusedRoutes',
      sortable: false,
      searchable: true,
      defaultWidth: 120,
      resizable: true
    }, {
      id: 'scheduledTrips',
      index: 3,
      type: 'number',
      label: '# Scheduled Trips',
      valuePath: 'scheduledTrips',
      sortable: false,
      searchable: true,
      defaultWidth: 120,
      resizable: true
    }, {
      id: 'waitlistTrips',
      index: 4,
      type: 'number',
      label: '# Waitlisted Trips',
      valuePath: 'waitlistedTrips',
      sortable: false,
      searchable: true,
      defaultWidth: 120,
      resizable: true
    }, {
      id: 'illegalTrips',
      index: 5,
      type: 'number',
      label: '# Illegal',
      valuePath: 'illegalTrips',
      sortable: false,
      searchable: true,
      defaultWidth: 90,
      resizable: true
    }, {
      id: 'breaks',
      index: 6,
      type: 'number',
      label: '# Breaks',
      valuePath: 'breakTrips',
      sortable: false,
      searchable: true,
      defaultWidth: 80,
      resizable: true
    }, {
      id: 'passengers',
      index: 7,
      type: 'number',
      label: '# Passengers',
      valuePath: 'riders',
      sortable: false,
      searchable: true,
      defaultWidth: 105,
      resizable: true
    }, {
      id: 'totalHours',
      index: 8,
      type: 'number',
      label: 'Total Hours',
      valuePath: 'totalHours',
      sortable: false,
      searchable: true,
      defaultWidth: 90,
      resizable: true
    }, {
      id: 'totalMiles',
      index: 9,
      type: 'number',
      label: 'Total Miles',
      valuePath: 'totalMiles',
      sortable: false,
      searchable: true,
      defaultWidth: 90,
      resizable: true
    }, {
      id: 'revenueHours',
      index: 10,
      type: 'number',
      label: 'Total Revenue Hours',
      valuePath: 'revenueHours',
      sortable: false,
      searchable: true,
      defaultWidth: 130,
      resizable: true
    }, {
      id: 'revenueMiles',
      index: 11,
      type: 'number',
      label: 'Total Revenue Miles',
      valuePath: 'revenueMiles',
      sortable: false,
      searchable: true,
      defaultWidth: 130,
      resizable: true
    }, {
      id: 'rideshareTrips',
      index: 12,
      type: 'number',
      label: '% Rideshare Trips',
      valuePath: 'percentageTripsRidesharing',
      sortable: false,
      searchable: true,
      defaultWidth: 130,
      resizable: true
    }, {
      id: 'timeSingle',
      index: 13,
      type: 'number',
      label: '% Time Single',
      valuePath: 'percentageTimeSingle',
      sortable: false,
      searchable: true,
      defaultWidth: 100,
      resizable: true
    }, {
      id: 'rideshareHours',
      index: 14,
      type: 'number',
      label: '% Time Rideshare',
      valuePath: 'percentageTimeRidesharing',
      sortable: false,
      searchable: true,
      defaultWidth: 130,
      resizable: true
    }, {
      id: 'timeOnBreak',
      index: 15,
      type: 'number',
      label: '% Time Break',
      valuePath: 'percentageTimeOnBreak',
      sortable: false,
      searchable: true,
      defaultWidth: 100,
      resizable: true
    }, {
      id: 'timeEmpty',
      index: 16,
      type: 'number',
      label: '% Time Empty',
      valuePath: 'percentageTimeEmpty',
      sortable: false,
      searchable: true,
      defaultWidth: 100,
      resizable: true
    }, {
      id: 'tripRideTime',
      index: 17,
      type: 'number',
      label: 'Average Ride Time',
      valuePath: 'averageRideTimePerTrip',
      sortable: false,
      searchable: true,
      defaultWidth: 155,
      resizable: true
    }]
  };
  _exports.default = _default;
});