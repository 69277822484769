define("adept-iq/models/travel-need-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WHEELCHAIR_TRAVELNEED_TYPE = _exports.LIFTREQUIRED_TRAVELNEED_TYPE = _exports.AMBULATORY_TRAVELNEED_TYPE = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const AMBULATORY_TRAVELNEED_TYPE = 'ambulatory';
  _exports.AMBULATORY_TRAVELNEED_TYPE = AMBULATORY_TRAVELNEED_TYPE;
  const WHEELCHAIR_TRAVELNEED_TYPE = 'wheelchair';
  _exports.WHEELCHAIR_TRAVELNEED_TYPE = WHEELCHAIR_TRAVELNEED_TYPE;
  const LIFTREQUIRED_TRAVELNEED_TYPE = 'liftRequired';
  _exports.LIFTREQUIRED_TRAVELNEED_TYPE = LIFTREQUIRED_TRAVELNEED_TYPE;

  var _default = Model.extend({
    name: attr('string'),
    vehicleCapacityCount: attr('number'),
    loadTime: attr('number'),
    unloadTime: attr('number'),
    displayName: attr('string'),
    displayOrder: attr('number'),
    schedule: belongsTo('schedule'),
    vehicleCapacityType: attr('string'),
    value: attr(),
    subscriptionTravelNeeds: hasMany('subscription-travel-need'),
    legTravelNeeds: hasMany('leg-travel-need'),
    riderTravelNeeds: hasMany('rider-travel-need'),
    isConsumable: Ember.computed('vehicleCapacityCount', function () {
      return this.get('vehicleCapacityCount') > 0;
    }),
    isServiceAnimal: Ember.computed('name', function () {
      return this.get('name') === 'serviceAnimal';
    }),
    isAmbulatory: Ember.computed('name', function () {
      return this.get('name') === 'ambulatory';
    }),
    isWheelchair: Ember.computed.equal('name', 'wheelchair'),
    isWideAmbulatory: Ember.computed.equal('name', 'wideAmbulatory'),
    isWideWheelchair: Ember.computed.equal('name', 'wideWheelchair'),
    upperCaseName: Ember.computed('displayName', function () {
      if (this.get('displayName')) {
        return this.get('displayName').toUpperCase();
      }
    })
  });

  _exports.default = _default;
});