define("adept-iq/services/report", ["exports", "adept-iq/config/api-urls", "lodash", "moment", "fetch", "adept-iq/config/mapped-permIds"], function (_exports, _apiUrls, _lodash, _moment, _fetch, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REPORT_SERVICE_API = `${_apiUrls.API.reportService.host}`; //"https://manage2.ddswireless.com/report-service";

  const REPORT_SERVER_BASE_URL = `${_apiUrls.API.reportService.baseURL}`; //'https://jasper.ddswireless.net/jasperserver-pro/flow.html';

  const DATE_FORMAT = 'YYYY-MM-DD'; // 2021-05-01

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    async getQueryString() {
      const appName = 'ADEPTIQ';
      const companyName = `${_apiUrls.API.reportService.companyName}`;
      const query = `appName=${appName}&companyName=${companyName}`;
      return query;
    },

    async reportList() {
      const store = this.get('store');
      const activeData = store.peekAll('report-list');
      const allReportList = await this.getAllReportList();

      if (activeData.content.length === 0) {
        if (!Ember.isEmpty(allReportList)) {
          allReportList.forEach(item => {
            let permGranted = false;
            if (item.id === 'CLOSEOUT_REPORT') permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.accessCloseoutReport, null);else if (item.id === 'DEDUCT_REPORT') permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.accessDeductReport, null);else {
              store.pushPayload('report-list', {
                data: {
                  id: item.id,
                  type: 'report-list',
                  attributes: {
                    name: item.name,
                    description: item.summary,
                    category: item.group.name
                  }
                }
              });
            }

            if (permGranted) {
              store.pushPayload('report-list', {
                data: {
                  id: item.id,
                  type: 'report-list',
                  attributes: {
                    name: item.name,
                    description: item.summary,
                    category: item.group.name
                  }
                }
              });
            }
          });
        }
      }

      return allReportList;
    },

    async getAllReportList() {
      const notifications = this.get('notifications');

      if (REPORT_SERVICE_API === 'undefined') {
        notifications.warning('Error related to undefined REPORT SERVICE API URL');
        return [];
      }

      const query = await this.getQueryString();
      const REPORT_LIST_API = `${REPORT_SERVICE_API}/report-list?${query}`;
      return await (0, _fetch.default)(REPORT_LIST_API, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(async response => {
        if (response.status === 204 || response.status === 404) {
          notifications.warning('Error related to the report service host url address');
          return [];
        }

        const jsonResponse = await response.json();

        if (!response.ok) {
          notifications.warning('Error related to the report service host url address');
          return [];
        }

        return jsonResponse;
      }).then(async reports => {
        return reports.map(report => {
          return { ...report.attributes,
            id: report.id
          };
        });
      });
    },

    async getUrl(model, runshedule) {
      const {
        parameters,
        uiParameters
      } = model;
      const notifications = this.get('notifications');
      const queryStr = await this.buildQueryStr(parameters, uiParameters);

      if (REPORT_SERVER_BASE_URL === 'undefined') {
        notifications.warning('Error related to undefined REPORT SERVER BASE URL');
        return [];
      }

      let reportUrl = `${REPORT_SERVER_BASE_URL}?${queryStr}`;
      reportUrl = reportUrl.replaceAll('flowId', '_flowId');
      reportUrl = reportUrl.replaceAll('decorate=null', 'decorate=no');

      if (runshedule) {
        reportUrl = reportUrl.replaceAll('flow.html', 'scheduler/main.html');
        reportUrl = reportUrl.replaceAll('reportUnit', 'reportUnitURI');
      }

      return reportUrl;
    },

    async buildQueryStr(parameters, uiParameters) {
      const ppToken = await this.getPPToken();
      const ppTokenParam = `pp=${ppToken}`; // take all uiparams except from and to

      const uiparams = (0, _lodash.omit)(uiParameters, ['from', 'to']);
      const allParameters = { ...parameters,
        ...uiparams
      };
      const queryParamList = Object.keys(allParameters).map(param => {
        const paramValue = allParameters[param];
        return `${param}=${paramValue}`;
      });
      const forDateAndBookingTimelineParam = this.getFromAndToDateQueryParams();
      const allParams = [ppTokenParam, ...queryParamList, forDateAndBookingTimelineParam];
      return allParams.join('&');
    },

    getFromAndToDateQueryParams() {
      const {
        fromDate,
        futureBookingTimeline
      } = this.getFromAndFutureBookingTimeline();
      const fromDateFormat = (0, _moment.default)(fromDate).format(DATE_FORMAT);
      const forDateAndBookingTimelineParam = `for_date=${fromDateFormat}&future_booking_time_line=${futureBookingTimeline}`;
      return forDateAndBookingTimelineParam;
    },

    getFromAndFutureBookingTimeline() {
      const fromDate = new Date((0, _moment.default)().startOf('day'));
      fromDate.setDate(fromDate.getDate() + 1);
      const storeFutureBookingTimeline = this.store.peekRecord('cs-config-item', 'config-Book_Trips/Future_Booking_Timeline');
      const futureBookingTimeline = parseInt(storeFutureBookingTimeline.value, 10);
      return {
        fromDate,
        futureBookingTimeline
      };
    },

    /**
     * @returns {Promise<string>}
     */
    async getPPToken() {
      const tmapiToken = this.session.data.authenticated.token;
      const JASPER_USER_API = `${_apiUrls.API.ssoService.host}/jasper-user-authentication`;
      const ppTokenStr = `dt=${tmapiToken}|dAuthUrl=${JASPER_USER_API}`;
      return this.getEncodedStr(ppTokenStr);
    },

    /**
     *   In java code, it is just one line:
     * URLEncoder.encode(new String(Base64.encodeBase64((string created in step 2))));
     * @param tokenStr
     * @returns {string}
     */
    getEncodedStr(tokenStr) {
      const ppTokenEncodeStr = encodeURIComponent(btoa(tokenStr));
      return ppTokenEncodeStr;
    }

  });

  _exports.default = _default;
});