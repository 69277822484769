define("adept-iq/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bs5gsXyd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"login-template\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"logo-container\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"login_logo.png\"],[11,\"alt\",\"Login Logo\"],[11,\"class\",\"logo\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"form-container p-4 bg-white\"],[9],[0,\"\\n    \"],[1,[27,\"login-form\",null,[[\"errors\",\"onSubmit\"],[[27,\"readonly\",[[23,[\"errors\"]]],null],[27,\"route-action\",[\"login\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});