define("adept-iq/pods/components/iq-widgets/fleet-manager-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WUCRX31q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"secondary-window\",null,[[\"title\",\"isCenterTitleAlign\",\"onXButtonClick\"],[\"Fleet Management\",true,[27,\"action\",[[22,0,[]],\"onXButtonClick\"],null]]],{\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"widget\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"widget-body\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n        \"],[1,[27,\"iq-widgets/fleet-manager-widget/vehicle-table\",null,[[\"tableRef\",\"vehicleRecord\",\"editAPI\",\"onTableRefUpdated\"],[[27,\"mut\",[[23,[\"tableRef\"]]],null],[27,\"mut\",[[23,[\"vehicleRecord\"]]],null],[27,\"mut\",[[23,[\"editAPI\"]]],null],[27,\"action\",[[22,0,[]],\"updateTableRef\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"vehicle-details-container\"],[9],[0,\"\\n        \"],[1,[27,\"iq-widgets/fleet-manager-widget/vehicle-details\",null,[[\"tableRef\",\"vehicleRecord\",\"editAPI\"],[[27,\"mut\",[[23,[\"tableRef\"]]],null],[27,\"mut\",[[23,[\"vehicleRecord\"]]],null],[27,\"mut\",[[23,[\"editAPI\"]]],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/fleet-manager-widget/template.hbs"
    }
  });

  _exports.default = _default;
});