define("adept-iq/pods/components/generic-widgets/ember-react-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "lodash", "ember-light-table", "moment", "adept-iq/classes/data-joins/column-widget", "adept-iq/pods/components/generic-widgets/ember-react-widget/classes/filter-column", "adept-iq/utils/compute-ordered-ids", "ember-concurrency", "papaparse", "adept-iq/utils/mobile", "adept-iq/config/mapped-permIds", "adept-iq/utils/remove-focus", "adept-iq/utils/rql-generators", "adept-iq/utils/filters", "adept-iq/utils/sorts", "adept-iq/config/filter-types", "adept-iq/config/environment", "adept-iq/config/widget-events"], function (_exports, _component, _lodash, _emberLightTable, _moment, _columnWidget, _filterColumn, _computeOrderedIds, _emberConcurrency, _papaparse, _mobile, _mappedPermIds, _removeFocus, _rqlGenerators, _filters, _sorts, _filterTypes, _environment, _widgetEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pathToModel = _exports.default = void 0;
  // const generateUUID = () => '_' + Math.random().toString(36).substr(2, 9);
  const {
    readOnly
  } = Ember.computed;
  const DEFAULT_COLUMN_WIDTH = 100;
  const MIN_COLUMN_WIDTH = 50;
  const CHECKBOX_COLUMN_WIDTH = 30; // number of records to load at a time while scrolling

  const PAGINATION_LIMIT = 3000;
  const PAGINATION_TRIGGER_OFFSET = 50; // time to wait between refreshing adjacent widgets
  // const STAGGERING_INTERVAL = 50;

  const COUNT_UPDATE_INTERVAL = 4000;
  const MAX_ROUTE_SELECTED = 300;

  const toPixels = x => `${x}px`;

  const fromPixels = x => parseInt(x.split('px')[0], 10); // const MAX_STATE_INST = 'max_state_inst';


  const pathToModel = {
    'travelNeeds': 'travel-need-type',
    'serviceNeeds': 'service-need-type',
    'vehicleType': 'vehicle-type'
  };
  _exports.pathToModel = pathToModel;
  const COLUMN_ARRAY_FIELDS = ['serviceNeeds'];

  var _default = _component.default.extend({
    workspace: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    ajax: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    interaction: Ember.inject.service(),
    work: Ember.inject.service(),
    socket: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    coreEntityPopulator: Ember.inject.service(),
    classNames: ['column-widget'],
    classNameBindings: ['disabled'],
    // overload this with a static config object
    config: null,
    limit: PAGINATION_LIMIT,
    offset: 0,
    paginationEnabled: true,
    tableActions: null,
    allowedTableActions: null,
    singleActions: null,
    bulkActions: null,
    records: null,
    visibleRecords: null,
    searchText: '',
    isSearchEnabled: false,
    isSearchAllowed: true,
    // set for demo
    isFilterAllowed: true,
    // set for demo
    isTraversalAllowed: true,
    // set by permission
    isCellEditAllowed: false,
    localQuickFilters: null,
    table: null,
    dataJoin: null,
    lastRowClicked: false,
    inlineRowMenu: null,
    inlineRowMenuPosition: null,
    refreshSync: false,
    resetScroll: false,
    isPaginating: false,
    filterQueryString: null,
    isFurtherSelectionAllowed: null,
    selectionInProgress: false,
    selectAllChecked: false,
    traversalFilterQuery: null,
    traversalFilterParams: null,
    implicitFilterParams: null,
    widgetRecords: null,
    updatePayloads: null,
    widgetInstanceId: null,
    totalWidgetCount: 0,
    previousCompoundFilterString: '',
    //to prevent loaddata from updateColumn in initial compononent state
    previousSortString: null,
    maxRecordLimit: PAGINATION_LIMIT,
    disabledRowIds: null,
    hiddenRowIds: null,
    serverDataTable: null,
    newRouteAction: null,
    scrollClassName: null,
    allowDisplayHash: null,
    isScheduleDashboard: readOnly('workspace.isScheduleDashboard'),
    isDispatchDashboard: readOnly('workspace.isDispatchDashboard'),
    disableRightClick: Ember.computed.or('workspace.{isExported,reoptimizeInProgress}'),
    displayDownloadOption: readOnly('workspace.reoptimizeInProgress'),
    getCoreModelNameByWidgetRecord: Ember.computed('activeContext.topActiveContext.getCoreModelNameByWidgetRecord', function () {
      const checkCoreModel = this.get('activeContext.topActiveContext.getCoreModelNameByWidgetRecord');
      return checkCoreModel;
    }),
    fetchedIdDict: null,
    fetchedRows: Ember.computed('fetchedIdDict', function () {
      const fetchedIdDict = this.get('fetchedIdDict');
      return Object.keys(fetchedIdDict).length;
    }),
    checkedRowIds: Ember.computed('activeContext.topActiveContext.checkedItems', function () {
      const checkedItems = this.get('activeContext.topActiveContext.checkedItems');
      const modelNames = Array.isArray(this.get('config.modelName')) ? this.get('config.modelName') : [this.get('config.modelName')];
      const widgetId = this.widget.id;
      return checkedItems.reduce((ids, item) => {
        // get checked row ids for a specific widget id with same type
        if (modelNames.includes(item.modelName) && item.widgetId === widgetId) {
          ids.push({
            model: item.modelName,
            id: item.record.id
          });
        }

        return _lodash.default.uniqWith(ids, _lodash.default.isEqual);
      }, []);
    }),
    checkedRowCount: Ember.computed('checkedRowIds', 'selectAllChecked', 'totalWidgetCount', 'isTraversalEnabled', function () {
      const isTraversalEnabled = this.get('isTraversalEnabled');
      const checkedRowIds = this.get('checkedRowIds');
      const selectAllChecked = this.get('selectAllChecked');
      const totalWidgetCount = this.get('totalWidgetCount');

      if (isTraversalEnabled) {
        return checkedRowIds.length;
      }

      return selectAllChecked ? totalWidgetCount : checkedRowIds.length;
    }),
    sortId: Ember.computed.alias('widget.state.sortId'),
    sortAsc: Ember.computed.alias('widget.state.sortAsc'),
    tileSize: Ember.computed.alias('workspace.tileSize'),
    tileSpacing: Ember.computed.alias('workspace.tileSpacing'),
    isLoading: false,
    isError: false,
    isServerDataDependency: true,
    // Ember-react-table set this true
    isMaximized: Ember.computed('maximizer.maximizedWidget', 'widget', function () {
      return this.get('maximizer.maximizedWidget') === this.get('widget');
    }),
    useBulkActions: Ember.computed.gt('checkedItemsCount', 1),
    isRowInCheckedRows: Ember.computed('lastRowClicked', 'checkedRowIds.[]', function () {
      const lastRowClicked = this.get('lastRowClicked');
      const checkedRowIds = this.get('checkedRowIds');

      if (!lastRowClicked) {
        return false;
      }

      return checkedRowIds.some(obj => {
        if (lastRowClicked.coreModel) {
          return lastRowClicked.id === obj.id && lastRowClicked.coreModel === obj.model;
        }

        return lastRowClicked.id === obj.id;
      });
    }),
    showBulkActions: Ember.computed.and('useBulkActions', 'isRowInCheckedRows'),
    showSingleActions: Ember.computed.equal('showBulkActions', false),
    checkedRowsCount: Ember.computed('table.rows.@each.isChecked', function () {
      return this.get('table.rows').filterBy('isChecked').length;
    }),
    checkedItemsCount: Ember.computed('checkedItems.[]', function () {
      const checkedItems = this.get('checkedItems') || [];
      return checkedItems.length;
    }),
    checkedItems: Ember.computed('activeContext.topActiveContext.checkedItems.[]', function () {
      const widgetId = this.widget.id;
      const modelName = this.get('config.modelName');
      const checkedItems = this.get('activeContext.topActiveContext.checkedItems') || [];
      const items = checkedItems.filter(storeRecord => {
        const coreModelName = this.get('activeContext.topActiveContext').getCoreModelNameByStoreRecord(modelName, storeRecord); // filter records for a specific widget id with same type

        return storeRecord.modelName === coreModelName && storeRecord.widgetId === widgetId;
      });
      const uniqueItems = [...new Set(items.map(item => item.record))];
      return uniqueItems;
    }),
    isFiltered: Ember.computed('table.columns.@each.{isFiltered}', function () {
      return this.get('table.columns').isAny('isFiltered');
    }),
    visibleColumns: Ember.computed('table.columns', function () {
      const tableColumns = this.get('table.columns');
      const visibleColumns = [];
      tableColumns.forEach(column => {
        const columnConfig = column.config;

        if (!column.hidden && columnConfig) {
          if (!columnConfig.unAvailable && column.allowColumn) {
            visibleColumns.push(columnConfig);
          }
        }
      });
      return visibleColumns;
    }),
    tableColumns: Ember.computed('workspace.startDate', 'config.columns', function () {
      const tableColumns = [{
        id: 'checkbox',
        valuePath: 'checked',
        cellComponent: 'table/cells/check-box',
        component: 'table/columns/check-box',
        sortable: false,
        width: _mobile.isTouchDevice ? '70px' : toPixels(CHECKBOX_COLUMN_WIDTH)
      }];
      const columnConfigs = this.get('config.columns');
      const orderedColumnIds = (0, _computeOrderedIds.default)(columnConfigs);
      const startDate = this.get('workspace.startDate');
      orderedColumnIds.forEach(id => {
        const config = columnConfigs.findBy('id', id);
        let allowColumn = this.get('allowDisplayHash')[config.allowDisplayAttr]; // disable a specific column depending if allowDisplayAttr exist in config

        allowColumn = Ember.isPresent(allowColumn) ? allowColumn : true;
        const tableColumn = {
          id: id,
          component: 'table/columns/filter-capable',
          cellClassNames: config.id,
          classNames: config.id,
          label: config.label,
          cellDesc: config.cellDesc,
          valuePath: `${config.valuePath}`,
          valuePaths: config.valuePaths,
          hidden: config.hidden || config.unAvailable || !allowColumn,
          allowColumn,
          resizable: true,
          minResizeWidth: MIN_COLUMN_WIDTH,
          width: toPixels(config.defaultWidth || DEFAULT_COLUMN_WIDTH),
          startDate,
          config
        };

        if (config.editable && this.isCellEditAllowed) {
          if (config.type === 'enum') {
            tableColumn.cellComponent = 'table/cells/user-editable-enum';
            tableColumn.options = config.options;
            tableColumn.allowMultiple = config.allowMultiple;
          } else {
            tableColumn.cellComponent = 'table/cells/user-editable';
          }
        } else if (config.type.includes('enum')) {
          tableColumn.cellComponent = 'table/cells/text-extension';
        } else {
          tableColumn.cellComponent = 'table/cells/base-cell';
        }

        if (config.highlightable) {
          tableColumn.cellClassNames = ['highlightable'];
        }

        if (['date', 'datetime', 'time', 'datetimeflatpickr'].includes(config.type) && config.format) {
          tableColumn.format = value => {
            return Ember.isEmpty(value) ? value : (0, _moment.default)(value).format(config.format);
          };

          switch (config.type) {
            case 'date':
            case 'datetimeflatpickr':
            case 'datetime':
              tableColumn.parse = value => {
                return (0, _moment.default)(value, config.format).toDate();
              };

              break;

            case 'time':
              tableColumn.parse = (timeString, originalValue) => {
                const date = (0, _moment.default)(originalValue).format('YYYY-MM-DD');
                const newValue = (0, _moment.default)(`${date} ${timeString}`, 'YYYY-MM-DD hh:mm A').toDate();
                return newValue;
              };

              break;

            default:
              break;
          }
        }

        tableColumns.push(tableColumn);
      }); // fills the rest of the space with an empty column

      tableColumns.push({
        id: 'spacer',
        width: '100%',
        sortable: false,
        isResizable: true
      });
      return tableColumns;
    }),
    click: function (event) {
      if (['fa fa-search active-button', 'column-widget-search-box data-test-search-box'].includes(event.target.className)) {
        return false;
      }

      (0, _removeFocus.removeFocus)();
    },
    sort: Ember.computed('sortId', 'sortAsc', function () {
      const value = this.get('sortAsc') ? `${this.get('sortId')}` : `-${this.get('sortId')}`;
      return value;
    }),
    quickFiltersExist: Ember.computed('widgetState', function () {
      const quickFilters = this.get('widgetState.quickFilters');

      if (Ember.isPresent(quickFilters) && quickFilters.length > 0) {
        return true;
      }

      return false;
    }),
    quickFilters: Ember.computed('widgetState.quickFilters', function () {
      const quickFilters = this.get('widgetState.quickFilters') || [];
      const localQuickFilters = this.get('localQuickFilters');
      const widgetInstanceId = `${this.get('widget.typeId')}_${this.get('widget.id')}`;

      if (Ember.isEmpty(localQuickFilters)) {
        // eslint-disable-next-line ember/no-side-effects
        const newQuickFilters = (0, _lodash.cloneDeep)(quickFilters);
        newQuickFilters.forEach(filter => {
          filter.widgetInstanceId = widgetInstanceId;
        }); // eslint-disable-next-line ember/no-side-effects

        this.set('localQuickFilters', newQuickFilters || []);
      } else {
        const newLocalQuickFilters = []; // add newly created filter to local array of quick filters

        quickFilters.forEach(newFilter => {
          const exist = localQuickFilters.find(currFilter => currFilter.id === newFilter.id);

          if (!exist) {
            localQuickFilters.push((0, _lodash.cloneDeep)(newFilter));
          }
        }); // remove filter from local array of quick filters

        localQuickFilters.forEach(currFilter => {
          const exist = quickFilters.find(newFilter => currFilter.id === newFilter.id);

          if (exist) {
            newLocalQuickFilters.push(currFilter);
          }
        }); // eslint-disable-next-line ember/no-side-effects

        this.set('localQuickFilters', newLocalQuickFilters);
      }

      return this.get('localQuickFilters');
    }),
    widgetState: Ember.computed('workspace.widgetStates', 'widget.typeId', function () {
      const widgetStates = this.get('workspace.widgetStates') || {};
      const widgetTypeId = this.get('widget.typeId');
      const widgetState = widgetStates[widgetTypeId] || {};
      return widgetState;
    }),

    setupQuickFilters() {
      const widgetTypeId = this.get('widget.typeId');
      this.set('scrollClassName', `column-widget-filters-scroll-${widgetTypeId}`);
    },

    // populate allowDisplayHash for columns in column widget attribute called allowDisplayAttr
    configureAllowDisplayHash() {
      this.set('allowDisplayHash', {
        allowFundingAgency: this.get('booking').getAllowFundingAgency()
      });
    },

    init() {
      this._super(...arguments);

      this.get('widgetService').setWidgetModelMap(this.get('config.widgetName'), this.get('config.modelName'));
      const isMaximized = this.get('isMaximized');
      this.configureAllowDisplayHash(); // widget records setup

      this.set('widgetRecords', []);
      this.set('updatePayloads', []);
      this.set('disabledRowIds', []);
      this.set('hiddenRowIds', []);
      this.set('fetchedIdDict', {});
      this.set('isFurtherSelectionAllowed', true);
      const widgetId = `${this.get('config.modelName')}_${this.get('widget.id')}${isMaximized ? '_maximized' : ''}`; // this will be unique id

      this.set('widgetInstanceId', widgetId);
      this.setupReceiveData();
      this.setupQuickFilters();
      const table = new _emberLightTable.default([], [], {
        enableSync: false
      });

      const dataJoin = _columnWidget.default.create({
        table,
        activeContext: this.get('activeContext.topActiveContext'),
        work: this.get('work'),
        modelName: this.get('config.modelName')
      });

      const permIds = [_mappedPermIds.default.downloadRoutes, _mappedPermIds.default.downloadStops, _mappedPermIds.default.downloadTrips, _mappedPermIds.default.downloadVehicles, _mappedPermIds.default.downloadDrivers, _mappedPermIds.default.downloadMessages, _mappedPermIds.default.downloadAlerts, // mappedPermIds.downloadMaps,
      _mappedPermIds.default.downloadRoles, _mappedPermIds.default.downloadUsers, _mappedPermIds.default.downloadSubscriptions, _mappedPermIds.default.downloadPassengers, _mappedPermIds.default.downloadZones //mappedPermIds.downloadRoadSupervisors,
      ];
      this.setProperties({
        table,
        dataJoin,
        records: [],
        visibleRecords: [],
        disabledTableActions: [],
        tableActions: [{
          id: 'print',
          permIds,
          name: 'Download',
          action: () => {
            this.exportData();
          }
        }]
      });
      this.set('saveFilterList', []); // Display Download option alone after releasing the route

      if (this.get('displayDownloadOption') && this.get('isScheduleDashboard')) {
        this.set('allowedTableActions', [{
          id: 'print',
          permIds,
          name: 'Download',
          action: () => {
            this.exportData();
          }
        }]);
      } else {
        this.set('allowedTableActions', this.get('tableActions'));
      }

      this.configureSearchFilterPermission();
      this.set('widget.models', _lodash.default.castArray(this.get('config.widgetName')));

      this._onScroll = () => {
        if (this.get('serverDataTable')) this.get('serverDataTable').onScroll();
      };

      this.setupActiveQuickFilter();
    },

    setupActiveQuickFilter() {
      if (this.get('isMaximized')) {
        const maximizedWidget = this.get('maximizer.maximizedWidget');
        const maximizedWidgetInstanceId = `${maximizedWidget.typeId}_${maximizedWidget.id}`;
        const widgetInstanceId = `${this.get('widget.typeId')}_${this.get('widget.id')}`;
        const localQuickFilters = this.get('quickFilters') || [];
        const activeQuickFilter = this.get('maximizer.activeQuickFilter');

        if (maximizedWidgetInstanceId === widgetInstanceId) {
          if (Ember.isPresent(activeQuickFilter)) {
            localQuickFilters.forEach(filter => {
              if (filter.id === activeQuickFilter.id) {
                filter.active = true;
              } else {
                filter.active = false;
              }
            });
          } else {
            localQuickFilters.forEach(filter => {
              filter.active = false;
            });
          }
        }
      }
    },

    destroy() {
      this.get('dataJoin').destroy();
      this.get('table.columns').forEach(column => column.destroy());
      this.get('table').destroy();
      this._onScroll = null;

      this._super(...arguments);
    },

    configureSearchFilterPermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const widgetTypeId = this.get('widget.typeId');

      if (!Ember.isNone(configPermissions)) {
        const configPermission = configPermissions[widgetTypeId];

        if (!Ember.isNone(configPermission.searchAllowed)) {
          this.set('isSearchAllowed', configPermission.searchAllowed);
        }

        if (!Ember.isNone(configPermission.filterAllowed)) {
          this.set('isFilterAllowed', configPermission.filterAllowed);
        }

        if (!Ember.isNone(configPermission.cellEditAllowed)) {
          // @TODO RUX-325 will disable this feature for now, eventually it will return in R5.0+
          this.set('isCellEditAllowed', false);
        }
      }
    },

    exportServerData(widgetColumns) {
      const {
        widgetName
      } = this.get('config'); // For exporting the widget data, Need first character capital of the name

      const widgetData = widgetName.charAt(0).toUpperCase() + widgetName.slice(1);
      const filterString = this.get('widgetCompoundFilter');
      this.get('widgetService').downloadServerData(widgetData, filterString).then(widgetRows => {
        const transformedWidgetRows = this.applyTransformationData(widgetRows);
        const sortedTransformedWidgetRows = this.applySortOrder(transformedWidgetRows);
        const data = this.formatData(sortedTransformedWidgetRows, widgetColumns);
        this.exportCsv(data);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }).catch(error => {
        console.log(error); //eslint-disable-line no-console

        this.get('notifications').warning('Download failed');
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      });
    },

    formatField(row, column) {
      let value = row[column.valuePath];

      if (Ember.isPresent(value)) {
        if (column.type === 'time' || column.type === 'date' || column.type === 'datetime' || column.type === 'datetimeflatpickr') {
          value = (0, _moment.default)(value).format(column.format);
        } else if (column.type === 'enum-date') {
          // The code for enum-date types is based on /app/pods/components/generic-widgets/ember-react-table/classes/availability-cell.js AvailabilityCell.getVehicleAvailabilities
          const valueArray = value.map(dates => {
            const startTime = (0, _moment.default)(dates.startTime);
            const endTime = (0, _moment.default)(dates.endTime);
            const now = (0, _moment.default)();

            if (row.active && now.isBetween(startTime, endTime)) {
              const formattedDates = [];
              formattedDates.push(`*${startTime.format('MM-DD-YYYY')} - ${endTime.format('MM-DD-YYYY')}*`);
              const formattedShiftStart = !_lodash.default.isNil(dates.shiftStart) ? (0, _moment.default)(dates.shiftStart).format('HH:mm') : '';
              const formattedShiftEnd = !_lodash.default.isNil(dates.shiftEnd) ? (0, _moment.default)(dates.shiftEnd).format('HH:mm') : '';
              const formattedShiftTimes = `${formattedShiftStart} - ${formattedShiftEnd}`;

              if (dates.monday) {
                formattedDates.push(`Monday ${formattedShiftTimes}`);
              }

              if (dates.tuesday) {
                formattedDates.push(`Tuesday ${formattedShiftTimes}`);
              }

              if (dates.wednesday) {
                formattedDates.push(`Wednesday ${formattedShiftTimes}`);
              }

              if (dates.thursday) {
                formattedDates.push(`Thursday ${formattedShiftTimes}`);
              }

              if (dates.friday) {
                formattedDates.push(`Friday ${formattedShiftTimes}`);
              }

              if (dates.saturday) {
                formattedDates.push(`Saturday ${formattedShiftTimes}`);
              }

              if (dates.sunday) {
                formattedDates.push(`Sunday ${formattedShiftTimes}`);
              }

              return formattedDates.join(';');
            }

            return null;
          });
          value = valueArray.compact().join(';;');
        } else if (column.type.includes('enum')) {
          value = JSON.stringify(value);
        }
      }

      return value;
    },

    formatData(rows, columns) {
      const exportableColumns = columns;
      const fields = exportableColumns.map(column => {
        return column.label;
      });
      const data = rows.map(row => {
        return exportableColumns.map(column => {
          return this.formatField(row, column);
        });
      });
      return {
        fields,
        data
      };
    },

    exportCsv(fieldsAndData) {
      const csv = _papaparse.default.unparse(fieldsAndData, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"',
        escapeFormulae: true
      });

      const link = document.createElement('a');
      const timestamp = (0, _moment.default)().format('YYYY-MM-DD_HH-mm-ss');
      const {
        title
      } = this.get('config');
      const filename = `${timestamp}_${title}.csv`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    exportData() {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const widgetColumns = this.get('visibleColumns');

      if (this.get('isServerDataDependency')) {
        this.exportServerData(widgetColumns);
      } else {
        const widgetRows = this.get('widgetRecords');
        const data = this.formatData(widgetRows, widgetColumns);
        this.exportCsv(data);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.get('config')) {
        // eslint-disable-next-line
        throw "static config property not found";
      } // bind screen scroll to close any opened drop down menus


      Ember.$('.tile-dashboard-wrapper').on('scroll', this._onScroll);
      this.onTableColumnsChange();
      this.onWidgetStateChange();
      this.reConfigureTableActions();
      this.reConfigureSingleActions(); // this.resetDefaultWidgetState();

      this.set('previousSortString', this.get('sort')); //to prevent loaddata from updateColumn in initial compononent state
      // this.handleWidgetMaxMinState();

      this.applyTraversalFilter(this.determineApplicableFilters()); // should be called when new widget is dragged from side drawer
      /// for new widget is dragged from side drawer

      const readyToLoadInitialData = this.get('widgetService').get('readyToLoadInitialData');

      if (readyToLoadInitialData) {
        this.get('loadWidgetTask').perform().then(() => {
          this.applySelectionConstraints();
        }).catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
      }
    },

    async resetDefaultWidgetState() {
      const defaultSortId = this.get('config.defaultSortId') || 'id';
      const defaultSortAsc = this.get('config.defaultSortAsc') || true;
      const widgetSortId = this.get('widget.state.sortId'); //const store = this.get('store');

      const limit = PAGINATION_LIMIT; // Temp Fix : Remove the calling of pagination as we don't have proper pagination support in RAAS.

      /*try {
        const paginationLimit = await store.findRecord('internal-ui-config', 'Widgets_Pagination_Limit');
         limit = parseInt(paginationLimit.get('value'), 0);
      } catch (e) {
        limit = PAGINATION_LIMIT;
      }*/

      if (Ember.isEmpty(widgetSortId)) {
        this.get('widget').mergeState({
          sortId: defaultSortId,
          sortAsc: defaultSortAsc
        });
      }

      this.set('offset', 0);
      this.set('limit', limit);
      this.set('maxRecordLimit', limit);
    },

    loadWidgetTask: (0, _emberConcurrency.task)(function* () {
      let readyToLoadInitialData = this.get('widgetService').get('readyToLoadInitialData');

      while (!_environment.default.APP.test && !readyToLoadInitialData) {
        yield (0, _emberConcurrency.timeout)(1000);
        readyToLoadInitialData = this.get('widgetService').get('readyToLoadInitialData');
      }

      yield this.loadWidgetData();
    }).keepLatest(),
    // eslint-disable-next-line no-unused-vars
    getTotalWidgetCountTask: (0, _emberConcurrency.task)(function* (modelName, countFilterString, dateQueryFields, selectedProviders) {//Temp Fix : Remove the calling of totalRecords API as we don't have this API implemented for RAAS.
      //const widgetCount = yield this.get('widgetService').getTotalWidgetCount(modelName, countFilterString, dateQueryFields, selectedProviders);
      //this.set('totalWidgetCount', widgetCount);
    }).keepLatest(),

    /**
     * Called when ver user changes filters, initial load etc
     * When user paginates - loadMoreData gets called.
     * This method
     * @returns {Promise<void>}
     */
    async loadWidgetData() {
      if (this.isDestroyed) {
        return;
      }

      const recordFilterString = this.get('widgetCompoundFilter');
      const countFilterString = this.get('countCompoundFilter');
      const widgetName = this.get('config.widgetName');
      const offset = this.get('offset');
      const sort = this.get('sort');
      const widgetInstanceId = this.get('widgetInstanceId');
      const descReg = /^-/;
      const columns = this.get('config.columns');
      const prefix = sort.match(descReg) ? '-' : '';
      const sortId = sort.replace(descReg, '');
      const sortColumn = columns.find(c => c.id === sortId); //const store = this.get('store');

      const limit = PAGINATION_LIMIT; // Temp Fix : Remove the calling of pagination as we don't have proper pagination support in RAAS.

      /*try {
        const paginationLimit = await store.findRecord('internal-ui-config', 'Widgets_Pagination_Limit');
         limit = parseInt(paginationLimit.get('value'), 0);
      } catch (e) {
        limit = PAGINATION_LIMIT;
      }*/

      let sortValuePath = 'id';

      if (sortColumn) {
        sortValuePath = sortColumn.customSortPath ? sortColumn.customSortPath : sortColumn.valuePath;
      } // refresh data so set previous unhandled updates to empty


      this.set('updatePayloads', []);
      this.set('isLoading', true);
      this.set('previousCompoundFilterString', recordFilterString);
      this.set('previousSortString', sort);
      const dateQueryFields = this.get('widgetService').getDateQueryFields();
      const selectedProviders = this.get('widgetService').getSelectedProviders();

      try {
        // this.set('totalWidgetCount', 0);
        const fetchedData = await this.get('widgetService').loadData(widgetInstanceId, widgetName, recordFilterString, offset, limit, prefix + sortValuePath, dateQueryFields, selectedProviders);
        const fetchedIdDict = {};
        fetchedData.forEach(d => {
          fetchedIdDict[d.id] = true;
        });
        this.set('fetchedIdDict', fetchedIdDict);
        this.notifyPropertyChange('fetchedIdDict');
        this.set('totalWidgetCount', fetchedData.length);
        await this.get('getTotalWidgetCountTask').perform(widgetName, countFilterString, dateQueryFields, selectedProviders).catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });

        if (this.isDestroyed) {
          return;
        }

        this.set('isLoading', false);
      } catch (e) {
        if (this.isDestroyed) {
          return;
        }

        this.set('isLoading', false);
      }
    },

    willDestroyElement() {
      this.$().off('keydown', this._onKeyDown);
      this.$().off('mousedown', this._onMouseDown);
      Ember.$('.tile-dashboard-wrapper').off('scroll', this._onScroll);
      this.get('dataJoin').clear();

      try {
        if (typeof MutationObserver !== 'undefined' && this.get('mutationObserver')) {
          this.get('mutationObserver').disconnect();
        }
      } catch (e) {
        console.log(e); //eslint-disable-line no-console
      }

      this.get('widgetService').off(_widgetEvents.WIDGET_DATA_ACTION, this._handleWidgetAction);
      this.get('widgetService').off(_widgetEvents.RECEIVE_DATA_LIST_EVENT, this._handleReceiveDataEventHandler);
      this.get('widgetService').off(_widgetEvents.LOAD_MORE_DATA_LIST_EVENT, this._handleLoadMoreEventHandler);
      this.get('widgetService').off(_widgetEvents.INITIAL_WIDGET_LOAD_EVENT, this._initialLoadEventHandler);
      this.get('widgetService').off(_widgetEvents.ADD_TRAVERSAL_SELECTION_EVENT, this._traversalEventHandler);
      this.get('widgetService').off(_widgetEvents.REMOVE_TRAVERSAL_SELECTION_EVENT, this._removeSelectionEventHandler);
      this.get('widgetService').off(_widgetEvents.DESELECT_ALL_WIDGET_EVENT, this._deselectAllEventHandler);

      if (this.get('loadWidgetTask.isRunning')) {
        this.get('loadWidgetTask').cancelAll();
      }

      if (this.get('getTotalWidgetCountTask.isRunning')) {
        this.get('getTotalWidgetCountTask').cancelAll();
      } // when any one of widget is maximized, copy all component's  widget state to maximizer
      // because when you minimized , we need to retain the all the  component's state
      // const maximizer = this.get('maximizer');
      // if (maximizer.isMaximized()) {
      //   this.copyComponentStateToMaximizerService();
      // }


      this._super(...arguments);
    },

    setTableColumns() {
      if (this.get('isDestroyed')) return;
      const oldColumns = this.get('table.columns').slice();
      const tableColumns = this.get('tableColumns');
      this.set('table.columns', tableColumns.map(col => new _filterColumn.default(col)));
      oldColumns.forEach(column => column.destroy());
    },

    updateTableColumns() {
      if (this.get('isDestroyed')) return;
      const state = this.get('widget.state') || {};
      const config = this.get('config') || {};
      const columns = this.get('table.columns');
      const restrictSwapColumns = config.restrictSwapColumns;
      let shouldResize = false;
      let scrollLeft;
      const body = this.$('.column-widget-body');

      if (body) {
        scrollLeft = this.$('.column-widget-body').scrollLeft();
      }

      if (columns) {
        columns.forEach(column => {
          const sortId = state.sortId || config.defaultSortId;

          if (Ember.isPresent(sortId) && sortId === column.get('id')) {
            let sortAsc;

            if (Ember.isPresent(state.sortAsc)) {
              sortAsc = state.sortAsc;
            } else if (Ember.isPresent(config.defaultSortAsc)) {
              sortAsc = config.defaultSortAsc;
            } else {
              sortAsc = true;
            }

            column.set('sorted', true);
            column.set('ascending', sortAsc);
          } else {
            column.set('sorted', false);
          }
        });
      }

      const columnsHash = state.columns || {};
      Object.entries(columnsHash).forEach(_ref => {
        let [id, columnState] = _ref;
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (Ember.isPresent(columnState.hidden)) {
          column.set('hidden', columnState.hidden);
          shouldResize = true;
        }

        if (Ember.isPresent(columnState.index) && !restrictSwapColumns) {
          // +/- 1 accounts for checkbox column
          const oldIndex = columns.indexOf(column) - 1;

          if (columnState.index !== oldIndex) {
            const swapColumn = columns.objectAt(1 + columnState.index);

            if (swapColumn && swapColumn.id !== 'spacer') {
              columns.replace(1 + columnState.index, 1, [column]);
              columns.replace(1 + oldIndex, 1, [swapColumn]);
              shouldResize = true;
            }
          }
        }

        ['filterTypeId', 'filterValues'].forEach(prop => {
          // allow null value to overwrite
          if (!columnState.hasOwnProperty(prop)) {
            return;
          }

          column.set(prop, columnState[prop]);
        });

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
          shouldResize = true;
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }

      this.notifyPropertyChange('visibleColumns');
      this.buildQuery();
      this.updateGrid();
    },

    /**
     * Gets called when sorting and filtering / searching is done through filter window
     */
    updateGrid() {
      const filterString = this.get('widgetCompoundFilter');
      const sortString = this.get('sort');
      const previousCompoundFilterString = this.get('previousCompoundFilterString');
      const previousSortString = this.get('previousSortString');

      if (filterString !== previousCompoundFilterString || sortString !== previousSortString) {
        this.set('offset', 0);
        this.set('limit', this.get('maxRecordLimit')); // debounce(this, 'loadWidgetData', 250);

        this.get('loadWidgetTask').perform().catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
      }
    },

    resizeBody() {
      if (this.get('isDestroyed')) {
        return;
      }

      const table = this.$('.lt-head-wrap table');
      const widgetBody = this.$('.column-widget-body');

      if (!table && !widgetBody) {
        return;
      }

      const tableWidth = table ? table.width() : -Infinity;
      const widgetWidth = widgetBody ? widgetBody.width() : -Infinity;
      const width = Math.max(tableWidth, widgetWidth);
      this.$('.ember-light-table').width(width);
    },

    clearData() {
      this.set('records', []);
      this.set('offset', 0);
    },

    setScrollLeft(scrollLeft) {
      if (!this.$('.column-widget-body')) {
        return;
      }

      this.$('.column-widget-body').scrollLeft(scrollLeft);
    },

    getWidgetIndex() {
      if (this.get('maximizer.maximizedWidget') || !Ember.isPresent(this.element.closest('.grid-stack'))) {
        return null;
      }

      const gridStack = this.element.closest('.grid-stack');
      const gridStackItems = Array.from(gridStack.children).sort((a, b) => {
        const yDiff = parseInt(a.getAttribute('data-gs-y'), 10) - parseInt(b.getAttribute('data-gs-y'), 10);

        if (yDiff === 0) {
          return parseInt(a.getAttribute('data-gs-x'), 10) - parseInt(b.getAttribute('data-gs-x'), 10);
        }

        return yDiff;
      });
      const gridStackItem = this.element.closest('.grid-stack-item');
      return gridStackItems.indexOf(gridStackItem);
    },

    widgetCompoundFilter: Ember.computed('filterQueryString', 'traversalFilterQuery', 'searchText', 'isTraversalEnabled', function () {
      const allFilters = this.calculateRecordFilters();
      return this.filterArrayToExpression(allFilters);
    }),

    calculateRecordFilters() {
      const isTraversalEnabled = this.get('isTraversalEnabled');
      const gridConfig = this.get('config');
      const widgetName = gridConfig.widgetName;
      const filterQueryString = this.get('filterQueryString') || '';
      const traversalFilter = this.get('widgetService').getTraversalFilterQuery(widgetName) || this.get('traversalFilterQuery') || '';
      const searchFilter = this.get('searchText') || '';
      const allFilters = [];
      this.populateImplicitFilter(allFilters);

      if (!_lodash.default.isEmpty(filterQueryString)) {
        allFilters.push(filterQueryString);
      }

      if (!_lodash.default.isEmpty(traversalFilter) && isTraversalEnabled) {
        allFilters.push(traversalFilter);
      }

      if (!_lodash.default.isEmpty(searchFilter)) {
        const searchRQL = this.getSearchTextRQL(searchFilter);

        if (searchRQL) {
          allFilters.push(searchRQL);
        }
      }

      return allFilters;
    },

    filterArrayToExpression(allFilters) {
      if (allFilters.length === 0) {
        return '';
      } // Can use reduce - Ashraf TODO


      if (allFilters.length === 5) {
        const filterQuery = (0, _rqlGenerators.andRQL)((0, _rqlGenerators.andRQL)((0, _rqlGenerators.andRQL)((0, _rqlGenerators.andRQL)(allFilters[0], allFilters[1]), allFilters[2]), allFilters[3]), allFilters[4]);
        return encodeURIComponent(filterQuery);
      }

      if (allFilters.length === 4) {
        const filterQuery = (0, _rqlGenerators.andRQL)((0, _rqlGenerators.andRQL)((0, _rqlGenerators.andRQL)(allFilters[0], allFilters[1]), allFilters[2]), allFilters[3]);
        return encodeURIComponent(filterQuery);
      }

      if (allFilters.length === 3) {
        const filterQuery = (0, _rqlGenerators.andRQL)((0, _rqlGenerators.andRQL)(allFilters[0], allFilters[1]), allFilters[2]);
        return encodeURIComponent(filterQuery);
      }

      if (allFilters.length === 2) {
        const filterQuery = (0, _rqlGenerators.andRQL)(allFilters[0], allFilters[1]);
        return encodeURIComponent(filterQuery);
      }

      if (allFilters[0]) {
        return encodeURIComponent(allFilters[0]);
      }

      return '';
    },

    countCompoundFilter: Ember.computed('filterQueryString', 'traversalFilterQuery', 'searchText', 'isTraversalEnabled', function () {
      const allFilters = this.calculateRecordFilters();
      this.populateCountFilter(allFilters);
      return this.filterArrayToExpression(allFilters);
    }),

    populateImplicitFilter(allFilters) {
      const implicitConditions = this.get('config.implicit.conditions') || [];
      const implicitFilters = [];

      for (const condition of implicitConditions) {
        let compareFn;

        switch (condition.type) {
          case 'stringNeRQL':
            compareFn = _rqlGenerators.stringNeRQL;
            break;

          case 'eqRQL':
            compareFn = _rqlGenerators.eqRQL;
            break;

          case 'neRQL':
            compareFn = _rqlGenerators.neRQL;
            break;

          default:
            compareFn = _rqlGenerators.eqRQL;
        }

        const statusFilter = compareFn(condition.field, condition.value);
        implicitFilters.push(statusFilter);
      }

      this.set('implicitFilterParams', implicitFilters);
      const compoundFilter = implicitFilters.reduce((c, f) => {
        const _c = c ? (0, _rqlGenerators.andRQL)(c, f) : f;

        return _c;
      }, null);

      if (compoundFilter) {
        allFilters.push(compoundFilter);
      }
    },

    // eslint-disable-next-line no-unused-vars
    populateCountFilter(allFilters) {},

    getModelsValueFromStoreItems(modelName, searchTerm) {
      const modelTypeMapper = this.get('store').peekAll(modelName).map(record => {
        return {
          id: record.id,
          name: record.name,
          displayName: record.displayName.trim().toLowerCase().replace(/ /, '')
        };
      });
      return (0, _filters.applyFilterConversion)(searchTerm, _filterTypes.enumContains.id, modelTypeMapper);
    },

    getSearchTextRQL(searchString) {
      const searchObject = searchString.trim().split('=');
      const searchKey = searchObject.length > 1 ? searchObject[0] : null;
      const searchValue = searchObject.length > 1 ? searchObject[1] : searchObject[0]; // Dummy search for potential matches.
      // Currently, it ignores spaces.
      // Additional cases can be added in the future.
      // And regex will be preferred if more cases will be included

      const adjustedSearchValue = [searchValue.trim().toLowerCase().replace(/ /, '')];
      let dbFieldName, modelName;

      if (searchKey) {
        dbFieldName = this.getSearchDBFieldColumn(searchKey);
        modelName = pathToModel[dbFieldName];
      } else {
        const widgetVisibleColumns = this.getSearchableColumns();
        const likePredicates = widgetVisibleColumns.map(column => {
          modelName = pathToModel[column.valuePath];
          return (0, _rqlGenerators.stringLikeRQL)(column.valuePath, modelName ? this.getModelsValueFromStoreItems(modelName, adjustedSearchValue) : adjustedSearchValue);
        });
        return (0, _rqlGenerators.orRQL)(likePredicates);
      } // If no store model is found for the provided search string,
      // we must include a valid filter with a non-empty searchTerm.
      // An empty object as searchTerm would exclude the filter from RQL.
      // Therefore, we use the original search string provided by the user
      // to construct a valid filter.


      const searchTerm = modelName ? this.getModelsValueFromStoreItems(modelName, adjustedSearchValue) : adjustedSearchValue;
      return (0, _rqlGenerators.stringLikeRQL)(dbFieldName, searchTerm.length < 1 ? adjustedSearchValue : [searchTerm]);
    },

    getSearchDBFieldColumn(fieldName) {
      const lowerCaseFieldName = fieldName.replace(/\s+/g, '').toLowerCase();
      const searchableColumns = this.getSearchableColumns();

      for (let i = 0; i < searchableColumns.length; i++) {
        const column = searchableColumns[i];
        const columnId = column.id;
        const columnLabel = column.label;
        const lowerCaseColumnId = columnId.replace(/\s+/g, '').toLowerCase();
        const lowerCaseColumnLabel = columnLabel.replace(/\s+/g, '').toLowerCase();

        if ([lowerCaseColumnId, lowerCaseColumnLabel].includes(lowerCaseFieldName)) {
          return column.valuePath;
        }
      }

      return null;
    },

    getSearchableColumns() {
      const widgetVisibleColumns = this.get('visibleColumns');
      return widgetVisibleColumns; //  return widgetVisibleColumns.filter((column) => column.searchable);
    },

    onTableColumnsChange: Ember.observer('tableColumns', function () {
      Ember.run.scheduleOnce('afterRender', this, 'setTableColumns');
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),
    onWidgetStateChange: Ember.observer('widget.state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),
    onTableActionsChange: Ember.observer('tableActions.[]', 'disabledTableActions.[]', function () {
      this.reConfigureTableActions();
    }),

    reConfigureTableActions() {
      const configPermissions = this.get('workspace.configPermissions');
      const disabledTableActions = this.get('disabledTableActions');
      const actions = [];

      if (!Ember.isNone(configPermissions)) {
        const configPermission = configPermissions[this.get('widget.typeId')];

        if (!Ember.isNone(configPermission) && !Ember.isNone(this.get('tableActions'))) {
          this.get('tableActions').forEach(currAction => {
            const permGranted = this.get('permissionLayer').permInUserHash(currAction.permId, currAction.permIds);

            if (permGranted && !Ember.isNone(configPermission.tableActions) && !disabledTableActions.includes(currAction.id) && (configPermission.tableActions.includes(currAction.id) || configPermission.tableActions.includes('all'))) {
              actions.push(currAction);
            }
          });
          this.set('allowedTableActions', actions);
        }
      }
    },

    keyDown(event) {
      const ltCell = event.target.closest('.lt-cell'); // need to exempt checkboxes to allow right arrow from checkbox col

      const targetIsControl = event.target.tagName === 'INPUT' && event.target.type !== 'checkbox';

      switch (event.key) {
        case 'Escape':
          {
            const targetIsSearchBox = event.target.classList.contains('column-widget-search-box');

            if (targetIsSearchBox) {
              event.preventDefault();
              this.set('isSearchEnabled', false);
              this.$('.column-widget-search-button').focus();
            }

            break;
          }

        case 'ArrowLeft':
          {
            if (!ltCell || targetIsControl) {
              return;
            }

            event.preventDefault();
            const previousCell = ltCell.previousElementSibling;

            if (!previousCell) {
              return;
            }

            const focusElement = previousCell.querySelector('.can-focus') || previousCell.firstElementChild;

            if (focusElement) {
              focusElement.focus();
            }

            break;
          }

        case 'ArrowRight':
          {
            if (!ltCell || targetIsControl) {
              return;
            }

            event.preventDefault();
            const nextCell = ltCell.nextElementSibling;

            if (!nextCell) {
              return;
            }

            const focusElement = nextCell.querySelector('.can-focus') || nextCell.firstElementChild;

            if (focusElement) {
              focusElement.focus();
            }

            break;
          }

        case 'ArrowDown':
          {
            if (!ltCell) {
              return;
            }

            event.preventDefault();
            const row = ltCell.closest('.lt-row'); // an HTMLCollection isn't really an array

            const rowChildren = [].slice.call(row.children);
            const index = rowChildren.indexOf(ltCell);
            const nextRow = row.nextElementSibling;

            if (nextRow && nextRow.tagName !== 'OCCLUDED-CONTENT') {
              // move table selection down one row
              const tableRows = this.get('table.rows');
              tableRows.setEach('selected', false);
              const recordId = nextRow.getAttribute('data-record-id');
              const nextTableRow = tableRows.findBy('content.id', recordId);
              nextTableRow.set('selected', true); // move browser focus to same column in next row

              const nextLtCell = nextRow.children[index];
              const focusElement = nextLtCell.querySelector('.can-focus') || nextLtCell.firstElementChild;

              if (focusElement) {
                focusElement.focus();
              }
            }

            break;
          }

        case 'ArrowUp':
          {
            if (!ltCell) {
              return;
            }

            event.preventDefault();
            const row = ltCell.closest('.lt-row'); // an HTMLCollection isn't really an array

            const rowChildren = [].slice.call(row.children);
            const index = rowChildren.indexOf(ltCell);
            const previousRow = row.previousElementSibling;

            if (previousRow && previousRow.tagName !== 'OCCLUDED-CONTENT') {
              // move table selection down one row
              const tableRows = this.get('table.rows');
              tableRows.setEach('selected', false);
              const recordId = previousRow.getAttribute('data-record-id');
              const previousTableRow = tableRows.findBy('content.id', recordId);
              previousTableRow.set('selected', true); // move browser focus to same column in previous row

              const previousLtCell = previousRow.children[index];
              const focusElement = previousLtCell.querySelector('.can-focus') || previousLtCell.firstElementChild;

              if (focusElement) {
                event.preventDefault();
                focusElement.focus();
              }
            }

            break;
          }

        case ' ':
        case 'Enter':
          {
            const isColumn = event.target.classList.contains('table-base-column');

            if (isColumn) {
              event.preventDefault();
              this.$(event.target).trigger('click');
            }

            break;
          }

        default:
          break;
      }
    },

    doubleClick(event) {
      event.preventDefault();

      if (event.target.tagName === 'INPUT' && event.target.type === 'checkbox') {
        // allow user to check another row without moving selection
        return;
      }

      const ltCell = event.target.closest('.lt-cell');

      if (ltCell) {
        const row = ltCell.closest('.lt-row');
        const index = Array.from(row.children).indexOf(ltCell);
        const recordId = row.getAttribute('data-record-id');
        const tableRows = this.get('table.rows');
        const clickedColumn = this.get('table.columns')[index];
        const clickedRow = tableRows.findBy('content.id', recordId);
        tableRows.setEach('selected', false);
        const scheduleLocked = clickedRow.get('record.scheduleLocked');

        if (!scheduleLocked || Ember.isNone(scheduleLocked)) {
          clickedRow.set('isEditing', true);
          clickedColumn.set('isEditing', true);
        }
      }
    },

    // Helper function to take a list of (single/bulk) actions, and the corresponding list of action names
    // from the config service.  Order the list of actions that will be used to populate the dropdown menu
    // based on the order from the config service, so that it's always consistent
    // Also allow for the config list to include an "all" value, where anything not already specified will
    // be inserted (in the order determined by the widget's returned action list)
    orderActionsByConfigList(actions, listOfActionsFromConfig) {
      const configList = Ember.isNone(listOfActionsFromConfig) ? [] : listOfActionsFromConfig; // Find any actions that were declared by the widget, but aren't in the config list

      const actionsToBeIncludedAsAll = actions.filter(action => !configList.some(configAction => configAction === action.id));
      const result = []; // Iterate over the config list, so that we populate the result in the right order

      configList.forEach(configAction => {
        if (configAction === 'all') {
          result.push(...actionsToBeIncludedAsAll);
        } else {
          const foundAction = actions.find(action => action.id === configAction);

          if (foundAction) {
            result.push(foundAction);
          }
        }
      });
      return result;
    },

    reConfigureBulkActions() {
      const bulkActions = this.get('dynamicBulkActions') || this.get('bulkActions') || [];
      this.set('bulkActions', bulkActions);
    },

    reConfigureSingleActions() {
      const configPermissions = this.get('workspace.configPermissions');

      if (!Ember.isNone(configPermissions)) {
        const configPermission = configPermissions[this.get('widget.typeId')];
        let singleActions = [];
        const bulkActions = [];

        if (!Ember.isNone(configPermission) && !Ember.isNone(this.get('singleActions'))) {
          this.get('singleActions').forEach(currAction => {
            const permGranted = this.get('permissionLayer').permInUserHash(currAction.permId, currAction.permIds);

            if (permGranted && !Ember.isNone(configPermission.singleActions) && (configPermission.singleActions.includes(currAction.id) || configPermission.singleActions.includes('all'))) {
              singleActions.push(currAction);
            }
          });

          if (!Ember.isNone(singleActions)) {
            singleActions = this.orderActionsByConfigList(singleActions, configPermission.singleActions);
          }

          this.set('singleActions', singleActions);
        }

        if (!Ember.isNone(configPermission) && !Ember.isNone(this.get('bulkActions'))) {
          this.get('bulkActions').forEach(currAction => {
            const permGranted = this.get('permissionLayer').permInUserHash(currAction.permId, currAction.permIds);

            if (permGranted && !Ember.isNone(configPermission.bulkActions) && (configPermission.bulkActions.includes(currAction.id) || configPermission.bulkActions.includes('all'))) {
              bulkActions.push(currAction);
            }
          });
          this.set('bulkActions', bulkActions);
        }
      }
    },

    handleWidgetAction() {},

    updateSelection(data, isSelected) {
      const config = this.get('config');
      const selectedRowLimit = config.maxLimitByRouteSelection;
      const maxLimitRouteAssociation = config.maxLimitWithoutRouteAssociation;
      const widgetModelName = config.widgetName;
      const coreModelName = config.modelName;
      const widgetInstanceId = this.get('widgetInstanceId');
      const isTraversalEnabled = this.get('isTraversalEnabled');
      this.set('selectionInProgress', true);
      this.get('activeContext.topActiveContext').get('setWidgetRecordsTask').perform([data], widgetModelName, coreModelName, widgetInstanceId, isSelected, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, false).then(() => {
        this.set('selectionInProgress', false);
      }).catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      });
    },

    setupReceiveData() {
      const gridConfig = this.get('config');
      const currentWidgetModelName = gridConfig.widgetName; // receiving single widget data

      this._handleWidgetAction = payLoadList => {
        if (this.get('loadWidgetTask.isRunning')) {
          this.set('updatePayloads', this.get('updatePayloads').concat(payLoadList));
          return;
        }

        let widgetRecords = this.get('widgetRecords');
        let receivedNewUpdate = false;
        const traversalActions = [];
        const updatePayloads = this.get('updatePayloads').concat(payLoadList);
        this.set('updatePayloads', []);
        updatePayloads.forEach(payload => {
          const {
            header,
            body
          } = payload;

          if (this.isPayloadApplicable(payload, currentWidgetModelName)) {
            const widgetData = body.data;
            const data = this.applyTransformationData([widgetData])[0];
            const widgetRowAction = this.determineWidgetRecordAction(widgetRecords, header, data);

            if (widgetRowAction === 'ADD' || widgetRowAction === 'UPDATE') {
              const index = widgetRecords.findIndex(record => record._key === data._key);

              if (index === -1) {
                widgetRecords.push(data);
                receivedNewUpdate = true;
              } else {
                widgetRecords[index] = data; // TODO: specify traversal fields only

                if (this.get('widgetService').isSelected(currentWidgetModelName, data._key)) {
                  traversalActions.push({
                    action: 'UPDATE',
                    data
                  });
                }
              }
            }

            if (widgetRowAction === 'DELETE') {
              this.removeDeletedItems(data);

              if (this.get('widgetService').isSelected(currentWidgetModelName, data._key)) {
                traversalActions.push({
                  action: 'DELETE',
                  data
                });
              }

              widgetRecords = widgetRecords.filter(record => {
                return record._key !== data._key;
              });
              receivedNewUpdate = true;
            }
          }
        });
        const sortedRecords = this.applySortOrder(widgetRecords);
        this.set('widgetRecords', sortedRecords);

        if (traversalActions.length > 0) {
          traversalActions.forEach(t => {
            if (t.action === 'UPDATE') {
              this.updateSelection(t.data, true);
            } else if (t.action === 'DELETE') {
              this.updateSelection(t.data, false);
            }
          });
        }

        if (receivedNewUpdate) {
          this.get('loadCountStatesTask').perform();
        }
      }; // receiving widget data list


      this._handleReceiveDataEventHandler = _ref2 => {
        let {
          widgetInstanceId,
          data
        } = _ref2;

        if (widgetInstanceId !== this.get('widgetInstanceId')) {
          return;
        }

        const records = data || [];
        const selectAllChecked = this.get('selectAllChecked');
        const widgetModelName = gridConfig.widgetName;
        const coreModelName = gridConfig.modelName;
        const isTraversalEnabled = this.get('isTraversalEnabled');
        const selectedRowLimit = gridConfig.maxLimitByRouteSelection;
        const maxLimitRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
        this.applyTransformationData(records);
        const filteredRecords = this.applyPostFetchFilter(records);
        const sortedRecords = this.handleSort(filteredRecords); // optional in memory sorting

        if (selectAllChecked) {
          this.set('selectionInProgress', true);
          this.get('activeContext.topActiveContext').get('setWidgetRecordsTask').perform(sortedRecords, widgetModelName, coreModelName, widgetInstanceId, true, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, false).then(() => {
            this.set('selectionInProgress', false);
          }).catch(e => {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              throw e;
            }
          });
        }

        this.set('widgetRecords', sortedRecords);
        this.setMaxRecordLimit(); // Records filtered for Widget Display might still be used for Store Update, so dont give filtered Records

        this.postHandleReceiveDataEventHandler(records);
      };

      this._handleLoadMoreEventHandler = _ref3 => {
        let {
          widgetInstanceId,
          data
        } = _ref3;

        if (widgetInstanceId !== this.get('widgetInstanceId')) {
          return;
        }

        const records = data || [];
        this.applyTransformationData(records);
        const filteredRecords = this.applyPostFetchFilter(records); // Replace existed data to avoid duplicate rows.

        const widgetRecords = (0, _lodash.default)(this.get('widgetRecords')).differenceBy(filteredRecords, '_key').concat(filteredRecords).value();
        const sortedRecords = this.handleSort(widgetRecords); // optional in memory sorting

        this.set('widgetRecords', sortedRecords);
        this.setMaxRecordLimit(); // Records filtered for Widget Display might still be used for Store Update, so dont give filtered Records

        this.postHandleReceiveDataEventHandler(records);
      };

      this._initialLoadEventHandler = async () => {
        await this.resetDefaultWidgetState();
        this.get('loadWidgetTask').perform().catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
      };

      this._traversalEventHandler = async widgetInstanceId => {
        const currentWidgetInstanceId = this.get('widgetInstanceId');

        if (currentWidgetInstanceId !== widgetInstanceId) {
          this.applyTraversalFilter(this.determineApplicableFilters());
          await this.get('loadWidgetTask').perform().catch(e => {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              throw e;
            }
          });
        }

        this.applySelectionConstraints();
      };

      this._removeSelectionEventHandler = async widgetInstanceId => {
        const currentWidgetInstanceId = this.get('widgetInstanceId');

        if (currentWidgetInstanceId !== widgetInstanceId) {
          this.applyTraversalFilter(this.determineApplicableFilters());
          await this.get('loadWidgetTask').perform().catch(e => {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              throw e;
            }
          });
        }

        this.applySelectionConstraints();
      };

      this._deselectAllEventHandler = async () => {
        this.deselectAllWidgets();
        this.applyTraversalFilter(this.determineApplicableFilters());
        await this.get('loadWidgetTask').perform().catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
        this.applySelectionConstraints();
      };

      this.get('widgetService').on(_widgetEvents.WIDGET_DATA_ACTION, this._handleWidgetAction);
      this.get('widgetService').on(_widgetEvents.RECEIVE_DATA_LIST_EVENT, this._handleReceiveDataEventHandler);
      this.get('widgetService').on(_widgetEvents.LOAD_MORE_DATA_LIST_EVENT, this._handleLoadMoreEventHandler);
      this.get('widgetService').on(_widgetEvents.INITIAL_WIDGET_LOAD_EVENT, this._initialLoadEventHandler);
      this.get('widgetService').on(_widgetEvents.ADD_TRAVERSAL_SELECTION_EVENT, this._traversalEventHandler);
      this.get('widgetService').on(_widgetEvents.REMOVE_TRAVERSAL_SELECTION_EVENT, this._removeSelectionEventHandler);
      this.get('widgetService').on(_widgetEvents.DESELECT_ALL_WIDGET_EVENT, this._deselectAllEventHandler);
    },

    /**
     * All Vehicles,  Messages are loaded fully, other stats will be updated when a ADD record event comes
     */
    throttledCountStats() {
      Ember.run.throttle(this, this.updateCountStats, COUNT_UPDATE_INTERVAL);
    },

    loadCountStatesTask: (0, _emberConcurrency.task)(function* () {
      yield this.updateCountStats();
    }).keepLatest(),

    async updateCountStats() {
      if (this.isDestroyed) {
        return;
      }

      if (this.get('loadWidgetTask.isRunning')) {
        return;
      }

      const countFilterString = this.get('countCompoundFilter');
      const widgetName = this.get('config.widgetName');
      const dateQueryFields = this.get('widgetService').getDateQueryFields();
      const selectedProviders = this.get('widgetService').getSelectedProviders();

      try {
        await this.get('getTotalWidgetCountTask').perform(widgetName, countFilterString, dateQueryFields, selectedProviders).catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('e', e);
      }

      if (this.isDestroyed) {
        return;
      }
    },

    isPayloadApplicable(payload, currentWidgetModelName) {
      const {
        header
      } = payload;
      const {
        widgetModelName
      } = header;
      return currentWidgetModelName.toLowerCase() === widgetModelName.toLowerCase();
    },

    applySortOrder(widgetRecords) {
      let isColumnArraySort = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const sortSet = widgetRecords.concat([]);
      const sort = this.buildSortParam();
      const compareFn = isColumnArraySort ? (0, _sorts.buildSimpleArrayCompareFunction)(sort) : (0, _sorts.buildSimpleCompareFunction)(sort);

      if (this.get('config.widgetName') === 'stopWidget' && this.get('stopsReorder.reorderInProgress')) {
        return sortSet;
      }

      sortSet.sort(compareFn);
      return sortSet;
    },

    // eslint-disable-next-line no-unused-vars
    postHandleReceiveDataEventHandler(records) {},

    /*
     determineWidgetRecordAction(widgetRecords,header, body) {
      return header.action;
    },*/

    /**
     * Widget Record always comes with UPDATE flag. If the
     *  if  the widget is not already included, return a ADD
     *
     * @param header
     * @param data
     * @returns {string|*}
     */
    determineWidgetRecordAction(widgetRecords, header, data) {
      const index = widgetRecords.findIndex(record => record._key === data._key);
      const match = this.matchesActiveFilter(data) && this.matchTraversalConstraint(data) && this.matchImplicitConstraint(data);

      if (header.action === 'DELETE') {
        return 'DELETE';
      }

      if (match && data.deleted) {
        return 'DELETE';
      }

      if (index !== -1 && match) {
        return 'UPDATE';
      }

      if (match && index === -1) {
        // if the filter condition matches and if the record doesnt exist in the component then ADD it
        return 'ADD';
      }

      if (!match) {
        // if the filter condition doesnt match and if the record current exist in the component then DELETE it
        return 'DELETE';
      }

      return 'NONE';
    },

    deselectAllWidgets() {
      this.set('isFurtherSelectionAllowed', true);
      this.set('offset', 0);
      this.set('limit', this.get('maxRecordLimit'));
    },

    applySelectionConstraints() {
      if (this.isDestroyed) {
        return;
      }

      if (this.canFurtherSelectionAllowed()) {
        this.set('disabledRowIds', []);
        this.set('isFurtherSelectionAllowed', true);
      } else {
        this.disableFurtherRows();
        this.set('isFurtherSelectionAllowed', false);
      }
    },

    applyTransformationData(widgetRecords) {
      return widgetRecords;
    },

    applyPostFetchFilter(widgetRecords) {
      return widgetRecords;
    },

    hasConflict(filter, allFilters, conflicts) {
      if (conflicts) {
        for (const conflict of conflicts) {
          const conflictWith = allFilters.find(f => f.model === conflict.widget && f.record[conflict.targetField] === filter.record[conflict.srcField]);
          if (conflictWith) return true;
        }
      }

      return false;
    },

    async applyTraversalFilter(applicableFilters) {
      const traversal = this.get('config.traversal');
      const excluded = this.get('config.excluded');
      const inFilterIds = [];
      const eqFieldQueries = [];
      const filters = Object.values(applicableFilters).reduce((a, i) => [...a, ...i], []);
      const nonConflictFilters = [];
      let keyName = 'id';

      if (excluded) {
        for (const filter of filters) {
          const conflicts = excluded[filter.model] ? excluded[filter.model].conflicts : [];
          const isConflicted = this.hasConflict(filter, filters, conflicts);
          if (!isConflicted) nonConflictFilters.push(filter);
        }
      } else {
        nonConflictFilters.push(...filters);
      }

      for (const filter of nonConflictFilters) {
        const links = traversal[filter.model] ? traversal[filter.model].links : [];

        for (const [i, link] of links.entries()) {
          if (filter.record[link.valuePath] || i === links.length - 1) {
            // The last link always need to execute.
            if (link.type === 'eq') {
              eqFieldQueries.push(this.get('widgetService').getEqualQuery(link.field, filter.record[link.valuePath]));
            }

            if (link.type === 'stringEq') {
              eqFieldQueries.push(this.get('widgetService').getEqualQuery(link.field, `'${filter.record[link.valuePath]}'`));
            }

            if (link.type === 'exist') {
              eqFieldQueries.push(this.get('widgetService').getExistedQuery(`${link.field}.${filter.record[link.valuePath]}`));
            }

            if (link.type === 'inObjValues') {
              // If not existing valuePath then all records should be filtered out.
              const keys = filter.record[link.valuePath] ? Object.values(filter.record[link.valuePath]).map(obj => obj[link.key]) : [''];
              inFilterIds.push(...keys);
              keyName = link.rqlKey;
            }

            if (link.type === 'inObjKeys') {
              // If not existing valuePath then all records should be filtered out.
              const keys = filter.record[link.valuePath] ? Object.keys(filter.record[link.valuePath]).map(key => `${key}`) : [''];
              inFilterIds.push(...keys);
              keyName = 'id';
            }

            if (link.type === 'in' && filter.record[link.valuePath]) {
              inFilterIds.push(`${filter.record[link.valuePath]}`);
            } // only adapt the first matching criteria


            if (link.type === '') break;
          }
        }
      }

      const generatedRQL = this.get('widgetService').combineInAndEqualFilters(keyName, inFilterIds, eqFieldQueries);
      const modelName = this.get('config.modelName');
      this.get('widgetService').setTraversalFilterQuery(modelName, generatedRQL);
      this.set('traversalFilterQuery', generatedRQL);
      this.set('traversalFilterParams', eqFieldQueries);
      this.set('traversalFilterIds', inFilterIds);
    },

    determineApplicableFilters() {
      const traversalFilters = this.get('widgetService').get('traversalFilters');
      const applicableFilters = {};
      const currentWidgetInstanceId = this.get('widgetInstanceId');
      (0, _lodash.forIn)(traversalFilters, (value, key) => {
        if (key !== currentWidgetInstanceId) {
          applicableFilters[key] = value;
        }
      });
      return applicableFilters;
    },

    setMaxRecordLimit() {
      const widgetRecords = this.get('widgetRecords');
      const maxRecordLimit = Math.max(widgetRecords.length, this.get('maxRecordLimit'));
      this.set('maxRecordLimit', maxRecordLimit);
    },

    getCheckedItems() {},

    getDisabledItems() {},

    removeDeletedItems() {},

    disableFurtherRows() {
      const disabledRowIds = this.get('disabledRowIds');
      const checkedItems = this.get('checkedRowIds');
      const widgetRecords = this.get('widgetRecords');
      widgetRecords.forEach(record => {
        const isChecked = checkedItems.some(obj => {
          if (record.coreModel) {
            return record.id === obj.id && record.coreModel === obj.model;
          }

          return record.id === obj.id;
        });

        if (!isChecked) {
          disabledRowIds.push(record.id);
        }
      });
      this.set('disabledRowIds', disabledRowIds.concat([]));
    },

    doSearchText(searchText) {
      this.set('searchText', searchText);
      this.set('offset', 0);
      this.set('limit', this.get('maxRecordLimit')); // debounce(this, 'loadWidgetData', 250);

      this.get('loadWidgetTask').perform().catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      });
    },

    async populateEntitiesForMenuAction() {
      await this.populateCoreEntities();
    },

    /**
     * populate core entities of selected widget record..
     * this method is fired  on right click
     * @param
     * @returns {Promise<*|Array|*>}
     */
    async populateCoreEntities() {
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      const widgetModelName = this.get('config.widgetName');
      await this.get('coreEntityPopulator').populateEntityGraph(widgetModelName, selectedWidgetRecord);
      const isMultiRecordsChecked = this.get('widgetService').isMultiRecordsSelected(widgetModelName);

      if (isMultiRecordsChecked) {
        const selectedRecords = this.get('widgetService').getSelectedWidgetRecordsByModel(widgetModelName);
        selectedRecords.forEach(async widgetrecord => {
          await this.get('coreEntityPopulator').populateEntityGraph(widgetModelName, widgetrecord);
        });
      }
    },

    getSelectedCoreModel() {
      const isSchedule = this.get('workspace.isScheduleDashboard');
      const widgetName = this.get('config.widgetName');
      const widgetRecord = this.get('selectedWidgetRecord');
      let coreModelName = this.get('config.modelName');

      if (isSchedule && widgetName === 'stopWidget') {
        if (widgetRecord.type === 'break') {
          coreModelName = 'route-break';
        } else if (widgetRecord.type === 'pulloutGarage' || widgetRecord.type === 'pullinGarage') {
          coreModelName = 'stop-point';
        } else {
          coreModelName = 'trip-stop';
        }
      }

      const message = this.get('store').peekRecord(coreModelName, widgetRecord.id);
      return message;
    },

    onWidgetResize() {
      if (this.get('serverDataTable')) {
        this.get('serverDataTable').onWidgetResize();
      }
    },

    setTraversal() {
      const gridConfig = this.get('config');
      const widgetModelName = gridConfig.widgetName;
      const isMaximized = this.get('isMaximized');
      const widgetRecords = this.get('widgetRecords');
      const checkedItems = this.get('checkedRowIds'); // we select/unselect the right instance whether it's minimized or maximized

      const widgetInstanceId = isMaximized ? this.get('widgetInstanceId').replace('_maximized', '') : this.get('widgetInstanceId');
      const isTraversalEnabled = this.get('isTraversalEnabled');
      const checkedWidgetRecords = widgetRecords.filter(record => {
        if (record.coreModel) {
          return _lodash.default.some(checkedItems, {
            model: record.coreModel,
            id: record.id
          });
        }

        return _lodash.default.some(checkedItems, {
          id: record.id
        });
      });
      this.get('activeContext.topActiveContext').get('setWidgetTraversalTask').perform(checkedWidgetRecords, widgetModelName, widgetInstanceId, isTraversalEnabled).catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      });
      this.get('loadWidgetTask').perform().catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      });
    },

    fetchDataTask: (0, _emberConcurrency.task)(function* () {
      const records = yield this.fetchDataQuery();
      this.set('records', records);
    }).restartable(),

    refreshData() {
      if (this.get('isDestroyed')) return;
      this.get('fetchDataTask').perform().then(() => {
        if (this.get('activeContext.topActiveContext.refreshInProgress')) {
          this.set('activeContext.topActiveContext.refreshInProgress', false);
        }
      }).catch(err => {
        if (this.get('activeContext.topActiveContext.refreshInProgress')) {
          this.set('activeContext.topActiveContext.refreshInProgress', false); // eslint-disable-next-line no-console

          console.log('Error occoured when refreshing the Data' + err);
        }

        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    cancelStopReorderIfInitiated() {
      const stopsReorder = this.get('stopsReorder');

      if (stopsReorder.get('reorderInProgress') && this.get('isDispatchDashboard')) {
        this.get('stopsReorder').stopReorder();
      }
    },

    toggleTraversalButton() {
      this.cancelStopReorderIfInitiated();
      const gridConfig = this.get('config');
      const widgetModelName = gridConfig.widgetName;
      const coreModelName = gridConfig.modelName;
      const selectedRowLimit = gridConfig.maxLimitByRouteSelection;
      const maxLimitRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
      const isMaximized = this.get('isMaximized');
      const widgetInstanceId = isMaximized ? this.get('widgetInstanceId').replace('_maximized', '') : this.get('widgetInstanceId');
      const checkedItems = this.get('checkedRowIds');
      const isTraversalEnabled = this.get('isTraversalEnabled');
      const widgetRecords = this.get('widgetRecords');
      const checkedWidgetRecords = widgetRecords.filter(record => {
        if (record.coreModel) {
          return _lodash.default.some(checkedItems, {
            model: record.coreModel,
            id: record.id
          });
        }

        return _lodash.default.some(checkedItems, {
          id: record.id
        });
      });

      if (this.get('selectionInProgress')) {
        return;
      }

      this.set('selectionInProgress', true);
      this.deselectAllWidgets();
      this.get('activeContext.topActiveContext').get('setWidgetRecordsTask').perform(checkedWidgetRecords, widgetModelName, coreModelName, widgetInstanceId, false, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled).then(() => {
        this.set('selectionInProgress', false);
      }).catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      });
      this.set('selectAllChecked', false);
    },

    isLockedByOtherUser(isLocked, lock) {
      const userId = this.get('session').data.authenticated.userId;
      return isLocked && lock.user !== userId;
    },

    actions: {
      onRefresh() {
        this.refreshData();
        this.get('loadWidgetTask').perform().catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
      },

      onLeftButtonClick() {
        Ember.$(`.${this.get('scrollClassName')}`).animate({
          scrollLeft: '-=100px'
        }, 'fast');
      },

      onRightButtonClick() {
        Ember.$(`.${this.get('scrollClassName')}`).animate({
          scrollLeft: '+=100px'
        }, 'fast');
      },

      setServerDataTableRef(tableRef) {
        this.set('serverDataTable', tableRef);
      },

      onSearchTextChange(event) {
        if (event.keyCode !== 13) {
          return;
        }

        const searchText = event.target.value;
        this.doSearchText(searchText);
      },

      onColumnClick(sortColumnId, sortAsc) {
        this.get('widget').mergeState({
          sortId: sortColumnId,
          sortAsc
        });
      },

      onColumnResize(column, pixels) {
        const scrollLeft = this.$('.column-widget-body').scrollLeft();
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          width: fromPixels(pixels)
        };
        this.get('widget').mergeState({
          columns
        });
        Ember.run.next(() => {
          Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
        });
      },

      onSearchButtonClick() {
        this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        Ember.run.schedule('afterRender', () => {
          if (this.get('isSearchEnabled')) {
            this.$('.column-widget-search-box').focus();
          }
        });
      },

      onRemoveSearchClick() {
        this.doSearchText('');
        this.toggleProperty('isSearchEnabled');
        this.$('.column-widget-search-button').focus();
      },

      onHeaderDoubleClick() {
        if (this.get('stopsReorder.reorderInProgress')) return;
        const maximizer = this.get('maximizer'); // when widget is maximized, minimize  the widget
        // if (maximizer.get('isWidgetMinified')) {

        if (maximizer.get('maximizedWidget') !== this.get('widget')) {
          const localQuickFilter = this.get('localQuickFilters').find(filter => filter.active);
          const activeQuickFilter = Ember.isPresent(localQuickFilter) ? {
            id: localQuickFilter.id
          } : null;
          maximizer.maximize(this.get('widget'), activeQuickFilter);
        } else {
          this.send('onExitMaximizedClick', '');
        }
      },

      onExitMaximizedClick() {
        // when widget is minimized, copy the only current(maximized) component state to maximizer
        // this.copyComponentStateToMaximizerService();
        const maximizer = this.get('maximizer');
        const localQuickFilter = this.get('localQuickFilters').find(filter => filter.active);
        const activeQuickFilter = Ember.isPresent(localQuickFilter) ? {
          id: localQuickFilter.id
        } : null;
        maximizer.minimize(activeQuickFilter); // this.minimizeWidget();
      },

      onFilterButtonClick() {
        const columns = [];
        const workspace = this.get('workspace');
        const widget = this.get('widget');
        const config = this.get('config');
        const displayName = `${config.title} Filters`; // close sidebar if _this_ widget's filter is currently open
        // @TODO: define and use unique keys rather than `displayName` for this

        if (displayName === workspace.get('topStateDisplayName')) {
          workspace.popState();
          return;
        }

        let topState = workspace.get('topState');

        while (topState === 'filterColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        this.get('table.columns').forEach(column => {
          const columnConfig = column.config;

          if (columnConfig) {
            if (!columnConfig.unAvailable) {
              columns.push(column);
            }
          } else {
            columns.push(column);
          }
        });
        workspace.pushState('filterColumnWidget', {
          columns,
          widget,
          displayName
        });
      },

      setInlineRowMenu(dropdown) {
        this.set('inlineRowMenu', dropdown);
      },

      setPlusIconMenu(dropdown) {
        this.set('plusIconMenu', dropdown);
      },

      async onRowRightClick(widgetRecord) {
        const disableRightClick = this.get('disableRightClick'); // disable right row click for iq-widgets when a user runs schedule generation or release routes.

        if (disableRightClick) return;
        this.set('lastRowClicked', widgetRecord);
        this.set('isLoading', true);
        this.set('selectedWidgetRecord', widgetRecord);
        await this.populateEntitiesForMenuAction();
        const row = this.getSelectedCoreModel();

        if (Ember.isNone(this.get('dynamicSingleActions'))) {
          this.set('dynamicSingleActions', this.get('singleActions'));
        } else {
          this.set('singleActions', this.get('dynamicSingleActions'));
        }

        if (row && this.isLockedByOtherUser(widgetRecord.isLocked, widgetRecord.lock)) {
          const singleActions = [];
          this.get('singleActions').forEach(action => {
            if (!action.disableOnLockedRoute) {
              singleActions.push(action);
            }
          });
          this.set('singleActions', singleActions);
        } else {
          const singleActions = [];
          this.get('singleActions').forEach(action => {
            singleActions.push(action);
          });
          this.set('singleActions', singleActions);
        } // remove right click action if schedule record is locked


        if (row && row.get('scheduleLocked')) {
          const singleActions = [];
          this.get('singleActions').forEach(action => {
            if (!action.disableOnLocked) {
              singleActions.push(action);
            }
          });
          this.set('singleActions', singleActions);
        } else {
          const singleActions = [];
          this.get('singleActions').forEach(action => {
            singleActions.push(action);
          });
          this.set('singleActions', singleActions);
        }

        if (!Ember.isNone(this.get('singleActions'))) {
          this.reConfigureSingleActions();
        }

        this.set('isLoading', false);

        if (this.get('showBulkActions')) {
          this.reConfigureBulkActions();
          return this.get('bulkActions');
        }

        return this.get('singleActions');
      },

      onScroll() {
        // pause background processing while scrolling
        this.get('interaction').didScrollWidget(); // close any open dropdown

        if (this.get('serverDataTable')) {
          this.get('serverDataTable').onScroll();
        } // figure out if we need to increase pagination


        const body = this.$('.column-widget-body')[0];
        const scrollPosition = body.scrollTop + body.offsetHeight;
        const triggerPosition = body.scrollHeight - PAGINATION_TRIGGER_OFFSET; // scroll height takes a while to update, so we don't reset pagination
        // flag until we observe it change in a subsequent `onScroll()` action

        if (body.scrollHeight !== this._scrollHeight) {
          this._scrollHeight = body.scrollHeight;
          this.set('isPaginating', false);
        }

        if (scrollPosition >= triggerPosition && !this._isPaginating) {
          const visibleCount = this.get('visibleRecords.length');
          const offset = this.get('offset');
          const limit = this.get('limit');

          if (offset < visibleCount) {
            this.set('offset', offset + limit);
            this.set('isPaginating', true);
            this.set('refreshSync', true);
          }
        }
      },

      onTableActionClick(action, dropdown) {
        dropdown.actions.close();

        if (action.action) {
          action.action();
        }
      },

      onSingleRowAction(action) {
        const model = this.get('lastRowClicked');
        this.inlineRowMenu.actions.close();

        if (action.action) {
          action.action(model);
        }
      },

      onSearchChange(option) {
        console.log('onSearchChange ', option, '  option id ', option.id); //eslint-disable-line no-console

        this.set('searchSelected', option.label);
        this.set('searchSelectedColumn', option);
      },

      onTraversalButtonClick() {
        const isTraversalEnabled = this.get('isTraversalEnabled');

        if (!isTraversalEnabled) {
          this.toggleTraversalButton();
        }

        this.toggleProperty('isTraversalEnabled');
        this.setTraversal();
      },

      onBulkRowAction(action) {
        const checkedRows = this.get('table.rows').filterBy('isChecked');
        const models = [];
        checkedRows.forEach(row => {
          models.push(row.get('record'));
        });
        this.get('inlineRowMenu').actions.close();

        if (action.action) {
          action.action(models);
        }
      },

      onCheckAll() {
        const totalWidgetCount = this.get('totalWidgetCount') ? parseInt(this.get('totalWidgetCount'), 10) : 0;

        if (this.get('selectionInProgress')) {
          return;
        }

        this.cancelStopReorderIfInitiated();
        const gridConfig = this.get('config');
        const widgetModelName = gridConfig.widgetName;
        const coreModelName = gridConfig.modelName;
        const widgetInstanceId = this.get('widgetInstanceId');
        const isTraversalEnabled = this.get('isTraversalEnabled');
        const widgetRecords = this.get('widgetRecords');
        const selectedRowLimit = gridConfig.maxLimitByRouteSelection;
        const maxLimitRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;

        if (widgetModelName === 'routeWidget' && totalWidgetCount > MAX_ROUTE_SELECTED) {
          this.get('notifications').warning(`${MAX_ROUTE_SELECTED} IS THE MAXIMUM NUMBER OF ROUTES THAT CAN BE SELECTED`);
          return;
        }

        this.set('selectionInProgress', true);
        this.get('activeContext.topActiveContext').get('setWidgetRecordsTask').perform(widgetRecords, widgetModelName, coreModelName, widgetInstanceId, true, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, true).then(() => {
          this.set('selectionInProgress', false);
        }).catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
        this.set('selectAllChecked', true);
      },

      onUncheckAll() {
        this.toggleTraversalButton();
      },

      onWidgetStateUpdateHandler(updateState) {
        this.get('widget').mergeState({ ...updateState
        }); // save any filter changes i.e columns reorders, visibility, and filter changes

        this.get('workspace').saveTempDashboardState();
      },

      onSelectCheckBtnHandler(selectedData) {
        this.cancelStopReorderIfInitiated();
        const gridConfig = this.get('config');
        const selectedRowLimit = gridConfig.maxLimitByRouteSelection;
        const maxLimitRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
        const widgetModelName = gridConfig.widgetName;
        const coreModelName = gridConfig.modelName;
        const isMaximized = this.get('isMaximized');
        const isTraversalEnabled = this.get('isTraversalEnabled'); // we select/unselect the right instance whether it's minimized or maximized

        const widgetInstanceId = isMaximized ? this.get('widgetInstanceId').replace('_maximized', '') : this.get('widgetInstanceId');

        if (this.get('selectionInProgress')) {
          return;
        }

        this.set('selectionInProgress', true);
        this.get('activeContext.topActiveContext').get('setWidgetRecordsTask').perform([selectedData], widgetModelName, coreModelName, widgetInstanceId, true, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, true).then(() => {
          this.set('selectionInProgress', false);
        }).catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
      },

      onUnSelectCheckBtnHandler(selectedData) {
        if (this.get('selectAllChecked')) return;
        this.cancelStopReorderIfInitiated();
        const gridConfig = this.get('config');
        const selectedRowLimit = gridConfig.maxLimitByRouteSelection;
        const maxLimitRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
        const widgetModelName = gridConfig.widgetName;
        const coreModelName = gridConfig.modelName;
        const isMaximized = this.get('isMaximized');
        const isTraversalEnabled = this.get('isTraversalEnabled'); // we select/unselect the right instance whether it's minimized or maximized

        const widgetInstanceId = isMaximized ? this.get('widgetInstanceId').replace('_maximized', '') : this.get('widgetInstanceId');

        if (this.get('selectionInProgress')) {
          return;
        }

        this.set('selectionInProgress', true);
        this.get('activeContext.topActiveContext').get('setWidgetRecordsTask').perform([selectedData], widgetModelName, coreModelName, widgetInstanceId, false, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, false).then(() => {
          this.set('selectionInProgress', false);
        }).catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        });
      },

      rowReorderHandler(oldIndex, newIndex) {
        const reorderCallback = this.get('reorderCallback');
        reorderCallback(oldIndex, newIndex);
      },

      /**
       * Gets called form React grid when user tried scroll past the visibile limit
       * @param offset
       * @param pageSize
       * @returns {Promise<void>}
       */
      async loadMoreRows(offset, pageSize) {
        const gridConfig = this.get('config');
        const widgetInstanceId = this.get('widgetInstanceId');
        const currentWidgetCount = this.get('widgetRecords.length');
        const totalWidgetCount = this.get('totalWidgetCount');
        const widgetType = gridConfig.widgetName;
        const sort = this.get('sort');
        const descReg = /^-/;
        const columns = this.get('config.columns');
        const prefix = sort.match(descReg) ? '-' : '';
        const sortId = sort.replace(descReg, '');
        const sortColumn = columns.find(c => c.id === sortId);
        const fetchedRows = this.get('fetchedRows');
        let start = Math.min(offset, fetchedRows);
        let sortValuePath = 'id';

        if (sortColumn) {
          sortValuePath = sortColumn.customSortPath ? sortColumn.customSortPath : sortColumn.valuePath;
        }

        const filterString = this.get('widgetCompoundFilter');
        this.set('isLoading', true);
        const dateQueryFields = this.get('widgetService').getDateQueryFields();
        const selectedProviders = this.get('widgetService').getSelectedProviders();
        let loadMore = !this.get('getTotalWidgetCountTask.isRunning') && totalWidgetCount > currentWidgetCount;
        const selectAllChecked = this.get('selectAllChecked');
        const widgetModelName = gridConfig.widgetName;
        const coreModelName = gridConfig.modelName;
        const isTraversalEnabled = this.get('isTraversalEnabled');
        const selectedRowLimit = gridConfig.maxLimitByRouteSelection;
        const maxLimitRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;

        try {
          while (loadMore) {
            const fetchedData = await this.widgetService.loadMoreData(widgetInstanceId, widgetType, filterString, start, pageSize, prefix + sortValuePath, dateQueryFields, selectedProviders);
            const fetchedIdDict = this.get('fetchedIdDict');
            fetchedData.forEach(d => {
              fetchedIdDict[d.id] = true;
            });
            this.notifyPropertyChange('fetchedIdDict');
            const updateWidgetCount = this.get('widgetRecords.length'); // If no increase on widget records we just keep fetching to the end

            if (updateWidgetCount === currentWidgetCount && updateWidgetCount < totalWidgetCount && start < totalWidgetCount) {
              start += pageSize;
            } else {
              loadMore = false;
            }

            if (selectAllChecked) {
              this.set('selectionInProgress', true);
              this.get('activeContext.topActiveContext').get('setWidgetRecordsTask').perform(fetchedData, widgetModelName, coreModelName, widgetInstanceId, true, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, true).then(() => {
                this.set('selectionInProgress', false);
              }).catch(e => {
                if (!(0, _emberConcurrency.didCancel)(e)) {
                  throw e;
                }
              });
            }
          }
        } catch (e) {
          this.set('isLoading', false);
        }

        this.set('isLoading', false);
      }

    },

    matchesActiveFilter(widgetRecord) {
      const searchText = this.get('searchText');
      const res = searchText.split('=');
      let filterParts = [];

      if (Ember.isEmpty(res[0]) || Ember.isEmpty(res[1])) {
        filterParts = [this.buildSearchFilter(), this.buildColumnFilter()];
      } else {
        filterParts = [this.buildSearchFilterByParameter(), this.buildColumnFilter()];
      }

      const filterNode = (0, _filters.buildCompoundFilterNode)('and', filterParts);
      const filterFn = (0, _filters.buildFilterFunction)(filterNode);
      const resultSet = [widgetRecord].filter(filterFn);
      return !!resultSet.length;
    },

    getByPath(widgetRecord, path) {
      const parts = path.split('.');
      let o = widgetRecord;

      if (parts.length > 0) {
        for (let i = 0; i < parts.length; i++) {
          if (!o[parts[i]]) {
            return null;
          }

          o = o[parts[i]];
        }
      }

      return o;
    },

    matchImplicitConstraint(widgetRecord) {
      const implicitFilterParams = this.get('implicitFilterParams');
      const transform = this.get('config.transform');

      if (Ember.isEmpty(implicitFilterParams)) {
        return true;
      }

      let matched = true;

      if (implicitFilterParams.length > 0) {
        for (let i = 0; i < implicitFilterParams.length; i++) {
          const [op] = implicitFilterParams[i].match(/^([eq,ne])+/);
          const implicitFieldConstraint = implicitFilterParams[i].match(/[eq,ne]\(([^)]+)\)/).pop();
          const paramParts = implicitFieldConstraint.split(',');
          const paramName = paramParts[0]; // local widgetRecord could be transformed, but filters are using nontransformed paramName

          const transformParamName = transform && transform[paramName] ? transform[paramName] : paramName;
          const paramValue = paramParts[1].replace(/'/g, '');
          const fieldValue = this.getByPath(widgetRecord, transformParamName); // If any traversal condition matches return true

          if (op === 'eq' && fieldValue && fieldValue === paramValue || Ember.isNone(fieldValue) && paramValue === 'null') {
            matched = true;
          } else if (op === 'ne' && paramValue === 'null' && fieldValue) {
            matched = true;
          } else if (op === 'ne' && fieldValue && fieldValue !== paramValue) {
            matched = true;
          } else {
            // If one of the condition failed then return false;
            return false;
          }
        }
      }

      return matched;
    },

    matchTraversalConstraint(widgetRecord) {
      const traversalFilterParams = this.get('traversalFilterParams');
      const traversalFilterIds = this.get('traversalFilterIds');
      const transform = this.get('config.transform');

      if (Ember.isEmpty(traversalFilterParams) && Ember.isEmpty(traversalFilterIds)) {
        return true;
      }

      if (traversalFilterParams.length > 0) {
        for (let i = 0; i < traversalFilterParams.length; i++) {
          const [op] = traversalFilterParams[i].match(/^([eq,ne])+/);
          const traversalFieldConstraint = traversalFilterParams[i].match(/[eq,ne]\(([^)]+)\)/).pop();
          const paramParts = traversalFieldConstraint.split(',');
          const paramName = paramParts[0]; // local widgetRecord could be transformed, but filters are using nontransformed paramName

          const transformParamName = transform && transform[paramName] ? transform[paramName] : paramName;
          const paramValue = paramParts[1].replace(/'/g, ''); // If any traversal condition matches return true

          if (op === 'eq' && widgetRecord[transformParamName] && widgetRecord[transformParamName] === paramValue) {
            return true;
          } else if (op === 'ne' && paramValue === 'null' && this.getByPath(widgetRecord, transformParamName)) {
            return true;
          }
        }
      }

      if (traversalFilterIds.length > 0) {
        return traversalFilterIds.some(id => `${id}` === `${widgetRecord._key}`);
      }

      return false;
    },

    /**
     *
     * @param widgetRecord -- Note : Adds it to SortedSet and then checks the position
     * @returns {*}
     */
    determineSortedPosition(widgetRecord) {
      const sort = this.buildSortParam();
      const compareFn = (0, _sorts.buildSimpleCompareFunction)(sort);
      const widgetRecords = this.get('widgetRecords');
      const sortSet = widgetRecords.concat([]);
      sortSet.push(widgetRecord);
      return widgetRecords.sort(compareFn).indexOf(sortSet);
    },

    handleSort(widgetRecords) {
      const state = this.get('state');

      if (Ember.isPresent(state.sortId) && this.getLocalSortColumns().includes(state.sortId)) {
        const isColumnArraySort = COLUMN_ARRAY_FIELDS.includes(state.sortId);
        return this.applySortOrder(widgetRecords, isColumnArraySort);
      }

      return widgetRecords;
    },

    getLocalSortColumns() {
      const columns = this.get('visibleColumns');
      const filteredColunms = columns.map(c => c.id);
      return filteredColunms;
    },

    rollbackCache() {
      if (this.get('serverDataTable')) {
        this.get('serverDataTable').rollbackCache();
      }
    },

    forceUpdateGrids() {
      const widgetRecords = this.get('widgetRecords');

      if (this.get('serverDataTable')) {
        this.get('serverDataTable').forceUpdateGrids();
        this.applySortOrder(widgetRecords);
      }
    }

  });

  _exports.default = _default;
});