define("adept-iq/pods/components/side-drawer/scheduling-trip-transfer/component", ["exports", "ember-light-table", "ember-concurrency", "lodash", "adept-iq/config/placeholders", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/side-drawer/scheduling-trip-transfer/config", "adept-iq/config/mapped-permIds", "adept-iq/classes/impact-stop", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberLightTable, _emberConcurrency, _lodash, _placeholders, _moment, _unwrapProxy, _config, _mappedPermIds, _impactStop, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROUTE_INCLUDES = ['trips', 'trips.pick', 'trips.pick.segmentStop', 'trips.pick.segmentStop.place', 'trips.pick.segmentStop.place.address', 'trips.drop', 'trips.drop.segmentStop', 'trips.drop.segmentStop.place', 'trips.drop.segmentStop.place.address', 'trips.segment', 'trips.segment.leg', 'trips.segment.leg.rider', 'routeBreaks'];
  const PICK_STOP_TYPE = 'pick';
  const DROP_STOP_TYPE = 'drop';

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    coreEntityPopulator: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    booking: Ember.inject.service(),
    //placeholders
    TIME_PLACEHOLDER: _placeholders.TIME_PLACEHOLDER,
    // passed by the side drawer container component
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    solutionPreviewTable: null,
    solutionImpactTable: null,
    activeTabRouteId: null,
    selectedSolutionId: null,
    providersList: null,
    currentRouteSolution: null,
    solutions: null,
    selectedImpactPeriod: 'after',
    routeOverrideOptions: null,
    overrideSelectedRoute: null,
    override: false,
    initialLoadedSolutionIds: null,
    overrideSelectedProvider: null,
    overrideSelectedPickOrder: null,
    overrideSelectedDropOrder: null,
    overridePickTime: null,
    overrideDropTime: null,
    pickTime: '',
    dropTime: '',
    errorText: null,
    overrideErrorText: null,
    title: 'Transfer Trip',
    rowComponent: 'table/rows/colored-row',
    canShrink: Ember.computed.not('isSolutionsTaskRunning'),
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    brokerEligible: Ember.computed.readOnly('trip.segment.leg.brokerEligible'),
    pickRouteOrdinals: Ember.computed.alias('overridePickOrderOptions'),
    previousSelectedTripProvider: Ember.computed.readOnly('trip.provider'),
    isManualSchedulingMode: Ember.computed.readOnly('trip.isManualSchedulingMode'),
    isCallback: Ember.computed.readOnly('trip.isCallback'),
    isWaitlisted: Ember.computed.readOnly('trip.isWaitlisted'),
    //passed  by the workspace service
    tripStops: null,
    //Trips could be an array sometimes
    formattedPickTime: Ember.computed('trip.pick.plannedEta', function () {
      const pickTime = this.get('trip.pick.plannedEta');

      if (!_lodash.default.isNil(pickTime)) {
        return (0, _moment.default)(pickTime).format('MM-DD-YYYY hh:mm A');
      }
    }),
    formattedDropTime: Ember.computed('trip.drop.plannedEta', function () {
      const dropTime = this.get('trip.drop.plannedEta');

      if (!_lodash.default.isNil(dropTime)) {
        return (0, _moment.default)(dropTime).format('MM-DD-YYYY hh:mm A');
      }
    }),
    isCallbackWaitlistedAndManual: Ember.computed('isCallback', 'isManualSchedulingMode', function () {
      const isCallback = this.get('isCallback');
      const isWaitlisted = this.get('isWaitlisted');
      const isManualSchedulingMode = this.get('isManualSchedulingMode');
      return (isCallback || isWaitlisted) && isManualSchedulingMode;
    }),
    canSave: Ember.computed('selectedSolutionId', 'overrideSelectedDropOrder', 'overrideErrorText', 'overrideSelectedProvider', function () {
      const selectedSolutionId = this.get('selectedSolutionId');
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
      const previousSelectedTripProvider = this.get('previousSelectedTripProvider');
      const overrideErrorText = this.get('overrideErrorText');

      if (!(0, _lodash.isEmpty)(selectedSolutionId) && (0, _lodash.isEmpty)(overrideErrorText)) {
        return true;
      }

      if (overrideSelectedProvider && overrideSelectedProvider.get('isBrokerProviderType') || previousSelectedTripProvider && previousSelectedTripProvider.get('isBrokerProviderType') && !(0, _lodash.isEmpty)(overrideSelectedDropOrder)) {
        return true;
      }

      return false;
    }),
    canDisableOverrideRouteSectionFields: Ember.computed('isNotOverride', 'overrideSelectedProvider', function () {
      const isNotOverride = this.get('isNotOverride');
      const overrideSelectedProvider = this.get('overrideSelectedProvider');

      if (isNotOverride || overrideSelectedProvider && overrideSelectedProvider.get('isBrokerProviderType')) {
        return true;
      }

      return false;
    }),
    canDisableOverrideOrderSectionFields: Ember.computed('canDisableOverrideRouteSectionFields', 'overrideSelectedRoute', function () {
      const canDisableOverrideRouteSectionFields = this.get('canDisableOverrideRouteSectionFields');
      const overrideSelectedRoute = this.get('overrideSelectedRoute');
      return canDisableOverrideRouteSectionFields || Ember.isNone(overrideSelectedRoute);
    }),
    currentRoute: Ember.computed('trip.route', function () {
      let route = '';
      route = this.get('trip.route');

      if (!route) {
        return this.get('noRoute');
      }

      return route;
    }).readOnly(),

    async populateRoutesList(scheduleId) {
      const scheduleRecord = await this.get('store').findRecord('schedule', scheduleId, {
        include: 'routes'
      });
      const routes = scheduleRecord.get('routes').toArray();
      return routes;
    },

    overrideRouteOptions: Ember.computed('currentRoute', 'overrideSelectedProvider', async function () {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const sourceRouteId = this.get('currentRoute.id');
      const sourceTripScheduleId = this.get('trip.schedule.id');
      const routes = await this.populateRoutesList(sourceTripScheduleId);

      if (Ember.isNone(routes)) {
        return [];
      } //load routes included model records to build the data for solution preview


      await routes.reduce((acc, route) => {
        return acc.then(async () => {
          return this.get('store').findRecord('route', route.get('id'), {
            include: ROUTE_INCLUDES.join(',')
          });
        });
      }, Promise.resolve());
      return (0, _lodash.sortBy)(routes.filter(route => {
        const routeScheduleId = route.get('schedule.id');
        const routeId = route.get('id');
        let sameProvider = false;

        if (!(0, _lodash.isEmpty)(overrideSelectedProvider)) {
          sameProvider = overrideSelectedProvider.id === route.get('provider.id');
        }

        const sameSchedule = routeScheduleId === sourceTripScheduleId;
        const differentId = routeId !== sourceRouteId;
        return sameSchedule && differentId && sameProvider;
      }), route => route.name);
    }),
    overridePickOrderOptions: Ember.computed('overrideSelectedRoute', async function () {
      const overrideSelectedRoute = this.get('overrideSelectedRoute');
      const coreEntityPopulator = this.get('coreEntityPopulator');
      let routeOrdinals = [];

      if (!(0, _lodash.isEmpty)(overrideSelectedRoute)) {
        await coreEntityPopulator.populateRtsRoute(overrideSelectedRoute.id, {});
        const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
        const routeBreaks = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.routeBreaks'));
        const totalStopsWithBreakCount = trips.length * 2 + routeBreaks.length;
        routeBreaks.forEach(routeBreak => {
          routeOrdinals.push(routeBreak.get('scheduleOrdinal'));
        });
        trips.forEach(trip => {
          const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
          const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
          routeOrdinals.push(pick.get('scheduleOrdinal'), drop.get('scheduleOrdinal'));
        });
        routeOrdinals = (0, _lodash.orderBy)(routeOrdinals, ordinal => {
          return ordinal;
        }, ['asc']);
        routeOrdinals.push(totalStopsWithBreakCount + 1);

        if (routeOrdinals.length === 0) {
          routeOrdinals.push(1);
        }

        return routeOrdinals.map(ord => ord.toString());
      }

      return routeOrdinals;
    }),
    overrideDropOrderOptions: Ember.computed('overrideSelectedPickOrder', async function () {
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      let routeOrdinals = await this.get('pickRouteOrdinals');
      routeOrdinals = routeOrdinals.map(ord => parseInt(ord, 10));

      if (!(0, _lodash.isNull)(overrideSelectedPickOrder) && !(0, _lodash.isEmpty)(routeOrdinals)) {
        routeOrdinals = routeOrdinals.filter(ordinal => {
          if (ordinal > overrideSelectedPickOrder) {
            return ordinal;
          }
        });

        if (routeOrdinals.length > 0) {
          routeOrdinals.push(Math.max(...routeOrdinals) + 1);
        }

        if (routeOrdinals.length === 0) {
          return [overrideSelectedPickOrder + 1].map(ord => ord.toString());
        }

        return routeOrdinals.map(ord => ord.toString());
      }

      return [];
    }),
    isSolutionsTaskRunning: Ember.computed('fetchSolutionsTask.isRunning', 'fetchManualSolutionsTask.isRunning', function () {
      return this.get('fetchSolutionsTask.isRunning') || this.get('fetchManualSolutionsTask.isRunning');
    }),
    isSolutionsTaskCanceled: Ember.computed('fetchSolutionsTask.lastCanceled', 'fetchManualSolutionsTask.lastCanceled', function () {
      return Ember.isPresent(this.get('fetchSolutionsTask.lastCanceled')) || Ember.isPresent(this.get('fetchManualSolutionsTask.lastCanceled'));
    }),
    isNotOverride: Ember.computed('override', function () {
      return !this.get('override');
    }),
    isOverrideChecked: Ember.observer('override', function () {
      const override = this.get('override');
      const deselectSolution = this.get('deselectSolution').bind(this);

      if (override) {
        this.set('selectedSolutionId');
        this.set('selectedOverrideSolutionId');
        deselectSolution();
      }

      if (!override) this.set('overrideErrorText', '');
    }),
    currentRouteImpact: Ember.computed('currentRouteSolution', function () {
      const routeId = this.get('trip.route.id');
      const currentTripStops = this.get('trip.stops').map(stop => stop.id);
      const solutionStops = this.get('solutions') ? this.get('solutions')[0].stops : [];
      const currentStops = solutionStops.filter(stop => stop.routeId === routeId);
      const filteredCurrentStops = currentStops.filter(stop => !currentTripStops.includes(stop.id));

      if (Ember.isNone(this.get('currentRouteSolution'))) {
        const impact = Ember.Object.create({
          before: this.buildImpactStops(currentStops)
        });
        return impact;
      }

      const impact = Ember.Object.create({
        before: this.buildImpactStops(currentStops),
        after: this.buildImpactStops(filteredCurrentStops)
      }); //sorting stops by 'plannedEta'

      const impactBeforeStops = this.sortingStopsByETA(impact.before.stops);
      const impactAfterStops = this.sortingStopsByETA(impact.after.stops);
      const {
        impactBeforeWithBrStops,
        impactAfterWithBrStops
      } = this.setGarageDataAndSequenceStops(impactBeforeStops, impactAfterStops); //replacing the impact before and after stops, after adding breaks and garage stops

      impact.before.stops = impactBeforeWithBrStops;
      impact.after.stops = impactAfterWithBrStops;
      return impact;
    }),
    observeSelectImpactPeriod: Ember.observer('selectedImpactPeriod', function () {
      const period = this.get('selectedImpactPeriod');
      const selectedRouteId = this.get('activeTabRouteId'); // TODO: Can the table rows be updated without an observer?

      this.get('solutionImpactTable').setRows(this.get(`impact.${period}.stops`)); // revaluate impact

      this.chooseImpactByRoute(selectedRouteId);
    }),

    selectedRouteImpact() {
      const selectedRouteId = this.get('currentSolutionRoute.id');
      const solutions = this.get('solutions') || [];
      const selectedSolution = solutions.findBy('id', selectedRouteId) || {};
      const transferTrips = this.get('trip.stops').map(stop => stop.id);
      const filteredCurrentTripStops = selectedSolution.stops.filter(tripStop => transferTrips.includes(tripStop.id));
      const filteredSolutionTrip = selectedSolution.tripStops.filter(tripStop => !transferTrips.includes(tripStop.id));
      const filteredSelectedSolution = [...filteredCurrentTripStops, ...filteredSolutionTrip];
      const impact = Ember.Object.create({
        before: this.buildImpactStops(filteredSolutionTrip),
        after: this.buildImpactStops(filteredSelectedSolution)
      }); //sorting stops by 'plannedEta'

      const impactBeforeStops = this.sortingStopsByETA(impact.before.stops);
      const impactAfterStops = this.sortingStopsByETA(impact.after.stops);
      const {
        impactBeforeWithBrStops,
        impactAfterWithBrStops
      } = this.setGarageDataAndSequenceStops(impactBeforeStops, impactAfterStops); //replacing the impact before and after stops, after adding breaks and garage stops

      impact.before.stops = impactBeforeWithBrStops;
      impact.after.stops = impactAfterWithBrStops;
      return impact;
    },

    /**
     * NYAAR-19582: Route ID is displayed next to the Route tab in the View Impact section on selecting route
     */
    selectedSolutionRoute: Ember.computed('solutions', 'selectedSolutionId', function () {
      const solutions = this.get('solutions');
      const selectedSolutionId = this.get('selectedSolutionId'); //if there is solutions and selectedSolutionId in solution table no routeId displayed

      if ((0, _lodash.isEmpty)(solutions) || (0, _lodash.isEmpty)(selectedSolutionId)) return null;
      const route = this.get('store').peekRecord('route', selectedSolutionId);
      return route;
    }),

    init() {
      this._super(...arguments);

      const solutionImpactTable = new _emberLightTable.default(_config.solutionImpactColumns);
      const solutionPreviewTable = new _emberLightTable.default(_config.solutionColumns);
      this.set('solutionImpactTable', solutionImpactTable); // eslint-disable-next-line new-cap

      this.set('solutions', Ember.A([]));
      this.set('solutionPreviewTable', solutionPreviewTable);
      this.setOverrideProviderOptions();
    },

    setOverrideProviderOptions() {
      const overrideProviderOptions = this.get('store').peekAll('provider').filter(provider => {
        if (provider.get('isInActive')) {
          return false;
        }

        return provider.get('userCanAccessProvider');
      });
      this.set('overrideProviderOptions', overrideProviderOptions);
    },

    async didInsertElement() {
      const isManualSchedulingMode = this.get('isManualSchedulingMode');

      this._super(...arguments); // side panel width just for trip transfer


      Ember.$('.side-drawer-modal').css('min-width', '600px');
      this.loadCurrentState();
      await this.setupProvidersList();
      this.set('override', isManualSchedulingMode);

      if (!isManualSchedulingMode && (0, _moment.default)(this.get('trip.pick.plannedEta')).isAfter((0, _moment.default)())) {
        this.setupSolutions();
      }
    },

    /**
     * NYAAR-19727: sorting Stops by ETA value
     * @param {*Array} stops
     * @returns {*Array}
     */
    sortingStopsByETA(stops) {
      return stops.sort((stop1, stop2) => _moment.default.utc(stop1.plannedEta).diff(_moment.default.utc(stop2.plannedEta)));
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('overrideSelectedProvider', currentState.overrideSelectedProvider);
        this.set('overrideSelectedRoute', currentState.overrideSelectedRoute);
        this.set('overrideSelectedPickOrder', currentState.overrideSelectedPickOrder);
        this.set('overrideSelectedDropOrder', currentState.overrideSelectedDropOrder);
        this.set('solutionPreviewTable', currentState.solutionPreviewTable);
        this.set('pickTime', currentState.pickTime);
        this.set('overridePickTime', currentState.overridePickTime);
        this.set('dropTime', currentState.dropTime);
        this.set('overrideDropTime', currentState.overrideDropTime);
        this.set('override', currentState.override);

        if (currentState.currentRouteSolution) {
          this.set('currentRouteSolution', currentState.currentRouteSolution);
        }
      }
    },

    /**
     * NYAAR-20239: fetchRider record form tripStops
     */
    fetchRiderRecord: (0, _emberConcurrency.task)(function* (currentStops) {
      const store = this.get('store');
      const filteredCurrentStops = currentStops.map(stop => store.peekRecord('trip-stop', stop.id)).filter(stop => !stop.get('trip.rider.lastName') && stop.get('trip.rider.id'));
      yield Promise.all(filteredCurrentStops.map(async stop => {
        return await this.get('fetchRecord').perform('rider', stop.get('trip.rider.id'), {
          reload: true
        });
      }));
    }),

    /**
     * NYAAR-20239: filter rider from selectedRoutes
     */
    async filterRiderFromSelectedRoutes() {
      const routeId = this.get('trip.route.id');
      const selectedRouteId = this.get('currentSolutionRoute.id');
      const solutions = this.get('solutions') || [];
      const solutionStops = this.get('solutions') ? this.get('solutions')[0].stops : [];
      const currentStops = solutionStops.filter(stop => stop.routeId === routeId);
      const selectedSolution = solutions.findBy('id', selectedRouteId) || {};
      const transferTrips = this.get('trip.stops').map(stop => stop.id);
      const filteredCurrentTripStops = selectedSolution.stops.filter(tripStop => transferTrips.includes(tripStop.id));
      const filteredSolutionTrip = selectedSolution.tripStops.filter(tripStop => !transferTrips.includes(tripStop.id));
      const filteredSelectedSolution = [...filteredCurrentTripStops, ...filteredSolutionTrip, ...currentStops];
      await this.fetchRiderRecord.perform(filteredSelectedSolution);
    },

    /**
     * NYAAR-19407: to build impactStops for impact table
     * @param {Object} stop
     * @param {*Object} tripStop
     * @returns {*Object}
     */
    buildImpactStops(currentStops) {
      const store = this.get('store');
      return {
        stops: currentStops.map(stop => {
          const tripStop = store.peekRecord('trip-stop', stop.id);
          const address = tripStop.get('segmentStop.place.address');
          const segment = tripStop.get('trip.segment');
          const trip = tripStop.get('trip');
          const type = tripStop.type;
          let city = tripStop.city;

          if (Ember.isBlank(city)) {
            city = address ? address.get('subLocality') : '';
          }

          if (Ember.isBlank(city)) {
            city = address ? address.get('region') : '';
          } //NYAAR-20339-Appt time should only display for drop anchored trips (i.e, stop type is drop)


          const computedApptTime = type === DROP_STOP_TYPE && segment.get('anchor') === DROP_STOP_TYPE ? segment.get('leg.requestTime') : '';
          return _impactStop.default.create({
            id: tripStop.trip.id,
            tripId: tripStop.trip.tripId,
            polyline: null,
            mrt: null,
            plannedRouteOrdinal: stop.plannedRouteOrdinal,
            plannedEta: stop.plannedEta,
            stop: type,
            stopStatus: stop.status,
            computedPromiseTime: type === DROP_STOP_TYPE ? '' : trip.get('promiseTime'),
            computedApptTime: computedApptTime,
            lastName: trip.get('rider.lastName'),
            simpleAddress: address.get('streetNumberAndAddress'),
            city,
            tripStop,
            routeBreakData: stop.routeBreakData || []
          });
        })
      };
    },

    /**
     * NYAAR-19407: sets routeBreaks, pullOutGarage, pullInGarage and sequences the stops for impact table
     * @param {Array} impactBeforeStops
     * @param {Array} impactAfterStops
     */
    setGarageDataAndSequenceStops(impactBeforeStops, impactAfterStops) {
      const impactBeforeWithBrStops = this.setRouteBreak(impactBeforeStops);
      const impactAfterWithBrStops = this.setRouteBreak(impactAfterStops);
      this.buildPullOutGarageData(impactAfterWithBrStops);
      this.buildPullInGarageData(impactAfterWithBrStops);
      this.buildPullOutGarageData(impactBeforeWithBrStops);
      this.buildPullInGarageData(impactBeforeWithBrStops);
      this.sequencePlannedRouteOrdinal(impactBeforeWithBrStops);
      this.sequencePlannedRouteOrdinal(impactAfterWithBrStops);
      return {
        impactBeforeWithBrStops,
        impactAfterWithBrStops
      };
    },

    onTransferTrip: (0, _emberConcurrency.task)(function* () {
      const isCallbackWaitlistedAndManual = this.get('isCallbackWaitlistedAndManual');
      const schedule = this.get('overrideSelectedRoute.schedule');
      const routeId = this.get('overrideSelectedRoute.id');
      const tripId = this.get('trip.id');
      const trips = this.get('solutionImpactTable.rows') || [];
      const pickTrip = trips.find(t => t.get('id') === tripId && t.get('stop') === PICK_STOP_TYPE);
      const dropTrip = trips.find(t => t.get('id') === tripId && t.get('stop') === DROP_STOP_TYPE);
      const operationType = isCallbackWaitlistedAndManual ? 'scheduleManualTrip' : 'tripTransfer';
      let result;
      const operationData = {
        routeId
      };

      if (isCallbackWaitlistedAndManual) {
        operationData.hypo = false;
        operationData.tripId = tripId ? parseInt(tripId, 10) : null;
        operationData.pickTime = (0, _moment.default)(pickTrip.get('plannedEta')).format('YYYY-MM-DD HH:mm:ssZ');
        operationData.dropTime = (0, _moment.default)(dropTrip.get('plannedEta')).format('YYYY-MM-DD HH:mm:ssZ');
      } else {
        operationData.tripId = tripId;
        operationData.pickTime = pickTrip.get('plannedEta');
        operationData.dropTime = dropTrip.get('plannedEta');
      }

      try {
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, operationType, options);

        if (!result) {
          throw new Error('Failed to transfer');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    resetSolutionTableData() {
      // reset stops table data
      this.set('solutionImpactTable.rows', []);
    },

    calculateDrop() {
      const tripList = this.get('tripStops');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
      const overrideDropTime = this.get('overrideDropTime');
      let previousStop, dropStopIndex;

      for (let i = 0; i < tripList.length; i++) {
        const trip = tripList[i];

        if (trip.plannedRouteOrdinal >= overrideSelectedDropOrder) {
          dropStopIndex = i + 1;
          previousStop = tripList[i - 1];
          break;
        }
      }

      if ((0, _lodash.isEmpty)(previousStop)) {
        const stop = tripList[overrideSelectedDropOrder - 1];

        if (stop) {
          previousStop = stop;
          dropStopIndex = -1;
        }
      }

      return {
        dropTime: overrideDropTime,
        dropStopIndex
      };
    },

    calculatePickOrder() {
      const tripList = this.get('tripStops');
      const overridePickTime = this.get('overridePickTime');
      let pickStop;

      for (let i = 0; i < tripList.length; i++) {
        const trip = tripList[i]; // eslint-disable-next-line no-undefined

        if (trip.isBreak && (0, _moment.default)(overridePickTime).isBetween(trip.plannedEta, trip.plannedEndEta, undefined, '[)')) {
          pickStop = trip;
          break;
        }

        if ((0, _moment.default)(trip.plannedEta).isAfter(overridePickTime) || (0, _moment.default)(trip.plannedEta).isSame(overridePickTime)) {
          pickStop = trip;
          break;
        }
      }

      if ((0, _lodash.isEmpty)(pickStop)) {
        const stop = tripList[tripList.length - 1];

        if (stop) {
          const plannedEndTime = this.get('overrideSelectedRoute.plannedEndTime');
          pickStop = {
            plannedEta: plannedEndTime,
            plannedRouteOrdinal: stop.plannedRouteOrdinal + 1
          };
        }
      }

      return pickStop.plannedRouteOrdinal ? pickStop.plannedRouteOrdinal : 1;
    },

    calculateDropOrder() {
      const tripList = this.get('tripStops');
      const overrideDropTime = this.get('overrideDropTime');
      let dropStop;

      for (let i = 0; i < tripList.length; i++) {
        const trip = tripList[i];

        if ((0, _moment.default)(trip.plannedEta).isAfter(overrideDropTime) || (0, _moment.default)(trip.plannedEta).isSame(overrideDropTime)) {
          dropStop = trip;
          break;
        }
      }

      if ((0, _lodash.isEmpty)(dropStop)) {
        const stop = tripList[tripList.length - 1];

        if (stop) {
          const plannedEndTime = this.get('overrideSelectedRoute.plannedEndTime');
          dropStop = {
            plannedEta: plannedEndTime,
            plannedRouteOrdinal: stop.plannedRouteOrdinal + 1
          };
        }
      }

      return dropStop.plannedRouteOrdinal ? dropStop.plannedRouteOrdinal + 1 : 1;
    },

    getPickAndDropDataForSolutionPreview(trip, tripList) {
      const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
      const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
      const rider = (0, _unwrapProxy.unwrapProxy)(trip.get('rider'));
      const pickOrdinal = pick.get('scheduleOrdinal') || pick.get('plannedRouteOrdinal');
      const dropOrdinal = drop.get('scheduleOrdinal') || drop.get('plannedRouteOrdinal');
      const pickData = {
        tripId: trip.get('tripId'),
        plannedRouteOrdinal: pickOrdinal,
        plannedEta: pick.get('plannedEta'),
        stopType: 'pick',
        tripStop: trip.stops,
        route: trip.route,
        currentProviderName: trip.currentProviderName,
        computedPromiseTime: trip.get('promiseTime'),
        lastName: rider ? rider.get('lastName') : '',
        simpleAddress: trip.get('pick.segmentStop.place.address.streetNumberAndAddress'),
        locality: trip.get('pick.segmentStop.place.address.locality')
      };
      const dropData = {
        tripId: trip.get('tripId'),
        plannedRouteOrdinal: dropOrdinal,
        plannedEta: drop.get('plannedEta'),
        stopType: 'drop',
        tripStop: trip.stops,
        route: trip.route,
        currentProviderName: trip.currentProviderName,
        computedPromiseTime: trip.get('promiseTime'),
        lastName: rider ? rider.get('lastName') : '',
        simpleAddress: trip.get('drop.segmentStop.place.address.streetNumberAndAddress'),
        locality: trip.get('drop.segmentStop.place.address.locality')
      };
      tripList.push(pickData, dropData);
    },

    buildTripList() {
      const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
      const routeBreaks = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.routeBreaks')) || [];
      let tripList = [];
      this.buildPullOutGarageData(tripList);
      trips.forEach(trip => {
        this.getPickAndDropDataForSolutionPreview(trip, tripList);
      });
      routeBreaks.forEach(routeBreak => {
        const breakOrdinal = routeBreak.get('scheduleOrdinal') || routeBreak.get('plannedRouteOrdinal');
        const plannedEta = (0, _moment.default)(routeBreak.get('plannedEta'));
        const plannedDuration = routeBreak.get('plannedDuration');
        const plannedEndEta = plannedEta.add(plannedDuration, 'm').toDate();
        tripList.push({
          stop: routeBreak.get('type'),
          plannedEta: routeBreak.get('plannedEta'),
          plannedEndEta: plannedEndEta,
          plannedRouteOrdinal: breakOrdinal,
          isBreak: true
        });
      });
      tripList = (0, _lodash.orderBy)(tripList, trip => {
        return trip.plannedRouteOrdinal;
      }, ['asc']);
      this.set('tripStops', tripList);
    },

    setupSolutionPreview() {
      const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
      const routeSolution = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute'));
      let tripList = [];
      const currentTrip = this.get('trip');
      const currentTripRider = (0, _unwrapProxy.unwrapProxy)(currentTrip.get('rider'));
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
      trips.forEach(trip => {
        this.getPickAndDropDataForSolutionPreview(trip, tripList);
      });
      tripList = (0, _lodash.orderBy)(tripList, trip => {
        return trip.plannedRouteOrdinal;
      }, ['asc']);
      this.sequencePlannedRouteOrdinal(tripList);
      this.set('tripStops', tripList);
      const convertPickOrdinalToTimeStamp = this.get('overridePickTime');
      const pickStop = (0, _lodash.findIndex)(tripList, stop => stop.plannedRouteOrdinal === overrideSelectedPickOrder);
      const pickTripStop = currentTrip.stops.filter(stop => stop.type === PICK_STOP_TYPE);
      const newpickOne = {
        id: pickTripStop.firstObject.get('id'),
        tripId: currentTrip.get('tripId'),
        plannedRouteOrdinal: overrideSelectedPickOrder,
        plannedEta: convertPickOrdinalToTimeStamp,
        stopType: PICK_STOP_TYPE,
        tripStop: currentTrip.stops,
        route: currentTrip.route,
        trip: currentTrip,
        currentProviderName: currentTrip.currentProviderName,
        computedPromiseTime: currentTrip.get('promiseTime'),
        computedApptTime: currentTrip.get('segment.anchor') === 'drop' ? currentTrip.get('segment.leg.requestTime') : '',
        lastName: currentTripRider.get('lastName'),
        simpleAddress: currentTrip.get('pick.segmentStop.place.address.streetNumberAndAddress'),
        locality: currentTrip.get('pick.segmentStop.place.address.locality'),
        isNewTrip: true
      };

      if (pickStop !== -1) {
        tripList.splice(pickStop, 0, newpickOne);
      } else {
        tripList.pushObject(newpickOne);
      }

      this.changeOtherPlannedRouted(tripList, overrideSelectedPickOrder);
      this.set('tripStops', tripList);
      const {
        dropTime,
        dropStopIndex
      } = this.calculateDrop();
      const dropTripStop = currentTrip.stops.filter(stop => stop.type === DROP_STOP_TYPE);
      const newDropOne = {
        id: dropTripStop.firstObject.get('id'),
        tripId: currentTrip.get('tripId'),
        plannedRouteOrdinal: overrideSelectedDropOrder,
        plannedEta: dropTime,
        stopType: DROP_STOP_TYPE,
        tripStop: currentTrip.stops,
        route: currentTrip.route,
        trip: currentTrip,
        currentProviderName: currentTrip.currentProviderName,
        computedPromiseTime: currentTrip.get('promiseTime'),
        computedApptTime: currentTrip.get('segment.anchor') === 'drop' ? currentTrip.get('segment.leg.requestTime') : '',
        lastName: currentTripRider.get('lastName'),
        simpleAddress: currentTrip.get('drop.segmentStop.place.address.streetNumberAndAddress'),
        locality: currentTrip.get('drop.segmentStop.place.address.locality'),
        isNewTrip: true
      };

      if (dropStopIndex !== -1) {
        tripList.splice(dropStopIndex, 0, newDropOne);
      } else {
        tripList.pushObject(newDropOne);
      }

      const tripStops = [newpickOne, newDropOne];
      this.changeOtherPlannedRouted(tripList, overrideSelectedDropOrder);
      this.setStylesForPickAndDropRows(tripList);
      tripList = (0, _lodash.orderBy)(tripList, trip => {
        return trip.plannedRouteOrdinal;
      }, ['asc']);
      const solution = Ember.Object.create({
        id: routeSolution.get('id'),
        isNewTrip: true,
        promiseTime: currentTrip.get('promiseTime'),
        providerName: routeSolution.get('provider.id'),
        routeId: routeSolution.get('id'),
        tripId: currentTrip.get('tripId'),
        tripStops: tripStops
      });
      this.prepareOverrideSolutionPreviewTable([solution]);
    },

    populateRoute: (0, _emberConcurrency.task)(function* (currentRouteID) {
      const coreEntityPopulator = this.get('coreEntityPopulator');
      yield coreEntityPopulator.populateRtsRoute(currentRouteID, {});
    }),

    buildPullInGarageData(tripList) {
      const store = this.get('store');
      const currentRouteID = this.get('currentSolutionRoute.id');
      const pullInGarage = store.peekRecord('stop-point', `GD${currentRouteID}`);
      const pullinGarageData = {
        stop: 'pullinGarage',
        plannedEta: !pullInGarage ? '' : pullInGarage.get('stopRoute.plannedEndTime'),
        plannedRouteOrdinal: tripList.length,
        simpleAddress: !pullInGarage ? '' : pullInGarage.get('place.address.streetNumberAndAddress'),
        city: !pullInGarage ? '' : pullInGarage.get('place.address.locality')
      };
      tripList.push(pullinGarageData);
    },

    buildPullOutGarageData(tripList) {
      const store = this.get('store');
      const currentRouteID = this.get('currentSolutionRoute.id');
      const pullOutGarage = store.peekRecord('stop-point', `GP${currentRouteID}`);
      const pulloutGarageData = {
        stop: 'pulloutGarage',
        plannedRouteOrdinal: 0,
        plannedEta: !pullOutGarage ? '' : pullOutGarage.get('stopRoute.plannedStartTime'),
        simpleAddress: !pullOutGarage ? '' : pullOutGarage.get('place.address.streetNumberAndAddress'),
        city: !pullOutGarage ? '' : pullOutGarage.get('place.address.locality')
      };
      tripList.unshift(pulloutGarageData);
    },

    changeOtherPlannedRouted(trips, order) {
      let isRouteOrdinalChange = false;
      trips.forEach((trip, index) => {
        if (isRouteOrdinalChange) {
          const nextTripStop = trips[index + 1];
          trip.plannedRouteOrdinal = nextTripStop ? nextTripStop.plannedRouteOrdinal : trip.plannedRouteOrdinal + 1;
        }

        if (trip.plannedRouteOrdinal === order) {
          isRouteOrdinalChange = true;
          return false;
        }
      });
    },

    setStylesForPickAndDropRows(tripList) {
      const currentTrip = this.get('trip');
      const pickAndDropStops = tripList.filter(stop => stop.tripId === currentTrip.get('tripId')); // eslint-disable-next-line no-return-assign

      pickAndDropStops.forEach(stop => stop.selectedOrdinalRow = true);
    },

    onTransferTripSave() {
      const brokerEligible = this.get('brokerEligible');
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const isBrokerProviderType = overrideSelectedProvider ? overrideSelectedProvider.get('isBrokerProviderType') : false;
      const currentSolutionRoute = this.get('currentSolutionRoute');
      const schedule = this.get('trip.schedule') ? this.get('trip.schedule') : null;
      let tip = '';
      let successMsg = '';
      let errorMsg = '';
      const title = 'Transfer Trip';
      const tripId = this.get('trip.tripId');
      const tooltip = this.get('tooltip');
      const routeId = this.get('trip.route.id');
      const currentSelectedRouteId = currentSolutionRoute.get('id');
      const currentSelectedRouteName = currentSolutionRoute.get('name');
      const initialLoadedSolutionIds = this.get('initialLoadedSolutionIds') ? this.get('initialLoadedSolutionIds') : []; //the call must be "blockReschedule" for automatic route (or) "tripTransfer" for manual/override route

      const isinitialLoadedProviderSelected = initialLoadedSolutionIds.includes(currentSelectedRouteId);
      const performAction = isinitialLoadedProviderSelected ? this.createScheduleAsyncOperation : this.onTransferTrip;
      const operationData = {
        hypo: false,
        trips: [tripId],
        routes: [currentSelectedRouteId],
        routeUsage: 2
      };
      const options = {
        operationData
      };
      tip = `Are you sure you want to transfer trip ${tripId} ?`;
      successMsg = `Trip ${tripId} successfully transferred to route ${currentSelectedRouteName} `;
      errorMsg = `Failed to transfer Trip ${tripId}`; //NYAAR-19315-if the broker is not eligible and the current provider type is broker

      if (!brokerEligible && isBrokerProviderType) {
        tip = 'This trip will be transferred to a broker, even though the trip is not broker-eligible. Are you sure you want to proceed?';
      }

      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          await this.setBrokerEligible(brokerEligible, isBrokerProviderType, true);
          return performAction.perform(schedule, 'blockReschedule', options).then(async result => {
            if (!result) throw new Error(errorMsg);
            this.get('notifications').success(successMsg);

            if (routeId) {
              await this.get('booking').routeTripQuery(routeId);
            }

            return this.onCloseClick();
          }).catch(async err => {
            // eslint-disable-next-line no-console
            console.warn(err);
            await this.setBrokerEligible(brokerEligible, isBrokerProviderType, false);
            this.get('notifications').warning(errorMsg);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    checkValidateOrder(type) {
      const overridePickTime = this.get('overridePickTime');
      const overrideDropTime = this.get('overrideDropTime');
      this.set('overrideErrorText', '');
      let isValid = true;

      if (overridePickTime.isSameOrAfter(overrideDropTime)) {
        this.set('overrideErrorText', 'Dropoff Time must be after Pickup Time.');
        isValid = false;
      }

      if (!isValid) {
        if (type === 'pick') {
          Ember.run.schedule('afterRender', () => {
            this.set('overrideSelectedPickOrder', null);
            this.set('pickTime');
            this.set('overridePickTime');
          });
        } else {
          Ember.run.schedule('afterRender', () => {
            this.set('overrideDropTime');
            this.set('overrideSelectedDropOrder', null);
            this.set('dropTime');
          });
        }
      }

      return isValid;
    },

    /**
     * NYAAR-20299: to calculate pick and drop order
     * @param {*Array} stopsRows
     */
    calculatePickAndDropOrder(type) {
      const tripList = this.get('tripStops');
      const overrideDropTime = this.get('overrideDropTime');
      const overridePickTime = this.get('overridePickTime');
      let isValid = true;

      if (overridePickTime && overrideDropTime) {
        const pickOrder = this.calculatePickOrder(overridePickTime);
        const dropOrder = this.calculateDropOrder(overrideDropTime);
        this.set('overrideSelectedPickOrder', pickOrder);
        this.set('overrideSelectedDropOrder', dropOrder);
        isValid = this.isBreakInBetweenStops(tripList);
      }

      if (!isValid) {
        if (type === 'pick') {
          Ember.run.schedule('afterRender', () => {
            this.set('overrideSelectedPickOrder', null);
            this.set('pickTime');
            this.set('overridePickTime');
          });
        } else {
          Ember.run.schedule('afterRender', () => {
            this.set('overrideDropTime');
            this.set('overrideSelectedDropOrder', null);
            this.set('dropTime');
          });
        }
      }

      return isValid;
    },

    /**
     * NYAAR-20299: to check is there any break beteween override pick and drop
     * @param {*Array} stopRows
     * @returns {*Boolean}
     */
    isBreakInBetweenStops(stopRows) {
      let isValid = true;
      const pickOrder = this.get('overrideSelectedPickOrder');
      const dropOrder = this.get('overrideSelectedDropOrder');
      if (!pickOrder && !dropOrder) return isValid;
      if (pickOrder === dropOrder - 1) return isValid;
      this.set('overrideErrorText', '');
      let index = pickOrder;

      while (isValid && index < dropOrder) {
        // eslint-disable-next-line no-loop-func
        const stop = (0, _unwrapProxy.unwrapProxy)(stopRows.find(stopData => stopData.plannedRouteOrdinal === index));

        if (Ember.isPresent(stop) && stop.isBreak) {
          isValid = false;
          this.get('notifications').info('Invalid pick/drop order. There is a break in between');
        }

        index++;
      }

      return isValid;
    },

    convertToRegularDateTime(value) {
      const scheduleName = this.get('overrideSelectedRoute.schedule.name');
      const dateTimeMoment = (0, _moment.default)(scheduleName).startOf('day');
      const timeArray = value.masked.split(':');
      dateTimeMoment.add(timeArray[0], 'hours');
      dateTimeMoment.add(timeArray[1], 'minutes');
      return dateTimeMoment;
    },

    //NYAAR-19315-update leg.brokerEligible as true when the broker is not eligible and the current provider type is broker and the trip is successfully transferred
    async setBrokerEligible(brokerEligible, isBrokerProviderType, value) {
      if (!brokerEligible && isBrokerProviderType) {
        const leg = (0, _unwrapProxy.unwrapProxy)(this.get('trip.segment.leg'));
        leg.set('brokerEligible', value);
        await leg.save();
      }
    },

    //NYAAR-19434-stop order in solution preview table must be in sequence order
    sequencePlannedRouteOrdinal(tripList) {
      tripList.forEach((trip, index) => {
        trip.plannedRouteOrdinal = index;
      });
    },

    /**
     * sets routeBreak for stops
     * @param {*Array} tripStopList
     * @returns {*Array}
     */
    setRouteBreak(tripStopList) {
      const filteredRouteBreak = !tripStopList.length ? [] : tripStopList.filter(stop => stop.routeBreakData[0]).map(stop => stop.routeBreakData).flat();
      const selectedRouteId = this.get('selectedTableRouteId');
      const breakForRoute = filteredRouteBreak.filter(routeBreak => routeBreak.relationships.route.data.id === selectedRouteId).uniqBy('attributes.estimatedStart');
      breakForRoute.forEach(routeBreak => {
        const breakOrdinal = routeBreak.attributes.plannedRouteOrdinal;
        tripStopList.push({
          stop: 'break',
          plannedEta: routeBreak.attributes.estimatedStart,
          plannedRouteOrdinal: breakOrdinal,
          isBreak: true
        });
      });
      return this.sortingStopsByETA(tripStopList);
    },

    /**
     * NYAAR-19407: to prepare solutionReview table for Manually select a route to transfer the trip
     * @param {Object} solution
     */
    prepareOverrideSolutionPreviewTable(solution) {
      const store = this.get('store');
      const selectedRouteId = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.id'));
      const destinationSolutionRoutes = this.get('solutions') || [];
      const foundSolution = destinationSolutionRoutes.findBy('id', solution[0].id); // reset stops table data

      this.get('solutionImpactTable').setRows([]);

      if (!foundSolution) {
        destinationSolutionRoutes.pushObject(solution[0]);
        const solutionPreviewTableRow = this.get('solutionPreviewTable.rows').toArray();
        const solutionsTableRows = solution.map(_ref => {
          let {
            id,
            providerName,
            routeId
          } = _ref;
          const selectedRoute = store.peekRecord('route', id);
          const routeName = selectedRoute.get('name');
          return {
            routeName,
            providerName,
            routeId,
            id
          };
        }); //inserting new override route to top of the list, in reviewTable

        solutionPreviewTableRow.splice(0, 0, solutionsTableRows[0]);
        this.get('solutionPreviewTable').setRows(solutionPreviewTableRow);
      } else {
        const solutionPreviewTableRow = this.get('solutionPreviewTable.rows').toArray();
        const solutionIndex = destinationSolutionRoutes.findIndex(currSolution => currSolution.id === solution[0].id);
        const solutionPreviewTableRowIindex = solutionPreviewTableRow.findIndex(row => row.get('id') === solution[0].id); // replace the solution with new data

        destinationSolutionRoutes.splice(solutionIndex, 1, solution[0]);
        const solutionsTableRows = solution.map(_ref2 => {
          let {
            id,
            providerName,
            routeId
          } = _ref2;
          const selectedRoute = store.peekRecord('route', id);
          const routeName = selectedRoute.get('name');
          return {
            routeName,
            providerName,
            routeId,
            id
          };
        }); // replace the routes table rows with new solution rows

        this.get('solutionPreviewTable').removeRow(solutionPreviewTableRow[solutionPreviewTableRowIindex]);
        this.get('solutionPreviewTable').addRow(solutionsTableRows[0]);
        this.get('solutionPreviewTable').setRows(solutionPreviewTableRow); // refresh stop table with new results

        const selectedRoute = store.peekRecord('route', solution[0].id);
        this.set('currentSolutionRoute', selectedRoute);
        this.populateRoute.perform(this.get('currentSolutionRoute.id'));
      }

      if (!this.get('currentRouteSolution')) {
        const currentRouteSolution = this.get('trip.route');
        this.set('currentRouteSolution', currentRouteSolution);
      }

      this.selectSolution.perform(solution[0].id);
      this.selectTableSolutionRow(selectedRouteId);
    },

    /**
     * NYAAR-19407: sets providerList for solution review table
     */
    async setupProvidersList() {
      const isManualSchedulingMode = this.get('isManualSchedulingMode');
      const transferBrokerTripDispatch = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.transferBrokerTripDispatch, null);
      const previousSelectedTripProvider = (0, _unwrapProxy.unwrapProxy)(this.get('previousSelectedTripProvider'));
      this.set('providersList', []);
      const providers = await this.get('store').peekAll('provider').filter(provider => {
        // filter out broker providers if user does not have permission or InActive
        if (provider.get('isBrokerProviderType') && !transferBrokerTripDispatch || provider.get('isInActive')) {
          return false;
        }

        return !(0, _lodash.isEmpty)(provider.get('name')) && !(0, _lodash.isEmpty)(provider.get('id')) && provider.get('userCanAccessProvider');
      });
      const providersList = [];
      providers.forEach(provider => {
        let isChecked = false;

        if (!isManualSchedulingMode && Ember.isPresent(previousSelectedTripProvider) && provider.get('id') === previousSelectedTripProvider.get('id')) {
          isChecked = true;
        }

        providersList.push({
          id: provider.get('id'),
          name: provider.get('providerName'),
          isChecked: isChecked
        });
      });
      this.set('providersList', providersList);
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    /**
     * NYAAR-19407: sets up solutions, When the panel is first loaded
     */
    setupSolutions() {
      this.set('errorText');
      const trip = this.get('trip');
      const currentState = this.get('currentState');
      let currentSolutionRouteId = null;

      if (currentState) {
        currentSolutionRouteId = currentState.currentSolutionRoute ? currentState.currentSolutionRoute.get('id') : null;
      }

      this.cursorWait();

      const fetchOrRestoreTask = async () => {
        if (currentState) {
          const restoreSolutions = this.get('currentState.solutions'); // after restore currentState solutions, remove the cache.

          this.set('currentState', null);
          return restoreSolutions;
        }

        return await this.get('fetchSolutionsTask').perform(trip);
      };

      fetchOrRestoreTask().catch(err => {
        // if the side drawer have been close while doing fetch
        if (this.get('isSolutionsTaskCanceled')) return null; // Try to be as robust as possible.  Log an error to console for discovery,
        // but just use an empty solution set to unblock manually transferring

        if (!(0, _lodash.isEmpty)(err) && !(0, _lodash.isEmpty)(err.payload)) {
          const reason = this.translate(err.payload.message, err.payload.detail);
          this.set('errorText', `* ${reason}`);
        } else {
          this.set('errorText', 'The system encountered an error, please try again. If the problem persists contact administrator.');
        }

        console.warn(`Unable to fetch trip transfer solutions: ${JSON.stringify(err)}`); // eslint-disable-line no-console

        return null;
      }).then(solutions => {
        // reset stops table data
        this.get('solutionImpactTable').setRows([]);
        const providerSelectedHash = this.get('providersList').reduce((hash, p) => {
          hash[p.id] = p.isChecked;
          return hash;
        }, {});
        const solutionsFilteredByProvider = solutions.filter(s => providerSelectedHash[s.providerName]); // give it a bit of time for everything to get loaded to ember store

        Ember.run.later(() => {
          this.prepareSolutionPreviewTable(solutionsFilteredByProvider, currentSolutionRouteId);
        }, 500);
        this.cursorDefault();
      }).catch(() => {
        this.get('notifications').info(`Trip ${trip.get('tripId')} cannot be transferred`);
        this.cursorDefault();
        return this.onCloseClick();
      });
      this.fetchRouteOverrideOptions();
    },

    /**
     * NYAAR-19407: prepare solution review table, When the panel is first loaded
     * @param {Array} solutions
     * @param {*Number} currentSolutionRouteId
     */
    prepareSolutionPreviewTable(solutions, currentSolutionRouteId) {
      const store = this.get('store');
      let solutionsTableRows = [];

      if (!(0, _lodash.isEmpty)(solutions)) {
        solutionsTableRows = solutions.map(_ref3 => {
          let {
            id,
            providerName,
            routeId
          } = _ref3;
          const selectedRoute = store.peekRecord('route', id);
          const routeName = selectedRoute.get('name');
          return {
            routeName,
            providerName,
            routeId,
            id
          };
        });
        solutions.forEach(solution => {
          const index = this.get('solutions').findIndex(s => s.id === solution.id);

          if (index === -1) {
            this.get('solutions').pushObject(solution);
          } else {
            this.get('solutions').splice(index, 1, solution);
          }
        });
      }

      this.get('solutionPreviewTable').setRows(solutionsTableRows); //from initially loaded solution the row ids are selected, for performing transfer route by calling 'blockReschedule'

      this.set('initialLoadedSolutionIds', solutionsTableRows.map(row => row.id));

      if (!(0, _lodash.isEmpty)(solutions)) {
        const solutionFound = currentSolutionRouteId ? solutions.find(solution => solution.id === currentSolutionRouteId) : null;
        const firstSolutionId = solutionFound ? solutionFound.id : solutions[0].routeId;
        this.selectTableSolutionRow(firstSolutionId);
        this.selectSolution.perform(firstSolutionId);
      }
    },

    /**
     * NYAAR-19407: fetch solutions for table, when the panel is first loaded
     */
    fetchSolutionsTask: (0, _emberConcurrency.task)(function* (trip) {
      const schedule = trip.get('schedule') ? trip.get('schedule') : null;
      const tripId = trip.get('id') ? parseInt(trip.get('id'), 10) : null;
      const operationData = {
        hypo: true,
        trips: [tripId]
      };
      const options = {
        operationData,
        outputResult: true
      };
      const {
        jsonData
      } = yield this.createScheduleAsyncOperation.perform(schedule, 'blockReschedule', options);
      const result = jsonData;

      if (result.attributes.state === 'failure') {
        throw result;
      }

      if (!(0, _lodash.isEmpty)(result.attributes)) {
        return this.prepareSolutionData(result);
      }
    }).keepLatest(),

    /**
     * NYAAR-19407: prepare solutions data, when the panel is first loaded
     */
    prepareSolutionData(result) {
      const store = this.get('store');
      const resultData = JSON.parse(result.attributes.results).trips.data;
      const routeBreakData = JSON.parse(result.attributes.results).routeBreaks.data;
      const resultInculudes = JSON.parse(result.attributes.results).trips.included;
      const promiseTime = resultData.firstObject.attributes.promiseTime;
      const tripStops = resultInculudes.filter(include => include.type === 'tripStop');
      const currentTrip = this.get('trip');
      const currentTripRider = currentTrip.get('rider');
      const currentRouteSolution = this.get('trip.route');
      const stops = [];
      this.set('currentRouteSolution', currentRouteSolution);
      tripStops.forEach(stop => {
        let riderId = '';
        const tripStop = store.peekRecord('tripStop', stop.id);
        const routeId = tripStop ? tripStop.get('route.id') : '';
        if (tripStop) riderId = tripStop.get('trip.rider.id');
        stops.push({
          id: stop.id,
          plannedRouteOrdinal: stop.attributes.plannedRouteOrdinal,
          plannedEta: stop.attributes.plannedEta,
          trip: currentTrip,
          computedPromiseTime: promiseTime,
          computedApptTime: currentTrip.get('segment.anchor') === DROP_STOP_TYPE ? currentTrip.get('segment.leg.requestTime') : '',
          lastName: currentTripRider.get('lastName'),
          simpleAddress: currentTrip.get('pick.segmentStop.place.address.streetNumberAndAddress'),
          locality: currentTrip.get('pick.segmentStop.place.address.locality'),
          status: stop.attributes.hasOwnProperty('dropStatus') ? stop.attributes.dropStatus : stop.attributes.pickStatus,
          routeId,
          routeBreakData,
          riderId
        });
      });
      const groupedStops = this.prepareGroupStops(resultData, stops);
      return this.prepareTripSolutionData(resultData, groupedStops, promiseTime, stops);
    },

    async prepareTripSolutionData(resultData, groupedStops, promiseTime, tripStops) {
      const store = this.get('store');
      const currentTripRouteId = this.get('trip.route.id');
      const filterdRouteTableResult = [];
      const transferTripPayloadResult = [];
      const filteredCurrentStops = tripStops.filter(stop => stop.riderId);
      await Promise.all(filteredCurrentStops.map(async stop => {
        return await this.get('fetchRecord').perform('rider', stop.riderId, {
          reload: true
        });
      }));
      resultData.forEach(trip => {
        const route = store.peekRecord('route', trip.relationships.route.data.id);
        transferTripPayloadResult.push(trip);

        if (currentTripRouteId !== route.id) {
          transferTripPayloadResult.pop();
          filterdRouteTableResult.push(trip);
        }
      });
      this.set('transferTripPayloadResult', transferTripPayloadResult);
      return filterdRouteTableResult.map(tripData => {
        const currentProvider = store.peekRecord('provider', tripData.relationships.currentProviderName.data.id);
        const tripRouteId = tripData.relationships.route.data.id;
        const stops = groupedStops[tripRouteId];
        return {
          id: tripRouteId,
          tripId: tripData.id,
          tripStops: stops || [],
          promiseTime,
          stops: tripStops,
          routeId: tripRouteId,
          providerName: currentProvider.id,
          isNewTrip: true
        };
      }).uniqBy('id');
    },

    /**
     * NYAAR-19690: prepare GroupStops from payloadData
     * @param {Array} resultData
     * @param {Array} stops
     * @returns
     */
    prepareGroupStops(resultData, stops) {
      const groupedStops = {};
      const pickDropObj = {};
      resultData.forEach(data => {
        const routeId = data.relationships.route.data.id;

        if (!pickDropObj[routeId]) {
          pickDropObj[routeId] = [data.relationships.drop.data.id, data.relationships.pick.data.id];
        } else {
          pickDropObj[routeId].push(data.relationships.drop.data.id, data.relationships.pick.data.id);
        }
      });
      const keyValuePair = Object.entries(pickDropObj);
      keyValuePair.forEach(keyValue => {
        const key = keyValue[0];
        const stopArr = [];
        keyValue[1].forEach(value => stopArr.push(...stops.filter(stop => stop.id === value)));
        groupedStops[key] = stopArr;
      });
      return groupedStops;
    },

    /**
     * NYAAR-19407: select solution for impact table
     */
    selectSolution: (0, _emberConcurrency.task)(function* (routeId) {
      const selectedRoute = this.get('store').peekRecord('route', routeId);
      this.setProperties({
        selectedSolutionId: routeId,
        selectedImpactPeriod: 'after',
        activeTabRouteId: routeId,
        currentSolutionRoute: selectedRoute
      });
      yield this.populateRoute.perform(this.get('currentSolutionRoute.id'));
      this.chooseImpactByRoute(routeId);
    }),

    /**
     * NYAAR-19407: select solution for impact table
     * @param {Number} routeId
     */
    selectTableSolutionRow(routeId) {
      const rows = this.get('solutionPreviewTable.rows');
      const currentSelectedRow = rows.findBy('selected');
      const rowToSelect = rows.findBy('id', routeId);
      if (rowToSelect === currentSelectedRow) return;

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
      }

      rowToSelect.set('selected', true);
      this.selectSolution.perform(routeId);
    },

    unSelectTableSolutionRow() {
      const rows = this.get('solutionPreviewTable.rows');
      const currentSelectedRow = rows.findBy('selected');
      this.set('selectedSolutionId');
      this.set('selectedOverrideSolutionId');

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
      }
    },

    /**
     * NYAAR-19407: choose Impacts (currentRouteImpact/selectedRouteImpact) in impact table
     * @param {Number} routeId
     * @returns {Object}
     */
    async chooseImpactByRoute(routeId) {
      const solutions = this.get('solutions');
      const period = this.get('selectedImpactPeriod');
      const currentRoute = this.get('currentRoute');
      const currentRouteSolution = this.get('currentRouteSolution');
      const currentTripId = this.trip.tripId;
      const selectedRouteImpact = this.get('selectedRouteImpact').bind(this);
      this.set('selectedTableRouteId', routeId);
      let selectedSolution = null;
      let impact = null; // Handle choosing the current route

      await this.filterRiderFromSelectedRoutes();

      if (!Ember.isNone(currentRoute) && routeId === currentRoute.get('id')) {
        selectedSolution = currentRouteSolution;

        if ((0, _lodash.isEmpty)(selectedSolution)) {
          if (this.get('trip.status') === 'waitlisted' && period === 'before') {
            this.get('solutionImpactTable').setRows(this.get('trip.stops'));
            return;
          }

          this.get('solutionImpactTable').setRows([]);
        }

        impact = this.get('currentRouteImpact');
      } else {
        selectedSolution = solutions.findBy('id', routeId);

        if ((0, _lodash.isEmpty)(selectedSolution)) {
          this.set('impact', impact);
          this.get('solutionImpactTable').setRows([]);
          return impact;
        }

        impact = selectedRouteImpact();
      }

      this.set('impact', impact);
      const stopsRows = impact[period] ? impact[period].stops : [];
      const rows = this.get('solutionImpactTable').setRows(stopsRows);
      rows.forEach(stop => {
        if (!Ember.isNone(stop.get('tripStop')) && stop.get('tripStop.trip.tripId') === currentTripId) {
          stop.set('selected', true);
        } //adding redRow class to row for showing ETA value in impact table related to lateness flag


        if (stop.get('stopStatus') === 1) {
          stop.set('classNames', 'redRow');
        }
      });
      return impact;
    },

    fetchRouteOverrideOptions() {
      const sourceRouteIdValue = this.get('currentRoute.id');
      const sourceRouteScheduleIdValue = this.get('currentRoute.schedule.id');
      if (!sourceRouteIdValue) return null;
      const store = this.get('store');
      const routes = store.peekAll('route').filter(route => {
        const routeScheduleIdValue = route.get('schedule.id');
        const routeIdValue = route.get('id');
        const sameSchedule = routeScheduleIdValue === sourceRouteScheduleIdValue;
        const differentId = routeIdValue !== sourceRouteIdValue;
        return sameSchedule && differentId;
      });
      this.set('routeOptions', routes);
      return routes;
    },

    selectOverrideSolution() {
      const route = this.get('overrideSelectedRoute');
      const previousSelectedTripProvider = this.get('previousSelectedTripProvider');
      const routeId = route.get('id');
      const routeName = route.get('name');
      const trip = this.get('trip');
      const currentSelectedRow = this.get('solutionPreviewTable.rows').findBy('selected');
      const store = this.get('store');
      const pickTime = (0, _moment.default)(this.get('overridePickTime')).format('YYYY-MM-DD HH:mm:ssZ');
      const dropTime = (0, _moment.default)(this.get('overrideDropTime')).format('YYYY-MM-DD HH:mm:ssZ'); // clear error message

      this.set('errorText');

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
        this.set('selectedSolutionId');
      }

      if (previousSelectedTripProvider.get('isBrokerProviderType')) {
        const selectedRoute = store.peekRecord('route', routeId);
        this.set('currentSolutionRoute', selectedRoute);
        this.populateRoute.perform(this.get('currentSolutionRoute.id'));
        return;
      }

      this.cursorWait();
      this.get('fetchManualSolutionsTask').perform(trip, pickTime, dropTime, routeId).then(solutionData => {
        // reset stops table data
        this.get('solutionImpactTable').setRows([]); // give it a bit of time for everything to get loaded to ember store

        Ember.run.later(() => {
          this.prepareOverrideSolutionPreviewTable(solutionData);
        }, 500);
        this.cursorDefault();
      }).catch(err => {
        this.catchBlockManualSolutionsTask(err, routeId);
        this.get('notifications').warning(`Trip ${trip.get('tripId')} cannot be transferred to route ${routeName}`);
        this.cursorDefault();
      });
    },

    /**
     * @param {Object} err
     * @param {Number} routeId
     * @returns {String}
     */
    catchBlockManualSolutionsTask(err, routeId) {
      // if the side drawer have been close while doing fetch
      if (this.get('isSolutionsTaskCanceled')) return null; // Try to be as robust as possible.  Log an error to console for discovery,
      // but just use an empty solution set to unblock manually transferring

      console.warn(`Unable to fetch trip transfer solutions: ${JSON.stringify(err)}`); // eslint-disable-line no-console

      if (!(0, _lodash.isEmpty)(err) && !(0, _lodash.isEmpty)(err.payload)) {
        if (err.payload.message) {
          const reason = this.translate(err.payload.message, err.payload.detail);

          if (err.payload.message === `Route ${routeId} does not exist`) {
            this.set('errorText', 'The selected route is not available, please try again in a few minutes. If the problem persist contact an administrator.');
          } else {
            this.set('errorText', `* ${reason}`);
          }
        } else if (err.payload.detail) {
          this.set('errorText', `* ${err.payload.detail}`);
        } else {
          this.set('errorText', '* Error getting hypo trip transfer.');
        }
      }

      this.cursorDefault();
      return null;
    },

    /**
     * NYAAR-19407: fetch ManualSolution for overrider selected route
     */
    fetchManualSolutionsTask: (0, _emberConcurrency.task)(function* (trip, pickTime, dropTime, routeId) {
      const isCallbackWaitlistedAndManual = this.get('isCallbackWaitlistedAndManual');
      const schedule = trip.get('schedule') ? trip.get('schedule') : null;
      const tripId = trip.get('id') ? parseInt(trip.get('id'), 10) : null;
      const operationData = {
        hypo: true,
        tripId: tripId,
        routeId,
        pickTime,
        dropTime
      };
      const options = {
        operationData,
        outputResult: true
      };
      const operationType = isCallbackWaitlistedAndManual ? 'scheduleManualTrip' : 'tripTransfer';
      const {
        jsonData
      } = yield this.createScheduleAsyncOperation.perform(schedule, operationType, options);
      const result = jsonData;

      if (result.attributes.state === 'failure') {
        throw result.attributes.results;
      }

      if (!(0, _lodash.isEmpty)(result.attributes)) {
        return this.prepareOverrideSolutionData(result);
      }
    }).keepLatest(),

    /**
     * NYAAR-19407: for prepare overrider solutionData
     * @param {Object} result
     * @returns {Object}
     */
    prepareOverrideSolutionData(result) {
      const store = this.get('store');
      const resultData = JSON.parse(result.attributes.results).trips.data;
      const routeBreakData = JSON.parse(result.attributes.results).routeBreaks.data;
      const resultInculudes = JSON.parse(result.attributes.results).trips.included;
      const promiseTime = resultData.firstObject.attributes.promiseTime;
      const tripStops = resultInculudes.filter(include => include.type === 'tripStop');
      const currentTrip = this.get('trip');
      const currentTripRider = currentTrip.get('rider');
      const currentRouteSolution = this.get('trip.route');
      const stops = [];
      this.set('currentRouteSolution', currentRouteSolution);
      tripStops.forEach(stop => {
        let riderId = '';
        const tripStop = store.peekRecord('tripStop', stop.id);
        const routeId = tripStop ? tripStop.get('route.id') : '';
        const plannedEta = stop.attributes.plannedEta;
        if (tripStop) riderId = tripStop.get('trip.rider.id');
        stops.push({
          id: stop.id,
          plannedRouteOrdinal: stop.attributes.plannedRouteOrdinal,
          plannedEta,
          trip: currentTrip,
          computedPromiseTime: promiseTime,
          computedApptTime: currentTrip.get('segment.anchor') === DROP_STOP_TYPE ? currentTrip.get('segment.leg.requestTime') : '',
          lastName: currentTripRider.get('lastName'),
          simpleAddress: currentTrip.get('pick.segmentStop.place.address.streetNumberAndAddress'),
          locality: currentTrip.get('pick.segmentStop.place.address.locality'),
          status: stop.attributes.hasOwnProperty('dropStatus') ? stop.attributes.dropStatus : stop.attributes.pickStatus,
          routeId,
          routeBreakData,
          riderId
        });
      });
      const groupedStops = this.prepareGroupStops(resultData, stops);
      return this.prepareTripSolutionData(resultData, groupedStops, promiseTime, stops);
    },

    fetchRecord: (0, _emberConcurrency.task)(function* (model, riderId, include) {
      yield this.get('store').findRecord(model, riderId, include);
    }),

    deselectSolution() {
      const currentSelectedRow = this.get('solutionPreviewTable.rows').findBy('selected');

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
        this.set('selectedSolutionId');
      }

      this.set('overrideErrorText', '');
    },

    actions: {
      onSaveClick() {
        this.onTransferTripSave();
      },

      onRefreshClick() {
        const isManualSchedulingMode = this.get('isManualSchedulingMode');
        this.set('overrideSelectedProvider', null);
        this.set('overrideSelectedRoute', null);
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.set('pickTime');
        this.set('overridePickTime');
        this.set('dropTime');
        this.set('overrideDropTime');
        this.set('override', isManualSchedulingMode); // eslint-disable-next-line new-cap

        this.set('solutions', Ember.A([]));
        this.get('solutionImpactTable').setRows([]);
        this.get('solutionPreviewTable').setRows([]);
        this.setupSolutions();
      },

      onShrinkClick() {
        let currentState = this.get('currentState'); // const bulkTripTransfer = this.get('bulkTripTransfer');

        const overrideSelectedProvider = this.get('overrideSelectedProvider');
        const overrideSelectedRoute = this.get('overrideSelectedRoute');
        const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
        const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
        const solutionPreviewTable = this.get('solutionPreviewTable');
        const pickTime = this.get('pickTime');
        const overridePickTime = this.get('overridePickTime');
        const dropTime = this.get('dropTime');
        const overrideDropTime = this.get('overrideDropTime');
        const solutions = this.get('solutions');
        const solutionImpactTable = this.get('solutionImpactTable');
        const currentRouteSolution = this.get('currentRouteSolution');
        const override = this.get('override'); // save current manual settings

        currentState = {
          overrideSelectedProvider,
          overrideSelectedRoute,
          overrideSelectedPickOrder,
          overrideSelectedDropOrder,
          pickTime,
          overridePickTime,
          dropTime,
          overrideDropTime,
          solutionPreviewTable,
          solutions,
          solutionImpactTable,
          currentRouteSolution,
          override
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onChangeProvider(provider) {
        // clear selected route, pick order and drop order
        this.set('overrideSelectedRoute', null);
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.set('pickTime');
        this.set('overridePickTime');
        this.set('dropTime');
        this.set('overrideDropTime');
        this.resetSolutionTableData();
        this.unSelectTableSolutionRow();
        this.set('overrideSelectedProvider', provider);
      },

      onChangeRoute(route) {
        // clear selected pick order and drop time
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.set('pickTime');
        this.set('overridePickTime');
        this.set('dropTime');
        this.set('overrideDropTime');
        this.resetSolutionTableData();
        this.unSelectTableSolutionRow();
        this.set('overrideSelectedRoute', route);
        this.set('dropTime');
        this.set('overrideDropTime');
      },

      onChangePickTime(value) {
        const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
        const deselectSolution = this.get('deselectSolution').bind(this);
        let isValid = false;
        this.set('pickTime', value.unmasked);

        if (value.unmasked.length < 4) {
          this.set('overridePickTime');
          this.set('overrideSelectedPickOrder');
          deselectSolution();
          return;
        }

        const pickTimeMoment = this.convertToRegularDateTime(value);
        this.set('overridePickTime', pickTimeMoment);
        this.buildTripList();
        const pickOrder = this.calculatePickOrder(pickTimeMoment);
        this.set('overrideSelectedPickOrder', pickOrder);
        this.resetSolutionTableData();
        this.unSelectTableSolutionRow();

        if (overrideSelectedDropOrder) {
          isValid = this.checkValidateOrder('pick') && this.calculatePickAndDropOrder('pick');
          if (!isValid) return;
          this.selectOverrideSolution();
        }
      },

      onChangeDropTime(value) {
        const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
        const deselectSolution = this.get('deselectSolution').bind(this);
        let isValid = false;
        this.set('dropTime', value.unmasked);

        if (value.unmasked.length < 4) {
          this.set('overrideDropTime');
          this.set('overrideSelectedDropOrder');
          deselectSolution();
          return;
        }

        const dropTimeMoment = this.convertToRegularDateTime(value);
        this.set('overrideDropTime', dropTimeMoment);
        this.buildTripList();
        const dropOrder = this.calculateDropOrder(dropTimeMoment);
        this.set('overrideSelectedDropOrder', dropOrder);
        this.resetSolutionTableData();
        this.unSelectTableSolutionRow();

        if (overrideSelectedPickOrder) {
          isValid = this.checkValidateOrder('drop') && this.calculatePickAndDropOrder('drop');
          if (!isValid) return;
          this.selectOverrideSolution();
        }
      },

      onSolutionRowClick(row
      /*, event */
      ) {
        const routeId = row.get('routeId'); // Reset the override

        this.set('selectedOverrideSolutionId');
        this.selectTableSolutionRow(routeId);
      },

      selectSolutionRouteTab() {
        const selectedRouteId = this.get('selectedSolutionId');
        this.set('selectedImpactView', 'destination');
        this.set('activeTabRouteId', selectedRouteId);
        this.chooseImpactByRoute(selectedRouteId);
      },

      selectCurrentRouteTab() {
        const selectedRouteId = this.get('currentRoute.id');
        this.set('selectedImpactView', 'current');
        this.set('activeTabRouteId', selectedRouteId);
        this.chooseImpactByRoute(selectedRouteId);
      },

      refreshProviderAutoTransfer() {
        const isManualSchedulingMode = this.get('isManualSchedulingMode');
        this.setProperties({
          override: isManualSchedulingMode,
          overrideSelectedProvider: null,
          overrideSelectedRoute: null,
          overrideSelectedPickOrder: null,
          overrideSelectedDropOrder: null,
          currentSolutionRoute: null,
          selectedSolutionId: null,
          dropTime: null,
          pickTime: null
        }); // eslint-disable-next-line new-cap

        this.set('solutions', Ember.A([]));
        this.get('solutionImpactTable').setRows([]);
        this.get('solutionPreviewTable').setRows([]);

        if ((0, _moment.default)(this.get('trip.pick.plannedEta')).isAfter((0, _moment.default)())) {
          this.setupSolutions();
        }
      }

    }
  });

  _exports.default = _default;
});