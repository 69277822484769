define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-suspensions/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'driver-suspension',
    title: 'Suspensions',
    defaultSortId: 'id',
    defaultSortAsc: true,
    selectFirstRow: true,
    tooltipTitle: 'Manage Driver',
    queryModelData: true,
    columns: [{
      id: 'startDate',
      index: 1,
      type: 'date',
      label: 'Start Date',
      valuePath: 'startDate',
      defaultWidth: 70,
      searchable: true,
      format: 'MM/DD/YYYY'
    }, {
      id: 'endDate',
      index: 2,
      type: 'date',
      label: 'End Date',
      valuePath: 'endDate',
      defaultWidth: 70,
      searchable: true,
      format: 'MM/DD/YYYY'
    }, {
      id: 'description',
      index: 3,
      type: 'text',
      label: 'Description',
      valuePath: 'description',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'notes',
      index: 4,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'associatedViolation',
      index: 5,
      type: 'boolean',
      label: 'Associated Violation',
      valuePath: 'hasViolation',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'violationId',
      index: 6,
      type: 'text',
      label: 'Violation ID',
      valuePath: 'driverViolation.id',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'incidentDate',
      index: 7,
      type: 'date',
      label: 'Incident Date',
      valuePath: 'driverViolation.incidentDate',
      defaultWidth: 100,
      searchable: true,
      format: 'MM/DD/YYYY'
    }, {
      id: 'incidentDescription',
      index: 8,
      type: 'text',
      label: 'Incident Description',
      valuePath: 'driverViolation.description',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});