define("adept-iq/pods/components/side-drawer/bulk-trip-transfer/component", ["exports", "ember-light-table", "adept-iq/utils/unwrapProxy", "ember-concurrency", "adept-iq/pods/components/side-drawer/bulk-trip-transfer/config", "moment", "adept-iq/classes/map-contexts/side-drawers/trip-transfer", "adept-iq/classes/impact-stop", "lodash"], function (_exports, _emberLightTable, _unwrapProxy, _emberConcurrency, _config, _moment, _tripTransfer, _impactStop, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ACTIVE_STATUS = 'active';

  var _default = Ember.Component.extend({
    tagName: '',
    map: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    coreEntityPopulator: Ember.inject.service(),
    // passed by the side drawer container component
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    // component properties
    solutionRoutesTable: null,
    stopsTable: null,
    routeOverrideOptions: null,
    overrideSelectedRoute: null,
    overrideSelectedProvider: null,
    overrideSelectedPickOrder: null,
    overrideSelectedDropOrder: null,
    errorText: null,
    solutions: null,
    impact: null,
    selectedSolutionId: null,
    selectedOverrideSolutionId: null,
    selectedImpactPeriod: 'after',
    activeTabRouteId: null,
    currentSolutionDispatchRoute: null,
    override: false,
    title: 'Bulk Transfer Trip',
    rowComponent: 'table/rows/colored-row',
    canShrink: Ember.computed.not('isSolutionsTaskRunning'),
    //passed  by the workspace service
    trips: Ember.computed('stackFrame.options.trips', function () {
      const trips = this.get('stackFrame.options.trips');
      const sortedTrips = trips.toSorted((a, b) => a.get('promiseTime') && b.get('promiseTime') ? (0, _moment.default)(a.get('promiseTime')).diff((0, _moment.default)(b.get('promiseTime'))) : 0);
      return sortedTrips;
    }),
    //Array of Trips
    currentDispatchRoute: Ember.computed.readOnly('currentRoute.dispatchRoute'),
    currentSolutionRoute: Ember.computed.readOnly('currentSolutionDispatchRoute.route'),
    overrideSelectedDispatchRoute: Ember.computed.readOnly('overrideSelectedRoute.dispatchRoute'),
    isNotOverride: Ember.computed('override', function () {
      return !this.get('override');
    }),
    canSave: Ember.computed('selectedSolutionId', function () {
      const selectedSolutionId = this.get('selectedSolutionId');

      if (!Ember.isEmpty(selectedSolutionId)) {
        return true;
      }

      return false;
    }),
    isSolutionsTaskRunning: Ember.computed('fetchSolutionsTask.isRunning', 'fetchManualSolutionsTask.isRunning', function () {
      return this.get('fetchSolutionsTask.isRunning') || this.get('fetchManualSolutionsTask.isRunning');
    }),
    currentRoute: Ember.computed('trips.@each.pickStopPoint.dispatchRoute.route', function () {
      const route = this.get('trips.firstObject.pickStopPoint.dispatchRoute.route');

      if (!route) {
        return this.get('noRoute');
      }

      return route;
    }).readOnly(),

    init() {
      this._super(...arguments);

      const solutionRoutesTable = new _emberLightTable.default(_config.solutionColumns);
      const stopsTable = new _emberLightTable.default(_config.stopColumns);
      const map = this.get('map');
      this.set('solutionRoutesTable', solutionRoutesTable); // eslint-disable-next-line new-cap

      this.set('solutions', Ember.A([]));
      this.set('stopsTable', stopsTable); // run-time bindings; this avoids having a ton of observers

      const mapContext = _tripTransfer.default.extend({
        trip: Ember.computed.readOnly('_component.trips'),
        destinationIqRoute: Ember.computed.readOnly('_component.selectedSolutionDispatchRoute'),
        currentIqRoute: Ember.computed.readOnly('_component.currentDispatchRoute'),
        impactPayload: Ember.computed.readOnly('_component.impact'),
        inDanger: Ember.computed.readOnly('_component.inDanger'),
        selectedImpactPeriod: Ember.computed.readOnly('_component.selectedImpactPeriod'),
        // 'before' or 'after',
        selectedImpactView: Ember.computed.readOnly('_component.selectedImpactView'),
        // 'current' or 'destination'
        map: map
      }).create({
        _component: this
      });

      this.set('mapContext', mapContext);
    },

    didInsertElement() {
      this._super(...arguments);

      const mapContext = this.get('mapContext'); // side panel width just for trip transfer

      this.setSideDrawerWidth('600px');
      this.get('map').pushContext(mapContext);
      this.loadCurrentState(); // skip auto trip transfer if the depart time is earlier than now.

      const isValid = this.get('trips').every(item => {
        return (0, _moment.default)(item.get('pickStopPoint.departTime')).isAfter((0, _moment.default)());
      });
      const overrideSelectedRoute = this.get('overrideSelectedRoute');

      if (isValid) {
        this.setupSolutions();
      } else if (Ember.isPresent(overrideSelectedRoute)) {
        this.selectOverrideSolution(overrideSelectedRoute);
      }
    },

    willDestroyElement() {
      const mapContext = this.get('mapContext');
      this.get('map').removeContext(mapContext);

      this._super(...arguments);
    },

    destroy() {
      this.get('mapContext').set('_component', null);

      this._super(...arguments);
    },

    isFoundSolution(route) {
      const solutions = this.get('solutions');
      const isFound = solutions.findBy('id', route.get('dispatchRoute.id'));
      return Ember.isPresent(isFound);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('override', currentState.override);
        this.set('overrideSelectedProvider', currentState.overrideSelectedProvider);
        this.set('overrideSelectedRoute', currentState.overrideSelectedRoute);
        this.set('overrideSelectedPickOrder', currentState.overrideSelectedPickOrder);
        this.set('overrideSelectedDropOrder', currentState.overrideSelectedDropOrder);

        if (currentState.currentRouteSolution) {
          this.set('currentRouteSolution', currentState.currentRouteSolution);
        }
      }
    },

    // creates impact after payload
    createManualSolution(route) {
      let trip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      const dsTrip = this.get('trips');
      let dsRouteId = null;
      let stops = [];

      if (Ember.isPresent(route)) {
        dsRouteId = route.get('dispatchRoute.id');
        stops = route.get('dispatchRoute.orderedStopsWithBreaks');
      } // current route impact after remove transferrable trip


      if (dsTrip.get('pickStopPoint.dispatchRoute.id') === dsRouteId) {
        stops = stops.filter(stop => stop.get('trip.id') !== dsTrip.get('id'));
      }

      const newSolution = {
        id: dsRouteId,
        gain: 'unknown',
        otp: {
          delta: 'unknown',
          originalValue: 'unknown',
          newValue: 'unknown'
        },
        stops: [],
        recipe: null
      };

      if (!Ember.isEmpty(stops)) {
        stops.forEach(stop => {
          newSolution.stops.push({
            eta: {
              originalValue: null,
              newValue: null
            },
            otp: {
              originalValue: null,
              newValue: null,
              delta: null
            },
            promiseTime: null,
            id: stop.get('id'),
            polyline: null
          });
        });
      }

      if (trip) {
        const pick = {
          eta: {
            originalValue: null,
            newValue: null
          },
          otp: {
            originalValue: null,
            newValue: null,
            delta: null
          },
          promiseTime: null,
          id: trip.get('pickStopPoint.id'),
          polyline: null
        };
        const drop = {
          eta: {
            originalValue: null,
            newValue: null
          },
          otp: {
            originalValue: null,
            newValue: null,
            delta: null
          },
          promiseTime: null,
          id: trip.get('dropStopPoint.id'),
          polyline: null
        };
        newSolution.stops.push(pick);
        newSolution.stops.push(drop);
        newSolution.recipe = this.createManualRecipe([pick, drop], route, trip);
      }

      return newSolution;
    },

    createManualRecipe(stops, route, trip) {
      // last stop ordinal needs to be at least greater than last actual stop ordinal, it doesn't matter as longer as its bigger
      // cos will be able to normalize it and put it at the end
      const lastStopOrdinal = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks.length') + 100;
      const destinationId = route.get('dispatchRoute.id');
      let currentSourceId = this.get('currentDispatchRoute.id');

      if (trip.get('isCanceled')) {
        currentSourceId = 'cancel';
      } else if (trip.get('isWaitlisted')) {
        currentSourceId = 'waitlist';
      }

      const recipe = {
        instructions: stops.map((stop, index) => {
          return {
            id: stop.id,
            type: 'stop',
            source: currentSourceId,
            destination: destinationId,
            ordinal: lastStopOrdinal + index
          };
        })
      };
      return recipe;
    },

    noRoute: Ember.computed('trips.{isCanceled,isWaitlisted}', function () {
      const trip = this.get('trips');
      const pickStopPoint = trip.get('pickStopPoint');
      const dropStopPoint = trip.get('dropStopPoint');
      const schedule = this.get('store').peekAll('schedule').find(sch => Ember.isPresent((0, _unwrapProxy.unwrapProxy)(sch.get('dispatchSchedule'))));

      if (trip.get('isCanceled') || trip.get('isWaitlisted')) {
        return Ember.Object.create({
          id: null,
          name: 'No Route',
          schedule,
          dispatchRoute: {
            orderedStopsWithBreaks: [pickStopPoint, dropStopPoint],
            iqRoute: this
          }
        });
      }

      return null;
    }),
    overrideProviderOptions: Ember.computed('', function () {
      return this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider') && provider.get('status').toLowerCase() === ACTIVE_STATUS;
      });
    }),
    overridePickOrderOptions: Ember.computed('overrideSelectedRoute', function () {
      const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.dispatchRoute'));

      if (!Ember.isEmpty(dispatchRoute)) {
        const unperformedStops = dispatchRoute.getUnArrivedStopsInSequence(0) || [];
        const routeOrdinals = [];
        unperformedStops.forEach(stop => {
          routeOrdinals.push(stop.get('routeOrdinal'));
        });

        if (unperformedStops.length === 0) {
          routeOrdinals.push(1);
        } else if (unperformedStops.length > 0) {
          routeOrdinals.push(Math.max(...routeOrdinals) + 1);
        }

        return routeOrdinals;
      }

      return [];
    }),
    overrideDropOrderOptions: Ember.computed('overrideSelectedPickOrder', function () {
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.dispatchRoute'));

      if (!Ember.isEmpty(overrideSelectedPickOrder) && !Ember.isEmpty(dispatchRoute)) {
        const unperformedStops = dispatchRoute.getUnArrivedStopsInSequence(overrideSelectedPickOrder) || [];
        const routeOrdinals = [];
        unperformedStops.forEach(stop => {
          routeOrdinals.push(stop.get('routeOrdinal'));
        });

        if (unperformedStops.length > 0) {
          routeOrdinals.push(Math.max(...routeOrdinals) + 1);
        }

        if (routeOrdinals.length > 0) {
          return routeOrdinals;
        }

        return [overrideSelectedPickOrder + 1];
      }

      return [];
    }),

    async populateRoutesList(scheduleId) {
      const include = 'routes,routes.routeBreaks,routes.dispatchRoute,routes.trips,routes.trips.stopPoints,routes.trips.stopPoints.cluster';
      const scheduleRecord = await this.get('store').findRecord('schedule', scheduleId, {
        include
      });
      return scheduleRecord.get('routes').toArray();
    },

    overrideRouteOptions: Ember.computed('currentRoute', 'overrideSelectedProvider', async function () {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const sourceRouteId = this.get('currentRoute.id');
      const sourceTripScheduleId = this.get('currentRoute.schedule.id');
      const routes = await this.populateRoutesList(sourceTripScheduleId);

      if (Ember.isNone(routes)) {
        return [];
      }

      return _lodash.default.sortBy(routes.filter(route => {
        const routeScheduleId = route.get('schedule.id');
        const routeId = route.get('id');
        let sameProvider = false;

        if (!Ember.isEmpty(overrideSelectedProvider)) {
          sameProvider = overrideSelectedProvider.id === route.get('provider.id');
        }

        const sameSchedule = routeScheduleId === sourceTripScheduleId;
        const differentId = routeId !== sourceRouteId;
        return sameSchedule && differentId && sameProvider;
      }), route => route.name);
    }),
    selectedGain: Ember.computed('selectedSolutionId', 'solutions.[]', function () {
      const solutions = this.get('solutions');
      if (!solutions) return 0;
      const solution = solutions.findBy('id', this.get('selectedSolutionId')); // TODO: update to use delta otp value

      if (solution) {
        return solution.otp.deltaValue;
      }

      return 0;
    }),
    selectedGainType: Ember.computed('impactScore', function () {
      let gainType = 'spending';

      if (this.get('impactScore') > 0) {
        gainType = 'gaining';
      }

      return gainType;
    }),
    impactScore: Ember.computed('selectedSolutionId', 'selectedImpactPeriod', 'impact', function () {
      const impact = this.get('impact');
      const period = this.get('selectedImpactPeriod');
      if (!impact) return null;
      let score = impact.otpDelta ? impact.otpDelta : null;
      if (period === 'before') score = -score; // transform after back to negative value

      return score > 0 ? -score : Math.abs(score); // transform score based on positive being late and negative being positive
    }),
    impactScoreType: Ember.computed('impactScore', function () {
      let impactType = 'negative';

      if (this.get('impactScore') > 0) {
        impactType = 'positive';
      }

      return impactType;
    }),
    currentRouteImpactScoreType: Ember.computed('impactScore', function () {
      let impactType = 'spending';

      if (this.get('impactScore') > 0) {
        impactType = 'saving';
      }

      return impactType;
    }),
    selectedSolutionDispatchRoute: Ember.computed('solutions', 'selectedSolutionId', function () {
      const solutions = this.get('solutions');
      const selectedSolutionId = this.get('selectedSolutionId');
      if (Ember.isEmpty(solutions) || Ember.isEmpty(selectedSolutionId)) return null;
      const route = this.get('store').peekRecord('route', selectedSolutionId);
      const dsRoute = this.get('store').peekRecord('dispatch-route', route.get('dispatchRoute.id'));
      return dsRoute;
    }),
    inDanger: Ember.computed('solutions', 'selectedSolutionId', function () {
      const solutions = this.get('solutions');
      if (Ember.isEmpty(solutions)) return false; // TODO: connect to real data here

      const selectedSolutionId = this.get('selectedSolutionId');
      const index = solutions.mapBy('id').indexOf(selectedSolutionId);
      return index % 2 === 1;
    }),

    setupSolutions() {
      const trip = this.get('trips');
      const store = this.get('store');
      const currentState = this.get('currentState');
      let currentSolutions = null;
      let currentSolutionRouteId = null;

      if (currentState) {
        currentSolutions = currentState.solutions ? this.currentState.solutions : null;
        currentSolutionRouteId = currentState.currentSolutionRoute ? currentState.currentSolutionRoute.get('id') : null;
      }

      this.get('fetchSolutionsTask').perform(trip).catch(err => {
        // Try to be as robust as possible.  Log an error to console for discovery,
        // but just use an empty solution set to unblock manually transferring
        console.warn(`Unable to fetch trip transfer solutions: ${JSON.stringify(err)}`); // eslint-disable-line no-console

        return null;
      }).then(solutions => {
        let solutionsTableRows = []; // eslint-disable-next-line no-param-reassign

        solutions = currentState ? currentSolutions : solutions; // reset stops table data

        this.set('stopsTable.rows', []);

        if (!Ember.isEmpty(solutions)) {
          solutionsTableRows = solutions.map(_ref => {
            let {
              id,
              otp
            } = _ref;
            const selectedRoute = store.peekRecord('route', id);
            otp.originalValue = selectedRoute.get('otpValueWithSign') ? selectedRoute.get('otpValueWithSign') : otp.originalValue;
            return {
              id,
              otp,
              dispatchRoute: selectedRoute.get('dispatchRoute')
            };
          });
          this.get('solutions').pushObjects(solutions);
        }

        this.get('solutionRoutesTable').setRows(solutionsTableRows);

        if (!Ember.isEmpty(solutions)) {
          const firstSolutionId = currentSolutionRouteId ? solutions.find(solution => solution.id === currentSolutionRouteId).id : solutions[0].id;
          this.selectTableSolutionRow(firstSolutionId);
          this.selectSolution(firstSolutionId);
        }
      }).catch(() => {
        const tripIds = trip.map(x => x.tripId).toString();
        this.get('notifications').info(`Trip ${tripIds} cannot be transferred`);
        return this.onCloseClick();
      });
      this.fetchRouteOverrideOptions();
    },

    fetchRouteOverrideOptions() {
      const sourceRouteIdValue = this.get('currentRoute.id');
      const sourceRouteScheduleIdValue = this.get('currentRoute.schedule.id');
      if (!sourceRouteIdValue) return null;
      const store = this.get('store');
      const routes = store.peekAll('route').filter(route => {
        const routeScheduleIdValue = route.get('schedule.id');
        const routeIdValue = route.get('id');
        const sameSchedule = routeScheduleIdValue === sourceRouteScheduleIdValue;
        const differentId = routeIdValue !== sourceRouteIdValue;
        return sameSchedule && differentId;
      });
      this.set('routeOptions', routes);
      return routes;
    },

    fetchSolutionsTask: (0, _emberConcurrency.task)(function* (trip, routeId) {
      const tripTransferAdapter = yield this.get('store').adapterFor('trip-transfer');
      const tripIds = [];
      const trips = Ember.makeArray(trip);
      const dispatchScheduleId = trips[0].get('dispatchRoute.dispatchSchedule.id') ? parseInt(trips[0].get('dispatchRoute.dispatchSchedule.id'), 10) : null;
      trips.forEach(currentTrip => {
        const tripId = currentTrip.get('id') ? parseInt(currentTrip.get('id'), 10) : null;
        tripIds.push(tripId);
      });
      const {
        data: {
          attributes: {
            destinationRoutes: solutions,
            sourceRoute: currentRouteSolution
          }
        }
      } = yield tripTransferAdapter.createTripTransferAsyncOperation.perform(tripIds, dispatchScheduleId, routeId ? parseInt(routeId, 10) : routeId);
      this.set('currentRouteSolution', currentRouteSolution);
      return solutions;
    }).keepLatest(),
    fetchManualSolutionsTask: (0, _emberConcurrency.task)(function* (trip, pickOrdinal, dropOrdinal, routeId) {
      const tripTransferAdapter = yield this.get('store').adapterFor('trip-transfer');
      let dispatchScheduleId = '';
      const payloadData = [];
      dispatchScheduleId = trip[0].get('dispatchRoute.dispatchSchedule.id') ? parseInt(trip[0].get('dispatchRoute.dispatchSchedule.id'), 10) : null;
      trip.forEach(currentTrip => {
        const id = currentTrip.get('id') ? parseInt(currentTrip.get('id'), 10) : null; // eslint-disable-next-line no-param-reassign

        dropOrdinal = pickOrdinal + 1;
        payloadData.push({
          id,
          pickOrdinal,
          dropOrdinal
        }); // eslint-disable-next-line no-param-reassign

        pickOrdinal = pickOrdinal + 2;
      });
      const result = yield tripTransferAdapter.postManualTransfer(payloadData, dispatchScheduleId, routeId ? parseInt(routeId, 10) : routeId);

      if (!Ember.isEmpty(result.data)) {
        const currentRouteSolution = result.data.attributes.sourceRoute;
        const solutions = result.data.attributes.destinationRoutes; // get core entity records from solution result

        return this.getRouteResults(solutions).then(() => {
          this.set('currentRouteSolution', currentRouteSolution);
          return solutions;
        });
      }

      return;
    }).keepLatest(),

    async getRouteResults(solutions) {
      const coreEntityPopulator = this.get('coreEntityPopulator');
      const solutionPromises = [];
      solutions.forEach(solution => {
        const routeId = solution.id;
        solutionPromises.push(coreEntityPopulator.populateRtsRoute(routeId, {}));
      });
      await Promise.all(solutionPromises);
    },

    currentRouteImpact: Ember.computed('currentRouteSolution', function () {
      const store = this.get('store');
      const currentSolution = this.get('currentRouteSolution') || {};
      const currentStops = this.get('currentDispatchRoute.orderedStopsWithGaragesAndBreaks') || []; // since hypo call does not return the route ordinal but
      // return in order, we generate the ordinal ourselves

      let routeOrdinal = 0; // @TODO we should refactor to make it more flexible for map context

      const impact = Ember.Object.create({
        routeOtp: currentSolution.otp,
        otpDelta: currentSolution.otp.deltaValue,
        before: {
          otp: currentSolution.otp.originalValue,
          stops: currentStops.map(stop => {
            const stopPoint = store.peekRecord('stop-point', stop.id);
            return _impactStop.default.create({
              id: stop.id,
              eta: stopPoint.get('eta'),
              otp: stop.get('otp'),
              promiseTime: stop.get('promisedTime'),
              otpValue: stop.get('otpValue'),
              polyline: null,
              mrt: null,
              routeOrdinal: stopPoint.get('routeOrdinal'),
              stopPoint: stopPoint
            });
          })
        },
        after: {
          otp: currentSolution.otp.newStatus,
          stops: currentSolution.stops.map(stop => _impactStop.default.create({
            id: stop.id,
            eta: stop.eta.newValue,
            otp: stop.otp.newStatus,
            otpValue: stop.otp,
            mrt: stop.otp.delta,
            promiseTime: stop.promiseTime,
            polyline: stop.polyline,
            routeOrdinal: routeOrdinal++,
            stopPoint: store.peekRecord('stop-point', stop.id)
          }))
        }
      });
      return impact;
    }),
    selectedRouteImpact: Ember.computed('currentSolutionDispatchRoute', function () {
      const store = this.get('store');
      const selectedRouteId = this.get('currentSolutionDispatchRoute.route.id');
      const solutions = this.get('solutions') || [];
      const selectedSolution = solutions.findBy('id', selectedRouteId) || {};
      const currentStops = this.get('currentSolutionDispatchRoute.orderedStopsWithGaragesAndBreaks') || []; // since hypo call does not return the route ordinal but
      // return in order, we generate the ordinal ourselves

      let routeOrdinal = 0; // @TODO we should refactor to make it more flexible for map context

      const impact = Ember.Object.create({
        routeOtp: selectedSolution.otp,
        otpDelta: selectedSolution.otp.deltaValue,
        before: {
          otp: selectedSolution.otp.originalValue,
          stops: currentStops.map(stop => {
            const stopPoint = store.peekRecord('stop-point', stop.id);
            return _impactStop.default.create({
              id: stop.id,
              eta: stopPoint.get('eta'),
              otp: stop.get('otp'),
              promiseTime: stop.get('promisedTime'),
              otpValue: stop.get('otpValue'),
              polyline: null,
              mrt: null,
              routeOrdinal: stopPoint.get('routeOrdinal'),
              stopPoint: stopPoint
            });
          })
        },
        after: {
          otp: selectedSolution.otp.newValue,
          stops: selectedSolution.stops.map(stop => {
            let stopPoint = null;

            if (stop.id.includes('B')) {
              const stopId = stop.id.replace('B', '');
              stopPoint = store.peekRecord('route-break', stopId).stopPoint;
            } else {
              stopPoint = store.peekRecord('stop-point', stop.id);
            }

            const eta = stop.eta.newValue ? stop.eta.newValue : stop.eta.originalValue;
            return _impactStop.default.create({
              id: stop.id,
              eta: eta,
              otp: stop.otp.newStatus,
              otpValue: stop.otp,
              mrt: stop.otp.delta,
              promiseTime: stop.promiseTime,
              polyline: stop.polyline,
              routeOrdinal: routeOrdinal++,
              stopPoint: stopPoint
            });
          })
        }
      });
      return impact;
    }),

    /**
     * In the view impact section only those stops which belong to the selected trip should be highlighted in yellow color
     * @param solutions
     * @param period
     * @param impact
     * @param routeId
     */
    selectedStopsHighlight(solutions, impact) {
      const selectedSolutionRoute = solutions;
      const selectedTripIds = this.get('trips').map(ele => parseInt(ele.id, 10));
      const filteredStops = selectedSolutionRoute.length > 0 ? selectedSolutionRoute.firstObject.stops.filter(ele => selectedTripIds.includes(ele.tripId)) : [];
      const selectedOrdinals = filteredStops.map(ele => ele.id);
      impact.filter(ordinal => selectedOrdinals.includes(ordinal.id)).forEach(stop => {
        stop.set('selectedOrdinalRow', true);
      });
    },

    chooseImpactByRoute(routeId) {
      const solutions = this.get('solutions');
      const period = this.get('selectedImpactPeriod');
      const currentRoute = this.get('currentRoute');
      const currentRouteSolution = this.get('currentRouteSolution');
      let selectedSolution = null;
      let impact = null; // Handle choosing the current route

      if (Ember.isEmpty(solutions)) {
        // eslint-disable-next-line new-cap
        this.get('stopsTable').setRows(Ember.A([]));
        return;
      }

      if (routeId === currentRoute.get('dispatchRoute.route.id')) {
        selectedSolution = currentRouteSolution;
        if (Ember.isEmpty(selectedSolution)) return;
        impact = this.get('currentRouteImpact');
      } else {
        selectedSolution = solutions.findBy('id', routeId);
        if (Ember.isEmpty(selectedSolution)) return;
        impact = this.get('selectedRouteImpact');
      }

      this.set('impact', impact);
      this.selectedStopsHighlight(solutions, impact[period].stops);
      this.get('stopsTable').setRows(impact[period].stops);
      return impact;
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      const solutions = this.get('solutions');
      const selectedSolutionId = this.get('currentSolutionDispatchRoute.route.id');
      const recipe = solutions.findBy('id', selectedSolutionId).recipe;
      const bulkOperationAdapter = this.get('store').adapterFor('bulk-operation');
      yield bulkOperationAdapter.postRecipe(recipe);
    }),

    selectSolution(routeId) {
      const selectedRoute = this.get('store').peekRecord('route', routeId);
      const selectedDispatchRoute = this.get('store').peekRecord('dispatch-route', selectedRoute.get('dispatchRoute.id'));
      this.setProperties({
        selectedSolutionId: routeId,
        selectedImpactPeriod: 'after',
        activeTabRouteId: routeId,
        currentSolutionDispatchRoute: selectedDispatchRoute
      });
      this.chooseImpactByRoute(routeId);
    },

    observeSelectImpactPeriod: Ember.observer('selectedImpactPeriod', function () {
      const period = this.get('selectedImpactPeriod');
      const selectedRouteId = this.get('activeTabRouteId'); // TODO: Can the table rows be updated without an observer?

      this.get('stopsTable').setRows(this.get(`impact.${period}.stops`)); // revaluate impact

      this.chooseImpactByRoute(selectedRouteId);
    }),

    selectTableSolutionRow(routeId) {
      const rows = this.get('solutionRoutesTable.rows');
      const currentSelectedRow = rows.findBy('selected');
      const rowToSelect = rows.findBy('id', routeId);
      if (rowToSelect === currentSelectedRow) return;

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
      }

      rowToSelect.set('selected', true);
      this.selectSolution(routeId);
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    selectOverrideSolution() {
      const route = this.get('overrideSelectedRoute');
      const routeId = route.get('id');
      const routeName = route.get('name');
      const trip = this.get('trips');
      const currentSelectedRow = this.get('solutionRoutesTable.rows').findBy('selected');
      const store = this.get('store');
      const solutions = this.get('solutions');
      const pickOrdinal = this.get('overrideSelectedPickOrder');
      const dropOrdinal = this.get('overrideSelectedDropOrder'); // clear error message

      this.set('errorText');

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
        this.set('selectedSolutionId');
      }

      this.cursorWait();
      this.get('fetchManualSolutionsTask').perform(trip, pickOrdinal, dropOrdinal, routeId).catch(err => {
        // Try to be as robust as possible.  Log an error to console for discovery,
        // but just use an empty solution set to unblock manually transferring
        console.warn(`Unable to fetch trip transfer solutions: ${JSON.stringify(err)}`); // eslint-disable-line no-console

        if (!Ember.isEmpty(err) && !Ember.isEmpty(err.payload)) {
          if (err.payload.message) {
            this.set('errorText', `* ${err.payload.message}`);
          } else if (err.payload.detail) {
            this.set('errorText', `* ${err.payload.detail}`);
          } else {
            this.set('errorText', '* Error getting hypo trip transfer.');
          }
        }

        return null;
      }).then(solution => {
        if (solution) {
          const destinationRoutes = solution || [];
          const foundSolution = solutions.findBy('id', destinationRoutes[0].id);

          if (!foundSolution) {
            this.get('solutions').pushObject(destinationRoutes[0]);
            const solutionsTableRows = destinationRoutes.map(_ref2 => {
              let {
                id,
                otp
              } = _ref2;
              const selectedRoute = store.peekRecord('route', id);
              otp.originalValue = selectedRoute.get('otpValueWithSign') ? selectedRoute.get('otpValueWithSign') : otp.originalValue;
              return {
                id,
                otp,
                dispatchRoute: selectedRoute.get('dispatchRoute')
              };
            }); // this.get('solutionRoutesTable').push(solutionsTableRows[0]);

            this.get('solutionRoutesTable').addRows(solutionsTableRows);
          } else {
            const solutionRoutesTableRow = this.get('solutionRoutesTable.rows').toArray();
            const solutionIndex = solutions.findIndex(currSolution => currSolution.id === destinationRoutes[0].id);
            const solutionRoutesTableRowIindex = solutionRoutesTableRow.findIndex(row => row.get('id') === destinationRoutes[0].id); // replace the solution with new data

            solutions.splice(solutionIndex, 0, destinationRoutes[0]);
            const solutionsTableRows = destinationRoutes.map(_ref3 => {
              let {
                id,
                otp
              } = _ref3;
              const selectedRoute = store.peekRecord('route', id);
              return {
                id,
                otp,
                dispatchRoute: selectedRoute.get('dispatchRoute')
              };
            }); // replace the routes table rows with new solution rows

            solutionRoutesTableRow.splice(solutionRoutesTableRowIindex, 0, solutionsTableRows[0]); // refresh stop table with new results

            const selectedRoute = store.peekRecord('route', destinationRoutes[0].id);
            this.set('currentSolutionDispatchRoute', selectedRoute.get('dispatchRoute'));
          }

          this.selectSolution(destinationRoutes[0].id);
          this.selectTableSolutionRow(routeId);
        }

        this.cursorDefault();
      }).catch(() => {
        const tripIds = trip.map(x => x.tripId).toString();
        this.get('notifications').warning(`Trip ${tripIds} cannot be transferred to route ${routeName}`);
        this.cursorDefault();
      });
    },

    resetSolutionTableData() {
      this.set('overrideSelectedRoute', null);
      this.set('overrideSelectedProvider', null);
      this.set('errorText', null);
      this.set('override', false); // reset routes table data

      /* eslint-disable new-cap */

      this.set('solutionRoutesTable.rows', Ember.A([]));
      this.set('stopsTable.rows', Ember.A([]));
      this.set('solutions', Ember.A([]));
      /* eslint-enable new-cap */

      this.setProperties({
        selectedSolutionId: null,
        selectedImpactPeriod: 'after',
        activeTabRouteId: null,
        currentSolutionDispatchRoute: null
      });
    },

    actions: {
      onSolutionRowClick(row
      /*, event */
      ) {
        const routeId = row.get('id'); // Reset the override

        this.set('selectedOverrideSolutionId');
        this.selectTableSolutionRow(routeId);
      },

      onRefreshClick() {
        this.resetSolutionTableData();
      },

      onSaveClick() {
        const currentSolutionDispatchRoute = this.get('currentSolutionDispatchRoute');
        const currentRouteId = this.get('currentRoute.name');
        const title = 'Bulk Transfer Trip';
        const tip = `Bulk Transfer trip from route ${currentRouteId} to ${currentSolutionDispatchRoute.get('route.name')}`;
        const successMsg = 'Bulk Trip transfer was successful';
        const errorMsg = 'Bulk Trip transfer failed';
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          tip,
          title,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('notifications').success(successMsg);
              tooltip.reset();
              return this.onCloseClick();
            }).catch(() => {
              this.get('notifications').warning(errorMsg);
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          },
          hasOverlay: true
        });
      },

      onShrinkClick() {
        let currentState = this.get('currentState'); // const bulkTripTransfer = this.get('bulkTripTransfer');

        const currentSolutionRoute = this.get('currentSolutionRoute');
        const solutions = JSON.parse(JSON.stringify(this.get('solutions')));
        const currentRouteSolution = this.get('currentRouteSolution');
        const overrideSelectedProvider = this.get('overrideSelectedProvider');
        const overrideSelectedRoute = this.get('overrideSelectedRoute');
        const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
        const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
        const override = this.get('override'); // save current manual settings

        currentState = {
          currentSolutionRoute: (0, _unwrapProxy.unwrapProxy)(currentSolutionRoute),
          solutions,
          currentRouteSolution,
          overrideSelectedProvider,
          overrideSelectedRoute,
          overrideSelectedPickOrder,
          overrideSelectedDropOrder,
          override
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onChangeProvider(provider) {
        // clear selected route, pick order and drop order
        this.set('overrideSelectedRoute', null);
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.set('overrideSelectedProvider', provider);
      },

      async onChangeRoute(route) {
        // clear selected pick order and drop order
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        await this.get('coreEntityPopulator').populateRtsRoute(route.id, {});
        this.set('overrideSelectedRoute', route);
        const pickOrders = this.get('overridePickOrderOptions') || [];
        const currentPickOrder = Math.max(...pickOrders);
        this.set('overrideSelectedPickOrder', currentPickOrder);
        this.set('overrideSelectedDropOrder', currentPickOrder + 1);
        this.set('selectedImpactView', 'destination'); //to highlight the row

        this.selectOverrideSolution(route);
      },

      onChangePickOrder(order) {
        // clear selected drop order
        this.set('overrideSelectedDropOrder', null);
        this.set('overrideSelectedPickOrder', order);
      },

      onChangeDropOrder(order) {
        this.set('overrideSelectedDropOrder', order); // this.selectOverrideSolution();

        this.selectOverrideSolution(this.get('overrideSelectedRoute'));
      },

      selectSolutionRouteTab() {
        const selectedRouteId = this.get('selectedSolutionId');
        this.set('selectedImpactView', 'destination');
        this.set('activeTabRouteId', selectedRouteId);
        this.chooseImpactByRoute(selectedRouteId);
      },

      selectCurrentRouteTab() {
        const selectedRouteId = this.get('currentDispatchRoute.route.id');
        this.set('selectedImpactView', 'current');
        this.set('activeTabRouteId', selectedRouteId);
        this.chooseImpactByRoute(selectedRouteId);
      }

    }
  });

  _exports.default = _default;
});