define("adept-iq/pods/components/workspace-navbar/notification-failure-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    actions: {
      onEligibleWarningClose() {
        this.get('onClose')();
      },

      onEligibleWarningOK() {
        this.get('onEligibleWarningOK')();
      },

      onEligibilityWarningYes() {
        if (this.get('onEligibilityWarningYes')) {
          this.get('onEligibilityWarningYes')();
        }
      },

      onEligibilityWarningNo() {
        if (this.get('onEligibilityWarningNo')) {
          this.get('onEligibilityWarningNo')();
        }
      }

    }
  });

  _exports.default = _default;
});