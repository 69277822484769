define("adept-iq/services/dwh-service", ["exports", "adept-iq/config/api-urls", "moment"], function (_exports, _apiUrls, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),

    async fetchDwhStops(date) {
      const session = this.get('session');

      const timezone = _moment.default.tz.guess();

      const apiUrl = `${_apiUrls.API.dwhService.host}/stop-point?timezone=${timezone}`;
      const fields = 'id,attributes.iqid,attributes.routename,attributes.stoptype,attributes.requesttime,attributes.promisetime,attributes.passengerfirstname,attributes.passengerlastname,attributes.streetnumber,attributes.streetaddress,attributes.sublocality,attributes.locality,attributes.subregion,attributes.region,attributes.postalcode,attributes.actualodometer,attributes.actualarrivetime,attributes.actualdeparttime,attributes.tripId,attributes.tripStatus,attributes.tripStatusReason,attributes.tripPurpose,attributes.fareCategory';
      const json = {
        data: {
          schedulename_str: JSON.stringify([date]),
          data_source_str: JSON.stringify(['DB']),
          usage_str: JSON.stringify(['165']),
          fields
        }
      };
      const res = await this.get('ajax').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json',
        data: json
      });
      const data = res.data.map(content => {
        return {
          id: content.id,
          ...content.attributes
        };
      });

      if (data.length === 0) {
        throw new Error('UNABLE TO DOWNLOAD. NO DATA EXISTS FOR THE SELECTED DATE.');
      }

      return data;
    },

    async patchDwhStops(date, uploadBinary) {
      const session = this.get('session');

      const timezone = _moment.default.tz.guess();

      const apiUrl = `${_apiUrls.API.dwhService.host}/stop-point/upload?date=${date}&timezone=${timezone}`;
      const res = await this.get('ajax').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`,
          'Content-Type': 'text/csv'
        },
        processData: false,
        data: uploadBinary
      });
      return res;
    }

  });

  _exports.default = _default;
});