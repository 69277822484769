define("adept-iq/pods/components/schedule-metrics/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    modelName: 'metric',
    title: 'Scheduling Metrics',
    columns: [{
      id: 'strategySelected',
      label: 'Strategy Selected',
      modelAttribute: 'strategyName',
      index: 1
    }, {
      id: 'numberOfRoutes',
      label: 'Number of Routes',
      modelAttribute: 'routes',
      index: 2
    }, {
      id: 'totalTravelMiles',
      label: 'Total Travel Miles',
      modelAttribute: 'totalMiles',
      index: 3
    }, {
      id: 'totalTravelHours',
      label: 'Total Travel Hours',
      modelAttribute: 'totalHours',
      index: 4
    }, {
      id: 'totalScheduledTrips',
      label: 'Total Number Of Scheduled Jobs',
      modelAttribute: 'scheduledTrips',
      index: 5
    }, {
      id: 'totalUnassignedTrips',
      label: 'Total Number Of Unassigned Jobs',
      modelAttribute: 'unscheduledTrips',
      index: 6
    }, {
      id: 'unusedVehicles',
      label: 'No of Unused Vehicles',
      modelAttribute: 'unusedRoutes',
      index: 7
    }, {
      id: 'totalRevenueHours',
      label: 'Total Revenue Hours',
      modelAttribute: 'revenueHours',
      index: 8
    }, {
      id: 'totalRevenueMiles',
      label: 'Total Revenue Miles',
      modelAttribute: 'revenueMiles',
      index: 9
    }]
  };
  _exports.default = _default;
});