define("adept-iq/pods/landing-page/controller", ["exports", "adept-iq/config/icon-paths", "adept-iq/config/api-urls"], function (_exports, _iconPaths, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    workspace: Ember.inject.service(),

    get iconPaths() {
      return {
        background: _iconPaths.default.pageBackgrounds.main,
        apiDocumentation: _iconPaths.default.actionMenu.apiDocumentation,
        sandbox: _iconPaths.default.actionMenu.sandbox,
        myProfile: _iconPaths.default.actionMenu.myProfile,
        logout: _iconPaths.default.actionMenu.logout
      };
    },

    actions: {
      navigateToSandboxSetup() {
        this.router.transitionTo('sandbox-setup');
      },

      openApiDocumentation() {
        window.open(_apiUrls.API.apiDocs.host, '_blank');
      },

      openMyProfile() {
        this.get('workspace').pushState('openUserProfile');
      }

    }
  });

  _exports.default = _default;
});