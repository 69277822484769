define("adept-iq/pods/components/side-drawer/cancel-trip-form/cancel-booking-related-trips/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    booking: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    actions: {
      onSelectedTrip(trip, event) {
        const isChecked = event.target.checked;
        trip.set('isChecked', isChecked);
        const isAllTripsChecked = this.get('bookingRelatedTrips').isEvery('isChecked');
        const isAllChecked = this.get('isAllChecked');
        if (isAllTripsChecked && !isAllChecked || !isAllTripsChecked && isAllChecked) this.toggleProperty('isAllChecked');
      },

      onCheckAllTrips() {
        const trips = this.get('bookingRelatedTrips');
        this.toggleProperty('isAllChecked');
        trips.setEach('isChecked', this.get('isAllChecked'));
      }

    }
  });

  _exports.default = _default;
});