define("adept-iq/models/driver-availability", ["exports", "ember-data", "moment", "ember-cp-validations", "adept-iq/utils/regex-functions"], function (_exports, _emberData, _moment, _emberCpValidations, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default; // model validation

  const Validations = (0, _emberCpValidations.buildValidations)({
    shiftStart: {
      description: 'Shift Start',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: '{description} cannot be blank.'
      })]
    },
    routeLength: {
      description: 'Shift Length',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: _regexFunctions.REGEX_HHMM,
        message: '{description} must be formatted hh:mm.'
      })]
    },
    DOWSelected: {
      description: 'day of week',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Requires at least 1 {description} selected presence.'
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        message: 'Requires at least 1 {description} selected.'
      })]
    }
  });

  var _default = Model.extend(Validations, {
    DOWSelected: attr(),
    startTime: attr('iso-date', {
      defaultValue: null
    }),
    endTime: attr('iso-date', {
      defaultValue: null
    }),
    calcEndDate: attr('iso-date', {
      defaultValue: null
    }),
    shiftStart: attr('iso-date', {
      defaultValue: null
    }),
    shiftEnd: attr('iso-date', {
      defaultValue: null
    }),
    routeLength: attr('string'),
    sunday: attr('boolean'),
    monday: attr('boolean'),
    tuesday: attr('boolean'),
    wednesday: attr('boolean'),
    thursday: attr('boolean'),
    friday: attr('boolean'),
    saturday: attr('boolean'),
    holiday: attr('boolean'),
    driver: belongsTo('driver'),
    driverId: Ember.computed.readOnly('driver.id'),
    selectedDOWs: Ember.computed('sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'holiday', function () {
      const selectedDOWArray = [];
      if (this.get('sunday')) selectedDOWArray.push('sunday');
      if (this.get('monday')) selectedDOWArray.push('monday');
      if (this.get('tuesday')) selectedDOWArray.push('tuesday');
      if (this.get('wednesday')) selectedDOWArray.push('wednesday');
      if (this.get('thursday')) selectedDOWArray.push('thursday');
      if (this.get('friday')) selectedDOWArray.push('friday');
      if (this.get('saturday')) selectedDOWArray.push('saturday');
      if (this.get('holiday')) selectedDOWArray.push('holiday');
      return selectedDOWArray;
    }),
    formattedShiftStart: Ember.computed('shiftStart', function () {
      const shiftStart = this.get('shiftStart');
      return (0, _moment.default)(shiftStart).format('LT');
    }),
    formattedShiftEnd: Ember.computed('shiftEnd', function () {
      const shiftEnd = this.get('shiftEnd');
      return (0, _moment.default)(shiftEnd).format('LT');
    })
  });

  _exports.default = _default;
});