define("adept-iq/pods/components/aws-file-upload/component", ["exports", "adept-iq/config/api-urls", "ember-concurrency", "aws-sdk", "adept-iq/mixins/async-ias-operation", "papaparse", "moment", "xlsx"], function (_exports, _apiUrls, _emberConcurrency, _awsSdk, _asyncIasOperation, _papaparse, _moment, _xlsx) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncIasOperation.default, {
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.selectedFile = null;
      this.isUploading = false;
    },

    async readFileContent(file) {
      return new Promise((resolve, reject) => {
        try {
          const fr = new FileReader();

          fr.onload = () => resolve(fr.result);

          fr.onerror = () => {
            reject(new Error('Failed to read file content'));
          };

          fr.readAsArrayBuffer(file); // Read file as an array buffer for binary files like xlsx
        } catch (error) {
          reject(new Error('Exception occurred while reading file content'));
        }
      });
    },

    async parseCSV(fileContent) {
      return new Promise((resolve, reject) => {
        _papaparse.default.parse(fileContent, {
          header: true,
          complete: results => resolve(results.data),
          error: error => reject(error)
        });
      });
    },

    async parseXLS(fileContent) {
      return new Promise((resolve, reject) => {
        try {
          const workbook = _xlsx.default.read(new Uint8Array(fileContent), {
            type: 'array'
          });

          const sheet = workbook.Sheets.Data; // Access the 'Data' tab

          if (!sheet) {
            reject(new Error('Data tab not found in the XLS file'));
            return;
          }

          const parsedData = _xlsx.default.utils.sheet_to_json(sheet, {
            header: true
          });

          resolve(parsedData);
        } catch (error) {
          reject(new Error('Failed to parse XLS file'));
        }
      });
    },

    extractRequestDate(parsedData) {
      if (parsedData.length > 0 && parsedData[0].requestTime) {
        const requestTime = parsedData[0].requestTime;
        return (0, _moment.default)(requestTime).toDate();
      }

      return null;
    },

    processUploadedTrips: (0, _emberConcurrency.task)(function* () {
      try {
        const userTimezone = localStorage.getItem('userTimezone');
        const res = yield this.createIasAsyncOperation.perform('import', 'schedules', {
          timezone: userTimezone
        });
        return Promise.resolve(res);
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          console.warn(e); // eslint-disable-line no-console

          throw e;
        }
      }
    }),
    processUploadedVehicles: (0, _emberConcurrency.task)(function* () {
      try {
        const userTimezone = localStorage.getItem('userTimezone');
        const res = yield this.createIasAsyncOperation.perform('import', 'vehicles', {
          timezone: userTimezone
        });
        return Promise.resolve(res);
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          console.warn(e); // eslint-disable-line no-console

          throw e;
        }
      }
    }),
    actions: {
      triggerFileInput() {
        const fileInput = document.getElementById(this.fileInputId);

        const handleClick = event => {
          event.stopPropagation();
        }; // Prevent the event from bubbling up


        fileInput.addEventListener('click', handleClick);
        fileInput.click();
        fileInput.removeEventListener('click', handleClick);
      },

      handleFileChange(event) {
        const file = event.target.files[0];

        if (file) {
          this.set('selectedFile', file);
          this.send('uploadFile'); // Reset the file input element

          event.target.value = '';
        }
      },

      async uploadFile() {
        const notifications = this.get('notifications');
        const file = this.get('selectedFile');
        const shouldProcessTrips = this.get('shouldProcessTrips');
        const shouldProcessVehicles = this.get('shouldProcessVehicles');
        const isUploading = this.get('isUploading');

        if (isUploading) {
          notifications.warning('An upload is already in progress. Please wait.');
          return;
        }

        try {
          if (file) {
            const userTenantName = localStorage.getItem('userTenantName');

            if (!userTenantName) {
              notifications.warning('File Upload Tenant not found');
              return;
            }

            this.get('workspace').set('isGlobalSpinnerVisible', true);
            let parsedData = null;
            let requestDate = null;
            const fileContent = await this.readFileContent(file); // Extract requestTime from the CSV if we are processing Trips

            if (shouldProcessTrips) {
              try {
                parsedData = await this.parseXLS(fileContent);
                requestDate = this.extractRequestDate(parsedData);

                if (!requestDate) {
                  notifications.warning('Request Time Missing in csv upload');
                  return;
                }
              } catch (error) {
                notifications.warning(`Failed to parse ${file.name}`);
                return;
              }
            }

            this.set('isUploading', true);

            _awsSdk.default.config.update({
              accessKeyId: _apiUrls.API.awsUploadConfig.accessKeyId,
              secretAccessKey: _apiUrls.API.awsUploadConfig.secretAccessKey,
              region: _apiUrls.API.awsUploadConfig.region
            });

            const s3 = new _awsSdk.default.S3();
            const filePath = shouldProcessVehicles ? `${userTenantName}/vehicles/${this.get('fileName')}` : `${userTenantName}/schedules/${this.get('fileName')}`;
            const params = {
              Bucket: _apiUrls.API.awsUploadConfig.bucketName,
              Key: filePath,
              Body: fileContent,
              ACL: 'private',
              ContentType: file.type
            };
            await s3.upload(params).promise(); // Check if we should process Trips

            if (shouldProcessTrips) {
              const result = await this.processUploadedTrips.perform();

              if (!result) {
                notifications.warning('Failed to process trips.');
                return;
              }
            } else if (shouldProcessVehicles) {
              const result = await this.processUploadedVehicles.perform();

              if (!result) {
                notifications.warning('Failed to process vehicles.');
                return;
              }
            } // Always call onSuccess, passing requestDate if trips were processed


            if (this.onSuccess) {
              this.onSuccess(requestDate);
            }
          }
        } catch (error) {
          notifications.warning(`Failed to upload the file: ${file.name}. Please try again.`); // eslint-disable-next-line no-console

          console.log(error);
        } finally {
          this.set('isUploading', false);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
        }
      }

    }
  });

  _exports.default = _default;
});