define("adept-iq/pods/components/rider-management-widget/rider-activity-log/activity-log-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-activity-log/activity-log-table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _config.default,
    rider: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    async query(modelName) {
      const store = this.get('store');
      const activeData = store.peekAll(modelName) || [];
      return activeData;
    },

    actions: {}
  });

  _exports.default = _default;
});