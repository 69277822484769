define("adept-iq/serializers/driver", ["exports", "adept-iq/serializers/-driver"], function (_exports, _driver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    attrs: {
      rvdStartTime: {
        serialize: false
      },
      rvdEndTime: {
        serialize: false
      },
      inProgress: {
        serialize: false
      },
      routeStatus: {
        serialize: false
      },
      license: {
        serialize: false
      },
      isCompleted: {
        serialize: false
      },
      rvdHistory: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        const providerRelationship = json.data.relationships.provider;
        const vehicleRelationship = json.data.relationships.vehicle;

        if (Ember.isPresent(providerRelationship)) {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }

        if (Ember.isPresent(vehicleRelationship)) {
          delete json.data.relationships.vehicle;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};

      if (Ember.isPresent(resourceHash.attributes)) {
        const providerName = resourceHash.attributes.providerName;
        const license = resourceHash.relationships.license;
        const routeActualEndTime = resourceHash.attributes.routeActualEndTime;
        const routePk = resourceHash.attributes.routePk;

        if (Ember.isPresent(providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: providerName
            }
          };
          resourceHash.relationships = relationships;
        } // hack for front-end, need backend to convert this relationship to be an object, not an array as that represents a hasMany


        if (Ember.isPresent(license)) {
          if (Ember.isArray(license.data)) {
            resourceHash.relationships.license = resourceHash.relationships.license.data[0];
          }
        }

        if (Ember.isPresent(routeActualEndTime) && Ember.isPresent(routePk)) {
          if (Ember.isPresent(routeActualEndTime[routePk])) {
            resourceHash.attributes.isCompleted = true;
          } else {
            resourceHash.attributes.isCompleted = false;
          }
        }
      }

      if (Ember.isPresent(resourceHash.relationships)) {
        const providerName = resourceHash.relationships.providerName;

        if (Ember.isPresent(providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          providerName.data.type = 'provider';
          relationships.provider = providerName;
          delete relationships.providerName;
          resourceHash.relationships = relationships;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};

      if (Ember.isPresent(payload.data.attributes.provider)) {
        relationships.provider = {
          data: {
            type: 'provider',
            id: payload.data.attributes.provider
          }
        };
        delete payload.data.attributes.provider;
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});