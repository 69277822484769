define("adept-iq/adapters/trip-stop", ["exports", "adept-iq/adapters/-ss-schedulingService", "adept-iq/config/api-urls"], function (_exports, _ssSchedulingService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    schedulingServiceHost: _apiUrls.API.schedulingService.host,

    urlForFindRecord(id) {
      const model = 'trip-stop';
      const schedulingServiceHost = this.get('schedulingServiceHost');
      const baseUrl = `${schedulingServiceHost}/${model}/${id}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});