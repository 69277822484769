define("adept-iq/classes/data-joins/column-widget", ["exports", "adept-iq/classes/data-joins/base", "adept-iq/pods/components/generic-widgets/column-widget/classes/checkbox-row", "adept-iq/config/environment"], function (_exports, _base, _checkboxRow, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // should move this
  const QUEUE_ID = 'wgt-refresh';

  var _default = _base.default.extend({
    table: null,
    activeContext: null,
    work: null,
    modelName: null,
    forceSync: _environment.default.APP.test,

    initialize(record) {
      const row = new _checkboxRow.default(record);
      row.set('activeContext', this.get('activeContext'));
      return row;
    },

    update(node, data) {
      node.set('content', data);
    },

    clear() {
      this.join([], {
        sync: true
      });
    },

    join(collection) {
      let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        sync: false
      };
      const workQueues = this.get('work.queues') || [];

      if (opts.sync || this.get('forceSync')) {
        // unloads memory from exited rows
        this.purgeCache(); // syncs & destroys some rows

        this._super(...arguments); // at this point, table probably contains some destroyed rows


        const tableRows = this.get('table.rows');
        const rows = this.get('nodes');
        if (!tableRows || !rows) return false; // skip update if possible

        if (rows.length === tableRows.length) {
          let isDifferent = false;

          for (let i = 0; i < rows.length; i++) {
            if (rows[i] !== tableRows[i]) {
              isDifferent = true;
              break;
            }
          }

          if (!isDifferent) return false;
        }

        tableRows.setObjects(rows);
        const queue = this.get('work.queues').findBy('name', QUEUE_ID);

        if (queue) {
          queue.addJob({
            collection,
            dataJoin: this
          });
          this.get('work').start();
        }

        return true;
      }

      const queue = workQueues.findBy('name', QUEUE_ID);

      if (!queue) {
        console.error(`queue '${QUEUE_ID}' not registered`); // eslint-disable-line no-console
      }

      if (queue) {
        queue.addJob({
          collection,
          dataJoin: this
        });
        this.get('work').start();
      }
    },

    exit(row) {
      row.setProperties({
        isSelected: false,
        isLastChecked: false
      });
    },

    release(row) {
      row.destroy();
    }

  });

  _exports.default = _default;
});