define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/violation/vehicle-search-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'vehicle',
    title: 'Vehicle',
    defaultSortId: 'name',
    defaultSortAsc: true,
    fetchModelData: false,
    selectFirstRow: true,
    hideWidgetControls: true,
    columns: [{
      id: 'name',
      index: 0,
      type: 'text',
      label: 'ID',
      valuePath: 'name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'placeholder',
      index: 1,
      type: 'boolean',
      label: 'Placeholder',
      valuePath: 'placeholder',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'providerId',
      index: 2,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'licensePlate',
      index: 3,
      type: 'text',
      label: 'License Plate',
      valuePath: 'licensePlate',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'vehicleStatus',
      index: 4,
      type: 'text',
      label: 'Vehicle Status',
      valuePath: 'vehicleStatusName.description',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'wheelChairLiftSerialNumber',
      index: 5,
      type: 'uuid',
      label: 'Wheelchair Lift Serial Number',
      valuePath: 'wheelChairLiftSerialNumber',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'leaseCompanyName',
      index: 6,
      type: 'text',
      label: 'Lease Company',
      valuePath: 'leaseCompanyName',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'leaseEndDate',
      index: 7,
      type: 'date',
      label: 'Lease End Date',
      valuePath: 'leaseEndDate',
      format: 'MM/DD/YYYY',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'inService',
      index: 8,
      type: 'boolean',
      label: 'In Service',
      valuePath: 'inService',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'displayInManager',
      index: 9,
      type: 'boolean',
      label: 'Display in Manager',
      valuePath: 'inService',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'displayInArchive',
      index: 10,
      type: 'boolean',
      label: 'Display in Archive',
      valuePath: 'displayInArchive',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'vehicleTypeName',
      index: 11,
      type: 'text',
      label: 'Type',
      valuePath: 'vehicleTypeName',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'manufacturerName',
      index: 12,
      type: 'boolean',
      label: 'Manufacturer',
      valuePath: 'manufacturerName.description',
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      searchable: true
    }, {
      id: 'modelYear',
      index: 13,
      type: 'number',
      label: 'Model Year',
      valuePath: 'modelYear',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'comments',
      index: 14,
      type: 'text',
      label: 'Comments',
      valuePath: 'comments',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'engineType',
      index: 15,
      type: 'text',
      label: 'Engine Type',
      valuePath: 'engineType',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'siteDef',
      index: 16,
      type: 'text',
      label: 'Site Def',
      valuePath: 'siteDef',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'inServiceDate',
      index: 17,
      type: 'date',
      label: 'In Service Date',
      valuePath: 'inServiceDate',
      format: 'MM/DD/YYYY',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'endServiceDate',
      index: 18,
      type: 'date',
      label: 'End Service Date',
      valuePath: 'endServiceDate',
      format: 'MM/DD/YYYY',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'version',
      index: 19,
      type: 'number',
      label: 'Version',
      valuePath: 'version',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'noOfAmbulatorySeats',
      index: 20,
      type: 'number',
      label: 'Amb Seats',
      valuePath: 'noOfAmbulatorySeats',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'noOfWheelChairSeats',
      index: 21,
      type: 'number',
      label: 'WC Seats',
      valuePath: 'noOfWheelChairSeats',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'extraWideLift',
      index: 22,
      type: 'number',
      label: 'XWide Lift',
      valuePath: 'vehicleType.extraWideLift',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});