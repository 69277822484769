define("adept-iq/serializers/schedule", ["exports", "adept-iq/serializers/-ss-schedulingService", "lodash"], function (_exports, _ssSchedulingService, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    attrs: {
      engineDatasetId: {
        serialize: false
      },
      name: {
        serialize: false
      },
      start: {
        serialize: false
      },
      end: {
        serialize: false
      },
      notificationStatus: {
        serialize: false
      },
      type: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      json.data.relationships = json.data.relationships || {};

      if (json.data && json.data.relationships.scheduleConfiguration) {
        json.data.relationships.scheduleConfigurationName = (0, _lodash.cloneDeep)(json.data.relationships.scheduleConfiguration);
        json.data.relationships.scheduleConfigurationName.data.type = 'scheduleConfigurationName';
        delete json.data.relationships.scheduleConfiguration;
      }

      if (json.data && json.data.relationships) {
        delete json.data.relationships.metric;
        delete json.data.relationships.dispatchSchedule;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.scheduleConfigurationName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.scheduleConfigurationName = {
          data: {
            type: 'scheduleConfigurationName',
            id: resourceHash.attributes.scheduleConfigurationName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.scheduleConfigurationName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});