define("adept-iq/classes/map-contexts/side-drawers/trip-transfer", ["exports", "adept-iq/classes/map-contexts/base", "adept-iq/config/environment", "adept-iq/classes/markers/stops/stop-point", "adept-iq/classes/markers/vehicles/iq", "adept-iq/classes/polylines/stops/iq"], function (_exports, _base, _environment, _stopPoint, _iq, _iq2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // will need this to decode impact payload polylines
  // import mapbox from '@mapbox/polyline';
  // will need this to render impact payload polylines
  // import BaseStopMarker from 'adept-iq/classes/markers/stops/base';
  var _default = _base.default.extend({
    currentIqRoute: null,
    destinationIqRoute: null,
    selectedImpactPeriod: 'after',
    // 'before' or 'after',
    selectedImpactView: 'destination',
    // 'current' or 'destination'
    impactPayload: null,
    // set this from trip-transfer component
    // `BaseMapContext` overrides
    centerLat: Ember.computed.readOnly('polylinesCenterLat'),
    centerLng: Ember.computed.readOnly('polylinesCenterLng'),
    polylines: Ember.computed.readOnly('stopPolylines'),
    markers: Ember.computed.readOnly('aggregatedMarkers'),
    // local properties
    polylinesCenterLat: Ember.computed('polylines.@each.points', function () {
      const lats = this.get('polylines').mapBy('points').reduce((arr, points) => {
        if (Ember.isEmpty(points)) return arr;
        const polylineLats = points.map(p => p[0]);
        arr.push(...polylineLats);
        return arr;
      }, []);
      if (Ember.isEmpty(lats)) return _environment.default.tomtom.search.center.lat;
      return (Math.min(...lats) + Math.max(...lats)) / 2;
    }),
    polylinesCenterLng: Ember.computed('polylines.@each.points', function () {
      const lngs = this.get('polylines').mapBy('points').reduce((arr, points) => {
        if (Ember.isEmpty(points)) return arr;
        const polylineLngs = points.map(p => p[1]);
        arr.push(...polylineLngs);
        return arr;
      }, []);
      if (Ember.isEmpty(lngs)) return _environment.default.tomtom.search.center.lon;
      return (Math.min(...lngs) + Math.max(...lngs)) / 2;
    }),
    aggregatedMarkers: Ember.computed('stopMarkers', 'vehicleMarker', function () {
      const stopMarkers = this.get('stopMarkers');
      const vehicleMarker = this.get('vehicleMarker');
      const markers = [vehicleMarker, ...stopMarkers].compact();
      return markers;
    }),
    vehicleMarker: Ember.computed('selectedImpactView', 'currentIqRoute.avlmRoute.avlmVehicle.iqVehicle', 'destinationIqRoute.avlmRoute.avlmVehicle.iqVehicle', function () {
      const iqRoute = this.get('selectedImpactView') === 'current' ? this.get('currentIqRoute.iqRoute') : this.get('destinationIqRoute');
      if (Ember.isNone(iqRoute) || Ember.isBlank(iqRoute.get('id'))) return null;
      const iqVehicle = iqRoute.get('avlmRoute.avlmVehicle.iqVehicle') || iqRoute.get('dispatchRoute.assignedVehicle.iqVehicle');
      if (Ember.isNone(iqVehicle) || Ember.isBlank(iqVehicle.get('id'))) return null;
      return _iq.default.create({
        id: `${Ember.guidFor(this)}-vehicle-${iqVehicle.get('id')}`,
        record: iqVehicle
      });
    }),
    stopMarkers: Ember.computed('selectedImpactPeriod', 'selectedImpactView', 'currentStopMarkersBefore', 'currentStopMarkersAfter', 'destinationStopMarkersBefore', 'destinationStopMarkersAfter', function () {
      const period = this.get('selectedImpactPeriod');
      const view = this.get('selectedImpactView');

      switch (`${view}-${period}`) {
        case 'current-before':
          return this.get('currentStopMarkersBefore');

        case 'current-after':
          return this.get('currentStopMarkersAfter');

        case 'destination-before':
          return this.get('destinationStopMarkersBefore');

        case 'destination-after':
          return this.get('destinationStopMarkersAfter');

        default:
          return [];
      }
    }),
    currentStopMarkersBefore: Ember.computed('impactPayload', function () {
      const impactBefore = this.get('impactPayload.before');
      if (Ember.isEmpty(impactBefore)) return [];
      const stopPoints = impactBefore.stops;
      const contextGuid = Ember.guidFor(this);
      if (Ember.isEmpty(stopPoints)) return [];
      return stopPoints.map(stopPoint => {
        return _stopPoint.default.create({
          id: `${contextGuid}-destination-after-${stopPoint.id}`,
          record: stopPoint
        });
      });
    }),
    currentStopMarkersAfter: Ember.computed('impactPayload', function () {
      const impactAfter = this.get('impactPayload.after');
      if (Ember.isEmpty(impactAfter)) return [];
      const stopPoints = impactAfter.stops;
      const contextGuid = Ember.guidFor(this);
      if (Ember.isEmpty(stopPoints)) return [];
      return stopPoints.map(stopPoint => {
        return _stopPoint.default.create({
          id: `${contextGuid}-destination-after-${stopPoint.id}`,
          record: stopPoint
        });
      });
    }),
    destinationStopMarkersBefore: Ember.computed('impactPayload', function () {
      const impactBefore = this.get('impactPayload.before');
      if (Ember.isEmpty(impactBefore)) return [];
      const stopPoints = impactBefore.stops;
      const contextGuid = Ember.guidFor(this);
      if (Ember.isEmpty(stopPoints)) return [];
      return stopPoints.map(stopPoint => {
        return _stopPoint.default.create({
          id: `${contextGuid}-destination-after-${stopPoint.id}`,
          record: stopPoint
        });
      });
    }),
    destinationStopMarkersAfter: Ember.computed('impactPayload', function () {
      const impactAfter = this.get('impactPayload.after');
      if (Ember.isEmpty(impactAfter)) return [];
      const stopPoints = impactAfter.stops;
      const contextGuid = Ember.guidFor(this);
      if (Ember.isEmpty(stopPoints)) return [];
      return stopPoints.map(stopPoint => {
        return _stopPoint.default.create({
          id: `${contextGuid}-destination-after-${stopPoint.id}`,
          record: stopPoint
        });
      });
    }),
    stopPolylines: Ember.computed('selectedImpactPeriod', 'selectedImpactView', 'currentStopPolylinesBefore', 'currentStopPolylinesAfter', 'destinationStopPolylinesBefore', 'destinationStopPolylinesAfter', function () {
      const period = this.get('selectedImpactPeriod');
      const view = this.get('selectedImpactView');

      switch (`${view}-${period}`) {
        case 'current-before':
          return this.get('currentStopPolylinesBefore');

        case 'current-after':
          return this.get('currentStopPolylinesAfter');

        case 'destination-before':
          return this.get('destinationStopPolylinesBefore');

        case 'destination-after':
          return this.get('destinationStopPolylinesAfter');

        default:
          return [];
      }
    }),
    currentStopPolylinesBefore: Ember.computed('impactPayload', function () {
      const impactBefore = this.get('impactPayload.before');
      if (Ember.isEmpty(impactBefore)) return [];
      const stopPoints = impactBefore.stops;
      const contextGuid = Ember.guidFor(this);
      if (Ember.isEmpty(stopPoints)) return [];
      const polylines = stopPoints.reduce((arr, stopPoint) => {
        const polyline = _iq2.default.create({
          id: `${contextGuid}-current-after-${stopPoint.id}`,
          record: stopPoint
        });

        arr.push(polyline);
        return arr;
      }, []);
      return polylines;
    }),
    // TODO: derive from impact payload
    // need Source Impact Payload
    currentStopPolylinesAfter: Ember.computed('impactPayload', function () {
      const impactAfter = this.get('impactPayload.after');
      if (Ember.isEmpty(impactAfter)) return [];
      const stopPoints = impactAfter.stops;
      const contextGuid = Ember.guidFor(this);
      if (Ember.isEmpty(stopPoints)) return [];
      const polylines = stopPoints.reduce((arr, stopPoint) => {
        const polyline = _iq2.default.create({
          id: `${contextGuid}-current-after-${stopPoint.id}`,
          record: stopPoint
        });

        arr.push(polyline);
        return arr;
      }, []);
      return polylines;
    }),
    destinationStopPolylinesBefore: Ember.computed('impactPayload.before', function () {
      const impactBefore = this.get('impactPayload.before');
      if (Ember.isEmpty(impactBefore)) return [];
      const stopPoints = impactBefore.stops;
      const contextGuid = Ember.guidFor(this);
      if (Ember.isEmpty(stopPoints)) return [];
      const polylines = stopPoints.reduce((arr, stopPoint) => {
        const polyline = _iq2.default.create({
          id: `${contextGuid}-current-after-${stopPoint.id}`,
          record: stopPoint
        });

        arr.push(polyline);
        return arr;
      }, []);
      return polylines;
    }),
    // TODO: derive from impact payload
    destinationStopPolylinesAfter: Ember.computed('impactPayload', function () {
      const impactAfter = this.get('impactPayload.after');
      if (Ember.isEmpty(impactAfter)) return [];
      const stopPoints = impactAfter.stops;
      const contextGuid = Ember.guidFor(this);
      if (Ember.isEmpty(stopPoints)) return [];
      const polylines = stopPoints.reduce((arr, stopPoint) => {
        const polyline = _iq2.default.create({
          id: `${contextGuid}-destination-after-${stopPoint.id}`,
          record: stopPoint
        });

        arr.push(polyline);
        return arr;
      }, []);
      return polylines;
    })
  });

  _exports.default = _default;
});