define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/date-user-cell-item", ["exports", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item", "moment"], function (_exports, _cellItem, _moment) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateCellItem extends _cellItem.default {
    constructor(props) {
      super(props);
      const userTimezone = localStorage.getItem('userTimezone'); // Store the timezone in a class variable, default to 'UTC' if not specified

      this.timezone = userTimezone ? userTimezone : 'UTC';
    }

    getColumnValue(data, columnInfo, rowIndex, columnIndex) {
      let columnValue = super.getColumnValue(data, columnInfo, rowIndex, columnIndex);
      const {
        format
      } = columnInfo;
      columnValue = columnValue && _moment.default.utc(columnValue).tz(this.timezone).format(format);
      return columnValue;
    }

  }

  _exports.default = DateCellItem;
});