define("adept-iq/adapters/trip", ["exports", "adept-iq/adapters/-ss-schedulingService", "adept-iq/config/api-urls"], function (_exports, _ssSchedulingService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    schedulingServiceHost: _apiUrls.API.schedulingService.host,
    dispatchServiceHost: _apiUrls.API.dispatchService.host,

    // eslint-disable-next-line no-unused-vars
    async findRecord(store, type, id
    /*, snapshot*/
    ) {
      const response = await this._super(...arguments);
      const tripData = response.data; // Flatten all relationships

      if (tripData.relationships) {
        Object.entries(tripData.relationships).forEach(_ref => {
          let [key, relationship] = _ref;

          if (relationship && relationship.data && relationship.data.id) {
            tripData.attributes[key + 'Id'] = relationship.data.id;
          }
        });
      }

      if (response && response.included && response.data) {
        response.included = response.included.filter(resource => {
          switch (resource.type) {
            case 'tripStop':
              resource.attributes.tripPk = tripData.id;
              resource.attributes.tripId = tripData.attributes.tripId;
              resource.attributes.routeId = tripData.attributes.routeId;
              resource.attributes.stopType = resource.id === tripData.attributes.pickId ? 'pick' : 'drop';
              return true;

            default:
              return false;
          }
        });
      }

      return response;
    },

    urlForFindRecord(id) {
      const model = 'trip';
      const schedulingServiceHost = this.get('schedulingServiceHost');
      const baseUrl = `${schedulingServiceHost}/${model}/${id}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});