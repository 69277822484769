define("adept-iq/adapters/route", ["exports", "adept-iq/adapters/-ss-schedulingService", "moment", "pako", "adept-iq/config/api-urls", "adept-iq/data/road-supervisor"], function (_exports, _ssSchedulingService, _moment, _pako, _apiUrls, _roadSupervisor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // polyline and guidance are stubbed using static data for now
  const SIMULATED_POLYLINE = _roadSupervisor.Polyline.map(p => [p[1], p[0]]);

  const SIMULATED_GUIDANCE = _roadSupervisor.Guidance;
  let _id = 1; // simulates how guidance data will be received from /navigation-info

  const SIMULATED_GUIDANCE_DEFLATED_BASE_64 = btoa(_pako.default.deflate(JSON.stringify(SIMULATED_GUIDANCE)));

  var _default = _ssSchedulingService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    schedulingServiceHost: _apiUrls.API.schedulingService.host,

    // TODO: This is a temporary solution for creating a route
    // dispatch backend will have a proper endpoint for POST router
    buildURL(modelType, id, snapshot, requestType) {
      if (requestType === 'createRecord') {
        return `${this.host}/schedule/${snapshot.record.get('schedule.id')}/route/clusterization`;
      }

      return this._super(...arguments);
    },

    createScheduleRoute(route) {
      const ajax = this.get('ajaxService');
      const token = this.get('session.data.authenticated.token');
      const type = route.get('type') ? route.get('type') : 'regular';
      const schedulingMode = route.get('schedulingMode') ? route.get('schedulingMode') : 'automatic';
      const json = {
        data: {
          type: 'route',
          attributes: {
            name: route.get('name'),
            status: 'new',
            plannedStartTime: (0, _moment.default)(route.get('plannedStartTime')).toDate(),
            plannedEndTime: (0, _moment.default)(route.get('plannedEndTime')).toDate(),
            type,
            schedulingMode,
            affinities: route.get('affinities'),
            optimizationFlags: route.get('optimizationFlags'),
            distanceLimit: route.get('distanceLimit'),
            tripLimit: route.get('tripLimit')
          },
          relationships: {
            vehicle: {
              data: {
                id: route.get('vehicle.id'),
                type: 'vehicle'
              }
            },
            schedule: {
              data: {
                id: route.get('schedule.id'),
                type: 'schedule'
              }
            },
            providerName: {
              data: {
                id: route.get('provider.id'),
                type: 'providerName'
              }
            }
          }
        }
      };
      return ajax.post(`${_apiUrls.API.schedulingService.host}/route`, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: json
      });
    },

    // Here actionType is an optional parameter.
    // In backend, for Trimslack action we need to create activity logs.
    // Inorder to differentiate trim-slack actions from other, here we are using 'actionType' param
    updateScheduleRoute(route, actionType) {
      const ajax = this.get('ajaxService');
      const token = this.get('session.data.authenticated.token');
      const json = {
        data: {
          type: 'route',
          id: route.get('id'),
          attributes: {
            name: route.get('name'),
            status: route.get('status'),
            plannedStartTime: (0, _moment.default)(route.get('plannedStartTime')).toDate(),
            plannedEndTime: (0, _moment.default)(route.get('plannedEndTime')).toDate(),
            type: route.get('type'),
            schedulingMode: route.get('schedulingMode'),
            affinities: route.get('affinities'),
            optimizationFlags: route.get('optimizationFlags'),
            distanceLimit: route.get('distanceLimit'),
            tripLimit: route.get('tripLimit')
          }
        }
      };

      if (Ember.isPresent(route.get('vehicle.id'))) {
        const relationships = json.data.relationships || {};
        relationships.vehicle = {
          data: {
            id: route.get('vehicle.id'),
            type: 'vehicle'
          }
        };
        json.data.relationships = relationships;
      }

      if (Ember.isPresent(route.get('schedule.id'))) {
        const relationships = json.data.relationships || {};
        relationships.schedule = {
          data: {
            id: route.get('schedule.id'),
            type: 'schedule'
          }
        };
        json.data.relationships = relationships;
      }

      if (Ember.isPresent(route.get('provider.id'))) {
        const relationships = json.data.relationships || {};
        relationships.providerName = {
          data: {
            id: route.get('provider.id'),
            type: 'providerName'
          }
        };
        json.data.relationships = relationships;
      }

      let routeUrl = `${_apiUrls.API.schedulingService.host}/route/${route.get('id')}`;
      routeUrl += actionType ? `?actionType=${actionType}` : '';
      return ajax.patch(routeUrl, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: json
      });
    },

    // WIP pending special-routes API
    createSpecialRoute(driver, vehicle, dispatchSchedule) {
      const ajax = this.get('ajaxService');
      const token = this.get('session.data.authenticated.token');
      const driverId = driver.get('id');
      const vehicleId = vehicle.get('id');
      const dispatchScheduleId = dispatchSchedule.get('id');
      const providerNameId = vehicle.get('providerName'); // TODO: confirm if these come from driver

      const shiftStartMoment = (0, _moment.default)();
      const shiftEndMoment = shiftStartMoment.clone().add(1, 'hour');
      const json = {
        data: {
          type: 'specialRoute',
          attributes: {
            name: `Supervisor ${driverId} / ${vehicleId}`,
            // TODO: what goes here?
            routeType: 'supervisor',
            shiftStartTime: shiftStartMoment.format(),
            shiftEndTime: shiftEndMoment.format()
          },
          relationships: {
            dispatchSchedule: {
              data: {
                id: dispatchScheduleId,
                type: 'dispatchSchedule'
              }
            },
            driver: {
              data: {
                id: driverId,
                type: 'driver'
              }
            },
            providerName: {
              data: {
                id: providerNameId,
                type: 'providerName'
              }
            },
            vehicle: {
              data: {
                id: vehicleId,
                type: 'vehicle'
              }
            }
          }
        }
      };
      return ajax.post(`${_apiUrls.API.dispatchService.host}/special-route`, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: json
      }).then(response => {
        // TODO: unpack and return Route model
        return response;
      }).catch(() => {
        // API is not functional yet, so we stub the result here:
        return Ember.Object.create({
          id: '1'
        });
      });
    },

    // WIP pending special-routes API
    updateSpecialRouteWaypoint(route, waypoint, dispatchSchedule) {
      const ajax = this.get('ajaxService');
      const token = this.get('session.data.authenticated.token');
      const waypointId = waypoint.get('id');
      const waypointModel = waypoint.constructor.modelName;
      const dispatchScheduleId = dispatchSchedule.get('id');
      let waypointType, waypointSource;

      switch (waypointModel) {
        case 'vehicle':
          {
            waypointType = 'dynamic';
            waypointSource = 'vehicle';
            break;
          }

        case 'address':
          {
            waypointType = 'fixed';
            waypointSource = 'address';
            break;
          }

        default:
          throw new Error(`unknown waypoint type '${waypointModel}'`);
      }

      const json = {
        data: {
          type: 'stopPoint',
          attributes: {
            routeOrdinal: 1,
            waypointType,
            waypointSource
          },
          relationships: {
            dispatchSchedule: {
              data: {
                id: dispatchScheduleId,
                type: 'dispatchSchedule'
              }
            },
            waypoint: {
              data: {
                id: waypointId,
                type: 'waypoint'
              }
            }
          }
        }
      };
      const routeId = route.get('id');
      const uri = `${_apiUrls.API.dispatchService.host}/special-route/${routeId}/waypoint`;
      return ajax.put(uri, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: json
      }).then(response => {
        // TODO: return stopPoint model
        return response;
      }).catch(() => {
        // API is not functional yet; stubbing result here;
        // TODO: remove stub here
        return Ember.Object.create({
          id: '1'
        });
      });
    },

    fetchNavigationInfo() {
      // const routeId = (typeof route) === 'string' ? route : get(route, 'id');
      // const token = this.get('session.data.authenticated.token');
      // const queryParams = [];
      // const includeParams = [];
      // if (opts.includePolyline) {
      //   includeParams.push('polyline');
      // }
      // if (opts.includeGuidance) {
      //   includeParams.push('guidance');
      // }
      // if (includeParams.length > 0) {
      //   queryParams.push(`include=${includeParams.join(',')}`);
      // }
      // let uri = `${API.etaService.host}/navigation-info/${routeId}`;
      // force update
      // if (opts.update) {
      //   uri = `${uri}/updatedNavInfo`;
      // }
      // if (queryParams.length > 0) {
      //   uri = `${uri}?${queryParams.join('&')}`;
      // }
      // this.get('ajaxService').get(uri, {
      //   headers: {
      //     'Authorization': `Bearer ${token}`
      //   }
      // }).then((response) => {
      //   const store = this.get('store');
      //   store.pushPayload(response);
      //   const navigationInfo = store.peekRecord('navigation-info', response.data.id);
      //   return navigationInfo;
      // });
      // guidance decoding is done by the `guidance` transform;
      // this is a work-around to use that functionality with stubs
      const payload = {
        data: {
          id: _id++,
          type: 'navigation-info',
          attributes: {
            guidance: SIMULATED_GUIDANCE_DEFLATED_BASE_64,
            polyline: SIMULATED_POLYLINE
          }
        }
      };
      this.get('store').pushPayload('navigation-info', payload);
      const navigationInfo = this.get('store').peekRecord('navigation-info', payload.data.id);
      return Ember.RSVP.resolve(navigationInfo);
    },

    updateBulkEditRoute(routes, schedulingMode) {
      const ajax = this.get('ajaxService');
      const token = this.get('session.data.authenticated.token');
      const routeUrl = `${_apiUrls.API.schedulingService.host}/route?fields[route]=schedulingMode`;
      const data = routes.map(route => {
        const routeData = {
          type: 'route',
          id: route.id,
          attributes: {
            name: route.get('name'),
            status: route.get('status'),
            plannedSlackTime: route.get('plannedSlackTime'),
            plannedStartTime: route.get('plannedStartTime'),
            plannedEndTime: route.get('plannedEndTime'),
            type: route.get('type'),
            schedulingMode: schedulingMode,
            tripLimit: route.get('tripLimit'),
            distanceLimit: route.get('distanceLimit'),
            optimizationFlags: route.get('optimizationFlags')
          },
          relationships: {
            schedule: {
              data: {
                type: 'schedule',
                id: route.get('schedule.id')
              }
            },
            providerName: {
              data: {
                type: 'providerName',
                id: route.get('provider.id')
              }
            }
          }
        };

        if (Ember.isPresent(route.get('vehicle.id'))) {
          routeData.relationships.vehicle = {
            data: {
              type: 'vehicle',
              id: route.get('vehicle.id')
            }
          };
        }

        return routeData;
      });
      const json = {
        data: data
      };
      return ajax.patch(routeUrl, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: json
      });
    },

    urlForQuery(query, modelName) {
      const schedulingServiceHost = this.get('schedulingServiceHost');
      const baseUrl = `${schedulingServiceHost}/${modelName}`;

      if (query.scheduleId) {
        const scheduleId = query.scheduleId;
        delete query.scheduleId; // Append the RQL filter to the URL

        return `${baseUrl}?filter=eq(scheduleId%2C${scheduleId})`;
      }

      return baseUrl;
    },

    urlForFindRecord(id, modelName
    /*snapshot*/
    ) {
      const schedulingServiceHost = this.get('schedulingServiceHost');
      const baseUrl = `${schedulingServiceHost}/${modelName}/${id}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});