define("adept-iq/pods/components/rider-management-widget/rider-certification/certification-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'rider-management-widget/certification-table',
    rowComponent: 'table/rows/text-formatted-row',
    rowSelectionType: 'radio',
    modelName: 'rider-certification',
    defaultSortAsc: false,
    checkBoxColumn: false,
    isFormControls: true,
    fetchModelData: true,
    title: 'Certification',
    columns: [{
      id: 'appSentDate',
      index: 0,
      type: 'date',
      label: 'App Sent Date',
      valuePath: 'appSentDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'appReceivedDate',
      index: 1,
      type: 'date',
      label: 'App Received Date',
      valuePath: 'appReceivedDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'sentToMDDate',
      index: 2,
      type: 'date',
      label: 'Sent To MD Date',
      valuePath: 'sentToMDDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'receivedFromMDDate',
      index: 3,
      type: 'date',
      label: 'Received From MD Date',
      valuePath: 'receivedFromMDDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'assessmentDate',
      index: 16,
      type: 'date',
      label: 'Assmt. Date',
      valuePath: 'assessmentDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'assessmentHearingDate',
      index: 17,
      type: 'date',
      label: 'Assmt. Hearing Date',
      valuePath: 'assessmentHearingDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'assessmentDecisionDate',
      index: 18,
      type: 'date',
      label: 'Assmt. Decision Date',
      valuePath: 'assessmentDecisionDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'approvedDate',
      index: 10,
      type: 'date',
      label: 'Approved Date',
      valuePath: 'approvedDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'deniedDate',
      index: 11,
      type: 'date',
      label: 'Denied Date',
      valuePath: 'deniedDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'appealedDate',
      index: 4,
      type: 'date',
      label: 'Appealed Date',
      valuePath: 'appealedDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'appealHearingDate',
      index: 5,
      type: 'date',
      label: 'Appeal Hearing Date',
      valuePath: 'appealHearingDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'appealDecisionDate',
      index: 6,
      type: 'date',
      label: 'Appeal Decision Date',
      valuePath: 'appealDecisionDate',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }]
  };
  _exports.default = _default;
});