define("adept-iq/pods/components/iq-widgets/route-information-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "moment", "adept-iq/pods/components/iq-widgets/route-information-widget/config", "ember-concurrency", "adept-iq/config/mapped-permIds", "lodash"], function (_exports, _component, _moment, _config, _emberConcurrency, _mappedPermIds, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VERIFY_ROUTE = 1;
  const REOPEN_ROUTE = 2;
  const REPORT_BREAKDOWN = 3;
  const BREAKDOWN_REPLACEMENT = 4;
  const RESCUE = 5;
  const REQUIRE_FIELDS = {
    driverPhoneNumber: 'Driver Phone Number',
    startName: 'Start Name',
    endName: 'End Name',
    routeStartAddress: 'Start Address',
    routeEndAddress: 'End Address',
    odoMeterStart: 'Odo Meter Start',
    odoMeterEnd: 'Odo Meter End'
  };
  const KEEPER_ROUTE = 'keeper';
  const EXPANDED = 'expanded';
  const COLLAPSED = 'collapsed';

  var _default = _component.default.extend({
    interaction: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    reconcileLambda: Ember.inject.service(),
    session: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    serviceModeTaxiType: 'Taxi',
    classNames: ['reconcile-widget'],
    layoutName: 'components/iq-widgets/reconcile-widget',
    config: _config.default,
    reconcileRouteDetails: null,
    routeVerifyDetails: null,
    requireFieldError: null,
    isUndoClicked: false,
    enableCursorEditing: false,
    enableCursorEditingIcons: false,
    collapseState: EXPANDED,
    notesCollapseState: EXPANDED,
    // Reset map and address picker
    detailCollapseState: EXPANDED,
    isCompletedRoute: Ember.computed.alias('reconcile.isCompletedRoute'),
    isDeletedRoute: Ember.computed.alias('reconcile.isDeletedRoute'),
    routeId: Ember.computed.readOnly('reconcile.reconcileSelectedRouteId'),
    vehicleId: Ember.computed.readOnly('selectedVehicle.vehicleId'),
    driverId: Ember.computed.readOnly('selectedDriver.driverId'),
    canEdit: Ember.computed.alias('enableEditing'),
    isEditStart: Ember.computed.alias('isEditingStart'),
    reconcileTripDate: Ember.computed.alias('reconcile.reconcileDate'),
    routeStartAddressGeoNode: Ember.computed('reconcile.reconcileRouteInfo.startAddress', function () {
      return _lodash.default.clone(this.get('reconcile.reconcileRouteInfo.startAddress'));
    }),
    routeEndAddressGeoNode: Ember.computed('reconcile.reconcileRouteInfo.endAddress', function () {
      return _lodash.default.clone(this.get('reconcile.reconcileRouteInfo.endAddress'));
    }),
    isClosedRoute: Ember.computed.notEmpty('reconcileRouteDetails.closedDate'),
    reconcileRouteInfo: Ember.computed.alias('reconcile.reconcileRouteInfo'),
    disableSavebtn: Ember.computed('{odoMeterStart,odoMeterEnd,vehicleId,driverId,driverPhoneNumber,startName,endName,routeStartAddressGeoNode,routeEndAddressGeoNode,startZone,endZone,startAddressNotes,endAddressNotes}', function () {
      const reconcileRouteInfo = this.get('reconcile.reconcileRouteInfo');
      let isSaveEnable = true;

      _config.default.RECONCILE_ROUTE_EDITABLE_FIELDS_OBJ.some(reconcileEditField => {
        const {
          fieldKey,
          fieldPath,
          isValueChanged
        } = reconcileEditField;
        const newVal = this.get(fieldKey);

        const oldVal = _lodash.default.get(reconcileRouteInfo, fieldPath);

        if (isValueChanged(oldVal, newVal)) {
          isSaveEnable = false;
        }
      });

      return isSaveEnable;
    }),
    reconcileVerifiedDate: Ember.computed('reconcileRouteDetails.verifiedDate', function () {
      const date = Ember.get(this, 'reconcileRouteDetails.verifiedDate');

      if (date) {
        return (0, _moment.default)(date).format('MM/DD/YYYY');
      }

      return '';
    }),
    routeTypeOptions: Ember.computed('routeActionTypeOptions', 'selectedRouteType', function () {
      return _config.default.routeTypes;
    }),
    routeActionTypeOptions: Ember.computed('selectedRouteActionType', function () {
      return _config.default.routeActionTypes;
    }),
    zoneOptions: Ember.computed('reconcileRouteInfo', 'routeStartZone', 'routeEndZone', function () {
      const zones = this.store.peekAll('zone');
      const zoneArray = [];
      zones.map(zone => {
        if (zone && zone.displayName) {
          zoneArray.push(zone.displayName);
        }
      });
      return zoneArray;
    }),
    isRescueRoute: Ember.computed('reconcileRouteDetails.type', function () {
      const type = Ember.get(this, 'reconcileRouteDetails.type');
      return type && type.toLocaleLowerCase() === 'rescue';
    }),
    isRescueReplacementRoute: Ember.computed('reconcileRouteDetails.type', function () {
      const type = Ember.get(this, 'reconcileRouteDetails.type');
      return type && type.toLocaleLowerCase() === 'rescue replacement';
    }),
    isBreakdownReplacementRoute: Ember.computed('reconcileRouteDetails.type', function () {
      const type = Ember.get(this, 'reconcileRouteDetails.type');
      return type && type.toLocaleLowerCase() === 'breakdown replacement';
    }),
    isBreakdownRoute: Ember.computed('reconcileRouteDetails.type', function () {
      const type = Ember.get(this, 'reconcileRouteDetails.type');
      return type && type.toLocaleLowerCase() === 'breakdown';
    }),
    isRegularRoute: Ember.computed('reconcileRouteDetails.type', function () {
      return !this.isRescueRoute && !this.isRescueReplacementRoute && !this.isBreakdownRoute && !this.isBreakdownReplacementRoute;
    }),
    enableDisableRouteActionOptions: Ember.observer('isCompletedRoute', 'isDeletedRoute', function () {
      const isCompleted = Ember.get(this, 'reconcile.isCompletedRoute');

      if (isCompleted) {
        Ember.set(this, 'enableEditing', false);
        Ember.set(this, 'enableCursorEditing', false);
      }

      this.enableDisableOption();
    }),
    updateReconcileRouteDetails: Ember.observer('reconcileRouteInfo', function () {
      const routeInformation = this.get('reconcileRouteInfo');
      Ember.set(this, 'selectedRouteActionType', null);

      if (!Ember.isEmpty(routeInformation)) {
        this.enableDisableEditing(false, !this.isCompletedRoute);
        this.updateProperties(routeInformation);
        Ember.set(this, 'reconcileRouteInfo', routeInformation);

        if (!routeInformation.isCompleted) {
          Ember.set(this, 'enableCursorEditing', true);
        }
      } else {
        this.resetProperties();
      }

      this.enableDisableOption();
    }),
    routeSelectionPerformed: Ember.computed('reconcile.reconcileRouteInfo', function () {
      const routeData = this.get('reconcile.reconcileRouteInfo');
      return Ember.isPresent(routeData);
    }),

    init() {
      Ember.set(this, 'selectedRadioValue', 'start');
      Ember.set(this, 'enableEditing', false);
      Ember.set(this, 'isEditingStart', false);
      Ember.set(this, 'initialMapLayers', null);
      Ember.set(this, 'allEvents', null);
      this.set('isRouteVerified', false);
      this.set('routeVerifyDetails', {});

      this._super(...arguments);

      if (this.get('reconcile.reconcileRouteInfo')) {
        Ember.run.scheduleOnce('afterRender', this, 'updateReconcileRouteDetails');
      }

      this.resetProperties();
      this.configurePermission();
    },

    configurePermission() {
      Ember.set(this, 'isRouteVerificationAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.verifyRouteRecon, null));
      Ember.set(this, 'isRouteEditingAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editRouteInfoRecon, null));
      Ember.set(this, 'isRouteDeletingAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deleteRouteRecon, null));
      Ember.set(this, 'isRescueReplacementAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createRescueReplacementRecon, null));
      Ember.set(this, 'isReportBreakdownAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.reportBreakdownRecon, null));
      Ember.set(this, 'isBreakdownReplacementAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createBreakdownReplacementRecon, null));
      Ember.set(this, 'isReopenRouteAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.reopenRouteRecon, null));
    },

    updateProperties(routeInformation) {
      if (Ember.isEmpty(routeInformation)) {
        return;
      }

      const routeTypeOptions = this.get('routeTypeOptions');
      const routeType = routeTypeOptions.find(rt => routeInformation.scheduleRoute.type ? rt.name.toLowerCase() === routeInformation.scheduleRoute.type.toLowerCase() : '') || routeTypeOptions[0];
      const zones = this.store.peekAll('zone');
      const routeStartZone = zones.find(z => z.get('id') === routeInformation.startAddress.zoneid) || zones.firstObject;
      const routeEndZone = zones.find(z => z.get('id') === routeInformation.endAddress.zoneid) || zones.firstObject;
      const reconcileTripDate = new Date((0, _moment.default)(this.get('reconcileTripDate')).format('YYYY-MM-DD'));
      this.setProperties({
        driverOptions: this.get('reconcile.reconcileDrivers'),
        vehicleOptions: this.get('reconcile.reconcileVehicles'),
        reconcileNotes: routeInformation.startplace.address[0].reconciliationnotes,
        routeName: routeInformation.routeName,
        driverPhoneNumber: routeInformation.dispatchroutevehicledriver.driverPhoneNumber,
        plannedStartTime: (0, _moment.default)(routeInformation.plannedStartTime).format('hh:mm A'),
        plannedEndTime: (0, _moment.default)(routeInformation.plannedEndTime).format('hh:mm A'),
        actualStartTime: (0, _moment.default)(routeInformation.routeStartEvent.receivedat).format('hh:mm A'),
        actualEndTime: routeInformation.routeEndEvent ? (0, _moment.default)(routeInformation.routeEndEvent.receivedat).format('hh:mm A') : '',
        badgeNumber: routeInformation.driver.badgeNumber,
        odoMeterStart: routeInformation.routeStartEvent.odo,
        odoMeterEnd: routeInformation.routeEndEvent ? routeInformation.routeEndEvent.odo : '',
        startName: routeInformation.startplace.address[0].alias,
        endName: routeInformation.endplace.alias,
        routeStartAddress: {
          freeformAddress: routeInformation.startAddress.formattedaddress,
          lat: routeInformation.startAddress.latitude,
          lng: routeInformation.startAddress.longitude
        },
        routeStartZone: routeStartZone.get('displayName'),
        routeStartNotes: routeInformation.startplace.address[0].notes,
        routeEndAddress: {
          freeformAddress: routeInformation.endAddress.formattedaddress,
          lat: routeInformation.endAddress.latitude,
          lng: routeInformation.endAddress.longitude
        },
        routeEndZone: routeEndZone.get('displayName'),
        routeEndNotes: routeInformation.endplace.notes,
        keeper: routeInformation.scheduleRoute.type.toLowerCase() === KEEPER_ROUTE,
        tripLimit: routeInformation.scheduleRoute.triplimit,
        distanceLimit: routeInformation.scheduleRoute.distancelimit,
        selectedDriver: routeInformation.driver,
        selectedVehicle: routeInformation.vehicle,
        selectedRouteType: routeType,
        shiftTimeRange: {
          startofday: (0, _moment.default)(new Date(reconcileTripDate.getFullYear(), reconcileTripDate.getMonth() + 1)).add(+14, 'days').toISOString(),
          endofday: (0, _moment.default)(this.get('reconcileTripDate')).add(2, 'day').toISOString()
        }
      });
    },

    resetProperties() {
      this.setProperties({
        keeper: null,
        routeNotes: null,
        plannedStartTime: null,
        plannedEndTime: null,
        actualStartTime: null,
        actualEndTime: null,
        driverStartTime: null,
        driverEndTime: null,
        odoMeterStart: null,
        odoMeterEnd: null,
        startName: null,
        endName: null,
        distanceLimit: null,
        tripLimit: null,
        routeStartIdentifier: null,
        routeStartAddress: null,
        routeStartZone: null,
        routeStartNotes: null,
        routeEndIdentifier: null,
        routeEndAddress: null,
        routeEndZone: null,
        routeEndNotes: null,
        reconcileNotes: null,
        selectedDriver: null,
        driverPhoneNumber: null,
        selectedVehicle: null,
        selectedRouteType: null
      });
      Ember.set(this, 'selectedRadioValue', 'start');
      Ember.set(this, 'enableEditing', false);
      Ember.set(this, 'enableCursorEditing', false);
      Ember.set(this, 'initialMapLayers', null);
      Ember.set(this, 'allEvents', null);
      Ember.set(this, 'reconcile.reconcileRouteInfo', null);
    },

    enableDisableOption() {
      const routeActionTypeArray = [];
      const options = Ember.get(this, 'routeActionTypeOptions');
      const isCompleted = Ember.get(this, 'reconcile.isCompletedRoute');
      options.map(opt => {
        const option = {
          disabled: false,
          name: opt.name,
          id: opt.id
        };

        switch (opt.id) {
          case VERIFY_ROUTE:
            option.disabled = isCompleted || !this.isRouteVerificationAllowed;
            break;

          case REOPEN_ROUTE:
            option.disabled = !isCompleted || this.isClosedRoute || !this.isReopenRouteAllowed;
            break;

          case RESCUE:
            option.disabled = !(!isCompleted && (this.isRegularRoute || this.isRescueReplacementRoute || this.isBreakdownRoute || this.isBreakdownReplacementRoute)) || !this.isRescueReplacementAllowed;
            break;

          case REPORT_BREAKDOWN:
            option.disabled = !(!isCompleted && (this.isRegularRoute || this.isRescueReplacementRoute || this.isRescueRoute)) || !this.isReportBreakdownAllowed;
            break;

          case BREAKDOWN_REPLACEMENT:
            option.disabled = !(!isCompleted && this.isBreakdownRoute) || !this.isBreakdownReplacementAllowed;
            break;

          default:
            break;
        }

        routeActionTypeArray.push(option);
      });
      Ember.set(this, 'routeActionTypeOptions', routeActionTypeArray);
    },

    showErrorToolTip(title, tip) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: title,
        tip: tip,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    showToolTip(title, tip, type) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: title,
        tip: tip,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          if (type === REOPEN_ROUTE) {
            return Ember.get(this, 'patchRouteTask').perform('reopen route').then(() => {
              this.send('reopenRouteSuccess');
              this.get('notifications').success('THIS ROUTE WAS SUCCESSFULLY REOPENED FOR EDITING');
              tooltip.reset();
            }).catch(error => {
              let message;

              switch (error.status) {
                case 400:
                  message = 'There was a problem with one of the fields. Please check over the form and try again.';
                  break;

                default:
                  message = 'There was a problem in reopen route, please try again.';
                  break;
              }

              this.get('notifications').warning(message);
              Ember.set(this, 'selectedRouteActionType', null);
              tooltip.reset();
            });
          }

          this.toggleProperty('reconcile.isLoading');
          return this.get('saveRouteInformationTask').perform().then(() => {
            this.set('requireFieldError', null);
            this.toggleProperty('reconcile.isLoading');
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in save route, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            this.toggleProperty('reconcile.isLoading');
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          Ember.set(this, 'selectedRouteActionType', null);
          return tooltip.reset();
        },
        closeAction: () => {
          Ember.set(this, 'selectedRouteActionType', null);
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    enableDisableEditing(isEnable, isEnableCursorEditing) {
      Ember.set(this, 'enableCursorEditing', isEnableCursorEditing);
      Ember.set(this, 'enableEditing', isEnable);
      Ember.set(this, 'isEditingStart', isEnable);
    },

    getValidationError() {
      const odoStart = this.get('odoMeterStart') ? parseInt(this.get('odoMeterStart'), 10) : 0;
      const odoEnd = this.get('odoMeterEnd') ? parseInt(this.get('odoMeterEnd'), 10) : 0;

      if (odoStart > odoEnd) {
        return 'Odometer Start value cannot be greater then Odometer End value.';
      }

      return null;
    },

    handleVerifyRoute() {
      const reconcileTrips = Ember.get(this, 'reconcile.reconcileTripDetails');

      if (!reconcileTrips) {
        const title = 'Verify Route';
        const tip = 'A route containing only driver breaks or no trips on it cannot be verified. Please delete the route';
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title,
          tip,
          primaryActionText: 'OK',
          primaryAction: () => {
            Ember.set(this, 'selectedRouteActionType', null);
            this.enableDisableEditing(false, true);
            return tooltip.reset();
          },
          closeAction: () => {
            Ember.set(this, 'selectedRouteActionType', null);
            return tooltip.reset();
          },
          hasOverlay: true
        });
      } else {
        this.toggleProperty('reconcile.isLoading');
        Ember.get(this, 'getVerifyRouteTask').perform('verify route').then(() => {
          this.toggleProperty('reconcile.isLoading');
        }).catch(error => {
          let message;

          switch (error.status) {
            case 400:
              message = 'There was a problem with one of the fields. Please check over the form and try again.';
              break;

            default:
              message = 'There was a problem in verify route, please try again.';
              break;
          }

          this.get('notifications').warning(message);
          Ember.set(this, 'selectedRouteActionType', null);
          this.toggleProperty('reconcile.isLoading');
        });
      }
    },

    handleCompleteRoute() {
      const title = 'Route Information';
      const tip = 'The route was successfully verified. Do you want to close the route?';
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title,
        tip,
        primaryActionText: 'OK',
        primaryAction: () => {
          return Ember.get(this, 'verifyRouteTask').perform().then(() => {
            tooltip.reset();
            this.set('reconcile.verifyRouteIsChecked', true);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in route complete, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            Ember.set(this, 'selectedRouteActionType', null);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          Ember.set(this, 'selectedRouteActionType', null);
          return tooltip.reset();
        },
        closeAction: () => {
          Ember.set(this, 'selectedRouteActionType', null);
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    closePopupHandler() {
      this.send('cancel');
    },

    setResetVerifyRouteOptions(status, errorMessages, callBackFun) {
      this.setProperties({
        verifyRouteStatus: status,
        'reconcile.verifyRouteErrors': errorMessages,
        'reconcile.closePopupHandler': callBackFun,
        unverifiedRouteDetails: errorMessages
      });
    },

    getVerifyRouteTask: (0, _emberConcurrency.task)(function* () {
      const routeInfo = this.get('reconcileRouteInfo');
      const routeId = routeInfo.routeid;
      const verifyRouteData = yield this.reconcile.verifyRoute(routeId);

      if (Ember.isEmpty(verifyRouteData)) {
        this.setResetVerifyRouteOptions(null, null, null);
        return;
      }

      const routeData = verifyRouteData.data;
      const verifyRouteErrors = verifyRouteData.data.errors;

      if (routeData && routeData.isVerified) {
        this.setResetVerifyRouteOptions('verified', null, null);
        this.handleCompleteRoute();
      } else {
        this.setResetVerifyRouteOptions('unverified', verifyRouteErrors, this.closePopupHandler.bind(this));
      }
    }),

    //building Payload Response for the Events,Route, StartAddress, EndAddress, Place, RVD
    buildEventResForPayload(routeRes, reconcileDate) {
      return [{
        'id': routeRes.routeStartEvent.id,
        'routeId': routeRes.routeStartEvent.routeid,
        'lng': routeRes.routeStartEvent.lng,
        'lat': routeRes.routeStartEvent.lat,
        'driverId': this.get('selectedDriver').badgenumber,
        'vehicleId': this.get('selectedVehicle').callsign,
        'scheduleId': routeRes.routeStartEvent.scheduleid,
        'stopPointId': routeRes.routeStartEvent.stoppointid,
        'odo': routeRes.routeStartEvent.odo,
        'speed': routeRes.routeStartEvent.speed,
        'heading': routeRes.routeStartEvent.heading,
        'source': routeRes.routeStartEvent.source,
        'eventType': routeRes.routeStartEvent.eventtype,
        'actionType': routeRes.routeStartEvent.actiontype,
        'createdAt': routeRes.routeStartEvent.createdat,
        'updatedAt': routeRes.routeStartEvent.updatedat,
        'receivedAt': routeRes.routeStartEvent.receivedat,
        'userId': routeRes.routeStartEvent.userid,
        'op$1': routeRes.routeStartEvent.__op,
        'deleted$1': routeRes.routeStartEvent.__deleted,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }, {
        'id': routeRes.routeEndEvent.id,
        'routeId': routeRes.routeEndEvent.routeid,
        'lng': routeRes.routeEndEvent.lng,
        'lat': routeRes.routeEndEvent.lat,
        'driverId': this.get('selectedDriver').badgenumber,
        'vehicleId': this.get('selectedVehicle').callsign,
        'scheduleId': routeRes.routeEndEvent.scheduleid,
        'stopPointId': routeRes.routeEndEvent.stoppointid,
        'odo': routeRes.routeEndEvent.odo,
        'speed': routeRes.routeEndEvent.speed,
        'heading': routeRes.routeEndEvent.heading,
        'source': routeRes.routeEndEvent.source,
        'eventType': routeRes.routeEndEvent.eventtype,
        'actionType': routeRes.routeEndEvent.actiontype,
        'createdAt': routeRes.routeEndEvent.createdat,
        'updatedAt': routeRes.routeEndEvent.updatedat,
        'receivedAt': routeRes.routeEndEvent.receivedat,
        'userId': routeRes.routeEndEvent.userid,
        'op$1': routeRes.routeEndEvent.__op,
        'deleted$1': routeRes.routeEndEvent.__deleted,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }];
    },

    buildRouteResForPayload(routeRes, startAddressId, endAddressId, reconcileDate) {
      return {
        'deleted$1': routeRes.dispatchRoute.__deleted,
        'endAddressId': endAddressId,
        'startAddressId': startAddressId,
        'version': 1,
        'op$1': routeRes.dispatchRoute.__op,
        'createdAt': routeRes.dispatchRoute.createdat,
        'plannedStartTime': _moment.default.utc(this.get('plannedStartTime'), 'HH:mm').toISOString(),
        'plannedEndTime': _moment.default.utc(this.get('plannedEndTime'), 'HH:mm').toISOString(),
        'actualStartTime': _moment.default.utc(this.get('actualStartTime'), 'HH:mm').toISOString(),
        'actualEndTime': _moment.default.utc(this.get('actualEndTime'), 'HH:mm').toISOString(),
        'id': routeRes.routeid,
        'providerName': routeRes.vehicle.providername,
        'scheduleId': routeRes.scheduleRoute.scheduleid,
        'status': routeRes.status,
        'trackingId': routeRes.routeName,
        'updatedAt': routeRes.dispatchRoute.updatedat,
        'alertId': routeRes.routeStartEvent.alertid,
        'otpValue': null,
        'otpStatus': null,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': this.get('reconcileNotes')
      };
    },

    buildStartAddressResForPayload(routeRes, address, startAddressId, reconcileDate) {
      return {
        'id': startAddressId,
        'streetName': address.streetname,
        'streetNumber': address.streetnumber,
        'postalCode': address.postalcode,
        'locality': address.locality,
        'region': address.region,
        'latitude': address.latitude,
        'longitude': address.longitude,
        'formattedAddress': address.formattedaddress,
        'zoneId': address.zoneid,
        'description': address.description,
        'subRegion': address.region,
        'createdAt': routeRes.startplace.address[0].createdat,
        'updatedAt': routeRes.startplace.address[0].updatedat,
        'op$1': routeRes.startAddress.__op,
        'deleted$1': routeRes.startAddress.__deleted,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': this.get('reconcileNotes')
      };
    },

    buildEndAddressResForPayload(routeRes, address, endAddressId, reconcileDate) {
      return {
        'id': endAddressId,
        'streetName': address.streetname,
        'streetNumber': address.streetnumber,
        'postalCode': address.postalcode,
        'locality': address.locality,
        'region': address.region,
        'latitude': address.latitude,
        'longitude': address.longitude,
        'formattedAddress': address.formattedaddress,
        'zoneId': address.zoneid,
        'description': address.description,
        'subRegion': address.region,
        'createdAt': routeRes.endplace.createdat,
        'updatedAt': routeRes.endplace.updatedat,
        'op$1': routeRes.endAddress.__op,
        'deleted$1': routeRes.endAddress.__deleted,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': this.get('reconcileNotes')
      };
    },

    buildStartPlaceResForPayload(routeRes, id, reconcileDate) {
      return {
        'id': id,
        'createdAt': routeRes.startplace.createdat,
        'updatedAt': routeRes.startplace.updatedat,
        'placeCategoryTypeName': routeRes.startplace.placecategorytypename,
        'addressId': routeRes.startplace.addressid,
        'locationId': routeRes.startplace.locationid,
        'geocodingMethod': routeRes.startplace.geocodingmethod,
        'op$1': routeRes.startplace.__op,
        'deleted$1': routeRes.startplace.__deleted,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildEndPlaceResForPayload(routeRes, id, reconcileDate) {
      return {
        'id': id,
        'createdAt': routeRes.endplace.createdat,
        'updatedAt': routeRes.endplace.updatedat,
        'placeCategoryTypeName': routeRes.endplace.placecategorytypename,
        'addressId': routeRes.endplace.addressid,
        'locationId': routeRes.endplace.locationid,
        'geocodingMethod': routeRes.endplace.geocodingmethod,
        'op$1': routeRes.endplace.__op,
        'deleted$1': routeRes.endplace.__deleted,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildRVDResForPayload(rvdResponse, reconcileDate) {
      return {
        'createdAt': rvdResponse.route.createdat,
        'routeId': rvdResponse.routeid,
        'driverId': this.get('selectedDriver').badgenumber,
        'deleted$1': rvdResponse.route.__deleted,
        'startTime': rvdResponse.route.actualstarttime,
        'id': rvdResponse.route.trackingid,
        'vehicleId': this.get('selectedVehicle').callsign,
        'endTime': rvdResponse.route.actualendtime,
        'op$1': rvdResponse.route.__op,
        'scheduleId': rvdResponse.route.scheduleid,
        'updatedAt': rvdResponse.route.updatedat,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': this.get('reconcileNotes')
      };
    },

    generateAddressId(address) {
      const reconcileRouteInfo = this.get('reconcile.reconcileRouteInfo');
      const startAddress = this.get('routeStartAddress.freeformAddress');

      if (address === startAddress) {
        if (address !== reconcileRouteInfo.startAddress.formattedaddress) {
          return Math.floor(Math.random() * 1000);
        }

        return reconcileRouteInfo.startAddress.id;
      }

      if (address !== reconcileRouteInfo.endAddress.formattedaddress) {
        return Math.floor(Math.random() * 1000);
      }

      return reconcileRouteInfo.endAddress.id;
    },

    buildReconcileRoutePayload() {
      const reconcileRouteInfo = this.get('reconcile.reconcileRouteInfo');
      const reconcileRVDResponse = this.get('reconcile.reconcileRVDResponse').find(route => route.routeid === parseInt(reconcileRouteInfo.routeid, 10));
      const startAddress = this.get('routeStartAddressGeoNode');
      const endAddress = this.get('routeEndAddressGeoNode');
      const startAddressId = this.generateAddressId(this.get('routeStartAddress.freeformAddress'));
      const endAddressId = this.generateAddressId(this.get('routeEndAddress.freeformAddress'));
      const reconcileDate = new Date().toISOString();
      return {
        'events': this.buildEventResForPayload(reconcileRouteInfo, reconcileDate),
        'route': this.buildRouteResForPayload(reconcileRouteInfo, startAddressId, endAddressId, reconcileDate),
        'address': [this.buildStartAddressResForPayload(reconcileRouteInfo, startAddress, startAddressId, reconcileDate), this.buildEndAddressResForPayload(reconcileRouteInfo, endAddress, endAddressId, reconcileDate)],
        'place': [this.buildStartPlaceResForPayload(reconcileRouteInfo, startAddressId, reconcileDate), this.buildEndPlaceResForPayload(reconcileRouteInfo, endAddressId, reconcileDate)],
        'rvd': this.buildRVDResForPayload(reconcileRVDResponse, reconcileDate)
      };
    },

    buildRouteDetailsForDeletePayload(response, rvdResponse) {
      return {
        'deleted$1': false,
        'endAddressId': response.endplace.addressid,
        'startAddressId': response.startplace.addressid,
        'version': 1,
        'op$1': response.dispatchRoute.__op || '',
        'createdAt': response.dispatchRoute.createdat || response.createdAt,
        'plannedStartTime': response.plannedStartTime,
        'plannedEndTime': response.plannedEndTime,
        'id': response.routeid,
        'providerName': response.vehicle.providername,
        'scheduleId': response.scheduleRoute.scheduleid,
        'status': response.status,
        'trackingId': response.routeName,
        'updatedAt': response.dispatchRoute.updatedat || response.createdAt,
        'actualStartTime': rvdResponse.route.actualstarttime,
        'actualEndTime': rvdResponse.route.actualendtime,
        'alertId': response.routeStartEvent.alertid,
        'otpValue': null,
        'otpStatus': null,
        'reconcileDate': new Date().toISOString(),
        'isReconciled': true,
        'reconciliationNotes': rvdResponse.route.reconciliationnotes
      };
    },

    buildRVDResponseForDeletePayload(response) {
      return {
        'createdAt': response.route.createdat || response.createdat,
        'routeId': response.routeid,
        'driverId': response.driver.id,
        'deleted$1': false,
        'startTime': response.route.actualstarttime,
        'id': response.route.trackingid,
        'vehicleId': response.vehicle.id,
        'endTime': response.route.actualendtime,
        'op$1': response.route.__op || '',
        'scheduleId': response.route.scheduleid,
        'updatedAt': response.route.updatedat || response.createdat
      };
    },

    buildReconcileDeleteRoutePayload() {
      const reconcileRouteInfo = this.get('reconcile.reconcileRouteInfo');
      const reconcileRVDResponse = this.get('reconcile.reconcileRVDResponse').find(route => route.routeid === parseInt(reconcileRouteInfo.routeid, 10));
      return {
        'route': this.buildRouteDetailsForDeletePayload(reconcileRouteInfo, reconcileRVDResponse),
        'rvd': this.buildRVDResponseForDeletePayload(reconcileRVDResponse)
      };
    },

    saveRouteInformationTask: (0, _emberConcurrency.task)(function* () {
      try {
        const routePayload = this.buildReconcileRoutePayload();
        const reconcile = this.get('reconcile');
        yield reconcile.updateReconcileRouteInfo(routePayload);
        this.confirmSaveAction();
      } catch (error) {
        throw error;
      }
    }),
    patchRouteTask: (0, _emberConcurrency.task)(function* (type) {
      const routeAdapter = this.get('store').adapterFor('reconcile-route');
      const routeDetails = this.get('reconcileRouteDetails');
      const payload = {
        id: routeDetails.routeId
      };

      if (type === 'delete route') {
        payload.isDeleted = true;
      } else if (type === 'verify route') {
        payload.isCompleted = true;
        payload.verifiedBy = this.session.data.authenticated.tokenInfo.firstName;
        payload.verifiedDate = (0, _moment.default)().toISOString();
        payload.userId = this.session.data.authenticated.userId;
      } else if (type === 'reopen route') {
        payload.isCompleted = false;
        payload.reopenedBy = this.session.data.authenticated.tokenInfo.firstName;
        payload.reopenedDate = (0, _moment.default)().toISOString();
        payload.userId = this.session.data.authenticated.userId;
      }

      yield routeAdapter.patchRoute(payload, type);
    }),
    verifyRouteTask: (0, _emberConcurrency.task)(function* () {
      const routeInfo = this.get('reconcileRouteInfo');
      const routeId = routeInfo.routeid;
      const verifyRouteData = yield this.reconcile.verifyRoute(routeId);
      const isverified = verifyRouteData.data.isVerified;

      if (isverified) {
        this.set('routeVerifyDetails.verifiedBy', this.session.data.authenticated.tokenInfo.firstName);
        this.set('routeVerifyDetails.verifiedDate', (0, _moment.default)().toISOString());
        this.set('routeVerifyDetails.userId', this.session.data.authenticated.userId);
        this.set('isRouteVerified', true);
        this.set('selectedRouteActionType', null);
        this.enableDisableEditing(false, false);
        this.updateRouteInformation();
      }
    }),

    formattedPhoneNo(phone) {
      if (!phone) {
        return;
      }

      const phoneNoStr = phone.toString().replace(/[^\d]/g, '');

      if (phoneNoStr.length === 10) {
        return phoneNoStr.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }

      return phoneNoStr;
    },

    updateRouteInformation() {
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });
    },

    requireFieldVallidation() {
      const errors = [];
      const requiredFields = Object.keys(REQUIRE_FIELDS);
      this.set('requireFieldError', {});
      requiredFields.forEach(rf => {
        const fieldValue = this.get(rf);

        if (Ember.isEmpty(fieldValue)) {
          errors.push({
            validation: `${REQUIRE_FIELDS[rf]} is required`
          });
          this.set(`requireFieldError.${rf}`, true);
        }
      });
      return errors;
    },

    routeInfoValidation() {
      const errors = this.requireFieldVallidation();
      const odoMeterStart = this.get('odoMeterStart') ? parseInt(this.get('odoMeterStart'), 10) : 0;
      const odoMeterEnd = this.get('odoMeterEnd') ? parseInt(this.get('odoMeterEnd'), 10) : 0;

      if (odoMeterStart > odoMeterEnd) {
        errors.pushObject({
          validation: 'Odometer Start value cannot be greater then Odometer End value.'
        });
        this.set('requireFieldError.odoMeterStart', true);
      }

      const tooltip = this.get('tooltip');

      if (!errors.length) {
        return true;
      }

      tooltip.pushConfirmation({
        title: 'Route Information',
        hasoverlay: true,
        errors,
        primaryActionText: 'OK',
        primaryAction: () => {
          this.set('inValidInfo', this.get('inValidRouteInfoError'));
          tooltip.reset();
        }
      });
      return false;
    },

    updateRouteIdsInformation() {
      this.fetchRouteIdsTask.perform();
    },

    getGeoNode(selectedAddress) {
      const geocode = this.get('geocode');
      return geocode.getGeonode(selectedAddress.freeformAddress).then(result => {
        return result;
      });
    },

    buildStartAndEndAddress(selectedAddress) {
      const addressDetails = selectedAddress.attributes;
      const formattedAddress = this.buildFormattedAddress(addressDetails.address);
      return {
        streetname: addressDetails.address.streetName,
        formattedaddress: formattedAddress,
        postalcode: addressDetails.address.postalCode,
        latitude: addressDetails.coordinates.lat,
        locality: addressDetails.address.city,
        zoneid: selectedAddress.zone.zoneName,
        longitude: addressDetails.coordinates.lng,
        streetnumber: addressDetails.address.streetNumber,
        description: addressDetails.address.city,
        region: addressDetails.address.state
      };
    },

    buildFormattedAddress(address) {
      return `${address.streetNumber} ,${address.streetName},${address.city}, ${address.state}`;
    },

    async setDriverFields(selectedOption) {
      const routeResponse = this.get('reconcile.routeResponseFromTripDate');
      const foundRouteInfo = routeResponse.find(response => {
        return response.driver.id === selectedOption.id;
      });
      this.setProperties({
        driverPhoneNumber: foundRouteInfo.dispatchroutevehicledriver.driverPhoneNumber
      });
    },

    confirmSaveAction() {
      Ember.set(this, 'selectedRadioValue', 'start');
      Ember.set(this, 'saveDataPopup', '');
      Ember.set(this, 'enableCursorEditingIcons', false);
      this.get('notifications').success('ROUTE ' + this.get('reconcileRouteInfo').routeName + ' SAVED SUCCESSFULLY');
      this.enableDisableEditing(false, true);
    },

    actions: {
      async onChangeStartLocation(record) {
        this.set('routeStartAddress', record);
        const geoNode = await this.getGeoNode(record);
        this.set('routeStartZone', geoNode.zone.zoneDisplayName);
        const startAddress = this.buildStartAndEndAddress(geoNode);
        this.set('routeStartAddressGeoNode', startAddress);
        this.enableDisableEditing(true, false);
      },

      async onChangeEndLocation(record) {
        this.set('routeEndAddress', record);
        const geoNode = await this.getGeoNode(record);
        this.set('routeEndZone', geoNode.zone.zoneDisplayName);
        const endAddress = this.buildStartAndEndAddress(geoNode);
        this.set('routeEndAddressGeoNode', endAddress);
        this.enableDisableEditing(true, false);
      },

      oninputTextChange(type, value) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'enableCursorEditing', false);

        switch (type) {
          case _config.default.DRIVER_PHONE_NUMBER:
            Ember.set(this, 'driverPhoneNumber', this.formattedPhoneNo(value));
            break;

          case _config.default.ODOMETER_START:
            Ember.set(this, 'odoMeterStart', value);
            break;

          case _config.default.ODOMETER_END:
            Ember.set(this, 'odoMeterEnd', value);
            break;

          case _config.default.RECONCILE_NOTES:
            Ember.set(this, 'reconcileNotes', value);
            break;

          case _config.default.ROUTE_START_IDENTIFIER:
            Ember.set(this, 'startName', value);
            break;

          case _config.default.ROUTE_START_NOTES:
            Ember.set(this, 'routeStartNotes', value);
            break;

          case _config.default.ROUTE_END_IDENTIFIER:
            Ember.set(this, 'endName', value);
            break;

          case _config.default.ROUTE_END_NOTES:
            Ember.set(this, 'routeEndNotes', value);
            break;

          default: // code block

        }
      },

      isNumber(keyCode) {
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
          return false;
        }

        return true;
      },

      onProviderOptionSelect(option) {
        Ember.set(this, 'selectedProvider', option);
        this.notifyPropertyChange('vehicleOptions');
        Ember.set(this, 'selectedRoute', null);
        Ember.set(this, 'selectedVehicle', null);
      },

      onRouteTypeSelect(option) {
        Ember.set(this, 'selectedRouteType', option);
        this.enableDisableEditing(true, false);
      },

      onRouteActionTypeSelect(option) {
        Ember.set(this, 'selectedRouteActionType', option);

        switch (option.id) {
          case VERIFY_ROUTE:
            this.handleVerifyRoute();
            break;

          case REOPEN_ROUTE:
            this.showToolTip('Route Information', 'Reopen this route to allow editing?', REOPEN_ROUTE);
            break;

          case BREAKDOWN_REPLACEMENT:
            this.get('workspace').pushState('reconcileReplacementBreakdown', {
              route: this.get('reconcileRouteDetails'),
              action: this.actions.closeDialogHandler.bind(this, option.id)
            });
            break;

          case REPORT_BREAKDOWN:
            this.get('workspace').pushState('reconcileVehicleBreakdown', {
              route: this.get('reconcileRouteDetails'),
              action: this.actions.closeDialogHandler.bind(this, option.id)
            });
            break;

          case RESCUE:
            this.get('workspace').pushState('rescueRouteReconcile', {
              route: this.get('reconcileRouteInfo'),
              action: this.actions.closeDialogHandler.bind(this, option.id)
            });
            break;

          default:
            break;
        }
      },

      onVehicleOptionSelect(option) {
        Ember.set(this, 'selectedVehicle', option);
        const reconcile = this.get('reconcile');
        const rvdResponse = reconcile.get('reconcileRVDResponse');
        const vehicleOptions = reconcile.get('getVehicleOptions')(rvdResponse, option);
        this.set('vehicleOptions', vehicleOptions);
        this.enableDisableEditing(true, false);
      },

      onDriverOptionSelect(option) {
        Ember.set(this, 'selectedDriver', option);
        const reconcile = this.get('reconcile');
        const rvdResponse = reconcile.get('reconcileRVDResponse');
        const driverOptions = reconcile.get('getDriverOptions')(rvdResponse, option);
        this.set('driverOptions', driverOptions);
        this.setDriverFields(option);
        this.enableDisableEditing(true, false);
      },

      onChangeTime(valuePath, value) {
        this.enableDisableEditing(true, true);
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.format('LT'));
      },

      reopenRouteSuccess() {
        Ember.set(this, 'enableCursorEditing', true);
        Ember.set(this, 'selectedRouteActionType', null);
        const routeDetails = Ember.get(this, 'reconcile.reconcileRouteDetails');
        Ember.set(routeDetails, 'isCompleted', false);
      },

      deleteRouteSuccess() {
        this.resetProperties();
      },

      cancel() {
        Ember.set(this, 'saveDataPopup', '');
        Ember.set(this, 'selectedRouteActionType', null);
        this.setResetVerifyRouteOptions(null, null, null);
        this.enableDisableEditing(false, true);
      },

      cancelAction() {
        Ember.set(this, 'enableEditing', false);
        this.setResetVerifyRouteOptions(null, null, null);
      },

      onEditButtonClick() {
        Ember.set(this, 'selectedRouteActionType', null);
        this.enableDisableEditing(true, false);
      },

      onSaveButtonClick() {
        const validRouteInfo = this.routeInfoValidation();

        if (validRouteInfo) {
          this.showToolTip('Route Information', 'Do you want to save route information?', 'Save Route');
          Ember.set(this, 'workspace.activeContext.topActiveContext.resetMapFlag', true);
        }
      },

      onUndoButtonClick() {
        const map = this.get('workspace.activeContext.topActiveContext.map');

        if (this.get('geocode.activeMarker')) {
          map.removeMarker(this.get('geocode.activeMarker'));
        }

        this.set('isUndoClicked', true);
        this.set('requireFieldError', null);
        this.set('selectedRadioValue', 'start');
        this.enableDisableEditing(false, true);
        const routeInformation = Ember.get(this, 'reconcile.reconcileRouteInfo');
        this.updateProperties(routeInformation);
        this.set('canReset', true);
        this.set('routeStartAddressGeoNode', routeInformation.startAddress);
        this.set('routeEndAddressGeoNode', routeInformation.endAddress);
      },

      onRadioButtonClick(val) {
        this.set('selectedRadioValue', val);
      },

      async onDeleteButtonClick() {
        const reconcileRouteInfo = this.get('reconcile.reconcileRouteInfo');
        const isTripExists = await this.reconcileLambda.validateRouteBeforeDeleting.perform(reconcileRouteInfo.routeid, 'validate');
        const title = 'Route Information';
        let tip = `Do you want to delete route ${this.get('reconcileRouteInfo').routeid}?`;
        const successMsg = `ROUTE ${this.get('reconcileRouteInfo').routeid} DELETED SUCCESSFULLY`;
        const tooltip = this.get('tooltip');

        if (isTripExists.count !== '0') {
          tip = isTripExists.message;
          tooltip.pushConfirmation({
            title,
            tip,
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            },
            hasOverlay: true
          });
        } else {
          const payload = this.buildReconcileDeleteRoutePayload();
          tooltip.pushConfirmation({
            title,
            tip,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('reconcile').deleteReconcileRouteInfo(payload, 'delete').then(() => {
                this.send('deleteRouteSuccess');
                this.get('notifications').success(successMsg);
                tooltip.reset();
              }).catch(error => {
                let message;

                switch (error.status) {
                  case 400:
                    message = 'There was a problem with one of the fields. Please check over the form and try again.';
                    break;

                  default:
                    message = 'There was a problem in delete route, please try again.';
                    break;
                }

                this.get('notifications').warning(message);
                tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              this.send('cancelAction');
              return tooltip.reset();
            },
            hasOverlay: true
          });
        }
      },

      onLocationHeaderClick() {
        const collapseState = this.get('collapseState');

        if (collapseState === COLLAPSED) {
          this.set('collapseState', EXPANDED);
        } else {
          this.set('collapseState', COLLAPSED);
        }
      },

      onReconcileNotesHeaderClick() {
        const notesCollapseState = this.get('notesCollapseState');

        if (notesCollapseState === COLLAPSED) {
          this.set('notesCollapseState', EXPANDED);
        } else {
          this.set('notesCollapseState', COLLAPSED);
        }
      },

      onDetailsHeaderClick() {
        const detailCollapseState = this.get('detailCollapseState');

        if (detailCollapseState === COLLAPSED) {
          this.set('detailCollapseState', EXPANDED);
        } else {
          this.set('detailCollapseState', COLLAPSED);
        }
      },

      closeDialogHandler(option, isSaved) {
        if (option === REPORT_BREAKDOWN) {
          Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
        }

        Ember.set(this, 'selectedRouteActionType', null);

        if (!isSaved) {
          return;
        }

        if (option === REPORT_BREAKDOWN) {
          this.updateRouteInformation();
          Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
        } else if (option === BREAKDOWN_REPLACEMENT || option === RESCUE) {
          this.updateRouteIdsInformation();
          this.updateRouteInformation();
        }
      },

      onZoneOptionsSelect(isStart, option) {
        this.enableDisableEditing(true, false);

        if (isStart) {
          Ember.set(this, 'routeStartZone', option);
        } else {
          Ember.set(this, 'routeEndZone', option);
        }
      }

    }
  });

  _exports.default = _default;
});