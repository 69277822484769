define("adept-iq/pods/sandbox-setup/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    workspace: Ember.inject.service(),
    step: '1',
    // Computed property to return the current step
    currentStep: Ember.computed('step', function () {
      return this.get('step');
    }),

    didInsertElement() {
      this._super(...arguments);

      this._setupCarouselEvent();
    },

    willDestroyElement() {
      this._super(...arguments);

      this._teardownCarouselEvent();
    },

    actions: {
      goToSlide(slideNumber) {
        this.set('step', `${slideNumber}`); // Update the step property
      },

      handleTaskCompletion() {
        const nextStep = parseInt(this.get('step'), 10) + 1; // Delay of 5 second before transitioning to the next slide

        setTimeout(() => this.send('goToSlide', nextStep), 2000);
      }

    }
  });

  _exports.default = _default;
});