define("adept-iq/pods/components/side-drawer/permissions-add-role/component", ["exports", "ember-concurrency", "lodash", "adept-iq/config/api-urls", "adept-iq/config/mapped-permIds"], function (_exports, _emberConcurrency, _lodash, _apiUrls, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['compose-role-side-drawer'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    collapseState: null,
    onCloseClick: null,
    record: null,
    isSearchEnabled: false,
    searchText: '',
    canSearch: true,
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    inputRoles: Ember.computed.readOnly('stackFrame.options.roles'),
    title: Ember.computed.readOnly('stackFrame.options.displayName'),
    roleData: Ember.computed.readOnly('stackFrame.options.roleData'),
    roles: Ember.computed('inputRoles', function () {
      const inputRoles = this.get('inputRoles');

      if (inputRoles.length === 0) {
        return [Ember.Object.create({
          roleName: '',
          id: '',
          displayName: '',
          status: 'active',
          permissionList: [],
          isNew: true
        })];
      }

      return inputRoles.map(r => {
        return Ember.Object.create({
          roleName: r.get('roleName'),
          id: r.get('id'),
          displayName: r.get('displayName'),
          status: r.get('status'),
          permissionList: r.get('permissionList'),
          isNew: false
        });
      });
    }),
    canSave: Ember.computed('isSaveEnable', function () {
      const isSaveEnable = this.get('isSaveEnable');
      return isSaveEnable;
    }),
    canUndo: Ember.computed('isUndoEnable', function () {
      const isUndoEnable = this.get('isUndoEnable');
      return isUndoEnable;
    }),

    init() {
      this._super(...arguments);

      this.set('collapseState', {});
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('systemConfig').set('sideDrawerPreventCollapse', true);
      this.refresh();
    },

    didDestroyElement() {
      this.get('systemConfig').set('sideDrawerPreventCollapse', null);
    },

    initSections() {
      const sections = this.preparePermissionSections();
      this.set('sections', sections);
    },

    isForbidden() {
      const record = this.get('roles.firstObject');
      const currentPerms = record.get('permissionList').sort((a, b) => a - b);
      const removedPermList = Object.entries(record.get('permissionHash')).map(_ref => {
        let [key, value] = _ref;

        if (!value) {
          return parseInt(key, 10);
        }

        return null;
      }).filter(val => Ember.isPresent(val)).sort((a, b) => a - b);
      const removedPermissions = (0, _lodash.intersection)(currentPerms, removedPermList) || [];
      const roleName = record.get('roleName') ? record.get('roleName') : record.get('displayName').replace(/\s+/, '');

      if (removedPermissions.includes(_mappedPermIds.default.accessPermissionsWorkspace)) {
        // The Permissions workspace can NOT be removed for the admin role. If user tries to edit the Admin role and remove the View Permissions Workspace, a black popup is displayed
        if (roleName === 'admin') {
          return true;
        }
      }

      return false;
    },

    isValid() {
      let isValid = true;
      const roles = this.get('roles');
      const isNewRole = roles[0].get('isNew');
      const alphanumericRegex = /^[a-z\d\s]+$/i;
      const enteredRoleName = roles[0].displayName.trim();
      Ember.set(this, 'errorMessages', []);

      if (_lodash.default.isEmpty(enteredRoleName)) {
        isValid = false;
        this.errorMessages.push('* Role name is empty');
        return;
      }

      if (!alphanumericRegex.test(enteredRoleName) || enteredRoleName.length > 32) {
        isValid = false;
        this.errorMessages.push('* Role Name can only have alphanumeric characters i.e. (a-z, A-Z, 0-9) and max length up to 32 characters and can not be  empty.');
        return;
      }

      this.get('roleData').map(data => {
        if (roles[0].displayName.toLowerCase() === data.displayName.toLowerCase() && isNewRole) {
          isValid = false;
          this.errorMessages.push('* Duplicated role name');
          return;
        } else if (roles[0].displayName.toLowerCase() === data.displayName.toLowerCase() && data.id !== roles[0].id) {
          isValid = false;
          this.errorMessages.push('* Duplicated role name');
          return;
        }
      });
      return isValid;
    },

    updateUndoStatus(status) {
      let canSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.set('isUndoEnable', status);

      if (canSave && status) {
        this.set('isSaveEnable', true);
      } else if (!status) {
        this.set('isSaveEnable', false);
      }
    },

    searchPermissions(value) {
      const searchText = this.get('searchText');
      const regex = new RegExp(searchText, 'gi');
      const category = value.category + ' Permissions';
      let found = false;
      const foundDescription = value.description.replace(regex, function (str) {
        found = true;
        return `<span class="search-highlighted">${str}</span>`;
      });
      const foundCategory = category.replace(regex, function (str) {
        found = true;
        return `<span class="search-highlighted">${str}</span>`;
      });
      return found ? {
        highlightedDescription: foundDescription,
        highlightedCategory: foundCategory
      } : null;
    },

    createPermissionSection(permissions) {
      if (permissions.length === 0) return;
      const permission = permissions[0];
      const noOfCheckedPermissions = [];
      const searchedPermissions = [];
      const searchText = this.get('searchText');
      const isSearchEnabled = this.get('isSearchEnabled');
      const isSearchEffective = isSearchEnabled && Ember.isPresent(searchText) && searchText.length >= 3;
      permissions.map(value => {
        if (permission && permission.category.toLowerCase() === value.category.toLowerCase()) {
          value.set('highlightedDescription', value.description);
          value.set('highlightedCategory', value.category + ' Permissions');

          if (isSearchEffective) {
            const searchResult = this.searchPermissions(value);

            if (!_lodash.default.isEmpty(searchResult)) {
              if (!_lodash.default.isEmpty(searchResult.highlightedCategory)) value.set('highlightedCategory', searchResult.highlightedCategory);
              if (!_lodash.default.isEmpty(searchResult.highlightedDescription)) value.set('highlightedDescription', searchResult.highlightedDescription);
              searchedPermissions.push(value);
            }
          } else {
            searchedPermissions.push(value);
          }

          if (value.state) {
            noOfCheckedPermissions.push(value);
          }
        }
      });
      return searchedPermissions.length > 0 ? {
        title: Ember.String.capitalize(`${permission.highlightedCategory} (${noOfCheckedPermissions.length}/${permissions.length})`),
        category: permission.category,
        fields: [{
          id: `${permission.category}-Permissions`,
          type: 'permission-section',
          valuePath: 'permissionHash',
          modelName: 'sso-permission',
          isLabel: false,
          extra: {
            category: `${permission.category}`,
            groupedPermissions: searchedPermissions
          }
        }]
      } : null;
    },

    preparePermissionSections() {
      const groupedPermissions = this.get('groupedPermissions');
      const permKeys = (0, _lodash.keys)(groupedPermissions) || [];
      this.set('categories', permKeys);
      const sections = permKeys.map(key => {
        return this.createPermissionSection(groupedPermissions[key]);
      }).filter(e => {
        return e;
      });
      return sections;
    },

    reconfigurePermissionHash(role, permissions) {
      const permissionList = role.get('permissionList');
      const permHash = permissions.reduce((acu, perm) => {
        acu[perm.parsedId] = false;
        return acu;
      }, {});
      permissionList.forEach(id => {
        permHash[id] = true;
      });
      role.set('permissionHash', permHash);
    },

    reconfigurePermissionCheck(role, permissions) {
      const permissionList = role.get('permissionList');
      permissions.map(data => {
        permissionList.map(item => {
          if (item === data.parsedId) {
            data.state = true;
          }
        });
      });
    },

    refresh() {
      this.get('refreshTask').perform();
    },

    reset() {},

    refreshTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('refreshPermissionTask').perform();
    }),
    refreshPermissionTask: (0, _emberConcurrency.task)(function* () {
      const roles = this.get('roles') || [];
      const permissions = yield this.get('store').findAll('sso-permission');
      permissions.map(data => {
        if (data && data.state) {
          data.set('state', false);
        }
      });
      const groupedPermissions = (0, _lodash.groupBy)(permissions.toArray(), function (value) {
        return value.category.toLowerCase();
      });
      this.set('permissions', permissions);
      this.set('groupedPermissions', groupedPermissions);
      roles.forEach(role => {
        this.reconfigurePermissionHash(role, permissions);
        this.reconfigurePermissionCheck(role, permissions);
        const displayRoleName = role.get('displayName');
        this.set('displayRoleName', displayRoleName);
      });
      this.initSections();
    }),
    refreshGroupedPermTask: (0, _emberConcurrency.task)(function* () {
      const permissions = yield this.get('store').findAll('sso-permission');
      const groupedPermissions = (0, _lodash.groupBy)(permissions.toArray(), function (value) {
        return value.category.toLowerCase();
      });
      this.set('groupedPermissions', groupedPermissions);
      this.initSections();
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      // using custom api call because sso api is non standard
      // and in-consistent.
      const ajax = this.get('ajax');
      const session = this.get('session');
      const roles = this.get('roles') || [];
      const notifications = this.get('notifications');
      const rolePermAssociations = yield ajax.request(`${_apiUrls.API.ssoService.host}/role-permission`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      yield Promise.all(roles.map(async record => {
        let currentPerms = record.get('permissionList');
        const displayName = record.get('displayName');
        let roleId = record.get('id');
        const roleName = record.get('roleName') ? record.get('roleName') : record.get('displayName').replace(/\s+/, '');
        const rolePermAssocByRole = record.get('isNew') ? [] : rolePermAssociations.filter(rolePerm => rolePerm.roleId.toString() === roleId);
        currentPerms = currentPerms.sort((a, b) => a - b); // create a new role

        if (record.get('isNew')) {
          const json = {
            roleName: roleName,
            status: 'active',
            displayName
          };
          await ajax.post(`${_apiUrls.API.ssoService.host}/role`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).then(response => {
            roleId = response.id;
          }).catch(e => {
            notifications.warning(`Unable to Create Role ${displayName}`);
            return Promise.reject(e); // failed to create role, won't be able to assign permissions

            console.error(e); // eslint-disable-line
          });
        } // otherwise patch the role
        else {
          const json = {
            displayName,
            status: 'active'
          };
          await ajax.patch(`${_apiUrls.API.ssoService.host}/role/${roleId}`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).catch(e => {
            notifications.warning(`Unable to Update Role ${displayName}`);
            console.error(e); // eslint-disable-line
          });
        }

        const newPermList = Object.entries(record.get('permissionHash')).map(_ref2 => {
          let [key, value] = _ref2;

          if (value) {
            return parseInt(key, 10);
          }

          return null;
        }).filter(val => Ember.isPresent(val)).sort((a, b) => a - b);
        const removedPermList = Object.entries(record.get('permissionHash')).map(_ref3 => {
          let [key, value] = _ref3;

          if (!value) {
            return parseInt(key, 10);
          }

          return null;
        }).filter(val => Ember.isPresent(val)).sort((a, b) => a - b); // remove any permissions

        const removedPermissions = (0, _lodash.intersection)(currentPerms, removedPermList) || [];
        await removedPermissions.reduce((acu, permId) => {
          const removedPermAssocId = rolePermAssocByRole.find(rolePerm => rolePerm.permissionId === permId);
          return acu.then(() => ajax.del(`${_apiUrls.API.ssoService.host}/role-permission/${removedPermAssocId.id}`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          }).catch(e => {
            notifications.warning(`Unable to Remove Permission ${permId}`);
            console.error(e); // eslint-disable-line
          }));
        }, Promise.resolve()); // add any new permissions

        const newPermissions = (0, _lodash.difference)(newPermList, currentPerms) || [];
        return newPermissions.reduce((acu, permId) => {
          const json = {
            roleId: roleId,
            permissionId: permId
          };
          return acu.then(() => ajax.post(`${_apiUrls.API.ssoService.host}/role-permission`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).catch(e => {
            notifications.warning(`Unable to Save New Permission ${permId}`);
            console.error(e); // eslint-disable-line
          }));
        }, Promise.resolve());
      })).then(async () => {// await this.get('store').findAll('perm-sso-role');
        // await this.get('workspaceContext').get('reloadWorkspace').perform();
        // return this.get('activeContext.topActiveContext').refreshTableContent.perform(['perm-sso-role']);
      }).catch(e => {
        console.error(e); // eslint-disable-line
      });
    }),

    showForbiddenNotice() {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Permissions',
        tip: 'Removing access to Permissions workspace for the Administrator role is not permitted.',
        hasOverlay: true,
        primaryActionText: 'OK',
        primaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    showToolTip() {
      Ember.set(this, 'errorMessages', []);
      const tooltip = this.get('tooltip');
      const role = this.get('roles')[0];
      const isNewRole = role.get('isNew');
      const tip = isNewRole ? 'Do you want to add a new role?' : 'Do you want to commit the changes?';
      const successBanner = isNewRole ? 'ADDED ROLE SUCCESSFULLY' : 'EDITED ROLE SUCCESSFULLY';
      const warningBanner = isNewRole ? 'FAILED TO ADD ROLE' : 'FAILED TO UPDATE ROLE';
      tooltip.pushConfirmation({
        title: isNewRole ? 'Add Role' : 'Edit Role',
        tip,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveTask').perform().then(() => {
            this.get('notifications').success(`${successBanner}`);
          }).catch(() => {
            this.get('notifications').warning(`${warningBanner}`);
          }).finally(() => {
            if (this.callBack) {
              this.callBack(true);
            }

            this.get('workspace').popState('permAddRole');
            return tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    focusSearchInput() {
      Ember.$('.role-search-input').focus();
    },

    actions: {
      onValueChange(record, valuePath, options) {
        const value = options;
        record.set(valuePath, value);
        this.updateUndoStatus(true);
      },

      onPermChange(record, valuePath, options) {
        const permHash = record.get(valuePath);
        const {
          id,
          state
        } = options;
        Ember.set(permHash, id, state);
        record.set(valuePath, permHash);
        this.get('refreshGroupedPermTask').perform();
        this.updateUndoStatus(true); // this.initSections();
      },

      onSaveRoleButtonClick() {
        if (this.isForbidden()) {
          this.showForbiddenNotice();
        } else if (this.isValid()) {
          this.showToolTip();
        }
      },

      cancel() {
        if (this.callBack) {
          this.callBack(false);
        }
      },

      onUndoClick() {
        const roles = this.get('roles') || [];
        roles.forEach(role => {
          this.reconfigurePermissionHash(role, this.permissions);
          role.set('displayName', this.displayRoleName);
        });
        this.refresh();
        this.set('errorMessages', []);
        this.updateUndoStatus(false);
        this.set('searchText', '');
        this.initSections();
      },

      onCloseClick() {
        this.onCloseClick();
      },

      onSearchClick() {
        const isSearchEnabled = this.get('isSearchEnabled');
        this.set('searchText', '');

        if (isSearchEnabled) {
          this.set('isSearchEnabled', false);
          this.set('sections', this.preparePermissionSections());
          return;
        }

        this.set('isSearchEnabled', true);
        Ember.run.scheduleOnce('afterRender', this, 'focusSearchInput');
      },

      onSearchInput(value) {
        const isSearchEnabled = this.get('isSearchEnabled');
        const searchedText = this.get('searchText') + value;
        this.updateUndoStatus(false, false);

        if (isSearchEnabled) {
          this.set('searchText', value);
          this.set('sections', this.preparePermissionSections());
        }

        if (!_lodash.default.isEmpty(searchedText)) this.updateUndoStatus(true, false);
      }

    }
  });

  _exports.default = _default;
});