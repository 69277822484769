define("adept-iq/pods/components/rider-management-widget/rider-eligibility/component", ["exports", "ember-concurrency", "moment", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _moment, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    rider: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    booking: Ember.inject.service(),
    workspace: Ember.inject.service(),
    riderEligibilityRecord: null,
    riderServiceNeedRecord: null,
    prevEligibilityRecord: null,
    curbToCurbRecord: null,
    doorToDoorRecord: null,
    visuallyAssuredEntryRecord: null,
    tableRef: null,
    isOpen: false,
    canShowRiderEligibilityFormModal: false,
    isCreatingNewEligibility: false,
    title: null,
    riderId: Ember.computed.readOnly('riderChangeSet.id'),
    riderEligibilities: Ember.computed('riderChangeSet.{riderEligibilities,riderEligibilities.[]}', function () {
      return this.get('riderChangeSet.riderEligibilities').toArray();
    }),
    curbToCurbServiceNeed: Ember.computed('riderChangeSet.riderServiceNeeds.[]', function () {
      const riderServiceNeeds = this.get('riderChangeSet.riderServiceNeeds');
      return riderServiceNeeds.find(riderServiceNeed => {
        return riderServiceNeed.get('isCurbToCurb');
      });
    }),
    doorToDoorServiceNeed: Ember.computed('riderChangeSet.riderServiceNeeds.[]', function () {
      const riderServiceNeeds = this.get('riderChangeSet.riderServiceNeeds');
      return riderServiceNeeds.find(riderServiceNeed => {
        return riderServiceNeed.get('isDoorToDoor');
      });
    }),
    visuallyAssuredEntryServiceNeed: Ember.computed('riderChangeSet.riderServiceNeeds.[]', function () {
      const riderServiceNeeds = this.get('riderChangeSet.riderServiceNeeds');
      return riderServiceNeeds.find(riderServiceNeed => {
        return riderServiceNeed.get('isVisuallyAssuredEntry');
      });
    }),
    isOpened: Ember.computed.oneWay('isOpen'),

    setupServiceNeedsDropdown() {
      const configServiceNeeds = this.store.peekAll('service-need-type');

      if (this.get('riderChangeSet.riderServiceNeeds').length) {
        const riderServiceNeeds = this.get('riderChangeSet.riderServiceNeeds').filter(riderServiceNeed => !riderServiceNeed.get('isSoftDeleted')).toArray();
        const configServiceNeedsMapper = {};
        configServiceNeeds.forEach(configServiceNeed => {
          const configServiceNeedName = configServiceNeed.get('id');
          configServiceNeed.set('isChecked', false);
          configServiceNeedsMapper[configServiceNeedName] = configServiceNeed;
        });
        riderServiceNeeds.forEach(riderServiceNeed => {
          const serviceNeedId = riderServiceNeed.get('serviceNeedType.id');
          const serviceNeedType = configServiceNeedsMapper[serviceNeedId];
          serviceNeedType.set('isChecked', true);
        });

        if (riderServiceNeeds.length > 1) {
          this.set('title', 'Multiple');
        } else {
          this.set('title', riderServiceNeeds.get('lastObject.serviceNeedType.displayName'));
        }
      } else {
        this.set('title', '');
        configServiceNeeds.forEach(configServiceNeed => {
          configServiceNeed.set('isChecked', false);
        });
      }

      this.setProperties({
        configServiceNeeds
      });
    },

    async init() {
      this._super(...arguments);

      const store = this.get('store');
      this.createRiderReasonEligibilityRecord = this.createRiderReasonEligibilityRecord.bind(this);
      this.onDeleteRiderReasonEligibility = this.onDeleteRiderReasonEligibility.bind(this);
      this.onEditRiderReasonEligibility = this.onEditRiderReasonEligibility.bind(this);
      this.onEditServiceModeEligibility = this.onEditServiceModeEligibility.bind(this);
      this.onRiderReasonEligibilitySave = this.onRiderReasonEligibilitySave.bind(this);
      this.onUndoServiceModeEligibility = this.onUndoServiceModeEligibility.bind(this);
      const configFarecategory = await store.peekAll('fare-category');
      const configFarecategoryTypes = configFarecategory.toArray();
      this.setupServiceNeedsDropdown();
      this.setProperties({
        configFarecategoryTypes
      });
      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onEditServiceModeEligibility = this.get('onEditServiceModeEligibility');
      const onSaveRiderServiceModeAndNeedRecord = this.get('onSaveRiderServiceModeAndNeedRecord');
      const onUndoServiceModeEligibility = this.get('onUndoServiceModeEligibility');
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRiderServiceModeAndNeedRecord,
          newAction: null,
          deleteAction: null,
          editAction: onEditServiceModeEligibility,
          undoAction: onUndoServiceModeEligibility,
          isNewBtnDisabled: false,
          isEditBtnDisabled: false,
          isDeleteBtnDisabled: false,
          isEditing: false
        });
      });
    },

    onEditServiceModeEligibility() {
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditing', true);
      this.set('editAPI.isEditBtnDisabled', true);
    },

    onUndoServiceModeEligibility() {
      const riderChangeSet = this.get('riderChangeSet');
      const riderEligibilities = riderChangeSet.get('riderEligibilities');
      const riderServiceNeeds = riderChangeSet.get('riderServiceNeeds');
      this.set('editAPI.isNewBtnDisabled', false);
      this.set('editAPI.isEditing', false);
      this.set('editAPI.isEditBtnDisabled', false);
      riderEligibilities.forEach(riderEligibility => {
        if (Ember.isBlank(riderEligibility.id)) {
          riderEligibility.destroyRecord();
        } else {
          riderEligibility.rollbackAttributes();
          const origEligCateName = riderEligibility.get('origEligCateName');
          const origEligCondName = riderEligibility.get('origEligCondName');
          const eligibilityCategoryRecord = origEligCateName ? this.store.peekRecord('eligibility-category', origEligCateName) : null;
          const eligibilityConditionRecord = origEligCondName ? this.store.peekRecord('eligibility-condition', origEligCondName) : null;
          riderEligibility.set('eligibilityCategory', eligibilityCategoryRecord);
          riderEligibility.set('eligibilityCondition', eligibilityConditionRecord);
        }
      });
      this.set('riderEligibilityRecord');

      for (const riderServiceNeed of riderServiceNeeds.toArray()) {
        if (Ember.isBlank(riderServiceNeed.get('id'))) {
          riderServiceNeed.destroyRecord();
        } else {
          riderServiceNeed.rollbackAttributes();
        }
      }

      riderChangeSet.rollback();
      this.setupServiceNeedsDropdown();
      this.get('tableRef').refreshData(); //to refresh table data
    },

    async onRiderReasonEligibilitySave() {
      const riderChangeSet = this.get('riderChangeSet');
      const rider = riderChangeSet.get('_internalModel');
      const riderServiceReasonEligibilityRecord = this.get('riderEligibilityRecord');
      const allowOverlappingEligibilityDates = this.store.peekRecord('cs-config-item', 'config-System_Configuration/allowOverlappingEligibilityDates').value;
      const isOverlap = allowOverlappingEligibilityDates ? false : this.overlapDates(riderServiceReasonEligibilityRecord, riderChangeSet.get('riderEligibilities'));

      if (isOverlap) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: "Unable to save. Date range overlaps an existing eligibility's date range.",
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.popConfirmation();
          }

        });
        return;
      }

      riderServiceReasonEligibilityRecord.set('rider', rider);
      riderChangeSet.get('riderEligibilities').pushObject(riderServiceReasonEligibilityRecord);
      this.get('tableRef').refreshData(); //to refresh table data

      this.set('canShowRiderEligibilityFormModal', false);
    },

    onSaveRiderServiceReasonEligibilityRecord() {
      const notifications = this.get('notifications');
      const rider = this.get('riderChangeSet').get('_internalModel');
      const riderServiceReasonEligibilityRecord = this.get('riderEligibilityRecord');

      try {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            riderServiceReasonEligibilityRecord.set('rider', rider);
            await riderServiceReasonEligibilityRecord.save();

            if (this.get('editAPI.isEditing')) {
              notifications.success('RIDER ELIGIBILTIY SUCCESSFULLY EDITED');
              this.set('editAPI.isEditing', false);
              this.set('editAPI.isEditBtnDisabled', false);
            }

            notifications.success('RIDER ELIGIBILTIY SUCCESSFULLY ADDED');
            this.get('tableRef').refreshData(); //to refresh table data

            this.set('canShowRiderEligibilityFormModal', false);
            return tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      } catch (e) {
        notifications.warning('Rider Eligibility Record failed to save');
        console.warn(e); // eslint-disable-line
      }
    },

    onDeleteRiderReasonEligibility(riderEligibilityRecord) {
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: 'Are you sure you want to delete this rider eligibility?',
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          riderEligibilityRecord.set('isSoftDeleted', true);
          this.get('tableRef').refreshData(); //to refresh table data

          notifications.success('RIDER ELIGIBILITY SUCCESSFULLY DELETED');
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    onEditRiderReasonEligibility(riderEligibilityRecord) {
      if (riderEligibilityRecord) {
        this.setProperties({
          prevEligibilityRecord: {
            eligibilityCategory: riderEligibilityRecord.get('eligibilityCategory'),
            eligibilityCondition: riderEligibilityRecord.get('eligibilityCondition'),
            from: riderEligibilityRecord.get('from'),
            to: riderEligibilityRecord.get('to')
          }
        });
        this.set('canShowRiderEligibilityFormModal', true);
        this.set('riderEligibilityRecord', riderEligibilityRecord);
      }
    },

    createRiderReasonEligibilityRecord() {
      const riderEligibilityRecord = this.get('store').createRecord('rider-eligibility');
      const riderEligibilityType = this.get('store').createRecord('eligibility-type');
      riderEligibilityRecord.set('eligibilityType', riderEligibilityType);
      riderEligibilityRecord.set('tempId', (0, _guid.guid)()); // temporary unique id for comparing during validations

      this.set('riderEligibilityRecord', riderEligibilityRecord);
      this.setProperties({
        prevEligibilityRecord: {
          eligibilityCategory: riderEligibilityRecord.get('eligibilityCategory'),
          eligibilityCondition: riderEligibilityRecord.get('eligibilityCondition'),
          from: riderEligibilityRecord.get('from'),
          to: riderEligibilityRecord.get('to')
        }
      });
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    overlapDates(eligibility, eligibilities) {
      const from = (0, _moment.default)(eligibility.get('from'));
      const to = (0, _moment.default)(eligibility.get('to'));
      const eligibilityTempId = eligibility.get('tempId');
      const eligibilityId = eligibility.get('id');
      let overlap = false;
      eligibilities.forEach(currEligibility => {
        const currEligibilityTempId = currEligibility.get('tempId');
        const currEligibilityId = currEligibility.get('id');
        if (Ember.isPresent(currEligibilityTempId) && Ember.isPresent(eligibilityTempId) && currEligibilityTempId === eligibilityTempId || Ember.isPresent(currEligibilityId) && Ember.isPresent(eligibilityId) && currEligibilityId === eligibilityId || currEligibility.get('isSoftDeleted')) return;
        const currFrom = currEligibility.get('from');
        const currTo = currEligibility.get('to');

        if (from.isSameOrAfter(currFrom) && from.isSameOrBefore(currTo) || to.isSameOrAfter(currFrom) && to.isSameOrBefore(currTo) || from.isSameOrBefore(currFrom) && to.isSameOrAfter(currTo)) {
          overlap = true;
        }
      });
      return overlap;
    },

    onSaveRiderServiceModeAndNeedRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const riderChangeSet = this.get('riderChangeSet');
      const riderServiceNeeds = riderChangeSet.get('riderServiceNeeds');
      const riderEligibilities = riderChangeSet.get('riderEligibilities');
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: 'Are you sure you want to save these changes?',
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          try {
            riderServiceNeeds.forEach(async riderServiceNeed => {
              if (riderServiceNeed.get('isSoftDeleted')) {
                await riderServiceNeed.destroyRecord();
              } else {
                await riderServiceNeed.save();
              }
            });
            riderEligibilities.forEach(async riderEligibility => {
              riderEligibility.set('to', (0, _moment.default)(riderEligibility.get('to')).toDate());
              riderEligibility.set('from', (0, _moment.default)(riderEligibility.get('from')).toDate());

              if (riderEligibility.get('isSoftDeleted')) {
                await riderEligibility.destroyRecord();
              } else {
                await riderEligibility.save();
              }
            });
            await riderChangeSet.save();
            notifications.success('Rider eligibility information successfully saved'); // activity log for editing rider eligibility screen

            const riderId = riderChangeSet.get('id');
            const riderExternalId = riderChangeSet.get('riderId');
            const actions = [{
              activity: 'Edit Rider Record',
              loggingType: 'user',
              details: `Edited information for rider ${riderExternalId}.`
            }];
            this.get('rider').createEditRiderActivityLog(riderId, actions);
            this.set('editAPI.isNewBtnDisabled', false);
            this.set('editAPI.isEditBtnDisabled', false);
            this.set('editAPI.isEditing', false);
            tooltip.reset();
          } catch (e) {
            notifications.warning('Rider eligibility record failed to save');
            tooltip.reset();
            console.warn(e); // eslint-disable-line
          }
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
      yield Promise.resolve();
    }),

    createCurbToCurbServiceNeed(event, configServiceNeedTypesOption) {
      const curbToCurbServiceNeed = this.get('curbToCurbServiceNeed');
      let curbToCurbRecord = curbToCurbServiceNeed;

      if (event.target.checked) {
        if (Ember.isPresent(curbToCurbRecord)) {
          curbToCurbRecord.set('isSoftDeleted', false);
        } else {
          curbToCurbRecord = this.get('store').createRecord('rider-service-need', {
            serviceNeedType: configServiceNeedTypesOption
          });
          const riderChangeSet = this.get('riderChangeSet');
          riderChangeSet.get('riderServiceNeeds').pushObject(curbToCurbRecord);
          const riderServiceNeedRecord = this.set('riderServiceNeedRecord', curbToCurbRecord);
          const rider = this.get('riderChangeSet').get('_internalModel');
          riderServiceNeedRecord.set('rider', rider);
        }
      } else {
        curbToCurbServiceNeed.set('isSoftDeleted', true);
      }
    },

    createDoorToDoorServiceNeed(event, configServiceNeedTypesOption) {
      const doorToDoorServiceNeed = this.get('doorToDoorServiceNeed');
      let doorToDoorRecord = doorToDoorServiceNeed;

      if (event.target.checked) {
        if (Ember.isPresent(doorToDoorRecord)) {
          doorToDoorRecord.set('isSoftDeleted', false);
        } else {
          doorToDoorRecord = this.get('store').createRecord('rider-service-need', {
            serviceNeedType: configServiceNeedTypesOption
          });
          const riderChangeSet = this.get('riderChangeSet');
          riderChangeSet.get('riderServiceNeeds').pushObject(doorToDoorRecord);
          const riderServiceNeedRecord = this.set('riderServiceNeedRecord', doorToDoorRecord);
          const rider = this.get('riderChangeSet').get('_internalModel');
          riderServiceNeedRecord.set('rider', rider);
        }
      } else {
        doorToDoorServiceNeed.set('isSoftDeleted', true);
      }
    },

    createVisuallyAssuredEntryServiceNeed(event, configServiceNeedTypesOption) {
      const visuallyAssuredEntryServiceNeed = this.get('visuallyAssuredEntryServiceNeed');
      let visuallyAssuredEntryRecord = visuallyAssuredEntryServiceNeed;

      if (event.target.checked) {
        if (Ember.isPresent(visuallyAssuredEntryRecord)) {
          visuallyAssuredEntryRecord.set('isSoftDeleted', false);
        } else {
          visuallyAssuredEntryRecord = this.get('store').createRecord('rider-service-need', {
            serviceNeedType: configServiceNeedTypesOption
          });
          const riderChangeSet = this.get('riderChangeSet');
          riderChangeSet.get('riderServiceNeeds').pushObject(visuallyAssuredEntryRecord);
          const riderServiceNeedRecord = this.set('riderServiceNeedRecord', visuallyAssuredEntryRecord);
          const rider = this.get('riderChangeSet').get('_internalModel');
          riderServiceNeedRecord.set('rider', rider);
        }
      } else {
        visuallyAssuredEntryServiceNeed.set('isSoftDeleted', true);
      }
    },

    actions: {
      onOpen() {
        this.toggleSelect();
      },

      onClose() {
        this.toggleSelect();
      },

      onClickCheckBox(configServiceNeedTypesOption, event) {
        const checkBox = Ember.$('input[name=checkbox_configServiceNeedTypesOption]:checked');
        const checkboxCheckedLength = checkBox.length;
        const checkboxChecked = checkBox;
        const multipleCheckboxChecked = 'Multiple';
        const configServiceNeeds = this.get('configServiceNeeds');
        const configServiceNeedTypes = configServiceNeeds.map(serviceNeedType => serviceNeedType.get('displayName'));
        Ember.set(configServiceNeedTypesOption, 'isChecked', event.target.checked);

        if (checkboxCheckedLength > 1) {
          this.set('title', multipleCheckboxChecked);
        } else if (checkboxCheckedLength === 0) {
          this.set('title', '');
        } else if (checkboxCheckedLength === 1) {
          const selectedCheckboxName = checkboxChecked[0].id;
          const selectedCheckboxId = selectedCheckboxName.split('checkbox_configServiceNeedTypesOption')[1];
          this.set('title', configServiceNeedTypes.objectAt(selectedCheckboxId));
        }

        switch (configServiceNeedTypesOption.get('displayName')) {
          case 'CURB TO CURB':
            this.createCurbToCurbServiceNeed(event, configServiceNeedTypesOption);
            break;

          case 'Door to Door':
            this.createDoorToDoorServiceNeed(event, configServiceNeedTypesOption);
            break;

          case 'Visually Assured Entry':
            this.createVisuallyAssuredEntryServiceNeed(event, configServiceNeedTypesOption);
            break;

          default:
        }
      },

      onPowerSelectChange(record, selected) {
        record.set('fareCategory', selected);
      }

    }
  });

  _exports.default = _default;
});