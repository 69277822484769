define("adept-iq/config/cs-config-model-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modelNameForCategory = void 0;
  // you must register any model that is serialized to a config item here
  const modelNameForCategory = {
    'config-Scheduling-schedule_configurations': 'schedule-configuration',
    'config-System_Configuration-providers': 'provider',
    'config-System_Configuration-vehicle_capacity_configs': 'vehicle-capacity-config',
    'config-System_Configuration-vehicle_capacity_types': 'vehicle-capacity-type',
    'config-System_Configuration-vehicle_types': 'vehicle-type',
    'config-System_Configuration-zones': 'zone-type',
    'config-System_Configuration-provider_types': 'provider-type',
    'config-System_Configuration-internal_ui_configs': 'internal-ui-config',
    'config-Engine_Strategy': 'engine-strategy'
  };
  _exports.modelNameForCategory = modelNameForCategory;
});