define("adept-iq/mixins/trip-transfer-reason", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const srcChunck = {
    EC_317: 'Engine Call failed with EC=317',
    TIMEOUT_WHILE_WAITING: 'timed out while waiting',
    BLOCKRESCHEDULE_ISSUE: 'blockReschedule has no validate trips to reschedule',
    BLOCKRESCHEDULE_ISSUE_1: 'blockReschedule has no trips to reschedule',
    SERVICE_UNAVAILABLE: 'Service is temporarily unavailable',
    UNABLE_TO_ACQUITE: 'Unable to Acquire',
    TRIPS_ARE_BROKER_TRIPS: 'Trips are broker trips',
    ROUTE_ID_DOES_NOT_EXIST: 'does not exist',
    MISSING_FROM_THE_CACHE: 'The following trips are missing from the cache',
    BELONG_TO_ONE_SCHEDULE: 'the trips should belong to one schedule',
    PROMISE_WINDOW: 'The promise window',
    UNABLE_TO_LOCK_SOURCE: 'Unable to lock source',
    SES_NOT_FIND_ANY: 'SES did not find any suitable destination route',
    TRIP_TRANSFER_FAILURE: 'Trip Transfer Failure',
    UNABLE_TO_TRANSFER: 'Unable to transfer trips',
    SERVICE_WINDOW: 'Service window for service type',
    UNABLE_TO_SEND_ROUTE: 'Unable to send route update to AVLM service',
    UNABLE_TO_LOCK_ENTITIES: 'Unable to lock entities',
    IS_NOT_ASSIGNED: 'is not assigned to a route',
    BEFORE_DROP_ORDINAL: 'before drop ordinal',
    VIOLATED_THE_CAPACITY: 'violated the capacity',
    DESTINATION_ROUTE_VIOLATED_CAPACITY: 'destination route violated capacity',
    STARTS_DURING_TRIP: 'starts during trip',
    INSERT_TRIP_BEFORE_PERFORMED: 'cannot insert trip before already performed trips',
    ENDPOINT_REQUEST_TIMED_OUT: 'Endpoint request timed out',
    VERSIONS_CONFLICT: 'modified by another request',
    VERSIONS_CONFLICT_PREREQUISITES: 'but has version',
    UNABLE_TO_OBTAIN_LOCK: 'Unable to obtain lock',
    ETANAV_ELIMINATED_ALL: 'ETAnav eliminated all destination routes suggested by SES'
  };
  const reason = {
    SYSTEM_UNAVAILABLE: 'System unavailable. Please try again.',
    SYSTEM_BUSY: 'System busy. Please try again.',
    NO_BROKER: 'No broker routes exist.',
    SCHEDULE_PROBLEM: 'There may be a problem with the trip date\'s schedule. ',
    PROMISE_TIME_ISSUE: 'There may be an issue with the promise time/window',
    SERVICE_WINDOW_ISSUE: 'There may be an issue with the trip\'s service window.',
    PICKUP_MUST_BEFORE_DROP: 'Trip\'s pickup must come before its drop-off.',
    NOT_HAVE_CAPACITY: 'Destination route does not have appropriate capacity.',
    IN_MIDDLE_OF_BREAK: 'Trip cannot be placed in the middle of a break.',
    TRIP_MUST_AFTER_PERFORMED: 'Trip must be placed after performed trips.',
    ROUTE_CAPACITY_OR_TIMES: 'Transferring causes an issue with route capacity or times.',
    CHECK_TRIP_STATE: 'Trip transfer issue. Please check trip state.',
    VERSIONS_CONFLICT: 'The Trip`s Route has already been modified by another request',
    VERSIONS_CONFLICT_PREREQUISITES: 'The Trip`s Route has already been modified by another request',
    UNABLE_TO_LOCK_ENTITIES: 'Obtain route lock failed at this time, please retry operation.',
    SYSTEM_UNABLE_TO_OBTAIN_ROUTE_LOCK: 'System unable to obtain route lock for transfer, Please retry operation.',
    NO_REASON: ''
  };
  const matchingTable = {
    SERVICE_UNAVAILABLE: reason.SYSTEM_UNAVAILABLE,
    UNABLE_TO_ACQUITE: reason.SYSTEM_UNAVAILABLE,
    TRIPS_ARE_BROKER_TRIPS: reason.NO_BROKER,
    ROUTE_ID_DOES_NOT_EXIST: reason.SYSTEM_UNAVAILABLE,
    MISSING_FROM_THE_CACHE: reason.SYSTEM_UNAVAILABLE,
    BELONG_TO_ONE_SCHEDULE: reason.SCHEDULE_PROBLEM,
    PROMISE_WINDOW: reason.PROMISE_TIME_ISSUE,
    SES_NOT_FIND_ANY: reason.NO_REASON,
    UNABLE_TO_LOCK_SOURCE: reason.SYSTEM_UNAVAILABLE,
    TRIP_TRANSFER_FAILURE: reason.SYSTEM_UNAVAILABLE,
    UNABLE_TO_TRANSFER: reason.SYSTEM_UNAVAILABLE,
    SERVICE_WINDOW: reason.SERVICE_WINDOW_ISSUE,
    UNABLE_TO_SEND_ROUTE: reason.SYSTEM_UNAVAILABLE,
    UNABLE_TO_LOCK_ENTITIES: reason.UNABLE_TO_LOCK_ENTITIES,
    IS_NOT_ASSIGNED: reason.SYSTEM_UNAVAILABLE,
    BEFORE_DROP_ORDINAL: reason.PICKUP_MUST_BEFORE_DROP,
    VIOLATED_THE_CAPACITY: reason.NOT_HAVE_CAPACITY,
    DESTINATION_ROUTE_VIOLATED_CAPACITY: reason.NOT_HAVE_CAPACITY,
    STARTS_DURING_TRIP: reason.IN_MIDDLE_OF_BREAK,
    INSERT_TRIP_BEFORE_PERFORMED: reason.TRIP_MUST_AFTER_PERFORMED,
    ENDPOINT_REQUEST_TIMED_OUT: reason.SYSTEM_BUSY,
    EC_317: reason.ROUTE_CAPACITY_OR_TIMES,
    BLOCKRESCHEDULE_ISSUE: reason.CHECK_TRIP_STATE,
    BLOCKRESCHEDULE_ISSUE_1: reason.CHECK_TRIP_STATE,
    TIMEOUT_WHILE_WAITING: reason.SYSTEM_BUSY,
    VERSIONS_CONFLICT: reason.VERSIONS_CONFLICT,
    UNABLE_TO_OBTAIN_LOCK: reason.SYSTEM_UNABLE_TO_OBTAIN_ROUTE_LOCK,
    ETANAV_ELIMINATED_ALL: reason.ROUTE_CAPACITY_OR_TIMES
  };

  var _default = Ember.Mixin.create({
    translate(message, detail) {
      for (const [key, value] of Object.entries(srcChunck)) {
        if (message && message.includes(value) || detail && detail.includes(value)) {
          return matchingTable[key];
        }
      }

      return reason.SYSTEM_UNAVAILABLE;
    }

  });

  _exports.default = _default;
});