define("adept-iq/pods/components/reoptimize-modal/operation-views/reoptimize/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reoptimize-options'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    // Passed In Attributes
    title: 'Reoptimize Options',
    currentSchedule: null,
    selectedVal: null,
    isAttemptToWaitlistEnabled: Ember.computed('currentSchedule.isGenerated', function () {
      return this.get('currentSchedule.isGenerated');
    }),

    init() {
      this._super(...arguments);

      this.onRadioButtonClick('reoptimize');
    },

    onRadioButtonClick(val) {
      this.set('selectedVal', val);
    },

    //to check the current schedule is generated(in-progess)
    async fetchCurrentSchedule(currentSchedule) {
      const store = this.get('store');
      const workspace = this.get('workspace');
      const schedules = await store.query('schedule', {
        filter: `eq(id,${currentSchedule.get('id')})`
      });
      const isGenerated = schedules.firstObject.get('isGenerated');

      if (isGenerated) {
        workspace.set('reoptimizeInProgress', true);
      }

      return isGenerated;
    },

    async onReoptimizeBtnClick() {
      const tooltip = this.get('tooltip');
      const workspace = this.get('workspace');
      const date = (0, _moment.default)(workspace.startDate).format('MM/DD/YYYY');
      const currentSchedule = this.get('currentSchedule');
      const selectedVal = this.get('selectedVal');

      if (await this.fetchCurrentSchedule(currentSchedule)) {
        return;
      }

      tooltip.pushConfirmation({
        title: 'Reoptimization',
        hasOverlay: true,
        tip: `Are you sure you want to Optimize ${date}`,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          if (await this.fetchCurrentSchedule(currentSchedule)) {
            return;
          }

          workspace.popState('reoptimize');
          workspace.pushState('schedule-generate', {
            currentSchedule,
            radioValue: selectedVal
          });
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    actions: {
      onXButtonClick() {
        this.get('workspace').popState('reoptimize');
      },

      onRadioButtonClick(val) {
        this.onRadioButtonClick(val);
      },

      onReoptimizeClick() {
        this.onReoptimizeBtnClick();
      }

    }
  });

  _exports.default = _default;
});