define("adept-iq/pods/components/iq-widgets/fleet-manager-widget/vehicle-details/component", ["exports", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/fleet-manager-widget/vehicle-details/validation", "moment"], function (_exports, _emberChangeset, _emberChangesetValidations, _validation, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DROPDOWN_MODELS = [{
    optionName: 'vehicleTypeOptions',
    modelName: 'vehicle-type'
  }];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    startGarageLatLngFormatted: Ember.computed('editAPI.isEditing', 'vehicleRecord.{startGarageLat,startGarageLng}', 'editedVehicleRecord.{startGarageLat,startGarageLng}', function () {
      const isEditing = this.get('editAPI.isEditing');
      const record = isEditing ? this.get('editedVehicleRecord') : this.get('vehicleRecord');

      if (!record) {
        return '';
      }

      const lat = record.get('startGarageLat');
      const lng = record.get('startGarageLng');

      if (!lat && !lng) {
        return '';
      }

      if (lat && lng) {
        return `${lat} / ${lng}`;
      }

      return lat || lng;
    }),
    endGarageLatLngFormatted: Ember.computed('editAPI.isEditing', 'vehicleRecord.{endGarageLat,endGarageLng}', 'editedVehicleRecord.{endGarageLat,endGarageLng}', function () {
      const isEditing = this.get('editAPI.isEditing');
      const record = isEditing ? this.get('editedVehicleRecord') : this.get('vehicleRecord');

      if (!record) {
        return '';
      }

      const lat = record.get('endGarageLat');
      const lng = record.get('endGarageLng');

      if (!lat && !lng) {
        return '';
      }

      if (lat && lng) {
        return `${lat} / ${lng}`;
      }

      return lat || lng;
    }),
    editedVehicleRecord: null,
    originalVehicleRecord: null,
    zoneCnt: null,

    init() {
      this._super(...arguments);

      this.setupDropDownValues();
      const editAPI = this.get('editAPI');
      this.saveVehicleRecord = this.saveVehicleRecord.bind(this);
      this.editVehicleRecord = this.editVehicleRecord.bind(this);
      this.undoVehicleRecord = this.undoVehicleRecord.bind(this);
      this.newVehicleRecord = this.newVehicleRecord.bind(this);
      const zones = this.store.peekAll('zone');
      const zoneCnt = zones.length;
      this.set('zoneCnt', zoneCnt);
      Ember.run.next(() => {
        editAPI.setProperties({
          editAction: this.editVehicleRecord,
          saveAction: this.saveVehicleRecord,
          undoAction: this.undoVehicleRecord,
          newAction: this.newVehicleRecord
        });
      });
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = DROPDOWN_MODELS;
      dropDownModels.forEach(dropdown => {
        const records = store.peekAll(dropdown.modelName);
        this.set(dropdown.optionName, records);
      });
    },

    createRouteTemplate(vehicleRecord) {
      const zoneCnt = this.get('zoneCnt');
      const affinities = new Array(zoneCnt).fill(5);
      const routeTemplate = this.store.createRecord('route-template', {
        name: vehicleRecord.get('name'),
        active: true,
        affinities: affinities
      });
      return routeTemplate.save();
    },

    createRouteTemplateAvailability(vehicleRecord, routeTemplateRecord) {
      const routeTemplateAvailability = this.store.createRecord('route-template-availability', {
        startTime: (0, _moment.default)('2000-01-01 00:00:00').utc().toDate(),
        endTime: (0, _moment.default)('2999-12-31 00:00:00').utc().toDate(),
        shiftStart: (0, _moment.default)(0).utc().add(1, 'minute').toDate(),
        shiftEnd: (0, _moment.default)(0).utc().add(24, 'hours').toDate(),
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        holiday: true,
        day: true,
        vehicle: vehicleRecord,
        routeTemplate: routeTemplateRecord
      });
      return routeTemplateAvailability.save();
    },

    async saveVehicleRecord(record) {
      const notifications = this.get('notifications');
      const workspace = this.get('workspace');

      if (!record) {
        notifications.warning('No vehicle to save.');
        return;
      }

      try {
        const tooltip = this.get('tooltip');
        const errors = [];
        const changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
        await changeset.validate();
        changeset.get('errors').forEach(error => {
          if (!record.get('error')) {
            record.set('error', {});
          }

          errors.push({
            validation: `${error.validation}`
          });
          record.set(`error.${error.key}`, error.validation);
        });

        if (errors.length > 0) {
          tooltip.pushConfirmation({
            title: 'Validation Errors',
            tip: '',
            errors: errors,
            primaryActionText: 'OK',

            primaryAction() {
              tooltip.popConfirmation();
            }

          });
          this.set('showErrorMsg', true);
          return false;
        }

        const allVehicles = this.get('store').peekAll('vehicle');
        const duplicateVehicles = allVehicles.filter(vehicle => vehicle.get('name').trim() === record.get('name').trim() && vehicle.get('id') !== record.get('id'));

        if (duplicateVehicles.length > 0) {
          const duplicateVehicle = duplicateVehicles.firstObject;
          tooltip.pushConfirmation({
            title: 'Duplicate Vehicle ID',
            tip: `A vehicle with the name "${record.get('name')}" already exists. Do you want to update this vehicle instead?`,
            primaryActionText: 'Update',
            secondaryActionText: 'Cancel',
            primaryAction: async () => {
              duplicateVehicle.setProperties({
                name: record.get('name'),
                startGarageDisplayName: record.get('startGarageDisplayName'),
                endGarageDisplayName: record.get('endGarageDisplayName'),
                startGarageLat: record.get('startGarageLat'),
                startGarageLng: record.get('startGarageLng'),
                endGarageLat: record.get('endGarageLat'),
                endGarageLng: record.get('endGarageLng'),
                vehicleType: record.get('vehicleType')
              });
              await duplicateVehicle.save();
              this.get('tableRef').refreshData();
              tooltip.popConfirmation();
              return true;
            },

            secondaryAction() {
              tooltip.popConfirmation();
              return false;
            }

          });
          return true;
        }

        let vehicleRecord = record;
        const isNew = record.get('isNew');

        if (isNew) {
          const providers = await this.get('store').peekAll('provider');
          const defaultProvider = providers.objectAt(0);

          if (defaultProvider) {
            vehicleRecord.set('provider', defaultProvider);
          }
        } else {
          vehicleRecord = this.get('vehicleRecord');
          vehicleRecord.setProperties({
            startGarageDisplayName: record.get('startGarageDisplayName'),
            endGarageDisplayName: record.get('endGarageDisplayName'),
            startGarageLat: record.get('startGarageLat'),
            startGarageLng: record.get('startGarageLng'),
            endGarageLat: record.get('endGarageLat'),
            endGarageLng: record.get('endGarageLng'),
            vehicleType: record.get('vehicleType')
          });
        }

        const currentSchedule = workspace.get('currentSchedule');
        const savePromises = [];

        if (currentSchedule) {
          currentSchedule.set('status', 'unscheduled');
          savePromises.push(currentSchedule.save());
        }

        savePromises.push(vehicleRecord.save().then(savedRecord => {
          vehicleRecord = savedRecord;
        }));
        await Promise.all(savePromises);

        if (isNew) {
          const routeTemplateRecord = await this.createRouteTemplate(vehicleRecord);
          await this.createRouteTemplateAvailability(vehicleRecord, routeTemplateRecord);
        }

        this.get('tableRef').refreshData();
        workspace.refreshWidgets();
        return true;
      } catch (error) {
        notifications.warning('Error In Saving Vehicle Record'); // eslint-disable-next-line no-console

        console.error('Unexpected error:', error);
        this.undoVehicleRecord();
        return false;
      }
    },

    editVehicleRecord(record) {
      const notifications = this.get('notifications');

      if (!record) {
        notifications.warning('No vehicle selected for editing.');
        return;
      }

      const editedVehicleRecord = Ember.Object.create({ ...record.toJSON(),
        id: record.get('id'),
        vehicleType: record.get('vehicleType')
      });
      const originalVehicleRecord = Ember.Object.create({ ...record.toJSON(),
        id: record.get('id'),
        vehicleType: record.get('vehicleType')
      });
      this.set('editedVehicleRecord', editedVehicleRecord);
      this.set('originalVehicleRecord', originalVehicleRecord);
      this.set('vehicleRecord', record);
    },

    undoVehicleRecord() {
      const originalVehicleRecord = this.get('originalVehicleRecord');

      if (originalVehicleRecord) {
        const vehicleRecord = this.get('vehicleRecord');
        const editedVehicleRecord = this.get('editedVehicleRecord');
        vehicleRecord.setProperties({
          name: originalVehicleRecord.get('name'),
          startGarageDisplayName: originalVehicleRecord.get('startGarageDisplayName'),
          endGarageDisplayName: originalVehicleRecord.get('endGarageDisplayName'),
          startGarageLat: originalVehicleRecord.get('startGarageLat'),
          startGarageLng: originalVehicleRecord.get('startGarageLng'),
          endGarageLat: originalVehicleRecord.get('endGarageLat'),
          endGarageLng: originalVehicleRecord.get('endGarageLng'),
          vehicleType: originalVehicleRecord.get('vehicleType')
        });
        editedVehicleRecord.setProperties({
          name: originalVehicleRecord.get('name'),
          startGarageDisplayName: originalVehicleRecord.get('startGarageDisplayName'),
          endGarageDisplayName: originalVehicleRecord.get('endGarageDisplayName'),
          startGarageLat: originalVehicleRecord.get('startGarageLat'),
          startGarageLng: originalVehicleRecord.get('startGarageLng'),
          endGarageLat: originalVehicleRecord.get('endGarageLat'),
          endGarageLng: originalVehicleRecord.get('endGarageLng'),
          vehicleType: originalVehicleRecord.get('vehicleType')
        });
      }
    },

    newVehicleRecord() {
      const vehicleRecord = this.get('store').createRecord('vehicle');
      this.set('vehicleRecord', vehicleRecord);
      this.set('editedVehicleRecord', vehicleRecord);
    },

    actions: {
      onInputValueChange(record, valuePath, value) {
        if (valuePath === 'endGarageLatLng') {
          const [lat, lng] = value.split('/').map(item => item.trim());
          record.set('endGarageLat', lat || null);
          record.set('endGarageLng', lng || null);
        } else if (valuePath === 'startGarageLatLng') {
          const [lat, lng] = value.split('/').map(item => item.trim());
          record.set('startGarageLat', lat || null);
          record.set('startGarageLng', lng || null);
        } else {
          record.set(valuePath, value);
        }

        this.set('editedVehicleRecord', record);
        this.get('editAPI').set('isSaveBtnDisabled', false);
      },

      async onSaveClick() {
        const record = this.get('editedVehicleRecord');
        const status = await this.get('editAPI').saveAction(record);

        if (status) {
          this.get('editAPI').setProperties({
            isNewBtnDisabled: false,
            isEditBtnDisabled: false,
            isDeleteBtnDisabled: false,
            isSaveBtnDisabled: true,
            isUndoBtnDisabled: true,
            isUploadCSVBtnDisabled: false,
            isEditing: false,
            isNewRecord: false
          });
        } else {
          this.get('editAPI').setProperties({
            isNewBtnDisabled: true,
            isEditBtnDisabled: true,
            isDeleteBtnDisabled: true,
            isSaveBtnDisabled: false,
            isUndoBtnDisabled: false,
            isUploadCSVBtnDisabled: true
          });
        }
      }

    }
  });

  _exports.default = _default;
});