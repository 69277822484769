define("adept-iq/pods/components/iq-widgets/passengers-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/passengers-widget/config", "adept-iq/pods/components/iq-widgets/passengers-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/passengers-widget/scheduleConfig", "adept-iq/config/environment", "adept-iq/config/api-urls", "lodash", "adept-iq/config/mapped-permIds", "adept-iq/models/dashboard"], function (_exports, _component, _config, _avlmConfig, _scheduleConfig, _environment, _apiUrls, _lodash, _mappedPermIds, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['passengers-widget', 'data-test-passengers-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      } else {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this.set('config', _scheduleConfig.default);
            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this.set('config', _config.default);
            break;
        }
      }

      this._super(...arguments);
    },

    // TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving passengers and all related models.
    saveRecordFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const placeSerializer = store.serializerFor('place');
      const placeModelClass = store.modelFor('place');

      function saveDeleteAllEligibilities(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.initialState, objects.content.currentState);

        if (objects) {
          objects.forEach(object => {
            if (object.isDeleted) {
              deleteObjects.push(object);
            }
          });
        } // delete objects that have been removed from the array


        return Ember.RSVP.Promise.all(deleteObjects.map(deleteObject => {
          deleteObject.deleteRecord();
          return deleteObject.save();
        })).then(() => {
          return Ember.RSVP.Promise.all(objects.map(object => object.save()));
        });
      }

      function saveDeleteAllTravelNeeds(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.initialState, objects.content.currentState);

        if (objects) {
          objects.forEach(object => {
            if (object.get('count') <= 0) {
              deleteObjects.push(object);
            }
          });
        } // delete objects that have been removed from the array


        return Ember.RSVP.Promise.all(deleteObjects.map(deleteObject => {
          deleteObject.deleteRecord();
          return deleteObject.save();
        })).then(() => {
          return Ember.RSVP.Promise.all(objects.map(object => {
            object.save();
          }));
        });
      }

      function saveDeleteAllPhoneNumbers(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.initialState, objects.content.currentState);

        if (objects) {
          objects.forEach(object => {
            if (object.get('count') <= 0) {
              deleteObjects.push(object);
            }
          });
        } // delete objects that have been removed from the array


        return Ember.RSVP.Promise.all(deleteObjects.map(deleteObject => {
          deleteObject.deleteRecord();
          return deleteObject.save();
        })).then(() => {
          return Ember.RSVP.Promise.all(objects.map(object => {
            object.save();
          }));
        });
      }

      function saveNewTravelNeedObjects(rider, newObjects) {
        return Ember.RSVP.Promise.all(newObjects.map(newObject => {
          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            newObject.set('rider', rider);
            newObject.save();
            return newObject;
          }
        }));
      }

      async function savePlaceObject(object) {
        if (!Ember.isNone(object)) {
          const address = object.get('address.content');
          const location = object.get('location.content');

          if (object.isNew) {
            const locationResponse1 = await locationAdapter.postLocation(location); // TODO: We need to deal with how zone name is populated

            if (Ember.isNone(address.get('zoneName.id'))) {
              address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
            }

            const address1 = await addressAdapter.postAddress(address);
            await addressAdapter.postAddressLocation(locationResponse1, address1);
            const placeResponse = await placeAdapter.postPlace(object, address1.data.id, locationResponse1.data.id, 'passenger');
            const placeNorm = placeSerializer.normalizeResponse(store, placeModelClass, placeResponse, placeResponse.data.id, 'createRecord');
            store.push(placeNorm);
            return Ember.RSVP.Promise.resolve(placeNorm);
          } // TODO: We need to deal with how zone name is populated


          if (Ember.isNone(address.get('zoneName.id'))) {
            address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
          }

          await address.save();
          await location.save();
          return object.save();
        }

        return Ember.RSVP.Promise.resolve();
      }

      function saveNewAddressObjects(objects) {
        let deleteObjects = [];

        if (!Ember.isNone(objects.get('content.initialState'))) {
          deleteObjects = _lodash.default.difference(objects.content.initialState, objects.content.currentState);
        }

        if (objects) {
          objects.forEach(object => {
            if (object.isDeleted) {
              deleteObjects.push(object);
            }
          });
        } // delete objects that have been removed from the array


        return Ember.RSVP.Promise.all(deleteObjects.map(deleteObject => {
          deleteObject.deleteRecord();
          return deleteObject.save();
        })).then(() => {
          return Ember.RSVP.Promise.all(objects.map(object => {
            return savePlaceObject(object.place.content).then(response => {
              if (object.isNew) {
                const place = store.peekRecord('place', response.data.id);
                object.set('place', place);
                return object.save();
              }

              return object.save();
            });
          }));
        });
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        if (records) {
          records.forEach(async record => {
            try {
              await record.save();
              await saveDeleteAllPhoneNumbers(record.get('riderPhoneNumbers'));
              await saveNewAddressObjects(record.get('riderPlaces'));
              await saveDeleteAllTravelNeeds(record.get('riderTravelNeeds'));
              const travelNeeds = (await saveNewTravelNeedObjects(record, record.get('newTravelNeeds'))) || [];
              travelNeeds.forEach(object => {
                if (!_lodash.default.isUndefined(object)) {
                  record.get('newTravelNeeds').removeObject(object);
                  record.get('riderTravelNeeds').addObject(object);
                }
              });
              return saveDeleteAllEligibilities(record.get('riderEligibilities')).then(() => {
                record.set('isForceDirty', false);
                this.get('activeContext.topActiveContext').get('refreshTableContent').perform([this.get('config.modelName')]);
                this.get('notifications').success(`PASSENGER ${record.id} EDIT CONFIRMED`);
                resolve();
              });
            } catch (error) {
              this.get('notifications').warning(`PASSENGER ${record.id} EDIT CONFIRMATION FAILED`);
              reject(error);
            }
          });
        }
      });
    },

    saveSubscriptionRecord(records) {
      this.set('editModal.errors', []);
      const notifications = this.get('notifications');

      function saveNewTravelNeedObjects(newObjects) {
        return Ember.RSVP.Promise.all(newObjects.map(newObject => {
          return newObject.save();
        }));
      }

      function saveRecurrencePatterns(objects) {
        return Ember.RSVP.Promise.all(objects.map(object => {
          object.save();
        }));
      }

      function saveExlusions(objects) {
        return Ember.RSVP.Promise.all(objects.map(object => {
          if (!Ember.isNone(object.get('startDate')) && !Ember.isNone(object.get('endDate'))) {
            object.save();
          }
        }));
      }

      async function savePlaceObject(object) {
        await object.address.save();
        await object.location.save();
        return object.save();
      }

      function saveRiderObject(object) {
        return object.save().then(() => {
          return Ember.RSVP.Promise.all(object.travelNeeds.map(travelNeed => {
            return travelNeed.save();
          }));
        });
      }

      return records.reduce(async (acu, record) => {
        await saveRiderObject(record.rider.content).catch(e => notifications.warning(e.detail));
        await savePlaceObject(record.origin.content).catch(e => notifications.warning(e.detail));
        await savePlaceObject(record.destination.content).catch(e => notifications.warning(e.detail));
        await saveRecurrencePatterns(record.get('recurrencePatterns')).catch(e => notifications.warning(e.detail));
        await saveExlusions(record.get('exclusions')).catch(e => notifications.warning(e.detail));
        await saveNewTravelNeedObjects(record.get('subscriptionTravelNeeds')).catch(e => notifications.warning(e.detail));
        return record.save().then(() => record.set('isForceDirty', false));
      }, Ember.RSVP.Promise.resolve()).then(() => {
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform([this.get('config.modelName')]);
      }).catch(e => notifications.push(e.detail));
    },

    saveTripRecordFunction(records) {
      const store = this.get('store');
      const session = this.get('session');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const placeSerializer = store.serializerFor('place');
      const placeModelClass = store.modelFor('place');

      function saveDeleteAllTravelNeeds(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.initialState, objects.content.currentState) || []; // delete objects that have been removed from the array

        return Ember.RSVP.Promise.all(deleteObjects.map(deleteObject => {
          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Ember.RSVP.Promise.all(objects.map(object => {
            object.save();
          }));
        });
      }
      /*eslint-disable */


      function saveNewTravelNeedObjects(segment, oldObjects, newObjects) {
        return Ember.RSVP.Promise.all(newObjects.map(newObject => {
          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            newObject.set('leg', segment.get('leg'));
            newObject.save();
            return newObject;
          }
        }));
      }

      async function saveTripPlaceObject(object) {
        if (!Ember.isNone(object)) {
          const address = object.get('address.content');
          const location = object.get('location.content');

          if (object.isNew) {
            const locationResponse1 = await locationAdapter.postLocation(location); // TODO: We need to deal with how zone name is populated

            if (Ember.isNone(address.get('zoneName.id'))) {
              address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
            }

            const address1 = await addressAdapter.postAddress(address);
            await addressAdapter.postAddressLocation(locationResponse1, address1);
            const placeResponse = await placeAdapter.postPlace(object, address1.data.id, locationResponse1.data.id, 'passenger');
            const placeNorm = placeSerializer.normalizeResponse(store, placeModelClass, placeResponse, placeResponse.data.id, 'createRecord');
            store.push(placeNorm);
            return Ember.RSVP.Promise.resolve(placeNorm);
          } // TODO: We need to deal with how zone name is populated


          if (Ember.isNone(address.get('zoneName.id'))) {
            address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
          }

          await address.save();
          await location.save();
          return object.save();
        }

        return Ember.RSVP.Promise.resolve();
      }

      function saveNewTripAddressObjects(object) {
        const place = object.get('place.content');
        return saveTripPlaceObject(place).then(response => {
          if (place.isNew) {
            const newPlace = store.peekRecord('place', response.data.id);
            object.set('place', newPlace);
            return object.save();
          }

          return object.save();
        });
      }

      function getSchedule() {
        const schedules = store.peekAll('schedule');
        const schedule = schedules.find(s => {
          return s.get('start') <= new Date() <= s.get('end');
        });
        if (!schedule) return;
        return schedule;
      }

      function getSameDayBookingPayload(bookingId) {
        const schedule = getSchedule();

        if (schedule) {
          return {
            'data': {
              'attributes': {},
              'relationships': {
                'schedule': {
                  'data': {
                    'id': schedule.get('id'),
                    'type': 'schedule'
                  }
                }
              },
              'id': bookingId,
              'type': 'booking'
            }
          };
        }

        return;
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        if (records) {
          records.forEach(async record => {
            try {
              await record.get('leg.booking.content').save();
              await saveNewTripAddressObjects(record.get('leg.segment.pick.content'));
              await saveNewTripAddressObjects(record.get('leg.segment.drop.content'));
              await record.get('leg.content').save();
              await record.get('leg.segment').save();
              await saveDeleteAllTravelNeeds(record.get('leg.legTravelNeeds'));
              await saveNewTravelNeedObjects(record, record.get('leg.legTravelNeeds'), record.get('leg.newTravelNeeds'));

              if (record.get('subscriptions.firstObject.recurrencePatterns.firstObject.recurring')) {
                await this.saveSubscriptionRecord(record.get('subscription'));
              }

              const json = getSameDayBookingPayload(record.get('id'));
              const bookingId = record.get('leg.booking.id');

              if (!json || record.get('leg.booking.isSameDayBooking')) {
                this.get('ajax').request(`${_apiUrls.API.bookingService.host}/booking/${bookingId}/export`, {
                  method: 'PATCH',
                  contentType: 'application/json',
                  headers: {
                    'Authorization': `Bearer ${session.data.authenticated.token}`
                  },
                  data: json
                }).then(response => {
                  this.get('notifications').success(`NEW TRIP ${bookingId} CREATED`);
                  resolve();
                }).catch(error => {
                  if (error.message === 'Unexpected end of JSON input') {
                    this.get('notifications').success(`NEW TRIP ${bookingId} CREATED`);
                    resolve();
                  } else {
                    this.get('notifications').warning(`NEW TRIP ${bookingId} CREATED BUT NOT EXPORTED`);
                    reject(error);
                  }
                });
              } else {
                this.get('notifications').warning(`NEW TRIP ${bookingId} CREATED BUT NOT EXPORTED`);
                resolve();
              }
            } catch (error) {
              this.get('notifications').warning(`ADD TRIP FAILED`);
              reject(error);
            }
          });
        }
      });
    },

    createSubscriptionRecord() {
      const currentDate = new Date();
      const subscriptions = [];
      const exclusion = this.get('store').createRecord('subscription-exclusion');
      const recurrencePattern = this.get('store').createRecord('subscription-recurrence-pattern', {
        type: 'daily',
        recurring: false
      });
      const subscription = this.get('store').createRecord('subscription', {
        requestTime: currentDate,
        exclusions: [exclusion],
        recurrencePatterns: [recurrencePattern]
      });
      subscriptions.push(subscription);
      return subscriptions;
    },

    createLegSegmentRecord(rider) {
      const riderTravelNeeds = rider.get('riderTravelNeeds') || [];
      const store = this.get('store');
      const legTravelNeeds = riderTravelNeeds.map(riderTravelNeed => store.createRecord('leg-travel-need', {
        count: riderTravelNeed.get('count'),
        travelNeedType: riderTravelNeed.get('travelNeedType'),
        passengerType: riderTravelNeed.get('passengerType')
      }));
      const serviceWindow = store.peekRecord('service-window', 'GENERAL');
      const fareType = store.peekAll('fare-type').firstObject;
      const date = new Date();
      const pickAddress = store.createRecord('address');
      const pickLocation = store.createRecord('location');
      const pickPlace = store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      const dropAddress = store.createRecord('address');
      const dropLocation = store.createRecord('location');
      const dropPlace = store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      const leg = store.createRecord('leg', {
        requestTime: date,
        anchor: 'pick',
        purpose: '',
        notes: '',
        rider,
        serviceWindow: serviceWindow,
        legTravelNeeds
      });
      const segment = store.createRecord('segment', {
        promiseTime: date,
        anchor: 'pick',
        fare: '',
        travelMode: 'bus',
        segmentType: 2,
        noSharing: 0,
        legOrdinal: 1,
        pick: store.createRecord('segment-stop', {
          type: 'pick',
          plannedTime: date,
          onboardCount: 1,
          place: pickPlace
        }),
        drop: store.createRecord('segment-stop', {
          type: 'drop',
          plannedTime: date,
          onboardCount: 1,
          place: dropPlace
        }),
        fareType: fareType,
        leg: leg
      });
      legTravelNeeds.forEach(legTravelneed => {
        legTravelneed.set('leg', leg);
      });
      return [leg];
    },

    applyTransformationData(widgetRecords) {
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyTravelNeedsTransformation(widgetRecord);
          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    applyTravelNeedsTransformation(widgetRecord) {
      const store = this.get('store');
      const travelNeedTypes = store.peekAll('travel-need-type');
      const travelNeeds = widgetRecord.travelNeeds || [];
      travelNeeds.forEach(travelNeed => {
        const travelNeedTypeName = travelNeed.travelNeedTypeName;
        const travelNeedType = travelNeedTypes.findBy('id', travelNeedTypeName);
        travelNeed.displayName = travelNeedType.get('displayName');
      });
    },

    didInsertElement() {
      this._super(...arguments); // disable add/edit passenger for 4.2. This will be added back
      // later in 5.0


      this.set('tableActions', [{
        id: 'newPassenger',
        permId: _mappedPermIds.default.newPassenger,
        name: 'New Passenger',
        action: function () {
          const store = this.get('store');
          const newModel = store.createRecord(this.get('config.modelName'));
          const riderPhoneNumber = store.createRecord('rider-phone-number', {
            rider: newModel
          });
          newModel.set('dateOfBirth', new Date());
          newModel.set('riderPhoneNumbers', [riderPhoneNumber]);
          this.get('records').pushObject(newModel);
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      }, {
        id: 'print',
        permIds: [_mappedPermIds.default.downloadPassengers],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);
      this.set('singleActions', [{
        id: 'edit',
        permId: _mappedPermIds.default.editPassenger,
        name: 'Edit',
        action: model => {
          const store = this.get('store');
          const editComponent = this.get('editComponent');

          if (Ember.isNone(model.get('riderPhoneNumber'))) {
            store.createRecord('rider-phone-number', {
              rider: model
            });
          }

          this.get('editModal').open(editComponent, [model], this.get('saveRecordFunction').bind(this));
        }
      }, {
        id: 'addTrip',
        permIds: [_mappedPermIds.default.createFutureBookings, _mappedPermIds.default.createSameDayBookings],
        name: 'Add Trip',
        action: model => {
          const provider = this.store.peekAll('provider').firstObject; // @TODO booking requires a provider

          const subscriptions = this.createSubscriptionRecord();
          const legs = this.createLegSegmentRecord(model) || [];
          const booking = this.store.createRecord('booking', {
            subscriptions,
            legs,
            provider
          });
          legs.forEach(leg => {
            leg.set('booking', booking);
          });
          this.get('records').pushObject(booking);
          const newTripComponent = this.get('newTripComponent');
          const segment = booking.get('legs.firstObject.segments.firstObject');
          this.get('editModal').open(newTripComponent, [segment], this.get('saveTripRecordFunction').bind(this));
        }
      }]); // this.set('bulkActions', [{
      //   id: 'bulkEdit',
      //   name: 'Bulk Edit',
      //   action: (models) => {
      //     const editComponent = this.get('editComponent');
      //     Promise.all(models.map((model) => {
      //       const queryFilter = {
      //         filter: `eq(riderId,'${model.get('rmsRider.content.riderId')}')`
      //       };
      //       return this.store.query('rms-rider', queryFilter).then((rider) => rider.firstObject);
      //     }))
      //       .then((rmsRiders) => {
      //         this.get('editModal').open(editComponent, rmsRiders, this.get('saveRecordFunction').bind(this));
      //       });
      //   }
      // }]);
    },

    applyTransformationData(widgetRecords) {
      this.applyRiderPhoneNumberTransformation(widgetRecords);
      return widgetRecords;
    },

    applyRiderPhoneNumberTransformation(widgetRecords) {
      widgetRecords.forEach(rider => {
        const riderPhoneNumber = rider.riderPhoneNumber;
        let riderPhoneNumbers = riderPhoneNumber && riderPhoneNumber.split(',');

        if (riderPhoneNumbers && riderPhoneNumbers.length > 1) {
          riderPhoneNumbers = riderPhoneNumbers.filter(Boolean);
          rider.riderPhoneNumber = (0, _lodash.uniqBy)(riderPhoneNumbers).join();
        }
      });
    }

  });

  _exports.default = _default;
});