define("adept-iq/pods/components/side-drawer/trip-transfer/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stopColumns = _exports.solutionColumns = void 0;
  const MIN_COLUMN_WIDTH = 50;
  const solutionColumns = [{
    width: '40px',
    resizable: false,
    cellComponent: 'side-drawer/trip-transfer/radio-button'
  }, {
    label: 'Options',
    valuePath: 'dispatchRoute.route.name',
    cellComponent: 'side-drawer/trip-transfer/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH
  }, {
    label: 'Current OTP',
    valuePath: 'otp.originalValue',
    cellComponent: 'side-drawer/trip-transfer/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH
  }, {
    label: 'New OTP',
    valuePath: 'otp.newValue',
    cellComponent: 'side-drawer/trip-transfer/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH
  }, {
    label: 'Result',
    valuePath: 'otp.deltaValue',
    cellComponent: 'side-drawer/trip-transfer/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH
  }, {
    label: 'Provider',
    valuePath: 'dispatchRoute.provider.name',
    cellComponent: 'side-drawer/trip-transfer/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH
  }];
  _exports.solutionColumns = solutionColumns;
  const stopColumns = [{
    label: 'Trip ID',
    valuePath: 'stopPoint.trip.tripId',
    cellComponent: 'table/cells/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH,
    width: '80px'
  }, {
    label: 'Last Name',
    valuePath: 'stopPoint.trip.rider.lastName',
    cellComponent: 'table/cells/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH,
    width: '80px'
  }, {
    label: 'Address',
    valuePath: 'stopPoint.place.address.simpleAddress',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    width: '80px'
  }, {
    label: 'City',
    valuePath: 'stopPoint.place.address.locality',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    width: '60px'
  }, {
    label: 'Stop',
    valuePath: 'stopPoint.stopType',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    width: '60px'
  }, {
    label: 'ETA',
    valuePath: 'eta',
    cellComponent: 'table/cells/trip-eta',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    width: '60px'
  }, {
    label: 'Promise Time',
    valuePath: 'stopPoint.computedPromiseTime',
    cellComponent: 'table/cells/date-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    format: 'HH:mm',
    width: '80px'
  }, {
    label: 'Appt Time',
    valuePath: 'stopPoint.computedApptTime',
    cellComponent: 'table/cells/date-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH,
    format: 'HH:mm',
    width: '80px'
  }, {
    label: 'Stop Break Ord',
    valuePath: 'routeOrdinal',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    width: '80px'
  }];
  _exports.stopColumns = stopColumns;
});