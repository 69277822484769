define("adept-iq/config/icon-paths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    stops: {
      pickPlanned1: '/stops-icons/pick/pick-unperformed-green.svg',
      pickPlanned2: '/stops-icons/pick/pick-unperformed-blue.svg',
      pickPlanned3: '/stops-icons/pick/pick-unperformed-red.svg',
      pickPlanned4: '/stops-icons/pick/pick-unperformed-orange.svg',
      pickPlanned5: '/stops-icons/pick/pick-unperformed-purple.svg',
      planned: '/stops-icons/pick/pick-unperformed.svg',
      pickPlannedLate: '/stops-icons/pick/pick-late.svg',
      pickPlannedInDanger: '/stops-icons/pick/pick-behind.svg',
      pickNext: '/stops-icons/pick/pick-unperformed-next.svg',
      pickNextLate: '/stops-icons/pick/pick-late-next.svg',
      pickNextInDanger: '/stops-icons/pick/pick-behind-next.svg',
      pickCompleted: '/stops-icons/pick/pick-performed.svg',
      pickPlannedWheelchair: '/stops-icons/wc-pick/wc-pick-unperformed.svg',
      pickPlannedLateWheelchair: '/stops-icons/wc-pick/wc-pick-late.svg',
      pickPlannedInDangerWheelchair: '/stops-icons/wc-pick/wc-pick-behind.svg',
      pickNextWheelchair: '/stops-icons/wc-pick/wc-pick-unperformed-next.svg',
      pickNextLateWheelchair: '/stops-icons/wc-pick/wc-pick-late-next.svg',
      pickNextInDangerWheelchair: '/stops-icons/wc-pick/wc-pick-behind-next.svg',
      pickCompletedWheelchair: '/stops-icons/wc-pick/wc-pick-performed.svg',
      dropPlanned1: '/stops-icons/drop/drop-unperformed-green.svg',
      dropPlanned2: '/stops-icons/drop/drop-unperformed-blue.svg',
      dropPlanned3: '/stops-icons/drop/drop-unperformed-red.svg',
      dropPlanned4: '/stops-icons/drop/drop-unperformed-orange.svg',
      dropPlanned5: '/stops-icons/drop/drop-unperformed-purple.svg',
      dropPlannedLate: '/stops-icons/drop/drop-late.svg',
      dropPlannedInDanger: '/stops-icons/drop/drop-behind.svg',
      dropNext: '/stops-icons/drop/drop-unperformed-next.svg',
      dropNextLate: '/stops-icons/drop/drop-late-next.svg',
      dropNextInDanger: '/stops-icons/drop/drop-behind-next.svg',
      dropCompleted: '/stops-icons/drop/drop-performed.svg',
      dropPlannedWheelchair: '/stops-icons/wc-drop/wc-drop-unperformed.svg',
      dropPlannedLateWheelchair: '/stops-icons/wc-drop/wc-drop-late.svg',
      dropPlannedInDangerWheelchair: '/stops-icons/wc-drop/wc-drop-behind.svg',
      dropNextWheelchair: '/stops-icons/wc-drop/wc-drop-unperformed-next.svg',
      dropNextLateWheelchair: '/stops-icons/wc-drop/wc-drop-late-next.svg',
      dropNextInDangerWheelchair: '/stops-icons/wc-drop/wc-drop-behind-next.svg',
      dropCompletedWheelchair: '/stops-icons/wc-drop/wc-drop-performed.svg',
      garagePulloutPlanned1: '/stops-icons/garage/garage-pullout-green.svg',
      garagePulloutPlanned2: '/stops-icons/garage/garage-pullout-blue.svg',
      garagePulloutPlanned3: '/stops-icons/garage/garage-pullout-red.svg',
      garagePulloutPlanned4: '/stops-icons/garage/garage-pullout-orange.svg',
      garagePulloutPlanned5: '/stops-icons/garage/garage-pullout-purple.svg',
      garagePullinPlanned1: '/stops-icons/garage/garage-pullin-green.svg',
      garagePullinPlanned2: '/stops-icons/garage/garage-pullin-blue.svg',
      garagePullinPlanned3: '/stops-icons/garage/garage-pullin-red.svg',
      garagePullinPlanned4: '/stops-icons/garage/garage-pullin-orange.svg',
      garagePullinPlanned5: '/stops-icons/garage/garage-pullin-purple.svg',
      garagePlannedLate: '/stops-icons/garage/garage-late.svg',
      garagePlannedInDanger: '/stops-icons/garage/garage-behind.svg',
      garageNext: '/stops-icons/garage/garage-unperformed-next.svg',
      garageNextLate: '/stops-icons/garage/garage-late-next.svg',
      garageNextInDanger: '/stops-icons/garage/garage-behind-next.svg',
      garageCompleted: '/stops-icons/garage/garage-performed.svg',
      break: '/stops-icons/break.svg',
      noshow: '/stops-icons/noshow.svg',
      onbreak: '/vehicles-icons/icon-vehicle-break.svg'
    },
    vehicleEvents: {
      vehicle: '/vehicles-icons/icon-vehicle.svg',
      arrow: '/arrows-icons/arrow.svg',
      planned: '/stops-icons/pick/pick-unperformed.svg',
      pickPlanned: '/stops-icons/pick/pick-unperformed.svg',
      pickPlannedLate: '/stops-icons/pick/pick-late.svg',
      pickPlannedInDanger: '/stops-icons/pick/pick-behind.svg',
      pickNext: '/stops-icons/pick/pick-unperformed-next.svg',
      pickNextLate: '/stops-icons/pick/pick-late-next.svg',
      pickNextInDanger: '/stops-icons/pick/pick-behind-next.svg',
      pickCompleted: '/stops-icons/pick/pick-performed.svg',
      pickPlannedWheelchair: '/stops-icons/wc-pick/wc-pick-unperformed.svg',
      pickPlannedLateWheelchair: '/stops-icons/wc-pick/wc-pick-late.svg',
      pickPlannedInDangerWheelchair: '/stops-icons/wc-pick/wc-pick-behind.svg',
      pickNextWheelchair: '/stops-icons/wc-pick/wc-pick-unperformed-next.svg',
      pickNextLateWheelchair: '/stops-icons/wc-pick/wc-pick-late-next.svg',
      pickNextInDangerWheelchair: '/stops-icons/wc-pick/wc-pick-behind-next.svg',
      pickCompletedWheelchair: '/stops-icons/wc-pick/wc-pick-performed.svg',
      dropPlanned: '/stops-icons/drop/drop-unperformed.svg',
      dropPlannedLate: '/stops-icons/drop/drop-late.svg',
      dropPlannedInDanger: '/stops-icons/drop/drop-behind.svg',
      dropNext: '/stops-icons/drop/drop-unperformed-next.svg',
      dropNextLate: '/stops-icons/drop/drop-late-next.svg',
      dropNextInDanger: '/stops-icons/drop/drop-behind-next.svg',
      dropCompleted: '/stops-icons/drop/drop-performed.svg',
      dropPlannedWheelchair: '/stops-icons/wc-drop/wc-drop-unperformed.svg',
      dropPlannedLateWheelchair: '/stops-icons/wc-drop/wc-drop-late.svg',
      dropPlannedInDangerWheelchair: '/stops-icons/wc-drop/wc-drop-behind.svg',
      dropNextWheelchair: '/stops-icons/wc-drop/wc-drop-unperformed-next.svg',
      dropNextLateWheelchair: '/stops-icons/wc-drop/wc-drop-late-next.svg',
      dropNextInDangerWheelchair: '/stops-icons/wc-drop/wc-drop-behind-next.svg',
      dropCompletedWheelchair: '/stops-icons/wc-drop/wc-drop-performed.svg',
      garagePlanned: '/stops-icons/garage/garage-unperformed.svg',
      garagePlannedLate: '/stops-icons/garage/garage-late.svg',
      garagePlannedInDanger: '/stops-icons/garage/garage-behind.svg',
      garageNext: '/stops-icons/garage/garage-unperformed-next.svg',
      garageNextLate: '/stops-icons/garage/garage-late-next.svg',
      garageNextInDanger: '/stops-icons/garage/garage-behind-next.svg',
      garageCompleted: '/stops-icons/garage/garage-performed.svg',
      break: '/stops-icons/break.svg',
      noshow: '/stops-icons/noshow.svg'
    },
    vehicles: {
      vehicleAVLM: '/vehicles-icons/icon-vehicle.png',
      vehicle: '/vehicles-icons/icon-vehicle.svg',
      vehicleDanger: '/vehicles-icons/icon-vehicle-gettinglate.svg',
      vehicleLate: '/vehicles-icons/icon-vehicle-late.svg',
      roadSupervisor: '/vehicles-icons/other-supervisors-noarrow.svg',
      drivingRoadSupervisor: '/vehicles-icons/ic-supervisor.svg',
      loggedRoadSupervisor: '/vehicles-icons/ic-supervisor.svg',
      onBreakAVLM: '/vehicles-icons/icon-vehicle-break.svg',
      onBreak: '/vehicles-icons/icon-vehicle-break.svg',
      atWork: '/vehicles-icons/icon-vehicle.svg',
      atWorkAVLM: '/vehicles-icons/icon-vehicle.png',
      atWorkDanger: '/vehicles-icons/icon-vehicle-gettinglate.svg',
      atWorkLate: '/vehicles-icons/icon-vehicle-late.svg',
      atWorkEmergency: '/vehicles-icons/icon-vehicle-emergency.svg',
      atWorkEmergencyDanger: '/vehicles-icons/icon-vehicle-emergency.svg',
      atWorkEmergencyLate: '/vehicles-icons/icon-vehicle-emergency.svg',
      vehicleBreakdown: '/vehicles-icons/icon-vehicle-broken.svg',
      vehicleCommunicationFailed: '/vehicles-icons/icon-communication-failed.svg',
      vehicleGPSFailed: '/vehicles-icons/icon-gps-failed.svg',
      vehicleNotSigned: '/vehicles-icons/icon-vehicle-not-signedin.svg'
    },
    zones: {
      normal: '/zones-icons/zone-line-normal.png'
    },
    tripArrow: {
      ontime: '/arrows-icons/arrow.svg',
      danger: '/arrows-icons/arrow-danger.svg',
      late: '/arrows-icons/arrow-late.svg'
    },
    tripArrowOffset: {
      ontime: '/arrows-icons/arrow_offset.svg',
      danger: '/arrows-icons/arrow_offset-danger.svg',
      late: '/arrows-icons/arrow_offset-late.svg'
    },
    routes: {
      planned: '/lines-icons/triangle-planned.svg',
      plannedLine: '/lines-icons/triangle-planned-line.svg',
      performed: '/lines-icons/triangle-performed.svg',
      performedLine: '/lines-icons/triangle-performed-line.svg',
      navigation: '/lines-icons/triangle-navigation.svg',
      navigationLine: '/lines-icons/triangle-navigation-line.svg',
      scheduled: '/lines-icons/scheduled.svg',
      floatingBreak: '/lines-icons/line-floating-break.png'
    },
    trips: {
      planned: '/lines-icons/line-planned.png',
      performed: '/lines-icons/line-performed.png',
      scheduled: '/lines-icons/line-scheduled.png',
      completed: '/lines-icons/line-completed.png',
      waitListed: '/lines-icons/line-waitlisted.png',
      callback: '/lines-icons/line-callback.png',
      active: '/lines-icons/line-active.png',
      noShowed: '/lines-icons/line-noshowed.png',
      noShowRequest: '/lines-icons/line-noshowrequest.png',
      dispatched: '/lines-icons/line-dispatched.png',
      enRoute: '/lines-icons/line-enroute.png',
      illegal: '/lines-icons/line-illegal.png',
      unScheduled: '/lines-icons/line-unscheduled.png',
      canceled: '/lines-icons/line-canceled.png',
      lateCanceled: '/lines-icons/line-latecanceled.png'
    },
    maps: {
      pinPoint: '/map-icons/icon-pinpoint.svg',
      highlight: '/map-icons/yellow-highlight.svg'
    },
    requestdetails: {
      paratransiticon: '/vehicles-icons/icon-paratransit.svg',
      busicon: '/vehicles-icons/icon-bus.svg',
      walkicon: '/vehicles-icons/icon-walk.svg',
      taxiicon: '/vehicles-icons/icon-taxi.svg'
    },
    actionMenu: {
      myProfile: '/icons/my-profile-icon.png',
      sandbox: '/icons/sandbox-icon.png',
      apiDocumentation: '/icons/api-doc-icon.svg',
      fleetEditor: '/icons/fleet-editor-icon.svg',
      scheduleMetrics: '/icons/schedule-metrics-icon.svg',
      optimize: '/icons/optimize.svg',
      logout: '/icons/logout-icon.png',
      upload: '/icons/upload-icon.svg',
      download: '/icons/download-icon.svg',
      checkmark: '/icons/checkmark-icon.svg'
    },
    pageBackgrounds: {
      main: '/background.png'
    }
  };
  _exports.default = _default;
});