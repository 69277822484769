define("adept-iq/pods/components/menu-navbar/component", ["exports", "adept-iq/config/icon-paths", "adept-iq/config/api-urls"], function (_exports, _iconPaths, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    isFleetManagementOpen: Ember.computed.readOnly('workspace.isFleetManagementOpen'),
    isScheduleMetricsOpen: Ember.computed.readOnly('workspace.isScheduleMetricsOpen'),
    isOptimizeScheduleOpen: Ember.computed.readOnly('workspace.isOptimizeScheduleOpen'),
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),
    isDropdownVisible: false,

    // Initial state
    get iconPaths() {
      return {
        optimize: _iconPaths.default.actionMenu.optimize,
        scheduleMetrics: _iconPaths.default.actionMenu.scheduleMetrics,
        fleetEditor: _iconPaths.default.actionMenu.fleetEditor,
        apiDocumentation: _iconPaths.default.actionMenu.apiDocumentation,
        myProfile: _iconPaths.default.actionMenu.myProfile,
        logout: _iconPaths.default.actionMenu.logout
      };
    },

    navItems: Ember.computed('isScheduleDashboard', 'isFleetManagementOpen', 'isScheduleMetricsOpen', 'isOptimizeScheduleOpen', function () {
      if (this.isScheduleDashboard) {
        return [{
          iconPath: this.iconPaths.optimize,
          label: 'Optimize',
          scaleClass: 'scale-2',
          actionName: 'openOptimizeSchedule',
          highlighted: this.isOptimizeScheduleOpen
        }, {
          iconPath: this.iconPaths.scheduleMetrics,
          label: 'Schedule Metrics',
          scaleClass: 'scale-1',
          actionName: 'openScheduleMetrics',
          highlighted: this.isScheduleMetricsOpen
        }, {
          iconPath: this.iconPaths.fleetEditor,
          label: 'Fleet Editor',
          scaleClass: 'scale-2',
          actionName: 'openFleetEditor',
          highlighted: this.isFleetManagementOpen
        }, {
          iconPath: this.iconPaths.apiDocumentation,
          label: 'API Docs',
          scaleClass: 'scale-1-5',
          actionName: 'openApiDocumentation',
          highlighted: false
        }, {
          iconPath: this.iconPaths.myProfile,
          label: 'My Profile',
          scaleClass: 'scale-0-75',
          actionName: 'openMyProfile',
          highlighted: false
        }];
      }

      return [];
    }),
    actions: {
      openOptimizeSchedule() {
        const workspace = this.get('workspace');
        workspace.set('isOptimizeScheduleOpen', true);
      },

      openScheduleMetrics() {
        this.get('workspace').pushState('openScheduleMetrics');
      },

      openFleetEditor() {
        this.get('workspace').pushState('openFleetManagement');
      },

      openApiDocumentation() {
        window.open(_apiUrls.API.apiDocs.host, '_blank');
      },

      openMyProfile() {
        this.get('workspace').pushState('openUserProfile');
      },

      // Toggle dropdown visibility
      toggleDropdown() {
        this.toggleProperty('isDropdownVisible'); // Toggles between true and false
      },

      async logout() {
        return this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});