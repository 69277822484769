define("adept-iq/pods/require-new-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x1Fb6LtG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"require-new-password\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"p-4 bg-white\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isNewUser\"]]],null,{\"statements\":[[0,\"        Temporary Password Expired\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Your Password Is Expired\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"\\n          Please create a new password.\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn\"],[9],[0,\"\\n        Cancel\\n      \"],[3,\"action\",[[22,0,[]],\"redirectToLogin\"]],[10],[0,\"\\n        \"],[4,\"link-to\",[\"change-password\"],[[\"class\"],[\"btn btn\"]],{\"statements\":[[0,\"OK\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/require-new-password/template.hbs"
    }
  });

  _exports.default = _default;
});