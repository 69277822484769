define("adept-iq/pods/components/user-profile/optimization-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    maxOptimizationIterations: '',
    maxInsertionPairs: '',
    rideIterations: '',
    disableDefaultBtn: false,
    disableSubmitBtn: false,
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),

    updateConfigItems(configItems) {
      const keyValueMap = this.get('keyValueMap');
      configItems.forEach(item => {
        item.value.forEach(keyValuePair => {
          if (keyValueMap.hasOwnProperty(keyValuePair.key)) {
            const property = keyValueMap[keyValuePair.key];
            const propertyValue = this.get(property);

            if (keyValuePair.value !== propertyValue) {
              keyValuePair.value = propertyValue;
            }

            keyValuePair.value = propertyValue;
          }
        });
      });
      return Promise.all(configItems.map(item => {
        return item.save();
      })).catch(error => {
        throw error;
      });
    },

    init() {
      this._super(...arguments);

      this.set('keyValueMap', {
        'Batch_Multiple_Passes': 'rideIterations',
        'Max_Routes_To_Consider': 'maxOptimizationIterations',
        'Max_Pairs_To_Consider': 'maxInsertionPairs'
      });
      this.loadNonDefaultStrategy();
    },

    loadNonDefaultStrategy() {
      const configEngineStrategy = this.store.peekRecord('cs-config-category', 'config-Engine_Strategy');

      if (configEngineStrategy) {
        const configItems = configEngineStrategy.configItems.filter(item => item.id !== 'config-Engine_Strategy/default');

        if (configItems.length > 0) {
          this.setConfigItems(configItems[0]);
        } else {
          // eslint-disable-next-line no-console
          console.error('No non-default config items found');
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('config-Engine_Strategy not found');
      }
    },

    setConfigItems(configItem) {
      const keyValueMap = this.get('keyValueMap');
      configItem.value.forEach(keyValuePair => {
        if (keyValueMap.hasOwnProperty(keyValuePair.key)) {
          const property = keyValueMap[keyValuePair.key];
          this.set(property, keyValuePair.value);
        }
      });
    },

    actions: {
      async resetToDefaultSettings() {
        this.set('disableDefaultBtn', true);
        const notifications = this.get('notifications');

        try {
          const defaultSetting = this.store.peekRecord('cs-config-item', 'config-Engine_Strategy/default');

          if (defaultSetting) {
            this.setConfigItems(defaultSetting);
            notifications.success('Default settings applied successfully.');
          } else {
            throw new Error('Default strategy not found');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error resetToDefaultSettings operation:', error);
          notifications.error('Failed to apply default settings. Please try again.');
        } finally {
          this.set('disableDefaultBtn', false);
        }
      },

      async submitChanges() {
        this.set('disableSubmitBtn', true);
        const notifications = this.get('notifications');

        try {
          const configEngineStrategy = this.store.peekRecord('cs-config-category', 'config-Engine_Strategy');

          if (configEngineStrategy) {
            const configItems = configEngineStrategy.configItems.filter(item => item.id !== 'config-Engine_Strategy/default');
            await this.updateConfigItems(configItems);
            notifications.success('Changes submitted successfully.');
          } else {
            throw new Error('config-Engine_Strategy not found');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('An error occurred during the submitChanges operation:', error);
          notifications.error('Failed to submit changes. Please try again.');
        } finally {
          this.set('disableSubmitBtn', false);
        }
      }

    }
  });

  _exports.default = _default;
});