define("adept-iq/config/workspace-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'default',
    hooks: []
  }, {
    id: 'editWorkspace',
    options: {
      classNames: ['add-widget-drawer']
    },
    sideDrawerComponent: 'side-drawer/add-widget',
    hooks: [{
      from: '*',

      action() {
        // this allows us to roll back on cancel
        this.get('dashboard').makeWidgetsSavepoint(); // set tooltip

        this.get('tooltip').pushConfirmation({
          tip: 'Configuration mode:',
          primaryActionText: 'Exit Configuration',
          secondaryActionText: 'Undo Changes',
          primaryAction: () => {
            this.popState('editWorkspace');
            this.get('notifications').info('In order to retain the workspace changes, you must save the workspace.');
          },
          secondaryAction: () => {
            this.get('dashboard').rollbackWidgets(); // Allow time for the GridStack instance to update the grid with the
            // rolled back widget configs before popping edit workspace state.

            setTimeout(() => {
              this.popState('editWorkspace');
            }, 15);
          }
        });
      }

    }, {
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'editPlayback',
    hooks: [{
      from: '*',

      action() {
        // this allows us to roll back on cancel
        this.get('dashboard').makeWidgetsSavepoint(); // set tooltip

        this.get('tooltip').pushConfirmation({
          tip: 'Configuration mode:',
          primaryActionText: 'Exit Configuration',
          secondaryActionText: 'Undo Changes',
          primaryAction: () => {
            this.popState('editPlayback');
            this.get('notifications').info('In order to retain the workspace changes, you must save the workspace.');
          },
          secondaryAction: () => {
            this.get('dashboard').rollbackWidgets(); // Allow time for the GridStack instance to update the grid with the
            // rolled back widget configs before popping edit workspace state.

            setTimeout(() => {
              this.popState('editPlayback');
            }, 15);
          }
        });
      }

    }, {
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'editRecords',
    isSideDrawerVisible: true,
    hooks: []
  }, {
    id: 'filterColumnWidget',
    sideDrawerComponent: 'side-drawer/filter-settings',
    hooks: []
  }, {
    id: 'gaugeWidget',
    sideDrawerComponent: 'side-drawer/gauge-chart-settings',
    hooks: []
  }, {
    id: 'donutChartWidget',
    sideDrawerComponent: 'side-drawer/donut-chart-settings',
    hooks: []
  }, {
    id: 'barChartWidget',
    sideDrawerComponent: 'side-drawer/bar-chart-settings',
    hooks: []
  }, {
    id: 'scatterChartWidget',
    sideDrawerComponent: 'side-drawer/scatter-chart-settings',
    hooks: []
  }, {
    id: 'boxWidget',
    sideDrawerComponent: 'side-drawer/box-widget-settings',
    hooks: []
  }, {
    // TODO: is this a real state?
    id: 'metricsColumnWidget',
    isSideDrawerVisible: true,
    hooks: []
  }, {
    id: 'filterMapWidget',
    sideDrawerComponent: 'side-drawer/map-filter',
    hooks: []
  }, {
    id: 'addNewMessage',
    sideDrawerComponent: 'side-drawer/add-new-message',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'openWorkspace',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'saveWorkspaceAs',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'editWorkspaceAs',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'deleteWorkspace',
    hooks: [{
      from: '*',

      action() {
        const mappedPermIds = this.get('mappedPermIds');
        const permissionLayer = this.get('permissionLayer');
        const dashboard = this.get('dashboardModel');
        const userId = this.get('session').data.authenticated.userId.replace(/-/g, '_');
        const category = `workspaces-${userId}`;
        const deletePrivateWorkspacePerm = permissionLayer.permInUserHash(mappedPermIds.deletePrivateWorkspace);
        const deletePublicWorkspacePerm = permissionLayer.permInUserHash(mappedPermIds.deletePublicWorkspace);
        const canDeleteAll = this.get('permissionLayer').permInUserHash(mappedPermIds.deleteWorkspace);

        if (!canDeleteAll && dashboard.get('isPublicWorkspace') && !deletePublicWorkspacePerm && dashboard.get('userId') !== userId || dashboard.get('isPrivateWorkspace') && (dashboard.get('category') !== category || !deletePrivateWorkspacePerm)) {
          this.get('tooltip').pushConfirmation({
            title: 'Delete Workspace',
            tip: 'You do not have permission to delete the workspace.',
            warning: true,
            hasOverlay: true,
            primaryActionText: 'Ok',
            primaryAction: () => {
              this.popState('deleteWorkspace');
            },
            closeAction: () => {
              this.popState('deleteWorkspace');
            }
          });
          return;
        }

        this.get('tooltip').pushConfirmation({
          title: 'Delete workspace',
          tip: 'Permanently delete workspace?',
          hasOverlay: true,
          primaryActionText: 'Delete Workspace',
          secondaryActionText: 'Keep Workspace',
          primaryAction: () => {
            this.popState('deleteWorkspace').then(async () => {
              const name = this.get('dashboardModel.name');
              const getDefaultDashboardModel = this.get('workspaceContext.workspace.getDefaultDashboardModel').bind(this);
              const defaultDashboard = await getDefaultDashboardModel();
              return dashboard.destroyRecord().then(() => {
                // have to unloadRecord to unmap ember data id
                dashboard.unloadRecord(); // must clear this before transitioning to index

                localStorage.removeItem('lastDashboardId');
                localStorage.removeItem('userDash');

                if (defaultDashboard) {
                  this.get('router').transitionTo('dashboard', defaultDashboard);
                } else {
                  this.get('router').transitionTo('index');
                }

                this.get('notifications').success(`Successfully Deleted Workspace ${name}`);
              }).catch(() => {
                this.get('notifications').warning(`Failed to Delete Workspace ${name}`);
              });
            });
          },
          secondaryAction: () => {
            this.popState('deleteWorkspace');
          },
          closeAction: () => {
            this.popState('deleteWorkspace');
          }
        });
      }

    }, {
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'closeSchedule',
    hooks: []
  }, {
    id: 'reoptimize',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'schedule-generate',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'assignVehicleDriverToRoute',
    sideDrawerComponent: 'side-drawer/assign-vehicle-driver-to-route'
  }, {
    id: 'routeActivityLog',
    sideDrawerComponent: 'side-drawer/activity-log/route-activity-log'
  }, {
    id: 'tripActivityLog',
    sideDrawerComponent: 'side-drawer/activity-log/trip-activity-log'
  }, {
    id: 'reconcileCancelTrip',
    sideDrawerComponent: 'side-drawer/reconcile-cancel-trip'
  }, {
    id: 'vehicleActivityLog',
    sideDrawerComponent: 'side-drawer/activity-log/vehicle-activity-log'
  }, {
    id: 'bookingActivityLog',
    sideDrawerComponent: 'side-drawer/activity-log/booking-activity-log'
  }, {
    id: 'subscriptionActivityLog',
    sideDrawerComponent: 'side-drawer/activity-log/subscription-activity-log'
  }, {
    id: 'noShowForm',
    sideDrawerComponent: 'side-drawer/no-show-form',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'cancelTripForm',
    sideDrawerComponent: 'side-drawer/cancel-trip-form',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'editSystemConfiguration',
    sideDrawerComponent: 'side-drawer/system-config'
  }, {
    id: 'roadSupervisor'
  }, {
    id: 'tripTransfer',
    sideDrawerComponent: 'side-drawer/trip-transfer',
    options: {
      displayName: 'Trip Transfer',
      classNames: ['wide']
    }
  }, {
    id: 'performDriverBreak',
    sideDrawerComponent: 'side-drawer/perform-stop/break',
    classNames: ['vertical-scroll']
  }, {
    id: 'performPickStop',
    sideDrawerComponent: 'side-drawer/perform-stop/pick',
    classNames: ['vertical-scroll']
  }, {
    id: 'unPerformNoShow',
    sideDrawerComponent: 'side-drawer/unperform-no-show'
  }, {
    id: 'reconcileUnPerformNoShow',
    sideDrawerComponent: 'side-drawer/reconcile-unperform-no-show'
  }, {
    id: 'unPerformPickStop',
    sideDrawerComponent: 'side-drawer/unperform-pick-stop'
  }, {
    id: 'performDropStop',
    sideDrawerComponent: 'side-drawer/perform-stop/drop'
  }, {
    id: 'performAllStop',
    sideDrawerComponent: 'side-drawer/perform-stop/all',
    options: {
      classNames: ['wide', 'vertical-scroll']
    }
  }, {
    id: 'unPerformDropStop',
    sideDrawerComponent: 'side-drawer/unperform-drop-stop'
  }, {
    id: 'unPerformBreak',
    sideDrawerComponent: 'side-drawer/unperform-break-stop'
  }, {
    id: 'rescueRoute',
    sideDrawerComponent: 'side-drawer/rescue-route'
  }, {
    id: 'rescueRouteReconcile',
    sideDrawerComponent: 'side-drawer/reconcile-rescue-route'
  }, {
    id: 'addNewRoute',
    sideDrawerComponent: 'side-drawer/add-new-route'
  }, {
    id: 'reconcileAddNewRoute',
    sideDrawerComponent: 'side-drawer/reconcile-add-new-route'
  }, {
    id: 'reconcileVehicleBreakdown',
    sideDrawerComponent: 'side-drawer/reconcile-vehicle-breakdown'
  }, {
    id: 'addeditScheduleRoute',
    sideDrawerComponent: 'side-drawer/add-edit-schedule-route'
  }, {
    id: 'composeRole',
    sideDrawerComponent: 'side-drawer/compose-role'
  }, {
    id: 'permAddRole',
    sideDrawerComponent: 'side-drawer/permissions-add-role'
  }, {
    id: 'schedulingBulkTripTransfer',
    sideDrawerComponent: 'side-drawer/scheduling-bulk-trip-transfer',
    options: {
      displayName: 'Bulk Trip Transfer',
      classNames: ['wide', 'vertical-scroll']
    }
  }, {
    id: 'addBreak',
    sideDrawerComponent: 'side-drawer/add-break',
    options: {
      displayName: 'Add Driver Break',
      classNames: ['wide']
    }
  }, {
    id: 'reconcileAddBreak',
    sideDrawerComponent: 'side-drawer/reconcile-add-break',
    options: {
      displayName: 'Insert Driver Break',
      classNames: ['wide']
    }
  }, {
    id: 'schedulingTripTransfer',
    sideDrawerComponent: 'side-drawer/scheduling-trip-transfer',
    options: {
      displayName: 'Trip Transfer',
      classNames: ['wide']
    }
  }, {
    id: 'schedulingAutoReschedule',
    sideDrawerComponent: 'side-drawer/scheduling-auto-reschedule',
    options: {
      displayName: 'Trip Rescheduled',
      classNames: ['wide', 'vertical-scroll']
    }
  }, {
    id: 'bulkTripTransfer',
    sideDrawerComponent: 'side-drawer/bulk-trip-transfer',
    options: {
      displayName: 'Bulk Trip Transfer',
      classNames: ['wide']
    }
  }, {
    id: 'editTripWidget',
    sideDrawerComponent: 'side-drawer/edit-trip-widget',
    options: {
      displayName: 'Trip Transfer',
      classNames: ['wide', 'vertical-scroll']
    }
  }, {
    id: 'reconcileTripTransferToRoute',
    sideDrawerComponent: 'side-drawer/reconcile-trip-transfer',
    options: {
      displayName: 'Transfer Trip to Another Route',
      classNames: ['wide']
    }
  }, {
    id: 'reconcileNoShowTrip',
    sideDrawerComponent: 'side-drawer/reconcile-noshow-trip',
    options: {
      displayName: 'No Show Trip',
      classNames: ['wide']
    }
  }, {
    id: 'reconcileOpenDates',
    sideDrawerComponent: 'side-drawer/reconcile-reopen-dates',
    options: {
      displayName: 'Reopen dates to editing',
      classNames: ['wide']
    }
  }, {
    id: 'reconcileReplacementBreakdown',
    sideDrawerComponent: 'side-drawer/reconcile-replacement-breakdown',
    options: {
      displayName: 'Add Replacement Route',
      classNames: ['wide']
    }
  }, {
    id: 'reconcileCloseDates',
    sideDrawerComponent: 'side-drawer/reconcile-close-dates',
    options: {
      displayName: 'Close dates to editing',
      classNames: ['wide']
    }
  }, {
    id: 'playbackFilterColumnWidget',
    sideDrawerComponent: 'side-drawer/playback-filter-settings',
    hooks: []
  }, {
    id: 'reconcileFilterColumnWidget',
    sideDrawerComponent: 'side-drawer/reconcile-filter-settings',
    hooks: []
  }, {
    id: 'schedulingAddBreak',
    sideDrawerComponent: 'side-drawer/scheduling-add-break',
    options: {
      classNames: ['wide']
    }
  }, {
    id: 'reconcileShowRouteLog',
    sideDrawerComponent: 'side-drawer/reconcile-route-log',
    options: {
      classNames: ['wide']
    }
  }, {
    id: 'reconcileTripLog',
    sideDrawerComponent: 'side-drawer/reconcile-trip-log',
    options: {
      classNames: ['wide']
    }
  }, {
    id: 'reconcileAddNewTrip',
    sideDrawerComponent: 'side-drawer/reconcile-add-new-trip',
    options: {
      displayName: 'Add New Trip',
      classNames: ['wide']
    }
  }, {
    id: 'reconcileScheduleUnscheduledTrip',
    sideDrawerComponent: 'side-drawer/reconcile-schedule-unscheduled-trips',
    options: {
      displayName: 'Schdule Trip to a Route',
      classNames: ['wide']
    }
  }, {
    id: 'reconcileEditAdditionalTripInfo',
    sideDrawerComponent: 'side-drawer/reconcile-edit-trip',
    options: {
      displayName: 'Edit Trip',
      classNames: ['wide']
    }
  }, {
    id: 'permissionsAddNewUser',
    sideDrawerComponent: 'side-drawer/permissions-add-user',
    options: {
      displayName: 'Add User',
      classNames: ['wide']
    }
  }, {
    id: 'permissionsEditUser',
    sideDrawerComponent: 'side-drawer/permissions-edit-user',
    options: {
      displayName: 'Edit User',
      classNames: ['wide']
    }
  }, {
    id: 'permissionsFilterColumnWidget',
    sideDrawerComponent: 'side-drawer/permissions-filter-settings',
    hooks: []
  }, {
    id: 'userActivityLog',
    sideDrawerComponent: 'side-drawer/activity-log/user-activity-log',
    options: {
      displayName: 'User Activity Log',
      classNames: ['wide']
    }
  }, {
    id: 'blockoutAlternativeAddresses',
    sideDrawerComponent: 'side-drawer/blockout-alternative-addresses',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'openFleetManagement',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'openScheduleMetrics',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }, {
    id: 'openUserProfile',
    hooks: [{
      to: '*',

      action() {
        this.get('tooltip').reset();
      }

    }]
  }];
  _exports.default = _default;
});