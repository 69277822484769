define("adept-iq/pods/components/iq-widgets/routes-widget/scheduleConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/routes-widget',
    rowComponent: 'iq-widgets/routes-widget/routes-row',
    modelName: 'route',
    title: 'Scheduled Routes',
    defaultSortId: 'vehicleName',
    defaultSortAsc: true,
    // ember-react-widget properties
    widgetName: 'routeWidget',
    selectedRowLimit: 5,
    maxLimitByRouteSelection: 5,
    restrictSwapColumns: false,
    hideWidgetControls: false,
    columns: [{
      id: 'vehicleName',
      index: 1,
      type: 'uuid',
      label: 'Vehicle ID',
      valuePath: 'vehicleName',
      valuePreview: '22',
      editable: false,
      isMapLabelVisible: false,
      searchable: false,
      defaultWidth: 100
    }, {
      id: 'vehicleType',
      index: 2,
      type: 'string',
      label: 'Vehicle Type',
      valuePath: 'vehicleTypeDisplayName',
      toolTipPath: 'vehicleCapacity',
      searchable: false,
      highlightable: false,
      defaultWidth: 100
    }, {
      id: 'startLocation',
      index: 3,
      type: 'string',
      label: 'Start Location',
      valuePath: 'startGarage',
      searchable: false,
      highlightable: false,
      defaultWidth: 350
    }, {
      id: 'endLocation',
      index: 4,
      type: 'string',
      label: 'End Location',
      valuePath: 'endGarage',
      searchable: false,
      highlightable: false,
      defaultWidth: 350
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'routeId'
        }]
      },
      stopWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'routeId'
        }]
      }
    }
  };
  _exports.default = _default;
});