define("adept-iq/pods/components/form-components/address-search/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_DEBOUNCE_TIME = 1200;
  const DEFAULT_PLACEHOLDER = 'Search Address...';
  const UP_ARROW_KEYCODE = 38;
  const DOWN_ARROW_KEYCODE = 40;
  const ENTER_KEYCODE = 13;

  var _default = Ember.Component.extend({
    classNames: ['address-search-component'],
    classNameBindings: ['isFocused', 'isBlurred', 'hasSelection', 'isEmpty'],
    addressService: Ember.inject.service('address'),
    user: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    text: '',
    selectedAddress: null,
    inputClass: null,
    debounceTime: DEFAULT_DEBOUNCE_TIME,
    placeHolder: DEFAULT_PLACEHOLDER,
    onTextChange: () => null,
    onAddressSelect: () => null,
    searchResults: null,
    assesmentCenterList: null,
    isFocused: false,
    isExpanded: false,
    autoFocus: true,
    selectedAddressIndex: null,
    isAddressHovered: false,
    isBlurred: Ember.computed.not('isFocused'),
    hasSelection: Ember.computed.bool('selectedAddress'),
    isEmpty: Ember.computed.not('hasSelected'),
    selectedRecentAddressIndex: null,
    isRecentAddressHovered: false,

    init() {
      this._super(...arguments);

      this.setProperties({
        assesmentCenterList: this.get('assesmentCenterList') || []
      });
      const initialAddresses = this.get('initialAddresses');

      if (initialAddresses) {
        this.set('searchResults', initialAddresses);
      }

      this.set('selectedAddressIndex', -1);
    },

    didInsertElement() {
      Ember.run.schedule('afterRender', () => {
        if (this.get('autoFocus')) {
          this.$('input').focus();
        }
      });

      this._bodyClickHandler = event => {
        if (this.get('isDestroyed')) return; // close dropdown if we click elsewhere in application

        if (!event.target.closest('.address-search-component')) {
          this.set('isExpanded', false);
        }
      };

      document.body.addEventListener('click', this._bodyClickHandler);
    },

    willRemoveElement() {
      document.body.removeEventListener('click', this._bodyClickHandler);
    },

    searchTask: (0, _emberConcurrency.task)(function* () {
      const text = this.get('text');
      let results; // can't do a blank search

      if (Ember.isEmpty(text)) {
        this.set('searchResults', null);
        this.set('isExpanded', false);
        return;
      }

      this.set('isExpanded', true);

      if (this.user.isRoadSupEnable()) {
        const center = this.roadSupervisor.getLocation();
        results = yield this.get('addressService').fuzzySearch(text, {
          center,
          limit: 25
        });
      } else {
        results = yield this.get('addressService').fuzzySearch(text, {
          limit: 25
        });
      }

      if (Ember.isEmpty(results)) {
        return;
      } // Show Assesment Center Place into Address lookup NYAAR-10385


      results = this.insertAssesmentAddressList(results, text);
      const matchedAddresses = results.filter(result => {
        return result.get('fullAddress') === text;
      });

      if (matchedAddresses.length > 0) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(matchedAddresses[0]);
          this.set('searchResults', matchedAddresses);
          this.set('isExpanded', false);
        });
        return;
      }

      this.set('searchResults', results);
    }).restartable(),

    // wrapped so we can use `debounce`
    performSearchTask() {
      this.get('searchTask').perform();
    },

    //get AssementCenterList
    getAssesmentCenterList() {
      return this.get('assesmentCenterList');
    },

    insertAssesmentAddressList(searchResults, searchText) {
      const assesmentCenterList = this.getAssesmentCenterList(); //if assesmentCenterList is empty return the searchResults

      if (assesmentCenterList.length === 0) {
        return searchResults;
      }

      const assesmentAddressList = assesmentCenterList.filter(result => {
        return result.get('fullAddress').includes(searchText);
      }); // insert assesmentAddressList to top of the searchResults

      searchResults.unshiftObjects(assesmentAddressList); // remove duplicate address

      const uniqSearchResults = (0, _lodash.uniqBy)(searchResults, function (address) {
        return address.get('streetAddress');
      });
      return uniqSearchResults;
    },

    //adding the hover properties while address selected using up or down arrow keys
    addClassForSelectedAddress(selectedAddressIndex) {
      const searchResults = this.get('searchResults');
      searchResults.forEach((result, index) => {
        const className = `.selectedAddress${index}`;
        this.$(className).removeClass('selectedAddress');

        if (selectedAddressIndex === index) {
          this.$(className).addClass('selectedAddress');
        }
      });
    },

    //setting the selected address for pick or drop address
    onSelectAddress(searchResults, selectedAddressIndex) {
      const address = searchResults[selectedAddressIndex];
      Ember.run.schedule('afterRender', () => {
        this.onAddressSelect(address);
        this.set('isExpanded', false);
      });
      this.set('selectedAddressIndex', -1);
    },

    actions: {
      onInputFocus() {
        Ember.run.schedule('afterRender', () => {
          this.set('isFocused', true);

          if (!Ember.isEmpty(this.get('searchResults'))) {
            this.set('isExpanded', true);
          }
        });
      },

      onInputBlur() {
        Ember.run.schedule('afterRender', () => {
          this.set('isFocused', false);
        });
      },

      onInput(text) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(null);
          this.onTextChange(text);
          Ember.run.debounce(this, 'performSearchTask', this.get('debounceTime'));
        });
        this.set('selectedAddressIndex', -1);
      },

      onAddressSelect(address) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(address);
          this.set('isExpanded', false);
        });
      },

      onKeyDown(event) {
        const recentChecked = this.get('recentChecked');
        const configuredChecked = this.get('configuredChecked');
        const favoriteChecked = this.get('favoriteChecked');
        const searchResults = this.get('searchResults') || [];
        const isAddressHovered = this.get('isAddressHovered');

        if ((recentChecked || configuredChecked || favoriteChecked) && searchResults.length && !isAddressHovered) {
          let selectedAddressIndex = this.get('selectedAddressIndex');

          if (event.keyCode === UP_ARROW_KEYCODE) {
            //if the first address is selected we dont have any address before that
            if (selectedAddressIndex > 0) {
              selectedAddressIndex--;
            }

            this.addClassForSelectedAddress(selectedAddressIndex);
          } else if (event.keyCode === DOWN_ARROW_KEYCODE) {
            //if the last address is selected we dont have any address after that
            if (selectedAddressIndex < searchResults.length - 1) {
              selectedAddressIndex++;
            }

            this.addClassForSelectedAddress(selectedAddressIndex);
          } else if (event.keyCode === ENTER_KEYCODE) {
            this.onSelectAddress(searchResults, selectedAddressIndex);
          }

          this.set('selectedAddressIndex', selectedAddressIndex);
        }
      },

      //if the address is selected using mouse hover we have to remove the keyboard selected class
      onHoverAddress() {
        const searchResults = this.get('searchResults');
        searchResults.forEach((result, index) => {
          const className = `.selectedAddress${index}`;
          this.$(className).removeClass('selectedAddress');
        });
        this.set('selectedAddressIndex', -1);
        this.set('isAddressHovered', true);
      },

      onUnHoverAddress() {
        this.set('isAddressHovered', false);
      }

    }
  });

  _exports.default = _default;
});