define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-driver/component", ["exports", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-driver/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reconcileLambda: Ember.inject.service(),
    store: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    driverTable: null,
    selectedDriver: null,
    selectedCdl: null,
    searchButtonEnabled: Ember.computed.or('selectedDriver', 'selectedCdl'),
    clearButtonEnabled: Ember.computed.or('selectedDriver', 'selectedCdl'),
    config: _config.default,

    init() {
      this._super(...arguments);

      this.set('fetchRoutesByDriver', this.get('fetchRoutesByDriver').bind(this));
      this.set('config.title', '');
    },

    driverOptions: Ember.computed('reconcile.reconcileRVDResponse', function () {
      const reconcileDrivers = this.get('reconcile.reconcileRVDResponse') || [];
      const driverOptions = reconcileDrivers.map(driver => {
        return {
          id: driver.driver.id,
          badgenumber: driver.driver.badgenumber
        };
      });
      return driverOptions;
    }),

    async fetchRoutesByDriver() {
      const driverId = this.get('selectedDriver.id');
      const searchMode = this.get('selectedSearchMode');

      if (!driverId || !searchMode) {
        return [];
      }

      const routes = await this.reconcileLambda.getReconcileRoutesAndTripsInfoByDriverId.perform(searchMode.toLowerCase(), driverId);
      const driverZones = this.store.peekAll('cs-config-item').filter(zone => {
        return zone.category === 'config-System_Configuration-zones';
      });

      if (!routes.length) {
        this.set('config.title', '');
        this.set('config.noRecordsFound', true);
      } else {
        this.set('config.title', `${Ember.String.capitalize(searchMode)}`);
        this.set('config.noRecordsFound', false);
      }

      const updatedResponse = routes.map(res => {
        const zone = driverZones.find(z => {
          return z.name === res.endZoneId;
        });
        return { ...res,
          tripDate: this.get('reconcile.reconcileDate'),
          zoneName: zone.displayName
        };
      });
      return updatedResponse;
    },

    getDriverOptions(rvdResponse, selectedOption) {
      return rvdResponse.filter(rvDriver => rvDriver.driver.badgenumber !== selectedOption.badgenumber).map(driver => {
        return {
          id: driver.driver.id,
          badgenumber: driver.driver.badgenumber
        };
      });
    },

    actions: {
      onSearchRoutesByDriver() {
        if (this.selectedDriver && this.selectedCdl) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'Search',
            tip: 'Both Driver and CDL Number can not be supplied. Choose one or the other.',
            primaryActionText: 'OK',
            primaryAction: () => {
              tooltip.reset();
            },
            hasOverlay: true
          });
        }

        this.get('driverTable').refreshData();
      },

      onOptionsSelect(item, option) {
        Ember.set(this, item, option);
        this.notifyPropertyChange(item);
        const rvdResponse = this.get('reconcile.reconcileRVDResponse');
        const driverOptions = this.getDriverOptions(rvdResponse, option);
        this.set('driverOptions', driverOptions);
      },

      clearSearchDriver() {
        this.set('selectedDriver', '');
        this.get('driverTable').clearData();
        this.set('config.title', '');
        this.set('config.noRecordsFound', false);
        this.set('routeId', null);
        const rvdResponse = this.get('reconcile.reconcileRVDResponse');
        const driverOptions = this.getDriverOptions(rvdResponse, this.get('selectedDriver'));
        this.set('driverOptions', driverOptions);
      }

    }
  });

  _exports.default = _default;
});