define("adept-iq/pods/components/iq-widgets/new-booking-form/passenger-list/component", ["exports", "adept-iq/config/placeholders", "adept-iq/config/mapped-permIds", "adept-iq/models/rider-phone-number"], function (_exports, _placeholders, _mappedPermIds, _riderPhoneNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    booking: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    PHONE_NUMBER_PLACEHOLDER: _placeholders.PHONE_NUMBER_PLACEHOLDER,
    disablePhoneNumber: false,
    passengerNotesBookingGranted: false,
    selectedRiders: Ember.computed('booking.selectedRiders.[]', 'booking.selectedRiders.@each.{fareCategory}', function () {
      const selectedRiders = this.get('booking.selectedRiders');
      return selectedRiders;
    }),

    init() {
      this._super(...arguments);

      this.set('passengerNotesBookingGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editPassengerNotesBooking));
    },

    actions: {
      onNumberChange: function (rider, maskObj) {
        rider.set('formattedPhoneNumber', maskObj.unmasked);
        const areaCode = maskObj.unmasked.substring(0, 3).trim();
        const phoneNumber = maskObj.unmasked.substring(3).trim();
        const notificationPhoneNumbers = rider.get('notificationPhoneNumbers'); // the rider does have a main phone number from nyct

        if (notificationPhoneNumbers.length == 0) {
          const phoneNumberObj = {
            type: _riderPhoneNumber.MAIN_PHONE_TYPE,
            areaCode: areaCode,
            phoneNumber: phoneNumber,
            extension: '',
            description: 'MAIN CONTACT #',
            useForSmsNotifications: false,
            useForVoiceNotifications: true
          };
          notificationPhoneNumbers.pushObject(phoneNumberObj);
        } else {
          const updatedNotificationPhoneNumbers = notificationPhoneNumbers.map(phoneNumberObj => {
            if (phoneNumberObj.type.toLowerCase() == _riderPhoneNumber.MAIN_PHONE_TYPE) {
              return { ...phoneNumberObj,
                areaCode,
                phoneNumber
              };
            }

            return phoneNumberObj;
          });
          rider.set('notificationPhoneNumbers', updatedNotificationPhoneNumbers);
          this.set('booking.isRiderPhoneNumberEdited', true);
        }
      }
    }
  });

  _exports.default = _default;
});