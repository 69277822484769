define("adept-iq/pods/components/iq-widgets/fleet-manager-widget/vehicle-details/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS = void 0;
  const VALIDATIONS = {
    name: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Vehicle ID',
      message: '{description} is required'
    })],
    startGarageDisplayName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Start Location',
      message: '{description} is required'
    })],
    endGarageDisplayName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'End Location',
      message: '{description} is required'
    })],
    vehicleType: [(0, _validators.validatePresence)({
      presence: true,
      description: 'VehicleType',
      message: '{description} is required'
    })],
    startGarageLat: [(0, _validators.validatePresence)({
      presence: true,
      description: 'startGarageLat',
      message: '{description} is required'
    })],
    startGarageLng: [(0, _validators.validatePresence)({
      presence: true,
      description: 'startGarageLng',
      message: '{description} is required'
    })],
    endGarageLat: [(0, _validators.validatePresence)({
      presence: true,
      description: 'EndGarageLat',
      message: '{description} is required'
    })],
    endGarageLng: [(0, _validators.validatePresence)({
      presence: true,
      description: 'EndGarageLng',
      message: '{description} is required'
    })]
  };
  _exports.VALIDATIONS = VALIDATIONS;
});