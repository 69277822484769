define("adept-iq/config/config-object-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // fieldName: the name of the object field name
  // displayName: The display name we want to show on the form
  // type: The type of field. eg. string, boolean, int
  // radioButton: This group of boolean can only have 1 selected.
  var _default = {
    'config-Driving_Configuration-No_Show_reason_code': {
      categoryPath: 'config-Driving_Configuration-No_Show_reason_code',
      fields: [{
        fieldName: 'noShowReason',
        displayName: 'No Show Reason',
        type: 'string'
      }]
    },
    'config-Driving_Configuration-voip_call_phone_numbers': {
      categoryPath: 'config-Driving_Configuration-voip_call_phone_numbers',
      fields: [{
        fieldName: 'buttonLabel',
        displayName: 'Button Label',
        type: 'string'
      }, {
        fieldName: 'phoneNumber',
        displayName: 'Phone Number',
        type: 'string'
      }]
    },
    'config-Canned_Messages-to_dispatch': {
      categoryPath: 'config-Canned_Messages-to_dispatch',
      fields: [{
        fieldName: 'message',
        displayName: 'Message',
        type: 'string'
      }]
    },
    'config-Canned_Messages-to_driver': {
      categoryPath: 'config-Canned_Messages-to_driver',
      fields: [{
        fieldName: 'message',
        displayName: 'Message',
        type: 'string'
      }]
    },
    'config-Scheduling-schedule_configurations': {
      categoryPath: 'config-Scheduling-schedule_configurations',
      fields: [{
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'isReadOnly',
        displayName: 'Read Only?',
        type: 'boolean'
      }]
    },
    'config-Scheduling-service_windows': {
      categoryPath: 'config-Scheduling-service_windows',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'dropWindowEnd',
        displayName: 'Drop Window End',
        type: 'int'
      }, {
        fieldName: 'dropWindowStart',
        displayName: 'Drop Window Start',
        type: 'int'
      }, {
        fieldName: 'promiseWindowEnd',
        displayName: 'Promised Window End',
        type: 'int'
      }, {
        fieldName: 'promiseWindowStart',
        displayName: 'Promised Window Start',
        type: 'int'
      }, {
        fieldName: 'scheduleConfigurationName',
        displayName: 'Schedule Configuration Name',
        type: 'string'
      }, {
        fieldName: 'searchWindowEnd',
        displayName: 'Search Window End',
        type: 'int'
      }, {
        fieldName: 'searchWindowStart',
        displayName: 'Search Window Start',
        type: 'int'
      }, {
        fieldName: 'serviceType',
        displayName: 'Service Type',
        type: 'int'
      }]
    },
    'config-System_Configuration-break_types': {
      categoryPath: 'config-System_Configuration-break_types',
      fields: [{
        fieldName: 'defaultDuration',
        displayName: 'Default Duration',
        type: 'int'
      }, {
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'isPaid',
        displayName: 'Paid?',
        type: 'boolean'
      }, {
        fieldName: 'isRecognized',
        displayName: 'Recognized?',
        type: 'boolean'
      }, {
        fieldName: 'scheduleConfigurationName',
        displayName: 'Schedule Configuration Name',
        type: 'string'
      }, {
        fieldName: 'serviceWindowName',
        displayName: 'Service Window Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-cancel_types': {
      categoryPath: 'config-System_Configuration-cancel_types',
      fields: [{
        fieldName: 'code',
        displayName: 'Code',
        type: 'string'
      }, {
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'externalCode',
        displayName: 'External Code',
        type: 'string'
      }, {
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-eligibility_types': {
      categoryPath: 'config-System_Configuration-eligibility_types',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }]
    },
    'config-System_Configuration-eligibility_categories': {
      categoryPath: 'config-System_Configuration-eligibility_categories',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'type',
        displayName: 'Type',
        type: 'string'
      }, {
        fieldName: 'denyService',
        displayName: 'Deny Service ',
        type: 'boolean'
      }, {
        fieldName: 'conditional',
        displayName: 'Conditional',
        type: 'boolean'
      }, {
        fieldName: 'checkADABoundary',
        displayName: 'Check ADA Boundary',
        type: 'boolean'
      }]
    },
    'config-System_Configuration-eligibility_conditions': {
      categoryPath: 'config-System_Configuration-eligibility_conditions',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'configuredValue ',
        displayName: 'Configured value',
        type: 'string'
      }]
    },
    'config-System_Configuration-fare_categories': {
      categoryPath: 'config-System_Configuration-fare_categories',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'amount',
        displayName: 'Amount',
        type: 'string'
      }]
    },
    'config-System_Configuration-fare_types': {
      categoryPath: 'config-System_Configuration-fare_types',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-no_show_reason_codes': {
      categoryPath: 'config-System_Configuration-no_show_reason_codes',
      fields: [{
        fieldName: 'code',
        displayName: 'Code',
        type: 'string'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'externalCode',
        displayName: 'External Code',
        type: 'string'
      }, {
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }, {
        fieldName: 'driverAppDefault',
        displayName: 'Make default on driver app',
        type: 'boolean',
        radioButton: true
      }]
    },
    'config-System_Configuration-no_show_reason_categories': {
      categoryPath: 'config-System_Configuration-no_show_reason_categories',
      isCustomizedPanel: true,
      customizedPanel: 'no-show-reason-categories',
      fields: [{
        fieldName: 'code',
        displayName: 'Code',
        type: 'string'
      }, {
        fieldName: 'driverNeedsApproval',
        displayName: 'Driver Needs Approval',
        type: 'boolean'
      }, {
        fieldName: 'minutesFromPromiseTimeEnd',
        displayName: 'Minutes from Promise Time: End',
        type: 'int'
      }, {
        fieldName: 'minutesFromPromiseTimeStart',
        displayName: 'Minutes from Promise Time: Start',
        type: 'int'
      }, {
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-provider_types': {
      categoryPath: 'config-System_Configuration-provider_types',
      fields: [{
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-driver_positions': {
      categoryPath: 'config-System_Configuration-driver_positions',
      fields: [{
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }, {
        fieldName: 'displayName',
        displayName: 'Description',
        type: 'string'
      }]
    },
    'config-System_Configuration-provider_zones': {
      categoryPath: 'config-System_Configuration-provider_zones',
      fields: [{
        fieldName: 'providerName',
        displayName: 'Provider Name',
        type: 'string'
      }, {
        fieldName: 'zoneName',
        displayName: 'Zone Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-providers': {
      categoryPath: 'config-System_Configuration-providers',
      fields: [{
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }, {
        fieldName: 'primaryProviderName',
        displayName: 'Primary Provider Name',
        type: 'string'
      }, {
        fieldName: 'primaryTypeName',
        displayName: 'Primary Type Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-travel_need_types': {
      categoryPath: 'config-System_Configuration-travel_need_types',
      fields: [{
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'displayOrder',
        displayName: 'Display Order',
        type: 'int'
      }, {
        fieldName: 'id',
        displayName: 'ID',
        type: 'int'
      }, {
        fieldName: 'loadTime',
        displayName: 'Load Time',
        type: 'int'
      }, {
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }, {
        fieldName: 'unloadTime',
        displayName: 'Unload Time',
        type: 'int'
      }, {
        fieldName: 'vehicleCapacityCount',
        displayName: 'Vehicle Capacity Count',
        type: 'int'
      }, {
        fieldName: 'vehicleCapacityType',
        displayName: 'Vehicle Capacity Type',
        type: 'string'
      }, {
        fieldName: 'fareCalculationType',
        displayName: 'Fare Calculation Type',
        type: 'string'
      }]
    },
    'config-System_Configuration-service_needs_types': {
      categoryPath: 'config-System_Configuration-service_needs_types',
      fields: [{
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-travel_modes': {
      categoryPath: 'config-System_Configuration-travel_modes',
      fields: [{
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'id',
        displayName: 'ID',
        type: 'int'
      }, {
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-vehicle_capacity_configs': {
      categoryPath: 'config-System_Configuration-vehicle_capacity_configs',
      fields: [{
        fieldName: 'count',
        displayName: 'Count',
        type: 'int'
      }, {
        fieldName: 'group',
        displayName: 'Group',
        type: 'int'
      }, {
        fieldName: 'vehicleCapacityType',
        displayName: 'Vehicle Capacity Type',
        type: 'string'
      }, {
        fieldName: 'vehicleType',
        displayName: 'Vehicle Type',
        type: 'string'
      }]
    },
    'config-System_Configuration-vehicle_capacity_types': {
      categoryPath: 'config-System_Configuration-vehicle_capacity_types',
      fields: [{
        fieldName: 'constrainedByLIFO',
        displayName: 'Constraint By LIFO',
        type: 'boolean'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'dwellFactor',
        displayName: 'Dwell Factor',
        type: 'int'
      }, {
        fieldName: 'engineId',
        displayName: 'Engine ID',
        type: 'string'
      }, {
        fieldName: 'engineName',
        displayName: 'Engine Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-vehicle_types': {
      categoryPath: 'config-System_Configuration-vehicle_types',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-zones': {
      categoryPath: 'config-System_Configuration-zones',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-breakdown_types': {
      categoryPath: 'config-System_Configuration-breakdown_types',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-borough': {
      categoryPath: 'config-System_Configuration-borough',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-holidays': {
      categoryPath: 'config-System_Configuration-holidays',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Name',
        type: 'string'
      }, {
        fieldName: 'date',
        displayName: 'Date',
        type: 'string'
      }, {
        fieldName: 'notificationDate',
        displayName: 'Notification Date',
        type: 'string'
      }]
    },
    'config-System_Configuration-ITP_Blockoutdate': {
      categoryPath: 'config-System_Configuration-ITP_Blockoutdate',
      fields: [{
        fieldName: 'name',
        displayName: 'Name',
        type: 'string'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'fromDate',
        displayName: 'From date(MM/DD/YYYY)',
        type: 'string'
      }, {
        fieldName: 'fromTime',
        displayName: 'From Time(HH:MM a)',
        type: 'string'
      }, {
        fieldName: 'toDate',
        displayName: 'To date(MM/DD/YYYY)',
        type: 'string'
      }, {
        fieldName: 'toTime',
        displayName: 'To Time(HH:MM a)',
        type: 'string'
      }, {
        fieldName: 'recursDaily',
        displayName: 'Recurs Daily(Yes/No)',
        type: 'boolean'
      }]
    },
    'config-System_Configuration-Activity_Log_Types-route': {
      categoryPath: 'config-System_Configuration-Activity_Log_Types-route',
      fields: [{
        fieldName: 'Name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-Activity_Log_Types-trip': {
      categoryPath: 'config-System_Configuration-Activity_Log_Types-trip',
      fields: [{
        fieldName: 'Name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-Activity_Log_Types-vehicle': {
      categoryPath: 'config-System_Configuration-Activity_Log_Types-vehicle',
      fields: [{
        fieldName: 'Name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-Activity_Log_Types-user': {
      categoryPath: 'config-System_Configuration-Activity_Log_Types-user',
      fields: [{
        fieldName: 'Name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-driver_physical_types': {
      categoryPath: 'config-System_Configuration-driver_physical_types',
      fields: [{
        fieldName: 'name',
        Name: 'Name',
        type: 'string'
      }, {
        fieldName: 'displayName',
        Name: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-Zip_Codes': {
      categoryPath: 'config-System_Configuration-Zip_Codes',
      fields: [{
        fieldName: 'allowBrokerTrips',
        displayName: 'Allow Broker Trips',
        type: 'boolean'
      }]
    },
    'config-System_Configuration-Activity_Log_Types-booking': {
      categoryPath: 'config-System_Configuration-Activity_Log_Types-booking',
      fields: [{
        fieldName: 'Name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-Activity_Log_Types-subscription': {
      categoryPath: 'config-System_Configuration-Activity_Log_Types-subscription',
      fields: [{
        fieldName: 'Name',
        displayName: 'Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-driver_statuses': {
      categoryPath: 'config-System_Configuration-driver_statuses',
      fields: [{
        fieldName: 'name',
        Name: 'Name',
        type: 'string'
      }, {
        fieldName: 'displayName',
        displayName: 'Description',
        Name: 'Description',
        type: 'string'
      }]
    },
    'config-System_Configuration-rider_relationship_types': {
      categoryPath: 'config-System_Configuration-rider_relationship_types',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-rider_phone_types': {
      categoryPath: 'config-System_Configuration-rider_phone_types',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-certification_denied_reasons': {
      categoryPath: 'config-System_Configuration-certification_denied_reasons',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }]
    },
    'config-System_Configuration-suspension_reasons': {
      categoryPath: 'config-System_Configuration-suspension_reasons',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'description',
        displayName: 'Description',
        type: 'string'
      }, {
        fieldName: 'length(days)',
        displayName: 'Length(days)',
        type: 'int'
      }]
    },
    'config-System_Configuration-funding_agencies': {
      categoryPath: 'config-System_Configuration-funding_agencies',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }, {
        fieldName: 'address',
        displayName: 'Address',
        type: 'string'
      }, {
        fieldName: 'phone',
        displayName: 'Phone',
        type: 'string'
      }, {
        fieldName: 'email',
        displayName: 'Email',
        type: 'string'
      }]
    },
    'config-System_Configuration-contact_reason_types': {
      categoryPath: 'config-System_Configuration-contact_reason_types',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    },
    'config-System_Configuration-Trip_Purposes': {
      categoryPath: 'config-System_Configuration-Trip_Purposes',
      fields: [{
        fieldName: 'displayName',
        displayName: 'Display Name',
        type: 'string'
      }]
    }
  };
  _exports.default = _default;
});