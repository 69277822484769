define("adept-iq/pods/components/iq-widgets/trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/trips-widget/scheduleConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/config/mapped-permIds", "adept-iq/mixins/async-schedule-operation", "adept-iq/mixins/async-booking-operation", "adept-iq/mixins/version-check", "adept-iq/utils/rql-generators"], function (_exports, _component, _scheduleConfig, _environment, _findRowRecord, _mappedPermIds, _asyncScheduleOperation, _asyncBookingOperation, _versionCheck, _rqlGenerators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend(_asyncScheduleOperation.default, _asyncBookingOperation.default, _versionCheck.default, {
    dragCoordinator: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['trips-widget', 'data-test-trips-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    config: _scheduleConfig.default,
    defaultIncludes: INCLUDES,

    init() {
      this.set('config', _scheduleConfig.default);

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        id: 'print',
        permIds: [_mappedPermIds.default.downloadTrips],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLog,
        name: 'View Activity Log',
        action: trip => {
          const id = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          this.get('workspace').pushState('tripActivityLog', {
            displayName: `Trip ${id} Activity Log`,
            trip
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto widget's other elements.

      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).addClass('drag-target');
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
    },

    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('rowGroup'));
      this.assignCluster(targetRowRecord, sourceRowRecord);
    },

    assignCluster(tripRow, routeRow) {
      const tripRecord = tripRow.get('record');
      const routeRecord = routeRow.get('record');

      if (routeRecord.constructor.modelName !== 'route') {
        return;
      }

      let cluster = routeRecord.get('clusters').firstObject;

      if (Ember.isBlank(cluster)) {
        cluster = this.get('store').createRecord('cluster', {
          route: routeRecord
        });
        tripRecord.set('cluster', cluster);
        cluster.save();
      } else {
        tripRecord.set('cluster', cluster);
        tripRecord.save();
      }
    },

    _dynamicScheduleActions() {
      const activityLog = this.get('activityLog');
      const row = this.getSelectedCoreModel();
      if (!row) return []; //const selectedWidgetRecord = this.get('selectedWidgetRecord');

      const genericActions = [activityLog];
      return genericActions;
    },

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const dateRangeFilterQuery = (0, _rqlGenerators.dateBetweenRQLUsingFormat)('promiseTime', [startTime, endTime], 'YYYY-MM-DDTHH:mm:ss.SSS');
      return dateRangeFilterQuery;
    },

    // eslint-disable-next-line no-unused-vars
    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      const rowStyleHandler = '';
      return rowStyleHandler;
    },

    canFurtherSelectionAllowed() {
      const gridConfig = this.get('config');
      const maxLimitByRouteSelection = gridConfig.maxLimitByRouteSelection;
      const maxLimitWithoutRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
      const selectedTripRecords = this.get('widgetService').getSelectedWidgetRecordsByModel('tripWidget');
      const selectedRouteSet = new Set();
      selectedTripRecords.forEach(data => {
        if (data.routeId) {
          selectedRouteSet.add(data.routeId);
        }
      });

      if (selectedRouteSet.size >= maxLimitByRouteSelection || selectedTripRecords.length >= maxLimitWithoutRouteAssociation) {
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});