define("adept-iq/classes/data-joins/map/polyline", ["exports", "adept-iq/classes/data-joins/map/base", "lodash", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "adept-iq/pods/components/iq-widgets/map-widget/config/patterns"], function (_exports, _base, _lodash, _polyline, _patterns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;

  var _default = _base.default.extend({
    onContextMenu: null,
    onDblClick: null,
    queueName: 'map-polyline',

    refresh(node) {
      this.reorderPolyline(node);
      return [this.refreshPolyline(node), this.refreshDecorator(node)].any(Boolean);
    },

    reorderPolyline(node) {
      //On each merging state, the new updated polyline would place on top of the layers and makes the polylines to get out of orders.
      //As the polylines will refresh one by one in the order that has been defined in the layer's config, here by bringing each polyline to the front,
      // we make sure that at the end of refresh cycle, the polylines order stays the same as what it was defined.
      if (node.polyline && node.polyline.native && _lodash.default.isFunction(node.polyline.native.bringToFront)) {
        node.polyline.native.bringToFront();
      }
    },

    refreshPolyline(node) {
      const isMaximized = this.get('options.isMaximized');
      const isJoined = node.get('isJoined');
      const isHidden = node.get('isHidden');
      const points = node.get('points');
      const polyline = isMaximized ? node.get('polylineMaximized') : node.get('polyline');

      if (!isJoined || isHidden || Ember.isEmpty(points)) {
        if (polyline) {
          return this.removePolyline(node, isMaximized);
        }

        return false;
      }

      if (!polyline) {
        return this.createPolyline(node, isMaximized);
      }

      return this.updatePolyline(node, isMaximized);
    },

    refreshDecorator(node) {
      const isMaximized = this.get('options.isMaximized');
      const isJoined = node.get('isJoined');
      const isHidden = node.get('isHidden');
      const pattern = node.get('pattern');
      const polyline = node.get('polyline');
      const decorator = isMaximized ? node.get('decoratorMaximized') : node.get('decorator');

      if (!isJoined || isHidden || !polyline || !pattern) {
        if (decorator) {
          return this.removeDecorator(node, isMaximized);
        }

        return false;
      }

      if (!decorator) {
        return this.createDecorator(node, isMaximized);
      }

      return this.updateDecorator(node, isMaximized);
    },

    createPolylineTripStatus(node) {
      let polylineAttribute = '';
      let filteredPolyline = '';
      let styleOtp = node.style;
      const otpStatus = node.otpStatus;
      styleOtp = styleOtp.replace(otpStatus, '');

      switch (styleOtp) {
        case 'tripWaitlisted':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = [1.5, 9];
          break;

        case 'tripCallback':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = [3, 4];
          break;

        case 'tripDispatched':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = [3.2, 3];
          break;

        case 'tripEnRoute':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = [2, 3];
          break;

        case 'tripActive':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = [1, 4.5];
          break;

        case 'tripIllegal':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = 3;
          break;

        case 'tripNoshowed':
        case 'tripNoshowedLate':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = [1, 4, 8, 4];
          break;

        case 'tripNoshowrequested':
        case 'tripNoshowrequestedLate':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = [1, 4, 8, 4];
          break;

        case 'tripCanceled':
        case 'tripCanceledLate':
        case 'tripLatecanceled':
        case 'tripLatecanceledLate':
          polylineAttribute = node.polyline.native._path.attributes;
          filteredPolyline = Object.values(polylineAttribute).filter(ele => ele.name === 'stroke-dasharray');
          filteredPolyline.firstObject.nodeValue = [1, 4, 6, 4];
          break;

        default:
          break;
      }
    },

    createPolyline(node, isMaximized) {
      const options = this._computePolylineOptions(node);

      const polyline = new bemap.Polyline(node.get('bemapCoords'), {
        style: options
      });
      const map = this.get('map');

      if (isMaximized) {
        node.set('polylineMaximized', polyline);
      } else {
        node.set('polyline', polyline);
      } // polyline.addTo(this.get('map'));


      map.addPolyline(polyline); // bind tooltip & pop-up

      const label = node.get('label');

      if (label) {
        polyline.native.bindPopup(label, {
          closeButton: false
        });
        polyline.native.bindTooltip(label);
      } // used for click handler callbacks


      const record = node.get('record');
      polyline.iq = {
        isActive: node.get('isActive'),
        modelId: record ? record.get('id') : null,
        modelName: record ? record.constructor.modelName : null
      }; // register click handlers

      polyline.native.on('contextmenu', e => {
        node.trigger('contextmenu', e);

        if (this.onContextMenu) {
          Ember.run(() => this.onContextMenu(e, node));
        }
      });
      polyline.native.on('dblclick', e => {
        node.trigger('dblclick', e);

        if (this.onDblClick) {
          window.L.DomEvent.stop(e);
          Ember.run(() => this.onDblClick(e, node));
        }
      }); // initialize cache

      node.cache = node.cache || {};
      node.cache.polyline = {
        label,
        optionsDigest: JSON.stringify(options),
        pointsDigest: node.get('isDynamic') ? node.get('pointsDigest') : null
      };
      return true;
    },

    updatePolyline(node, isMaximized) {
      const polyline = isMaximized ? node.get('polylineMaximized') : node.get('polyline');
      const cache = node.cache.polyline;
      let isDirty = false; // update style options

      const options = this._computePolylineOptions(node);

      const optionsDigest = JSON.stringify(options);

      if (optionsDigest !== cache.optionsDigest) {
        if (polyline.style && typeof polyline.style.setColor === 'function') {
          const currentColor = polyline.style.getColor ? polyline.style.getColor() : null;

          if (currentColor !== options.color) {
            polyline.style.setColor(options.color);
          }
        }

        cache.optionsDigest = optionsDigest;
        isDirty = true;
      } // update polyline points; only if dynamic!


      if (node.get('isDynamic')) {
        const pointsDigest = node.get('pointsDigest');

        if (pointsDigest !== cache.pointsDigest) {
          polyline.native.setLatLngs(node.get('bemapCoords'));
          cache.pointsDigest = pointsDigest;
          isDirty = true;
        }
      }

      if (polyline.native._popup && !polyline.native._popup._container) {
        if (polyline.map.native._popup) {
          const _popup = polyline.map.native._popup;
          polyline.native._popup = _popup;
        }
      } // update popup & tooltip


      const label = node.get('label'); //for removing the blank popup when label has empty string

      if (polyline.native._popup && !label) {
        const container = polyline.native._popup._container;

        if (container.innerHTML) {
          container.innerHTML = '';
        }
      }

      if (label !== cache.label) {
        if (!label) {
          polyline.native.unbindPopup();
          polyline.native.unbindTooltip();
        } else if (!cache.label) {
          polyline.native.bindPopup(label, {
            closeButton: false
          });
          polyline.native.bindTooltip(label);
        } else {
          polyline.native.setPopupContent(label);
          polyline.native.setTooltipContent(label);
        }

        cache.label = label;
        isDirty = true;
      }

      return isDirty;
    },

    removePolyline(node, isMaximized) {
      const polyline = isMaximized ? node.get('polylineMaximized') : node.get('polyline');
      polyline.iq = null;
      polyline.native.off('contextmenu');
      polyline.native.off('dblclick');
      polyline.remove();

      if (isMaximized) {
        node.set('polylineMaximized', null);
      } else {
        node.set('polyline', null);
      }

      if (!node.cache && !node.cache.polyline) {
        node.cache.polyline = null;
      }

      if (!node.cache.decorator) {
        node.cache = null;
      }

      return true;
    },

    createDecorator(node, isMaximized) {
      const polyline = isMaximized ? node.get('polylineMaximized') : node.get('polyline');

      const options = this._computeDecoratorOptions(node);

      const decorator = window.L.polylineDecorator(polyline.native, options);
      const map = this.get('map');

      if (isMaximized) {
        node.set('decoratorMaximized', decorator);
      } else {
        node.set('decorator', decorator);
      }

      decorator.addTo(map.native);
      node.cache = node.cache || {};
      node.cache.decorator = {
        patternsDigest: JSON.stringify(options.patterns),
        pointsDigest: node.get('isDynamic') ? node.get('pointsDigest') : null
      };
      return true;
    },

    updateDecorator(node, isMaximized) {
      const decorator = isMaximized ? node.get('decoratorMaximized') : node.get('decorator');
      const cache = node.cache.decorator;
      let isDirty = false; // update patterns; this includes opacity

      const {
        patterns
      } = this._computeDecoratorOptions(node);

      const patternsDigest = JSON.stringify(patterns);

      if (patternsDigest !== cache.patternsDigest) {
        decorator.setPatterns(patterns);
        cache.patternsDigest = patternsDigest;
        isDirty = true;
      } // update points, but only if dynamic


      if (node.get('isDynamic')) {
        const pointsDigest = node.get('pointsDigest');

        if (pointsDigest !== cache.pointsDigest) {
          const points = node.get('points');
          decorator.setPaths(points);
          cache.pointsDigest = pointsDigest;
          isDirty = true;
        }
      }

      return isDirty;
    },

    removeDecorator(node, isMaximized) {
      const decorator = isMaximized ? node.get('decoratorMaximized') : node.get('decorator');
      decorator.remove();

      if (isMaximized) {
        node.set('decoratorMaximized', null);
      } else {
        node.set('decorator', null);
      }

      if (!node.cache && !node.cache.decorato) {
        node.cache.decorator = null;
      }

      if (!node.cache.polyline) {
        node.cache = null;
      }

      return true;
    },

    _computeDecoratorOptions(node) {
      const patternName = node.get('pattern');

      if (!_patterns.default.hasOwnProperty(patternName)) {
        throw new Error(`no pattern preset matching '${patternName}'`);
      }

      const presetPatterns = _patterns.default[patternName];
      const opacity = node.get('opacity');
      const patterns = presetPatterns.map(presetPattern => {
        const pattern = _lodash.default.cloneDeep(presetPattern);

        const pathOptions = Ember.get(pattern, 'symbol.options.pathOptions');
        if (!pathOptions) return pattern; // some pattern presets have both opacities, some only one; this is why
        // we need to bake opacity into the patterns array

        if (pathOptions.hasOwnProperty('opacity')) {
          pathOptions.opacity = opacity;
        }

        if (pathOptions.hasOwnProperty('fillOpacity')) {
          pathOptions.fillOpacity = opacity;
        }

        return pattern;
      });
      return {
        patterns
      };
    },

    _computePolylineOptions(node) {
      const styleObjects = [];
      const opacity = node.get('opacity');

      if (!Ember.isNone(opacity)) {
        styleObjects.push({
          opacity
        });
      }

      const nodeOptions = node.get('options');

      if (nodeOptions) {
        styleObjects.push(nodeOptions);
      }

      const style = node.get('style');
      if (node.polyline) this.createPolylineTripStatus(node);

      if (style) {
        const presetOptions = _polyline.default[style];

        if (!presetOptions) {
          throw new Error(`no style preset matching '${style}'`);
        }

        styleObjects.push(presetOptions);
      }

      return _lodash.default.defaultsDeep({}, ...styleObjects);
    }

  });

  _exports.default = _default;
});