define("adept-iq/classes/map-contexts/map-configs/reconcile-layers", ["exports", "adept-iq/config/icon-paths", "adept-iq/pods/components/iq-widgets/history-routes-widget/config"], function (_exports, _iconPaths, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: make this JSON-only
  // @TODO: it would be better to look up values from static config at time of use,
  // but for now this handles the differences in config format
  const extractLabelsFromWidgetConfig = widgetConfig => {
    return widgetConfig.columns.map(columnConfig => {
      const {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        searchable,
        isMapLabelVisible,
        unAvailable
      } = columnConfig;
      return {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        isSearchable: searchable,
        isVisible: isMapLabelVisible,
        unAvailable: unAvailable
      };
    });
  };

  const layers = [{
    id: 'stops',
    type: 'markers',
    label: 'Stops',
    modelName: _config.default.mapConfig.modelName + '-stops',
    isVisible: true,
    isActive: true,
    isHighLighted: true,
    isDisplayMapIconLabel: false,
    opacity: 1,
    selectedDisplay: 'On Click',
    types: [{
      id: 'pullinGarage',
      label: 'Pullin Garage',
      filterKey: 'mapId',
      filterValue: 'pullinGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned
    }, {
      id: 'pulloutGarage',
      label: 'Pullout Garage',
      filterKey: 'mapId',
      filterValue: 'pulloutGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned
    }, {
      id: 'break',
      label: 'Break',
      filterKey: 'mapId',
      filterValue: 'break',
      style: 'break',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.break
    }, {
      id: 'deployStop',
      label: 'Deploy Stop',
      filterKey: 'mapId',
      filterValue: 'deployStop',
      style: 'pick',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.planned
    }, {
      id: 'pick',
      label: 'Pickup',
      filterKey: 'mapId',
      filterValue: 'pick',
      style: 'pick',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned
    }, {
      id: 'drop',
      label: 'Drop Off',
      filterKey: 'mapId',
      filterValue: 'drop',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned
    }, {
      id: 'performedStop',
      label: 'Performed Stop',
      filterKey: 'mapId',
      filterValue: 'performedStop',
      style: 'Depart',
      canFilter: true,
      isVisible: true,
      display: true,
      iconPath: _iconPaths.default.stops.pickCompleted
    }, {
      id: 'next',
      label: 'Next Stop',
      filterKey: 'mapId',
      filterValue: 'next',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickNext
    }, {
      id: 'wheelchair',
      label: 'Wheelchair Stop',
      filterKey: 'mapId',
      filterValue: 'wheelchair',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlannedWheelchair
    }, {
      id: 'NextWheelchair',
      label: 'Wheelchair Next Stop',
      filterKey: 'mapId',
      filterValue: 'NextWheelchair',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickNextWheelchair
    }, {
      id: 'noshow',
      label: 'No Show Stop',
      filterKey: 'mapId',
      filterValue: 'noshow',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.noshow
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_config.default.mapConfig.stops)
  }, {
    id: 'trips',
    type: 'polylines',
    label: 'Trips',
    modelName: _config.default.mapConfig.modelName,
    isVisible: true,
    isActive: true,
    opacity: 1,
    selectedDisplay: 'On Left Click',
    types: [{
      id: 'Waitlisted',
      label: 'Waitlist',
      filterKey: 'statusLabel',
      filterValue: 'Waitlisted',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Scheduled',
      label: 'Scheduled',
      filterKey: 'statusLabel',
      filterValue: 'Scheduled',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Active',
      label: 'Active',
      filterKey: 'statusLabel',
      filterValue: 'Active',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }, {
      id: 'Completed',
      label: 'Completed',
      filterKey: 'statusLabel',
      filterValue: 'Completed',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }, {
      id: 'No Show',
      label: 'No Show',
      filterKey: 'statusLabel',
      filterValue: 'No Show',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_config.default.mapConfig.trips)
  }];
  var _default = layers;
  _exports.default = _default;
});