define("adept-iq/pods/components/user-profile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentComponent: 'profile-home',
    classNames: ['user-profile'],
    workspace: Ember.inject.service(),
    isTableInitialized: false,
    actions: {
      setCurrentComponent(component) {
        this.set('currentComponent', component);
      },

      onXButtonClick() {
        this.get('workspace').popState('openUserProfile');
      }

    }
  });

  _exports.default = _default;
});