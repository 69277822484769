define("adept-iq/pods/components/iq-widgets/previous-bookings-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/previous-bookings-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'booking',
    defaultSortId: 'bookingId',
    defaultSortAsc: false,
    checkBoxColumn: true,
    isFormControls: true,
    title: 'Booking',
    columns: [{
      id: 'bookingDateRequestTime',
      index: 0,
      type: 'text',
      isKey: true,
      label: 'Date / Request / Time',
      valuePath: 'requestTimeForDisplay',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 200,
      format: 'YYYY-MM-DD / / hh:mm A'
    }, {
      id: 'bookingId',
      index: 1,
      type: 'text',
      label: 'Booking ID',
      valuePath: 'id',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'bookingStatus',
      index: 2,
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 70,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'bookingPassengerId',
      index: 3,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'firstLeg.rider.riderId',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'bookingFirstName1',
      index: 4,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstLeg.rider.firstName',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'bookingLastName',
      index: 5,
      type: 'text',
      label: 'Last Name',
      valuePath: 'firstLeg.rider.lastName',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 100,
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'bookingPickAddress',
      index: 6,
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'firstSegmentInFistLeg.pick.place.address.simpleAddress',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 140,
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'bookingPickCity',
      index: 7,
      type: 'text',
      label: 'Pickup City',
      valuePath: 'bookingPickUpAddress',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'pickupZone',
      index: 8,
      type: 'number',
      label: 'Pickup Zone',
      valuePath: 'firstSegmentInFistLeg.pick.place.address.zoneName.displayName',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'bookingDropaddress',
      index: 9,
      type: 'text',
      label: 'Drop off Address',
      valuePath: 'lastSegmentInLastLeg.drop.place.address.simpleAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 140,
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'bookingDropCity',
      index: 10,
      type: 'text',
      label: 'Drop off City',
      valuePath: 'bookingDropOffAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120
    }, {
      id: 'dropoffZone',
      index: 11,
      type: 'number',
      label: 'Drop off Zone',
      valuePath: 'firstSegmentInFistLeg.drop.place.address.zoneName.displayName',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'isGroupBooking',
      index: 12,
      type: 'text',
      label: 'Group Booking ',
      valuePath: 'isGroupBookingDisplayText',
      editable: false,
      searchable: false,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'bookingGroupId',
      index: 13,
      type: 'text',
      label: 'Group ID',
      valuePath: 'group.id',
      editable: false,
      searchable: false,
      hidden: false,
      defaultWidth: 100
    }]
  };
  _exports.default = _default;
});