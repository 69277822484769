define("adept-iq/pods/components/side-drawer/reconcile-add-new-trip/component", ["exports", "moment", "adept-iq/pods/components/side-drawer/reconcile-add-new-trip/stubs", "ember-concurrency"], function (_exports, _moment, _stubs, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ADDRESS_TYPE = 'primary';
  const DEFAULT_TRAVEL_NEED = 'ambulatory';
  const PCA = 'pca';
  const SERVICE_ANIMAL = 'serviceanimal';

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    session: Ember.inject.service(),
    geocode: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    defaultFare: 2.75,
    stubs: _stubs.default,
    onCloseClick: null,
    coordinates: null,
    travelNeedsOptionsCount: 0,
    selectedNeedsOptions: null,
    selectedPickLocation: null,
    selectedDropLocation: null,
    passengerPickAddress: null,
    pickAnchorType: null,
    dropAnchorType: null,
    selectedTripDate: Ember.computed.alias('reconcile.reconcileDate'),
    isPcaChecked: false,
    isServiceAnimalChecked: false,
    // Reset map and address picker
    canResetAddress: Ember.computed.alias('workspace.activeContext.topActiveContext.resetLocation'),
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    isEditStart: Ember.computed.alias('isEditingStart'),
    passengerDetails: Ember.computed.readOnly('stackFrame.options.selectedRow'),
    reconcileRouteStartTime: Ember.computed.readOnly('reconcile.reconcileRouteDetails.actualStartTime'),
    reconcileRouteEndTime: Ember.computed.readOnly('reconcile.reconcileRouteDetails.actualEndTime'),
    passengerTravelNeeds: Ember.computed.readOnly('passengerDetails.travelNeeds'),
    reconcileRouteInfo: Ember.computed.readOnly('reconcile.reconcileRouteInfo'),
    lastRowClickedInTripTable: Ember.computed.readOnly('reconcile.lastRowClickedInTripTable'),
    canPlus: Ember.computed('travelNeedsOptions', 'travelNeedsOptionsCount', function () {
      return Ember.get(this, 'travelNeedsOptionsCount') < Ember.get(this, 'travelNeedsOptions').length;
    }),
    anchorOptions: Ember.computed('workspaceData', 'selectedAnchor', function () {
      const anchorOptionsData = _stubs.default.anchorData;
      return anchorOptionsData;
    }),
    cloneSelectedNeedsOptions: Ember.computed('selectedNeedsOptions', function () {
      const selectedNeedsOptions = this.get('selectedNeedsOptions');
      return selectedNeedsOptions.map(sno => {
        return Ember.Object.create({ ...sno
        });
      });
    }),
    travelNeedOptionsWithFilter: Ember.computed('cloneSelectedNeedsOptions.@each.name', 'travelNeedsOptions', function () {
      const travelNeedsOptions = this.get('travelNeedsOptions').map(e => {
        return e;
      });
      const filteredOptions = travelNeedsOptions.filter(sno => sno.name.toLowerCase() !== PCA && sno.name.toLowerCase() !== SERVICE_ANIMAL);
      const selectedValueInTravelNeeds = this.get('cloneSelectedNeedsOptions') || null;
      const travelNeedOptions = filteredOptions.filter(e => {
        return !selectedValueInTravelNeeds.find(stn => {
          return stn.name.toLowerCase() === e.name.toLowerCase();
        });
      });
      return travelNeedOptions.map(tno => {
        return { ...tno,
          name: tno.name.toUpperCase()
        };
      });
    }),
    companionOptions: Ember.computed('workspaceData', 'selectedNoOfCompanions', function () {
      const companions = _stubs.default.companions;
      return companions;
    }),
    selectedPickAddress: Ember.computed('passengerPickAddress', function () {
      const passengerPickAddress = this.get('passengerPickAddress');
      const selectedStreetAddress = passengerPickAddress.address.streetAddress;
      const selectedLocality = passengerPickAddress.address.locality;
      const selectedStreetNumber = passengerPickAddress.address.streetNumber;
      return `${selectedStreetNumber},${selectedStreetAddress},${selectedLocality}`;
    }),

    init() {
      this._super(...arguments);

      this.coordinates = {
        lat: null,
        lng: null
      };
      Ember.set(this, 'selectedDate', {
        today: (0, _moment.default)().subtract(1, 'day').format('MM-DD-YYYY'),
        date: null,
        endDateSelection: (0, _moment.default)(this.get('selectedTripDate')).add(2, 'day').startOf('day').toISOString()
      });
      const travelNeedsOptions = this.store.peekAll('cs-config-item').filter(config => {
        return config.category === 'config-System_Configuration-travel_need_types';
      });
      travelNeedsOptions.map(option => {
        return option.data.displayName;
      });
      this.set('travelNeedsOptions', travelNeedsOptions);
      const passengerAddressDetails = this.get('passengerDetails.address');
      const foundAddress = passengerAddressDetails.find(address => address.type.toLowerCase() === ADDRESS_TYPE);
      this.set('passengerPickAddress', foundAddress);
      this.setProperties({
        shiftTimeRange: {
          pickTime: null,
          dropTime: null,
          startofday: (0, _moment.default)(this.get('selectedDate.date')).startOf('day').toISOString(),
          endofday: (0, _moment.default)(this.get('selectedDate.date')).endOf('day').toISOString()
        },
        displayAmbulatory: true,
        isDisableDatePicker: true,
        isTimeDisable: true,
        isEnableServiceAnimal: false,
        isPcaChecked: false,
        isServiceAnimalChecked: false,
        isEditingStart: false,
        serviceNeeds: this.get('passengerDetails.serviceNeeds')
      });
      this.setDefaultTravelNeeds();
      this.setDefaultPickupLocation();
      this.isPcaAvail();
      this.isServiceAnimalAvail();
      this.setLoadAndUnLoadTime();
      this.defaultSelectedTravelNeeds();
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    defaultSelectedTravelNeeds() {
      const travelNeedDetails = this.get('passengerDetails.travelNeeds');

      if (travelNeedDetails) {
        const travelNeed = travelNeedDetails.map(e => {
          return {
            name: e.type,
            cnt: null
          };
        });
        this.set('selectedTravelNeedsForPayload', travelNeed);
      } else {
        this.set('selectedTravelNeedsForPayload', []);
      }
    },

    async setDefaultPickupLocation() {
      if (this.get('passengerDetails.address')) {
        this.set('selectedPickLocation', {
          freeformAddress: this.get('selectedPickAddress'),
          lat: this.get('coordinates.lat'),
          lng: this.get('coordinates.lng')
        });
        const geoNode = await this.getGeoNode(this.get('selectedPickLocation'));
        this.set('selectedPickLocation.zoneId', geoNode.zone.zoneName);
      }
    },

    setLoadAndUnLoadTime() {
      this.set('loadTime', this.get('passengerDetails.loadTime'));
      this.set('UnloadTime', this.get('passengerDetails.UnloadTime'));
    },

    searchTask: (0, _emberConcurrency.task)(function* () {
      const passengerAddress = this.get('passengerDetails.address'); // can't do a blank search

      if (Ember.isEmpty(passengerAddress)) {
        return;
      }

      const results = yield this.get('geocode').getGeonode(passengerAddress);

      if (results && results.attributes && results.attributes.coordinates) {
        this.set('coordinates', results.attributes.coordinates);
      }
    }),

    generateRandomId() {
      return Math.floor(Math.random() * 10000);
    },

    buildArriveAndDepartEventResForPayload(reconcileRouteInfo, reconcileDate, randomIds) {
      return [{
        'id': randomIds.startEventId,
        'routeId': reconcileRouteInfo.routeid,
        'driverId': reconcileRouteInfo.driver.id,
        'vehicleId': reconcileRouteInfo.vehicle.id,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'stopPointId': randomIds.pickStopPointId,
        'odo': null,
        'speed': null,
        'heading': null,
        'eventType': this.get('lastRowClickedInTripTable.arriveEvent.eventtype'),
        'actionType': this.get('lastRowClickedInTripTable.arriveEvent.actiontype'),
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'receivedAt': null,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': randomIds.endEventId,
        'routeId': reconcileRouteInfo.routeid,
        'driverId': reconcileRouteInfo.driver.id,
        'vehicleId': reconcileRouteInfo.vehicle.id,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'stopPointId': randomIds.dropStopPointId,
        'odo': null,
        'speed': null,
        'heading': null,
        'eventType': this.get('lastRowClickedInTripTable.departEvent.eventtype'),
        'actionType': this.get('lastRowClickedInTripTable.departEvent.actiontype'),
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'receivedAt': null,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': randomIds.startEventId,
        'routeId': reconcileRouteInfo.routeid,
        'driverId': reconcileRouteInfo.driver.id,
        'vehicleId': reconcileRouteInfo.vehicle.id,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'stopPointId': randomIds.pickStopPointId,
        'odo': null,
        'speed': null,
        'heading': null,
        'eventType': this.get('lastRowClickedInTripTable.arriveEvent.eventtype'),
        'actionType': this.get('lastRowClickedInTripTable.arriveEvent.actiontype'),
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'receivedAt': null,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': randomIds.endEventId,
        'routeId': reconcileRouteInfo.routeid,
        'driverId': reconcileRouteInfo.driver.id,
        'vehicleId': reconcileRouteInfo.vehicle.id,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'stopPointId': randomIds.dropStopPointId,
        'odo': null,
        'speed': null,
        'heading': null,
        'eventType': this.get('lastRowClickedInTripTable.departEvent.eventtype'),
        'actionType': this.get('lastRowClickedInTripTable.departEvent.actiontype'),
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'receivedAt': null,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }];
    },

    buildStartAddressResForPayload(reconcileRouteInfo, reconcileDate, randomIds) {
      const passengerPickAddress = this.get('passengerPickAddress');
      const selectedPickLocation = this.get('selectedPickLocation');
      return {
        'id': randomIds.startAddressId,
        'streetName': selectedPickLocation.streetAddress ? selectedPickLocation.streetAddress : passengerPickAddress.address.streetAddress,
        'streetNumber': selectedPickLocation.streetNumber ? selectedPickLocation.streetNumber : passengerPickAddress.address.streetNumber,
        'postalCode': selectedPickLocation.postalCode ? selectedPickLocation.postalCode : passengerPickAddress.address.postalCode,
        'locality': selectedPickLocation.locality ? selectedPickLocation.locality : passengerPickAddress.address.locality,
        'region': selectedPickLocation.region ? selectedPickLocation.region : passengerPickAddress.address.region,
        'latitude': selectedPickLocation.lat ? selectedPickLocation.lat : passengerPickAddress.address.lat,
        'longitude': selectedPickLocation.lng ? selectedPickLocation.lng : passengerPickAddress.address.lng,
        'formattedAddress': this.get('selectedPickLocation.freeformAddress'),
        'zoneId': selectedPickLocation.zoneId,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      };
    },

    buildEndAddressResForPayload(reconcileRouteInfo, reconcileDate, randomIds) {
      const selectedDropLocation = this.get('selectedDropLocation');
      return {
        'id': randomIds.endAddressId,
        'streetName': selectedDropLocation.streetAddress,
        'streetNumber': selectedDropLocation.streetNumber,
        'postalCode': selectedDropLocation.postalCode,
        'locality': selectedDropLocation.locality,
        'region': selectedDropLocation.region,
        'latitude': selectedDropLocation.lat,
        'longitude': selectedDropLocation.lng,
        'formattedAddress': selectedDropLocation.freeformAddress,
        'zoneId': selectedDropLocation.zoneId,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      };
    },

    buildRiderEventResForPayload(reconcileRouteInfo, passengerRes, reconcileDate, randomIds) {
      return [{
        'id': randomIds.startEventId,
        'stopPointId': randomIds.pickStopPointId,
        'scheduledTime': this.shiftTimeRange.pickTime,
        'type': this.get('pickAnchorType') ? this.get('pickAnchorType') : null,
        'riderId': passengerRes.passengerId,
        'anchor': null,
        'promisedTime': null,
        'serviceStartTime': this.shiftTimeRange.pickTime,
        'serviceEndTime': this.shiftTimeRange.dropTime,
        'deleted': null,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': randomIds.endEventId,
        'stopPointId': randomIds.dropStopPointId,
        'scheduledTime': this.shiftTimeRange.dropTime,
        'type': this.get('dropAnchorType') ? this.get('dropAnchorType') : null,
        'riderId': passengerRes.passengerId,
        'anchor': null,
        'promisedTime': null,
        'serviceStartTime': this.shiftTimeRange.pickTime,
        'serviceEndTime': this.shiftTimeRange.dropTime,
        'deleted': null,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }];
    },

    buildAttributeResForPayload(reconcileRouteInfo, reconcileDate) {
      const travelNeeds = this.get('selectedTravelNeedsForPayload');
      return travelNeeds.map(ele => {
        return {
          'id': this.generateRandomId(),
          'type': ele.name,
          'createdAt': reconcileDate,
          'updatedAt': reconcileDate,
          'dbVersion': null,
          'op$1': reconcileRouteInfo.op$1,
          'deleted$1': false,
          'reconcileDate': reconcileDate,
          'isReconciled': true,
          'reconciliationNotes': null
        };
      });
    },

    buildStopPointResForPayload(reconcileRouteInfo, reconcileDate, randomIds) {
      return [{
        'id': randomIds.pickStopPointId,
        'tripId': randomIds.tripId,
        'routeIndex': null,
        'addressId': randomIds.startAddressId,
        'clusterId': null,
        'eta': this.shiftTimeRange.dropTime,
        'dwellTime': null,
        'clusterIndex': null,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'status': null,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }, {
        'id': randomIds.dropStopPointId,
        'tripId': randomIds.tripId,
        'routeIndex': null,
        'addressId': randomIds.endAddressId,
        'clusterId': null,
        'eta': this.shiftTimeRange.dropTime,
        'dwellTime': null,
        'clusterIndex': null,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'status': null,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }];
    },

    buildRouteResFromRVDForPayload(reconcileRouteInfo, rvdResponse, reconcileDate) {
      return {
        'deleted$1': false,
        'endAddressId': rvdResponse.route.endaddressid,
        'startAddressId': rvdResponse.route.startaddressid,
        'version': 1,
        'op$1': reconcileRouteInfo.op$1,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'plannedStartTime': rvdResponse.route.plannedstarttime,
        'plannedEndTime': rvdResponse.route.plannedendtime,
        'id': rvdResponse.route.id,
        'providerName': rvdResponse.route.providername,
        'scheduleId': rvdResponse.route.scheduleid,
        'status': rvdResponse.route.status,
        'trackingId': rvdResponse.route.trackingid,
        'actualStartTime': reconcileRouteInfo.routeStartEvent.receivedat,
        'actualEndTime': reconcileRouteInfo.routeEndEvent.receivedat,
        'alertId': rvdResponse.route.alertid,
        'otpValue': rvdResponse.route.otpvalue,
        'otpStatus': rvdResponse.route.otpstatus,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': rvdResponse.route.reconciliationnotes
      };
    },

    buildTripResForPayload(reconcileRouteInfo, reconcileDate, randomIds) {
      return {
        'id': randomIds.tripId,
        'routeId': reconcileRouteInfo.routeid,
        'status': null,
        'externalId': null,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildTripPaymentResForPayload(reconcileDate, randomIds, tripPaymentRes) {
      return {
        'id': randomIds.tripPaymentId,
        'tripId': randomIds.tripId,
        'time': null,
        'fare': null,
        'componentAmount': null,
        'scheduleId': tripPaymentRes.scheduleId,
        'componentName': null,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'dbVersion': null,
        'op$1': tripPaymentRes.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildRiderResForPayload(passengerRes, reconcileDate, randomIds, reconcileRVDResponse, reconcileRouteInfo) {
      return {
        'id': passengerRes.passengerId,
        'name': passengerRes.fullName,
        'firstName': passengerRes.firstName,
        'lastName': passengerRes.lastName,
        'middleName': passengerRes.middleName,
        'passengerTypeName': 'C',
        'bookingClientId': null,
        'tripId': randomIds.tripId,
        'providerName': reconcileRVDResponse.route.providername,
        'op$1': reconcileRouteInfo.op$1,
        'deleted$1': false,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildEventAttributesForPayload(reconcileRouteInfo, reconcileDate, randomIds) {
      return [{
        'id': randomIds.eventAttributeId,
        'eventId': randomIds.startEventId,
        'attributeId': null,
        'attributeCount': null,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'deletedAt': null,
        'deleted$1': false,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      }, {
        'id': randomIds.eventAttributeId,
        'eventId': randomIds.endEventId,
        'attributeId': null,
        'attributeCount': null,
        'scheduleId': reconcileRouteInfo.scheduleRoute.scheduleid,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'deletedAt': null,
        'deleted$1': false,
        'dbVersion': null,
        'op$1': reconcileRouteInfo.op$1,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      }];
    },

    buildReconcileTripPayload() {
      const reconcileRouteInfo = this.get('reconcileRouteInfo');
      const reconcileRVDResponse = this.get('reconcile.reconcileRVDResponse').find(route => route.routeid === parseInt(reconcileRouteInfo.routeid, 10));
      const reconcileDate = new Date().toISOString();
      const passengerRes = this.get('passengerDetails');
      const tripPaymentRes = this.get('reconcile.lastRowClickedInTripTable');
      const randomIds = {
        startEventId: this.generateRandomId(),
        endEventId: this.generateRandomId(),
        startAddressId: this.generateRandomId(),
        endAddressId: this.generateRandomId(),
        attributeId: this.generateRandomId(),
        pickStopPointId: this.generateRandomId(),
        dropStopPointId: this.generateRandomId(),
        eventAttributeId: this.generateRandomId(),
        tripId: this.generateRandomId(),
        tripPaymentId: this.generateRandomId()
      };
      return {
        'events': this.buildArriveAndDepartEventResForPayload(reconcileRouteInfo, reconcileDate, randomIds),
        'address': [this.buildStartAddressResForPayload(reconcileRouteInfo, reconcileDate, randomIds), this.buildEndAddressResForPayload(reconcileRouteInfo, reconcileDate, randomIds)],
        'riderEvents': this.buildRiderEventResForPayload(reconcileRouteInfo, passengerRes, reconcileDate, randomIds),
        'attributes': this.buildAttributeResForPayload(reconcileRouteInfo, reconcileDate),
        'stopPoint': this.buildStopPointResForPayload(reconcileRouteInfo, reconcileDate, randomIds),
        'route': this.buildRouteResFromRVDForPayload(reconcileRouteInfo, reconcileRVDResponse, reconcileDate),
        'trip': this.buildTripResForPayload(reconcileRouteInfo, reconcileDate, randomIds),
        'tripPayment': this.buildTripPaymentResForPayload(reconcileDate, randomIds, tripPaymentRes),
        'rider': this.buildRiderResForPayload(passengerRes, reconcileDate, randomIds, reconcileRVDResponse, reconcileRouteInfo),
        'eventAttributes': this.buildEventAttributesForPayload(reconcileRouteInfo, reconcileDate, randomIds)
      };
    },

    saveAddTripTask: (0, _emberConcurrency.task)(function* () {
      try {
        const payload = this.buildReconcileTripPayload();
        const reconcile = this.get('reconcile');
        return yield reconcile.saveNewTrip(payload);
      } catch (e) {
        throw e;
      }
    }),

    showToolTip(title, tip, successMsg) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveAddTripTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('reconcileAddNewTrip');
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem creating the trip, please try again.';
                break;
            }

            this.set('errorMessage', message);
            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    checkValidations() {
      const routeStartTime = (0, _moment.default)(this.get('reconcileRouteInfo.routeStartEvent.receivedat')).format('HH:mm');
      const routeEndTime = (0, _moment.default)(this.get('reconcileRouteInfo.routeEndEvent.receivedat')).format('HH:mm');
      const currentPickTime = this.get('shiftTimeRange.pickTime') ? (0, _moment.default)(this.get('shiftTimeRange.pickTime')).format('HH:mm') : '';
      const currentDropTime = this.shiftTimeRange.dropTime ? (0, _moment.default)(this.get('shiftTimeRange.dropTime')).format('HH:mm') : '';
      const selectedDropLocation = this.get('selectedDropLocation.freeformAddress') || '';
      const selectedPickLocation = this.get('selectedPickLocation.freeformAddress') || '';

      if (!selectedPickLocation || !selectedDropLocation || !this.get('selectedAnchor')) {
        this.set('errorMessage', 'Please fill out the trip details before saving.');
        return false;
      } else if (!currentPickTime && !currentDropTime) {
        this.set('errorMessage', 'Please fill the anchor time before saving.');
        return false;
      }

      if (currentPickTime) {
        if (currentPickTime < routeStartTime) {
          this.set('errorMessage', 'Pickup time can not be less than route start time');
          return false;
        }

        if (currentPickTime > routeEndTime) {
          this.set('errorMessage', 'Pickup time can not be greater than route end time');
          return false;
        }
      }

      if (currentDropTime) {
        if (currentDropTime > routeEndTime) {
          this.set('errorMessage', 'Dropoff time can not be greater than route end time');
          return false;
        }

        if (currentDropTime < routeStartTime) {
          this.set('errorMessage', 'Dropoff time can not be less than route start time');
          return false;
        }
      }

      return true;
    },

    setDefaultTravelNeeds() {
      const travelNeeds = this.get('passengerTravelNeeds').map(e => {
        return {
          name: e.type,
          cnt: e.cnt
        };
      });
      this.setProperties({
        isEnableCheckbox: false,
        selectedNoOfCompanions: {
          id: 0,
          name: 0,
          value: 0
        },
        isPcaChecked: false,
        isServiceAnimalChecked: false,
        selectedNeedsOptions: this.convertToEmberObject(travelNeeds)
      });
      this.calculateFare();
    },

    convertToEmberObject(travelNeeds) {
      return travelNeeds.filter(tn => {
        return tn.name.toLowerCase() !== PCA && tn.name.toLowerCase() !== SERVICE_ANIMAL;
      }).map(e => {
        return Ember.Object.create(e);
      });
    },

    findAmbulatoryObj(tnResponse) {
      return tnResponse.find(tn => tn.name.toLowerCase() === DEFAULT_TRAVEL_NEED);
    },

    calculateFare() {
      let fare = 0;
      const cloneSelectedNeedsOptions = this.get('cloneSelectedNeedsOptions');
      const ambulatoryOption = this.findAmbulatoryObj(cloneSelectedNeedsOptions);

      if (ambulatoryOption && ambulatoryOption.cnt > 0) {
        fare += ambulatoryOption.cnt * this.defaultFare;
      }

      Ember.set(this, 'expectedFare', `$${fare}`);
      return fare;
    },

    handleAmbulatoryCount() {
      const companionOption = Ember.get(this, 'selectedNoOfCompanions');
      const selectedNeedsOptions = this.get('selectedNeedsOptions');
      const cloneSelectedNeedsOptions = this.get('cloneSelectedNeedsOptions');
      const ambulatoryObj = this.findAmbulatoryObj(selectedNeedsOptions);
      const updatedValue = ambulatoryObj.cnt + companionOption.value;
      const cloneAmbulatoryObj = cloneSelectedNeedsOptions.find(x => x.name.toLowerCase() === ambulatoryObj.name.toLowerCase());
      cloneAmbulatoryObj.set('cnt', updatedValue);
      const ambulatoryIndex = cloneSelectedNeedsOptions.findIndex(x => x.name.toLowerCase() === DEFAULT_TRAVEL_NEED);
      cloneSelectedNeedsOptions.splice(ambulatoryIndex, 1, cloneAmbulatoryObj);
      this.set('cloneSelectedNeedsOptions', cloneSelectedNeedsOptions);
      this.calculateFare();
    },

    isPcaAvail() {
      const travelNeedsOptions = this.get('passengerDetails.travelNeeds');
      const pcaTravelNeeds = travelNeedsOptions.find(tn => tn.type.toLowerCase() === PCA);

      if (pcaTravelNeeds) {
        this.set('isPcaChecked', true);
      }
    },

    isServiceAnimalAvail() {
      const travelNeedsOptions = this.get('passengerDetails.travelNeeds');
      const serviceAnimalTravelNeeds = travelNeedsOptions.find(tn => tn.type === SERVICE_ANIMAL);

      if (serviceAnimalTravelNeeds) {
        this.set('isServiceAnimalChecked', true);
      }
    },

    getGeoNode(selectedAddress) {
      const geocode = this.get('geocode');
      return geocode.getGeonode(selectedAddress.freeformAddress).then(result => {
        return result;
      });
    },

    buildPcaAndServiceAnimalTravelNeedForPayload(travelNeedType, checkBoxCheckedValue) {
      const passengerTravelNeeds = this.get('selectedTravelNeedsForPayload');
      const foundTravelNeed = passengerTravelNeeds.find(travelNeed => travelNeed.name.toLowerCase() === travelNeedType);

      if (checkBoxCheckedValue && foundTravelNeed) {
        return;
      }

      if (checkBoxCheckedValue && !foundTravelNeed) {
        passengerTravelNeeds.pushObject({
          name: travelNeedType.toUpperCase(),
          cnt: Ember.get(this, 'travelNeedsOptionsCount') + 1
        });
        return;
      }

      passengerTravelNeeds.removeObject(foundTravelNeed);
    },

    actions: {
      onAnchorSelect(option) {
        Ember.set(this, 'isEditingStart', true);
        const anchorSelectedVal = option.name;

        if (anchorSelectedVal && anchorSelectedVal.toLowerCase() === 'pick') {
          this.set('pickAnchorType', 'P');
          this.set('isDisableDatePicker', false);
        }

        if (anchorSelectedVal && anchorSelectedVal.toLowerCase() === 'drop') {
          this.set('dropAnchorType', 'D');
          this.set('isDisableDatePicker', false);
        }

        Ember.set(this, 'selectedAnchor', option);
      },

      onSelectCompanion(option) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'selectedNoOfCompanions', option);
        this.handleAmbulatoryCount();
      },

      addTravelNeedItem() {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') + 1);
        this.get('cloneSelectedNeedsOptions').pushObject(Ember.Object.create({
          cnt: Ember.get(this, 'travelNeedsOptionsCount'),
          name: ''
        }));
      },

      onUndoClick() {
        this.geocode.deactivateGeocode();
        this.setDefaultPickupLocation();
        this.setDefaultTravelNeeds();
        this.set('isEditingStart', false);
        Ember.set(this, 'pickNotes', '');
        Ember.set(this, 'dropNotes', '');
        Ember.set(this, 'selectedAnchor', '');
        Ember.set(this, 'travelNeedsOptionsCount', 0);
        this.set('selectedDropLocation', {
          freeformAddress: null
        });
        Ember.set(this, 'selectedDate.date', null);
        Ember.set(this, 'shiftTimeRange.pickTime', null);
        Ember.set(this, 'shiftTimeRange.dropTime', null);
        this.set('isDisableDatePicker', true);
        this.set('isTimeDisable', true);
        this.set('cloneSelectedNeedsOptions', this.get('selectedNeedsOptions'));
        this.set('loadTime', this.get('passengerDetails.loadTime'));
        this.set('UnloadTime', this.get('passengerDetails.UnloadTime'));
        this.calculateFare();
        this.defaultSelectedTravelNeeds();
        this.isPcaAvail();
        this.isServiceAnimalAvail();
      },

      onSaveClick() {
        if (!this.checkValidations()) {
          return false;
        }

        this.set('errorMessage', null);
        this.set('errorMessage2', null);
        const title = 'Add New Trip';
        const tip = 'Would you like to add this new trip?';
        const successMsg = 'NEW TRIP ADDED SUCCESSFULLY';
        this.showToolTip(title, tip, successMsg);
      },

      onDateChange(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set('isTimeDisable', false);
        this.set(valuePath, momentTime.toISOString());
      },

      onTimeChange(valuePath, value) {
        Ember.set(this, 'isEditingStart', true);
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      async onChangePickLocation(record) {
        Ember.set(this, 'isEditingStart', true);
        const geoNode = await this.getGeoNode(record);
        record.set('zoneId', geoNode.zone.zoneName);
        this.set('selectedPickLocation', record);
      },

      async onChangeDropLocation(record) {
        Ember.set(this, 'isEditingStart', true);
        const geoNode = await this.getGeoNode(record);
        record.set('zoneId', geoNode.zone.zoneName);
        this.set('selectedDropLocation', record);
      },

      onDeleteTravelNeeds(selectedNeed) {
        Ember.set(this, 'isEditingStart', true);
        const selectedNeedsOptions = this.get('cloneSelectedNeedsOptions');
        const foundTravelNeed = selectedNeedsOptions.find(sno => sno.name === selectedNeed.name || null);
        selectedNeedsOptions.removeObject(foundTravelNeed);
      },

      onPcaChanged() {
        Ember.set(this, 'isEditingStart', true);
        this.toggleProperty('isPcaChecked');
        this.buildPcaAndServiceAnimalTravelNeedForPayload(PCA, this.get('isPcaChecked'));
      },

      onServiceAnimalChanged() {
        Ember.set(this, 'isEditingStart', true);
        this.toggleProperty('isServiceAnimalChecked');
        this.buildPcaAndServiceAnimalTravelNeedForPayload(SERVICE_ANIMAL, this.get('isServiceAnimalChecked'));
      },

      onRefreshFare() {
        this.calculateFare();
      },

      oninputTextChange(type, value) {
        Ember.set(this, 'isEditingStart', true);

        switch (type) {
          case _stubs.default.LOAD_TIME:
            this.set('loadTime', value);
            break;

          case _stubs.default.UNLOAD_TIME:
            this.set('unloadTime', value);
            break;

          case _stubs.default.PICK_NOTES:
            Ember.set(this, 'pickNotes', value);
            break;

          case _stubs.default.DROP_NOTES:
            Ember.set(this, 'dropNotes', value);
            break;

          case _stubs.default.SELECTEDNEED_CNT:
            Ember.set(this, 'cloneSelectedNeedsOptions[cnt]', value);
            break;

          default:
        }
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      },

      onCloseButtonClick() {
        this.onCloseClick();
      },

      onTravelNeedOptionSelect(valuePath, value) {
        this.set('isEditingStart', true);
        valuePath.set('name', value.name);
        this.get('selectedTravelNeedsForPayload').push({
          cnt: null,
          name: value.name
        });
      }

    }
  });

  _exports.default = _default;
});