define("adept-iq/pods/components/side-drawer/system-config/places/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/side-drawer/system-config/places/config"], function (_exports, _emberConcurrency, _unwrapProxy, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['places'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    disableForm: true,
    isNewPlace: false,
    placeChanges: false,
    location: null,
    disableBtns: null,
    locationConfig: _config.default.fields,
    addressOptions: null,
    selectedPlaceTypeCategory: null,
    inValidName: false,
    inValidZone: false,
    inValidCategory: false,
    inValidAddress: false,
    addressPublicApi: null,
    prevPlaceName: null,
    isEditMode: false,
    undoChanges: false,
    currentState: null,
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    previousPlaceInfo: null,
    previousAddress: null,
    lat: Ember.computed('location.place.location.lat', 'selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      let lat = this.get('location.place.location.lat');

      if (selectedRow && Ember.isEmpty(lat)) {
        lat = selectedRow.get('location.lat');
      }

      return lat;
    }),
    lng: Ember.computed('location.place.location.lng', 'selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      let lng = this.get('location.place.location.lng');

      if (selectedRow && Ember.isEmpty(lng)) {
        lng = selectedRow.get('location.lng');
      }

      return lng;
    }),
    zone: Ember.computed('location.place.address.zoneName.{name}', 'lng', 'selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      let zone = this.get('location.place.address.zoneName.displayName');

      if (selectedRow && Ember.isEmpty(zone)) {
        zone = selectedRow.get('address.zoneName.displayName');
      }

      return zone;
    }),
    placeFormChanges: Ember.computed('placeChanges', 'location.{name,address,placeTypeCategory,place.address.zoneName,place.location.lat}', function () {
      const placeChanges = this.get('placeChanges');

      if (placeChanges) {
        return true;
      }

      return false;
    }),
    disableUndobtn: Ember.computed('disableForm', 'placeChanges', function () {
      const disableForm = this.get('disableForm');
      const placeChanges = this.get('placeChanges');
      const returnValue = !(!disableForm && placeChanges);
      this.hasChanges(!returnValue);
      return returnValue;
    }),
    disableSavebtn: Ember.computed('disableForm', 'location.{name,address,placeTypeCategory,place.address.zoneName,place.location.lat}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = !Ember.isEmpty(this.get('zone')) && !Ember.isEmpty(this.get('lat')) && !Ember.isEmpty(this.get('location.name')) && !Ember.isEmpty(this.get('location.placeTypeCategory'));
      return !hasValues;
    }),
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');

      if (this.get('isEditMode')) {
        const address = this.get('location.place.address.previousReadableAddress');
        this.set('location.place.address.address.address.freeformAddress', address);
        this.set('isEditMode', false);
      }

      this.setDefaultProperties(selectedRow);
    }),

    hasChanges(value) {
      this.set('systemConfig.hasChanges', value);
    },

    init() {
      this._super(...arguments);

      this.setDefaultProperties();
      this.setupPlaceTypeCategoryList();
      this.defaultSettings();
      this.get('providerChanges');
      this.undoPlaces = this.undoPlaces.bind(this);
      this.hasChanges = this.get('hasChanges').bind(this);
    },

    backUpCurrentState() {
      const placeTypeCategory = this.get('location.placeTypeCategory');
      const address = this.get('location.place.address.freeformAddress');
      const name = this.get('location.name');
      const notes = this.get('location.notes');
      const {
        zone,
        lat,
        lng
      } = this;
      const currentState = {
        name,
        zone,
        lat,
        lng,
        placeTypeCategory,
        address,
        notes
      };
      this.set('currentState', currentState);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const address = this.get('store').createRecord('address', {});
      const locationRecord = this.get('store').createRecord('location', {
        lat: '',
        lng: '',
        geoNode: ''
      });
      const placeFields = {
        name: '',
        placeTypeCategory: '',
        notes: '',
        place: Ember.Object.create({
          location: locationRecord,
          address
        })
      };

      if (this.selectedRow) {
        if (this.isNewPlace) {
          this.isNewPlace = false;
        }
      }

      if (this.isNewPlace && !this.undoChanges) {
        this.backUpCurrentState();
      } else {
        this.set('location', Ember.Object.create(placeFields));
      }

      if (!Ember.isEmpty(record)) {
        const location = this.get('location');
        const addressRecord = (0, _unwrapProxy.unwrapProxy)(record.get('address'));
        location.setProperties({
          name: record.get('address.alias'),
          notes: record.get('address.notes'),
          placeTypeCategory: record.get('placeCategoryType.id'),
          'place.address': this.createNewPlaceAddress(addressRecord),
          'place.location': (0, _unwrapProxy.unwrapProxy)(record.get('location'))
        });
        this.set('disableForm', true);
        this.set('placeChanges', false);
        this.defaultSettings();
      } else {
        // no selected rows should have edit, delete and save disabled
        let disableBtns;

        if (this.isNewPlace) {
          disableBtns = {
            new: true,
            edit: true,
            delete: true
          };
        } else {
          disableBtns = {
            new: false,
            edit: true,
            delete: true
          };
        }

        this.set('disableBtns', disableBtns);
        this.set('placeChanges', false);
      }

      if (this.get('addressPublicApi')) {
        this.get('addressPublicApi').onValueChange(this.get('location.place'));
      }

      this.hasChanges(false);
    },

    defaultSettings() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: false
      };
      this.set('disableBtns', disableBtns);
      this.set('inValidName', false);
      this.set('inValidZone', false);
      this.set('inValidCategory', false);
      this.set('inValidAddress', false);
    },

    resetErrors() {
      this.set('inValidName', false);
      this.set('inValidZone', false);
      this.set('inValidCategory', false);
      this.set('inValidAddress', false);
    },

    createNewPlaceAddress(addressRecord) {
      const store = this.get('store');
      const zoneNameRecord = (0, _unwrapProxy.unwrapProxy)(addressRecord.get('zoneName'));
      const addressAttr = addressRecord.toJSON();
      const clonedAddress = store.createRecord('address', addressAttr);
      clonedAddress.set('zoneName', zoneNameRecord);
      return clonedAddress;
    },

    copyRecordDataToPlace(newAddress, selectedRowAddress, zoneName) {
      const addressAttr = newAddress.toJSON(); // eslint-disable-next-line guard-for-in

      for (const addressAttrKey in addressAttr) {
        selectedRowAddress.set(addressAttrKey, addressAttr[addressAttrKey]);
      }

      selectedRowAddress.set('zoneName', zoneName);
    },

    setupPlaceTypeCategoryList() {
      const placeTypeCategory = this.get('store').peekAll('place-category-type');
      const list = [];
      placeTypeCategory.forEach(item => {
        if (item.name.toLowerCase() !== 'passenger') {
          list.push(item.name);
        }
      });
      this.set('placeTypeCategory', list);
    },

    async checkDuplicateAddressName() {
      const {
        name
      } = this.get('location');
      const aliasName = name.trim();

      if (!this.get('isNewPlace') && this.get('prevPlaceName')) {
        if (this.get('prevPlaceName').toLowerCase() === aliasName.toLowerCase()) return false;
      }

      const placeRecords = this.get('store').peekAll('place').toArray();
      const filterRecords = placeRecords.filter(place => {
        const addressAliasName = place.get('address.alias') && place.get('address.alias').toLowerCase();
        const newAliasName = aliasName.toLowerCase();
        return addressAliasName === newAliasName && place.get('placeCategoryType.name') && place.get('placeCategoryType.name').toLowerCase() !== 'passenger';
      });
      return filterRecords.length;
    },

    validateRecordTask: (0, _emberConcurrency.task)(function* () {
      return yield this.validateName();
    }).drop(),

    async validateName() {
      const name = this.get('location.name');
      const address = this.get('location.place.address');
      const zoneName = (0, _unwrapProxy.unwrapProxy)(address.get('zoneName'));
      const category = this.get('location.placeTypeCategory');
      const addressLat = this.get('location.place.location.lat');
      const addressLng = this.get('location.place.location.lng');
      const fullAddress = this.get('location.place.address.fullAddress');
      const errors = [];
      let hasDuplicateNames;
      this.set('inValidName', false);
      this.set('inValidZone', false);
      this.set('inValidCategory', false);
      this.set('inValidAddress', false);

      if (!Ember.isEmpty(name)) {
        hasDuplicateNames = await this.checkDuplicateAddressName(name);
      }

      if (Ember.isEmpty(name)) {
        errors.pushObject({
          validation: 'Name cannot be empty.'
        });
        this.set('inValidName', true);
      } else if (hasDuplicateNames >= 1) {
        errors.pushObject({
          validation: 'Unable to save. Name already exists.'
        });
        this.set('inValidName', true);
      }

      if (!Ember.isEmpty(addressLat) && !Ember.isEmpty(addressLng) && !Ember.isEmpty(fullAddress)) {
        if (Ember.isEmpty(zoneName)) {
          errors.pushObject({
            validation: 'System could not assign a zone to the address.'
          });
          this.set('inValidAddress', true);
        }
      }

      if (Ember.isEmpty(category)) {
        errors.pushObject({
          validation: 'Category cannot be empty.'
        });
        this.set('inValidCategory', true);
      }

      if (Ember.isEmpty(addressLat) && Ember.isEmpty(addressLng) && Ember.isEmpty(fullAddress)) {
        errors.pushObject({
          validation: 'Address cannot be empty.'
        });
        this.set('inValidAddress', true);
      }

      if (!errors.length) return true;
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'System Configuration',
        hasoverlay: true,
        errors,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        }
      });
      return false;
    },

    //if the selected address is already in the address-model we have to patch the address,because backend does'nt allow to post the same address
    getAddressRecord(address, name) {
      const addressRecords = this.get('store').peekAll('address').toArray();
      const filterRecords = addressRecords.filter(adds => {
        return adds.get('address.address.freeformAddress') === address.get('address.address.freeformAddress') && adds.get('address.alias') && adds.get('address.alias').toLowerCase() === name.toLowerCase() && adds.get('id');
      });
      return filterRecords.length ? filterRecords.get('firstObject') : address;
    },

    //if the selected location is already in the location-model we have to patch the location,because backend does'nt allow to post the same location
    getLocationRecord(location) {
      const locationRecords = this.get('store').peekAll('location').toArray();
      const filterRecords = locationRecords.filter(ltn => {
        return ltn.get('latlng') === location.get('latlng') && ltn.get('id');
      });
      return filterRecords.length ? filterRecords.get('firstObject') : location;
    },

    save: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const {
        name,
        placeTypeCategory,
        notes
      } = this.get('location');
      const address = this.getAddressRecord(this.get('location.place.address'), name);
      const zoneName = (0, _unwrapProxy.unwrapProxy)(address.get('zoneName'));
      const location = this.getLocationRecord(this.get('location.place.location'));
      address.set('alias', name);
      address.set('notes', notes);

      if (this.get('isNewPlace')) {
        this.set('tableRef.config.selectFirstRow', false);
        const place = Ember.Object.create({
          geocodingMethod: 'something'
        });
        const locationResponse = location.get('id') ? yield locationAdapter.patchLocation(location) : yield locationAdapter.postLocation(location);
        const addressResponse = address.get('id') ? yield addressAdapter.patchAddress(address) : yield addressAdapter.postAddress(address);
        const addressId = addressResponse ? addressResponse.data.id : address.get('id');
        const locationId = locationResponse ? locationResponse.data.id : location.get('id');
        const locationAndAddressId = {
          'locationId': locationId,
          'addressId': addressId
        };

        if (locationResponse && addressResponse) {
          yield addressAdapter.postAddressLocation(locationResponse, addressResponse);
        } //patched records does'nt give the response back,so we have to pass the id's as argument,to post the address and location


        yield addressAdapter.postAddressLocation(locationResponse, addressResponse, locationAndAddressId);
        yield placeAdapter.postPlace(place, addressId, locationId, placeTypeCategory);
        this.set('isNewPlace', false);
        this.set('tableRef.config.selectFirstRow', true);
        return;
      }

      const place = this.get('selectedRow');
      const selectedRowAddress = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      this.copyRecordDataToPlace(address, selectedRowAddress, zoneName);
      yield locationAdapter.patchLocation(location);
      yield addressAdapter.patchAddress(selectedRowAddress);
      return yield placeAdapter.patchPlace(place, selectedRowAddress.get('id'), location.get('id'), placeTypeCategory);
    }).drop(),
    saveRecordTask: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      let isSaveSuccessful = true;
      yield this.save.perform().then(() => {
        notifications.success('Record successfully saved');

        if (this.get('isEditMode')) {
          const address = this.get('selectedRow.address.readableAddress');
          this.set('selectedRow.address.placeAddress', address);
          this.set('isEditMode', false);
        }
      }).catch(error => {
        isSaveSuccessful = false; // rollback changes before refreshing table

        const selectedRow = this.get('selectedRow');
        this.set('placeChanges', true);

        if (this.get('isEditMode') && selectedRow) {
          const selectedRowAddress = (0, _unwrapProxy.unwrapProxy)(selectedRow.get('address'));
          if (selectedRowAddress) selectedRowAddress.rollbackAttributes();
          selectedRow.set('address.placeAddress', selectedRow.get('address.readableAddress'));
        } // eslint-disable-next-line no-console


        console.error(error);
        notifications.warning('Record failed to save');
      }).finally(() => {
        const tableRef = this.get('tableRef');

        if (isSaveSuccessful) {
          if (tableRef) {
            Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data
          }

          const disableBtns = {
            new: false,
            edit: false,
            delete: false
          };
          this.set('disableBtns', disableBtns);
          this.set('disableForm', true);
        }
      });
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* () {
      const placeRecord = this.get('selectedRow');
      const addressRecord = this.get('location.place.address');
      const notifications = this.get('notifications');

      try {
        yield placeRecord.destroyRecord();
        addressRecord.destroyRecord();
        notifications.success('Record successfully deleted.');
      } catch (error) {
        notifications.warning('Record failed to delete.');
      } finally {
        const tableRef = this.get('tableRef');

        if (tableRef) {
          Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data
        }
      }
    }).drop(),

    undoPlaces() {
      this.resetErrors();

      if (this.get('isNewPlace')) {
        this.set('undoChanges', true);
        this.setDefaultProperties();
      } else {
        const selectedRow = this.get('selectedRow');
        const place = this.get('location.place');
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        const disableBtns = {
          new: true,
          edit: true,
          delete: true
        };
        if (address) address.rollbackAttributes();
        if (location) location.rollbackAttributes();
        this.setDefaultProperties(selectedRow);
        this.set('disableForm', false);
        this.set('disableBtns', disableBtns);
      }
    },

    actions: {
      newPlaces() {
        const disableBtns = {
          new: true,
          edit: true,
          delete: true
        };
        const selectedRow = this.get('selectedRow');

        if (selectedRow) {
          selectedRow.set('selected', false);
          this.set('tableRef.config.selectFirstRow', false);
        }

        this.get('tableRef').onUncheckAll();
        this.set('isNewPlace', true);
        this.set('disableForm', false);
        this.set('disableBtns', disableBtns);
        this.set('placeChanges', false);
      },

      editPlaces() {
        const selectedRow = this.get('selectedRow');
        const address = selectedRow.get('address.placeAddress');
        const disableBtns = {
          new: true,
          edit: true,
          delete: true
        };
        this.setDefaultProperties(selectedRow);
        this.set('prevPlaceName', selectedRow.get('address.alias'));
        this.set('location.place.address.previousReadableAddress', address);
        this.set('isEditMode', true);
        this.set('isNewPlace', false);
        this.set('disableForm', false);
        this.set('disableBtns', disableBtns);
        this.set('placeChanges', false);
      },

      deletePlaces() {
        if (this.get('selectedRow')) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'System Configuration',
            hasoverlay: true,
            tip: 'Are you sure you want to delete this place?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              this.set('placeChanges', false);
              this.set('tableRef.config.selectFirstRow', true);
              return this.get('delete').perform().then(() => {
                tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
          this.resetErrors();
        }
      },

      async savePlaces() {
        const tooltip = this.get('tooltip');
        const validationResult = await this.validateName();
        if (!validationResult) return;
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            tooltip.reset();
            this.set('placeChanges', false);
            this.set('tableRef.config.selectFirstRow', true);
            return this.saveRecordTask.perform();
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            tooltip.reset();
          }

        });
      },

      onCellValueChange(record, valuePath, value, options) {
        this.set('placeChanges', true);
        record.set(`${valuePath}.${options.valuePath}`, value);
      },

      onInputValueChange(record, valuePath, value) {
        this.set('placeChanges', true);
        return record.set(valuePath, value);
      },

      registerAddressPublicApi(publicApi) {
        this.set('addressPublicApi', publicApi);
      },

      undoPlaces() {
        this.undoPlaces();
      },

      onPlaceFormChanges() {
        this.set('placeChanges', true);
      },

      onNotesChanges(value) {
        if (!Ember.isEmpty(value)) {
          this.set('placeChanges', true);
        }
      }

    }
  });

  _exports.default = _default;
});