define("adept-iq/services/map", ["exports", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;
  const {
    benomad,
    iqMap
  } = _apiUrls.API;

  var _default = Ember.Service.extend(Ember.Evented, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    editModal: Ember.inject.service(),
    polyline: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    // stack
    mapContexts: null,
    // map context required for generating maps, geocode, etc...
    bemapMainCtx: null,
    siteId: null,
    bbox: null,
    center: null,
    boundingBox: null,
    // "top" map-context in stack; this is what the map widget shows right now
    topMapContext: Ember.computed.readOnly('mapContexts.lastObject'),
    isEmpty: Ember.computed.equal('mapContexts.length', 0),

    async init() {
      this._super(...arguments);

      this.set('mapContexts', []);
      const domain = new URL(benomad.host).hostname;
      const bemapMainCtx = new bemap.Context({
        'secure': true,
        'host': domain,
        'geoserver': _environment.default.benomad.geoserver
      });
      this.set('bemapMainCtx', bemapMainCtx);
      this.set('center', _environment.default.tomtom.center);
      const session = this.get('session');
      const siteId = session.data.authenticated.userSiteId;

      if (Ember.isPresent(siteId) && !_environment.default.APP.test) {
        this.set('siteId', siteId);
        await this.getBoundingBox();
      }
    },

    getMinCoord(boundingBox) {
      let minCoord = boundingBox[0];
      boundingBox.forEach(point => {
        if (point[0] <= minCoord[0] && point[1] <= minCoord[1]) {
          minCoord = point;
        }
      });
      return minCoord;
    },

    getMaxCoord(boundingBox) {
      let maxCoord = boundingBox[0];
      boundingBox.forEach(point => {
        if (point[0] >= maxCoord[0] && point[1] >= maxCoord[1]) {
          maxCoord = point;
        }
      });
      return maxCoord;
    },

    getBoundingBox() {
      const session = this.get('session');
      const siteId = encodeURIComponent(`${this.get('siteId')}`);
      return this.get('ajax').request(`${iqMap.host}/site?filter=eq(id,'${siteId}')`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(result => {
        const dataAttributes = result.data[0].attributes;
        const boundingBox = dataAttributes.boundingBox.coordinates[0];
        const minCoord = this.getMinCoord(boundingBox);
        const maxCoord = this.getMaxCoord(boundingBox);
        const center = dataAttributes.center.coordinates[0][0];
        this.set('center', {
          lat: center[1],
          lon: center[0]
        });
        this.set('bbox', `${minCoord[0].toFixed(2)},${minCoord[1].toFixed(2)},${maxCoord[0].toFixed(2)},${maxCoord[1].toFixed(2)}`);
        this.set('boundingBox', boundingBox);
        return;
      }).catch(e => {
        console.log(e); //eslint-disable-line no-console

        throw e;
      });
    },

    pushContext(mapContext) {
      this.get('mapContexts').pushObject(mapContext);
    },

    popContext() {
      return this.get('mapContexts').popObject();
    },

    removeContext(identifier) {
      const mapContexts = this.get('mapContexts');
      const mapContext = typeof identifier === 'string' ? mapContexts.findBy('id', identifier) : identifier;
      return this.get('mapContexts').removeObject(mapContext);
    },

    // TODO: get rid of these and call them on the map-context directly
    setLayerVisibility(layerId, isVisible) {
      this.get('topMapContext').setLayerVisibility(layerId, isVisible);
    },

    setLayerOpacity(layerId, opacity) {
      this.get('topMapContext').setLayerOpacity(layerId, opacity);
    },

    setLayerTypeVisibility(layerId, typeId, isVisible) {
      this.get('topMapContext').setLayerTypeVisibility(layerId, typeId, isVisible);
    },

    setLayerLabelVisibility(layerId, labelId, isVisible) {
      this.get('topMapContext').setLayerLabelVisibility(layerId, labelId, isVisible);
    },

    setLayerLabelFilterType(layerId, labelId, filterTypeId) {
      this.get('topMapContext').setLayerLabelFilterType(layerId, labelId, filterTypeId);
    },

    setLayerLabelFilterValues(layerId, labelId, filterValues) {
      this.get('topMapContext').setLayerLabelFilterValues(layerId, labelId, filterValues);
    },

    setLayerLabelsOrder(layerId, labelIds) {
      this.get('topMapContext').setLayerLabelsOrder(layerId, labelIds);
    },

    setDisplayMapIconLabelAllTime(layerId, isDisplayMapIconLabel) {
      this.get('topMapContext').setDisplayMapIconLabelAllTime(layerId, isDisplayMapIconLabel);
    },

    setDisplayMapLabelUpdated(layerId, mapLabelUpdated) {
      this.get('topMapContext').setDisplayMapLabelUpdated(layerId, mapLabelUpdated);
    }

  });

  _exports.default = _default;
});