define("adept-iq/pods/components/iq-widgets/map-widget/config/patterns", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/config/colors"], function (_exports, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PATTERN_REPEAT = 200;
  const TRIP_OFFSET = 10; // makes room for stop icons

  const ROUTE_PLANNED_ARROW_WEIGHT = 6;
  const ROUTE_NAVIGATION_ARROW_WEIGHT = 1; // different offset so they won't overlap

  const ROUTE_PLANNED_OFFSET = 200;
  const ROUTE_NAVIGATION_OFFSET = 180; // solid arrows spaced along body of polyline

  const directionalPattern = function (color) {
    let weight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    let offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : PATTERN_REPEAT / 2;
    let repeat = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : PATTERN_REPEAT;
    return [{
      offset,
      endOffset: offset,
      repeat,
      symbol: window.L.Symbol.arrowHead({
        pathOptions: {
          weight,
          stroke: true,
          color,
          fill: true,
          fillColor: color,
          fillOpacity: 1,
          opacity: 1
        }
      })
    }];
  }; // single arrowhead at end of polyline


  const arrowHeadPattern = function (color) {
    let endOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let pixelSize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 8;
    let weight = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 2;
    // we actually reverse points and draw the head of the arrow at the tail;
    // this allows the offset to be set correctly in pixels
    return [{
      offset: endOffset,
      repeat: 0,
      symbol: window.L.Symbol.arrowHead({
        headAngle: -290,
        // flips arrow around so it looks right
        pixelSize,
        polygon: false,
        pathOptions: {
          weight,
          stroke: true,
          color,
          opacity: 1
        }
      })
    }];
  };

  const solidLinePattern = function (color) {
    let weight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
    return [{
      offset: 0,
      repeat: 0,
      symbol: window.L.Symbol.dash({
        pathOptions: {
          weight,
          stroke: true,
          color,
          opacity: 0.60
        }
      })
    }];
  };

  var _default = {
    // trip endpoint arrows
    trip: [...arrowHeadPattern(_colors.PLANNED_DARK_COLOR)],
    tripDanger: [...arrowHeadPattern(_colors.OTP_DANGER_COLOR)],
    tripLate: [...arrowHeadPattern(_colors.OTP_LATE_COLOR)],
    tripSolid: [...arrowHeadPattern(_colors.PLANNED_DARK_COLOR)],
    tripSolidDanger: [...arrowHeadPattern(_colors.OTP_DANGER_COLOR)],
    tripSolidLate: [...arrowHeadPattern(_colors.OTP_LATE_COLOR)],
    tripPerformed: [...arrowHeadPattern(_colors.PERFORMED_COLOR)],
    // these ones leave a bit of room for the stop icons
    tripOffset: [...arrowHeadPattern(_colors.PLANNED_DARK_COLOR, TRIP_OFFSET)],
    tripDangerOffset: [...arrowHeadPattern(_colors.OTP_DANGER_COLOR, TRIP_OFFSET)],
    tripLateOffset: [...arrowHeadPattern(_colors.OTP_LATE_COLOR, TRIP_OFFSET)],
    tripSolidOffset: [...arrowHeadPattern(_colors.PLANNED_DARK_COLOR, TRIP_OFFSET)],
    tripSolidDangerOffset: [...arrowHeadPattern(_colors.OTP_DANGER_COLOR, TRIP_OFFSET)],
    tripSolidLateOffset: [...arrowHeadPattern(_colors.OTP_LATE_COLOR, TRIP_OFFSET)],
    tripPerformedOffset: [...arrowHeadPattern(_colors.PERFORMED_COLOR, TRIP_OFFSET)],
    // stop polyline directional arrows
    stopPlanned: [...directionalPattern(_colors.PLANNED_COLOR)],
    stopPlannedDanger: [...directionalPattern(_colors.OTP_DANGER_COLOR)],
    stopPlannedLate: [...directionalPattern(_colors.OTP_LATE_COLOR)],
    stopPending: [...directionalPattern(_colors.PENDING_COLOR)],
    stopPendingDanger: [...directionalPattern(_colors.PENDING_COLOR)],
    stopPendingLate: [...directionalPattern(_colors.PENDING_COLOR)],
    stopPerformed: [...directionalPattern(_colors.PERFORMED_COLOR)],
    // route polyline directional arrows
    routePlanned: [...directionalPattern(_colors.PLANNED_COLOR, ROUTE_PLANNED_ARROW_WEIGHT, ROUTE_PLANNED_OFFSET)],
    routeNavigation: [...directionalPattern(_colors.PENDING_COLOR, ROUTE_NAVIGATION_ARROW_WEIGHT, ROUTE_NAVIGATION_OFFSET)],
    routePerformed: [...directionalPattern(_colors.PERFORMED_COLOR)],
    routeScheduled1: [...solidLinePattern(_colors.SCHEDULE_COLOR_1)],
    routeScheduled2: [...solidLinePattern(_colors.SCHEDULE_COLOR_2)],
    routeScheduled3: [...solidLinePattern(_colors.SCHEDULE_COLOR_3)],
    routeScheduled4: [...solidLinePattern(_colors.SCHEDULE_COLOR_4)],
    routeScheduled5: [...solidLinePattern(_colors.SCHEDULE_COLOR_5)],
    routeFloatingBreak: [...directionalPattern(_colors.BENOMAD_FLOATING_BREAK_COLOR, ROUTE_NAVIGATION_ARROW_WEIGHT, ROUTE_NAVIGATION_OFFSET)]
  };
  _exports.default = _default;
});