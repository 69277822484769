define("adept-iq/pods/components/iq-widgets/history-routes-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/pods/components/iq-widgets/history-routes-widget/config", "ember-light-table", "adept-iq/config/mapped-permIds", "moment", "ember-concurrency", "lodash", "adept-iq/utils/format-text-extension"], function (_exports, _component, _config, _emberLightTable, _mappedPermIds, _moment, _emberConcurrency, _lodash, _formatTextExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const toPixels = x => `${x}px`;

  var _default = _component.default.extend({
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    session: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    activeContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    geocode: Ember.inject.service(),
    reconcileLambda: Ember.inject.service(),
    classNames: ['reconcile-widget'],
    config: _config.default,
    contextMenuContainer: null,
    contextMenuContainerPosition: null,
    contextMenuOptions: null,
    lastRowClicked: null,
    isEditClicked: false,
    fetchRouteDataTask: null,
    fetchTaxiDataTask: null,
    resetAndFetchRouteIdsTask: null,
    reconcileRouteDetails: null,
    isColumnEditorSettings: false,
    isCloseSearchPassenger: false,
    isUnscheduledTrips: false,
    editableColumnList: null,
    noresultFound: false,
    scrollToOffset: 0,
    contextMenuHeight: 350,
    serviceModeTaxiType: 'Taxi',
    tableConfigColumns: null,
    ascendingFlag: false,
    currentSortId: Ember.computed.alias('config.defaultSortId'),
    currentSortAsc: Ember.computed.alias('config.defaultSortAsc'),
    map: Ember.computed.readOnly('workspace.activeContext.topActiveContext.map'),
    isCompletedRoute: Ember.computed.alias('reconcile.isCompletedRoute'),
    routeId: Ember.computed.readOnly('reconcile.reconcileSelectedRoute'),
    reconcileTripDate: Ember.computed.readOnly('reconcile.reconcileDate'),
    reconcileHistoryRowData: Ember.computed.alias('reconcile.reconcileHistoryRouteDetails'),
    isFilterAllowed: Ember.computed.bool('workspace.configPermissions.map.filterAllowed'),
    reconcileTripsWithTravelNeedsCount: Ember.computed('reconcile.reconcileTripDetails', function () {
      const reconTripDetails = this.get('reconcile.reconcileTripDetails') || [];
      return reconTripDetails.map(trip => {
        const travelNeeds = this.getTravelNeeds(trip);

        if (travelNeeds) {
          return { ...trip,
            travelNeedsCount: travelNeeds
          };
        }

        return { ...trip
        };
      });
    }),
    canEdit: Ember.computed('lastRowClicked', 'editableColumnList', function () {
      const columnList = Ember.get(this, 'editableColumnList');
      let editableColumns = [];

      if (!Ember.isEmpty(columnList)) {
        editableColumns = columnList.filterBy('isEditable', true);
      }

      return this.lastRowClicked && !this.isCompletedRoute && editableColumns.length;
    }),
    canSave: Ember.computed('historyRoutesTable.columns.@each.editingStart', function () {
      const columns = Ember.get(this, 'historyRoutesTable.columns');
      let editedColumns = [];

      if (!Ember.isEmpty(columns)) {
        editedColumns = columns.filterBy('editingStart', true);
      }

      return editedColumns.length;
    }),
    isFiltered: Ember.computed('historyRoutesTable.columns.@each.{isFiltered}', function () {
      return this.get('historyRoutesTable.columns').isAny('isFiltered');
    }),
    title: Ember.computed('reconcileRouteDetails', function () {
      const routeDetails = Ember.get(this, 'reconcileRouteDetails');

      if (routeDetails && routeDetails.routeid && this.reconcileTripDate) {
        return `Route ${routeDetails.routeid} on ${(0, _moment.default)(this.reconcileTripDate).format('MM/DD/YYYY')}`;
      }

      return 'Route';
    }),
    onWidgetStateChange: Ember.observer('widget.state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateConfigColumns');
    }),
    updateReconcileHistoryRouteDetails: Ember.observer('reconcile.reconcileTripDetails', 'currentSortAsc', 'currentSortId', function () {
      this.resetProperties();
      const historyRouteDetails = this.get('reconcileTripsWithTravelNeedsCount') || [];
      this.set('reconcile.reconcileTripsWithTravelNeedsCount', historyRouteDetails);
      const routeDetails = Ember.get(this, 'reconcile.reconcileRouteInfo');
      const sortKey = Ember.get(this, 'currentSortId');
      const currentSortAsc = Ember.get(this, 'currentSortAsc');
      const configHistoryRoutesTableColumns = this.config.historyRoutesColumns;
      const foundCol = configHistoryRoutesTableColumns.find(col => col.valuePath === sortKey);
      this.set('reconcileRouteDetails', routeDetails);
      const sortedList = currentSortAsc ? this.sortList(historyRouteDetails, sortKey, foundCol.dataType) : this.sortList(historyRouteDetails, sortKey).reverse();
      this.get('historyRoutesTable').setRows(sortedList);
      this.refreshData();
      this.maintainTripSelection();
    }),

    init() {
      this._super(...arguments); //assign table and populate data in it


      this.configurePermissions();
      this.createRouteTable();
      this.updateColumnsForFilter();

      if (this.get('reconcile.reconcileHistoryRouteDetails')) {
        Ember.run.scheduleOnce('afterRender', this, 'updateReconcileHistoryRouteDetails');
      }

      this.get('geocode').deactivateGeocode();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('geocode').deactivateGeocode();
    },

    configurePermissions() {
      this.set('isTransferTripToRouteAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.transferTripRecon, null));
      this.set('isEditTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editTripInfoRecon, null));
      this.set('isCancelTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.cancelTripRecon, null));
      this.set('isNoshowTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.noShowTripRecon, null));
      this.set('isUnperformNoshowTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.unperformNoShowRecon, null));
      this.set('isBreakAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.insertBreakRecon, null));
      this.set('isTransferTripToTaxiAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.transferTripToTaxiRecon, null));
      this.set('isScheduledTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.scheduleTripsRecon, null));
      this.set('isEditColumnAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editColumnRecon, null));
      this.set('isViewUnscheduledTripsAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.viewUnscheduledTripsRecon, null));
      this.set('isAddNewTripsAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.addNewTripRecon, null));
      this.set('isRouteActivityLogAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.activityLogRoute, null));
      this.set('isTripActivityLogAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.activityLog, null));
      this.set('isSortingAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.sortRouteGridRecon, null));
    },

    deriveTravelNeedsCount(travelNeeds) {
      if (travelNeeds && travelNeeds.length === 1) {
        return `${travelNeeds.length} Travel Need`;
      }

      if (travelNeeds && travelNeeds.length > 1) {
        return `${travelNeeds.length} Travel Needs`;
      }

      return '';
    },

    getTravelNeeds(trip) {
      const travelNeeds = [];
      let tripTravelNeeds = trip.eventAttribute ? trip.eventAttribute.attribute : '';

      if (!_lodash.default.isEmpty(tripTravelNeeds)) {
        if (!_lodash.default.isArray(tripTravelNeeds)) {
          // Only one item
          tripTravelNeeds = [tripTravelNeeds];
        }

        const travelNeedCount = this.deriveTravelNeedsCount(tripTravelNeeds);
        tripTravelNeeds.forEach(function (item) {
          const count = parseInt(item.cnt, 10) || 1;
          const name = item.type;
          let text = {};
          text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${count} ${name}`);
          text.travelNeedDesc = travelNeedCount;
          travelNeeds.push(text);
        });
      }

      return travelNeeds;
    },

    sortList(tripList, key, type) {
      const paths = key.split('.');
      return tripList.sort(function (a, b) {
        const valueA = a[paths[0]] ? a[paths[0]][paths[1]] : '';
        const valueB = b[paths[0]] ? b[paths[0]][paths[1]] : '';

        if (paths.length > 1 && type === 'time') {
          // eslint-disable-next-line no-nested-ternary
          return (0, _moment.default)(valueA) > (0, _moment.default)(valueB) ? 1 : (0, _moment.default)(valueB) > (0, _moment.default)(valueA) ? -1 : 0;
        }

        if (paths.length > 1 && type !== 'time') {
          // eslint-disable-next-line no-nested-ternary
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }

        if (paths.length <= 1 && type === 'time') {
          // eslint-disable-next-line no-nested-ternary
          return (0, _moment.default)(a[key]) > (0, _moment.default)(b[key]) ? 1 : (0, _moment.default)(b[key]) > (0, _moment.default)(a[key]) ? -1 : 0;
        } // eslint-disable-next-line no-nested-ternary


        return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
      });
    },

    resetProperties() {
      this.setProperties({
        reconcileRouteDetails: null,
        lastRowClicked: null,
        isEditClicked: null,
        noresultFound: false
      });
    },

    resetTable(isSearchMessageVisible) {
      this.set('reconcileRouteDetails', null);
      this.get('historyRoutesTable').setRows([]);

      if (isSearchMessageVisible) {
        if (Ember.get(this, 'historyRoutesTable').isEmpty) {
          this.set('noresultFound', true);
        }
      }
    },

    maintainTripSelection() {
      const selectedSearchedTrip = Ember.get(this, 'reconcile.selectedSearchedTrip');
      const rows = this.get('historyRoutesTable.rows.content');

      if (selectedSearchedTrip) {
        rows.forEach(_row => {
          if (_row.get('tripId') === selectedSearchedTrip.get('tripId') && _row.get('type') === 'pickup') {
            this.send('onRowClick', _row);
          }
        });
      }
    },

    selectRow(stopId) {
      if (!stopId) {
        return;
      }

      const rows = this.get('historyRoutesTable.rows');
      const previousSelectedRow = rows.filter(row => row.content.stopId === stopId)[0];

      if (previousSelectedRow) {
        this.send('onRowClick', previousSelectedRow);
      }
    },

    createRouteTable() {
      const configHistoryRoutesTableColumns = this.config.historyRoutesColumns;
      configHistoryRoutesTableColumns.forEach(column => {
        if (['date', 'datetime', 'time', 'datetimeflatpickr'].includes(column.dataType) && column.format && typeof column.format === 'string') {
          const formatDate = column.format;

          column.format = value => {
            return Ember.isEmpty(value) ? value : (0, _moment.default)(value).format(formatDate);
          };
        }
      });
      const historyRoutesTable = new _emberLightTable.default(configHistoryRoutesTableColumns);
      this.set('historyRoutesTable', historyRoutesTable);
      this.setSortingPropsToRouteTable();
      this.setEditablePropsToRouteTable();
    },

    setSortingPropsToRouteTable() {
      const routeTableColumns = this.get('historyRoutesTable.columns');
      routeTableColumns.forEach(column => {
        if (column.valuePath === Ember.get(this, 'currentSortId')) {
          column.set('sorted', true);
          column.set('ascending', this.config.defaultSortAsc);
        }
      });
    },

    setEditablePropsToRouteTable() {
      if (Ember.isEmpty(this.editableColumnList)) {
        this.set('editableColumnList', []);
      }

      const editRouteTableColumns = this.get('historyRoutesTable.columns');
      editRouteTableColumns.map(column => {
        column.set('isEditable', true);
        this.editableColumnList.push({
          label: column.label,
          isEditable: column.isEditable
        });
      });
    },

    updateColumnsForFilter() {
      const columns = [];
      const tableColumns = this.config.historyRoutesColumns;

      if (!Ember.isEmpty(tableColumns)) {
        tableColumns.forEach(column => {
          if (!column.unAvailable) {
            const col = Ember.Object.create(column);
            col.set('filterTypes', this.config.filters[column.dataType] || []);
            columns.push(col);
          }
        });
      }

      Ember.set(this, 'tableConfigColumns', columns);
    },

    getEditTableColumn(configColumn) {
      let column = null;
      const editConfigColumns = this.config.historyRoutesColumns;
      const editConfigColumn = editConfigColumns.filter(configCol => configCol.valuePath === configColumn.id)[0];

      if (editConfigColumn) {
        const editColumns = Ember.get(this, 'historyRoutesTable.columns');
        column = editColumns.filter(col => col.valuePath === configColumn.id)[0];
      }

      return column;
    },

    getNonEditTableColumn(configColumn) {
      let column = null;
      const nonEditConfigColumns = this.config.historyRoutesColumns;
      const nonEditConfigColumn = nonEditConfigColumns.filter(configCol => configCol.valuePath === configColumn.id)[0];

      if (nonEditConfigColumn) {
        const nonEditColumns = Ember.get(this, 'historyRoutesTable.columns');
        column = nonEditColumns.filter(col => col.valuePath === configColumn.id)[0];
      }

      return column;
    },

    setPropToActualColumn(configColumn, options) {
      let column = this.getEditTableColumn(configColumn);

      if (!column) {
        column = this.getNonEditTableColumn(configColumn);
      }

      if (column) {
        if (options.isHiddenProp) {
          column.set('hidden', configColumn.hidden);
        } else if (options.isWidthProp) {
          column.set('width', configColumn.width);
        }
      }

      return column;
    },

    updateConfigColumns() {
      const state = this.get('widget.state') || {};
      const columns = this.get('tableConfigColumns') || [];
      let shouldResize = false;
      const scrollLeft = this.getScrollLeft();
      const columnsHash = state.columns || {};
      Object.entries(columnsHash).forEach(_ref => {
        let [id, columnState] = _ref;
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (Ember.isPresent(columnState.hidden)) {
          column.set('hidden', columnState.hidden);
          this.setPropToActualColumn(column, {
            isHiddenProp: true
          });
          shouldResize = true;
        }

        const wasFiltered = column.get('isFiltered');
        ['filterTypeId', 'filterValues', 'filterType'].forEach(prop => {
          // allow null value to overwrite
          if (!columnState.hasOwnProperty(prop)) {
            return;
          }

          column.set(prop, columnState[prop]);
        });

        if (wasFiltered || column.get('isFiltered')) {
          Ember.run.scheduleOnce('afterRender', this, 'refreshData');
        }

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
          this.setPropToActualColumn(column, {
            isWidthProp: true
          });
          shouldResize = true;
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }

      Ember.run.debounce(this, 'refreshData', 250);
    },

    setScrollLeft(scrollLeft) {
      if (!this.$('.reconcile-widget-table-body')) {
        return;
      }

      this.$('.reconcile-widget-table-body').scrollLeft(scrollLeft);
    },

    getScrollLeft() {
      const body = this.$('.reconcile-widget-table-body');

      if (body) {
        return body.scrollLeft();
      }

      return 0;
    },

    getFilteredColumns(columns) {
      return columns.filter(column => {
        if (!Ember.isEmpty(column.filterType)) {
          if (column.filterType.id === 'empty') {
            return true;
          } else if (!Ember.isEmpty(column.filterValues) && column.filterValues.filter(item => !Ember.isEmpty(item)).length) {
            return true;
          }
        }

        return false;
      });
    },

    refreshData() {
      const columns = this.get('tableConfigColumns') || [];
      const tableData = this.get('reconcileHistoryRowData') || [];
      const resultModel = [];

      if (!Ember.isEmpty(tableData)) {
        const searchColumns = this.getFilteredColumns(columns);
        tableData.map(item => {
          if (Ember.isEmpty(searchColumns)) resultModel.push(item);else {
            let bool = true;
            searchColumns.forEach(column => {
              if (column.filterType && column.filterType.exp && column.filterType.exp(item[column.valuePath], column.filterValues)) return;
              bool = false;
            });
            if (bool) resultModel.push(item);
          }
        });

        if (resultModel) {
          const editTable = Ember.get(this, 'historyRoutesTable');
          const nonEditTable = Ember.get(this, 'historyRoutesTable');
          const rows = [...new Set(resultModel)];
          editTable.setRowsSynced(rows.toArray());
          nonEditTable.setRowsSynced(rows.toArray());
        }

        this.set('noresultFound', false);

        if (Ember.get(this, 'historyRoutesTable').isEmpty) {
          this.set('noresultFound', true);
        }
      }
    },

    isValidOption(optionId) {
      switch (optionId) {
        case 'reconcileTripTransferToRoute':
          return this.isTransferTripToRouteAllowed;

        case 'reconcileTripTransferToTaxi':
          return this.isTransferTripToTaxiAllowed;

        case 'reconcileCancelTrip':
          return this.isCancelTripAllowed;

        case 'reconcileNoShowTrip':
          return this.isNoshowTripAllowed;

        case 'reconcileUnPerformNoShow':
          return this.isUnperformNoshowTripAllowed;

        case 'reconcileEditAdditionalTripInfo':
          return this.isEditTripAllowed;

        case 'reconcileTripLog':
          return this.isTripActivityLogAllowed;

        case 'reconcileAddNewTrip':
          return this.isAddNewTripsAllowed;

        case 'reconcileAddBreak':
          return this.isBreakAllowed;

        case 'reconcileUnscheduledTrips':
          return this.isViewUnscheduledTripsAllowed;

        case 'reconcileShowRouteLog':
          return this.isRouteActivityLogAllowed;

        case 'reconcileColumnEditor':
          return this.isEditColumnAllowed;

        default:
          break;
      }

      return false;
    },

    getEnableDisableOptionFlag(optionId, rowData) {
      if (!rowData) {
        return true;
      }

      let isEnabled = false;

      if (this.get('reconcile.reconcileMode') === this.serviceModeTaxiType) {
        return this.isValidOption(optionId) && (optionId === 'reconcileCancelTrip' || optionId === 'reconcileEditAdditionalTripInfo' || optionId === 'reconcileTripLog');
      } // For normal trips


      switch (rowData.type.toLowerCase()) {
        case 'startgarage':
        case 'endgarage':
        case 'break':
          isEnabled = this.isValidOption(optionId) && (this.isCompletedRoute && optionId === 'reconcileShowRouteLog' || !this.isCompletedRoute && (optionId === 'reconcileShowRouteLog' || optionId === 'reconcileAddNewTrip' || optionId === 'reconcileAddBreak' || optionId === 'reconcileUnscheduledTrips' || optionId === 'reconcileShowRouteLog' || optionId === 'reconcileColumnEditor'));
          break;

        case 'pick':
        case 'drop':
          isEnabled = this.isValidOption(optionId) && (this.isCompletedRoute && (optionId === 'reconcileTripLog' || optionId === 'reconcileShowRouteLog') || !this.isCompletedRoute && (optionId === 'reconcileTripTransferToRoute' || optionId === 'reconcileTripTransferToTaxi' || rowData.status && rowData.status.toLowerCase() !== 'noshow' && optionId === 'reconcileCancelTrip' || rowData.status && rowData.status.toLowerCase() !== 'noshow' && optionId === 'reconcileNoShowTrip' || rowData.status && rowData.status.toLowerCase() === 'noshow' && optionId === 'reconcileUnPerformNoShow' || optionId === 'reconcileWaitlistTrip' || optionId === 'reconcileEditAdditionalTripInfo' || optionId === 'reconcileTripLog' || optionId === 'reconcileAddNewTrip' || optionId === 'reconcileAddBreak' || optionId === 'reconcileUnscheduledTrips' || optionId === 'reconcileShowRouteLog' || optionId === 'reconcileColumnEditor'));
          break;

        default:
          break;
      }

      return isEnabled;
    },

    createContextMenu(rowData) {
      if (!rowData) {
        return;
      }

      this.set('contextMenuOptions', []);
      this.get('config').contextMenuOptionsList.map(option => {
        this.get('contextMenuOptions').push(this.createContextMenuOptions(option.id, option.caption, option.dialogId, option.dialogTitle, rowData));
      });
    },

    createContextMenuOptions(optionId, optionCaption, dialogId, dialogTitle, rowData) {
      const disable = !this.getEnableDisableOptionFlag(optionId, rowData);
      let action = null;

      if (!disable) {
        action = trip => {
          Ember.set(this, 'contextMenuHandler', this.contextMenuCallback.bind(this, optionId));

          if (optionId === 'reconcileTripTransferToTaxi') {
            this.handleTranserTripToTaxi();
          } else if (optionId === 'reconcileAddNewTrip') {
            this.unselectRow();
            this.toggleProperty('isCloseSearchPassenger');
          } else if (optionId === 'reconcileColumnEditor') {
            this.toggleProperty('isColumnEditorSettings');
          } else if (optionId === 'reconcileUnscheduledTrips') {
            this.toggleProperty('isUnscheduledTrips');
          } else {
            const recon = Ember.get(this, 'reconcile');
            recon.set('reconcileSelectedTripId', trip.tripId);
            this.get('workspace').pushState(optionId, {
              trip,
              title: dialogTitle,
              route: Ember.get(this, 'reconcileRouteDetails'),
              action: this.contextMenuHandler
            });
          }
        };
      }

      return {
        id: optionId,
        name: optionCaption,
        permId: dialogId,
        disable,
        action
      };
    },

    async updateHistoryRouteWidget(stopId, isValid) {
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });

      if (this.get('reconcile.reconcileMode') === this.serviceModeTaxiType) {
        this.fetchTaxiDataTask.perform().then(() => {
          if (stopId) {
            this.toggleProperty('reconcile.isLoading');

            if (isValid) {
              this.get('notifications').success('SUCCESSFULLY CHANGED VALUES');
            }

            this.selectRow(stopId);
          }
        });
        return;
      }

      try {
        await this.reconcile.fetchReconcileTrips(this.routeId);
      } catch (error) {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem is getting updated route, please try again.';
            break;
        }

        this.get('notifications').warning(message);

        if (this.get('reconcile.isLoading')) {
          this.toggleProperty('reconcile.isLoading');
        }
      }
    },

    contextMenuCallback(optionId, isSaved) {
      Ember.set(this, 'reconcile.reconcileSelectedTripId', null);

      if (optionId === 'reconcileAddNewTrip') {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
      }

      if (optionId === 'reconcileEditAdditionalTripInfo' || optionId === 'reconcileAddBreak') {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetMapFlag', true);
      }

      if (!isSaved || optionId === 'reconcileTripLog' || optionId === 'reconcileShowRouteLog') {
        return;
      }

      this.updateHistoryRouteWidget();
    },

    isValidColumnForEditing(row, column) {
      if (!row) {
        return false;
      }

      let isValid = true;
      const rowData = row.content;
      const columnType = column.get('valuePath');

      switch (rowData.type.toLowerCase()) {
        case 'pickup':
        case 'dropoff':
          if (columnType === 'ambSeats' || columnType === 'fare' || columnType === 'pca' && !rowData.pca) {
            isValid = false;
          }

          break;

        case 'startgarage':
        case 'endgarage':
          if (columnType === 'stopTime' || columnType === 'otpStatus' || columnType === 'ambSeats' || columnType === 'wcSeats' || columnType === 'cmp' || columnType === 'pca' || columnType === 'fare') {
            isValid = false;
          }

          break;

        case 'break':
          if (columnType === 'ambSeats' || columnType === 'wcSeats' || columnType === 'cmp' || columnType === 'pca' || columnType === 'fare') {
            isValid = false;
          }

          break;

        default:
          break;
      }

      return isValid;
    },

    enableDisableTableRows(isEnable) {
      const rows = this.get('historyRoutesTable.rows');
      rows.map(row => {
        row.set('disabled', !isEnable);
      });
    },

    manageRowEditing() {
      const rows = this.get('historyRoutesTable.rows');
      const row = rows.findBy('selected');

      if (row) {
        const columns = this.get('historyRoutesTable.columns');
        columns.map(column => {
          const valuePath = column.get('valuePath');
          const value = row.get(valuePath);
          column.set('oldValue', value);

          if (column.get('isEditable') && this.isValidColumnForEditing(row, column)) {
            row.set('isEditing', true);
            row.set('columns', columns);
            column.set('isEditing', true);
            this.set('isEditClicked', true);
          }
        });
      }

      if (this.isEditClicked) {
        this.enableDisableTableRows(false);
      }
    },

    enableDisableEditing(row, isEnable) {
      const columns = row.get('columns');
      columns.map(column => {
        column.set('isEditing', isEnable);

        if (!isEnable) {
          column.set('editingStart', isEnable);
        }
      });
      row.set('isEditing', isEnable);
    },

    handleSaveTrip() {
      const rows = this.get('historyRoutesTable.rows');
      const row = rows.findBy('selected');
      let isValueChanged = false;

      if (row) {
        const rowData = row.get('content');
        const columns = row.get('columns');
        columns.map(column => {
          const newValue = column.get('newValue');
          const oldValue = column.get('oldValue');
          const valuePath = column.get('valuePath');

          if (newValue && oldValue !== newValue && column.get('isEditable')) {
            row.set(valuePath, newValue);
            isValueChanged = true;

            if (valuePath === 'actualTime') {
              row.set('eta', newValue);
              row.set('arriveTime', newValue);
            }
          }
        });
        this.enableDisableEditing(row, false);
        this.enableDisableTableRows(true);
        this.set('lastRowClicked', rowData);
      }

      if (isValueChanged) {
        this.toggleProperty('reconcile.isLoading');
        this.get('saveTripDetailsTask').perform(row);
      }

      this.set('isEditClicked', false);
    },

    // Create playload for map polyline and marker!!!
    // Later to be updated with relation model
    mapTrip(selectedTrip) {
      const tripDetail = {
        eventPoints: [],
        addresses: {
          pick: null,
          drop: null
        }
      };
      const reconcileStopPointMap = this.get('reconcile.reconcileStopPointMap');
      Ember.set(this, 'workspace.activeContext.topActiveContext.implicitMarkers', []);
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', []);
      if (Ember.isEmpty(selectedTrip)) return Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      }); // To display polyline in the map, we should give the data as ember model object. But we get a normal object from the lambda as response.
      // So built ReconcileTrip & ReconcileStops from the models [reconcile-trip & reconcile-stops] respectively.

      const reconcileTrip = this.buildReconcileTrip(selectedTrip);
      const reconcileStops = this.buildReconcileStops(reconcileStopPointMap[selectedTrip.tripId]);
      const eventBounds = [];

      if (!Ember.isEmpty(reconcileStops)) {
        reconcileStops.forEach(stop => {
          tripDetail.addresses[stop.get('type')] = stop.get('address');

          if (stop.get('lat') && stop.get('lng')) {
            tripDetail.eventPoints.push([stop.get('lat').toString(), stop.get('lng').toString()]);
            eventBounds.push({
              lat: stop.get('lat').toString(),
              lng: stop.get('lng').toString()
            });
          }
        });

        if (tripDetail.eventPoints.length && tripDetail.addresses.pick && tripDetail.addresses.drop) {
          const map = this.get('map');
          const bounds = window.L.latLngBounds(tripDetail.eventPoints);

          if (bounds) {
            map.native.fitBounds(bounds);
          }

          Ember.set(this, 'workspace.activeContext.topActiveContext.implicitMarkers', eventBounds);
          reconcileTrip.set('addresses', tripDetail.addresses);
          reconcileTrip.set('providerName', Ember.get(this, 'reconcileRouteDetails.providerName'));
          const pickAddress = tripDetail.addresses.pick;
          const dropAddress = tripDetail.addresses.drop;
          reconcileTrip.set('polyline', [[dropAddress.lat, dropAddress.lng], [pickAddress.lat, pickAddress.lng]]);
          return Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
            'reconcile-trip': [reconcileTrip],
            'reconcile-trip-stops': reconcileStops
          });
        }
      }

      return Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });
    },

    /**
     * Create reconcileTrip from the selectedTrip
     * @param selectedTrip
     * @returns {reconcileTrip}
     */
    buildReconcileTrip(selectedTrip) {
      const {
        status,
        type,
        externalTripId,
        promisedTime,
        routeId,
        tripId
      } = selectedTrip;
      const reconcileTrip = this.store.createRecord('reconcile-trip', {
        tripId,
        status,
        externalTripId,
        routeId,
        'type': type.toLowerCase(),
        'promiseTime': promisedTime
      });
      return reconcileTrip;
    },

    /**
     * Create reconcileStops from the stops
     * @param stops
     * @returns {reconcileStops}
     */
    buildReconcileStops(stops) {
      return stops.map(stop => {
        const {
          eta,
          status,
          type,
          lat,
          lng,
          odometerOnArrival,
          promisedTime,
          routeId,
          stopPointId,
          tripId,
          stopAddress
        } = stop;
        const reconcileAddress = this.store.createRecord('reconcile-address', {
          'formattedAddress': stopAddress,
          lat,
          lng
        });
        const reconcileStop = this.store.createRecord('reconcile-stop', {
          tripId,
          'stopId': stopPointId,
          eta,
          status,
          'type': type.toLowerCase(),
          'stopType': type.toLowerCase(),
          'odometerArrival': odometerOnArrival,
          'promiseTime': promisedTime,
          routeId,
          lat,
          lng,
          'address': reconcileAddress
        });
        return reconcileStop;
      });
    },

    calculateActualDepartTime(data) {
      let actualDepartDateTime = data.actualArriveDateTime;

      if (data.actualTime) {
        if (!actualDepartDateTime) {
          const tripDate = (0, _moment.default)(this.reconcileTripDate).format('MM-DD-YYYY');
          actualDepartDateTime = (0, _moment.default)(tripDate + ' ' + data.actualTime);
        } else {
          const actualDate = (0, _moment.default)(actualDepartDateTime).format('MM-DD-YYYY');
          actualDepartDateTime = (0, _moment.default)(actualDate + ' ' + data.actualTime);
        }

        const actualDepartDate = (0, _moment.default)(actualDepartDateTime).format('MM-DD-YYYY');
        const startTime = (0, _moment.default)(actualDepartDateTime).format('hh:mm A');
        const stopMins = data.stopTime;
        const endTime = (0, _moment.default)(startTime, 'h:mm A').add(stopMins, 'minutes').format('hh:mm A');
        return (0, _moment.default)(actualDepartDate + ' ' + endTime).toISOString();
      }

      return null;
    },

    saveTripDetailsTask: (0, _emberConcurrency.task)(function* () {
      try {
        const reconcile = this.get('reconcile');
        const tripPayload = this.buildReconcileTripPayload();
        yield reconcile.updateReconcileTripsInfo(tripPayload);
        this.get('notifications').success('SUCCESSFULLY CHANGED VALUES');
      } catch (error) {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem updating the route, please try again.';
            break;
        }

        this.get('notifications').warning(message);
        return false;
      } finally {
        this.toggleProperty('reconcile.isLoading');
      }
    }),

    getValidationError() {
      const rows = this.get('historyRoutesTable.rows');
      const row = rows.findBy('selected');

      if (row) {
        let actualTime, stopTime, odometer, ambSeats, wcSeats, cmp, pca, fare, otpStatus;
        const columns = row.get('columns');
        columns.map(column => {
          const oldValue = column.get('oldValue');
          const newValue = column.get('newValue');
          const valuePath = column.get('valuePath');
          const finalValue = !oldValue && newValue ? newValue : oldValue;

          switch (valuePath) {
            case 'actualTime':
              actualTime = finalValue;
              break;

            case 'stopTime':
              stopTime = finalValue;
              break;

            case 'mileage':
              odometer = finalValue;
              break;

            case 'ambSeats':
              ambSeats = finalValue;
              break;

            case 'wcSeats':
              wcSeats = finalValue;
              break;

            case 'cmp':
              cmp = finalValue;
              break;

            case 'pca':
              pca = finalValue;
              break;

            case 'fare':
              fare = finalValue;
              break;

            case 'otpStatus':
              otpStatus = finalValue;
              break;

            default:
              break;
          }
        });
        const rowData = Ember.get(row, 'content');

        if (stopTime && !Number(stopTime) && Number(stopTime) !== 0) {
          return 'Stop time is not valid';
        }

        if (stopTime && !actualTime) {
          return 'You can not edit Stop Time if Actual Time is empty';
        }

        if (odometer && !Number(odometer) && Number(odometer) !== 0) {
          return 'Mileage is not valid';
        }

        if (!(rowData.type && (rowData.type.toLowerCase() === 'startgarage' || rowData.type.toLowerCase() === 'endgarage' || rowData.type.toLowerCase() === 'break'))) {
          if (ambSeats && !Number(ambSeats) && Number(ambSeats) !== 0) {
            return 'AmbSeats is not valid';
          }

          if (wcSeats && !Number(wcSeats) && Number(wcSeats) !== 0) {
            return 'WcSeats is not valid';
          }

          if (cmp && !Number(cmp) && Number(cmp) !== 0) {
            return 'CMP is not valid';
          }

          if (pca && !Number(pca) && Number(pca) !== 0) {
            return 'PCA is not valid';
          }

          if (fare && !Number(fare) && Number(fare) !== 0) {
            return 'Fare is not valid';
          }

          if (otpStatus && !Number(otpStatus) && Number(otpStatus) !== 0) {
            return 'Otp status is not valid';
          }
        }
      }

      return null;
    },

    showErrorToolTip(title, tip) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: title,
        tip: tip,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    removeTaxiTrip() {
      const tripDetails = Ember.get(this, 'lastRowClicked');
      const record = this.store.peekRecord('reconcile-trip', tripDetails.tripId);
      this.store.unloadRecord(record);
      this.contextMenuCallback('reconcileTripTransferToTaxi', true);
    },

    unselectRow() {
      const tableRows = this.get('historyRoutesTable.rows');
      const selectedRow = tableRows.findBy('selected');

      if (selectedRow) {
        selectedRow.set('selected', null);
        this.set('workspace.activeContext.topActiveContext.structuredMapData', {
          'reconcile-trip': [],
          'reconcile-trip-stops': []
        });
        Ember.set(this, 'lastRowClicked', null);
      }
    },

    //Building Trip Payload Response
    //Building Trip Payload Response
    buildArriveAndDepartEventResForPayload(tripResponse, reconcileDate) {
      return [{
        'id': tripResponse.arriveEvent.id || '',
        'routeId': tripResponse.arriveEvent.routeid || '',
        'driverId': tripResponse.arriveEvent.driverid || '',
        'vehicleId': tripResponse.arriveEvent.vehicleid || '',
        'scheduleId': tripResponse.arriveEvent.scheduleid || '',
        'stopPointId': tripResponse.arriveEvent.stoppointid,
        'odo': tripResponse.arriveEvent.odo,
        'tripId': tripResponse.arriveEvent.tripid,
        'eventType': tripResponse.arriveEvent.eventtype,
        'actionType': tripResponse.arriveEvent.actiontype,
        'speed': tripResponse.arriveEvent.speed,
        'heading': tripResponse.arriveEvent.heading,
        'createdAt': tripResponse.arriveEvent.createdat || tripResponse.createdAt,
        'updatedAt': tripResponse.arriveEvent.updatedat || tripResponse.updatedAt,
        'receivedAt': tripResponse.arriveEvent.receivedat || '',
        'dbVersion': null,
        'op$1': tripResponse.arriveEvent.__op || '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': tripResponse.arriveEvent.reconciliationnotes
      }, {
        'id': tripResponse.departEvent.id || '',
        'routeId': tripResponse.departEvent.routeid || '',
        'driverId': tripResponse.departEvent.driverid || '',
        'vehicleId': tripResponse.departEvent.vehicleid || '',
        'scheduleId': tripResponse.departEvent.scheduleid || '',
        'stopPointId': tripResponse.departEvent.stoppointid,
        'odo': tripResponse.departEvent.odo,
        'tripId': tripResponse.departEvent.tripid,
        'eventType': tripResponse.departEvent.eventtype,
        'actionType': tripResponse.departEvent.actiontype,
        'speed': tripResponse.departEvent.speed,
        'heading': tripResponse.departEvent.heading,
        'createdAt': tripResponse.departEvent.createdat || tripResponse.createdAt,
        'updatedAt': tripResponse.departEvent.updatedat || tripResponse.updatedAt,
        'receivedAt': tripResponse.departEvent.receivedat || '',
        'dbVersion': null,
        'op$1': tripResponse.departEvent.__op || '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': tripResponse.departEvent.reconciliationnotes
      }];
    },

    buildAddressResForPayload(tripResponse, reconcileDate) {
      return [{
        'id': tripResponse.address.id,
        'streetName': tripResponse.address.streetname,
        'streetNumber': tripResponse.address.streetnumber,
        'postalCode': tripResponse.address.postalcode,
        'locality': tripResponse.address.locality,
        'region': tripResponse.address.region,
        'latitude': tripResponse.address.latitude,
        'longitude': tripResponse.address.longitude,
        'formattedAddress': tripResponse.address.formattedaddress,
        'zoneId': tripResponse.address.zoneid,
        'createdAt': tripResponse.address.createdat || tripResponse.createdAt,
        'updatedAt': tripResponse.address.updatedat || tripResponse.updatedAt,
        'op$1': tripResponse.address.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': tripResponse.address.reconciliationnotes
      }];
    },

    buildRiderEventResForPayload(tripResponse, reconcileDate) {
      return [{
        'id': tripResponse.riderEvent.id,
        'stopPointId': tripResponse.riderEvent.stoppointid,
        'scheduledTime': tripResponse.riderEvent.scheduledtime,
        'type': tripResponse.riderEvent.type,
        'riderId': tripResponse.riderEvent.riderid,
        'anchor': tripResponse.riderEvent.anchor,
        'promisedTime': tripResponse.riderEvent.promisedtime,
        'serviceStartTime': tripResponse.riderEvent.servicestarttime,
        'serviceEndTime': tripResponse.riderEvent.serviceendtime,
        'deleted': tripResponse.riderEvent.deleted,
        'scheduleId': tripResponse.scheduleId,
        'createdAt': tripResponse.riderEvent.createdat || tripResponse.createdAt,
        'updatedAt': tripResponse.riderEvent.updatedat || tripResponse.updatedAt,
        'dbVersion': null,
        'op$1': tripResponse.riderEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': tripResponse.riderEvent.reconciliationnotes
      }];
    },

    buildAttributeResForPayload(tripResponse, reconcileDate) {
      const eventAttributes = tripResponse.eventAttribute || '';

      if (eventAttributes) {
        if (eventAttributes.attribute) {
          return eventAttributes.attribute.map(atr => {
            return {
              'id': atr.id,
              'type': atr.type,
              'createdAt': atr.createdat || tripResponse.createdAt,
              'updatedAt': atr.updatedat || tripResponse.updatedAt,
              'dbVersion': null,
              'op$1': atr.__op || '',
              'deleted$1': false,
              'reconcileDate': reconcileDate,
              'isReconciled': true,
              'reconciliationNotes': atr.reconciliationnotes
            };
          });
        }

        return [{
          'id': tripResponse.eventAttribute.eventid || '',
          'type': '',
          'createdAt': tripResponse.eventAttribute.createdat || tripResponse.createdAt,
          'updatedAt': tripResponse.eventAttribute.updatedat || tripResponse.updatedAt,
          'dbVersion': null,
          'op$1': tripResponse.eventAttribute.__op || '',
          'deleted$1': false,
          'reconcileDate': reconcileDate,
          'isReconciled': true,
          'reconciliationNotes': tripResponse.eventAttribute.reconciliationnotes || ''
        }];
      }

      return [{
        'id': '',
        'type': '',
        'createdAt': tripResponse.createdAt,
        'updatedAt': tripResponse.updatedAt,
        'dbVersion': null,
        'op$1': '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': ''
      }];
    },

    buildStopPointResForPayload(tripResponse, reconcileDate) {
      return [{
        'id': tripResponse.stopPointId,
        'tripId': tripResponse.tripId,
        'routeIndex': null,
        'addressId': tripResponse.address.id,
        'clusterId': null,
        'eta': tripResponse.eta,
        'dwellTime': null,
        'clusterIndex': null,
        'scheduleId': tripResponse.scheduleId,
        'status': tripResponse.status,
        'createdAt': tripResponse.createdAt || tripResponse.createdAt,
        'updatedAt': tripResponse.updatedAt || tripResponse.updatedAt,
        'dbVersion': null,
        'op$1': tripResponse.riderEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }];
    },

    buildRouteResFromRVDForPayload(rvdResponse, tripResponse, reconcileDate) {
      return {
        'deleted$1': false,
        'endAddressId': rvdResponse.route.endaddressid,
        'startAddressId': rvdResponse.route.startaddressid,
        'version': null,
        'op$1': rvdResponse.route.__op,
        'createdAt': rvdResponse.route.createdat || tripResponse.createdAt,
        'plannedStartTime': rvdResponse.route.plannedstarttime,
        'plannedEndTime': rvdResponse.route.plannedendtime,
        'id': rvdResponse.route.id,
        'providerName': rvdResponse.route.providername,
        'scheduleId': rvdResponse.route.scheduleid,
        'status': rvdResponse.route.status,
        'trackingId': rvdResponse.route.trackingid,
        'updatedAt': rvdResponse.route.updatedat || tripResponse.updatedAt,
        'actualStartTime': rvdResponse.route.actualstarttime,
        'actualEndTime': rvdResponse.route.actualendtime,
        'alertId': rvdResponse.route.alertid,
        'otpValue': rvdResponse.route.otpvalue,
        'otpStatus': rvdResponse.route.otpstatus,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': rvdResponse.route.reconciliationnotes
      };
    },

    buildRiderResForPayload(tripResponse, reconcileDate) {
      return {
        'id': tripResponse.riderDetails.id,
        'name': tripResponse.riderDetails.name,
        'firstName': tripResponse.riderDetails.firstname,
        'lastName': tripResponse.riderDetails.lastname,
        'middleName': tripResponse.riderDetails.middlename,
        'passengerTypeName': tripResponse.riderDetails.passengertypename,
        'bookingClientId': tripResponse.riderDetails.bookingclientid,
        'tripId': tripResponse.riderDetails.tripId,
        'providerName': tripResponse.riderDetails.providername,
        'op$1': tripResponse.riderDetails.__op,
        'createdAt': tripResponse.riderDetails.createdat || tripResponse.createdAt,
        'updatedAt': tripResponse.riderDetails.updatedat || tripResponse.updatedAt,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildTripResForPayload(tripResponse, reconcileDate) {
      return {
        'id': tripResponse.tripId,
        'routeId': tripResponse.routeId,
        'status': tripResponse.status,
        'externalId': tripResponse.externalTripId,
        'scheduleId': tripResponse.scheduleId,
        'createdAt': tripResponse.createdAt || tripResponse.createdAt,
        'updatedAt': tripResponse.updatedAt || tripResponse.updatedAt,
        'op$1': tripResponse.tripPayment.__op || '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildEventAttributesForPayload(tripResponse, reconcileDate) {
      const eventAttributes = tripResponse.eventAttribute || '';

      if (eventAttributes) {
        return [{
          'id': tripResponse.eventAttribute.eventid,
          'eventId': tripResponse.eventAttribute.eventid,
          'attributeId': tripResponse.eventAttribute.attributeid || '',
          'attributeCount': tripResponse.eventAttribute.attributecount || '',
          'scheduleId': tripResponse.eventAttribute.scheduleid || tripResponse.arriveEvent.scheduleid,
          'createdAt': tripResponse.eventAttribute.createdat || tripResponse.createdAt,
          'updatedAt': tripResponse.eventAttribute.updatedat || tripResponse.updatedAt,
          'deletedAt': null,
          'deleted$1': false,
          'dbVersion': null,
          'op$1': tripResponse.eventAttribute.__op || '',
          'reconcileDate': reconcileDate,
          'isReconciled': true
        }];
      }

      return [{
        'id': '',
        'eventId': '',
        'attributeId': '',
        'attributeCount': '',
        'scheduleId': tripResponse.arriveEvent.scheduleid,
        'createdAt': tripResponse.createdAt,
        'updatedAt': tripResponse.updatedAt,
        'deletedAt': null,
        'deleted$1': false,
        'dbVersion': null,
        'op$1': ''
      }];
    },

    buildReconcileTripPayload() {
      const reconcileRouteInfo = this.get('reconcile.reconcileRouteInfo');
      const reconcileRVDResponse = this.get('reconcile.reconcileRVDResponse').find(route => route.routeid === parseInt(reconcileRouteInfo.routeid, 10));
      const selectedTripRow = this.get('reconcile.lastRowClickedInTripTable');
      const reconcileDate = new Date().toISOString();
      return {
        'events': this.buildArriveAndDepartEventResForPayload(selectedTripRow, reconcileDate),
        'address': this.buildAddressResForPayload(selectedTripRow, reconcileDate),
        'riderEvents': this.buildRiderEventResForPayload(selectedTripRow, reconcileDate),
        'attributes': this.buildAttributeResForPayload(selectedTripRow, reconcileDate),
        'stopPoint': this.buildStopPointResForPayload(selectedTripRow, reconcileDate),
        'rider': this.buildRiderResForPayload(selectedTripRow, reconcileDate),
        'route': this.buildRouteResFromRVDForPayload(reconcileRVDResponse, selectedTripRow, reconcileDate),
        'trip': this.buildTripResForPayload(selectedTripRow, reconcileDate),
        'eventAttributes': this.buildEventAttributesForPayload(selectedTripRow, reconcileDate)
      };
    },

    actions: {
      onColumnEditorSaveClick(columnEditorList) {
        const columns = this.get('historyRoutesTable.columns');
        columns.map(column => {
          columnEditorList.map(item => {
            if (item.label === column.label) {
              column.set('isEditable', item.isEditable);
            }
          });
        });
        this.set('editableColumnList', columnEditorList);
        this.send('onColumnEditorCloseClick');
      },

      onColumnEditorCloseClick() {
        this.toggleProperty('isColumnEditorSettings');
      },

      onSearchPassengerCloseClick() {
        this.toggleProperty('isCloseSearchPassenger');
      },

      onUnscheduledTripsCloseClick() {
        this.toggleProperty('isUnscheduledTrips');
      },

      setContextMenuContainer(dropdown) {
        this.set('contextMenuContainer', dropdown);
      },

      onContextMenuRowClick(action) {
        const model = this.get('lastRowClicked');
        this.get('contextMenuContainer').actions.close();

        if (action.action) {
          action.action(model);
        }
      },

      onRowClick(row) {
        if (this.get('isEditClicked')) {
          return;
        }

        const rows = this.get('historyRoutesTable.rows');
        const verifyRoute = this.get('reconcile.verifyRouteIsChecked');
        const selectedRow = rows.findBy('selected');
        if (row === selectedRow) return;
        this.mapTrip(row.content);
        row.set('selected', true);

        if (selectedRow) {
          selectedRow.set('selected', false);
        }

        if (verifyRoute) {
          this.set('lastRowClicked', null);
        } else {
          this.set('lastRowClicked', row.get('content'));
        }

        this.set('reconcile.lastRowClickedInTripTable', row.get('content'));
        Ember.set(this, 'reconcile.selectedTripRow', row.get('content'));
      },

      onEditRowRightClick(row, event) {
        event.preventDefault();
      },

      onRowRightClick(row, event) {
        if (this.get('isEditClicked')) {
          return;
        }

        let rows = this.get('historyRoutesTable.rows');
        let selectedRow = rows.findBy('selected');

        if (selectedRow !== row) {
          rows = this.get('historyRoutesTable.rows');
          selectedRow = rows.findBy('selected');

          if (selectedRow !== row) {
            return;
          }
        }

        this.createContextMenu(row.content);
        let top = event.clientY;

        if (top + this.contextMenuHeight > Ember.$(window).height()) {
          top -= top + this.contextMenuHeight - Ember.$(window).height();
        }

        this.set('contextMenuContainerPosition', () => {
          return {
            style: {
              top: top,
              left: event.clientX
            }
          };
        });
        this.set('lastRowClicked', row.get('content'));
        this.get('contextMenuContainer').actions.open();
      },

      onEditClick() {
        if (this.get('isEditClicked') || this.isCompletedRoute) {
          return;
        }

        this.manageRowEditing();
      },

      onUndoClick() {
        if (!this.get('isEditClicked') || this.isCompletedRoute) {
          return;
        }

        const rows = this.get('historyRoutesTable.rows');
        const row = rows.findBy('selected');

        if (row) {
          const columns = row.get('columns');
          columns.forEach(column => {
            // if (column.get('isEditable')) {
            const oldValue = column.get('oldValue');
            const valuePath = column.get('valuePath');

            if (oldValue) {
              row.set(valuePath, oldValue);
            } // }

          });
          this.enableDisableEditing(row, false);
          this.enableDisableTableRows(true);
          this.set('isEditClicked', false); //this.manageRowEditing();
        }
      },

      onSaveClick() {
        if (this.isCompletedRoute) {
          return;
        } //save all the edit cells into the models


        const error = this.getValidationError();

        if (error) {
          this.showErrorToolTip('Error message', error);
        } else {
          this.handleSaveTrip();
        }
      },

      onColumnClick(column) {
        if (!this.isSortingAllowed) {
          return false;
        }

        const previousSortKey = Ember.get(this, 'currentSortId');
        const sortColumn = column.get('valuePath');
        const ascendingFlag = this.get('ascendingFlag');

        if (sortColumn === previousSortKey) {
          column.set('ascending', ascendingFlag);
          this.set('ascendingFlag', !ascendingFlag);
          this.toggleProperty('currentSortAsc');
          return;
        }

        const routesColumns = this.get('historyRoutesTable.columns');
        routesColumns.forEach(col => {
          if (col.valuePath === sortColumn) {
            col.set('sorted', true);
            column.set('ascending', true);
          } else if (col.valuePath === previousSortKey) {
            col.set('sorted', false);
          }
        });
        Ember.set(this, 'currentSortId', sortColumn);
        Ember.set(this, 'currentSortAsc', true);
      },

      onScroll(offset) {
        Ember.set(this, 'scrollToOffset', offset);
        event.preventDefault();
      },

      onFilterButtonClick() {
        if (this.get('isEditClicked')) {
          return;
        }

        this.unselectRow();
        const columns = this.get('tableConfigColumns') || [];
        const workspace = this.get('workspace');
        const widget = this.get('widget');
        const displayName = `${this.config.title} Filters`; // close sidebar if _this_ widget's filter is currently open
        // @TODO: define and use unique keys rather than `displayName` for this

        if (displayName === workspace.get('topStateDisplayName')) {
          workspace.popState();
          return;
        }

        let topState = workspace.get('topState');

        while (topState === 'reconcileFilterColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        workspace.pushState('reconcileFilterColumnWidget', {
          columns,
          widget,
          displayName
        });
      }

    }
  });

  _exports.default = _default;
});