define("adept-iq/pods/components/generic-components/datetimeflatpickr/component", ["exports", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy", "adept-iq/utils/encoding", "moment"], function (_exports, _environment, _unwrapProxy, _encoding, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    notEmpty
  } = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['datetimeflatpickr-wrapper', 'flatpickr'],
    classNameBindings: ['focus', 'disabled'],
    flatpickrRef: null,
    value: null,
    disabled: false,
    defaultDate: null,
    maxDate: null,
    minDate: null,
    clickOpens: true,
    format: null,
    extra: null,
    isOpened: null,
    showCaret: true,
    allowInput: false,
    enableTime: false,
    noCalendar: false,
    showClearButton: false,
    placeholder: 'Select Date',
    closeCalender: false,
    focus: readOnly('isOpened'),
    minTimePath: readOnly('extra.minTimePath'),
    maxTimePath: readOnly('extra.maxTimePath'),
    valuePath: readOnly('extra.valuePath'),
    isDatePresent: notEmpty('value'),

    init() {
      this._super(...arguments);

      const record = (0, _unwrapProxy.unwrapProxy)(this.get('record'));
      const valuePath = this.get('valuePath');
      const minTimePath = this.get('minTimePath');
      const maxDateTimePath = this.get('maxTimePath');

      const format = this.get('format') || _environment.default.dateTimeFormat.dateFlatPickrDate;

      if (record) {
        let minDateValue = record.get(`${minTimePath}`);
        minDateValue = minDateValue ? (0, _moment.default)(minDateValue).format('YYYY-MM-DD HH:mm A') : (0, _moment.default)().format('YYYY-MM-DD HH:mm A');
        const minDate = minTimePath ? minDateValue : (0, _moment.default)(this.get('value')).format('YYYY-MM-DD HH:mm A');
        let maxDate = record.get(`${maxDateTimePath}`);
        maxDate = maxDate ? (0, _moment.default)(maxDate).format('YYYY-MM-DD HH:mm A') : null;
        this.set('minDate', minDate);
        this.set('maxDate', maxDate);
      }

      if (valuePath && record) {
        this.set('value', record.get(valuePath));
      } // time needs to be inclusive, so we cut seconds off


      this.set('dateFormat', format);

      if (this.get('readonlyCell')) {
        this.set('clickOpens', false);
      }
    },

    getCurrentDateValue() {
      const value = this.get('value');

      if ((0, _encoding.isDate)(value)) {
        return value.getTime();
      }

      return value;
    },

    isValueChanged(changedValue) {
      const value = this.getCurrentDateValue();
      if (Ember.isEmpty(changedValue)) return true;
      return value !== changedValue.getTime();
    },

    actions: {
      isValidDate(value) {
        const newValueMoment = (0, _moment.default)(value);

        if (newValueMoment._isValid) {
          this.set('value', newValueMoment.toDate());
        }
      },

      onChangeDate(value) {
        const changedValue = value[0];

        if (!changedValue) {
          this.get('onChange')(null);
          this.set('isOpened', true);
          this.flatpickrRef.toggle();
        } //when AM/PM is changed, flatpickr  triggers the event two times.. it causes duplicate calling of onChange method
        else if (this.isValueChanged(changedValue)) {
          this.get('onChange')(changedValue);
        }
      },

      onOpen() {
        if (this.get('closeCalender')) {
          this.send('toggleCalendar');
          this.set('closeCalender', false);
        } else {
          this.set('isOpened', true);
          this.set('closeCalender', true);
        }
      },

      onClose(currentValue, stringValue, datePicker) {
        const val = currentValue.length ? currentValue[0] : stringValue;
        const newValueMoment = (0, _moment.default)(val);
        this.set('isOpened', false);
        this.set('closeCalender', false);

        if (datePicker._input.value && newValueMoment._isValid) {
          this.get('onChange')(newValueMoment.toDate());
        }
      },

      toggleCalendar() {
        if (this.get('isOpened')) return;
        this.flatpickrRef.toggle();
      },

      onClearDate() {
        this.get('onChange')(null);
      },

      onReady(_selectedDates, _dateStr, instance) {
        if (Ember.isPresent(this.get('onReady'))) this.get('onReady')(instance);
      }

    }
  });

  _exports.default = _default;
});