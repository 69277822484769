define("adept-iq/pods/components/form-components/shift-breaks/component", ["exports", "moment", "adept-iq/config/environment", "ember-concurrency"], function (_exports, _moment, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  var _default = Ember.Component.extend({
    classNames: ['form-components-availabilities', 'form-components-places'],
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    value: null,
    disabled: false,
    dayOfWeeks: null,
    store: Ember.inject.service(),
    startTimePath: Ember.computed.readOnly('extra.startTimePath'),
    endTimePath: Ember.computed.readOnly('extra.endTimePath'),
    sundayPath: Ember.computed.readOnly('extra.sundayPath'),
    mondayPath: Ember.computed.readOnly('extra.mondayPath'),
    tuesdayPath: Ember.computed.readOnly('extra.tuesdayPath'),
    wednesdayPath: Ember.computed.readOnly('extra.wednesdayPath'),
    thursdayPath: Ember.computed.readOnly('extra.thursdayPath'),
    fridayPath: Ember.computed.readOnly('extra.fridayPath'),
    saturdayPath: Ember.computed.readOnly('extra.saturdayPath'),
    shiftBreaksPath: Ember.computed.readOnly('extra.shiftBreaksPath'),
    shiftBreakTypePath: Ember.computed.readOnly('extra.shiftBreakTypePath'),
    breakPromisedStartPath: Ember.computed.readOnly('extra.breakPromisedStartPath'),
    breakPromisedEndPath: Ember.computed.readOnly('extra.breakPromisedEndPath'),
    breakDurationPath: Ember.computed.readOnly('extra.breakDurationPath'),
    shiftBreaks: null,
    breakTypes: null,
    addressOptions: null,
    // addressType: computed.readOnly('extra.addressvaluePath'),
    aliasPath: Ember.computed.readOnly('extra.aliasPath'),
    notesPath: Ember.computed.readOnly('extra.notesPath'),
    streetNumberPath: Ember.computed.readOnly('extra.streetNumberPath'),
    streetAddressPath: Ember.computed.readOnly('extra.streetAddressPath'),
    streetNamePath: Ember.computed.readOnly('extra.streetNamePath'),
    localityPath: Ember.computed.readOnly('extra.localityPath'),
    regionPath: Ember.computed.readOnly('extra.regionPath'),
    subRegionPath: Ember.computed.readOnly('extra.subRegionPath'),
    postalCodePath: Ember.computed.readOnly('extra.postalCodePath'),
    countryPath: Ember.computed.readOnly('extra.countryPath'),
    tomtomFormattedAddressPath: Ember.computed.readOnly('extra.tomtomFormattedAddressPath'),
    locationsPath: Ember.computed.readOnly('extra.locationsPath'),
    addressRecord: null,
    selected: null,
    geocodeAddressChange: Ember.observer('geocode.address', function () {
      const record = this.get('addressRecord');
      const address = this.get('geocode.address');
      this.convertTTAddressToModel(record, address).then(() => {
        this.disableAddressSearchOnMap(record);
      });
    }),

    init() {
      this.set('dayOfWeeks', ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);
      this.set('datePlaceHolder', _environment.default.dateTimeFormat.dateMoment);
      this.set('timePlaceHolder', _environment.default.dateTimeFormat.timeMoment);
      this.set('dateFormat', _environment.default.dateTimeFormat.dateFlatPickr);
      this.set('timeFormat', _environment.default.dateTimeFormat.timeFlatPickr);

      this._super(...arguments);

      this.set('shiftBreaks', this.get('value'));
    },

    validateAddresses() {
      const record = this.get('address');
      this.set('editModal.errors', []);

      if (record.location.content.lat === 0 || record.location.content.lng === 0) {
        this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
      }
    },

    addressSearchResults(results) {
      this.set('addressOptions', results);
    },

    searchAddress: (0, _emberConcurrency.task)(function* (term) {
      const result = yield this.get('geocode').geoCodingApi(term);
      this.addressSearchResults(result);
    }),
    convertTTAddressToModel: function (record, ttAddress) {
      if (Ember.isNone(ttAddress)) {
        return;
      }

      if (Ember.isNone(ttAddress.id) || !Ember.isNone(ttAddress.position)) {
        const useOptionRecord = true;
        const valuePath = this.get('streetNumberPath');
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        const geocode = this.get('geocode');

        if (ttAddress.address && ttAddress.address.freeformAddress) {
          return geocode.getGeonode(ttAddress.address.freeformAddress).then(result => {
            if (!Ember.isNone(result)) {
              record.set('location.geoNode', result.geonode);
              this.set('record.isForceDirty', true);
              this.get('onChange')(cleanFieldString(ttAddress.address.streetNumber), options);
              options.valuePath = this.get('streetAddressPath');
              this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);
              options.valuePath = this.get('streetNamePath');
              this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);
              options.valuePath = this.get('localityPath');
              this.get('onChange')(cleanFieldString(ttAddress.address.municipality), options);
              options.valuePath = this.get('regionPath');
              this.get('onChange')(cleanFieldString(ttAddress.address.countrySubdivisionName), options);
              options.valuePath = this.get('subRegionPath');
              this.get('onChange')(cleanFieldString(ttAddress.address.countrySecondarySubdivision), options);
              options.valuePath = this.get('postalCodePath');
              this.get('onChange')(cleanFieldString(ttAddress.address.postalCode), options);
              options.valuePath = this.get('countryPath');
              this.get('onChange')(cleanFieldString(ttAddress.address.countryCode), options);
              options.valuePath = this.get('aliasPath');
              this.get('onChange')('', options);
              record.set('location.lat', ttAddress.position.lat);
              record.set('location.lng', ttAddress.position.lon);
              this.validateAddresses();
            } else {
              this.get('notifications').warning(`CAN'T GET GEONODE FOR ${ttAddress.address.freeformAddress}`);
              return;
            }

            geocode.activateGeocode(false, record);
            this.set('addressOptions', this.get('record').get('rider.riderAddresses'));
          });
        }
      } else {
        const useOptionRecord = true;
        const valuePath = this.get('streetNumberPath');
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        const geocode = this.get('geocode');

        if (ttAddress.tomtomFormattedAddress && ttAddress.tomtomFormattedAddress.address && ttAddress.tomtomFormattedAddress.address.freeformAddress) {
          return geocode.getGeonode(ttAddress.tomtomFormattedAddress.address.freeformAddress).then(result => {
            if (!Ember.isNone(result)) {
              record.set('location.geoNode', result.geonode);
              this.set('record.isForceDirty', true);
              this.get('onChange')(cleanFieldString(ttAddress.streetNumber), options);
              options.valuePath = this.get('streetNamePath');
              this.get('onChange')(cleanFieldString(ttAddress.streetAddress), options);
              options.valuePath = this.get('localityPath');
              this.get('onChange')(cleanFieldString(ttAddress.locality), options);
              options.valuePath = this.get('regionPath');
              this.get('onChange')(cleanFieldString(ttAddress.countrySubdivisionName), options);
              options.valuePath = this.get('subRegionPath');
              this.get('onChange')(cleanFieldString(ttAddress.countrySecondarySubdivision), options);
              options.valuePath = this.get('postalCodePath');
              this.get('onChange')(cleanFieldString(ttAddress.postalCode), options);
              options.valuePath = this.get('countryPath');
              this.get('onChange')(cleanFieldString(ttAddress.countryCode), options);
              options.valuePath = this.get('aliasPath');
              this.get('onChange')(cleanFieldString(ttAddress.alias), options);
              options.valuePath = this.get('notesPath');
              this.get('onChange')(cleanFieldString(ttAddress.notes), options);
              record.set('location.lat', ttAddress.locations.firstObject.lat);
              record.set('location.lng', ttAddress.locations.firstObject.lng);
              this.validateAddresses();
            } else {
              this.get('notifications').warning(`CAN'T GET GEONODE FOR ${ttAddress.address.freeformAddress}`);
              return;
            }

            geocode.activateGeocode(false, record);
            this.set('addressOptions', this.get('record').get('rider.riderAddresses'));
          });
        }
      }
    },
    clearAddressModel: function (record) {
      const addressvaluePath = this.get('streetNumberPath');
      const options = {
        record,
        addressvaluePath
      };
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('streetNamePath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('localityPath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('regionPath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('subRegionPath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('postalCodePath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('countryPath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('aliasPath');
      this.get('onChange')('', options);
      record.set('lat', 0);
      record.set('lng', 0);
    },

    enableAddressSearchOnMap(record) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      this.get('geocode').activateGeocode(true, record);
      this.set('addressRecord', record);
    },

    disableAddressSearchOnMap(record) {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').activateGeocode(false, record);
      this.set('addressRecord', null);
    },

    didInsertElement: function () {
      const breakTypeModelName = this.get('extra.breakTypeModelName');
      this.set('breakTypes', this.get('store').peekAll(breakTypeModelName));
    },

    getAvailibilityBreaks(record) {
      return record.get('shiftBreaks');
    },

    actions: {
      onInput(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
      },

      onChangeDate(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value[0], options);
      },

      onClose(currentValue, stringValue, datePicker) {
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid) {
          // $(`#${datePicker.element.id}`).focus();
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          // date format is for database so should not be from config
          datePicker.setDate(newValueMoment.format('YYYY-MM-DD'), true);
        }
      },

      onChangeTime(record, valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        const options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(momentTime.format('HH:mm:ss'), options);
      },

      onCheckboxChange(record, valuePath) {
        let switchState = record.get(valuePath);
        const options = {
          record,
          valuePath
        };
        switchState = !switchState;
        this.set('record.isForceDirty', true);
        this.get('onChange')(switchState, options);
      },

      onDOWChange(record, values) {
        record.set('sunday', values.includes('sunday'));
        record.set('monday', values.includes('monday'));
        record.set('tuesday', values.includes('tuesday'));
        record.set('wednesday', values.includes('wednesday'));
        record.set('thursday', values.includes('thursday'));
        record.set('friday', values.includes('friday'));
        record.set('saturday', values.includes('saturday'));
        this.set('record.isForceDirty', true);
      },

      onAddNewBreak(record, valuePath, modelName) {
        const place = this.get('store').createRecord('place') || [];
        const address = this.get('editModal').addRecordValue(place, 'address', 'address') || [];
        const location = this.get('editModal').addRecordValue(place, 'location', 'location') || [];
        const shiftBreak = this.get('store').createRecord(modelName) || [];
        address.get('locations').pushObject(location);
        shiftBreak.set('breakType', this.get('breakTypes.firstObject'));
        shiftBreak.set('place', place);
        shiftBreak.set('provider', this.store.peekAll('provider').firstObject);
        this.set('address', place);
        record.set('isForceDirty', true);
        Ember.get(record, valuePath).pushObject(shiftBreak);
      },

      onbreakTypeChange(record, valuePath, value) {
        record.set(valuePath, value);
        this.record.set(valuePath, value);
        this.set('record.isForceDirty', true);
      },

      onRemoveBreak(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.get('onRemove')(value, options);
      },

      onAddressSelected(record, valuePath, value) {
        this.convertTTAddressToModel(record, value);
      },

      onAliasAddressSelected(record, valuePath, value) {
        this.convertTTAliasAddressToModel(record, value);
      },

      onPickOnMap(record) {
        const activeGeocode = this.get('geocode.activeGeocode');

        if (activeGeocode) {
          this.disableAddressSearchOnMap(record);
        } else {
          this.enableAddressSearchOnMap(record);
        }
      },

      handleKeydown(record, valuePath, dropdown, e) {
        if (e.keyCode !== 13) {
          return;
        }

        const text = e.target.value;
        const options = {
          record,
          valuePath: 'streetAddress'
        }; // address has been forced without geocodes
        // this.set('record.isForceDirty', true);

        this.clearAddressModel(record); // this.set('record.isForceDirty', true);

        this.get('onChange')(text, options);
        this.set('addressRecord', record);
        this.validateAddresses();
      }

    }
  });

  _exports.default = _default;
});