define("adept-iq/pods/components/timeline-picker/time-picker/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['time-picker-wrapper'],
    classNameBindings: ['isValid', 'isInvalid', 'isDirty'],
    timePickerOptions: Ember.computed('startDate', 'endDate', 'editingDateProperty', function () {
      return {
        dropdown: true,
        interval: 30,
        scrollbar: true,
        defaultTime: this.get(this.get('editingDateProperty'))
      };
    }),

    didInsertElement() {
      this._super(...arguments);

      if (!this.get('isFullAccess')) {
        this.$().find('.ember-timepicker').prop('disabled', true);
      }
    },

    isValid: Ember.computed('fieldValidityObject.{startDate,endDate}', function () {
      const fieldValidityObj = this.get('fieldValidityObject');
      const editingDateProperty = this.get('editingDateProperty');

      if (fieldValidityObj[editingDateProperty] === false) {
        return false;
      }

      return true;
    }),
    startDate: null,
    endDate: null,
    editingDateProperty: null,
    fieldValidityObject: null,
    timeString: null,
    actions: {
      onTimeChange(selectedTime) {
        const propertyName = this.get('editingDateProperty');
        const editingDate = this.get(propertyName);
        const selectedDate = new Date(editingDate.getTime());
        selectedDate.setHours(selectedTime.getHours());
        selectedDate.setMinutes(selectedTime.getMinutes());

        if (propertyName === 'startDate') {
          selectedDate.setSeconds(0);
          selectedDate.setMilliseconds(0);
        } else {
          selectedDate.setSeconds(59);
          selectedDate.setMilliseconds(999);
        } // do validation here!


        if (editingDate.getTime() === selectedDate.getTime()) {
          // nothing changed
          this.set(`fieldValidityObject.${propertyName}`, true);
        } else if (propertyName === 'startDate') {
          const endDate = this.get('endDate');

          if (selectedDate > endDate) {
            this.set(`fieldValidityObject.${propertyName}`, false);
            this.set('startDate', selectedDate);
          } else {
            this.set(`fieldValidityObject.${propertyName}`, true);
            this.set('startDate', selectedDate);
          }
        } else {
          // endDate being set
          const startDate = this.get('startDate');

          if (selectedDate < startDate) {
            this.set(`fieldValidityObject.${propertyName}`, false);
            this.set('endDate', selectedDate);
          } else {
            this.set(`fieldValidityObject.${propertyName}`, true);
            this.set('endDate', selectedDate);
          }
        }

        this.get('isValid');
        this.get('fieldValidityCheck')();
      },

      onLastHourButtonClick(dropdown) {
        dropdown.actions.close();
        const end = (0, _moment.default)().startOf('minute');
        const start = end.clone().subtract(1, 'hour');
        this.setDates(start.toDate(), end.toDate());
      },

      onNowButtonClick(dropdown) {
        dropdown.actions.close();
        const start = (0, _moment.default)();
        const end = start.clone().add(1, 'hour');
        this.setDates(start.toDate(), end.toDate());
      },

      onNextTwoHoursButtonClick(dropdown) {
        dropdown.actions.close();
        const start = (0, _moment.default)();
        const end = start.clone().add(2, 'hour');
        this.setDates(start.toDate(), end.toDate());
      },

      onDoneButtonClick(dropdown) {
        dropdown.actions.close();
      }

    }
  });

  _exports.default = _default;
});