define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/tooltip-user-cell", ["exports", "react", "react-dom", "lodash", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item", "adept-iq/utils/format-text-extension"], function (_exports, _react, _reactDom, _lodash, _cellItem, _formatTextExtension) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RenderInBody = void 0;

  class RenderInBody extends _react.default.PureComponent {
    constructor(props) {
      super(props);
      this.el = document.createElement('div');
      this.el.style.display = 'contents'; // The <div> is a necessary container for our content, but it should not affect our layout. Only works in some browsers, but generally doesn't matter since this is at the end anyway. Feel free to delete this line.
    }

    componentDidMount() {
      document.body.appendChild(this.el);
    }

    componentWillUnmount() {
      document.body.removeChild(this.el);
    }

    render() {
      return _reactDom.default.createPortal(this.props.children, this.el);
    }

  }

  _exports.RenderInBody = RenderInBody;

  class TooltipCell extends _cellItem.default {
    constructor(props) {
      super(props);
      this.state = {
        isHovering: false,
        positionX: 0,
        positionY: 0
      };
      this.handleMouseHover = this.handleMouseHover.bind(this);
    }

    handleMouseHover() {
      this.setState({
        positionX: window.pageXOffset + event.clientX,
        positionY: window.pageYOffset + event.clientY
      });
      this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
      const clonedState = { ...state
      };
      clonedState.isHovering = !state.isHovering;
      return clonedState;
    }

    render() {
      const {
        data,
        style,
        columnIndex,
        rowIndex,
        columnInfo,
        selectedRowIndex,
        selectedColumnIndex
      } = this.props;
      const {
        isHovering,
        positionX,
        positionY
      } = this.state;
      const toolTipStyles = {
        width: '200px',
        backgroundColor: '#fff',
        color: 'black',
        textAlign: 'center',
        borderRadius: '6px',
        padding: '5px 0',
        position: 'absolute',
        left: `${positionX}px`,
        top: `${positionY}px`,
        zIndex: 1000
      };
      const currentRowData = data[rowIndex - 1];
      const columnValue = '';
      const classNames = this.getStylesClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue);
      const valuePath = columnInfo ? columnInfo.valuePath : null;
      const toolTipPath = columnInfo ? columnInfo.toolTipPath : null;
      const dataField = valuePath ? this.getValues(currentRowData, valuePath) : [];
      const toolTipData = toolTipPath ? this.getValues(currentRowData, toolTipPath) : [];
      const length = toolTipData.filter(val => val.className === 'valueItem').length;
      let columnVal = '';

      if (valuePath && dataField.length > 0) {
        columnVal = dataField.firstObject.value;
      } else if (length > 1) {
        columnVal = `${length} ${this.props.columnInfo.cellDesc}`;
      } else if (length === 1) {
        columnVal = toolTipData.firstObject.value;
      }

      return _react.default.createElement("span", {
        role: "none",
        className: classNames,
        style: style,
        onMouseEnter: this.handleMouseHover,
        onMouseLeave: this.handleMouseHover
      }, _react.default.createElement("span", {
        "class": "column-label"
      }, columnVal), isHovering && length > 0 && _react.default.createElement(RenderInBody, null, _react.default.createElement("div", {
        style: toolTipStyles
      }, this.renderToolTipContent(toolTipData))));
    }

    renderToolTipContent(values) {
      const toolTipStylesULStyle = {
        maxHeight: '200px',
        overflowY: 'auto',
        padding: 0,
        listStyleType: 'none'
      };
      return _react.default.createElement("ul", {
        style: toolTipStylesULStyle
      }, values.map(function (item, key) {
        return _react.default.createElement("li", {
          key: key,
          style: {
            padding: '7px'
          }
        }, item.value);
      }));
    }

    getValues(currentRowData, field) {
      const values = [];
      const details = currentRowData[field];

      if (!_lodash.default.isEmpty(details)) {
        // Split the details string into individual items
        const items = details.split(', ');
        items.forEach(function (detail) {
          const text = (0, _formatTextExtension.formatTextExtension)('valueItem', detail);
          values.push(text);
        });
      }

      return values;
    }

  }

  _exports.default = TooltipCell;
});