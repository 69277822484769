define("adept-iq/serializers/perm-sso-user", ["exports", "adept-iq/serializers/application", "lodash"], function (_exports, _application, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      const {
        roleNames
      } = resourceHash.attributes;

      if (roleNames) {
        resourceHash.attributes.roleNames = roleNames.map(item => {
          if (typeof item === 'object') return item;
          return {
            roleName: item.toString(),
            displayName: Ember.String.capitalize(item.toString())
          };
        });
      }

      const relationships = resourceHash.relationships || {};

      if (resourceHash.attributes.roles) {
        const ssoRoles = resourceHash.attributes.roles.map(role => {
          return {
            type: 'perm-sso-role',
            id: role.id
          };
        });
        relationships.ssoRoles = {
          data: ssoRoles
        };
      }

      resourceHash.relationships = relationships;
      return this._super(...arguments);
    },

    normalizeQueryResponse(store, clazz, payload) {
      const userPayload = []; // eslint-disable-next-line no-param-reassign

      payload = {
        data: payload
      };
      payload.data.forEach(user => {
        if (user.status !== 'delete' && user.status !== 'deleted') {
          userPayload.push({
            id: user.userId,
            type: 'sso-user',
            attributes: {
              userId: user.userId,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              status: user.status,
              loginDetails: _lodash.default.map(user.basicLogin, log => {
                return {
                  id: log.id,
                  userName: log.username,
                  userId: log.userId,
                  password: log.passwordHash,
                  passwordUpdatedAt: log.passwordUpdatedAt
                };
              }),
              roles: _lodash.default.map(user.UserRoleRoles, role => {
                return {
                  id: role.id,
                  roleName: role.roleName,
                  displayName: role.displayName
                };
              }),
              providers: _lodash.default.map(user.providers, p => {
                return {
                  id: p.provider,
                  providerName: p.provider
                };
              })
            }
          });
        }
      });
      payload.data = userPayload;
      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'sso-user') {
        return 'perm-sso-user';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});