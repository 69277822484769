define("adept-iq/models/trip-stop", ["exports", "ember-data", "adept-iq/utils/unwrapProxy"], function (_exports, _emberData, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    plannedDistanceToNext: attr('string'),
    // in miles
    plannedTravelTimeToNext: attr('string'),
    // in minutes
    plannedEta: attr('date'),
    plannedSlackTime: attr('number'),
    plannedRouteOrdinal: attr('number'),
    lat: attr('number'),
    lng: attr('number'),
    tripId: attr('string'),
    vehiclePk: attr('string'),
    tripPk: attr('string'),
    routeId: attr('string'),
    stopType: attr('string'),
    routeName: attr('string'),
    dwell: attr('number'),
    pickPassengerCount: attr('number'),
    dropPassengerCount: attr('number'),
    currentPassengerCount: attr('number'),
    address: attr('string'),
    pickTripStop: belongsTo('trip', {
      inverse: 'pick'
    }),
    dropTripStop: belongsTo('trip', {
      inverse: 'drop'
    }),
    type: Ember.computed.readOnly('stopType'),
    travelNeeds: Ember.computed.readOnly('trip.segment.leg.legTravelNeeds'),
    notes: Ember.computed.alias('segmentStop.notes'),
    city: Ember.computed.alias('segmentStop.place.address.locality'),
    scheduleOrdinal: attr('number'),
    eta: attr('string'),
    scheduleEta: attr('string'),
    // populated from stops-widget iqux-service
    segmentStopPhoneNumbers: attr('object'),
    formattedSegmentStopPhoneNumbers: attr('object'),
    scheduleStopStatus: attr('string'),
    trip: Ember.computed('pickTripStop', 'dropTripStop', function () {
      const trip = (0, _unwrapProxy.unwrapProxy)(this.get('pickTripStop'));
      return trip ? trip : (0, _unwrapProxy.unwrapProxy)(this.get('dropTripStop'));
    }),
    mapId: Ember.computed('stopType', function () {
      const stopType = this.get('stopType');
      return stopType;
    }),
    isPick: Ember.computed('stopType', function () {
      return this.get('stopType') === 'pick';
    }),
    route: Ember.computed('trip', function () {
      const route = this.get('trip.route');
      return route;
    }),
    isLocked: Ember.computed('route.isLocked', function () {
      return this.get('route.isLocked');
    }),
    lockedBy: Ember.computed('route.lockedBy', function () {
      return this.get('route.lockedBy');
    }),
    lockedId: Ember.computed('isLocked', function () {
      return this.get('route.id');
    }),
    isLockedByOtherUser: Ember.computed('route.isLockedByOtherUser', function () {
      return this.get('route.isLockedByOtherUser');
    }),
    computedStatus: Ember.computed('status', function () {
      const status = this.get('status') !== '' ? this.get('status') : null;
      return status;
    }),
    computedSlack: Ember.computed('slack', function () {
      const slack = this.get('slack') !== '' ? this.get('slack') : null;
      return slack;
    })
  });

  _exports.default = _default;
});