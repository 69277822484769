define("adept-iq/pods/components/workspace-navbar-reconcile-csv-only/component", ["exports", "moment", "adept-iq/config/mapped-permIds", "adept-iq/pods/components/workspace-navbar-reconcile-csv-only/config", "papaparse"], function (_exports, _moment, _mappedPermIds, _config, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['workspace-navbar'],
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    reconcileLambda: Ember.inject.service(),
    notifications: Ember.inject.service(),
    dwhService: Ember.inject.service(),
    config: null,
    timeFormat: 'm-d-Y',
    submitBtnClicked: false,
    uploadBinary: null,
    uploadInput: null,
    reconcileAction: null,
    isReconExport: Ember.computed.equal('reconcileAction', 'export'),
    isReconExportReady: Ember.computed.and('isReconExport', 'selectedDateTimeRage.date'),
    isReconImport: Ember.computed.equal('reconcileAction', 'import'),
    isReconImportReady: Ember.computed.and('isReconImport', 'selectedDateTimeRage.date'),
    isReconImportFileReady: Ember.computed.and('isReconImportReady', 'uploadBinary'),

    init() {
      this._super(...arguments);

      this.set('reconcileAction', 'export');
      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().subtract(1, 'day').format('MM-DD-YYYY'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(6, 'months').format('MM-DD-YYYY')
      });
      this.set('config', _config.default);
      this.configurePermission();
    },

    configurePermission() {
      Ember.set(this, 'isAddNewRouteAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.addNewRouteRecon, null));
      Ember.set(this, 'isCloseDateAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.closeEditDateRecon, null));
      Ember.set(this, 'isReopenDateAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.reopenEditDateRecon, null));
      Ember.set(this, 'isSearchByClientAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByClientRecon, null));
      Ember.set(this, 'isSearchByDriverAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByDriverRecon, null));
      Ember.set(this, 'isSearchByVehicleAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByVehicleRecon, null));
      Ember.set(this, 'isSearchByAddressAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByAddrRecon, null));
      Ember.set(this, 'isSearchByTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByTripRecon, null));
    },

    didInsertElement() {
      this._super(...arguments);
    },

    async exportToCsv() {
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const date = this.get('selectedDateTimeRage.date');
        const dateString = (0, _moment.default)(date).format('YYYY-MM-DD');
        const data = await this.get('dwhService').fetchDwhStops(dateString);
        const columns = this.get('config.columns').map(c => c.id);
        const emptyObj = columns.reduce((obj, c) => {
          obj[c] = '';
          return obj;
        }, {});
        const dataWithBlankColumns = data.map(d => {
          return { ...emptyObj,
            ...d
          };
        });

        const csv = _papaparse.default.unparse(dataWithBlankColumns, {
          quotes: true,
          quoteChar: '"',
          escapeChar: '"',
          escapeFormulae: true,
          columns
        });

        const link = document.createElement('a');
        const filename = `${dateString}.csv`;
        const blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8;'
        });
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await this.createUserReconExportLog(date);
      } catch (e) {
        this.get('notifications').warning(e);
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    async importFromCsv() {
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const date = this.get('selectedDateTimeRage.date');
        const dateString = (0, _moment.default)(date).format('YYYY-MM-DD');
        const uploadBinary = this.get('uploadBinary');
        await this.get('dwhService').patchDwhStops(dateString, uploadBinary);
        await this.createUserReconImportLog();

        if (this.get('uploadInput')) {
          this.get('uploadInput').value = null;
          this.set('uploadBinary', null);
        }

        this.get('notifications').success('UPLOAD SUCCESS');
      } catch (e) {
        this.get('notifications').warning(e);
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    async createUserReconExportLog(date) {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const dateString = (0, _moment.default)(date).format('MM/DD/YYYY');
      const eventLog = {
        type: 'event',
        attributes: {
          source: 'webApp',
          eventType: 'UE',
          actionType: 'genericLogging',
          userId: this.get('session.data.authenticated.userId'),
          receivedAt: (0, _moment.default)().toDate(),
          eventLog: {
            genericLogging: [{
              activity: 'Reconcile Export',
              loggingType: 'user',
              details: `Exported stops to CSV for date ${dateString}.`
            }]
          }
        }
      };
      return activityLogAdapter.createEventActivity(eventLog);
    },

    async createUserReconImportLog() {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const eventLog = {
        type: 'event',
        attributes: {
          source: 'webApp',
          eventType: 'UE',
          actionType: 'genericLogging',
          userId: this.get('session.data.authenticated.userId'),
          receivedAt: (0, _moment.default)().toDate(),
          eventLog: {
            genericLogging: [{
              activity: 'Reconcile Import',
              loggingType: 'user',
              details: 'Imported stops CSV.'
            }]
          }
        }
      };
      return activityLogAdapter.createEventActivity(eventLog);
    },

    _arrayBufferToBase64(bytes) {
      const uint8Array = new Uint8Array(bytes);
      const data = uint8Array.reduce((acc, i) => {
        const _acc = acc + String.fromCharCode.apply(null, [i]);

        return _acc;
      }, '');
      return data;
    },

    actions: {
      onRadioButtonClick(val) {
        this.set('reconcileAction', val);
      },

      resetFile(event) {
        this.set('uploadBinary', null);
        event.target.value = null;
      },

      readFile(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        this.set('uploadInput', event.target);
        let csvData;

        reader.onload = () => {
          csvData = reader.result;

          const binaryString = this._arrayBufferToBase64(csvData);

          this.set('uploadBinary', binaryString);
        };

        if (file) {
          reader.readAsArrayBuffer(file);
        }
      },

      upload() {
        this.importFromCsv();
      },

      download() {
        this.exportToCsv();
      },

      onChangeTripDate(valuePath, value) {
        const momentTime = value && value.length > 0 ? (0, _moment.default)(value[0]) : null;
        const formattedDate = momentTime && momentTime.isValid() ? momentTime.toISOString() : null;
        this.set(valuePath, formattedDate);
      },

      reopenDatesClick() {
        this.get('workspace').pushState('reconcileOpenDates', {});
      },

      closeDatesClick() {
        this.get('workspace').pushState('reconcileCloseDates', {});
      }

    }
  });

  _exports.default = _default;
});