define("adept-iq/models/rider-eligibility", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    from: attr('date', {
      defaultValue: null
    }),
    to: attr('date', {
      defaultValue: null
    }),
    isSoftDeleted: attr('boolean', {
      defaultValue: false
    }),
    // the 2 attributes below are used only inside IQUX as a in-memory record of the original eligibility category and eligibility condition for undo operations
    origEligCateName: attr('string'),
    origEligCondName: attr('string'),
    rider: belongsTo('rider'),
    eligibilityCategory: belongsTo('eligibility-category'),
    eligibilityCondition: belongsTo('eligibility-condition'),
    formattedFrom: Ember.computed('from', function () {
      const from = (0, _moment.default)(this.get('from'));
      return from.format('MM/DD/YYYY');
    }),
    formattedTo: Ember.computed('to', function () {
      const to = (0, _moment.default)(this.get('to'));
      return to.format('MM/DD/YYYY');
    }),
    isConditional: Ember.computed('eligibilityCategory', function () {
      const eligibilityCategory = this.get('eligibilityCategory');
      return eligibilityCategory.get('id') === 'conditional';
    }),
    checkADABoundary: Ember.computed('eligibilityCategory', function () {
      const eligibilityCategory = this.get('eligibilityCategory');
      const checkADABoundaryData = eligibilityCategory.get('data') || {};
      const isCheckADABoundary = Ember.isPresent(checkADABoundaryData.checkADABoundary) ? checkADABoundaryData.checkADABoundary.toUpperCase() === 'YES' : false;
      return isCheckADABoundary;
    })
  });

  _exports.default = _default;
});