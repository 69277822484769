define("adept-iq/pods/components/iq-widgets/passengers-widget/scheduleConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/passengers-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'rider',
    defaultSortId: 'riderId',
    defaultSortAsc: false,
    title: 'Passengers',
    // ember-react-widget properties
    widgetName: 'riderWidget',
    initialLimit: 5000,
    paginationLimit: 20,
    maxLimitByRouteSelection: 5,
    maxLimitWithoutRouteAssociation: 20,
    selectedRowLimit: 20,
    columns: [{
      id: 'riderId',
      index: 2,
      type: 'uuid',
      isKey: true,
      label: 'ID',
      valuePath: 'riderId',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 30
    }, {
      id: 'firstName',
      index: 0,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 60,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'lastName',
      index: 1,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 75,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'fullPhoneNumber',
      index: 3,
      type: 'enum',
      label: 'Phone Numbers',
      valuePath: 'riderPhoneNumber',
      cellDesc: 'Phone Numbers',
      editable: false,
      hidden: true,
      defaultWidth: 90
    }, {
      id: 'dateOfBirth',
      index: 4,
      type: 'date',
      label: 'Date Of Birth',
      valuePath: 'dateOfBirth',
      editable: true,
      searchable: true,
      hidden: true,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'primaryAddress',
      index: 5,
      type: 'enum',
      label: 'Primary Address',
      valuePath: 'formattedPrimaryAddresses',
      cellDesc: 'Addresses',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 120
    }, {
      id: 'travelNeeds',
      index: 6,
      type: 'enum',
      label: 'Travel Needs',
      valuePath: 'travelNeeds',
      cellDesc: 'Travel Needs',
      editable: false,
      hidden: true,
      cellItem: 'TravelNeedsCell'
    }, {
      id: 'eligibilities',
      index: 8,
      type: 'enum-date',
      label: 'Eligibility',
      valuePath: 'formattedRiderEligibilities',
      alternateValuePath: 'riderEligibilityDates',
      cellDesc: 'Eligibilities',
      format: 'MM/DD/YYYY',
      editable: false,
      hidden: true
    }, {
      id: 'notes',
      index: 9,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      editable: true,
      hidden: true,
      searchable: true
    }, {
      id: 'middleName',
      index: 10,
      type: 'text',
      label: 'Middle Name',
      valuePath: 'middleName',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 75,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'exist',
          field: 'trips',
          valuePath: 'id'
        }]
      },
      routeWidget: {
        links: [{
          type: 'exist',
          field: 'scheduleRoutes',
          valuePath: 'routeId'
        }]
      },
      stopWidget: {
        links: [{
          type: 'exist',
          field: 'trips',
          valuePath: 'tripPk'
        }]
      }
    },
    excluded: {
      routeWidget: {
        conflicts: [{
          widget: 'tripWidget',
          srcField: 'routeId',
          targetField: 'routeId'
        }, {
          widget: 'stopWidget',
          srcField: 'routeId',
          targetField: 'routeId'
        }]
      }
    }
  };
  _exports.default = _default;
});