define("adept-iq/pods/components/dropdown-provider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ACTIVE_STATUS = 'active';
  const UNASSIGNED_PROVIDER = 'UNASSIGNED';

  var _default = Ember.Component.extend({
    classNames: ['dropdown-provider'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    user: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    isEnable: Ember.computed('isEnabled', 'user', 'workspace.dashboardModel', function () {
      const isRoadSupEnable = this.get('user').isRoadSupEnable();
      const isPermissionWorkspace = this.get('workspace.isPermissionsDashboard');
      return !isRoadSupEnable && !isPermissionWorkspace;
    }),
    isEnabled: Ember.computed.oneWay('isEnable'),
    title: 'All Providers',
    isOpen: false,
    isOpened: Ember.computed.oneWay('isOpen'),
    providersList: Ember.computed.alias('workspaceContext.providersList'),
    disabledProvider: null,
    allProviders: Ember.computed.alias('workspaceContext._allProviders'),
    providerNames: Ember.computed.alias('workspaceContext.providerNames'),
    // disable all provider checkboxes
    hasOneProvider: Ember.computed.lte('providersList.length', 1),

    resetDisableProvider() {
      const disabledProvider = this.get('disabledProvider');

      if (disabledProvider) {
        disabledProvider.set('isDisabled', false);
        this.set('disabledProvider', null);
      }
    },

    updateTitle: Ember.observer('providersList', function () {
      const results = this.get('providersList').filterBy('isChecked', true);
      const workspaceContext = this.get('workspaceContext');
      const userCanSeeAll = this.get('providerNames').includes('ALL') || false;

      if (results.length === 1) {
        this.set('title', results[0].providerName);
        Ember.set(results[0], 'isDisabled', true);
        this.set('disabledProvider', results[0]);

        if (results.length === this.providersList.length && userCanSeeAll) {
          workspaceContext.set('_allProviders', true);
        } else {
          workspaceContext.set('_allProviders', false);
        }
      } else if (results.length < this.providersList.length && results.length > 1) {
        this.set('title', 'Multiple');
        workspaceContext.set('_allProviders', false);
        this.resetDisableProvider();
      } else if (results.length === this.providersList.length) {
        this.set('title', 'All Providers');
        workspaceContext.set('_allProviders', true);
        this.resetDisableProvider();
      }
    }),

    //NYAAR-20043. This function to trigger a refresh of the All Providers drop down, when a provider details are changed or new provider added or if a provider is deleted.
    setProviderChanges() {
      const providersList = this.get('providersList');
      const filteredProviders = [];
      const allProviders = this.get('store').peekAll('provider');
      providersList.forEach(provider => {
        const currentProviderId = provider.get('id');

        if (currentProviderId) {
          const providerRecord = currentProviderId === UNASSIGNED_PROVIDER ? provider : this.get('store').peekRecord('provider', currentProviderId);

          if (providerRecord && providerRecord.get('userCanAccessProvider') && (providerRecord.get('status') && provider.get('status').toLowerCase() === ACTIVE_STATUS || !provider.get('status'))) {
            filteredProviders.pushObject(provider);
          }
        }
      });
      allProviders.forEach(provider => {
        const providerId = provider.get('id');
        const providerData = filteredProviders.findBy('id', providerId);

        if (!providerData && provider.get('userCanAccessProvider') && provider.get('status') && provider.get('status').toLowerCase() === ACTIVE_STATUS) {
          Ember.set(provider, 'isChecked', true);
          filteredProviders.pushObject(provider);
        }
      });
      this.set('providersList', filteredProviders);
    },

    async setupProviderList() {
      const providers = this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider') && (provider.get('status') && provider.get('status').toLowerCase() === ACTIVE_STATUS || !provider.get('status'));
      });
      providers.forEach(provider => provider.set('isChecked', true)); //add unassigned Provider to the list in case user has access

      const userProviders = this.get('session.data.authenticated.tokenInfo.providerNames') || [];
      const canAccessUnassigned = userProviders.some(provider => {
        return provider === 'ALL' || provider === UNASSIGNED_PROVIDER;
      });

      if (canAccessUnassigned) {
        const unassignedProvider = Ember.Object.create({
          id: UNASSIGNED_PROVIDER,
          name: UNASSIGNED_PROVIDER,
          isChecked: true,
          isDisabled: false,
          isNew: false,
          status: 'Active',
          isInActive: false,
          providerTypeName: 'dedicated_carrier',
          isBrokerProviderType: false,
          providerName: UNASSIGNED_PROVIDER,
          userCanAccessProvider: canAccessUnassigned
        });
        providers.push(unassignedProvider);
      }

      this.set('providersList', providers);
    },

    init() {
      this._super(...arguments);

      const workspaceContext = this.get('workspaceContext');
      workspaceContext.set('_allProviders', true);
      this.get('store').findAll('provider').then(() => {
        this.setupProviderList();
        this.updateTitle();
      }).then(() => {
        const providersList = this.get('providersList');
        providersList.forEach(provider => {
          Ember.set(provider, 'isChecked', true);
        });
      });
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    actions: {
      onToggleSelect() {
        this.toggleSelect();
      },

      onOpen() {
        this.toggleSelect();
        this.setProviderChanges();
      },

      onClose() {
        this.toggleSelect();
      },

      async onClickCheckAll(event) {
        const providersList = this.get('providersList');
        const topActiveContext = this.get('activeContext.topActiveContext');
        const activeContextNodes = topActiveContext.get('nodes') || [];

        if (event.target.checked) {
          providersList.forEach(provider => {
            Ember.set(provider, 'isChecked', event.target.checked);
          });
        } else {
          providersList.forEach((provider, index) => {
            if (index === 0) {
              // keep first provider checked.
              Ember.set(provider, 'isChecked', !event.target.checked);
            } else {
              Ember.set(provider, 'isChecked', event.target.checked);
            }
          });
        }

        this.updateTitle();
        this.get('workspaceContext').setSelectedProviders();
        this.get('widgetService').globalFilterChanged();
        topActiveContext.clearCheckedItems();
        activeContextNodes.reduce((obj, node) => {
          this.get('workspaceContext').trigger('change', [node.modelName]);
        });
      },

      async onClickCheckBox(provider, event) {
        const selectedProvider = provider;
        const topActiveContext = this.get('activeContext.topActiveContext');
        const activeContextNodes = topActiveContext.get('nodes') || [];
        Ember.set(selectedProvider, 'isChecked', event.target.checked);
        this.updateTitle();
        this.get('workspaceContext').setSelectedProviders();
        this.get('widgetService').globalFilterChanged();
        topActiveContext.clearCheckedItems();
        activeContextNodes.reduce((obj, node) => {
          this.get('workspaceContext').trigger('change', [node.modelName]);
        });
      }

    }
  });

  _exports.default = _default;
});