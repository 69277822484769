define("adept-iq/services/geocode", ["exports", "adept-iq/config/environment", "adept-iq/utils/zone-validation", "adept-iq/config/api-urls", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "adept-iq/pods/components/iq-widgets/map-widget/config/marker"], function (_exports, _environment, _zoneValidation, _apiUrls, _unwrapProxy, _polyline, _marker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;
  const {
    benomad
  } = _apiUrls.API;
  const SPEED_REGION_MARKER_TYPE = 'speed-region';

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    map: Ember.inject.service(),
    activeGeocode: false,
    activeMarker: null,
    activePickMarker: null,
    activeDropMarker: null,
    activeSpeedRegion: null,
    oldPickMarker: null,
    oldDropMarker: null,
    oldSpeedRegionMarker: null,
    oldTripPolyline: null,
    activeTripPolyline: null,
    activeTripArrow: null,
    oldMarker: null,
    markerType: '',
    latlng: null,
    address: null,
    zoom: 12,
    currentRecord: null,
    geo: null,
    isInvalidAddress: false,
    isGeoNodeResultsFetched: false,

    init() {
      this._super(...arguments);
    },

    addressSearchResults(result) {
      const record = this.get('currentRecord.locations.firstObject');
      const markerType = this.get('markerType');
      const bndRes = result.BND;

      if (bndRes.Elements.count > 0) {
        const bndAddressRes = bndRes.Elements.Element.firstObject;
        const latLng = {
          lon: bndAddressRes.Coordinate.x,
          lat: bndAddressRes.Coordinate.y
        };
        const markerOptions = _marker.default[markerType];
        this.set('latlng', {
          lat: latLng.lat,
          lng: latLng.lon
        });
        const tomtomMarker = new bemap.Marker(this.get('latlng'), markerOptions);

        if (markerType === 'pick') {
          if (this.get('activePickMarker')) {
            this.set('oldPickMarker', this.get('activePickMarker'));
          }

          this.set('activePickMarker', tomtomMarker);
        } else if (markerType === 'drop') {
          if (this.get('activeDropMarker')) {
            this.set('oldDropMarker', this.get('activeDropMarker'));
          }

          this.set('activeDropMarker', tomtomMarker);
        } else if (markerType === SPEED_REGION_MARKER_TYPE) {
          if (this.get('activeSpeedRegionMarker')) {
            this.set('oldSpeedRegionMarker', this.get('activeSpeedRegionMarker'));
          }

          this.set('activeSpeedRegionMarker', tomtomMarker);
        } else {
          this.set('oldMarker', this.get('activeMarker'));
          this.set('activeMarker', tomtomMarker);
        }
      } else if (!Ember.isNone(record) && record.lat !== 0 && record.lng !== 0) {
        this.set('latlng', {
          lat: record.lat,
          lng: record.lng
        });
        const tomtomMarker = new bemap.Marker(this.get('latlng'));

        if (markerType === 'pick') {
          if (this.get('activePickMarker')) {
            this.set('oldPickMarker', this.get('activePickMarker'));
          }

          this.set('activePickMarker', tomtomMarker);
        } else if (markerType === 'drop') {
          if (this.get('activeDropMarker')) {
            this.set('oldDropMarker', this.get('activeDropMarker'));
          }

          this.set('activeDropMarker', tomtomMarker);
        } else if (markerType === SPEED_REGION_MARKER_TYPE) {
          if (this.get('activeSpeedRegionMarker')) {
            this.set('oldSpeedRegionMarker', this.get('activeSpeedRegionMarker'));
          }

          this.set('activeSpeedRegionMarker', tomtomMarker);
        } else {
          this.set('oldMarker', this.get('activeMarker'));
          this.set('activeMarker', tomtomMarker);
        }
      }

      if (this.get('activePickMarker') && this.get('activeDropMarker')) {
        const tripPoints = [[this.get('activeDropMarker._latlng.lat'), this.get('activeDropMarker._latlng.lng')], [this.get('activePickMarker._latlng.lat'), this.get('activePickMarker._latlng.lng')]];
        const polyLineOptions = _polyline.default.dotted;
        /*eslint-enable */

        const tripPolyline = window.L.polyline(tripPoints, {
          style: polyLineOptions
        });

        if (this.get('activeTripPolyline')) {
          this.set('oldTripPolyline', this.get('activeTripPolyline'));
        }

        if (this.get('activeTripArrow')) {
          this.set('oldTripArrow', this.get('activeTripArrow'));
        }

        this.set('activeTripPolyline', tripPolyline);
      }
    },

    findLocationForRecord(record) {
      const unwrappedRecord = (0, _unwrapProxy.unwrapProxy)(record);

      if (Ember.isPresent(unwrappedRecord)) {
        this.set('currentRecord', record);
        const country = record.get('country');
        const region = record.get('region');
        const postalCode = record.get('postalCode');
        const streetAddress = record.get('streetAddress');

        if (!Ember.isNone(country) || !Ember.isNone(region) || !Ember.isNone(postalCode) || !Ember.isNone(postalCode)) {
          const url = `${benomad.host}/bgis/bnd?version=${_environment.default.benomad.version}&geoserver=${_environment.default.benomad.geoserver}&action=geocoding&format=${_environment.default.benomad.format}&searchType=${_environment.default.benomad.searchType}&country=${country}&city=${region}&postalCode=${postalCode}&street=${streetAddress}&language=${_environment.default.benomad.languages[1]}&maxResult=10`;
          return this.get('ajax').request(url, {
            method: 'GET'
          }).then(this.addressSearchResults.bind(this));
        }
      }
    },

    activateGeocode(activeGeocode, record, markerType) {
      this.findLocationForRecord(record);
      this.set('activeGeo', activeGeocode);
      this.set('markerType', markerType);
    },

    deactivateGeocode() {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');

      if (this.get('activeMarker')) {
        this.get('activeMarker').remove();
        this.set('activeMarker', null);
      }

      if (this.get('oldMarker')) {
        this.get('oldMarker').remove();
        this.set('oldMarker', null);
      }

      if (this.get('oldPickMarker')) {
        this.get('oldPickMarker').remove();
        this.set('oldPickMarker', null);
      }

      if (this.get('oldDropMarker')) {
        this.get('oldDropMarker').remove();
        this.set('oldDropMarker', null);
      }

      if (this.get('activePickMarker')) {
        this.get('activePickMarker').remove();
        this.set('activePickMarker', null);
      }

      if (this.get('activeDropMarker')) {
        this.get('activeDropMarker').remove();
        this.set('activeDropMarker', null);
      }

      if (this.get('oldTripPolyline')) {
        this.get('oldTripPolyline').remove();
        this.set('oldTripPolyline', null);
      }

      if (this.get('activeTripPolyline')) {
        this.get('activeTripPolyline').remove();
        this.set('activeTripPolyline', null);
      }

      if (this.get('activeTripArrow')) {
        this.get('activeTripArrow').remove();
        this.set('activeTripArrow', null);
      }

      if (this.get('activeSpeedRegionMarker')) {
        this.get('activeSpeedRegionMarker').remove();
        this.set('activeSpeedRegionMarker', null);
      }

      if (this.get('oldSpeedRegionMarker')) {
        this.get('oldSpeedRegionMarker').remove();
        this.set('oldSpeedRegionMarker', null);
      }

      this.set('activeGeocode', false);
      this.set('address', null);
      this.set('markerType', '');
    },

    reverseGeocodeResult(result) {
      const latlng = this.get('latlng');
      const bndRes = result.BND;
      const boundingBoxExtent = bndRes.Extent;
      const bndAddressRes = bndRes.Elements.Element.firstObject; // benomad postalCode consist of cityCode and postal code V6L1C6/V6L. Sometimes postal code placed after city code

      const [pos1, pos2] = bndAddressRes.PostalAddress.PostalCode.trim().split('/');
      let postalCode;

      if (pos1.length > 3) {
        postalCode = pos1;
      } else if (Ember.isPresent(pos2)) {
        postalCode = pos2;
      } else {
        postalCode = pos1;
      }

      const [strPos1, strPos2] = bndAddressRes.PostalAddress.Street.trim().split(', ');
      const street = strPos2 ? strPos2 : strPos1;
      const streetNameAndNumber = `${bndAddressRes.PostalAddress.StreetNumber} ${street}`;
      const city = bndAddressRes.PostalAddress.City || bndAddressRes.PostalAddress.State;
      const country = bndAddressRes.PostalAddress.Country;
      const freeformAddress = `${streetNameAndNumber}, ${city}, ${postalCode}, ${country}`;
      const res = {
        address: {
          boundingBox: {
            northEast: `${boundingBoxExtent.maxX},${boundingBoxExtent.maxY}`,
            southWest: `${boundingBoxExtent.minX},${boundingBoxExtent.minY}`
          },
          buildingNumber: bndAddressRes.PostalAddress.StreetNumber,
          country: bndAddressRes.PostalAddress.Country,
          countryCodeISO3: bndAddressRes.PostalAddress.CountryCode,
          countrySecondarySubdivision: bndAddressRes.PostalAddress.County,
          countrySubdivisionName: bndAddressRes.PostalAddress.County,
          freeformAddress: freeformAddress,
          localName: bndAddressRes.PostalAddress.County,
          municipality: bndAddressRes.PostalAddress.County,
          locality: bndAddressRes.PostalAddress.District,
          postalCode: postalCode,
          state: bndAddressRes.PostalAddress.State,
          city: city,
          street: street,
          streetName: street,
          streetNameAndNumber: streetNameAndNumber,
          streetNumber: bndAddressRes.PostalAddress.StreetNumber
        }
      };
      res.position = {
        lat: parseFloat(latlng.lat.toFixed(5)),
        lon: parseFloat(latlng.lng.toFixed(5))
      };
      this.set('address', res);
      return res;
    },

    async saveNewGeocode(latlng, address) {
      const markerType = this.get('markerType');
      const boundingBox = this.get('map.boundingBox');
      this.set('latlng', latlng); // if the point is outside the valid zone polygon
      // return error and exit

      if (!(0, _zoneValidation.insideZone)([latlng.lat, latlng.lng], boundingBox) && !this.get('isInvalidAddress')) {
        this.get('notifications').warning('Unable to save. Location must be within the agency service boundary.');
        return;
      }

      const pintPointIcon = {
        icon: new bemap.Icon({
          src: '/map-icons/icon-pinpoint.svg',
          anchorX: 0.50,
          anchorY: 1,
          height: 30,
          width: 30,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: 1
        }),
        keyboard: false,
        interactive: false
      };
      const coord = new bemap.Coordinate(latlng.lng, latlng.lat);
      const tomtomMarker = new bemap.Marker(coord, pintPointIcon);

      if (Ember.isNone(address)) {
        await this.reverseGeocode(latlng);
      } else {
        this.set('address', address);
      }

      if (markerType === 'pick') {
        this.set('oldPickMarker', this.get('activePickMarker'));
        this.set('activePickMarker', tomtomMarker);
      } else if (markerType === 'drop') {
        this.set('oldDropMarker', this.get('activeDropMarker'));
        this.set('activeDropMarker', tomtomMarker);
      } else if (markerType === SPEED_REGION_MARKER_TYPE) {
        this.set('oldSpeedRegionMarker', this.get('activeSpeedRegionMarker'));
        this.set('activeSpeedRegionMarker', tomtomMarker);
      } else {
        this.set('oldMarker', this.get('activeMarker'));
        this.set('activeMarker', tomtomMarker);
      }

      if (this.get('activePickMarker') && this.get('activeDropMarker')) {
        const tripPoints = [[this.get('activePickMarker._latlng.lat'), this.get('activePickMarker._latlng.lng')], [this.get('activeDropMarker._latlng.lat'), this.get('activeDropMarker._latlng.lng')]];
        const polyLineOptions = _polyline.default.dotted;
        const tripPolyline = window.L.polyline(tripPoints, {
          style: polyLineOptions
        });

        if (this.get('activeTripPolyline')) {
          this.set('oldTripPolyline', this.get('activeTripPolyline'));
        }

        if (this.get('activeTripArrow')) {
          this.set('oldTripArrow', this.get('activeTripArrow'));
        }

        this.set('activeTripPolyline', tripPolyline);
      }
    },

    // geonode is part of DDS' service used by the scheduling engine
    getGeonode(queryString) {
      this.set('isGeoNodeResultsFetched', false);
      const session = this.get('session');
      return this.get('ajax').request(`${_apiUrls.API.geocodeService.host}/geocode`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          query: queryString,
          resultCount: 1
        }
      }).then(results => {
        this.set('isGeoNodeResultsFetched', true);
        const result = results.data.find(object => {
          return !Ember.isNone(object.geonode);
        });
        return result;
      }).catch(() => {
        return;
      });
    },

    fuzzySearch(query) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const limit = options.limit || 1;
      const queryParams = this.parseGeoCodeQueryString(query);
      const url = `${benomad.host}/bgis/bnd?version=${_environment.default.benomad.version}&geoserver=${_environment.default.benomad.geoserver}&action=geocoding&format=${_environment.default.benomad.format}&maxResult=${limit}`;
      return this.get('ajax').request(url, {
        method: 'GET',
        data: queryParams
      }).then(this.geoCodingResult.bind(this, query));
    },

    async reverseGeocode(latlng) {
      const lat = parseFloat(latlng.lat.toFixed(5));
      const lng = parseFloat(latlng.lng.toFixed(5));
      this.set('latlng', latlng);
      const url = `${benomad.host}/bgis/bnd?version=${_environment.default.benomad.version}&geoserver=${_environment.default.benomad.geoserver}&action=revgeocoding&format=${_environment.default.benomad.format}&options=SKIP_EMPTY_STREETNAME&xy=${lng},${lat}&radius=${_environment.default.benomad.radius}&maxResult=10`;
      return this.get('ajax').request(url, {
        method: 'GET'
      }).then(this.reverseGeocodeResult.bind(this));
    },

    buildTravelTimeUrl() {
      let destinations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let benomadUrl = `${benomad.host}/bgis/bnd?version=${_environment.default.benomad.version}&geoserver=${_environment.default.benomad.geoserver}&action=routing&format=${_environment.default.benomad.format}`;
      destinations.forEach(dest => {
        benomadUrl = benomadUrl + `&xy=${dest.lng},${dest.lat}`;
      });
      return benomadUrl;
    },

    /**
     * retrive travel time from source addres to destination address
     * @param destinations
     * @returns {*}
     */
    retriveTravelTime(destinations) {
      const URL = this.buildTravelTimeUrl(destinations);
      return this.get('ajax').request(URL, {
        method: 'GET'
      }).then(data => {
        return {
          usedDestinations: data.BND.UsedDestinations,
          duration: data.BND.Routes.Route[0].Duration
        };
      });
    },

    geoCodingApi(term) {
      const bbox = this.get('map.bbox');
      const addressArray = term.split(',');
      const street = addressArray[0].trim();
      let city = '';

      if (addressArray.length > 1) {
        city = addressArray[1].trim();
      }

      const url = `${benomad.host}/bgis/bnd?version=${_environment.default.benomad.version}&geoserver=${_environment.default.benomad.geoserver}&action=geocoding&format=${_environment.default.benomad.format}&searchType=${_environment.default.benomad.searchTypePattern}&street=${street}&city=${city}&bbox=${bbox}&maxResult=10`;
      return this.get('ajax').request(url, {
        method: 'GET'
      }).then(this.geoCodingResult.bind(this, term));
    },

    geoCodingResult(searchTerm, result) {
      const site = this.get('map.site');
      const firstPart = Ember.isPresent(site) ? site.split('/')[0] : null;
      const countryCode = Ember.isPresent(firstPart) ? firstPart : 'USA';
      const bndRes = result.BND;

      if (bndRes.Elements.Element) {
        const results = bndRes.Elements.Element.filter(e => {
          if (e.PostalAddress.StreetNumber && e.PostalAddress.PostalCode && e.PostalAddress.CountryCode === countryCode) return true;
          return false;
        });
        return results.map(ele => {
          ele.PostalAddress.StreetNumber = this.getConvertedStreetNumber(ele.PostalAddress.StreetNumber, searchTerm); // benomad postalCode consist of cityCode and postal code V6L1C6/V6L. Sometimes postal code placed after city code

          const [pos1, pos2] = ele.PostalAddress.PostalCode.trim().split('/');
          const [strPos1, strPos2] = ele.PostalAddress.Street.trim().split(', ');
          const street = strPos2 ? strPos2 : strPos1;
          const streetNameAndNumber = `${ele.PostalAddress.StreetNumber} ${street}`;
          const city = ele.PostalAddress.City || ele.PostalAddress.State;
          const country = ele.PostalAddress.Country;
          let postalCode;

          if (pos1.length > 3) {
            postalCode = pos1;
          } else if (Ember.isPresent(pos2)) {
            postalCode = pos2;
          } else {
            postalCode = pos1;
          }

          const freeformAddress = `${streetNameAndNumber}, ${city}, ${postalCode}, ${country}`;
          return {
            address: {
              freeformAddress: freeformAddress,
              state: ele.PostalAddress.State,
              city: ele.PostalAddress.City,
              street: street,
              streetName: street,
              streetNameAndNumber: streetNameAndNumber,
              streetNumber: ele.PostalAddress.StreetNumber,
              localName: ele.PostalAddress.County,
              municipality: ele.PostalAddress.County,
              country: ele.PostalAddress.CountryCode,
              postalCode: postalCode
            },
            position: {
              lon: ele.Coordinate.x,
              lat: ele.Coordinate.y
            }
          };
        });
      }
    },

    parseGeoCodeQueryString(queryString) {
      const site = this.get('map.site');
      const bbox = this.get('map.bbox');
      const firstPart = Ember.isPresent(site) ? site.split('/')[0] : null;
      const countryCode = Ember.isPresent(firstPart) ? firstPart : 'USA';

      if (!Number.isNaN(Number(queryString))) {
        return {
          countryCode: countryCode,
          street: queryString,
          bbox: `${bbox}`,
          searchType: `${_environment.default.benomad.searchTypePattern}`
        };
      } // eslint-disable-next-line no-extra-parens


      const [street1, city, cityWithPostal, postal, country] = queryString.indexOf(',') !== -1 ? queryString.split(',') : ['', '']; // eslint-disable-next-line no-unused-vars

      const [cityCode, postalCode] = cityWithPostal ? cityWithPostal.trim().split(/\s+/) : ['', ''];
      const searchParams = {
        street: street1 ? `${street1.trim()},` : '',
        country: Ember.isNone(country) ? countryCode : country,
        city: !isNaN(city) ? '' : city.trim(),
        // eslint-disable-next-line no-nested-ternary
        postalCode: !isNaN(postalCode) ? postalCode : !isNaN(postal) ? postal.trim() : !isNaN(cityCode) ? cityCode : ''
      };
      const streetObj = Ember.isEmpty(searchParams.city) ? {
        street: queryString
      } : { ...searchParams
      };
      const queryParams = searchParams.street && searchParams.city && searchParams.postalCode ? { ...searchParams,
        searchType: `${_environment.default.benomad.searchType}`
      } : { ...streetObj,
        bbox: `${bbox}`,
        searchType: `${_environment.default.benomad.searchType}`,
        countryCode: searchParams.country
      };
      return queryParams;
    },

    /**
     * Reg- ( NYAAR-18554 ) get street Number with hypens like ( 39-27, 31-08 )
     * @param {string} streetNumber
     * @param {string} searchTerm
     * @returns {string}
     */
    getConvertedStreetNumber(streetNumber, searchTerm) {
      const streetNumberWithHypenated = searchTerm.split(',').firstObject.split(' ').firstObject;
      const searchTermStreetNumber = streetNumberWithHypenated.split('-').join('');

      if (searchTermStreetNumber === streetNumber) {
        return streetNumberWithHypenated;
      }

      return streetNumber;
    }

  });

  _exports.default = _default;
});