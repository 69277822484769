define("adept-iq/pods/components/side-drawer/trip-transfer/component", ["exports", "ember-light-table", "adept-iq/utils/unwrapProxy", "ember-concurrency", "adept-iq/pods/components/side-drawer/trip-transfer/config", "adept-iq/mixins/trip-transfer-reason", "moment", "adept-iq/classes/map-contexts/side-drawers/trip-transfer", "adept-iq/classes/impact-stop", "lodash", "adept-iq/config/mapped-permIds", "adept-iq/utils/vehicleCapacity", "adept-iq/config/placeholders"], function (_exports, _emberLightTable, _unwrapProxy, _emberConcurrency, _config, _tripTransferReason, _moment, _tripTransfer, _impactStop, _lodash, _mappedPermIds, _vehicleCapacity, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATUS_CODE = 500;

  var _default = Ember.Component.extend(_tripTransferReason.default, {
    tagName: '',
    map: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    coreEntityPopulator: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    // placeholders
    TIME_PLACEHOLDER: _placeholders.TIME_PLACEHOLDER,
    // passed by the side drawer container component
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    // component properties
    solutionRoutesTable: null,
    stopsTable: null,
    routeOverrideOptions: null,
    overrideSelectedRoute: null,
    overrideSelectedProvider: null,
    previousSelectedProvider: null,
    overrideSelectedPickOrder: null,
    overrideSelectedDropOrder: null,
    overridePickTime: null,
    overrideDropTime: null,
    pickTime: null,
    dropTime: null,
    isBrokerProviderType: false,
    errorText: null,
    overrideErrorText: null,
    solutions: null,
    impact: null,
    selectedSolutionId: null,
    selectedOverrideSolutionId: null,
    selectedImpactPeriod: 'after',
    activeTabRouteId: null,
    currentSolutionDispatchRoute: null,
    providersList: null,
    override: false,
    hasPermissionOverride: false,
    selectedImpactView: 'destination',
    title: 'Transfer Trip',
    componentName: 'tripTransfer',
    canShrink: Ember.computed.not('isSolutionsTaskRunning'),
    isShrunken: Ember.computed.alias('workspace.isDrawerShrunken'),
    //passed  by the workspace service
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    //Trips could be an array sometimes
    callback: Ember.computed.readOnly('stackFrame.options.callback'),
    currentDispatchRoute: Ember.computed.readOnly('currentRoute.dispatchRoute'),
    currentSolutionRoute: Ember.computed.readOnly('currentSolutionDispatchRoute.route'),
    overrideSelectedDispatchRoute: Ember.computed.readOnly('overrideSelectedRoute.dispatchRoute'),
    previousSelectedTripProvider: Ember.computed.readOnly('trip.provider'),
    isNotOverride: Ember.computed('override', function () {
      return !this.get('override');
    }),
    canDisableOverrideRouteSectionFields: Ember.computed('isNotOverride', 'overrideSelectedProvider', function () {
      const isNotOverride = this.get('isNotOverride');
      const overrideSelectedProvider = this.get('overrideSelectedProvider');

      if (isNotOverride || overrideSelectedProvider && overrideSelectedProvider.get('isBrokerProviderType')) {
        return true;
      }

      return false;
    }),
    canDisableOverrideOrderSectionFields: Ember.computed('canDisableOverrideRouteSectionFields', 'overrideSelectedRoute', function () {
      const canDisableOverrideRouteSectionFields = this.get('canDisableOverrideRouteSectionFields');
      const overrideSelectedRoute = this.get('overrideSelectedRoute');
      return canDisableOverrideRouteSectionFields || Ember.isNone(overrideSelectedRoute);
    }),
    canSave: Ember.computed('selectedSolutionId', 'overrideSelectedDropOrder', 'overrideErrorText', function () {
      const selectedSolutionId = this.get('selectedSolutionId');
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
      const previousSelectedTripProvider = this.get('previousSelectedTripProvider');
      const overrideErrorText = this.get('overrideErrorText');

      if (!Ember.isEmpty(selectedSolutionId) && Ember.isEmpty(overrideErrorText)) {
        return true;
      }

      if (overrideSelectedProvider && overrideSelectedProvider.get('isBrokerProviderType') || previousSelectedTripProvider && previousSelectedTripProvider.get('isBrokerProviderType') && !Ember.isEmpty(overrideSelectedDropOrder)) {
        return true;
      }

      return false;
    }),
    isOverrideChecked: Ember.observer('override', function () {
      const override = this.get('override');
      if (!override) this.set('overrideErrorText', '');
    }),
    isSolutionsTaskRunning: Ember.computed('fetchSolutionsTask.isRunning', 'fetchManualSolutionsTask.isRunning', function () {
      return this.get('fetchSolutionsTask.isRunning') || this.get('fetchManualSolutionsTask.isRunning');
    }),
    isSolutionsTaskCanceled: Ember.computed('fetchSolutionsTask.lastCanceled', 'fetchManualSolutionsTask.lastCanceled', function () {
      return Ember.isPresent(this.get('fetchSolutionsTask.lastCanceled')) || Ember.isPresent(this.get('fetchManualSolutionsTask.lastCanceled'));
    }),
    isStopPickTime: Ember.computed('trip.isWaitlisted', function () {
      return this.get('trip.isWaitlisted') ? '' : (0, _moment.default)(this.get('trip.pickStopPoint.computedStartTime')).format('MM-DD-YYYY hh:mm A');
    }),
    isStopDropTime: Ember.computed('trip.isWaitlisted', function () {
      return this.get('trip.isWaitlisted') ? '' : (0, _moment.default)(this.get('trip.dropStopPoint.computedStartTime')).format('MM-DD-YYYY hh:mm A');
    }),
    currentRoute: Ember.computed('trip.pickStopPoint.dispatchRoute.route', function () {
      const route = this.get('trip.pickStopPoint.dispatchRoute.route');

      if (!route) {
        return this.get('noRoute');
      }

      return route;
    }).readOnly(),
    brokerEligible: Ember.computed.readOnly('trip.segment.leg.brokerEligible'),

    async init() {
      this._super(...arguments);

      const solutionRoutesTable = new _emberLightTable.default(_config.solutionColumns);
      const stopsTable = new _emberLightTable.default(_config.stopColumns);
      const map = this.get('map');
      this.set('solutionRoutesTable', solutionRoutesTable); // eslint-disable-next-line new-cap

      this.set('solutions', Ember.A([]));
      this.set('stopsTable', stopsTable);
      this.set('hasPermissionOverride', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.transferTrip, null)); // run-time bindings; this avoids having a ton of observers

      const mapContext = _tripTransfer.default.extend({
        trip: Ember.computed.readOnly('_component.trip'),
        destinationIqRoute: Ember.computed.readOnly('_component.selectedSolutionDispatchRoute'),
        currentIqRoute: Ember.computed.readOnly('_component.currentDispatchRoute'),
        impactPayload: Ember.computed.readOnly('_component.impact'),
        inDanger: Ember.computed.readOnly('_component.inDanger'),
        selectedImpactPeriod: Ember.computed.readOnly('_component.selectedImpactPeriod'),
        // 'before' or 'after',
        selectedImpactView: Ember.computed.readOnly('_component.selectedImpactView'),
        // 'current' or 'destination'
        map: map
      }).create({
        _component: this
      });

      this.set('mapContext', mapContext);
      this.setOverrideProviderOptions();
    },

    async setupProvidersList() {
      const transferBrokerTripDispatch = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.transferBrokerTripDispatch, null);
      const previousSelectedTripProvider = (0, _unwrapProxy.unwrapProxy)(this.get('previousSelectedTripProvider'));
      this.set('providersList', []);
      const providers = await this.get('store').peekAll('provider').filter(provider => {
        // filter out broker providers if user does not have permission or InActive
        if (provider.get('isBrokerProviderType') && !transferBrokerTripDispatch || provider.get('isInActive')) {
          return false;
        }

        return !Ember.isEmpty(provider.get('name')) && !Ember.isEmpty(provider.get('id')) && provider.get('userCanAccessProvider');
      });
      const providersList = [];
      providers.forEach(provider => {
        let isChecked = false;

        if (Ember.isPresent(previousSelectedTripProvider) && provider.get('id') === previousSelectedTripProvider.get('id')) {
          isChecked = true;
        }

        providersList.push({
          id: provider.get('id'),
          name: provider.get('providerName'),
          isChecked: isChecked
        });
      });
      this.set('providersList', providersList);
    },

    setOverrideProviderOptions() {
      const transferBrokerTripDispatch = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.transferBrokerTripDispatch, null);
      const selectedProvider = this.get('trip.provider');
      const overrideProviderOptions = this.get('store').peekAll('provider').filter(provider => {
        let canAllowProvider = provider.get('userCanAccessProvider'); // filter out broker providers if user does not have permission

        if (provider.get('isBrokerProviderType') && !transferBrokerTripDispatch || provider.get('isInActive')) {
          return false;
        }

        if (canAllowProvider && selectedProvider.get('isBrokerProviderType')) {
          canAllowProvider = selectedProvider.get('id') !== provider.get('id');
        }

        return canAllowProvider;
      });
      this.set('overrideProviderOptions', overrideProviderOptions);
    },

    async didInsertElement() {
      this._super(...arguments);

      const mapContext = this.get('mapContext'); // side panel width just for trip transfers

      this.setSideDrawerWidth('600px');
      this.get('map').pushContext(mapContext);
      this.loadCurrentState();
      await this.setupProvidersList(); // collect in advance because of the huge number of records takes time
      // to load in ember store

      this.populateRoutesList(this.get('trip.schedule.id')); // skip auto trip transfer if the depart time is earlier than now.

      if ((0, _moment.default)(this.get('trip.pickStopPoint.departTime')).isAfter((0, _moment.default)())) {
        this.setupSolutions();
      }
    },

    willDestroyElement() {
      const mapContext = this.get('mapContext');
      this.get('map').removeContext(mapContext);

      this._super(...arguments);
    },

    destroy() {
      this.get('mapContext').set('_component', null);

      this._super(...arguments);
    },

    isFoundSolution(route) {
      const solutions = this.get('solutions');
      const isFound = solutions.findBy('id', route.get('dispatchRoute.id'));
      return Ember.isPresent(isFound);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('override', currentState.override);
        this.set('overrideSelectedProvider', currentState.overrideSelectedProvider);
        this.set('overrideSelectedRoute', currentState.overrideSelectedRoute);
        this.set('overrideSelectedPickOrder', currentState.overrideSelectedPickOrder);
        this.set('overrideSelectedDropOrder', currentState.overrideSelectedDropOrder);
        this.set('pickTime', currentState.pickTime);
        this.set('overridePickTime', currentState.overridePickTime);
        this.set('dropTime', currentState.dropTime);
        this.set('overrideDropTime', currentState.overrideDropTime);

        if (currentState.currentRouteSolution) {
          this.set('currentRouteSolution', currentState.currentRouteSolution);
        }
      }
    },

    // creates impact after payload
    createManualSolution(route) {
      let trip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      const dsTrip = this.get('trip');
      let dsRouteId = null;
      let stops = [];

      if (Ember.isPresent(route)) {
        dsRouteId = route.get('dispatchRoute.id');
        stops = route.get('dispatchRoute.orderedStopsWithBreaks');
      } // current route impact after remove transferrable trip


      if (dsTrip.get('pickStopPoint.dispatchRoute.id') === dsRouteId) {
        stops = stops.filter(stop => stop.get('trip.id') !== dsTrip.get('id'));
      }

      const newSolution = {
        id: dsRouteId,
        gain: 'unknown',
        otp: {
          delta: 'unknown',
          originalValue: 'unknown',
          newValue: 'unknown'
        },
        stops: [],
        recipe: null
      };

      if (!Ember.isEmpty(stops)) {
        stops.forEach(stop => {
          newSolution.stops.push({
            eta: {
              originalValue: null,
              newValue: null
            },
            otp: {
              originalValue: null,
              newValue: null,
              delta: null
            },
            promiseTime: null,
            id: stop.get('id'),
            polyline: null
          });
        });
      }

      if (trip) {
        const pick = {
          eta: {
            originalValue: null,
            newValue: null
          },
          otp: {
            originalValue: null,
            newValue: null,
            delta: null
          },
          promiseTime: null,
          id: trip.get('pickStopPoint.id'),
          polyline: null
        };
        const drop = {
          eta: {
            originalValue: null,
            newValue: null
          },
          otp: {
            originalValue: null,
            newValue: null,
            delta: null
          },
          promiseTime: null,
          id: trip.get('dropStopPoint.id'),
          polyline: null
        };
        newSolution.stops.push(pick);
        newSolution.stops.push(drop);
        newSolution.recipe = this.createManualRecipe([pick, drop], route, trip);
      }

      return newSolution;
    },

    createManualRecipe(stops, route, trip) {
      // last stop ordinal needs to be at least greater than last actual stop ordinal, it doesn't matter as longer as its bigger
      // cos will be able to normalize it and put it at the end
      const lastStopOrdinal = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks.length') + 100;
      const destinationId = route.get('dispatchRoute.id');
      let currentSourceId = this.get('currentDispatchRoute.id');

      if (trip.get('isCanceled')) {
        currentSourceId = 'cancel';
      } else if (trip.get('isWaitlisted')) {
        currentSourceId = 'waitlist';
      }

      const recipe = {
        instructions: stops.map((stop, index) => {
          return {
            id: stop.id,
            type: 'stop',
            source: currentSourceId,
            destination: destinationId,
            ordinal: lastStopOrdinal + index
          };
        })
      };
      return recipe;
    },

    noRoute: Ember.computed('trip.{isCanceled,isWaitlisted}', function () {
      const trip = this.get('trip');
      const pickStopPoint = trip.get('pickStopPoint');
      const dropStopPoint = trip.get('dropStopPoint');
      const schedule = this.get('store').peekAll('schedule').find(sch => Ember.isPresent((0, _unwrapProxy.unwrapProxy)(sch.get('dispatchSchedule'))));

      if (trip.get('isCanceled') || trip.get('isWaitlisted')) {
        return Ember.Object.create({
          id: null,
          name: 'No Route',
          schedule,
          dispatchRoute: {
            orderedStopsWithBreaks: [pickStopPoint, dropStopPoint],
            iqRoute: this
          }
        });
      }

      return null;
    }),
    overrideOrderOptions: Ember.computed('overrideSelectedRoute', function () {
      const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.dispatchRoute'));
      const plannedRouteStartTime = dispatchRoute.get('route.plannedStartTime');
      const plannedRouteEndTime = dispatchRoute.get('route.plannedEndTime');

      if (!Ember.isEmpty(dispatchRoute)) {
        const unperformedStops = dispatchRoute.getUnArrivedStopsInSequence(0) || [];
        const lastPerformedStop = dispatchRoute.get('lastPerformedStop');
        const routeOrdinals = [];
        unperformedStops.forEach(stop => {
          routeOrdinals.push({
            routeOrdinal: stop.get('routeOrdinal'),
            eta: (0, _moment.default)(stop.get('widgetEta'))
          });
        });

        if (unperformedStops.length === 0 && Ember.isNone(lastPerformedStop)) {
          routeOrdinals.push({
            routeOrdinal: 1,
            eta: (0, _moment.default)(plannedRouteStartTime)
          });
        } else if (unperformedStops.length === 0 && Ember.isPresent(lastPerformedStop)) {
          routeOrdinals.push({
            routeOrdinal: lastPerformedStop.get('routeOrdinal') + 1,
            eta: (0, _moment.default)(plannedRouteEndTime)
          });
        } else if (unperformedStops.length > 0) {
          const routeOrdinalArray = routeOrdinals.map(x => x.routeOrdinal);
          routeOrdinals.push({
            routeOrdinal: Math.max(...routeOrdinalArray) + 1,
            eta: (0, _moment.default)(plannedRouteEndTime)
          });
        }

        return routeOrdinals;
      }

      return [];
    }),

    populateRoutesList(scheduleId) {
      this.get('store').findRecord('schedule', scheduleId, {
        include: 'routes'
      });
    },

    overrideRouteOptions: Ember.computed('currentRoute', 'routesList', async function () {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const sourceRouteId = this.get('currentRoute.id');
      const sourceTripScheduleId = this.get('trip.schedule.id');
      const routes = this.get('routesList');

      if (Ember.isNone(routes)) {
        return [];
      }

      return _lodash.default.sortBy(routes.filter(route => {
        const routeScheduleId = route.get('schedule.id');
        const routeId = route.get('id');
        const sameSchedule = routeScheduleId === sourceTripScheduleId;
        const isBreakdown = route.get('status') === 'breakdown';
        let sameProvider = false;

        if (!Ember.isEmpty(overrideSelectedProvider)) {
          sameProvider = overrideSelectedProvider.id === route.get('provider.id');
        }

        const differentId = routeId !== sourceRouteId;
        return sameSchedule && differentId && sameProvider && !isBreakdown;
      }), route => route.name);
    }),
    selectedGain: Ember.computed('selectedSolutionId', 'solutions.[]', function () {
      const solutions = this.get('solutions');
      if (!solutions) return 0;
      const solution = solutions.findBy('id', this.get('selectedSolutionId')); // TODO: update to use delta otp value

      if (solution) {
        return solution.otp.deltaValue;
      }

      return 0;
    }),
    selectedGainType: Ember.computed('impactScore', function () {
      let gainType = 'spending';

      if (this.get('impactScore') > 0) {
        gainType = 'gaining';
      }

      return gainType;
    }),
    impactScore: Ember.computed('selectedSolutionId', 'selectedImpactPeriod', 'impact', function () {
      const impact = this.get('impact');
      const period = this.get('selectedImpactPeriod');
      if (!impact) return null;
      let score = impact.otpDelta ? impact.otpDelta : null;
      if (period === 'before') score = -score; // transform after back to negative value

      return score > 0 ? -score : Math.abs(score); // transform score based on positive being late and negative being positive
    }),
    impactScoreType: Ember.computed('impactScore', function () {
      let impactType = 'negative';

      if (this.get('impactScore') > 0) {
        impactType = 'positive';
      }

      return impactType;
    }),
    currentRouteImpactScoreType: Ember.computed('impactScore', function () {
      let impactType = 'spending';

      if (this.get('impactScore') > 0) {
        impactType = 'saving';
      }

      return impactType;
    }),
    selectedSolutionDispatchRoute: Ember.computed('solutions', 'selectedSolutionId', function () {
      const solutions = this.get('solutions');
      const selectedSolutionId = this.get('selectedSolutionId');
      if (Ember.isEmpty(solutions) || Ember.isEmpty(selectedSolutionId)) return null;
      const route = this.get('store').peekRecord('route', selectedSolutionId);
      const dsRoute = this.get('store').peekRecord('dispatch-route', route.get('dispatchRoute.id'));
      return dsRoute;
    }),
    inDanger: Ember.computed('solutions', 'selectedSolutionId', function () {
      const solutions = this.get('solutions');
      if (Ember.isEmpty(solutions)) return false; // TODO: connect to real data here

      const selectedSolutionId = this.get('selectedSolutionId');
      const index = solutions.mapBy('id').indexOf(selectedSolutionId);
      return index % 2 === 1;
    }),

    setupSolutions() {
      this.set('errorText');
      const trip = this.get('trip');
      const store = this.get('store');
      const currentState = this.get('currentState');
      let currentSolutionRouteId = null;

      if (currentState) {
        currentSolutionRouteId = currentState.currentSolutionRoute ? currentState.currentSolutionRoute.get('id') : null;
      }

      this.cursorWait();

      const fetchOrRestoreTask = async () => {
        if (currentState) {
          const restoreSolutions = this.get('currentState.solutions'); // after restore currentState solutions, remove the cache.

          this.set('currentState', null);
          return restoreSolutions;
        }

        return await this.get('fetchSolutionsTask').perform(trip);
      };

      fetchOrRestoreTask().catch(err => {
        // if the side drawer have been close while doing fetch
        if (this.get('isSolutionsTaskCanceled')) return null; // Try to be as robust as possible.  Log an error to console for discovery,
        // but just use an empty solution set to unblock manually transferring

        if (!Ember.isEmpty(err) && !Ember.isEmpty(err.payload)) {
          const reason = this.translate(err.payload.message, err.payload.detail);

          if (reason && reason !== '') {
            this.set('errorText', `* ${reason}`);
          } else {
            this.set('errorText', null);
          }
        } else {
          this.set('errorText', 'The system encountered an error, please try again. If the problem persists contact administrator.');
        }

        console.warn(`Unable to fetch trip transfer solutions: ${JSON.stringify(err)}`); // eslint-disable-line no-console

        return null;
      }).then(solutions => {
        let solutionsTableRows = []; // reset stops table data

        this.get('stopsTable').setRows([]); // give it a bit of time for everything to get loaded to ember store

        Ember.run.later(() => {
          if (!Ember.isEmpty(solutions)) {
            solutionsTableRows = solutions.map(_ref => {
              let {
                id,
                otp,
                extensionExceeded,
                plannedEndTime,
                prevEndEta,
                endEta
              } = _ref;
              const selectedRoute = store.peekRecord('route', id);
              return {
                id,
                otp,
                extensionExceeded,
                plannedEndTime,
                prevEndEta,
                endEta,
                dispatchRoute: selectedRoute.get('dispatchRoute')
              };
            });
            solutions.forEach(solution => {
              const index = this.get('solutions').findIndex(s => s.id === solution.id);

              if (index === -1) {
                this.get('solutions').pushObject(solution);
              } else {
                this.get('solutions').splice(index, 1, solution);
              }
            });
          }

          this.get('solutionRoutesTable').setRows(solutionsTableRows);
          const availableSolutions = solutions ? solutions.filter(s => !s.extensionExceeded) : [];
          availableSolutions.sort((a, b) => a.otp.newValue - b.otp.newValue);

          if (!Ember.isEmpty(availableSolutions)) {
            const solutionFound = currentSolutionRouteId ? availableSolutions.find(solution => solution.id === currentSolutionRouteId) : null;
            const firstSolutionId = solutionFound ? solutionFound.id : availableSolutions[0].id;
            this.sortTableSolutionRow();
            this.selectTableSolutionRow(firstSolutionId);
            this.selectSolution(firstSolutionId);
          }
        }, 500);
        this.cursorDefault();
      }).catch(() => {
        this.get('notifications').info(`Trip ${trip.get('tripId')} cannot be transferred`);
        this.cursorDefault();
        return this.onCloseClick();
      });
      this.fetchRouteOverrideOptions();
    },

    fetchRouteOverrideOptions() {
      const sourceRouteIdValue = this.get('currentRoute.id');
      const sourceRouteScheduleIdValue = this.get('currentRoute.schedule.id');
      if (!sourceRouteIdValue) return null;
      const store = this.get('store');
      const routes = store.peekAll('route').filter(route => {
        const routeScheduleIdValue = route.get('schedule.id');
        const routeIdValue = route.get('id');
        const sameSchedule = routeScheduleIdValue === sourceRouteScheduleIdValue;
        const differentId = routeIdValue !== sourceRouteIdValue;
        return sameSchedule && differentId;
      });
      this.set('routeOptions', routes);
      return routes;
    },

    // if all providers or none are selected then it means
    // we return null. We don't send anything to COS trip transfer
    getAutoTripTransferProvidersList() {
      const providersList = this.get('providersList');
      const checkedProvidersList = providersList.filter(provider => provider.isChecked).map(provider => provider.id);
      if (checkedProvidersList.length === 0) return null;
      if (checkedProvidersList.length === providersList.length) return null;
      return checkedProvidersList;
    },

    validateRouteExtensionLimit(solution) {
      const store = this.get('store');
      const selectedRoute = store.peekRecord('route', solution.id);
      const plannedEndTime = (0, _moment.default)(selectedRoute.get('computedPlannedEndTime'));
      const actualStartTime = selectedRoute.get('actualStartTimestamp') ? (0, _moment.default)(selectedRoute.get('actualStartTimestamp')) : (0, _moment.default)(selectedRoute.get('dispatchRoute.plannedStartTime'));
      const TIME_FORMAT = 'MM-DD HH:mm';
      solution.extensionExceeded = false;

      if (solution.stops && solution.stops.length > 0 && actualStartTime) {
        const endEta = solution.stops[solution.stops.length - 1].eta.newValue;
        const preEndEta = solution.stops[solution.stops.length - 1].eta.orignalValue;
        const actualEndTime = (0, _moment.default)(endEta);
        const prevEndTime = (0, _moment.default)(preEndEta); //   if planned end time < eta return error

        const extensionExceeded = actualEndTime.diff(plannedEndTime, 'minutes') > 0;
        solution.extensionExceeded = extensionExceeded;
        solution.plannedEndTime = plannedEndTime.format(TIME_FORMAT);
        solution.prevEndEta = prevEndTime.format(TIME_FORMAT);
        solution.endEta = actualEndTime.format(TIME_FORMAT);
      }
    },

    validateSolutions(solutions, routeMaxExtension) {
      solutions.forEach(solution => {
        this.validateRouteExtensionLimit(solution, routeMaxExtension);
      });
      return solutions;
    },

    fetchSolutionsTask: (0, _emberConcurrency.task)(function* (trip, routeId) {
      const providersList = this.getAutoTripTransferProvidersList();
      const tripTransferAdapter = yield this.get('store').adapterFor('trip-transfer');
      const dispatchScheduleId = trip.get('schedule.dispatchSchedule.id') ? parseInt(trip.get('schedule.dispatchSchedule.id'), 10) : null;
      const tripId = trip.get('id') ? parseInt(trip.get('id'), 10) : null;
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
      const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes;

      const result = yield tripTransferAdapter.createTripTransferAsyncOperation.perform([tripId], dispatchScheduleId, routeId ? parseInt(routeId, 10) : routeId, providersList);

      if (!Ember.isEmpty(result.data)) {
        const currentRouteSolution = result.data.attributes.sourceRoute;
        const solutions = result.data.attributes.destinationRoutes; // get core entity records from solution result

        return this.getRouteResults(solutions).then(() => {
          this.set('currentRouteSolution', currentRouteSolution);
          return this.validateSolutions(solutions, routeMaxExtension);
        });
      }

      if (!result.isJobSuccess) {
        throw result;
      }

      return;
    }).keepLatest(),

    async getRouteResults(solutions) {
      const coreEntityPopulator = this.get('coreEntityPopulator');
      const solutionPromises = [];
      solutions.forEach(solution => {
        const routeId = solution.id;
        solutionPromises.push(coreEntityPopulator.populateRtsRoute(routeId, {}));
      });
      await Promise.all(solutionPromises);
    },

    fetchManualSolutionsTask: (0, _emberConcurrency.task)(function* (trip, pickOrdinal, dropOrdinal, routeId) {
      const tripTransferAdapter = yield this.get('store').adapterFor('trip-transfer');
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
      const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes;

      const payloadData = [];
      const dispatchScheduleId = trip.get('schedule.dispatchSchedule.id') ? parseInt(trip.get('schedule.dispatchSchedule.id'), 10) : null;
      const id = trip.get('id') ? parseInt(trip.get('id'), 10) : null;
      payloadData.push({
        id,
        pickOrdinal,
        dropOrdinal
      });
      const result = yield tripTransferAdapter.postManualTransfer(payloadData, dispatchScheduleId, routeId ? parseInt(routeId, 10) : routeId);

      if (!Ember.isEmpty(result.data)) {
        const currentRouteSolution = result.data.attributes.sourceRoute;
        const solutions = result.data.attributes.destinationRoutes; // get core entity records from solution result

        return this.getRouteResults(solutions).then(() => {
          this.set('currentRouteSolution', currentRouteSolution);
          return this.validateSolutions(solutions, routeMaxExtension);
        });
      }

      return;
    }).keepLatest(),
    currentRouteImpact: Ember.computed('currentRouteSolution', function () {
      const store = this.get('store');
      const currentSolution = this.get('currentRouteSolution') || {};
      const currentStops = this.get('currentDispatchRoute.orderedStopsWithGaragesAndBreaks') || [];
      const currentRouteStart = this.get('currentDispatchRoute.route.actualStartTimestamp') || this.get('currentDispatchRoute.route.computedPlannedStartTime');

      if (Ember.isNone(this.get('currentRouteSolution'))) {
        const impact = Ember.Object.create({
          routeOtp: currentSolution.otp,
          otpDelta: '',
          before: {
            otp: '',
            stops: currentStops.map(stop => {
              const stopPoint = store.peekRecord('stop-point', stop.id);
              return _impactStop.default.create({
                id: stop.id,
                eta: stop.id.includes('GP') ? currentRouteStart : stopPoint.get('eta'),
                otp: stop.get('otp'),
                promiseTime: stop.get('promisedTime'),
                otpValue: stop.get('otpValue'),
                polyline: null,
                mrt: null,
                routeOrdinal: stopPoint.get('routeOrdinal'),
                stopPoint: stopPoint,
                isBreak: stopPoint.get('isBreak')
              });
            })
          }
        });
        return impact;
      } // @TODO we should refactor to make it more flexible for map context


      const impact = Ember.Object.create({
        routeOtp: currentSolution.otp,
        otpDelta: currentSolution.otp.deltaValue,
        before: {
          otp: currentSolution.otp.originalValue,
          stops: currentStops.map(stop => {
            const stopPoint = store.peekRecord('stop-point', stop.id);
            return _impactStop.default.create({
              id: stop.id,
              eta: stop.id.includes('GP') ? currentRouteStart : stopPoint.get('eta'),
              otp: stop.get('otp'),
              promiseTime: stop.get('promisedTime'),
              otpValue: stop.get('otpValue'),
              polyline: null,
              mrt: null,
              routeOrdinal: stopPoint.get('routeOrdinal'),
              stopPoint: stopPoint,
              isBreak: stopPoint.get('isBreak')
            });
          })
        },
        after: {
          otp: currentSolution.otp.newStatus,
          stops: currentSolution.stops.map(stop => {
            let stopPoint = null;

            if (stop.id.includes('B')) {
              const stopId = stop.id.replace('B', '');
              stopPoint = store.peekRecord('route-break', stopId).stopPoint;
            } else {
              stopPoint = store.peekRecord('stop-point', stop.id);
            }

            return _impactStop.default.create({
              id: stopPoint.get('id'),
              eta: stop.id.includes('GP') ? currentRouteStart : stop.eta.newValue,
              otp: stop.otp.newStatus,
              otpValue: stop.otp,
              mrt: stop.otp.delta,
              promiseTime: stop.promiseTime,
              polyline: stop.polyline,
              routeOrdinal: stop.ordinal,
              stopPoint,
              isBreak: stopPoint.get('isBreak')
            });
          })
        }
      });
      return impact;
    }),
    selectedRouteImpact: Ember.computed('currentSolutionDispatchRoute', function () {
      const store = this.get('store');
      const selectedRouteId = this.get('currentSolutionDispatchRoute.route.id');
      const solutions = this.get('solutions') || [];
      const selectedSolution = solutions.findBy('id', selectedRouteId) || {};
      const currentStops = this.get('currentSolutionDispatchRoute.orderedStopsWithGaragesAndBreaks') || [];
      const selectedRouteStart = this.get('currentSolutionDispatchRoute.route.actualStartTimestamp') || this.get('currentSolutionDispatchRoute.route.computedPlannedStartTime'); // @TODO we should refactor to make it more flexible for map context

      const impact = Ember.Object.create({
        routeOtp: selectedSolution.otp,
        otpDelta: selectedSolution.otp.deltaValue,
        before: {
          otp: selectedSolution.otp.originalValue,
          stops: currentStops.map(stop => {
            const stopPoint = store.peekRecord('stop-point', stop.id);
            return _impactStop.default.create({
              id: stop.id,
              eta: stop.id.includes('GP') ? selectedRouteStart : stopPoint.get('eta'),
              otp: stop.get('otp'),
              promiseTime: stop.get('promisedTime'),
              otpValue: stop.get('otpValue'),
              polyline: null,
              mrt: null,
              routeOrdinal: stopPoint.get('routeOrdinal'),
              stopPoint: stopPoint,
              isBreak: stopPoint.get('isBreak')
            });
          })
        },
        after: {
          otp: selectedSolution.otp.newValue,
          stops: selectedSolution.stops.map(stop => {
            let stopPoint = null;

            if (stop.id.includes('B')) {
              const stopId = stop.id.replace('B', '');
              stopPoint = store.peekRecord('route-break', stopId).stopPoint;
            } else {
              stopPoint = store.peekRecord('stop-point', stop.id);
            }

            return _impactStop.default.create({
              id: stopPoint.get('id'),
              eta: stop.id.includes('GP') ? selectedRouteStart : stop.eta.newValue,
              otp: stop.otp.newStatus,
              otpValue: stop.otp,
              mrt: stop.otp.delta,
              promiseTime: stop.promiseTime,
              polyline: stop.polyline,
              routeOrdinal: stop.ordinal,
              stopPoint: stopPoint,
              isBreak: stopPoint.get('isBreak')
            });
          })
        }
      });
      return impact;
    }),

    chooseImpactByRoute(routeId) {
      const solutions = this.get('solutions');
      const period = this.get('selectedImpactPeriod');
      const currentRoute = this.get('currentRoute');
      const currentRouteSolution = this.get('currentRouteSolution');
      const currentTripId = this.trip.tripId;
      let selectedSolution = null;
      let impact = null; // Handle choosing the current route

      if (!Ember.isNone(currentRoute) && routeId === currentRoute.get('dispatchRoute.route.id')) {
        selectedSolution = currentRouteSolution;

        if (Ember.isEmpty(selectedSolution)) {
          if (this.get('trip.status') === 'waitlisted' && period === 'before') {
            this.get('stopsTable').setRows(this.get('trip.stops'));
            return;
          }

          this.get('stopsTable').setRows([]);
        }

        impact = this.get('currentRouteImpact');
      } else {
        selectedSolution = solutions.findBy('id', routeId);

        if (Ember.isEmpty(selectedSolution)) {
          this.set('impact', impact);
          this.get('stopsTable').setRows([]);
          return impact;
        }

        impact = this.get('selectedRouteImpact');
      }

      this.set('impact', impact);
      const stopsRows = impact[period] ? impact[period].stops : [];
      const rows = this.get('stopsTable').setRows(stopsRows);
      rows.map(stop => {
        if (!Ember.isNone(stop.get('stopPoint')) && stop.get('stopPoint.trip.tripId') === currentTripId) {
          return stop.set('selected', true);
        }
      });
      return impact;
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      const solutions = this.get('solutions');
      const selectedSolutionId = this.get('currentSolutionDispatchRoute.route.id');
      const recipe = solutions.findBy('id', selectedSolutionId).recipe;
      const bulkOperationAdapter = this.get('store').adapterFor('bulk-operation');
      const finalRecipe = this.setRecipePrerequisites(recipe);
      return yield bulkOperationAdapter.createBulkAsyncOperation.perform(finalRecipe).then(result => {
        if (result.isJobSuccess) {
          this.get('workspace').requestWootricSurvey('on_tripTransfer');
          return Promise.resolve();
        }

        return Promise.reject(result.result);
      }).catch(function (error) {
        return Promise.reject(error);
      });
    }),

    setRecipePrerequisites(recipe) {
      const currentRoute = this.get('currentRoute');
      const currentDispatchRoute = this.get('currentDispatchRoute');
      const selectedSolutionDispatchRoute = this.get('selectedSolutionDispatchRoute');
      let currentDispatchRouteId = null;
      if (_lodash.default.isNil(recipe.prerequisites)) recipe.prerequisites = [];

      if (currentRoute.get('name') !== 'No Route' && currentDispatchRoute) {
        currentDispatchRouteId = currentDispatchRoute.get('id');
        const existPrerequisiteIndex = recipe.prerequisites.findIndex(v => v.id === currentDispatchRouteId);

        if (existPrerequisiteIndex !== -1) {
          recipe.prerequisites[existPrerequisiteIndex].version = currentDispatchRoute.get('version');
        } else {
          recipe.prerequisites.push({
            id: currentDispatchRouteId,
            type: 'route',
            version: currentDispatchRoute.get('version')
          });
        }
      }

      if (selectedSolutionDispatchRoute) {
        const selectedSolutionDispatchRouteId = selectedSolutionDispatchRoute.get('id');
        const existSelectedSolutionIndex = recipe.prerequisites.findIndex(v => v.id === selectedSolutionDispatchRouteId);

        if (existSelectedSolutionIndex !== -1) {
          recipe.prerequisites[existSelectedSolutionIndex].version = selectedSolutionDispatchRoute.get('version');
        } else if (selectedSolutionDispatchRouteId !== currentDispatchRouteId) {
          recipe.prerequisites.push({
            id: selectedSolutionDispatchRouteId,
            type: 'route',
            version: selectedSolutionDispatchRoute.get('version')
          });
        }
      }

      return recipe;
    },

    selectSolution(routeId) {
      const selectedRoute = this.get('store').peekRecord('route', routeId);
      const selectedDispatchRoute = this.get('store').peekRecord('dispatch-route', selectedRoute.get('dispatchRoute.id'));
      this.setProperties({
        selectedSolutionId: routeId,
        selectedImpactPeriod: 'after',
        activeTabRouteId: routeId,
        currentSolutionDispatchRoute: selectedDispatchRoute
      });
      this.chooseImpactByRoute(routeId);
    },

    observeSelectImpactPeriod: Ember.observer('selectedImpactPeriod', function () {
      const period = this.get('selectedImpactPeriod');
      const selectedRouteId = this.get('activeTabRouteId'); // TODO: Can the table rows be updated without an observer?

      this.get('stopsTable').setRows(this.get(`impact.${period}.stops`)); // revaluate impact

      this.chooseImpactByRoute(selectedRouteId);
    }),

    sortTableSolutionRow() {
      const rows = this.get('solutionRoutesTable.rows');
      const sortedRows = rows.get('content').sort(function (a, b) {
        if (a && b) {
          const aExtensionExceeded = a.get('extensionExceeded');
          const bExtensionExceeded = b.get('extensionExceeded');
          if (aExtensionExceeded && !bExtensionExceeded) return 1;
          if (bExtensionExceeded && !aExtensionExceeded) return -1;
          const aNewOtp = a.get('otp.newValue') || 0;
          const bNewOtp = b.get('otp.newValue') || 0;
          return aNewOtp - bNewOtp;
        }

        return 0;
      });
      this.get('solutionRoutesTable').setRows(sortedRows);
    },

    selectTableSolutionRow(routeId) {
      const rows = this.get('solutionRoutesTable.rows');
      const currentSelectedRow = rows.findBy('selected');
      const rowToSelect = rows.findBy('id', routeId);
      if (rowToSelect === currentSelectedRow) return;

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
      }

      rowToSelect.set('selected', true);
      this.selectSolution(routeId);
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    selectOverrideSolution() {
      const route = this.get('overrideSelectedRoute');
      const previousSelectedTripProvider = this.get('previousSelectedTripProvider');
      const routeId = route.get('id');
      const routeName = route.get('name');
      const trip = this.get('trip');
      const currentSelectedRow = this.get('solutionRoutesTable.rows').findBy('selected');
      const store = this.get('store');
      const solutions = this.get('solutions');
      const pickOrdinal = this.get('overrideSelectedPickOrder');
      const dropOrdinal = this.get('overrideSelectedDropOrder'); // clear error message

      this.set('errorText');

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
        this.set('selectedSolutionId');
      }

      if (previousSelectedTripProvider.get('isBrokerProviderType')) {
        const selectedRoute = store.peekRecord('route', routeId);
        this.set('currentSolutionDispatchRoute', selectedRoute.get('dispatchRoute'));
        return;
      }

      this.cursorWait();
      this.get('fetchManualSolutionsTask').perform(trip, pickOrdinal, dropOrdinal, routeId).catch(err => {
        // if the side drawer have been close while doing fetch
        if (this.get('isSolutionsTaskCanceled')) return null; // Try to be as robust as possible.  Log an error to console for discovery,
        // but just use an empty solution set to unblock manually transferring

        console.warn(`Unable to fetch trip transfer solutions: ${JSON.stringify(err)}`); // eslint-disable-line no-console

        if (!Ember.isEmpty(err) && !Ember.isEmpty(err.payload)) {
          if (err.payload.message) {
            const reason = this.translate(err.payload.message, err.payload.detail);

            if (err.payload.message === `Route ${routeId} does not exist`) {
              this.set('errorText', 'The selected route is not available, please try again in a few minutes. If the problem persist contact an administrator.');
            } else if (reason && reason !== '') {
              this.set('errorText', `* ${reason}`);
            } else {
              this.set('errorText', null);
            }
          } else if (err.payload.detail) {
            this.set('errorText', `* ${err.payload.detail}`);
          } else {
            this.set('errorText', '* Error getting hypo trip transfer.');
          }
        }

        return null;
      }).then(solution => {
        if (solution) {
          const destinationRoutes = solution || [];
          const foundSolution = solutions.findBy('id', destinationRoutes[0].id); // reset stops table data

          this.get('stopsTable').setRows([]);

          if (!foundSolution) {
            this.get('solutions').pushObject(destinationRoutes[0]);
            const solutionsTableRows = destinationRoutes.map(_ref2 => {
              let {
                id,
                otp,
                extensionExceeded,
                plannedEndTime,
                prevEndEta,
                endEta
              } = _ref2;
              const selectedRoute = store.peekRecord('route', id);
              return {
                id,
                otp,
                extensionExceeded,
                plannedEndTime,
                prevEndEta,
                endEta,
                dispatchRoute: selectedRoute.get('dispatchRoute')
              };
            }); // this.get('solutionRoutesTable').push(solutionsTableRows[0]);

            this.get('solutionRoutesTable').addRows(solutionsTableRows);
          } else {
            const solutionRoutesTableRow = this.get('solutionRoutesTable.rows').toArray();
            const solutionIndex = solutions.findIndex(currSolution => currSolution.id === destinationRoutes[0].id);
            const solutionRoutesTableRowIindex = solutionRoutesTableRow.findIndex(row => row.get('id') === destinationRoutes[0].id); // replace the solution with new data

            solutions.splice(solutionIndex, 1, destinationRoutes[0]);
            const solutionsTableRows = destinationRoutes.map(_ref3 => {
              let {
                id,
                otp,
                extensionExceeded,
                plannedEndTime,
                prevEndEta,
                endEta
              } = _ref3;
              const selectedRoute = store.peekRecord('route', id);
              return {
                id,
                otp,
                extensionExceeded,
                plannedEndTime,
                prevEndEta,
                endEta,
                dispatchRoute: selectedRoute.get('dispatchRoute')
              };
            }); // replace the routes table rows with new solution rows

            this.get('solutionRoutesTable').removeRow(solutionRoutesTableRow[solutionRoutesTableRowIindex]);
            this.get('solutionRoutesTable').addRow(solutionsTableRows[0]);
            this.get('solutionRoutesTable').setRows(solutionRoutesTableRow); // refresh stop table with new results

            const selectedRoute = store.peekRecord('route', destinationRoutes[0].id);
            this.set('currentSolutionDispatchRoute', selectedRoute.get('dispatchRoute'));
          }

          this.sortTableSolutionRow();

          if (!destinationRoutes[0].extensionExceeded) {
            this.selectSolution(destinationRoutes[0].id);
            this.selectTableSolutionRow(routeId);
          }
        }

        this.cursorDefault();
      }).catch(e => {
        if (e.status) {
          this.get('notifications').warning(`Trip ${trip.get('tripId')} cannot be transferred to route ${routeName}`);
        }

        this.cursorDefault();
      });
    },

    onCancelBookingPatchTask() {
      const trip = this.get('trip');
      const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
      booking.set('status', 'canceled');
      return booking.save();
    },

    async waitListTripInDispatch() {
      const dispatchAdapter = this.get('store').adapterFor('dispatch-schedule');
      const tripId = parseInt(this.get('trip.id'), 10);
      const response = await dispatchAdapter.waitListTrip(tripId);
      return response;
    },

    onManualTripTransfer() {
      const currentSolutionDispatchRoute = this.get('currentSolutionDispatchRoute');
      const selectedDispatchRouteId = parseInt(this.get('overrideSelectedRoute.dispatchRoute.id'), 10);
      const pickOrdinal = this.get('overrideSelectedPickOrder');
      const dropOrdinal = this.get('overrideSelectedDropOrder');
      const currentRouteId = this.get('currentRoute.name');
      const title = 'Transfer Trip';
      const tripId = this.get('trip.tripId');
      const tooltip = this.get('tooltip');
      const currentRouteIdTip = !this.get('trip.isScheduled') ? '' : `from route ${currentRouteId}`;
      const tip = `Transfer trip ${tripId} ${currentRouteIdTip} to route ${currentSolutionDispatchRoute.get('route.name')}`;
      const successMsg = `Trip transfer ${tripId} was successful`;
      const errorMsg = `Trip transfer ${tripId} failed`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          await this.onCancelBookingPatchTask();
          return this.get('manualTripTransferForDispatch').perform(selectedDispatchRouteId, pickOrdinal, dropOrdinal, false).then(() => {
            if (this.get('callback')) {
              this.get('callback')();
            }

            this.get('notifications').success(successMsg);
            tooltip.reset();
            this.get('workspace').popState('tripTransfer');
          }).catch(async () => {
            this.get('notifications').warning(errorMsg);
            tooltip.reset();
            await this.waitListTripInDispatch();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    onAutoGeneratedSolutions() {
      const tripId = this.get('trip.tripId');
      const currentSolutionDispatchRoute = this.get('currentSolutionDispatchRoute');
      const currentRouteId = this.get('currentRoute.name');
      const title = 'Transfer Trip';
      const currentRouteIdTip = !this.get('trip.isScheduled') ? '' : `from route ${currentRouteId}`;
      const vehicle = currentSolutionDispatchRoute.get('assignedVehicle');
      const tip = `Transfer trip ${tripId} ${currentRouteIdTip} to route ${currentSolutionDispatchRoute.get('route.name')}?`;
      const subTips = {
        header: 'Please consider the following:',
        items: []
      };
      let missingNonConsumableArray = [];

      if (Ember.isPresent(vehicle)) {
        const capacityCounts = vehicle.get('capacityCounts');
        const travelNeedCounts = this.get('trip.travelNeedCounts');
        const travelNeedTypes = this.get('store').peekAll('travel-need-type').toArray();
        const routeVehicleType = vehicle.get('vehicleType.name');
        const tripVehicleType = this.get('currentDispatchRoute.assignedVehicle.vehicleType.name');
        missingNonConsumableArray = (0, _vehicleCapacity.missingNonConsumable)(travelNeedCounts.nonConsumables, capacityCounts.nonConsumables, travelNeedTypes);

        if (missingNonConsumableArray.length > 0) {
          subTips.items.push(`The trip has an ${missingNonConsumableArray.map(obj => obj.displayName).join(' , ')}, but the vehicle on this route does not.`);
        }

        if (tripVehicleType && routeVehicleType && routeVehicleType !== tripVehicleType) {
          subTips.items.push('The vehicle type does not match the original vehicle for this trip.');
        }
      }

      this.get('tooltip').pushConfirmation({
        tip: tip,
        subTips: subTips,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveTask').perform().then(() => {
            if (this.get('callback')) {
              this.get('callback')();
            }

            this.get('notifications').success(`Trip transfer ${tripId} was successful`);
            this.get('tooltip').reset();
            return this.onCloseClick();
          }).catch(err => {
            const reason = this.translate(err);
            this.get('notifications').warning(`Unable to transfer trip ${tripId}. ${reason}`);

            if (reason.includes('Obtain route lock failed')) {
              this.get('tooltip').reset();
              return this.onCloseClick();
            }
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          this.get('tooltip').reset();
        },
        hasOverlay: true
      });
    },

    async onPostBooking() {
      const bookingAdapter = this.get('store').adapterFor('booking');
      const rider = (0, _unwrapProxy.unwrapProxy)(this.get('trip.rider'));
      const pick = (0, _unwrapProxy.unwrapProxy)(this.get('trip.pick'));
      const drop = (0, _unwrapProxy.unwrapProxy)(this.get('trip.drop'));
      const segment = (0, _unwrapProxy.unwrapProxy)(this.get('trip.segment'));
      const leg = (0, _unwrapProxy.unwrapProxy)(segment.get('leg'));
      const legTravelNeed = (0, _unwrapProxy.unwrapProxy)(leg.get('legTravelNeeds.firstObject'));
      const stopPointsJson = this.buildStopPointsJson(pick, drop);
      const tripId = this.get('trip.tripId');
      const json = {
        data: {
          type: 'booking',
          attributes: {
            trackingId: tripId,
            externalBookingId: '',
            requestedProvider: {
              type: 'dedicated',
              id: '',
              name: 'Verifone'
            },
            source: {
              type: 'callAgent',
              id: '1'
            },
            status: 'active',
            passengers: [{
              id: rider.get('id'),
              name: rider.get('fullName'),
              lastName: rider.get('lastName'),
              firstName: rider.get('firstName'),
              middleName: rider.get('middleName'),
              email: `${rider.get('firstName')}@emaple.com`,
              phone1: {
                number: rider.get('riderPhoneNumbers').length ? rider.get('riderPhoneNumbers')[0].get('phoneNumber') : ''
              },
              bookingNote: rider.get('notes') ? rider.get('notes') : '',
              requiredAttributes: [{
                type: legTravelNeed.get('travelNeedTypeName'),
                cnt: legTravelNeed.get('count')
              }]
            }],
            stopPoints: stopPointsJson,
            price: {
              payerIdentifier: '',
              currencyCode: 'USD',
              estimate: segment.get('fare'),
              paymengTypeName: segment.get('fareTypeName'),
              tip: {
                percent: 1,
                minValue: 0
              }
            }
          }
        }
      };
      const bookingResponse = await bookingAdapter.postBooking(json);
      return bookingResponse;
    },

    buildStopPointsJson(pick, drop) {
      const stopPointsJson = [];
      const pickAddress = (0, _unwrapProxy.unwrapProxy)(pick.get('segmentStop.place.address'));
      const dropAddress = (0, _unwrapProxy.unwrapProxy)(drop.get('segmentStop.place.address'));
      const rider = (0, _unwrapProxy.unwrapProxy)(this.get('trip.rider'));
      const segment = (0, _unwrapProxy.unwrapProxy)(this.get('trip.segment'));
      const pickJson = {
        index: pick.get('plannedRouteOrdinal'),
        'address': {
          'formattedAddress': pickAddress.get('simpleAddress'),
          'streetName': pickAddress.get('streetAddress'),
          'streetNr': pickAddress.get('streetNumber'),
          'region': pickAddress.get('region'),
          'postCode': pickAddress.get('postalCode'),
          'city': pickAddress.get('locality'),
          'state': pickAddress.get('region'),
          'coord': {
            'lat': pick.get('lat'),
            'lng': pick.get('lng')
          }
        },
        'passengerEvents': [{
          'type': 'pickup',
          'passengerId': rider.get('riderId'),
          'time': (0, _moment.default)(segment.get('promiseTime')).format(),
          'anchor': true,
          'driverNote': rider.get('notes') ? rider.get('notes') : ''
        }]
      };
      const dropJson = {
        index: drop.get('plannedRouteOrdinal'),
        'address': {
          'formattedAddress': dropAddress.get('simpleAddress'),
          'streetName': dropAddress.get('streetAddress'),
          'streetNr': dropAddress.get('streetNumber'),
          'region': dropAddress.get('region'),
          'postCode': dropAddress.get('postalCode'),
          'city': dropAddress.get('locality'),
          'state': dropAddress.get('region'),
          'coord': {
            'lat': drop.get('lat'),
            'lng': drop.get('lng')
          }
        },
        'passengerEvents': [{
          'type': 'dropoff',
          'passengerId': rider.get('riderId'),
          'time': (0, _moment.default)(segment.get('promiseTime')).format(),
          'anchor': false,
          'driverNote': rider.get('notes') ? rider.get('notes') : ''
        }]
      };
      stopPointsJson.push(pickJson, dropJson);
      return stopPointsJson;
    },

    onValidateBrokerProvider() {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const previousSelectedProvider = this.get('previousSelectedProvider');
      const isAnotherBrokerProviderSelected = previousSelectedProvider && previousSelectedProvider.get('isBrokerProviderType') && overrideSelectedProvider.get('isBrokerProviderType');

      if (isAnotherBrokerProviderSelected || overrideSelectedProvider.get('isBrokerProviderType')) {
        this.set('isBrokerProviderType', true);
      }
    },

    validatePickDropOrder(pickOrder, dropOrder) {
      const currentStops = this.get('overrideSelectedRoute.dispatchRoute.orderedStopsWithBreaks') || [];
      const pickOrderMin = pickOrder - 1; // because array is zero based

      const dropOrderMax = dropOrder - 2; // because array is zero based and the inserted pick stop moves everything down one.

      const overridePickTime = this.get('overridePickTime');
      const overrideDropTime = this.get('overrideDropTime');
      this.set('overrideErrorText', '');
      let isValid = true;
      let index = pickOrderMin;

      if (overridePickTime.isSameOrAfter(overrideDropTime)) {
        this.set('overrideErrorText', 'Dropoff Time must be after Pickup Time.');
        isValid = false;
      }

      if (isValid && dropOrder < pickOrder) {
        this.set('overrideErrorText', 'Dropoff Time must be after Pickup Time.');
        isValid = false;
      }

      while (isValid && index < dropOrderMax) {
        const stop = currentStops.objectAt(index);

        if (Ember.isPresent(stop) && stop.get('isBreak')) {
          this.set('overrideErrorText', 'Invalid pick/drop time. There is a break in between');
          isValid = false;
        }

        index++;
      }

      return isValid;
    },

    manualTripTransferForDispatch: (0, _emberConcurrency.task)(function* (routeId, pickOrdinal, dropOrdinal, ignoreCapacityViolation) {
      const dispatchRouteAdapter = this.get('store').adapterFor('dispatch-route');
      const tripId = parseInt(this.get('trip.id'), 10);
      yield dispatchRouteAdapter.manualTripTransferForDispatch(routeId, tripId, pickOrdinal, dropOrdinal, ignoreCapacityViolation);
    }),

    convertToRegularDateTime(value) {
      const scheduleName = this.get('overrideSelectedRoute.schedule.name');
      const dateTimeMoment = (0, _moment.default)(scheduleName).startOf('day');
      const timeArray = value.masked.split(':');
      dateTimeMoment.add(timeArray[0], 'hours');
      dateTimeMoment.add(timeArray[1], 'minutes');
      return dateTimeMoment;
    },

    getOrderfromDateTime(selectDateTime, overrideOrderOptions) {
      const selectedRouteOrderOption = overrideOrderOptions.find(option => {
        const currentTime = option.eta;

        if (selectDateTime <= currentTime) {
          return true;
        }

        return false;
      });
      return Ember.isPresent(selectedRouteOrderOption) ? selectedRouteOrderOption.routeOrdinal : 1;
    },

    async setBrokerEligible(value) {
      const leg = (0, _unwrapProxy.unwrapProxy)(this.get('trip.segment.leg'));
      leg.set('brokerEligible', value);
      await leg.save();
    },

    actions: {
      refreshProviderAutoTransfer() {
        this.setProperties({
          override: null,
          overrideSelectedProvider: null,
          overrideSelectedRoute: null,
          overrideSelectedPickOrder: null,
          overrideSelectedDropOrder: null,
          pickTime: null,
          dropTime: null,
          currentSolutionDispatchRoute: null,
          selectedSolutionId: null
        }); // eslint-disable-next-line new-cap

        this.set('solutions', Ember.A([]));
        this.get('solutionRoutesTable').setRows([]);
        this.get('stopsTable').setRows([]);

        if ((0, _moment.default)(this.get('trip.pickStopPoint.departTime')).isAfter((0, _moment.default)())) {
          this.setupSolutions();
        }
      },

      onSolutionRowClick(row
      /*, event */
      ) {
        const routeId = row.get('id');
        const solution = row.get('content');
        if (solution.extensionExceeded) return; // Reset the override

        this.set('selectedOverrideSolutionId');
        this.selectTableSolutionRow(routeId);
      },

      onChangePickTime(value) {
        this.set('pickTime', value.unmasked);

        if (value.unmasked.length < 4) {
          return;
        }

        let overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
        const overrideOrderOptions = this.get('overrideOrderOptions');
        const pickTimeMoment = this.convertToRegularDateTime(value);
        const pickRouteOrinal = this.getOrderfromDateTime(pickTimeMoment, overrideOrderOptions);

        if (Ember.isNone(pickRouteOrinal)) {
          this.set('pickTime');
          this.get('notifications').info('Invalid pick order.');
        }

        if (overrideSelectedDropOrder === pickRouteOrinal) {
          overrideSelectedDropOrder = overrideSelectedDropOrder + 1;
          this.set('overrideSelectedDropOrder', overrideSelectedDropOrder);
        }

        this.set('overridePickTime', pickTimeMoment);
        this.set('overrideSelectedPickOrder', pickRouteOrinal);

        if (Ember.isPresent(overrideSelectedDropOrder)) {
          if (this.validatePickDropOrder(pickRouteOrinal, overrideSelectedDropOrder)) {
            this.selectOverrideSolution(this.get('overrideSelectedRoute'));
          } else {
            this.set('pickTime');
            this.set('overridePickTime');
            this.set('overrideSelectedPickOrder');
          }
        }
      },

      onChangeDropTime(value) {
        this.set('dropTime', value.unmasked);

        if (value.unmasked.length < 4) {
          return;
        }

        const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
        const overrideOrderOptions = this.get('overrideOrderOptions');
        const dropTimeMoment = this.convertToRegularDateTime(value);
        let dropRouteOrdinal = this.getOrderfromDateTime(dropTimeMoment, overrideOrderOptions);

        if (overrideSelectedPickOrder === dropRouteOrdinal) {
          dropRouteOrdinal = dropRouteOrdinal + 1;
        } else if (overrideSelectedPickOrder < dropRouteOrdinal) {
          dropRouteOrdinal = dropRouteOrdinal + 1;
        }

        this.set('overrideDropTime', dropTimeMoment);
        this.set('overrideSelectedDropOrder', dropRouteOrdinal);

        if (Ember.isPresent(overrideSelectedPickOrder)) {
          if (this.validatePickDropOrder(overrideSelectedPickOrder, dropRouteOrdinal)) {
            this.selectOverrideSolution(this.get('overrideSelectedRoute'));
            return;
          }

          this.set('dropTime');
          this.set('overrideDropTime');
          this.set('overrideSelectedDropOrder');
        }
      },

      onRefreshClick() {
        this.setProperties({
          override: null,
          overrideSelectedProvider: null,
          overrideSelectedRoute: null,
          overrideSelectedPickOrder: null,
          overrideSelectedDropOrder: null,
          currentSolutionDispatchRoute: null,
          selectedSolutionId: null,
          pickTime: null,
          overridePickTime: null,
          dropTime: null,
          overrideDropTime: null
        }); // eslint-disable-next-line new-cap

        this.set('solutions', Ember.A([]));
        this.get('solutionRoutesTable').setRows([]);
        this.get('stopsTable').setRows([]);
        this.setupSolutions();
      },

      async onSaveClick() {
        const coreEntityPopulator = this.get('coreEntityPopulator');
        const overrideSelectedProvider = this.get('overrideSelectedProvider');
        const overrideRouteOptions = await this.get('overrideRouteOptions');
        const previousSelectedTripProvider = this.get('previousSelectedTripProvider');
        const currentSolutionDispatchRoute = this.get('currentSolutionDispatchRoute');
        const brokerEligible = this.get('brokerEligible'); // Reg-NYAAR-19415 - if no override selected isBrokerProviderType will be false

        const isBrokerProviderType = overrideSelectedProvider ? overrideSelectedProvider.get('isBrokerProviderType') : false; // no routes exists for selected provider

        if (!overrideRouteOptions.length && overrideRouteOptions.length > 0) {
          const warningMsg = overrideSelectedProvider.get('isBrokerProviderType') ? 'Failed to transfer trip. No broker routes exist' : 'Failed to transfer trip. No routes exist';
          this.onCloseClick();
          this.get('notifications').warning(warningMsg);
          return;
        }

        if (overrideRouteOptions.length > 0) {
          const routeId = parseInt((0, _unwrapProxy.unwrapProxy)(overrideRouteOptions.get('firstObject.id')), 10);
          await coreEntityPopulator.populateRtsRoute(routeId, {});
          const dispatchRouteId = parseInt((0, _unwrapProxy.unwrapProxy)(overrideRouteOptions.get('firstObject.dispatchRoute.id')), 10);
          const pickOrdinal = 1;
          const dropOrdinal = 2; //NYAAR-19315-if the broker is not eligible and the current provider type is broker

          if (!brokerEligible && isBrokerProviderType) {
            const overrideSelectedRoute = this.get('overrideSelectedRoute');
            const tripId = this.get('trip.tripId');
            const tip = 'This trip will be transferred to a broker, even though the trip is not broker-eligible. Are you sure you want to proceed?';
            const successMsg = `Trip ${tripId} successfully transferred to route ${overrideSelectedRoute.get('name')} `;
            const errorMsg = `Failed to transfer Trip ${tripId}`;
            const title = 'Transfer Trip';
            const tooltip = this.get('tooltip');
            tooltip.pushConfirmation({
              tip,
              title,
              primaryActionText: 'Confirm',
              primaryAction: async () => {
                this.cursorWait();
                await this.setBrokerEligible(true);
                await this.waitListTripInDispatch();
                this.get('manualTripTransferForDispatch').perform(dispatchRouteId, pickOrdinal, dropOrdinal, true).then(this.onPostBooking.bind(this)).then(() => {
                  this.cursorDefault();
                  this.get('notifications').success(successMsg);
                  this.onCloseClick();
                }).catch(async error => {
                  //NYAAR-18952: if IQUX call to Trip Assignment Service (TAS) fails
                  if (error.status >= STATUS_CODE) {
                    this.get('notifications').warning('Failed to transfer trip. Broker company did not respond. Trip has been placed on waitlist');
                  }

                  this.get('notifications').warning(errorMsg);
                  await this.setBrokerEligible(false);
                  await this.waitListTripInDispatch();
                  this.cursorDefault();
                  this.onCloseClick();
                }).finally(() => {
                  tooltip.reset();
                });
              },
              secondaryActionText: 'Cancel',
              secondaryAction: () => {
                tooltip.reset();
              },
              hasOverlay: true
            });
          } // if trip selected provider is non-broker type  and current select provider has a broker type
          else if (!previousSelectedTripProvider.get('isBrokerProviderType') && overrideSelectedProvider.get('isBrokerProviderType')) {
            this.cursorWait();
            await this.waitListTripInDispatch();
            this.get('manualTripTransferForDispatch').perform(dispatchRouteId, pickOrdinal, dropOrdinal, true).then(this.onPostBooking.bind(this)).then(() => {
              this.cursorDefault();
              this.onCloseClick();
            }).catch(async error => {
              //NYAAR-18952: if IQUX call to Trip Assignment Service (TAS) fails
              if (error.status >= STATUS_CODE) {
                this.get('notifications').warning('Failed to transfer trip. Broker company did not respond. Trip has been placed on waitlist');
              }

              await this.waitListTripInDispatch();
              this.cursorDefault();
              this.onCloseClick();
            });
          } // if trip selected provider and current select provider has a broker type
          else if (previousSelectedTripProvider && previousSelectedTripProvider.get('isBrokerProviderType') && overrideSelectedProvider.get('isBrokerProviderType')) {
            this.cursorWait();
            await this.onCancelBookingPatchTask();
            this.get('manualTripTransferForDispatch').perform(dispatchRouteId, pickOrdinal, dropOrdinal, true).then(this.onPostBooking.bind(this)).then(() => {
              this.cursorDefault();
              this.onCloseClick();
            }).catch(async error => {
              //NYAAR-18952: if IQUX call to Trip Assignment Service (TAS) fails
              if (error.status >= STATUS_CODE) {
                this.get('notifications').warning('Failed to transfer trip. Broker company did not respond. Trip has been placed on waitlist');
              }

              await this.waitListTripInDispatch();
              this.cursorDefault();
              this.onCloseClick();
            });
          } //if trip selected provider is broker type  and current select provider has a non-broker type
          else {
            this.onManualTripTransfer();
          }
        } else if (!Ember.isNone(currentSolutionDispatchRoute)) {
          // auto generated solutions
          this.onAutoGeneratedSolutions();
        }
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const currentSolutionRoute = this.get('currentSolutionRoute');
        const solutions = this.get('solutions');
        const currentRouteSolution = this.get('currentRouteSolution');
        const overrideSelectedProvider = this.get('overrideSelectedProvider');
        const overrideSelectedRoute = this.get('overrideSelectedRoute');
        const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
        const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
        const override = this.get('override');
        const pickTime = this.get('pickTime');
        const overridePickTime = this.get('overridePickTime');
        const dropTime = this.get('dropTime');
        const overrideDropTime = this.get('overrideDropTime'); // save current manual settings

        currentState = {
          currentSolutionRoute: (0, _unwrapProxy.unwrapProxy)(currentSolutionRoute),
          solutions,
          currentRouteSolution,
          overrideSelectedProvider,
          overrideSelectedRoute,
          overrideSelectedPickOrder,
          overrideSelectedDropOrder,
          override,
          pickTime,
          overridePickTime,
          dropTime,
          overrideDropTime
        };
        this.set('currentState', currentState);
        this.set('overrideSelectedDropOrder');
        this.onShrinkClick();
      },

      async onChangeProvider(provider) {
        const routesList = await this.get('store').peekAll('route').toArray(); // clear selected route, pick time and drop time

        this.set('pickTime', '');
        this.set('overridePickTime');
        this.set('dropTime', '');
        this.set('overrideDropTime');
        this.set('overrideSelectedRoute', null);
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.set('previousSelectedProvider', this.get('overrideSelectedProvider'));
        this.set('overrideSelectedProvider', provider);
        this.set('routesList', routesList);
        this.onValidateBrokerProvider();
      },

      async onChangeRoute(route) {
        // clear selected pick order and drop time
        this.set('pickTime', '');
        this.set('overridePickTime');
        this.set('overrideSelectedPickOrder');
        this.set('dropTime', '');
        this.set('overrideDropTime');
        this.set('overrideSelectedDropOrder');
        await this.get('coreEntityPopulator').populateRtsRoute(route.id, {}); // give it a bit of time for everything to get loaded to ember store

        Ember.run.later(() => {
          this.set('overrideSelectedRoute', route);
        }, 500);
      },

      selectSolutionRouteTab() {
        const selectedRouteId = this.get('selectedSolutionId');
        this.set('selectedImpactView', 'destination');
        this.set('activeTabRouteId', selectedRouteId);
        this.chooseImpactByRoute(selectedRouteId);
      },

      selectCurrentRouteTab() {
        const selectedRouteId = this.get('currentDispatchRoute.route.id');
        this.set('selectedImpactView', 'current');
        this.set('activeTabRouteId', selectedRouteId);
        this.chooseImpactByRoute(selectedRouteId);
      }

    }
  });

  _exports.default = _default;
});