define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/maintenance/component", ["exports", "moment", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/maintenance/maintenance-validation", "adept-iq/config/placeholders", "lodash"], function (_exports, _moment, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _maintenanceValidation, _placeholders, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DROPDOWN_MODELS = [{
    optionName: 'maintTypeOptions',
    modelName: 'maintenance-type-name'
  }, {
    optionName: 'maintStatusOptions',
    modelName: 'maintenance-status-name'
  }, {
    optionName: 'maintLocationOptions',
    modelName: 'maintenance-location-name'
  }, {
    optionName: 'problemIdentifiedOptions',
    modelName: 'problem-identified-name'
  }, {
    optionName: 'inspectionProviderOptions',
    modelName: 'inspector-name'
  }];
  const EVENT_MAINT_TYPE_MAP = {
    addVehicleMaintenance: true,
    editVehicleMaintenance: true,
    deleteVehicleMaintenance: true
  };
  const MAINTENANCE_FIELDS = ['serviceDate', 'acceptedDate', 'maintenanceDate'];

  var _default = Ember.Component.extend({
    classNames: ['maintenance'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Placeholders
    maintenanceTypePlaceholder: _placeholders.MAINTENANCE_TYPE_PLACEHOLDER,
    maintenanceStatusPlaceholder: _placeholders.MAINTENANCE_STATUS_PLACEHOLDER,
    maintenanceLocationPlaceholder: _placeholders.MAINTENANCE_LOCATION_PLACEHOLDER,
    ProblemIdentifiedPlaceholder: _placeholders.PROBLEM_IDENTIFIED_PLACEHOLDER,
    inspectionProviderNamePlaceholder: _placeholders.INSPECTION_PROVIDER_NAME_PLACEHOLDER,
    mileagePlaceholder: _placeholders.MILEAGE_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    disableBtns: null,
    vehicleInspector: null,
    maintenanceStatus: null,
    maintenanceType: null,
    maintenanceLocation: null,
    ProblemIdentified: null,
    selectedVal: null,
    maxLength: null,
    tableRef: null,
    maintenanceTableRef: null,
    disableForm: true,
    deleteBtn: true,
    showErrorMsg: false,
    timeFormat: 'm/d/Y',
    // passed into view
    editAPI: null,
    selectedVehicle: Ember.computed.readOnly('editAPI.selectedRecord'),
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedMaintenanceRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    vehicleMaintenance: Ember.computed('selectedRow', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRow'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('vehicleMaintenance.changes.length', function () {
      const vehicleMaintenance = this.get('vehicleMaintenance');
      const changes = vehicleMaintenance.get('changes') || [];
      return changes.length;
    }),
    saveBtn: Ember.computed('disableForm', 'hasPendingChanges', function () {
      const disableForm = this.get('disableForm');
      const hasTemplateChanged = this.get('hasTemplateChanged') || false;

      if (disableForm) {
        return true;
      }

      return !hasTemplateChanged;
    }),
    filteredMaintTypeOptions: Ember.computed('maintTypeOptions', 'vehicleMaintenance.scheduled', function () {
      const maintTypeOptions = this.get('maintTypeOptions') || [];
      const scheduled = this.get('vehicleMaintenance.scheduled');
      return maintTypeOptions.filterBy('scheduled', scheduled);
    }),

    init() {
      this._super(...arguments);

      const vehicle = this.get('selectedVehicle');
      this.set('timePlaceHolder', 'MM/DD/YYYY');
      this.setupDropDownValues();
      this.set('scheduled', true);
      this.set('maxLength', {
        max: 9
      });
      this.set('maintQueryParams', {
        filter: `eq(vehicleId,${vehicle.get('id')})`
      });
      this.set('activityQueryParams', {
        vehicleId: `${vehicle.get('id')}`,
        eventLog: true
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleMaintenanceRecord = this.get('onSaveVehicleMaintenanceRecord');
      const onNewVehicleMaintenanceRecord = this.get('onNewVehicleMaintenanceRecord').bind(this);
      const onEditVehicleMaintenanceRecord = this.get('onEditVehicleMaintenanceRecord').bind(this);
      const onDeleteVehicleMaintenanceRecord = this.get('onDeleteVehicleMaintenanceRecord');
      const onUndoVehicleMaintenanceRecord = this.get('onUndoVehicleMaintenanceRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleMaintenanceRecord,
          newAction: onNewVehicleMaintenanceRecord,
          deleteAction: onDeleteVehicleMaintenanceRecord,
          editAction: onEditVehicleMaintenanceRecord,
          undoAction: onUndoVehicleMaintenanceRecord,
          onValidateAction,
          hasChanges,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    hasChanges() {
      const vehicleMaintenance = this.get('vehicleMaintenance');

      if (vehicleMaintenance.get('isNew')) {
        const pendingChanges = this.get('vehicleMaintenance');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? (0, _lodash.every)(fields, field => MAINTENANCE_FIELDS.includes(field)) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const vehicleMaintenance = this.get('vehicleMaintenance');

      if (vehicleMaintenance) {
        if (!vehicleMaintenance.get('isNew') && !vehicleMaintenance.get('isDeleted')) {
          yield vehicleMaintenance.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const maintenanceTableRef = this.get('maintenanceTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (maintenanceTableRef) Ember.run.scheduleOnce('afterRender', maintenanceTableRef, 'refreshData');
    }),

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_MAINT_TYPE_MAP[event.get('actionType')];
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let vehicleMaintenanceRecord = this.get('pendingVehicleMaintenanceRecord');
      const selectedVehicle = this.get('selectedVehicle');

      if (record) {
        vehicleMaintenanceRecord = record;
        this.resetForm();
      } else if (Ember.isEmpty(vehicleMaintenanceRecord)) {
        const store = this.get('store');
        vehicleMaintenanceRecord = store.createRecord('vehicle-maintenance', {
          vehicle: selectedVehicle
        });
        this.set('pendingVehicleMaintenanceRecord', vehicleMaintenanceRecord);
      }

      if (vehicleMaintenanceRecord.get('isNew')) {
        const today = new Date();
        vehicleMaintenanceRecord.set('installDate', today);
        vehicleMaintenanceRecord.setProperties({
          scheduled: true,
          mileage: null,
          serviceDate: today,
          accepted: false,
          acceptedDate: today,
          maintenanceDate: today,
          notes: null,
          comments: null
        });
      }

      this.set('showErrorMsg', false);
      return new _emberChangeset.default(vehicleMaintenanceRecord, (0, _emberChangesetValidations.default)(_maintenanceValidation.VALIDATIONS), _maintenanceValidation.VALIDATIONS);
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = DROPDOWN_MODELS;
      dropDownModels.forEach(option => {
        const records = store.peekAll(option.modelName);
        this.set(option.optionName, records);
      });
    },

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleMaintenance = this.get('vehicleMaintenance');
      yield vehicleMaintenance.validate();
      const accepted = vehicleMaintenance.get('accepted');

      if (accepted) {
        const acceptedDateFormated = (0, _moment.default)(vehicleMaintenance.get('acceptedDate')).format('MM/DD/YYYY');
        const serviceDateFormated = (0, _moment.default)(vehicleMaintenance.get('serviceDate')).format('MM/DD/YYYY');
        const acceptedDate = (0, _moment.default)(acceptedDateFormated);
        const serviceDate = (0, _moment.default)(serviceDateFormated);

        if (acceptedDate.isBefore(serviceDate)) {
          vehicleMaintenance.addError('acceptedDate', {
            value: acceptedDate.toISOString(),
            validation: 'The Fix Accepted Date must be equal to or after the Fix Date.'
          });
        }
      }

      const errors = vehicleMaintenance.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleMaintenanceRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');

      if (isValid) {
        const notifications = this.get('notifications');
        const vehicleMaintenanceRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleMaintenance'));
        const isNewRecord = this.get('isNewRecord');
        const selectedVehicle = this.get('selectedVehicle');
        const isScheduled = this.get('vehicleMaintenance.scheduled');

        if (isNewRecord) {
          vehicleMaintenanceRecord.set('vehicle', selectedVehicle);
        }

        if (isScheduled) {
          vehicleMaintenanceRecord.set('accepted', null);
          vehicleMaintenanceRecord.set('acceptedDate', null);
          vehicleMaintenanceRecord.set('serviceDate', null);
        }

        try {
          yield vehicleMaintenanceRecord.save();
          notifications.success('Record successfully saved.');
          this.set('pendingVehicleMaintenanceRecord', null);
          this.get('refreshTask').perform().then(() => {
            Ember.run.later(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            }, 1000);
          });
          return vehicleMaintenanceRecord;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onEditVehicleMaintenanceRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');
      if (!selectedRow) return;
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = this.get('selectedRow');
      const notifications = this.get('notifications');

      try {
        yield selectedRow.destroyRecord();
        notifications.success('Vehicle Maintenance Record successfully deleted');
        this.get('refreshTask').perform().then(() => {
          Ember.run.later(() => {
            this.resetForm();
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
          }, 1000);
        });
      } catch (e) {
        notifications.warning('Vehicle Maintenance Record failed to delete');
      }
    }),
    onDeleteVehicleMaintenanceRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = this.get('selectedRow');
      const tooltip = this.get('tooltip');
      const selectedVehicle = this.get('selectedVehicle');
      if (!selectedRow) return;
      yield tooltip.pushConfirmation({
        title: 'Vehicle Management',
        tip: `Are you sure you want to delete maintenance for vehicle ${selectedVehicle.get('name')}?`,
        warning: true,
        hasOverlay: true,
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteRecord').perform();
          return tooltip.reset();
        }
      });
    }),

    onUndoVehicleMaintenanceRecord() {
      const vehicleMaintenance = this.get('vehicleMaintenance');

      if (vehicleMaintenance) {
        vehicleMaintenance.rollback();
      }
    },

    onNewVehicleMaintenanceRecord() {
      const selectedMaintenanceRow = this.get('selectedMaintenanceRow');
      const editAPI = this.get('editAPI');

      if (selectedMaintenanceRow) {
        selectedMaintenanceRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleMaintenanceRecord() {
        return this.onSaveVehicleMaintenanceRecord.perform();
      },

      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      },

      onRadioButtonClick(val) {
        this.set('vehicleMaintenance.scheduled', val);
      }

    }
  });

  _exports.default = _default;
});