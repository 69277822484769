define("adept-iq/pods/components/side-drawer/activity-log/route-activity-log/config", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.editableSections = _exports.columns = void 0;
  const TIME_FORMAT = 'YYYY-MM-DD hh:mmA';
  const columns = [{
    label: 'Activity',
    valuePath: 'activity'
  }, {
    label: 'Activity Time',
    valuePath: 'date',
    format: date => (0, _moment.default)(date).format(TIME_FORMAT)
  }, {
    label: 'Log Time',
    valuePath: 'logTime',
    format: logTime => (0, _moment.default)(logTime).format(TIME_FORMAT)
  }, {
    label: 'Details',
    valuePath: 'details'
  }, {
    label: 'User',
    valuePath: 'user'
  }];
  _exports.columns = columns;
  const editableSections = [{
    title: 'Add Activity Log Entry',
    fields: [{
      id: 'activity-type',
      type: 'enum',
      label: 'Activity',
      valuePath: 'userActivitySelected',
      cellDesc: 'Activity',
      editable: true,
      hidden: false,
      extra: {
        optionModelName: 'route-activity-log-type',
        optionSearchPath: 'displayName',
        optionConfigCategoryName: 'config-System_Configuration-Activity_Log_Types-route'
      }
    }, {
      id: 'Details',
      type: 'text-area',
      editable: true,
      label: 'Details',
      valuePath: 'details'
    }]
  }];
  _exports.editableSections = editableSections;
});