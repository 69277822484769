define("adept-iq/mixins/fetchAssignableVehicleDrivers", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/utils/vehicleCapacity", "adept-iq/config/mapped-permIds", "adept-iq/mixins/version-check", "lodash", "moment"], function (_exports, _emberConcurrency, _unwrapProxy, _vehicleCapacity, _mappedPermIds, _versionCheck, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Mixin.create(_versionCheck.default, {
    permissionLayer: Ember.inject.service(),
    disptachSchedule: Ember.computed.readOnly('record.dispatchRoute.dispatchSchedule'),
    resetVehicles: Ember.observer('plannedStartTime', 'plannedEndTime', 'provider', 'usePlaceHolderTask', function () {
      if (this.get('usePlaceHolderTask')) {
        return this.get('fetchPlaceHolderVehiclesTask').perform();
      }

      return this.get('fetchVehiclesTask').perform();
    }),
    resetDrivers: Ember.observer('plannedStartTime', 'plannedEndTime', 'provider', function () {
      return this.get('fetchDriversTask').perform();
    }),
    fetchVehiclesTask: (0, _emberConcurrency.task)(function* () {
      let vehicles = this.get('store').peekAll('vehicle');
      const filterVehiclesByHoliday = this.get('filterVehiclesByHoliday');

      if (!vehicles || !vehicles.length) {
        vehicles = yield this.get('store').query('vehicle', {
          useVehicleService: true
        });
      }

      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('provider'));
      const plannedStart = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEnd = (0, _moment.default)(this.get('plannedEndTime'));
      const assignVehicleOverCapacityPerm = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.assignVehicleOverCapacity, null);
      const dpScheduleId = this.get('dispatchRoute.dispatchSchedule.id') || this.get('record.dispatchSchedule.id');
      if (!vehicles || !vehicles.length) return []; // make sure vehicles are active and stay in the same provider as route

      if (provider) {
        vehicles = vehicles.filter(vehicle => {
          return vehicle.get('active') && vehicle.get('vehicleEntityStatus') !== 'breakdown' && provider.get('id') === vehicle.get('provider.id');
        });
      } // make sure any existing route->vehicle assignments in dispatch domain
      // do not intersect with destination route's planned window;


      vehicles = vehicles.filter(vehicle => {
        // data coming from vehicleWidget
        if (vehicle.get('vehicleEntityStatus') === 'breakdown') return false;
        if (vehicle.get('isCompleted')) return true;
        let rvdHistory = vehicle.get('rvdHistory') ? Object.values(vehicle.get('rvdHistory')) : [];
        let isAvailable = true;
        rvdHistory = rvdHistory.filter(rvd => rvd.dpScheduleId === dpScheduleId && !rvd.isCompleted);
        rvdHistory.forEach(rvd => {
          const startMoment = (0, _moment.default)(rvd.startTime);
          const endMoment = (0, _moment.default)(rvd.endTime);

          if (startMoment.isBetween(plannedStart, plannedEnd, null, '[)') || endMoment.isBetween(plannedStart, plannedEnd, null, '(]') || plannedStart.isBetween(startMoment, endMoment, null, '[)') || plannedEnd.isBetween(startMoment, endMoment, null, '(]')) {
            isAvailable = false;
          }
        });
        return isAvailable;
      }); // availability is disabled we should still filter for valid vehicles to execute a route

      const tnVehicleCapacityItinerary = this.get('dispatchRoute.tnVehicleCapacityItinerary'); // show vehicles that does not have enough capacity if user have permission

      if (!assignVehicleOverCapacityPerm && Ember.isPresent(tnVehicleCapacityItinerary)) {
        vehicles = vehicles.filter(function (vehicle) {
          const capacityCounts = vehicle.get('capacityCounts');
          return tnVehicleCapacityItinerary.every(travelNeedCounts => !(0, _vehicleCapacity.notEnoughCapacity)(travelNeedCounts, capacityCounts));
        });
      } // filter unneeded placeholder vehicles


      vehicles = vehicles.filter(vehicle => !vehicle.get('placeholder'));

      if (filterVehiclesByHoliday) {
        vehicles = yield this.filterDriverAndVehicleByHolidayAvailability(vehicles, 'vehicle-availability', 'vehicleId');
      }

      this.set('vehicles', vehicles);
    }),
    fetchPlaceHolderVehiclesTask: (0, _emberConcurrency.task)(function* () {
      const provider = this.get('provider');
      let vehicles = this.get('store').peekAll('vehicle');

      if (!vehicles || !vehicles.length) {
        vehicles = yield this.get('store').query('vehicle', {
          useVehicleService: true
        });
      }

      if (provider) {
        vehicles = vehicles.filter(vehicle => vehicle.get('provider.id') === provider.get('id'));
      }

      const placeholderVehicles = vehicles.filterBy('placeholder', true);
      this.set('vehicles', placeholderVehicles);
    }),
    fetchDriversTask: (0, _emberConcurrency.task)(function* () {
      let drivers = this.get('store').peekAll('driver');
      const filterDriversByHoliday = this.get('filterDriversByHoliday');
      const filterDriversByShiftTime = this.get('filterDriversByShiftTime');

      if (!drivers || !drivers.length) {
        drivers = yield this.get('store').findAll('driver');
      }

      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('provider'));
      const plannedStart = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEnd = (0, _moment.default)(this.get('plannedEndTime'));
      const dpScheduleId = this.get('dispatchRoute.dispatchSchedule.id') || this.get('record.dispatchSchedule.id');
      if (!drivers || !drivers.length) return []; // make sure vehicles are active and stay in the same provider as route

      if (provider) {
        drivers = drivers.filter(driver => {
          return driver.get('active') && provider.get('id') === driver.get('provider.id');
        });
      } // make sure any existing route->vehicle assignments in dispatch domain
      // do not intersect with destination route's planned window;


      drivers = drivers.filter(driver => {
        // data coming from driverWidget
        if (driver.get('isCompleted')) return true;
        let rvdHistory = driver.get('rvdHistory') ? Object.values(driver.get('rvdHistory')) : [];
        let isAvailable = true;
        rvdHistory = rvdHistory.filter(rvd => rvd.dpScheduleId === dpScheduleId && !rvd.isCompleted);
        rvdHistory.forEach(rvd => {
          const startMoment = (0, _moment.default)(rvd.startTime);
          const endMoment = (0, _moment.default)(rvd.endTime);

          if (startMoment.isBetween(plannedStart, plannedEnd, null, '[)') || endMoment.isBetween(plannedStart, plannedEnd, null, '(]') || plannedStart.isBetween(startMoment, endMoment, null, '[)') || plannedEnd.isBetween(startMoment, endMoment, null, '(]')) {
            isAvailable = false;
          }
        });
        return isAvailable;
      });

      if (filterDriversByHoliday) {
        drivers = yield this.filterDriverAndVehicleByHolidayAvailability(drivers, 'driver-availability', 'driverId');
      }

      if (filterDriversByShiftTime) {
        drivers = yield this.filterDriversByShitTimeAvailability(drivers, plannedStart, plannedEnd);
      }

      this.set('drivers', drivers);
    }),

    /**
     * In dispatch workspace  routes are holidays routes then we should filter the vehicles and drivers by holiday availabities
     * @param records
     * @param availabilityModel
     * @param groupByKey
     * @returns {Promise<*>}
     */
    async filterDriverAndVehicleByHolidayAvailability(records, availabilityModel, groupByKey) {
      const startDate = (0, _moment.default)(this.get('workspace.startDate')).format(DATE_FORMAT);
      const holidayDates = this.getHolidayDates();
      const isHoliday = holidayDates.some(holiday => (0, _moment.default)(holiday).isSame(startDate));
      if (!isHoliday) return records;
      const availabilities = await this.get('store').query(availabilityModel, {});
      const availabilityMap = (0, _lodash.groupBy)(availabilities.toArray(), groupByKey);

      if (availabilityModel === 'driver-availability' && groupByKey === 'driverId') {
        //Save the driverAvailabilityMap for further uses
        this.set('driverAvailabilityMap', availabilityMap);
      }

      return records.filter(record => {
        const availabilityList = availabilityMap[record.get('id')] || [];
        return availabilityList.some(vehicleAvailability => vehicleAvailability.get('holiday'));
      });
    },

    getHolidayDates() {
      const csConfigItems = this.get('store').peekAll('cs-config-item').filter(csItem => csItem.get('category') === 'config-System_Configuration-holidays');
      const holidayDates = csConfigItems.filter(cs => Ember.isPresent(cs.value.date || cs.value.Date)).map(csConfigItem => {
        const dateString = csConfigItem.value.date || csConfigItem.value.Date;
        const momentDate = (0, _moment.default)();
        const dates = dateString.split('/');
        const month = parseInt(dates[0], 10);
        const date = parseInt(dates[1], 10);
        momentDate.set('month', month - 1);
        momentDate.set('date', date);
        return momentDate.format(DATE_FORMAT);
      });
      return holidayDates;
    },

    /**
     * We should filter the drivers by their shiftTime availabilities
     * @param records
     * @param plannedStart
     * @param plannedEnd
     * @returns {Promise<*>}
     */
    async filterDriversByShitTimeAvailability(records, plannedStart, plannedEnd) {
      let driverAvailabilityMap = this.get('driverAvailabilityMap');

      if ((0, _lodash.isEmpty)(driverAvailabilityMap)) {
        const availabilities = await this.get('store').query('driver-availability', {});
        driverAvailabilityMap = (0, _lodash.groupBy)(availabilities.toArray(), 'driverId');
      }

      return records.filter(record => {
        const rvdDayOfWeek = plannedStart.format('dddd').toLowerCase();
        const availabilityList = driverAvailabilityMap[record.get('id')] || [];
        return availabilityList.some(availability => {
          const driverStartMoment = availability.startTime;
          const driverEndMoment = availability.endTime;
          const shiftStartTime = (0, _moment.default)(availability.formattedShiftStart, 'H:mm A');
          if (!availability.routeLength) return false;
          const shiftLength = availability.routeLength.split(':');
          if (!shiftLength || shiftLength.length !== 2) return false;
          const shiftHour = shiftLength[0];
          const shiftMinute = shiftLength[1];
          const driverShiftStartTime = plannedStart.clone().set({
            h: shiftStartTime.hour(),
            m: shiftStartTime.minute()
          });
          const driverShiftEndTime = driverShiftStartTime.clone().add(shiftHour, 'hour').add(shiftMinute, 'minute');
          const isDayOfWeekAvailable = availability[rvdDayOfWeek];
          const isDriverAvailableDates = plannedStart.isSameOrAfter(driverStartMoment, 'day') && plannedEnd.isSameOrBefore(driverEndMoment, 'day');
          const isShiftMatches = parseInt(shiftHour, 10) >= 24 || plannedStart.isBetween(driverShiftStartTime, driverShiftEndTime, null, '[]') && plannedEnd.isBetween(driverShiftStartTime, driverShiftEndTime, null, '[]');
          return isDayOfWeekAvailable && isShiftMatches && isDriverAvailableDates;
        });
      });
    }

  });

  _exports.default = _default;
});