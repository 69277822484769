define("adept-iq/pods/components/login-form/component", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    errors: null,
    email: null,
    password: null,
    resetPasswordAllowed: false,
    isValid: Ember.computed('email', 'password', function () {
      const email = this.get('email');
      const password = this.get('password');
      return Ember.isPresent(email) && Ember.isPresent(password);
    }),

    init() {
      this._super(...arguments);

      if (_environment.default.APP.avlmLite) {
        this.set('resetPasswordAllowed', false);
      }
    },

    clearPassword: function () {
      setTimeout(() => {
        this.$('input[type=text]').val('');
        this.$('input[type=password]').val('');
      }, 200);
    },

    didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this.clearPassword);
    },

    actions: {
      onSubmit(email, password, e) {
        e.preventDefault();
        this.get('onSubmit')(email, password);
      }

    }
  });

  _exports.default = _default;
});