define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'driver',
    title: 'Driver Records',
    defaultSortId: 'id',
    defaultSortAsc: true,
    queryModelData: true,
    selectFirstRow: true,
    columns: [{
      id: 'id',
      index: 0,
      type: 'uuid',
      label: 'Driver ID',
      valuePath: 'driverId',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'providerID',
      index: 1,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider.name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'lastName',
      index: 2,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'firstName',
      index: 3,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'middleInitial',
      index: 4,
      type: 'text',
      label: 'MI',
      valuePath: 'middleInitial',
      defaultWidth: 100,
      format: 'HH:mm',
      searchable: true
    }, {
      id: 'driverCdlNumber',
      index: 5,
      type: 'string',
      label: 'Driver CDL',
      valuePath: 'license.license',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'startDate',
      index: 6,
      type: 'date',
      label: 'Start Date',
      valuePath: 'startDateDs870',
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      searchable: true
    }, {
      id: 'phoneNumbers',
      index: 7,
      type: 'text',
      label: 'Phone Number',
      valuePath: 'phoneNumbers.firstObject.formattedPhoneNumber',
      format: 'MM/DD/YYYY',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});