define("adept-iq/models/place", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    geocodingMethod: attr('string'),
    address: belongsTo('address'),
    location: belongsTo('location'),
    placeCategoryType: belongsTo('place-category-type'),
    segmentStops: hasMany('segment-stop'),
    riderPlaces: hasMany('rider-place'),
    startGarageVehicles: hasMany('vehicle'),
    endGarageVehicles: hasMany('vehicle'),
    shiftBreaks: hasMany('shift-break'),
    routeBreaks: hasMany('route-break'),
    breakdownVehicle: belongsTo('vehicle-breakdown', {
      inverse: 'place'
    })
  });

  _exports.default = _default;
});