define("adept-iq/pods/components/side-drawer/permissions-add-user/component", ["exports", "ember-concurrency", "adept-iq/mixins/password-validation", "adept-iq/mixins/role-provider-validation"], function (_exports, _emberConcurrency, _passwordValidation, _roleProviderValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ACTIVE_STATUS = 'active';

  var _default = Ember.Component.extend(_passwordValidation.default, _roleProviderValidation.default, {
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    onShrinkClick: null,
    errorMessages: null,
    userDetails: null,
    title: 'Select Providers',
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    userRecords: Ember.computed.readOnly('stackFrame.options.userRecords'),
    hasOneProvider: Ember.computed.lte('providersList.length', 1),
    isSaveEnabled: Ember.computed.and('userDetails.{firstName,lastName,userName,password}', 'selectedStatus', 'isPasswordValid', 'hasOneSelectedRoles', 'hasOneSelectedProviders'),
    isUndoEnabled: Ember.computed.or('userDetails.{firstName,lastName,emailId,userName,password}', 'selectedStatus', 'selectedRoles'),
    canSave: Ember.computed('isSaveEnabled', 'providersTitle', function () {
      return Ember.get(this, 'isSaveEnabled');
    }),
    canUndo: Ember.computed('isUndoEnabled', 'providersTitle', function () {
      return Ember.get(this, 'isUndoEnabled') || Ember.get(this, 'providersTitle') !== 'Select Providers';
    }),
    statusOptions: Ember.computed('selectedStatus', function () {
      return ['Active', 'Inactive'];
    }),
    roleOptions: Ember.computed('selectedRoles', function () {
      const roles = [];
      const rolesList = Ember.get(this, 'store').peekAll('perm-sso-role');
      rolesList.map(role => {
        if (role.id) {
          roles.push(role);
        }
      });
      return roles;
    }),
    providersTitle: Ember.computed('providersList.@each.isChecked', function () {
      const providers = this.get('providersList') || [];
      const checkedProviders = providers.filter(p => p.isChecked);

      if (checkedProviders.length <= 0) {
        return 'Select Providers';
      } else if (checkedProviders.length === 1) {
        return checkedProviders[0].providerName;
      } else if (checkedProviders.length === providers.length) {
        return 'All Providers';
      }

      return 'Multiple';
    }),
    visiblePassword: false,

    init() {
      this._super(...arguments);

      this.resetUserProperties();
      this.fillProviders();
      this.setSideDrawerWidth('490px');
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('systemConfig').set('sideDrawerPreventCollapse', true);
    },

    didDestroyElement() {
      this.get('systemConfig').set('sideDrawerPreventCollapse', null);
    },

    resetUserProperties() {
      this.setProperties({
        userDetails: {
          firstName: '',
          lastName: '',
          emailId: '',
          userName: '',
          password: ''
        },
        errorMessages: [],
        selectedRoles: null,
        changedProviders: null,
        selectedStatus: null,
        title: 'Select Providers',
        visiblePassword: false
      });
      this.checkUncheckAllProviders(false);
    },

    checkUncheckAllProviders(isChecked) {
      const providerList = Ember.get(this, 'providersList');
      if (Ember.isEmpty(providerList)) return;
      providerList.map(provider => {
        provider.set('isChecked', isChecked);
      });
    },

    async fillProviders() {
      const providers = [];
      const adaptor = 'perm-sso-user';
      const permUserAdapter = this.get('store').adapterFor(adaptor);
      const providersList = await permUserAdapter.getSSOProvider();
      if (Ember.isEmpty(providersList)) return;
      const configProviders = this.get('store').peekAll('provider');
      const filteredProviders = providersList.filter(provider => {
        if (provider.provider === 'ALL') return true;
        const configProvider = configProviders.find(cp => cp.id === provider.provider);

        if (configProvider) {
          return configProvider.get('userCanAccessProvider') && configProvider.get('status') && configProvider.get('status').toLowerCase() === ACTIVE_STATUS;
        }

        return false;
      });
      filteredProviders.map(provider => {
        providers.push(Ember.Object.create({
          id: provider.id,
          displayName: provider.provider === 'ALL' ? 'Select All' : provider.provider,
          providerName: provider.provider,
          isChecked: false
        }));
      });
      this.set('providersList', providers);
    },

    isValid() {
      const nameAlphanumericRegex = /^[a-z\d]+$/i;
      const usernameAlphanumericRegex = /^[a-z\d_.@]+$/i;
      let isValid = true;
      Ember.set(this, 'errorMessages', []);

      if (!Ember.isEmpty(this.userDetails.emailId)) {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (!emailRegex.test(this.userDetails.emailId)) {
          this.errorMessages.push('Please enter valid email id');
          isValid = false;
        }
      } else if (this.userDetails && this.get('userRecords')) {
        if (this.get('userRecords').some(data => data && data.userName === this.userDetails.userName)) {
          this.errorMessages.push('* Duplicated username');
          isValid = false;
        }
      }

      if (Ember.isEmpty(this.userDetails.firstName) || Ember.isEmpty(this.userDetails.lastName) || !nameAlphanumericRegex.test(this.userDetails.firstName) || !nameAlphanumericRegex.test(this.userDetails.lastName) || this.userDetails.firstName.length > 32 || this.userDetails.lastName.length > 32) {
        isValid = false;
        this.errorMessages.push('* Firstname and lastname can only have alphanumeric characters i.e. (a-z, A-Z, 0-9)  and max length up to 32 characters and can not be empty.');
        return;
      }

      if (Ember.isEmpty(this.userDetails.userName) || !usernameAlphanumericRegex.test(this.userDetails.userName) || this.userDetails.userName.length > 50) {
        isValid = false;
        this.errorMessages.push('* Username can only have alphanumeric characters i.e. (a-z, A-Z, 0-9), underscore (_), dot(.), at sign(@) and max length up to 50 characters and can not be empty.');
        return;
      }

      return isValid;
    },

    saveUser() {
      return Ember.get(this, 'saveUserTask').perform().then(() => {
        this.get('notifications').success('Added New User Successfully');
        this.get('workspace').popState('permissionsAddNewUser');
      }).catch(error => {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in creating new user, please try again.';
            break;
        }

        this.errorMessages.push(message);
        this.get('notifications').warning(message);
      });
    },

    saveUserTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const userAdapter = store.adapterFor('perm-sso-user');
        const providers = this.get('providersList');
        const checkedProviders = providers.filter(p => p.isChecked);
        const isAllProviders = checkedProviders.length > 0 && checkedProviders.length === providers.length;
        const recordedRoles = Ember.get(this, 'store').peekAll('perm-sso-role').map(role => role.id);

        if (this.selectedRoles) {
          const invalidSelectedRoles = !this.selectedRoles.every(selectedRole => {
            const role = recordedRoles.find(recordedRole => recordedRole === selectedRole.id);
            return !!role;
          });
          if (invalidSelectedRoles) throw Error('Invalid roles provided');
        }

        const payload = {
          firstName: this.userDetails.firstName,
          lastName: this.userDetails.lastName,
          // emailId: this.userDetails.emailId,
          status: this.selectedStatus ? this.selectedStatus.toLowerCase() : 'active',
          roles: this.selectedRoles ? this.selectedRoles.map(role => role.id) : [],
          username: this.userDetails.userName,
          password: this.userDetails.password,
          providers: isAllProviders ? [0] : checkedProviders.map(p => p.id)
        };
        yield userAdapter.postUser(payload);
      } catch (error) {
        throw error;
      }
    }),

    getSelectedProviders() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return [];
      const results = this.get('providersList').filterBy('isChecked', true);
      return results.map(p => {
        if (p.providerName !== 'ALL') {
          return p.id;
        }
      });
    },

    handleCheckUncheckSelectAll() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;
      let results = providersList.filterBy('isChecked', false);

      if (results.length === 1 && results[0].providerName === 'ALL') {
        results[0].set('isChecked', true);
      } else {
        results = providersList.filterBy('providerName', 'ALL');

        if (results.length > 0) {
          results[0].set('isChecked', false);
        }
      }
    },

    handleCheckUncheckProviders(provider, isChecked) {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;

      if (provider.providerName === 'ALL') {
        providersList.map(item => {
          item.set('isChecked', isChecked);
        });
      } else {
        providersList.map(item => {
          if (provider.id === item.id) {
            item.set('isChecked', isChecked);
          }
        });
        this.handleCheckUncheckSelectAll();
      }
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    showToolTip() {
      const tooltip = this.get('tooltip');
      const tip = 'Do you want to add a new user?';
      tooltip.pushConfirmation({
        title: 'Add User',
        tip,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return Ember.get(this, 'saveUserTask').perform().then(() => {
            if (this.callBack) {
              this.callBack(true);
            }

            this.get('notifications').success('Added New User Successfully');
            this.get('workspace').popState('permissionsAddNewUser');
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                if (error.message && error.message.includes('Invalid roles provided')) {
                  message = 'SELECTED ROLE IS NO LONGER AVAILABLE, PLEASE SELECT ANOTHER ROLE AND TRY AGAIN';
                } else {
                  message = 'There was a problem with one of the fields. Please check over the form and try again.';
                }

                break;

              default:
                message = error.message.startsWith('Invalid roles provided') ? 'Selected role is no longer available, please select another role and try again' : 'There was a problem in creating new user, please try again.';
                break;
            }

            this.get('notifications').warning(message);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    filterTextInput(event) {
      const alphanumeric = /[A-Za-z0-9]/;
      var keyChar = String.fromCharCode(event.which || event.keyCode);
      return alphanumeric.test(keyChar);
    },

    filterPastedInput(event) {
      const alphanumeric = /[A-Za-z0-9]/;
      if (!event.clipboardData) return false;
      const char = event.clipboardData.getData('Text');
      return alphanumeric.test(char);
    },

    filterUserNameInput(event) {
      const alphanumeric = /[A-Za-z0-9_.@]/;
      var keyChar = String.fromCharCode(event.which || event.keyCode);
      return alphanumeric.test(keyChar);
    },

    actions: {
      onChangeStatus(option) {
        Ember.set(this, 'selectedStatus', option);
      },

      onChangeRole(option) {
        Ember.set(this, 'selectedRoles', option);
      },

      onUndoClick() {
        this.resetUserProperties();
      },

      onSaveClick() {
        if (this.isValid()) {
          Ember.set(this, 'errorMessages', []);
          this.showToolTip();
        }
      },

      onCloseClick() {},

      onToggleSelect() {
        this.toggleSelect();
      },

      onOpenProvider() {
        this.toggleSelect();
      },

      onCloseProvider() {
        this.toggleSelect();
      },

      onProviderCheckboxClick(provider, event) {
        Ember.set(this, 'changedProviders', true);
        this.handleCheckUncheckProviders(provider, event.target.checked);
      },

      cancel() {
        if (this.callBack) {
          this.callBack(false);
        }
      },

      onToggleVisiblePasswordClick() {
        const visiblePassword = this.get('visiblePassword');
        this.set('visiblePassword', !visiblePassword);
      }

    }
  });

  _exports.default = _default;
});