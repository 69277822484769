define("adept-iq/config/mapped-permIds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // version:29
  const fullOperations = {
    configureWorkspace: 1,
    savePrivateWorkspace: 2,
    saveWorkspace: 3,
    saveAsWorkspace: 3,
    deletePrivateWorkspace: 4,
    deletePublicWorkspace: 5,
    deleteWorkspace: 6,
    openWorkspace: 7,
    openPrivateWorkspace: 8,
    showRoutes: 9,
    showStops: 10,
    showTrips: 11,
    showMessages: 12,
    showVehicles: 13,
    showAlerts: 14,
    showMaps: 15,
    showDrivers: 16,
    showRoles: 17,
    showZones: 18,
    showSubscriptions: 19,
    downloadRoutes: 20,
    downloadStops: 21,
    downloadTrips: 22,
    downloadVehicles: 23,
    downloadDrivers: 24,
    showSystemConfig: 25,
    newRole: 26,
    editRole: 27,
    deleteRole: 28,
    newUser: 29,
    editUser: 30,
    resetUserPass: 31,
    enableDisableUser: 32,
    timeline: 33,
    createSameDayReplacement: 34,
    emergencyAcknowledge: 35,
    emergencyResolve: 36,
    emergencyCancel: 37,
    reportBreakdown: 38,
    reportRescue: 39,
    createRoute: 40,
    editRoute: 41,
    deleteRoute: 42,
    lockUnlockRoute: 43,
    limitRoute: 44,
    startRoute: 45,
    endRoute: 46,
    createFuture: 47,
    // OBSOLETE
    createSameDay: 48,
    // OBSOLETE
    cancelFuture: 49,
    cancelSameDay: 50,
    editFuture: 51,
    autoTransferTrip: 52,
    transferTrip: 53,
    waitlist: 54,
    noshow: 55,
    editPast: 56,
    editSameDay: 57,
    markStopArrived: 58,
    markStopDeparted: 59,
    noShowTrip: 60,
    unperformStop: 61,
    navigateStop: 62,
    editStop: 63,
    reorderStops: 64,
    addBreak: 65,
    editBreak: 66,
    deleteBreak: 67,
    newPassenger: 68,
    editPassenger: 69,
    newVehicle: 70,
    editVehicle: 70,
    newdriver: 70,
    editdriver: 70,
    autoReschedule: 71,
    resetDeviceAction: 72,
    vehicleBreakdown: 73,
    playbackVehicle: 74,
    accessPlaybackMode: 74,
    cannedMessageToDriver: 75,
    freeTypeMessageToDriver: 76,
    messageToDispatch: 77,
    newIncident: 78,
    deleteIncident: 79,
    editIncident: 80,
    viewIncident: 81,
    editPlaces: 82,
    newService: 83,
    editService: 83,
    navigateMap: 84,
    addressPinpoint: 85,
    closeSchedule: 86,
    generateSchedule: 87,
    reoptimizeSchedule: 88,
    manageRouteSchedule: 89,
    SignInADEPTIQ: 90,
    showUsers: 91,
    showPassengers: 92,
    downloadMessages: 93,
    downloadAlerts: 94,
    downloadRoles: 95,
    downloadUsers: 96,
    downloadsubscriptions: 97,
    downloadPassengers: 98,
    downloadZones: 99,
    assignVehicleDriver: 100,
    approveNoShowRequest: 101,
    denyNoShowRequest: 101,
    overrideTripCapacityLimits: 102,
    newSubscription: 103,
    createSubscription: 103,
    editSubscription: 104,
    activityLogUser: 105,
    activityLogRoute: 106,
    activityLog: 106,
    activityLogVehicle: 106,
    showSchedule: 107,
    downloadSchedule: 108,
    editContractManagement: 109,
    assignVehicleOverCapacity: 110,
    createFutureBookings: 111,
    createSameDayBookings: 112,
    cancelFutureBookings: 113,
    cancelSameDayBookings: 114,
    editFutureBookings: 115,
    editSameDayBookings: 116,
    overrideEligibilityConditions: 117,
    overrideITP: 118,
    overrideWheelchairRequest: 119,
    deleteBooking: 120,
    deleteSubscription: 121,
    accessBookingWorkspace: 122,
    accessReconcileWorkspace: 123,
    retrieveHistoricalData: 124,
    editRouteInfoRecon: 125,
    editRouteLocRecon: 126,
    deleteRouteRecon: 127,
    verifyRouteRecon: 128,
    createRescueReplacementRecon: 129,
    reportBreakdownRecon: 130,
    createBreakdownReplacementRecon: 131,
    reopenRouteRecon: 132,
    addNewRouteRecon: 133,
    addNewTripRecon: 134,
    editTripBookingInfoRecon: 135,
    editTripInfoRecon: 136,
    transferTripRecon: 137,
    transferTripToTaxiRecon: 138,
    cancelTripRecon: 139,
    noShowTripRecon: 140,
    unperformNoShowRecon: 141,
    insertBreakRecon: 142,
    addTripLogMsgRecon: 143,
    addRouteLogMsgRecon: 144,
    closeEditDateRecon: 145,
    reopenEditDateRecon: 146,
    sortRouteGridRecon: 147,
    viewUnscheduledTripsRecon: 148,
    scheduleTripsRecon: 149,
    searchByClientRecon: 150,
    searchByDriverRecon: 151,
    searchByVehicleRecon: 152,
    searchByAddrRecon: 153,
    searchByTripRecon: 154,
    editColumnRecon: 155,
    mapTripRecon: 156,
    accessPermissionsWorkspace: 157,
    accessDispatchWorkspace: 158,
    accessRoadSupervisorWorkspace: 159,
    accessSchedulingWorkspace: 88,
    accessVehicleDriverMgmntWorkspace: 70,
    downloadMaps: 0,
    sendRouteMessage: 0,
    navigateIntercept: 0,
    sendMessageVehicle: 0,
    showAllMessage: 0,
    hideMessages: 0,
    readMessages: 0,
    sendMessageDriver: 0,
    showAllAlerts: 0,
    hideAlerts: 0,
    undoRouteStart: 160,
    undoRouteEnd: 161,
    unlockOtherUserLocks: 162,
    overrideNoShowReason: 163,
    accessRiderManagementWorkspace: 164,
    accessReportsWorkspace: 165,
    showReports: 165,
    cancelSubscriptionTrips: 166,
    editRideshareBooking: 167,
    editRideshareSubscription: 168,
    editRideshareDispatch: 169,
    editRideshareScheduling: 170,
    editLoadUnloadBooking: 171,
    editLoadUnloadSubscription: 172,
    openZones: 173,
    copyBooking: 174,
    transferBrokerTripDispatch: 175,
    transferBrokerTripScheduling: 176,
    addBookingfromDispatch: 178,
    copyTrip: 179,
    manageServiceZones: 180,
    manageCancelTrips: 181,
    manageBookTrips: 182,
    manageCannedMessages: 183,
    manageDBPurgeParameters: 184,
    manageDrivingConfiguration: 185,
    manageFixedRouteEngineParameters: 186,
    manageDriverAppAdminPassword: 187,
    manageRouteParameters: 188,
    manageScheduling: 189,
    manageSchedulingParms: 190,
    manageSystemConfiguration: 191,
    manageADABoundaries: 192,
    designReports: 193,
    overrideADABoundaries: 194,
    editPassengerNotesBooking: 195,
    editPassengerNotesSubscription: 196,
    editPassengerNotesScheduling: 197,
    editPassengerNotesDispatch: 198,
    accessCloseoutReport: 199,
    accessDeductReport: 200,
    setPreferredProviderSameDayBooking: 202,
    setPreferredProviderFutureBooking: 203,
    setPreferredProviderSubscription: 204,
    setServiceTypetoDAnchorSub: 205,
    manageSpeedRegions: 206,
    manageBlockoutRegions: 207,
    editFareCategoryBooking: 214,
    editFareCategorySubscription: 215,
    editFareBooking: 217,
    editFareSubscription: 218,
    manageRoadSupervisorConfiguration: 219,
    manageUserAccounts: 220
  };
  const operations = fullOperations;
  var _default = operations;
  _exports.default = _default;
});