define("adept-iq/serializers/trip-stop", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    normalize(modelClass, resourceHash) {
      const relationships = {};

      if (!Ember.isNone(resourceHash.attributes.tripPk) && resourceHash.stopType === 'pick') {
        relationships.pickTripStop = {
          data: {
            type: 'trip',
            id: resourceHash.attributes.pickId
          }
        };
      }

      if (!Ember.isNone(resourceHash.attributes.tripPk) && resourceHash.stopType === 'drop') {
        relationships.dropTripStop = {
          data: {
            type: 'trip',
            id: resourceHash.attributes.dropId
          }
        };
      }

      resourceHash.relationships = relationships;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      payload.data.relationships = payload.data.relationships || {};
      return payload;
    }

  });

  _exports.default = _default;
});