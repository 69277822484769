define("adept-iq/pods/components/generic-widgets/column-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "ember-light-table", "moment", "adept-iq/classes/data-joins/column-widget", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column", "adept-iq/utils/compute-ordered-ids", "ember-concurrency", "papaparse", "lodash", "adept-iq/utils/mobile", "adept-iq/config/mapped-permIds", "adept-iq/utils/remove-focus"], function (_exports, _component, _emberLightTable, _moment, _columnWidget, _filterColumn, _computeOrderedIds, _emberConcurrency, _papaparse, _lodash, _mobile, _mappedPermIds, _removeFocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_COLUMN_WIDTH = 100;
  const MIN_COLUMN_WIDTH = 50;
  const CHECKBOX_COLUMN_WIDTH = 30; // number of records to load at a time while scrolling

  const PAGINATION_LIMIT = 50; // how close to bottom do we need to scroll before loading more

  const PAGINATION_TRIGGER_OFFSET = 100;

  const toPixels = x => `${x}px`;

  const fromPixels = x => parseInt(x.split('px')[0], 10);

  var _default = _component.default.extend({
    workspace: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    interaction: Ember.inject.service(),
    work: Ember.inject.service(),
    booking: Ember.inject.service(),
    canShowHeader: true,
    routesFilter: null,
    classNames: ['column-widget'],
    classNameBindings: ['disabled'],
    // overload this with a static config object
    config: null,
    limit: PAGINATION_LIMIT,
    offset: 0,
    paginationEnabled: true,
    componentName: null,
    tableActions: null,
    allowedTableActions: null,
    downloadAction: null,
    singleActions: null,
    bulkActions: null,
    records: null,
    visibleRecords: null,
    searchText: '',
    isSearchEnabled: false,
    isSearchAllowed: true,
    // set for demo
    isFilterAllowed: true,
    // set for demo
    isCellEditAllowed: false,
    localQuickFilters: null,
    table: null,
    dataJoin: null,
    lastRowClicked: false,
    inlineRowMenu: null,
    inlineRowMenuPosition: null,
    refreshSync: false,
    resetScroll: false,
    isPaginating: false,
    scrollClassName: null,
    allowDisplayHash: null,
    sortId: Ember.computed.alias('widget.state.sortId'),
    sortAsc: Ember.computed.alias('widget.state.sortAsc'),
    tileSize: Ember.computed.alias('workspace.tileSize'),
    tileSpacing: Ember.computed.alias('workspace.tileSpacing'),
    isLoading: Ember.computed.alias('fetchDataTask.isRunning'),
    isError: Ember.computed.alias('fetchDataTask.last.isError'),
    useBulkActions: Ember.computed.gt('checkedRowsCount', 1),
    reoptimizeInProgress: Ember.computed.readOnly('workspace.reoptimizeInProgress'),
    disableRightClick: Ember.computed.or('workspace.{isExported,reoptimizeInProgress}'),
    isRowInCheckedRows: Ember.computed('lastRowClicked', 'table.rows', function () {
      const lastRowClicked = this.get('lastRowClicked');
      const checkedRows = this.get('table.rows').filterBy('isChecked') || [];

      if (!lastRowClicked) {
        return false;
      }

      return checkedRows.some(row => row.get('content.id') === lastRowClicked.get('id'));
    }),
    showBulkActions: Ember.computed.and('useBulkActions', 'isRowInCheckedRows'),
    showSingleActions: Ember.computed.equal('showBulkActions', false),
    checkedRowsCount: Ember.computed('table.rows.@each.isChecked', function () {
      const checkedRows = this.get('table.rows').filterBy('isChecked') || [];
      return checkedRows.length;
    }),
    checkedItemsCount: Ember.computed('activeContext.topActiveContext.checkedItems.[]', function () {
      let items = [];
      const modelName = this.get('config.modelName');
      const checkedItems = this.get('activeContext.topActiveContext.checkedItems') || []; // modelName will also be a array like in stop-widget for schedule workspace

      if (Array.isArray(modelName)) {
        items = checkedItems.filter(i => modelName.includes(i.modelName)) || [];
        return items.length;
      }

      items = checkedItems.filter(i => i.modelName === modelName) || [];
      return items.length;
    }),
    isFiltered: Ember.computed('table.columns.@each.{isFiltered}', function () {
      return this.get('table.columns').isAny('isFiltered');
    }),
    tableColumns: Ember.computed('config.{hideCheckBox,columns}', function () {
      const hideCheckBox = this.get('config.hideCheckBox');
      const tableColumns = hideCheckBox ? [{
        id: 'checkbox',
        valuePath: 'checked',
        sortable: false,
        width: '0px'
      }] : [{
        id: 'checkbox',
        valuePath: 'checked',
        cellComponent: 'table/cells/check-box',
        component: 'table/columns/check-box',
        sortable: false,
        width: _mobile.isTouchDevice ? '70px' : toPixels(CHECKBOX_COLUMN_WIDTH)
      }];
      const columnConfigs = this.get('config.columns');
      const orderedColumnIds = (0, _computeOrderedIds.default)(columnConfigs) || [];
      orderedColumnIds.forEach(id => {
        const config = columnConfigs.findBy('id', id);
        let allowColumn = this.get('allowDisplayHash')[config.allowDisplayAttr]; // disable a specific column depending if allowDisplayAttr exist in config

        allowColumn = Ember.isPresent(allowColumn) ? allowColumn : true;
        const tableColumn = {
          id: id,
          component: 'table/columns/filter-capable',
          cellClassNames: config.id,
          classNames: config.id,
          label: config.label,
          cellDesc: config.cellDesc,
          valuePath: `record.${config.valuePath}`,
          valuePaths: config.valuePaths,
          hidden: config.hidden || config.unAvailable || !allowColumn,
          allowColumn,
          resizable: true,
          minResizeWidth: MIN_COLUMN_WIDTH,
          width: toPixels(config.defaultWidth || DEFAULT_COLUMN_WIDTH),
          config
        };

        if (config.editable && this.isCellEditAllowed) {
          if (config.type === 'enum') {
            tableColumn.cellComponent = 'table/cells/user-editable-enum';
            tableColumn.options = config.options;
            tableColumn.allowMultiple = config.allowMultiple;
          } else {
            tableColumn.cellComponent = 'table/cells/user-editable';
          }
        } else if (config.type.includes('enum')) {
          tableColumn.cellComponent = 'table/cells/text-extension';
        } else {
          tableColumn.cellComponent = config.cellComponent ? config.cellComponent : 'table/cells/base-cell';
        }

        if (config.highlightable) {
          tableColumn.cellClassNames = ['highlightable'];
        }

        if (['date', 'datetime', 'time', 'datetimeflatpickr'].includes(config.type) && config.format) {
          tableColumn.format = value => {
            return Ember.isEmpty(value) ? value : (0, _moment.default)(value).format(config.format);
          };

          switch (config.type) {
            case 'date':
            case 'datetimeflatpickr':
            case 'datetime':
              tableColumn.parse = value => {
                return (0, _moment.default)(value, config.format).toDate();
              };

              break;

            case 'time':
              tableColumn.parse = (timeString, originalValue) => {
                const date = (0, _moment.default)(originalValue).format('YYYY-MM-DD');
                const newValue = (0, _moment.default)(`${date} ${timeString}`, 'YYYY-MM-DD hh:mm A').toDate();
                return newValue;
              };

              break;

            default:
              break;
          }
        }

        tableColumns.push(tableColumn);
      }); // fills the rest of the space with an empty column

      tableColumns.push({
        id: 'spacer',
        width: '100%',
        sortable: false,
        isResizable: true
      });
      return tableColumns;
    }),
    click: function (event) {
      if (['fa fa-search active-button', 'column-widget-search-box data-test-search-box'].includes(event.target.className)) {
        return false;
      }

      (0, _removeFocus.removeFocus)();
    },

    // populate allowDisplayHash for columns in column widget attribute called allowDisplayAttr
    configureAllowDisplayHash() {
      this.set('allowDisplayHash', {
        allowFundingAgency: this.get('booking').getAllowFundingAgency()
      });
    },

    init() {
      this._super(...arguments);

      this.configureAllowDisplayHash();
      const table = new _emberLightTable.default([], [], {
        enableSync: false
      });

      const dataJoin = _columnWidget.default.create({
        table,
        activeContext: this.get('activeContext.topActiveContext'),
        work: this.get('work'),
        modelName: this.get('config.modelName')
      });

      this.setProperties({
        table,
        dataJoin,
        records: [],
        visibleRecords: []
      });
      this.setupDefaultTableAction();
      this.configureSearchFilterPermission();
      this.set('widget.models', (0, _lodash.castArray)(this.get('config.modelName')));

      this._onScroll = () => {
        const dropdown = this.get('inlineRowMenu');
        const plusMenu = this.get('plusIconMenu');
        if (dropdown) dropdown.actions.close();
        if (plusMenu) plusMenu.actions.close();
      };

      const typeId = this.get('widget.typeId');
      this.set('scrollClassName', `column-widget-filters-scroll-${typeId}`);
      this.set('componentName', `${typeId}Widget`);
      this.setupActiveQuickFilter();
    },

    setupActiveQuickFilter() {
      if (this.get('isMaximized')) {
        const maximizedWidget = this.get('maximizer.maximizedWidget');
        const maximizedWidgetInstanceId = `${maximizedWidget.typeId}_${maximizedWidget.id}`;
        const widgetInstanceId = `${this.get('widget.typeId')}_${this.get('widget.id')}`;
        const localQuickFilters = this.get('quickFilters') || [];
        const activeQuickFilter = this.get('maximizer.activeQuickFilter');

        if (maximizedWidgetInstanceId === widgetInstanceId) {
          if (Ember.isPresent(activeQuickFilter)) {
            localQuickFilters.forEach(filter => {
              if (filter.id === activeQuickFilter.id) {
                filter.active = true;
              } else {
                filter.active = false;
              }
            });
          } else {
            localQuickFilters.forEach(filter => {
              filter.active = false;
            });
          }
        }
      }
    },

    destroy() {
      const tableColumns = this.get('table.columns') || [];
      this.get('dataJoin').destroy();
      tableColumns.forEach(column => column.destroy());
      this.get('table').destroy();
      this._onScroll = null;

      this._super(...arguments);
    },

    isMaximized: Ember.computed('maximizer.maximizedWidget', 'widget', function () {
      return this.get('maximizer.maximizedWidget') === this.get('widget');
    }),
    quickFiltersExist: Ember.computed('widgetState', function () {
      const quickFilters = this.get('widgetState.quickFilters');

      if (Ember.isPresent(quickFilters) && quickFilters.length > 0) {
        return true;
      }

      return false;
    }),
    quickFilters: Ember.computed('widgetState.quickFilters', function () {
      const quickFilters = this.get('widgetState.quickFilters') || [];
      const localQuickFilters = this.get('localQuickFilters');
      const widgetInstanceId = `${this.get('widget.typeId')}_${this.get('widget.id')}`;

      if (Ember.isEmpty(localQuickFilters)) {
        // eslint-disable-next-line ember/no-side-effects
        const newQuickFilters = (0, _lodash.cloneDeep)(quickFilters);
        newQuickFilters.forEach(filter => {
          filter.widgetInstanceId = widgetInstanceId;
        }); // eslint-disable-next-line ember/no-side-effects

        this.set('localQuickFilters', newQuickFilters || []);
      } else {
        const newLocalQuickFilters = []; // add newly created filter to local array of quick filters

        quickFilters.forEach(newFilter => {
          const exist = localQuickFilters.find(currFilter => currFilter.id === newFilter.id);

          if (!exist) {
            localQuickFilters.push((0, _lodash.cloneDeep)(newFilter));
          }
        }); // remove filter from local array of quick filters

        localQuickFilters.forEach(currFilter => {
          const exist = quickFilters.find(newFilter => currFilter.id === newFilter.id);

          if (exist) {
            newLocalQuickFilters.push(currFilter);
          }
        }); // eslint-disable-next-line ember/no-side-effects

        this.set('localQuickFilters', newLocalQuickFilters);
      }

      return this.get('localQuickFilters');
    }),
    quickFilters2: Ember.computed('widgetState', function () {
      const widgetState = this.get('widgetState') || {};
      return widgetState.quickFilters || [];
    }),
    widgetState: Ember.computed('workspace.widgetStates', 'widget.typeId', function () {
      const widgetStates = this.get('workspace.widgetStates') || {};
      const widgetTypeId = this.get('widget.typeId');
      const widgetState = widgetStates[widgetTypeId] || {};
      return widgetState;
    }),

    setupDefaultTableAction() {
      const downloadAction = {
        id: 'print',
        permIds: [_mappedPermIds.default.downloadRoutes, _mappedPermIds.default.downloadStops, _mappedPermIds.default.downloadTrips, _mappedPermIds.default.downloadVehicles, _mappedPermIds.default.downloadDrivers, _mappedPermIds.default.downloadMessages, _mappedPermIds.default.downloadAlerts, // mappedPermIds.downloadMaps,
        _mappedPermIds.default.downloadRoles, _mappedPermIds.default.downloadUsers, _mappedPermIds.default.downloadsubscriptions, _mappedPermIds.default.downloadPassengers, _mappedPermIds.default.downloadZones, _mappedPermIds.default.downloadSchedule //mappedPermIds.downloadRoadSupervisors,
        ],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      };
      this.set('downloadAction', downloadAction); // eslint-disable-next-line new-cap

      this.set('tableActions', Ember.A([downloadAction]));
      this.set('allowedTableActions', this.get('tableActions'));
    },

    configureSearchFilterPermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const widgetStates = this.get('workspace.widgetStates');
      const widgetTypeId = this.get('widget.typeId');

      if (!Ember.isNone(configPermissions)) {
        const configPermission = configPermissions[widgetTypeId];

        if (!Ember.isNone(configPermission.searchAllowed)) {
          this.set('isSearchAllowed', configPermission.searchAllowed);
        }

        if (!Ember.isNone(configPermission.filterAllowed)) {
          this.set('isFilterAllowed', configPermission.filterAllowed);
        }

        if (!Ember.isNone(configPermission.cellEditAllowed)) {
          // @TODO RUX-325 will disable this feature for now, eventually it will return in R5.0+
          this.set('isCellEditAllowed', false);
        }

        if (!Ember.isNone(widgetStates)) {
          this.set('widgetStates', widgetStates);
        }
      }
    },

    exportData() {
      const table = this.get('table');
      const columns = table.get('columns').rejectBy('hidden') || [];
      const rows = table.get('rows') || [];
      const fields = columns.reduce((arr, column) => {
        const id = column.get('id');

        if (['checkbox', 'spacer'].includes(id)) {
          return arr;
        }

        const label = column.get('label');
        arr.push(label);
        return arr;
      }, []);
      const data = rows.map(row => {
        return columns.reduce((arr, column) => {
          const config = column.get('config');
          const id = column.get('id');
          const csvReplaceRegex = config && config.csvReplaceRegex ? config.csvReplaceRegex : null;
          let value = '';

          if (['checkbox', 'spacer'].includes(id)) {
            return arr;
          }

          const valuePath = column.get('valuePath');

          if (csvReplaceRegex && csvReplaceRegex.pattern) {
            const pattern = csvReplaceRegex.pattern;
            const flag = csvReplaceRegex.flag ? csvReplaceRegex.flag : 'g';
            const replace = csvReplaceRegex.replaceWith ? csvReplaceRegex.replaceWith : '';
            const regex = new RegExp(pattern, flag);
            value = row.get(valuePath).replace(regex, replace);
          } else {
            value = row.get(valuePath);
          }

          if (Ember.isPresent(value)) {
            if (config.type.includes('enum')) {
              let values = null;

              if (Ember.isPresent(value.values)) {
                values = value.values.map(obj => obj.value);
                value = values.join(';');
              } else if (Ember.isEmpty(value.values)) value = '';
            } else if (config.type === 'datetime' || config.type === 'time' || config.type === 'date') {
              value = (0, _moment.default)(value).local().format(config.format);
            }
          }

          arr.push(value);
          return arr;
        }, []);
      });

      const csv = _papaparse.default.unparse({
        fields,
        data
      }, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"'
      });

      const link = document.createElement('a');
      const timestamp = (0, _moment.default)().format('YYYY-MM-DD_HH-MM-SS');
      const {
        title
      } = this.get('config');
      const filename = `${timestamp}_${title}.csv`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.get('config')) {
        // eslint-disable-next-line
        throw 'static config property not found';
      } // bind screen scroll to close any opened drop down menus


      Ember.$('.tile-dashboard-wrapper').on('scroll', this._onScroll);
      this.onTableColumnsChange();
      this.onWidgetStateChange();
      this.onRecordsChange();
      this.reConfigureTableActions();
      this.reConfigureSingleActions();
      Ember.run.scheduleOnce('afterRender', this, 'refreshData'); // listen for column resize

      if (typeof MutationObserver !== 'undefined') {
        const mutationObserver = new MutationObserver(mutationList => {
          if (mutationList[0].target.classList.contains('is-resizing')) {
            this.get('interaction').didStartResizingColumn();
          } else {
            this.get('interaction').didStopResizingColumn();
          }
        });
        const el = this.$('.ember-light-table')[0];
        mutationObserver.observe(el, {
          attributeFilter: ['class']
        });
        this.set('mutationObserver', mutationObserver);
      }
    },

    willDestroyElement() {
      this.$().off('keydown', this._onKeyDown);
      this.$().off('mousedown', this._onMouseDown);
      Ember.$('.tile-dashboard-wrapper').off('scroll', this._onScroll);
      this.get('dataJoin').clear();

      if (typeof MutationObserver !== 'undefined') {
        this.get('mutationObserver').disconnect();
      }

      this._super(...arguments);
    },

    setTableColumns() {
      const oldColumns = this.get('table.columns').slice() || [];
      const tableColumns = this.get('tableColumns') || [];
      this.set('table.columns', tableColumns.map(col => new _filterColumn.default(col)));
      oldColumns.forEach(column => column.destroy());
    },

    updateTableColumns() {
      const state = this.get('widget.state') || {};
      const config = this.get('config') || {};
      const columns = this.get('table.columns') || [];
      let shouldResize = false;
      let scrollLeft;
      const body = this.$('.column-widget-body');

      if (body) {
        scrollLeft = this.$('.column-widget-body').scrollLeft();
      }

      if (columns) {
        columns.forEach(column => {
          const sortId = state.sortId || config.defaultSortId;

          if (config.modelName === 'avlm-alert') {
            config.defaultSortId = 'priorityId';
          }

          if (Ember.isPresent(sortId) && sortId === column.get('id') || config.modelName === 'avlm-alert' && Ember.isPresent(sortId) && sortId === 'priorityId' && column.get('id') === 'priority') {
            let sortAsc;

            if (Ember.isPresent(state.sortAsc)) {
              sortAsc = state.sortAsc;
            } else if (Ember.isPresent(config.defaultSortAsc)) {
              sortAsc = config.defaultSortAsc;
            } else {
              sortAsc = true;
            }

            column.set('sorted', true);
            column.set('ascending', sortAsc);
          } else {
            column.set('sorted', false);
          }
        });
      }

      const columnsHash = state.columns || {};
      Object.entries(columnsHash).forEach(_ref => {
        let [id, columnState] = _ref;
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (Ember.isPresent(columnState.hidden)) {
          column.set('hidden', columnState.hidden);
          shouldResize = true;
        }

        if (Ember.isPresent(columnState.index)) {
          // +/- 1 accounts for checkbox column
          const oldIndex = columns.indexOf(column) - 1;

          if (columnState.index !== oldIndex) {
            const swapColumn = columns.objectAt(1 + columnState.index);
            columns.replace(1 + columnState.index, 1, [column]);
            columns.replace(1 + oldIndex, 1, [swapColumn]);
            shouldResize = true;
          }
        }

        const wasFiltered = column.get('isFiltered');
        ['filterTypeId', 'filterValues'].forEach(prop => {
          // allow null value to overwrite
          if (!columnState.hasOwnProperty(prop)) {
            return;
          }

          column.set(prop, columnState[prop]);
        });

        if (wasFiltered || column.get('isFiltered')) {
          Ember.run.scheduleOnce('afterRender', this, 'refreshData');
        }

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
          shouldResize = true;
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }

      Ember.run.debounce(this, 'refreshData', 250);
    },

    refreshData() {
      if (this.get('isDestroyed')) return;
      this.get('fetchDataTask').perform().then(() => {
        if (this.get('activeContext.topActiveContext.refreshInProgress')) {
          this.set('activeContext.topActiveContext.refreshInProgress', false);
        }
      }).catch(err => {
        if (this.get('activeContext.topActiveContext.refreshInProgress')) {
          this.set('activeContext.topActiveContext.refreshInProgress', false); // eslint-disable-next-line no-console

          console.log('Error occoured when refreshing the Data' + err);
        }

        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    clearData() {
      this.set('records', []);
      this.set('offset', 0);
    },

    setScrollLeft(scrollLeft) {
      if (!this.$('.column-widget-body')) {
        return;
      }

      this.$('.column-widget-body').scrollLeft(scrollLeft);
    },

    getWidgetIndex() {
      if (this.get('maximizer.maximizedWidget') || !Ember.isPresent(this.element.closest('.grid-stack'))) {
        return null;
      }

      const gridStack = this.element.closest('.grid-stack');
      const gridStackItems = Array.from(gridStack.children).sort((a, b) => {
        const yDiff = parseInt(a.getAttribute('data-gs-y'), 10) - parseInt(b.getAttribute('data-gs-y'), 10);

        if (yDiff === 0) {
          return parseInt(a.getAttribute('data-gs-x'), 10) - parseInt(b.getAttribute('data-gs-x'), 10);
        }

        return yDiff;
      });
      const gridStackItem = this.element.closest('.grid-stack-item');
      return gridStackItems.indexOf(gridStackItem);
    },

    fetchDataTask: (0, _emberConcurrency.task)(function* () {
      const records = yield this.fetchDataQuery();
      this.set('records', records);
    }).restartable(),
    onRecordsChange: Ember.observer('records', 'offset', function () {
      const limit = this.get('limit');
      const offset = this.get('offset');
      const records = this.get('records');
      let visibleRecords = [];

      if (records) {
        visibleRecords = this.get('records').slice(0, offset + limit);
      }

      this.set('visibleRecords', visibleRecords);
    }),
    onVisibleRecordsChange: Ember.observer('visibleRecords', function () {
      const visibleRecords = this.get('visibleRecords');
      const refreshSync = this.get('refreshSync');
      const resetScroll = this.get('resetScroll');
      this.get('dataJoin').join(visibleRecords, refreshSync);
      this.set('refreshSync', false);

      if (resetScroll) {
        // reset scroll and pagination to top
        this.set('offset', 0);
        this.$('.column-widget-body').scrollTop(0);
      }

      this.set('resetScroll', false);
    }),
    onTableColumnsChange: Ember.observer('tableColumns', function () {
      Ember.run.scheduleOnce('afterRender', this, 'setTableColumns');
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),
    onWidgetStateChange: Ember.observer('widget.state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),
    onTableActionsChange: Ember.observer('tableActions.[]', function () {
      this.reConfigureTableActions();
    }),

    reConfigureTableActions() {
      const configPermissions = this.get('workspace.configPermissions');
      const actions = [];

      if (!Ember.isNone(configPermissions)) {
        const configPermission = configPermissions[this.get('widget.typeId')];

        if (!Ember.isNone(configPermission) && !Ember.isNone(this.get('tableActions'))) {
          this.get('tableActions').forEach(currAction => {
            const permGranted = this.get('permissionLayer').permInUserHash(currAction.permId, currAction.permIds);

            if (permGranted && !Ember.isNone(configPermission.tableActions) && (configPermission.tableActions.includes(currAction.id) || configPermission.tableActions.includes('all'))) {
              actions.push(currAction);
            }
          });
          this.set('allowedTableActions', actions);
        }
      }
    },

    keyDown(event) {
      const ltCell = event.target.closest('.lt-cell'); // need to exempt checkboxes to allow right arrow from checkbox col

      const targetIsControl = event.target.tagName === 'INPUT' && event.target.type !== 'checkbox';

      switch (event.key) {
        case 'Escape':
          {
            const targetIsSearchBox = event.target.classList.contains('column-widget-search-box');

            if (targetIsSearchBox) {
              event.preventDefault();
              this.set('isSearchEnabled', false);
              this.$('.column-widget-search-button').focus();
            }

            break;
          }

        case 'ArrowLeft':
          {
            if (!ltCell || targetIsControl) {
              return;
            }

            event.preventDefault();
            const previousCell = ltCell.previousElementSibling;

            if (!previousCell) {
              return;
            }

            const focusElement = previousCell.querySelector('.can-focus') || previousCell.firstElementChild;

            if (focusElement) {
              focusElement.focus();
            }

            break;
          }

        case 'ArrowRight':
          {
            if (!ltCell || targetIsControl) {
              return;
            }

            event.preventDefault();
            const nextCell = ltCell.nextElementSibling;

            if (!nextCell) {
              return;
            }

            const focusElement = nextCell.querySelector('.can-focus') || nextCell.firstElementChild;

            if (focusElement) {
              focusElement.focus();
            }

            break;
          }

        case 'ArrowDown':
          {
            if (!ltCell) {
              return;
            }

            event.preventDefault();
            const row = ltCell.closest('.lt-row'); // an HTMLCollection isn't really an array

            const rowChildren = [].slice.call(row.children);
            const index = rowChildren.indexOf(ltCell);
            const nextRow = row.nextElementSibling;

            if (nextRow && nextRow.tagName !== 'OCCLUDED-CONTENT') {
              // move table selection down one row
              const tableRows = this.get('table.rows');
              tableRows.setEach('selected', false);
              const recordId = nextRow.getAttribute('data-record-id');
              const nextTableRow = tableRows.findBy('content.id', recordId);
              nextTableRow.set('selected', true); // move browser focus to same column in next row

              const nextLtCell = nextRow.children[index];
              const focusElement = nextLtCell.querySelector('.can-focus') || nextLtCell.firstElementChild;

              if (focusElement) {
                focusElement.focus();
              }
            }

            break;
          }

        case 'ArrowUp':
          {
            if (!ltCell) {
              return;
            }

            event.preventDefault();
            const row = ltCell.closest('.lt-row'); // an HTMLCollection isn't really an array

            const rowChildren = [].slice.call(row.children);
            const index = rowChildren.indexOf(ltCell);
            const previousRow = row.previousElementSibling;

            if (previousRow && previousRow.tagName !== 'OCCLUDED-CONTENT') {
              // move table selection down one row
              const tableRows = this.get('table.rows');
              tableRows.setEach('selected', false);
              const recordId = previousRow.getAttribute('data-record-id');
              const previousTableRow = tableRows.findBy('content.id', recordId);
              previousTableRow.set('selected', true); // move browser focus to same column in previous row

              const previousLtCell = previousRow.children[index];
              const focusElement = previousLtCell.querySelector('.can-focus') || previousLtCell.firstElementChild;

              if (focusElement) {
                event.preventDefault();
                focusElement.focus();
              }
            }

            break;
          }

        case ' ':
        case 'Enter':
          {
            const isColumn = event.target.classList.contains('table-base-column');

            if (isColumn) {
              event.preventDefault();
              this.$(event.target).trigger('click');
            } else {
              const actions = this.get('inlineRowMenu.actions');
              if (actions) actions.close();
            }

            break;
          }

        default:
          break;
      }
    },

    mouseDown(event) {
      if (!this.get('stopsReorder').reorderInProgress) {
        const targetIsSearchBox = event.target.classList.contains('column-widget-search-box');

        if (!targetIsSearchBox) {
          event.preventDefault();
        }
      }

      if (event.target.tagName === 'INPUT' && event.target.type === 'checkbox') {
        // allow user to check another row without moving selection
        return;
      }

      const ltCell = event.target.closest('.lt-cell');

      if (ltCell) {
        const row = ltCell.closest('.lt-row');
        const recordId = row.getAttribute('data-record-id');
        const tableRows = this.get('table.rows') || [];
        const clickedRow = tableRows.findBy('content.id', recordId);
        tableRows.setEach('selected', false);
        clickedRow.set('selected', true);
        const focusElement = ltCell.querySelector('.can-focus') || ltCell.firstElementChild;

        if (focusElement) {
          focusElement.focus();
        }
      }
    },

    doubleClick(event) {
      event.preventDefault();

      if (event.target.tagName === 'INPUT' && event.target.type === 'checkbox') {
        // allow user to check another row without moving selection
        return;
      }

      const ltCell = event.target.closest('.lt-cell');

      if (ltCell) {
        const row = ltCell.closest('.lt-row');
        const index = Array.from(row.children).indexOf(ltCell);
        const recordId = row.getAttribute('data-record-id');
        const tableRows = this.get('table.rows') || [];
        const clickedColumn = this.get('table.columns')[index];
        const clickedRow = tableRows.findBy('content.id', recordId);
        tableRows.setEach('selected', false);
        const scheduleLocked = clickedRow.get('record.scheduleLocked');

        if (!scheduleLocked || Ember.isNone(scheduleLocked)) {
          clickedRow.set('isEditing', true);
          clickedColumn.set('isEditing', true);
        }
      }
    },

    // Helper function to take a list of (single/bulk) actions, and the corresponding list of action names
    // from the config service.  Order the list of actions that will be used to populate the dropdown menu
    // based on the order from the config service, so that it's always consistent
    // Also allow for the config list to include an "all" value, where anything not already specified will
    // be inserted (in the order determined by the widget's returned action list)
    orderActionsByConfigList(actions, listOfActionsFromConfig) {
      const configList = Ember.isNone(listOfActionsFromConfig) ? [] : listOfActionsFromConfig; // Find any actions that were declared by the widget, but aren't in the config list

      let actionsToBeIncludedAsAll = [];

      if (actions) {
        actionsToBeIncludedAsAll = actions.filter(action => !configList.some(configAction => configAction === action.id));
      }

      const result = []; // Iterate over the config list, so that we populate the result in the right order

      configList.forEach(configAction => {
        if (configAction === 'all') {
          result.push(...actionsToBeIncludedAsAll);
        } else {
          const foundAction = actions.find(action => action.id === configAction);

          if (foundAction) {
            result.push(foundAction);
          }
        }
      });
      return result;
    },

    reConfigureBulkActions() {
      const bulkActions = this.get('dynamicBulkActions') || [];
      this.set('bulkActions', [...bulkActions]);
    },

    reConfigureSingleActions() {
      const configPermissions = this.get('workspace.configPermissions');

      if (!Ember.isNone(configPermissions)) {
        const configPermission = configPermissions[this.get('widget.typeId')];
        let singleActions = [];
        const bulkActions = [];

        if (!Ember.isNone(configPermission) && !Ember.isNone(this.get('singleActions'))) {
          this.get('singleActions').forEach(currAction => {
            const permGranted = this.get('permissionLayer').permInUserHash(currAction.permId, currAction.permIds);

            if (permGranted && !Ember.isNone(configPermission.singleActions) && (configPermission.singleActions.includes(currAction.id) || configPermission.singleActions.includes('all'))) {
              singleActions.push(currAction);
            }
          });

          if (!Ember.isNone(singleActions)) {
            singleActions = this.orderActionsByConfigList(singleActions, configPermission.singleActions);
          }

          this.set('singleActions', singleActions);
        }

        if (!Ember.isNone(configPermission) && !Ember.isNone(this.get('bulkActions'))) {
          this.get('bulkActions').forEach(currAction => {
            const permGranted = this.get('permissionLayer').permInUserHash(currAction.permId, currAction.permIds);

            if (permGranted && !Ember.isNone(configPermission.bulkActions) && (configPermission.bulkActions.includes(currAction.id) || configPermission.bulkActions.includes('all'))) {
              bulkActions.push(currAction);
            }
          });
          this.set('bulkActions', bulkActions);
        }
      }
    },

    actions: {
      onRowClick() {},

      onRowDoubleClick() {},

      onSearchTextChange(event) {
        if (event.keyCode !== 13) {
          return;
        }

        const searchText = event.target.value;
        const previousSearchText = this.get('searchText');

        if (searchText === previousSearchText) {
          return;
        }

        this.set('searchText', searchText);
        this.set('refreshSync', true);
        this.set('widget.state.modelName', this.get('config.modelName'));
        Ember.run.debounce(this, 'refreshData', 250);
      },

      onColumnClick(column) {
        if (!column.sorted) {
          return;
        }

        let sortColumnId = column.get('id');

        if (this.get('config.modelName') === 'avlm-alert' && sortColumnId === 'priority') {
          sortColumnId = 'priorityId';
        }

        this.get('widget').mergeState({
          sortId: sortColumnId,
          sortAsc: column.get('ascending'),
          modelName: this.get('config.modelName')
        });
        this.set('refreshSync', true);
        this.set('resetScroll', true);
        Ember.run.scheduleOnce('afterRender', this, 'refreshData');
      },

      onColumnResize(column, pixels) {
        const scrollLeft = this.$('.column-widget-body').scrollLeft();
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          width: fromPixels(pixels)
        };
        this.get('widget').mergeState({
          columns
        });
        Ember.run.next(() => {
          Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
        });
      },

      onSearchButtonClick() {
        this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        Ember.run.schedule('afterRender', () => {
          if (this.get('isSearchEnabled')) {
            this.$('.column-widget-search-box').focus();
          }
        });
      },

      onRemoveSearchClick() {
        this.set('searchText', '');
        Ember.run.debounce(this, 'refreshData', 250);
        this.toggleProperty('isSearchEnabled');
        this.$('.column-widget-search-button').focus();
      },

      onHeaderDoubleClick() {
        const maximizer = this.get('maximizer'); // when widget is maximized, minimize  the widget
        // if (maximizer.get('isWidgetMinified')) {

        if (maximizer.get('maximizedWidget') !== this.get('widget')) {
          const localQuickFilter = this.get('localQuickFilters').find(filter => filter.active);
          const activeQuickFilter = Ember.isPresent(localQuickFilter) ? {
            id: localQuickFilter.id
          } : null;
          maximizer.maximize(this.get('widget'), activeQuickFilter);
        } else {
          this.send('onExitMaximizedClick', '');
        }
      },

      onExitMaximizedClick() {
        const maximizer = this.get('maximizer');
        const localQuickFilter = this.get('localQuickFilters').find(filter => filter.active);
        const activeQuickFilter = Ember.isPresent(localQuickFilter) ? {
          id: localQuickFilter.id
        } : null;
        maximizer.minimize(activeQuickFilter);
      },

      onFilterButtonClick() {
        const columns = [];
        const workspace = this.get('workspace');
        const widget = this.get('widget');
        const config = this.get('config');
        const tableColumns = this.get('table.columns') || [];
        const displayName = `${config.title} Filters`;
        this.set('widget.state.modelName', this.get('config.modelName')); // close sidebar if _this_ widget's filter is currently open
        // @TODO: define and use unique keys rather than `displayName` for this

        if (displayName === workspace.get('topStateDisplayName')) {
          workspace.popState();
          return;
        }

        let topState = workspace.get('topState');

        while (topState === 'filterColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        tableColumns.forEach(column => {
          const columnConfig = column.config;

          if (columnConfig) {
            if (!columnConfig.unAvailable) {
              columns.push(column);
            }
          } else {
            columns.push(column);
          }
        });
        workspace.pushState('filterColumnWidget', {
          columns,
          widget,
          displayName
        });
      },

      setInlineRowMenu(dropdown) {
        this.set('inlineRowMenu', dropdown);
      },

      setPlusIconMenu(dropdown) {
        this.set('plusIconMenu', dropdown);
      },

      onRowRightClick(row, event) {
        let rowSingleActions = this.get('singleActions') || [];
        const disableRightClick = this.get('disableRightClick');
        if (disableRightClick) return; // disable right row click for iq-widgets when a user runs schedule generation or release routes.

        this.set('inlineRowMenuPosition', () => {
          return {
            style: {
              top: event.clientY,
              left: event.clientX
            }
          };
        });
        this.set('lastRowClicked', row.get('record'));

        if (Ember.isNone(this.get('dynamicSingleActions'))) {
          this.set('dynamicSingleActions', rowSingleActions);
        } else {
          this.set('singleActions', this.get('dynamicSingleActions'));
          rowSingleActions = this.get('dynamicSingleActions');
        }

        if (row.get('isLockedByOtherUser')) {
          const singleActions = [];
          rowSingleActions.forEach(action => {
            if (!action.disableOnLockedRoute) {
              singleActions.push(action);
            }
          });
          this.set('singleActions', singleActions);
        } // remove right click action if schedule record is locked


        if (row.get('record.scheduleLocked')) {
          const singleActions = [];
          rowSingleActions.forEach(action => {
            if (!action.disableOnLocked) {
              singleActions.push(action);
            }
          });
          this.set('singleActions', singleActions);
        }

        if (!Ember.isNone(rowSingleActions)) {
          this.reConfigureSingleActions();
        }

        this.reConfigureBulkActions();
        this._lastRowClickEvent = event; // storing for Edit Field action

        this.inlineRowMenu.actions.open();
      },

      onScroll() {
        // pause background processing while scrolling
        this.get('interaction').didScrollWidget(); // close any open dropdown

        const dropdown = this.get('inlineRowMenu');

        if (dropdown) {
          dropdown.actions.close(); //this.set('inlineRowMenu', null);
        } // figure out if we need to increase pagination


        const body = this.$('.column-widget-body')[0];
        const scrollPosition = body.scrollTop + body.offsetHeight;
        const triggerPosition = body.scrollHeight - PAGINATION_TRIGGER_OFFSET; // scroll height takes a while to update, so we don't reset pagination
        // flag until we observe it change in a subsequent `onScroll()` action

        if (body.scrollHeight !== this._scrollHeight) {
          this._scrollHeight = body.scrollHeight;
          this.set('isPaginating', false);
        }

        if (scrollPosition >= triggerPosition && !this._isPaginating) {
          const visibleCount = this.get('visibleRecords.length');
          const offset = this.get('offset');
          const limit = this.get('limit');

          if (offset < visibleCount) {
            this.set('offset', offset + limit);
            this.set('isPaginating', true);
            this.set('refreshSync', true);
          }
        }
      },

      onTableActionClick(action, dropdown) {
        dropdown.actions.close();

        if (action.action) {
          action.action();
        }
      },

      onSingleRowAction(action) {
        const model = this.get('lastRowClicked');
        this.inlineRowMenu.actions.close();

        if (action.action) {
          action.action(model);
        }
      },

      onBulkRowAction(action) {
        const checkedRows = this.get('table.rows').filterBy('isChecked') || [];
        const models = [];
        checkedRows.forEach(row => {
          models.push(row.get('record'));
        });
        this.inlineRowMenu.actions.close();

        if (action.action) {
          action.action(models);
        }
      },

      onCheckAll() {
        const records = this.get('records');
        this.get('activeContext.topActiveContext').setRecordsChecked(records, true);
      },

      onUncheckAll() {
        const records = this.get('records');
        this.get('activeContext.topActiveContext').setRecordsChecked(records, false);
      },

      onPlusIconClick() {
        // disable plus icon click for iq-widgets when a user runs schedule generation or release routes.
        // allow the user though to click after reoptimize / release is finished
        const disableRightClick = this.get('workspace.reoptimizeInProgress');
        return !disableRightClick;
      },

      onRefresh() {
        this.refreshData();
      },

      onLeftButtonClick() {
        Ember.$(`.${this.get('scrollClassName')}`).animate({
          scrollLeft: '-=100px'
        }, 'fast');
      },

      onRightButtonClick() {
        Ember.$(`.${this.get('scrollClassName')}`).animate({
          scrollLeft: '+=100px'
        }, 'fast');
      }

    }
  });

  _exports.default = _default;
});