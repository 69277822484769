define("adept-iq/models/vehicle", ["exports", "ember-data", "adept-iq/mixins/otp-label", "ember-cp-validations"], function (_exports, _emberData, _otpLabel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 32
      })]
    }
  });

  var _default = Model.extend(Validations, _otpLabel.default, {
    workspace: Ember.inject.service(),
    name: attr('string'),
    mobileId: attr('string', {
      defaultValue: null
    }),
    active: attr('boolean', {
      defaultValue: true
    }),
    status: attr('string', {
      defaultValue: 'operational'
    }),
    placeholder: attr('boolean', {
      defaultValue: false
    }),
    nonRevenueVehicle: attr('boolean'),
    startGarageDisplayName: attr('string'),
    startGarageLat: attr('number'),
    startGarageLng: attr('number'),
    endGarageDisplayName: attr('string'),
    endGarageLat: attr('number'),
    endGarageLng: attr('number'),
    provider: belongsTo('provider'),
    //schedule: belongsTo(),
    vehicleType: belongsTo('vehicle-type'),
    routes: hasMany('route'),
    vehicleTypeDisplayName: Ember.computed.readOnly('vehicleType.displayName'),
    vehicleTypeId: Ember.computed.readOnly('vehicleType.id'),
    workspaceStartTime: Ember.computed.alias('workspace.startDate'),
    workspaceEndTime: Ember.computed.alias('workspace.endDate'),
    callSign: Ember.computed.alias('name'),
    point: Ember.computed.collect('lat', 'lng'),
    capacityCounts: Ember.computed.readOnly('vehicleType.capacityCounts')
  });

  _exports.default = _default;
});