define("adept-iq/pods/components/side-drawer/trip-transfer/radio-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-radio-button-cell', 'data-test-table-radio-button-cell'],
    solution: Ember.computed.readOnly('row.content'),
    isDisabled: Ember.computed.alias('solution.extensionExceeded'),
    actions: {
      onRadioButtonClick() {
        // prevents `click` event from bubbling to row component
        // event.stopPropagation();
        const row = this.get('row');
        const rows = this.get('table.rows');
        const selectedRow = rows.findBy('selected', true);
        const buttonClickAction = this.get('tableActions.radioButtonClick');
        if (row === selectedRow) return;

        if (selectedRow) {
          selectedRow.set('selected', false);
        }

        if (buttonClickAction) {
          buttonClickAction(row);
        }

        row.set('selected', true);
      }

    }
  });

  _exports.default = _default;
});