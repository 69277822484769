define("adept-iq/pods/components/iq-widgets/map-widget/config/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRIP_COMPLETED_COLOR = _exports.SCHEDULE_COLOR_5 = _exports.SCHEDULE_COLOR_4 = _exports.SCHEDULE_COLOR_3 = _exports.SCHEDULE_COLOR_2 = _exports.SCHEDULE_COLOR_1 = _exports.PLANNED_DARK_COLOR = _exports.PLANNED_COLOR = _exports.PERFORMED_COLOR = _exports.PENDING_COLOR = _exports.OTP_LATE_COLOR = _exports.OTP_DANGER_COLOR = _exports.FLOATING_BREAK_COLOR = _exports.DISTANCE_COLOR = _exports.BENOMAD_WAITLISTED_COLOR = _exports.BENOMAD_TRIP_COMPLETED_COLOR = _exports.BENOMAD_SCHEDULE_COLOR_5 = _exports.BENOMAD_SCHEDULE_COLOR_4 = _exports.BENOMAD_SCHEDULE_COLOR_3 = _exports.BENOMAD_SCHEDULE_COLOR_2 = _exports.BENOMAD_SCHEDULE_COLOR_1 = _exports.BENOMAD_PLANNED_UNSCHEDULE_COLOR = _exports.BENOMAD_PLANNED_SCHEDULE_COLOR = _exports.BENOMAD_PLANNED_DARK_COLOR = _exports.BENOMAD_PLANNED_COLOR = _exports.BENOMAD_PERFORMED_COLOR = _exports.BENOMAD_PENDING_COLOR = _exports.BENOMAD_OTP_LATE_COLOR = _exports.BENOMAD_OTP_DANGER_COLOR = _exports.BENOMAD_NOSHOWREQUESTED_COLOR = _exports.BENOMAD_NOSHOWED_COLOR = _exports.BENOMAD_LATE_CANCELED_COLOR = _exports.BENOMAD_ILLEGAL_COLOR = _exports.BENOMAD_FLOATING_BREAK_COLOR = _exports.BENOMAD_ENROUTE_COLOR = _exports.BENOMAD_DISTANCE_COLOR = _exports.BENOMAD_DISPATCHED_COLOR = _exports.BENOMAD_CANCELED_COLOR = _exports.BENOMAD_CALLBACK_COLOR = _exports.BENOMAD_ACTIVE_COLOR = void 0;
  const bemap = window.bemap;
  const PLANNED_COLOR = '#A9B2B5';
  _exports.PLANNED_COLOR = PLANNED_COLOR;
  const DISTANCE_COLOR = '#303E44'; // used for trips to improve contrast against stop polylines

  _exports.DISTANCE_COLOR = DISTANCE_COLOR;
  const PLANNED_DARK_COLOR = '#A9B2B5';
  _exports.PLANNED_DARK_COLOR = PLANNED_DARK_COLOR;
  const PENDING_COLOR = '#0067FC';
  _exports.PENDING_COLOR = PENDING_COLOR;
  const PERFORMED_COLOR = '#1C1C1D';
  _exports.PERFORMED_COLOR = PERFORMED_COLOR;
  const FLOATING_BREAK_COLOR = '#000000';
  _exports.FLOATING_BREAK_COLOR = FLOATING_BREAK_COLOR;
  const TRIP_COMPLETED_COLOR = '#5a6368';
  _exports.TRIP_COMPLETED_COLOR = TRIP_COMPLETED_COLOR;
  const OTP_DANGER_COLOR = '#E3621B';
  _exports.OTP_DANGER_COLOR = OTP_DANGER_COLOR;
  const OTP_LATE_COLOR = '#d13730';
  _exports.OTP_LATE_COLOR = OTP_LATE_COLOR;
  const SCHEDULE_COLOR_1 = '#004000'; // Green Shade

  _exports.SCHEDULE_COLOR_1 = SCHEDULE_COLOR_1;
  const SCHEDULE_COLOR_2 = '#0000A4'; // Blue Shade

  _exports.SCHEDULE_COLOR_2 = SCHEDULE_COLOR_2;
  const SCHEDULE_COLOR_3 = '#800000'; // Red Shade

  _exports.SCHEDULE_COLOR_3 = SCHEDULE_COLOR_3;
  const SCHEDULE_COLOR_4 = '#9F5000'; // Yellow Shade

  _exports.SCHEDULE_COLOR_4 = SCHEDULE_COLOR_4;
  const SCHEDULE_COLOR_5 = '#400080'; // Purple Shade

  _exports.SCHEDULE_COLOR_5 = SCHEDULE_COLOR_5;
  const BENOMAD_PLANNED_COLOR = new bemap.Color(169, 178, 181, 1); // grey color

  _exports.BENOMAD_PLANNED_COLOR = BENOMAD_PLANNED_COLOR;
  const BENOMAD_DISTANCE_COLOR = new bemap.Color(48, 62, 68, 1); // dark-grey color

  _exports.BENOMAD_DISTANCE_COLOR = BENOMAD_DISTANCE_COLOR;
  const BENOMAD_PLANNED_DARK_COLOR = new bemap.Color(85, 94, 99, 1); // dark-grey color

  _exports.BENOMAD_PLANNED_DARK_COLOR = BENOMAD_PLANNED_DARK_COLOR;
  const BENOMAD_PENDING_COLOR = new bemap.Color(0, 103, 252, 1); // blue color

  _exports.BENOMAD_PENDING_COLOR = BENOMAD_PENDING_COLOR;
  const BENOMAD_PERFORMED_COLOR = new bemap.Color(97, 95, 96, 1); // dark-grey color

  _exports.BENOMAD_PERFORMED_COLOR = BENOMAD_PERFORMED_COLOR;
  const BENOMAD_OTP_DANGER_COLOR = new bemap.Color(227, 98, 27, 1); // orange color

  _exports.BENOMAD_OTP_DANGER_COLOR = BENOMAD_OTP_DANGER_COLOR;
  const BENOMAD_OTP_LATE_COLOR = new bemap.Color(209, 55, 48, 1); // red color

  _exports.BENOMAD_OTP_LATE_COLOR = BENOMAD_OTP_LATE_COLOR;
  const BENOMAD_SCHEDULE_COLOR_1 = new bemap.Color(0, 64, 0, 0.6); // green color for route schedule polyline

  _exports.BENOMAD_SCHEDULE_COLOR_1 = BENOMAD_SCHEDULE_COLOR_1;
  const BENOMAD_SCHEDULE_COLOR_2 = new bemap.Color(0, 0, 164, 0.6); // blue color for route schedule polyline

  _exports.BENOMAD_SCHEDULE_COLOR_2 = BENOMAD_SCHEDULE_COLOR_2;
  const BENOMAD_SCHEDULE_COLOR_3 = new bemap.Color(128, 0, 0, 0.6); // red color for route schedule polyline

  _exports.BENOMAD_SCHEDULE_COLOR_3 = BENOMAD_SCHEDULE_COLOR_3;
  const BENOMAD_SCHEDULE_COLOR_4 = new bemap.Color(159, 80, 0, 0.6); // yellow color for route schedule polyline

  _exports.BENOMAD_SCHEDULE_COLOR_4 = BENOMAD_SCHEDULE_COLOR_4;
  const BENOMAD_SCHEDULE_COLOR_5 = new bemap.Color(64, 0, 128, 0.6); // purple color for route schedule polyline

  _exports.BENOMAD_SCHEDULE_COLOR_5 = BENOMAD_SCHEDULE_COLOR_5;
  const BENOMAD_FLOATING_BREAK_COLOR = new bemap.Color(0, 0, 0, 1); // black color

  _exports.BENOMAD_FLOATING_BREAK_COLOR = BENOMAD_FLOATING_BREAK_COLOR;
  const BENOMAD_PLANNED_SCHEDULE_COLOR = new bemap.Color(176, 176, 176, 1); //light-grey-color

  _exports.BENOMAD_PLANNED_SCHEDULE_COLOR = BENOMAD_PLANNED_SCHEDULE_COLOR;
  const BENOMAD_PLANNED_UNSCHEDULE_COLOR = new bemap.Color(64, 64, 64, 1); //light-black-color

  _exports.BENOMAD_PLANNED_UNSCHEDULE_COLOR = BENOMAD_PLANNED_UNSCHEDULE_COLOR;
  const BENOMAD_TRIP_COMPLETED_COLOR = new bemap.Color(0, 0, 0, 1); //black color

  _exports.BENOMAD_TRIP_COMPLETED_COLOR = BENOMAD_TRIP_COMPLETED_COLOR;
  const BENOMAD_WAITLISTED_COLOR = new bemap.Color(192, 192, 192, 1); //;ight-grey-color

  _exports.BENOMAD_WAITLISTED_COLOR = BENOMAD_WAITLISTED_COLOR;
  const BENOMAD_ILLEGAL_COLOR = new bemap.Color(112, 112, 112, 1);
  _exports.BENOMAD_ILLEGAL_COLOR = BENOMAD_ILLEGAL_COLOR;
  const BENOMAD_DISPATCHED_COLOR = new bemap.Color(0, 16, 16, 1);
  _exports.BENOMAD_DISPATCHED_COLOR = BENOMAD_DISPATCHED_COLOR;
  const BENOMAD_CALLBACK_COLOR = new bemap.Color(152, 152, 152, 1);
  _exports.BENOMAD_CALLBACK_COLOR = BENOMAD_CALLBACK_COLOR;
  const BENOMAD_ACTIVE_COLOR = new bemap.Color(88, 88, 88, 1);
  _exports.BENOMAD_ACTIVE_COLOR = BENOMAD_ACTIVE_COLOR;
  const BENOMAD_ENROUTE_COLOR = new bemap.Color(72, 72, 72, 1);
  _exports.BENOMAD_ENROUTE_COLOR = BENOMAD_ENROUTE_COLOR;
  const BENOMAD_NOSHOWED_COLOR = new bemap.Color(96, 96, 96, 1);
  _exports.BENOMAD_NOSHOWED_COLOR = BENOMAD_NOSHOWED_COLOR;
  const BENOMAD_NOSHOWREQUESTED_COLOR = new bemap.Color(192, 192, 192, 1);
  _exports.BENOMAD_NOSHOWREQUESTED_COLOR = BENOMAD_NOSHOWREQUESTED_COLOR;
  const BENOMAD_CANCELED_COLOR = new bemap.Color(192, 192, 192, 1); //;ight-grey-color

  _exports.BENOMAD_CANCELED_COLOR = BENOMAD_CANCELED_COLOR;
  const BENOMAD_LATE_CANCELED_COLOR = new bemap.Color(96, 96, 96, 1); //;dark-grey-color

  _exports.BENOMAD_LATE_CANCELED_COLOR = BENOMAD_LATE_CANCELED_COLOR;
  var _default = {
    PLANNED_COLOR,
    PLANNED_DARK_COLOR,
    PENDING_COLOR,
    PERFORMED_COLOR,
    OTP_DANGER_COLOR,
    OTP_LATE_COLOR,
    BENOMAD_PLANNED_COLOR,
    BENOMAD_PLANNED_DARK_COLOR,
    BENOMAD_PENDING_COLOR,
    BENOMAD_PERFORMED_COLOR,
    BENOMAD_OTP_DANGER_COLOR,
    BENOMAD_OTP_LATE_COLOR,
    BENOMAD_DISTANCE_COLOR,
    BENOMAD_SCHEDULE_COLOR_1,
    BENOMAD_SCHEDULE_COLOR_2,
    BENOMAD_SCHEDULE_COLOR_3,
    BENOMAD_SCHEDULE_COLOR_4,
    BENOMAD_SCHEDULE_COLOR_5,
    BENOMAD_FLOATING_BREAK_COLOR,
    TRIP_COMPLETED_COLOR,
    BENOMAD_PLANNED_SCHEDULE_COLOR,
    BENOMAD_PLANNED_UNSCHEDULE_COLOR,
    BENOMAD_TRIP_COMPLETED_COLOR,
    BENOMAD_WAITLISTED_COLOR,
    BENOMAD_ILLEGAL_COLOR,
    BENOMAD_DISPATCHED_COLOR,
    BENOMAD_CALLBACK_COLOR,
    BENOMAD_ACTIVE_COLOR,
    BENOMAD_ENROUTE_COLOR,
    BENOMAD_NOSHOWED_COLOR,
    BENOMAD_NOSHOWREQUESTED_COLOR,
    FLOATING_BREAK_COLOR,
    DISTANCE_COLOR
  };
  _exports.default = _default;
});