define("adept-iq/services/subscription", ["exports", "adept-iq/classes/json-api-serializer/JSONAPISerializer", "adept-iq/config/api-urls", "moment", "lodash", "adept-iq/utils/unwrapProxy"], function (_exports, _JSONAPISerializer, _apiUrls, _moment, _lodash, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EXCLUDED_TRAVELNEED = ['AMBULATORY'];

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    subscriptionLogGeneration: Ember.inject.service('subscription-activitylog-generation'),
    serializer: null,
    noSharing: 0,
    updatedActivityLogData: null,
    defaultActivityLogData: null,
    isTravelNeedEdited: false,
    previousSchedulingMode: null,
    previousOriginPlannedEta: null,
    previousDestinationPlannedEta: null,
    previousRouteTemplate: null,
    previousSubscriptionDays: null,
    pickTime: '',
    dropTime: '',
    pickTimeMoment: null,
    dropTimeMoment: null,

    init() {
      this._super(...arguments);

      this.serializer = new _JSONAPISerializer.default(); //Register 'subscription' type

      this.serializer.register('subscription', {
        id: 'id',
        relationships: {
          originPlace: {
            type: 'place'
          },
          destinationPlace: {
            type: 'place'
          },
          rider: {
            type: 'rider'
          },
          group: {
            type: 'subscriptionGroup'
          },
          exclusions: {
            type: 'subscriptionExclusion'
          },
          recurrencePatterns: {
            type: 'subscriptionRecurrencePattern'
          },
          bookings: {
            type: 'generatedSubscriptionBooking'
          },
          travelNeeds: {
            type: 'subscriptionTravelNeed'
          },
          originPhoneNumber: {
            type: 'subscriptionPhoneNumber'
          },
          destinationPhoneNumber: {
            type: 'subscriptionPhoneNumber'
          },
          tripPurposeName: {
            type: 'tripPurposeName'
          },
          fareCategoryName: {
            type: 'fareCategoryName'
          },
          routeTemplate: {
            type: 'routeTemplate'
          }
        }
      });
      this.serializer.register('place', {
        id: 'id'
      });
      this.serializer.register('rider', {
        id: 'id'
      });
      this.serializer.register('subscriptionTravelNeed', {
        id: 'id'
      });
      this.serializer.register('subscriptionGroup', {
        id: 'id'
      });
      this.serializer.register('subscriptionExclusion', {
        id: 'id'
      });
      this.serializer.register('subscriptionRecurrencePattern', {
        id: 'id'
      });
      this.serializer.register('generatedSubscriptionBooking', {
        id: 'id'
      });
      this.serializer.register('address', {
        id: 'id'
      });
      this.serializer.register('location', {
        id: 'id'
      });
      this.serializer.register('subscriptionPhoneNumber', {
        id: 'id'
      });
      this.serializer.register('tripPurposeName', {
        id: 'id'
      });
      this.serializer.register('fareCategoryName', {
        id: 'id'
      });
      this.serializer.register('routeTemplate', {
        id: 'id'
      });
    },

    saveSubscription(subscription, isEditMode, travelNeeds) {
      return new Promise(async (resolve, reject) => {
        if (isEditMode) {
          const subcription = await this.updateSubscription(subscription, travelNeeds).then(data => resolve(data)).catch(err => reject(err));
          const payload = this.convertSubscriptionUpdateRecordToPayloadFormat(subscription);
          const id = subscription.get('id');
          this.get('ajax').post(_apiUrls.API.bookingService.host + `/subscription/${id}`, {
            method: 'PATCH',
            contentType: 'application/json',
            headers: {
              Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
            },
            data: JSON.stringify(payload)
          }).then(res => {
            resolve(res);
          }).catch(err => {
            reject(err);
          });
          return subcription;
        }

        const payload = this.convertSubscriptionCreateRecordToPayloadFormat(subscription, travelNeeds);
        this.get('ajax').post(_apiUrls.API.bookingService.host + '/subscription-with-subentity', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          },
          data: JSON.stringify(payload)
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    },

    /**
     * NYAAR-19927: saving changes for edit subscription
     * @param {*Object} subscription
     * @param {*Array} travelNeeds
     */
    async updateSubscription(subscription, travelNeeds) {
      const currentUser = this.session.data.authenticated.userId;
      const subscriptionId = subscription.get('id');
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      await this.prepareSubscriptionUpdateRawData(subscription);
      const {
        activityLogMessages,
        saveSubscriptionRecord
      } = await this.get('subscriptionLogGeneration').activityLogDifference(this.get('updatedActivityLogData'), this.get('defaultActivityLogData'), ['relationships', 'jsonapi', 'links'], subscription, travelNeeds);
      await Promise.all(saveSubscriptionRecord.uniqBy('id').map(data => {
        (0, _unwrapProxy.unwrapProxy)(data).save();
      })).then(async () => {
        if (this.get('isTravelNeedEdited')) await this.createAndBuildSubscriptionTravelNeeds(subscription, this.refactorTNs(travelNeeds));
        const content = {
          userId: currentUser,
          actualTime: (0, _moment.default)().toDate(),
          activity: 'Changed',
          details: `Subscription modified. ${activityLogMessages.join(';')}`
        };
        activityLogAdapter.createUpdateSubscriptionActivity({
          content
        }, subscriptionId);
        this.set('isTravelNeedEdited', false);
      }).catch(err => {
        return err;
      });
    },

    convertSubscriptionCreateRecordToPayloadFormat(subscriptionRecord, travelNeeds) {
      const subscriptionRawData = this.prepareSubscriptionCreateRawData(subscriptionRecord, travelNeeds);
      const jsonapi = this.serializer.serialize('subscription', subscriptionRawData);
      const payload = this.prepareFinalPayloadForSubscription(jsonapi);
      return payload;
    },

    convertSubscriptionUpdateRecordToPayloadFormat(subscriptionRecord) {
      const payload = this.prepareSubscriptionPatchRawData(subscriptionRecord);
      return payload;
    },

    prepareRouteTemplate(routeTemplate) {
      return {
        id: routeTemplate.get('id'),
        type: 'routeTemplate'
      };
    },

    prepareSubscriptionCreateRawData(subscriptionRecord, subTravelNeeds) {
      const leg = this.booking.get('activeBooking.legs.firstObject');
      const serviceWindowName = leg.get('serviceWindow.name');
      const fundingAgencyName = leg.get('fundingAgency.name');
      const tripPurposeName = subscriptionRecord.get('tripPurpose.name');
      const fareCategoryName = subscriptionRecord.get('fareCategory.name');
      const segment = leg.get('segments.firstObject');
      const origin = leg.get('segments.firstObject.pick.place');
      const destination = leg.get('segments.firstObject.drop.place');
      const exclusions = this.prepareExclusions(subscriptionRecord.get('exclusions'));
      const recurrencePatterns = this.prepareReccurencePatterns(subscriptionRecord.get('recurrencePatterns'));
      const provider = this.store.peekAll('provider.firstObject');
      const rider = this.booking.prepareRider(this.booking.get('selectedRiders.firstObject'));
      const travelNeeds = this.prepareSubscriptionTravelNeeds(this.refactorTNs(subTravelNeeds));
      const routeTemplate = this.prepareRouteTemplate(subscriptionRecord.get('routeTemplate'));
      return {
        providerName: provider.get('providerName'),
        //
        requestTime: leg.get('requestTime'),
        anchor: leg.get('anchor'),
        noSharing: this.get('noSharing'),
        purpose: leg.get('purpose'),
        serviceWindowName,
        fundingAgencyName,
        tripPurposeName,
        fareCategoryName,
        fare: segment.get('fare'),
        fareTypeName: 'cash',
        legNotes: leg.get('notes'),
        originNotes: leg.get('segments.firstObject.pick.notes'),
        destinationNotes: leg.get('segments.lastObject.drop.notes'),
        startDate: subscriptionRecord.get('startDate'),
        endDate: subscriptionRecord.get('endDate'),
        status: subscriptionRecord.get('status'),
        maximumOccurrences: subscriptionRecord.get('maximumOccurrences'),
        remainingOccurrences: subscriptionRecord.get('remainingOccurrences'),
        originPlace: this.preparePlace(origin, '-1'),
        destinationPlace: this.preparePlace(destination, '-2'),
        schedulingMode: subscriptionRecord.get('schedulingMode'),
        originPlannedEta: subscriptionRecord.get('originPlannedEta'),
        destinationPlannedEta: subscriptionRecord.get('destinationPlannedEta'),
        rider,
        exclusions,
        recurrencePatterns,
        travelNeeds,
        routeTemplate
      };
    },

    prepareSubscriptionPatchRawData(subscriptionRecord) {
      const leg = this.booking.get('activeBooking.legs.firstObject');
      const serviceWindowName = leg.get('serviceWindow.name');
      const fundingAgencyName = leg.get('fundingAgency.name');
      const tripPurposeName = subscriptionRecord.get('tripPurpose.name');
      const fareCategoryName = subscriptionRecord.get('fareCategory.name');
      const rawData = {
        data: {
          id: subscriptionRecord.get('id'),
          type: 'subscription',
          relationships: {
            serviceWindowName: {
              data: {
                type: 'serviceWindowName',
                id: serviceWindowName
              }
            }
          }
        }
      };

      if (!(0, _lodash.isNil)(fundingAgencyName)) {
        rawData.data.relationships.fundingAgencyName = {
          data: {
            type: 'fundingAgencyName',
            id: fundingAgencyName
          }
        };
      }

      if (!(0, _lodash.isNil)(tripPurposeName)) {
        rawData.data.relationships.tripPurposeName = {
          data: {
            type: 'tripPurposeName',
            id: tripPurposeName
          }
        };
      }

      if (!(0, _lodash.isNil)(fareCategoryName)) {
        rawData.data.relationships.fareCategoryName = {
          data: {
            type: 'fareCategoryName',
            id: fareCategoryName
          }
        };
      }

      return rawData;
    },

    /**
     * method is used to remove the empty data value
     * @param {Object} payload
     */
    removeEmptyRelationshipData(payload) {
      const relationships = payload.data.relationships;
      (0, _lodash.forEach)(relationships, (value, key) => {
        if (!value.data) {
          delete relationships[key];
        }
      });
    },

    /**
     * NYAAR-19927: prepare Data for edit subcription
     * @param {*Object} subscriptionRecord
     */
    async prepareSubscriptionUpdateRawData(subscriptionRecord) {
      const id = subscriptionRecord.get('id');
      const leg = this.booking.get('activeBooking.legs.firstObject');
      const fundingAgency = leg.get('fundingAgency');
      const segment = leg.get('segments.firstObject');
      const origin = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick.place'));
      const destination = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.drop.place'));
      const originPlace = await this.createAndPreparePlace(origin);
      const destinationPlace = await this.createAndPreparePlace(destination);
      await this.saveSubscriptionPhoneNumber(id, subscriptionRecord);
      subscriptionRecord.set('origin', originPlace);
      subscriptionRecord.set('destination', destinationPlace);
      subscriptionRecord.set('originNotes', leg.get('segments.firstObject.pick.notes'));
      subscriptionRecord.set('destinationNotes', leg.get('segments.lastObject.drop.notes'));
      subscriptionRecord.set('legNotes', leg.get('notes'));
      subscriptionRecord.set('fare', segment.get('fare'));
      subscriptionRecord.set('anchor', leg.get('anchor'));
      subscriptionRecord.set('noSharing', this.get('noSharing'));
      subscriptionRecord.set('requestTime', leg.get('requestTime'));
      subscriptionRecord.set('fundingAgency', fundingAgency);
    },

    createCloneLegTravelNeedRecord(cloneLegTravelNeed) {
      const store = this.get('store');
      const travelNeedType = (0, _unwrapProxy.unwrapProxy)(cloneLegTravelNeed.get('travelNeedType'));
      const passengerType = (0, _unwrapProxy.unwrapProxy)(cloneLegTravelNeed.get('passengerType'));
      const subscription = (0, _unwrapProxy.unwrapProxy)(cloneLegTravelNeed.get('subscription'));
      const legTravelNeedAttr = cloneLegTravelNeed.toJSON();
      const legTravelNeed = store.createRecord('subscription-travel-need', legTravelNeedAttr);
      legTravelNeed.set('travelNeedType', travelNeedType);
      legTravelNeed.set('passengerType', passengerType);
      legTravelNeed.set('subscription', subscription);
      legTravelNeed.set('isCloned', true);
      return legTravelNeed;
    },

    refactorTNs(legTravelNeedRecords) {
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('name').toUpperCase());
      const legTravelNeeds = this.filterConsumableTravelNeeds(legTravelNeedRecords);
      const bigSeatTravelneeds = legTravelNeeds.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase')) && !travelNeed.get('isCloned');
      });
      const uniqBigTns = {};
      bigSeatTravelneeds.forEach(t => {
        const type = t.get('travelNeedTypeNameUppercase');

        if (!uniqBigTns[type]) {
          uniqBigTns[type] = this.createCloneLegTravelNeedRecord(t);
          return;
        }

        uniqBigTns[type].set('count', parseInt(t.count, 10) + parseInt(uniqBigTns[type].count, 10));
      });
      const otherTravelNeeds = legTravelNeeds.filter(travelNeed => {
        return !bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const uniqBigTRNs = Object.values(uniqBigTns);
      return [...otherTravelNeeds, ...uniqBigTRNs];
    },

    // todo : remove this logic in the future when the payload is json api complacent
    prepareFinalPayloadForSubscription(jsonApi) {
      const included = jsonApi.included.map(entity => {
        if (entity.type === 'place') {
          const address = entity.attributes.address;
          const location = entity.attributes.location;
          delete entity.attributes.address;
          delete entity.attributes.location;
          return { ...entity,
            relationships: {
              address: {
                data: {
                  type: 'address',
                  ...address
                }
              },
              location: {
                data: {
                  type: 'location',
                  ...location
                }
              }
            }
          };
        }

        return entity;
      });
      return {
        data: jsonApi.data,
        included
      };
    },

    preparePlace(place, idx) {
      return {
        id: idx,
        placeCategoryTypeName: 'passenger',
        geocodingMethod: 'something',
        address: this.booking.prepareAddress(place.get('address'), place.get('address.zoneName.name')),
        location: this.booking.prepareLocation(place.get('location'))
      };
    },

    /**
     * create a place, address , and location if it's new
     * @param {Model} place
     * @returns {Array<{placeCategoryTypeName: string, address: {[p: string]: *}, geocodingMethod: string, location: {lng: *, lat: *, geoNode: *}, id: *}>}
     */
    async createAndPreparePlace(place) {
      const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));

      if (place.get('isNew')) {
        const locationData = await this.getOrCreateLocation(location);
        place.set('location', locationData);
        const placeData = await this.getOrCreatePlace(place);
        return placeData;
      }

      return place;
    },

    async getOrCreateLocation(location) {
      const locationQuery = `and(eq(lat,'${location.get('lat')}'),eq(lng,'${location.get('lng')}'),eq(geoNode,'${location.get('geoNode')}'))`;
      const locations = await this.store.query('location', {
        filter: locationQuery
      });
      const locationData = locations.toArray();

      if (locationData.length) {
        return locationData.firstObject;
      }

      try {
        await location.save();
        return location;
      } catch (e) {
        console.error(e);
      }
    },

    async getOrCreatePlace(place) {
      const locationId = place.get('location.id');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const placeQuery = `in(locationId,('${locationId}'))&include=address,location`;
      const places = await this.store.query('place', {
        filter: placeQuery
      });
      const placeData = places.toArray();

      if (placeData.length) {
        return placeData.firstObject;
      }

      try {
        await address.save();
        await place.save();
        return place;
      } catch (e) {
        console.error(e);
      }
    },

    getPlacePayload(place) {
      return {
        id: place.get('id'),
        placeCategoryTypeName: 'passenger',
        geocodingMethod: 'something',
        address: {
          id: place.get('address.id'),
          ...this.booking.prepareAddress(place.get('address'), place.get('address.zoneName.name'))
        },
        location: {
          id: place.get('location.id'),
          ...this.booking.prepareLocation(place.get('location'))
        }
      };
    },

    prepareSubscriptionTravelNeeds(legTravelNeeds) {
      const subscriptionTravelNeeds = this.get('booking').filterPcaAndServiceAnimal(legTravelNeeds);
      return subscriptionTravelNeeds.map((travelNeed, idx) => {
        const positiveIndex = idx + 1;
        const subTrNeed = {
          id: `-${positiveIndex}`,
          count: travelNeed.get('count'),
          travelNeedTypeName: travelNeed.get('travelNeedType.id'),
          passengerTypeName: travelNeed.get('passengerType.id')
        };
        return subTrNeed;
      });
    },

    /**
     * create the record if its added
     * delete the record if it deleted from the UI
     * @param subscriptionRecord
     * @param legTravelNeeds
     * @returns {Array<{operateFlag: *|null, passengerTypeName: *, travelNeedTypeName: *, count: *, id: *}>}
     */
    async createAndBuildSubscriptionTravelNeeds(subscriptionRecord, legTravelNeeds) {
      const subscriptionTravelNeeds = this.get('booking').filterPcaAndServiceAnimal(legTravelNeeds);
      const newlyCreatedTravelNeeds = subscriptionTravelNeeds.filter(travelNeed => travelNeed.isNew);
      newlyCreatedTravelNeeds.forEach(tn => tn.set('operateFlag', 'added'));
      const travelNeedPromises = newlyCreatedTravelNeeds.map(tn => tn.save());
      const {
        deletedSubscriptionTravelNeeds,
        oldSubscriptionTravelNeeds
      } = this.getDeletedTravelNeeds(subscriptionRecord, subscriptionTravelNeeds);
      const deletedSubscriptionTravelNeedPromises = deletedSubscriptionTravelNeeds.map(tn => tn.save());
      const oldSubscriptionTravelNeedsPromises = oldSubscriptionTravelNeeds.map(tn => tn.save());
      await Promise.all(travelNeedPromises);
      await Promise.all(deletedSubscriptionTravelNeedPromises);
      await Promise.all(oldSubscriptionTravelNeedsPromises);
    },

    /**
     * get deleted subscriptionTravelNeeds to set the deleted flag for the subscription entity payload
     * @param {Model} subscriptionRecord
     * @param {Array} subscriptionTravelNeeds
     * @returns {Array}
     */
    getDeletedTravelNeeds(subscriptionRecord, subscriptionTravelNeeds) {
      const subscriptionId = subscriptionRecord.get('id');
      const store = this.get('store');
      const createdSubscriptionTravelNeedIds = subscriptionTravelNeeds.filter(travelNeed => !travelNeed.isNew).map(subscriptionTravelNeed => subscriptionTravelNeed.get('id'));
      const allSubscriptionTravelNeeds = store.peekAll('subscription-travel-need').filter(stn => stn.get('subscription.id') === subscriptionId);
      const deletedSubscriptionTravelNeeds = allSubscriptionTravelNeeds.filter(tn => {
        return !tn.get('isNew') && !createdSubscriptionTravelNeedIds.includes(tn.get('id'));
      });
      const oldSubscriptionTravelNeeds = allSubscriptionTravelNeeds.filter(tn => {
        return !tn.get('isNew') && createdSubscriptionTravelNeedIds.includes(tn.get('id'));
      });
      deletedSubscriptionTravelNeeds.forEach(tn => tn.set('operateFlag', 'deleted'));
      return {
        deletedSubscriptionTravelNeeds,
        oldSubscriptionTravelNeeds
      };
    },

    prepareExclusions(exclusions) {
      const sExclusions = exclusions.map((e, idx) => {
        const positiveIndex = idx + 1;

        if (e.get('suspend')) {
          return {
            id: `-${positiveIndex}`,
            startDate: e.get('startDate'),
            endDate: e.get('endDate'),
            type: e.get('type')
          };
        }

        return null;
      });
      return sExclusions;
    },

    /**
     * used to save only if the attributes are changed
     * otherwise return null
     * @param {Model} exclusions - subscription-exclusion
     * @returns {Object}
     */
    async createExclusionsForUpdate(exclusions) {
      const exclusion = exclusions.firstObject;
      const isAttributeChanged = exclusion.changedAttributes();
      const isSuspended = exclusion.get('suspend');
      const isDeleted = exclusion.get('isDeleted'); // if suspended dates are checked and changes happen in the field

      if (isSuspended && !(0, _lodash.isEmpty)(isAttributeChanged)) {
        const sExclusionsPromises = exclusions.map(e => e.save());
        await Promise.all(sExclusionsPromises);

        if (sExclusionsPromises.length) {
          return this.getSubscriptionExclusionPayload(exclusions);
        }
      } // if suspended dates are checked and no changes


      if (isSuspended && (0, _lodash.isEmpty)(isAttributeChanged)) {
        return this.getSubscriptionExclusionPayload(exclusions);
      } // if suspended dates are unchecked delete the record


      if (isDeleted) {
        await exclusion.save();
      }

      return null;
    },

    /**
       * get exclusion Entity Payload
       * @param exclusions
       * @returns {Array}
       */
    getSubscriptionExclusionPayload(exclusions) {
      return exclusions.map(exclusion => ({
        id: exclusion.get('id'),
        startDate: exclusion.get('startDate'),
        endDate: exclusion.get('endDate'),
        type: exclusion.get('type')
      }));
    },

    prepareReccurencePatterns(reccurencePatterns) {
      return reccurencePatterns.map((r, idx) => {
        const positiveIndex = idx + 1;
        return {
          id: `-${positiveIndex}`,
          ...r.toJSON()
        };
      });
    },

    /**
     * save the record if the attributes is changed
     * @param {subscription-exclusion} reccurencePatterns - Model
     * @returns {Array<Object>}
     */
    async createReccurencePatternsForUpdate(reccurencePatterns) {
      const isAttributeChanged = reccurencePatterns.firstObject.changedAttributes();

      if (!(0, _lodash.isEmpty)(isAttributeChanged)) {
        const sReccurencePatternPromises = reccurencePatterns.map(r => r.save());
        await Promise.all(sReccurencePatternPromises);
        return reccurencePatterns.map(r => ({
          id: r.get('id'),
          ...r.toJSON()
        }));
      }

      return reccurencePatterns.map(r => ({
        id: r.get('id'),
        ...r.toJSON()
      }));
    },

    prepareSubscriptionPhoneNoPayload(phoneNumber) {
      if (phoneNumber) {
        return {
          id: phoneNumber.get('id'),
          ...phoneNumber.toJSON()
        };
      }

      return null;
    },

    // NYAAR-13442 Booking: Consumable travel needs with count=0 should not be saved in leg travel needs tables
    filterConsumableTravelNeeds(legTravelNeedRecords) {
      const travelNeedItems = this.get('booking.travelNeedItems'); // NYAAR-19052 -On editing subscription, should display Ambulatory in 1st row of Travel Need Although count is 0.

      const consumableTravelNeeds = travelNeedItems.toArray().filter(tr => {
        const trName = tr.get('name').toUpperCase();
        return parseInt(tr.get('vehicleCapacityCount'), 10) > 0 && !EXCLUDED_TRAVELNEED.includes(trName);
      });
      const TR_N_TO_NOT_SAVE_IF_COUNT_ZERO = consumableTravelNeeds.map(tr => tr.get('name').toUpperCase());
      return legTravelNeedRecords.filter(lt => !(TR_N_TO_NOT_SAVE_IF_COUNT_ZERO.indexOf(lt.get('travelNeedTypeNameUppercase')) > -1 && parseInt(lt.get('count'), 10) === 0));
    },

    async fetchFutureTrips(subscriptionId) {
      try {
        let subscriptionTrips = await this.get('booking').fetchSubscriptionTrips(subscriptionId);
        subscriptionTrips = (0, _lodash.flattenDeep)(subscriptionTrips);
        let futureTrips = this.filterFutureTrips(subscriptionTrips);
        const futureTripIds = futureTrips.map(function (trip) {
          return trip && trip.id;
        });
        const futureTripsIdsInClause = futureTripIds.map(t => `'${t}'`).join(',');
        const stopByTripId = `in(trip,(${futureTripsIdsInClause}))`;
        const dispatchTrips = await this.get('store').query('stop-point', {
          filter: stopByTripId
        });

        if ((0, _lodash.isEmpty)(dispatchTrips)) {
          futureTrips = this.get('booking.currentTrips').filter(trip => futureTripIds.includes(trip.get('id')));
          return this.addIsCheckedPropertySubscriptionTrips(futureTrips) || [];
        }

        futureTrips = (0, _lodash.uniqBy)(dispatchTrips.toArray(), ft => ft.get('trip.tripId'));
        const dispatchIds = futureTrips.map(trip => trip.get('trip.tripId'));
        const tripIds = this.differenceDispatchTrips(dispatchIds, futureTripIds);
        futureTrips = this.get('booking.currentTrips').filter(trip => tripIds.includes(trip.get('id')));
        return this.addIsCheckedPropertySubscriptionTrips(futureTrips) || [];
      } catch (e) {
        console.error(e);
      }
    },

    filterFutureTrips(trips) {
      const futureTrips = trips.filter(trip => {
        this.tripHaveNoPromiseTimeAndLegRequestTime(trip);
        const promiseTime = trip.attributes.promiseTime && (0, _moment.default)(trip.attributes.promiseTime);
        const currentTime = (0, _moment.default)().startOf('day');
        const currentDate = (0, _moment.default)(currentTime).format('YYYY-MM-DD');
        const promiseDate = (0, _moment.default)(promiseTime).format('YYYY-MM-DD');
        const greaterThanPromiseTime = promiseTime ? currentTime.isBefore(promiseTime) && ((0, _moment.default)(promiseDate).isAfter(currentDate) || (0, _moment.default)(promiseDate).isSame(currentDate)) : false;
        const status = trip.attributes.status && trip.attributes.status.toLowerCase();
        return greaterThanPromiseTime && status !== 'canceled';
      });
      const sortedTrips = futureTrips.sortBy('tripId'); //NYAAR-19331-single leg is created with two trips for database purpose,uniq those trips for cancellation trips

      const uniqTrips = sortedTrips.reverse().uniqBy('relationships.segment.data.id');
      return uniqTrips || [];
    },

    tripHaveNoPromiseTimeAndLegRequestTime(trip) {
      const hasPromiseTime = trip.attributes.hasOwnProperty('promiseTime');

      if (!hasPromiseTime) {
        const bookingId = trip.relationships.booking.data.id;
        const bookingInfo = this.get('booking.currentBookings').find(booking => booking.get('id') === bookingId);

        if (bookingInfo) {
          trip.attributes.promiseTime = bookingInfo.firstLeg.get('requestTime').toISOString();
        }
      }
    },

    addIsCheckedPropertySubscriptionTrips(trips) {
      const futureTrips = trips;
      return futureTrips.map(trip => {
        const id = parseInt(trip.get('id'), 10);
        return {
          tripId: trip.get('tripId'),
          id: id,
          isChecked: false
        };
      });
    },

    differenceDispatchTrips(dispatchTrips, futureTripIds) {
      if (futureTripIds.length >= dispatchTrips.length) {
        return (0, _lodash.difference)(futureTripIds, dispatchTrips);
      }

      return (0, _lodash.difference)(dispatchTrips, futureTripIds);
    },

    async cancelFutureTrips(subscriptionId) {
      try {
        const session = this.get('session');
        const tripIds = this.get('booking').tripIds;
        const subscriptionTripsCancelPayload = {
          data: {
            type: 'subscriptionTripCancellation',
            attributes: {
              tripIds: tripIds
            }
          }
        };
        const json = JSON.stringify(subscriptionTripsCancelPayload);
        return await this.get('ajax').post(_apiUrls.API.bookingService.host + '/subscription/' + subscriptionId + '/trip-cancellation', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${session.data.authenticated.token}`
          },
          data: json
        });
      } catch (e) {
        const errorMessage = 'FAILED TO CANCEL SUBSCRIPTION TRIPS ' + e.message; // eslint-disable-next-line no-throw-literal

        throw {
          message: errorMessage
        };
      }
    },

    async deleteSubscription(subscriptionId) {
      try {
        const session = this.get('session');
        return await this.get('ajax').post(_apiUrls.API.bookingService.host + '/subscription/' + subscriptionId, {
          method: 'DELETE',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${session.data.authenticated.token}`
          }
        });
      } catch (e) {
        const errorMessage = 'FAILED TO DELETE SUBSCRIPTION ' + e.message; // eslint-disable-next-line no-throw-literal

        throw {
          message: errorMessage
        };
      }
    },

    async saveSubscriptionServiceNeeds(subscriptionId, subscriptionServiceNeeds) {
      await this.get('store').queryRecord('subscription', subscriptionId);
      const subscription = this.get('store').peekRecord('subscription', subscriptionId);
      subscriptionServiceNeeds.forEach(subscriptionServiceNeed => {
        subscriptionServiceNeed.set('subscription', subscription);
      });
      await subscriptionServiceNeeds.reduce((acu, subscriptionServiceNeed) => {
        return acu.then(() => {
          return subscriptionServiceNeed.save();
        });
      }, Promise.resolve());
    },

    /**
     * this function is used in subscription form
     * @param {*} subscriptionId(Number)
     * @param {*} subscriptionData(current subscription model)
     */
    async saveSubscriptionPhoneNumber(subscriptionId, subscriptionData) {
      const maxPhoneNumberLength = 10;
      const phoneNumbers = subscriptionData.get('phoneNumbers').toArray();
      const subscriptionRecord = this.get('store').peekRecord('subscription', subscriptionId);
      const phoneNumberRecords = [];
      await phoneNumbers.reduce((acu, number) => {
        return acu.then(async () => {
          //save the phone number if its have the required number length
          if (number.get('fullPhoneNumber').length === maxPhoneNumberLength) {
            const savedPickPhoneNumberRecord = await this.querySubscriptionPhoneNumber(number);
            let phoneNumberRecord = savedPickPhoneNumberRecord.get('firstObject');

            if (!savedPickPhoneNumberRecord.length) {
              const phoneumber = await number.save();
              phoneNumberRecord = phoneumber;
            }

            phoneNumberRecords.pushObject(phoneNumberRecord);
          }
        });
      }, Promise.resolve());
      subscriptionRecord.set('phoneNumbers', phoneNumberRecords);
    },

    async querySubscriptionPhoneNumber(subscriptionPhoneNumber) {
      const areaCode = subscriptionPhoneNumber.get('areaCode');
      const phoneNumber = subscriptionPhoneNumber.get('phoneNumber');
      const description = subscriptionPhoneNumber.get('description');
      const phoneNumberRecord = await this.get('store').query('subscription-phone-number', {
        filter: `and(eq(areaCode,'${areaCode}'),eq(phoneNumber,'${phoneNumber}'),eq(description,'${description}'))`
      });
      return phoneNumberRecord.toArray();
    },

    //Trips showed in the subscription cancellation screen must be same as trips showed in the View All Trips table
    async fetchTripsForCancelSubscription(subscriptionId) {
      try {
        let subscriptionTrips = await this.get('booking').fetchSubscriptionTrips(subscriptionId);
        subscriptionTrips = (0, _lodash.flattenDeep)(subscriptionTrips);
        let futureTrips = this.filterFutureTrips(subscriptionTrips);
        const futureTripIds = futureTrips.map(trip => trip && trip.id);
        futureTrips = this.get('booking.currentTrips').filter(trip => futureTripIds.includes(trip.get('id')));
        const uniqTrips = this.get('booking').uniqTrips(futureTrips);
        return uniqTrips || [];
      } catch (e) {
        console.error(e);
      }
    },

    async _getSubscription(subscriptionId) {
      return await this.store.findRecord('subscription', subscriptionId);
    }

  });

  _exports.default = _default;
});