define("adept-iq/pods/components/side-drawer/system-config/places/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/places/table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PASSENGER_TYPE_PLACE_CATEGORY = 'passenger';

  var _default = _component.default.extend({
    classNames: ['places-table'],
    config: _config.default,
    enableWarningRowChangeAlert: true,
    paginationEnabled: true,

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        return records.filter(place => place.get('placeCategoryType.name').toLowerCase() !== PASSENGER_TYPE_PLACE_CATEGORY);
      });
    },

    /**
     * this method override the save record method in generic table component
     * @param record
     * @returns {*}
     */
    saveRecord(record) {
      const saveRecordTask = this.get('saveRecordTask');
      if (saveRecordTask) return saveRecordTask.perform();
      return record.save();
    },

    hasPendingChanges: Ember.computed('placeFormChanges', function () {
      const placeFormChanges = this.get('placeFormChanges') || false;
      return placeFormChanges;
    })
  });

  _exports.default = _default;
});