define("adept-iq/classes/active-contexts/schedule/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.zoneNode = _exports.vehicleNode = _exports.ssoUserNode = _exports.ssoRoleNode = _exports.ssTripStopNode = _exports.ssTripNode = _exports.ssStopNode = _exports.ssRouteNode = _exports.ssRouteBreakNode = _exports.riderNode = _exports.driverNode = _exports.default = _exports.bsSubscriptionNode = _exports.bsStopNode = _exports.bsSegmentNode = _exports.bsLegNode = _exports.bsBookingNode = _exports.activeContextNodes = _exports.activeContextGraph = void 0;
  // Rider Managament Related Nodes
  const riderNode = {
    id: 'rider',
    modelName: 'rider',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip',
      path: 'trips'
    }],
    providerFilterKey: 'providers',
    scheduleFilterKey: 'schedules'
  }; // Driver Related Nodes

  _exports.riderNode = riderNode;
  const driverNode = {
    id: 'driver',
    modelName: 'driver',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }],
    providerFilterKey: 'provider'
  }; // Vehicle Related Nodes

  _exports.driverNode = driverNode;
  const vehicleNode = {
    id: 'vehicle',
    modelName: 'vehicle',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-route',
      path: 'routes'
    }, {
      type: 'hasMany',
      nodeId: 'driver',
      path: 'drivers'
    }],
    providerFilterKey: 'provider'
  }; // Zone Related Nodes

  _exports.vehicleNode = vehicleNode;
  const zoneNode = {
    id: 'zone',
    modelName: 'zone',
    links: [],
    providerFilterKey: false
  }; // Scheduling Related Nodes

  _exports.zoneNode = zoneNode;
  const ssRouteNode = {
    id: 'ss-route',
    modelName: 'route',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip',
      path: 'trips'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    scheduleFilterKey: 'schedule',
    leftTimeConstraint: 'plannedStartTime',
    rightTimeConstraint: 'plannedEndTime'
  };
  _exports.ssRouteNode = ssRouteNode;
  const ssTripNode = {
    id: 'ss-trip',
    modelName: 'trip',
    links: [{
      type: 'belongsTo',
      nodeId: 'ss-route',
      path: 'route'
    }, {
      type: 'hasMany',
      nodeId: 'ss-trip-stop',
      path: 'stops'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    scheduleFilterKey: 'schedule',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  };
  _exports.ssTripNode = ssTripNode;
  const ssTripStopNode = {
    id: 'ss-trip-stop',
    modelName: 'trip-stop',
    links: [{
      type: 'belongsTo',
      nodeId: 'ss-trip',
      path: 'trip'
    }],
    isActive: true,
    providerFilterKey: 'trip.provider',
    scheduleFilterKey: 'trip.schedule',
    leftTimeConstraint: 'trip.routeTripStartTime',
    rightTimeConstraint: 'trip.routeTripEndTime'
  };
  _exports.ssTripStopNode = ssTripStopNode;
  const ssRouteBreakNode = {
    id: 'ss-route-break',
    modelName: 'route-break',
    links: [{
      type: 'belongsTo',
      nodeId: 'ss-route',
      path: 'route'
    }],
    isActive: true,
    providerFilterKey: 'route.provider',
    scheduleFilterKey: 'route.schedule',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  };
  _exports.ssRouteBreakNode = ssRouteBreakNode;
  const ssStopNode = {
    id: 'ss-stop',
    modelName: 'stop-point',
    links: [],
    isActive: true,
    showUnAssignedProvider: true,
    providerFilterKey: 'computedProvider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  }; // booking service related nodes

  _exports.ssStopNode = ssStopNode;
  const bsBookingNode = {
    id: 'bs-booking',
    modelName: 'booking',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-subscription',
      path: 'subscription'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsBookingNode = bsBookingNode;
  const bsLegNode = {
    id: 'bs-leg',
    modelName: 'leg',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-booking',
      path: 'booking'
    }, {
      type: 'belongsTo',
      nodeId: 'rider',
      path: 'rider'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsLegNode = bsLegNode;
  const bsSegmentNode = {
    id: 'bs-segment',
    modelName: 'segment',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-leg',
      path: 'leg'
    }, {
      type: 'hasMany',
      nodeId: 'bs-stop',
      path: 'stops'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsSegmentNode = bsSegmentNode;
  const bsStopNode = {
    id: 'bs-stop',
    modelName: 'segment-stop',
    links: [],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsStopNode = bsStopNode;
  const bsSubscriptionNode = {
    id: 'bs-subscription',
    modelName: 'subscription',
    links: [],
    providerFilterKey: false,
    isActive: false
  };
  _exports.bsSubscriptionNode = bsSubscriptionNode;
  const ssoUserNode = {
    id: 'sso-user',
    modelName: 'sso-user',
    links: [{
      type: 'hasMany',
      nodeId: 'sso-role',
      path: 'roles'
    }],
    providerFilterKey: false
  };
  _exports.ssoUserNode = ssoUserNode;
  const ssoRoleNode = {
    id: 'sso-role',
    modelName: 'sso-role',
    isActive: false,
    links: [{
      type: 'hasMany',
      nodeId: 'sso-user',
      path: 'users'
    }],
    providerFilterKey: false
  };
  _exports.ssoRoleNode = ssoRoleNode;
  const activeContextNodes = [// vehicle graph
  vehicleNode, // scheduling graph
  ssTripNode, ssRouteNode, ssTripStopNode];
  _exports.activeContextNodes = activeContextNodes;
  const activeContextGraph = activeContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.activeContextGraph = activeContextGraph;
  var _default = activeContextGraph;
  _exports.default = _default;
});