define("adept-iq/pods/components/sandbox-setup/step3/component", ["exports", "adept-iq/config/icon-paths", "lodash"], function (_exports, _iconPaths, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    widget: Ember.inject.service(),
    selectedOption: '',
    availableStrategies: null,
    isOptimizeDisabled: false,

    init() {
      this._super(...arguments);

      this.loadOptimizationStrategies();
    },

    async loadOptimizationStrategies() {
      try {
        let strategies = await this.store.findAll('engine-strategy');
        strategies = strategies.filter(strategy => strategy.id !== 'default');
        strategies = (0, _lodash.orderBy)(strategies, ['id'], ['desc']);
        this.set('availableStrategies', strategies);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading strategies:', error);
        this.notifications.error('Failed to load optimization strategies.');
      }
    },

    onScheduleGenerationSuccessOrFailure: Ember.observer('workspace.scheduleReoptimizeStatus', 'workspace.reoptimizeInProgress', function () {
      const notifications = this.get('notifications'); // Reoptimization is in progress

      if (this.workspace.reoptimizeInProgress) {
        return;
      } // Schedule Generation Processed , enable the optimized btn


      if (this.workspace.scheduleReoptimizeStatus === 'success' || this.workspace.scheduleReoptimizeStatus === 'fail') this.set('isOptimizeDisabled', false);

      if (this.workspace.scheduleReoptimizeStatus === 'success') {
        const router = this.get('router');
        const dashboardId = 'workspaces-schedule/schedule';
        this.store.findRecord('dashboard', dashboardId).then(dashboard => {
          router.transitionTo('dashboard', dashboard.role, dashboard.id);
        }).catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error finding dashboard:', error);
          notifications.warning('Scheduled dashboard not found');
        });
      }

      if (this.workspace.scheduleReoptimizeStatus === 'fail') {
        notifications.warning('SCHEDULE FAILED TO OPTIMIZE');
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      document.addEventListener('click', this.addBoundingBox.bind(this));
    },

    willDestroyElement() {
      this._super(...arguments);

      document.removeEventListener('click', this.addBoundingBox.bind(this));
    },

    addBoundingBox(event) {
      if (!this.element) {
        return;
      }

      let containersToHighlight = []; // Get all radio buttons and the optimize button

      const radioButtons = Array.from(this.element.querySelectorAll('.radio-button-input input[type="radio"]'));
      const optimizeButtonContainer = this.element.querySelector('.optimize-btn-container');
      const clickedRadioButtonContainer = event.target.closest('.radio-button-input');
      const clickedOptimizeButton = event.target.closest('.optimize-btn');

      if (!this.selectedOption) {
        containersToHighlight = radioButtons;
      } else if (optimizeButtonContainer) {
        containersToHighlight.push(optimizeButtonContainer);
      } // Prevent bounding box if the clicked element is the radio-button container or optimize button already highlighted


      if (clickedRadioButtonContainer && containersToHighlight === radioButtons || clickedOptimizeButton && containersToHighlight.includes(optimizeButtonContainer)) {
        return;
      } // Add the bounding box to the relevant containers


      containersToHighlight.forEach(container => {
        container.classList.add('bounding-box');
      });
      setTimeout(() => {
        containersToHighlight.forEach(container => {
          container.classList.remove('bounding-box');
        });
      }, 400);
    },

    get iconPaths() {
      return {
        sandbox: _iconPaths.default.actionMenu.sandbox,
        upload: _iconPaths.default.actionMenu.upload,
        download: _iconPaths.default.actionMenu.download,
        checkmark: _iconPaths.default.actionMenu.checkmark
      };
    },

    actions: {
      async onOptimizeBtnClick() {
        this.set('isOptimizeDisabled', true); //step2 : Trip-Upload Should Generate currentSchedule

        const workspace = this.get('workspace');
        const notifications = this.get('notifications');
        const selectedStrategy = this.get('selectedOption');
        let currentSchedule = workspace.get('currentSchedule');
        const selectedDate = workspace.get('selectedDate');

        if (!currentSchedule) {
          await workspace.updateCurrentSchedule(selectedDate);
          currentSchedule = workspace.get('currentSchedule');
        }

        if (!currentSchedule) {
          notifications.warning('Schedule could not be found or created');
          this.set('isOptimizeDisabled', false);
          return;
        }

        workspace.pushState('schedule-generate', {
          currentSchedule,
          radioValue: 'optimize',
          strategyName: selectedStrategy
        });
      },

      updateOption(selectedOption) {
        // Handle the option change
        this.set('selectedOption', selectedOption);
      }

    }
  });

  _exports.default = _default;
});