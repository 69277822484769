define("adept-iq/pods/landing-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0m7JxBVa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"landing-page\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"card-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"col-md-4\"],[9],[0,\"\\n             \"],[5,\"card-item\",[],[[\"@iconPath\",\"@altText\",\"@title\",\"@onClick\"],[[22,0,[\"iconPaths\",\"apiDocumentation\"]],\"API Documentation\",\"API Documentation\",[27,\"action\",[[22,0,[]],\"openApiDocumentation\"],null]]]],[0,\"\\n          \"],[10],[0,\"\\n           \"],[7,\"div\"],[11,\"class\",\"col-md-4\"],[9],[0,\"\\n            \"],[5,\"card-item\",[],[[\"@iconPath\",\"@altText\",\"@title\",\"@onClick\"],[[22,0,[\"iconPaths\",\"sandbox\"]],\"Sandbox\",\"Sandbox\",[27,\"action\",[[22,0,[]],\"navigateToSandboxSetup\"],null]]]],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"col-md-4\"],[9],[0,\"\\n            \"],[5,\"card-item\",[],[[\"@iconPath\",\"@altText\",\"@title\",\"@onClick\"],[[22,0,[\"iconPaths\",\"myProfile\"]],\"Profile\",\"My Profile\",[27,\"action\",[[22,0,[]],\"openMyProfile\"],null]]]],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/landing-page/template.hbs"
    }
  });

  _exports.default = _default;
});