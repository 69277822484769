define("adept-iq/pods/components/workspace-navbar/component", ["exports", "ember-concurrency", "lodash", "adept-iq/config/environment", "adept-iq/mixins/async-initiation-operation", "moment"], function (_exports, _emberConcurrency, _lodash, _environment, _asyncInitiationOperation, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    or,
    equal,
    and
  } = Ember.computed;
  const DEFAULT_TERM = 'default';
  const BOOKING_TIMELINE_CONFIG_PATH = 'config-Book_Trips/Future_Booking_Timeline';

  var _default = Ember.Component.extend(_asyncInitiationOperation.default, {
    classNames: ['workspace-navbar'],
    classNameBindings: ['reoptimizeInProgress', 'isExported'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    widget: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    timeFormat: 'm-d-Y',
    schedules: null,
    scheduleDate: null,
    isReoptimizeOpen: false,
    minScheduleDate: null,
    isReconcileCsvOnly: true,
    // TODO: remove this flag after 2.9.0 release
    showNotificationFailureResults: false,
    notificationFailureResults: null,
    currentScheduleAdvanceNotificationSent: false,
    // TODO: saved in schedule table
    notificationEnabled: false,
    isScheduleDashboard: readOnly('workspace.isScheduleDashboard'),
    isDispatchDashboard: readOnly('workspace.isDispatchDashboard'),
    isBookingDashboard: readOnly('workspace.isBookingDashboard'),
    isRiderManagementDashboard: readOnly('workspace.isRiderManagementDashboard'),
    currentSchedule: readOnly('workspace.currentSchedule'),
    isExported: readOnly('workspace.isExported'),
    operationType: readOnly('workspace.operationType'),
    reoptimizeInProgress: or('workspace.reoptimizeInProgress'),
    systemConfigInProgress: or('workspace.systemConfigInProgress'),
    disableReoptimizeBtn: Ember.computed('isExported', 'reoptimizeInProgress', 'currentSchedule', function () {
      const currentSchedule = this.get('currentSchedule');
      if (Ember.isEmpty(currentSchedule)) return true;
      return this.get('isExported') || this.get('reoptimizeInProgress');
    }),
    isExporting: equal('operationType', 'export'),
    isBrokerExporting: equal('operationType', 'brokerExport'),
    isExportedAndEnabled: and('isExported', 'notificationEnabled'),

    async init() {
      this._super(...arguments);

      const {
        value: notificationEnabled
      } = _environment.default.APP.test ? {
        value: false
      } : await this.get('store').findRecord('cs-config-item', 'config-System_Configuration-notifications-advance_trip_reminder/enabled');
      this.set('notificationEnabled', notificationEnabled);
      this.set('minScheduleDate', (0, _moment.default)().startOf('day').add(1, 'day').toISOString());
    },

    didInsertElement() {
      this._super(...arguments);

      this.refreshTask.perform();
    },

    canSendAdvanceNotification: Ember.computed('workspace.currentSchedule', function () {
      const currentSchedule = this.get('currentSchedule');

      if (Ember.isNone(currentSchedule)) {
        return false;
      }

      const scheduleStart = (0, _moment.default)(currentSchedule.get('start'));
      const currentTime = (0, _moment.default)();
      const canSend = currentTime.isBefore(scheduleStart);
      return canSend;
    }),
    workspaceName: Ember.computed('workspace.dashboardModel.{category,role,name}', function () {
      const dashboard = this.get('workspace.dashboardModel');

      if (dashboard) {
        const dashboardRole = dashboard.get('role');
        const dashboardName = dashboard.get('name');
        const category = dashboard.get('category');

        if (category && category.includes(DEFAULT_TERM)) {
          return `${dashboardRole} [default]`;
        } else if (dashboardName && dashboardRole) return `${dashboardRole} - ${dashboardName}`;

        return dashboardRole;
      }

      return null;
    }),
    // in used by release schedule / reoptimize
    inProgressTitle: Ember.computed('reoptimizeInProgress', function () {
      const isOptimizeInProgress = this.get('reoptimizeInProgress');

      if (!isOptimizeInProgress) {
        return;
      }

      return 'OPTIMIZATION IN PROGRESS';
    }),
    // in used by release schedule / reoptimize
    progress: Ember.computed('workspace.progressValue', function () {
      const progressValue = this.get('workspace.progressValue');

      if (progressValue) {
        // reoptimize progress value should not greater than 100
        return progressValue > 100 ? 100 : progressValue;
      }

      return 0;
    }),
    maxScheduleDate: Ember.computed('bookingWindow.value', function () {
      const bookingWindowVal = parseInt(this.get('bookingWindow.value'), 10);
      return bookingWindowVal ? (0, _moment.default)().endOf('day').add(bookingWindowVal, 'days').toDate() : null;
    }),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      // incase today ever stretches into the next day, this will update once the user moves to that date
      // thus today -> tomorrow, and booking window will also slide forward
      //this.get('fetchBookingWindow').perform();
      this.get('fetchSchedulesTask').perform();
      this.set('minScheduleDate', (0, _moment.default)().startOf('day').add(1, 'day').toISOString());
      yield;
    }),
    fetchBookingWindow: (0, _emberConcurrency.task)(function* () {
      const config = yield this.store.findRecord('cs-config-item', BOOKING_TIMELINE_CONFIG_PATH);

      if (config) {
        this.set('bookingWindow', config);
      }
    }),
    fetchSchedulesTask: (0, _emberConcurrency.task)(function* () {
      const filterQuery = 'eq(type,\'primary\')';
      let schedules = yield this.store.query('schedule', {
        filter: filterQuery
      });

      if (schedules) {
        schedules = schedules.sortBy('end');
      }

      this.set('schedules', schedules);
    }),

    async reInitializeStore() {
      const store = this.store;
      const workspace = this.get('workspace');
      const topActiveContext = this.get('activeContext.topActiveContext');
      const notifications = this.get('notifications'); // clear active context traversal

      Ember.run.scheduleOnce('actions', topActiveContext, 'reset'); // dump all widget data

      topActiveContext.trigger('clear');
      await notifications.get('monitorForEmergencyTask').cancelAll(); // dump ember store

      await Ember.run.scheduleOnce('destroy', store, 'init');
      await Ember.run.next(() => {
        store.findAll('cs-config-category');
        store.findAll('cs-config-item');
        store.findAll('route-template');
        store.findAll('cs-config-permission').then(configPermissions => {
          return workspace.set('configPermissions', configPermissions.get('firstObject.value'));
        });
        notifications.get('monitorForEmergencyTask').perform();
      });
    },

    async updateSchedulingWorkspaceTimeRange(date) {
      const momentDate = (0, _moment.default)(date);
      await this.reInitializeStore();
      await Ember.run.later(() => {
        this.workspace.setProperties({
          startDate: momentDate.startOf('day').toDate(),
          endDate: momentDate.endOf('day').toDate()
        });
        this.refreshTask.perform();
      }, 1000); // delay because of reInitializeStore.
    },

    formattedPhoneNo(phone) {
      if (!phone) {
        return;
      }

      const phoneNoStr = phone.toString().substring(0, 10).replace(/[^\d]/g, '');

      if (phoneNoStr.length === 10) {
        return phoneNoStr.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }

      return phoneNoStr;
    },

    insertSources(tripDestination, key, sources) {
      const keyObject = JSON.parse(key);

      if (tripDestination.destination && (tripDestination.status.code === 'error' || tripDestination.status.code === 'in-progress')) {
        const phoneNumber = tripDestination.destination.sms || tripDestination.destination.textToSpeech;
        const errorCode = tripDestination.status.message ? tripDestination.status.message : 'In Progress';
        sources.push({
          tripId: `Rider IDs [${keyObject.externalRiderIds.toString()}], Trip IDs [${keyObject.externalTripIds.toString()}]`,
          phoneNumber: this.formattedPhoneNo(phoneNumber),
          errorCode: errorCode
        });
      }
    },

    processAdvanceTripReminderResponse(response) {
      const tripIds = response.data.attributes.sourceIds;
      const keys = Object.keys(tripIds);
      const sources = [];
      keys.map(key => {
        const tripDestinationArray = tripIds[key]; // filter out duplicate phone numbers by sms or textToSpeech

        const tripDestinationArrayUniq = _lodash.default.uniqBy(tripDestinationArray, function (tripDestinationObject) {
          const destination = Array.isArray(tripDestinationObject) ? tripDestinationObject.firstObject.destination : tripDestinationObject.destination;
          const sms = destination ? destination.sms : '';
          const textToSpeech = destination ? destination.textToSpeech : '';
          const part1 = sms ? sms : '';
          const part2 = textToSpeech ? textToSpeech : '';
          return part1 + part2;
        });

        tripDestinationArrayUniq.map(tripDestinationObject => {
          if (Array.isArray(tripDestinationObject)) {
            const tripDestinations = tripDestinationObject;
            tripDestinations.map(tripDestination => {
              this.insertSources(tripDestination, key, sources);
            });
          } else {
            const tripDestination = tripDestinationObject;
            this.insertSources(tripDestination, key, sources);
          }
        });
      });
      return sources;
    },

    performPostAdvanceTripReminder: (0, _emberConcurrency.task)(function* (scheduleDate) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const options = {
        outputResult: true
      };

      try {
        const {
          isJobSuccess,
          results
        } = yield this.createInitiationAsyncOperation.perform(scheduleDate, 'advanceTripRemindersGroupedByTextToSpeech', options);

        if (isJobSuccess) {
          const response = JSON.parse(results);
          const sources = this.processAdvanceTripReminderResponse(response);
          const status = sources.length > 0 ? 'error' : 'success';
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          return {
            status: status,
            sources: sources
          };
        }

        this.get('notifications').warning('Processing Advance Trip Reminder failed.');
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        return {
          status: null,
          sources: []
        };
      } catch (error) {
        console.log(error.message); // eslint-disable-line no-console

        this.get('notifications').warning('Processing Advance Trip Reminder failed.');
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        return {
          status: error.status,
          sources: []
        };
      }
    }).drop(),

    changeModalZIndex(zindex) {
      const modalPopup = Ember.$('.ember-modal-overlay.translucent.tooltip-modal-styles-overlay');
      modalPopup.css('z-index', zindex);
    },

    async handleAdvanceTripReminder(currentSchedule) {
      const tooltip = this.get('tooltip');
      const scheduleStart = (0, _moment.default)(currentSchedule.get('start'));
      const currentTime = (0, _moment.default)();
      const response = await this.performPostAdvanceTripReminder.perform(currentSchedule.name);
      const tip = `The system detected (${response.sources.length}) notification failure(s).`;
      const canResend = currentTime.isBefore(scheduleStart);

      if (response.status === 'error') {
        tooltip.pushConfirmation({
          title: 'Reminder Notification',
          tip: tip,
          primaryActionText: canResend ? 'Resend' : null,
          primaryAction: () => {
            if (canResend) {
              this.handleAdvanceTripReminder(currentSchedule);
            }

            tooltip.reset();
          },
          secondaryActionText: 'Review',
          secondaryAction: () => {
            this.changeModalZIndex(90);
            this.set('notificationFailureResults', response.sources);
            this.set('showNotificationFailureResults', true);
          }
        });
      } else if (response.status === 'success') {
        currentSchedule.set('notificationStatus', response.status);
        currentSchedule.save();
      }
    },

    //to check the current schedule is generated(in-progess)
    async fetchCurrentSchedule(currentSchedule) {
      const store = this.get('store');
      const workspace = this.get('workspace');
      const schedules = await store.query('schedule', {
        filter: `eq(id,${currentSchedule.get('id')})`
      });
      const isGenerated = schedules.firstObject.get('isGenerated');

      if (isGenerated) {
        workspace.set('reoptimizeInProgress', true);
      }

      return isGenerated;
    },

    actions: {
      onUpdateTimeRangeScheduleWorkspace(selectedDate) {
        let forceUpdate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        const currentWorkspaceDate = this.get('workspace.startDate');

        if (forceUpdate || !(0, _moment.default)(selectedDate).isSame(currentWorkspaceDate)) {
          this.updateSchedulingWorkspaceTimeRange(selectedDate);
        }
      },

      async onOptimizeBtnClick() {
        const tooltip = this.get('tooltip');
        const workspace = this.get('workspace');
        const date = (0, _moment.default)(workspace.startDate).format('MM/DD/YYYY');
        const currentSchedule = this.get('currentSchedule');

        if (await this.fetchCurrentSchedule(currentSchedule)) {
          return;
        }

        tooltip.pushConfirmation({
          title: 'Optimization',
          hasOverlay: true,
          tip: `Are you sure you want to Optimize ${date}`,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            if (await this.fetchCurrentSchedule(currentSchedule)) {
              return;
            }

            workspace.pushState('schedule-generate', {
              currentSchedule,
              radioValue: 'optimize'
            });
            tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      },

      sendAdvanceNotification() {
        const currentSchedule = this.get('currentSchedule');
        this.handleAdvanceTripReminder(currentSchedule);
      },

      onNotificationFailureClose() {
        this.set('showNotificationFailureResults', false);
        this.changeModalZIndex(9001);
      }

    }
  });

  _exports.default = _default;
});