define("adept-iq/config/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    BOOKING: {
      IN_ACTIVE: 'Reservation can’t be started for inactive clients',
      INVALID_ELIGIBILITY: 'There is no valid eligibility in the selected rider ',
      SEARCH_ERROR: 'Search error. Try again. If the issue persists, please contact your system administrator.',
      NO_MATCHES: 'There are no results matching your search criteria.',
      EMPTY_SEARCH_CRITERIA: 'ENTER SEARCH CRITERIA AND TRY AGAIN'
    }
  };
  _exports.default = _default;
});