define("adept-iq/pods/components/side-drawer/system-config/speed-region-groups/component", ["exports", "ember-concurrency", "moment", "lodash", "turfHelpers", "booleanPointInPolygon", "adept-iq/utils/guid", "adept-iq/pods/components/side-drawer/system-config/speed-region-groups/table/speed-region-groups/config", "adept-iq/pods/components/side-drawer/system-config/speed-region-groups/table/speed-zones/config", "adept-iq/pods/components/side-drawer/system-config/speed-region-groups/config", "adept-iq/models/speed-region-group"], function (_exports, _emberConcurrency, _moment, _lodash, _turfHelpers, _booleanPointInPolygon, _guid, _config, _config2, _config3, _speedRegionGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WAIT_TIME = void 0;
  const WAIT_TIME = 500;
  _exports.WAIT_TIME = WAIT_TIME;
  const MAX_ADDRESS = 25;
  const MIN_SPEED_PERCENTAGE = -90;
  const MAX_SPEED_PERCENTAGE = 100;
  const daysOfWeekMap = [{
    label: 'Mon',
    index: 1
  }, {
    label: 'Tue',
    index: 2
  }, {
    label: 'Wed',
    index: 3
  }, {
    label: 'Thu',
    index: 4
  }, {
    label: 'Fri',
    index: 5
  }, {
    label: 'Sat',
    index: 6
  }, {
    label: 'Sun',
    index: 0
  }];
  /* eslint-disable comma-spacing */
  // speed factor defaults to 1.0

  const defaultSpeedFactor = [[1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0], [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0], [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0], [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0], [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0], [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0], [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0]];
  /* eslint-enable comma-spacing */

  var _default = Ember.Component.extend({
    classNames: ['speed-region-groups'],
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    geocode: Ember.inject.service(),
    addressConfig: _config3.addressConfig,
    srgConfig: _config.default,
    srConfig: _config2.default,
    place: null,
    speedRegionGroupNameDupError: false,
    dateRangeError: false,
    mandatoryError: false,
    disableBtns: null,
    dateFormat: 'm/d/Y',
    timeFormat: 'G:i K',
    dateTimeFormat: 'm/d/Y G:i K',
    timeIntervalMap: null,
    speedRegionGroupChanges: false,
    isNewSpeedRegionGroup: false,
    isNewSpeedRegion: false,
    isDeleteSpeedRegionGroup: false,
    currentRegionSetType: '',
    // speed or blockout, exported from system-config main view
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedSpeedRegionRow: Ember.computed.readOnly('speedRegionTableRef.checkedRows.firstObject'),
    selectedSpeedRegionAddressRow: Ember.computed.readOnly('speedRegionAddressTableRef.checkedRows.firstObject'),
    disableUndobtn: Ember.computed('disableForm', 'speedRegionGroupFormChanges', function () {
      const disableForm = this.get('disableForm');
      const speedRegionGroupFormChanges = this.get('speedRegionGroupFormChanges');
      const returnValue = !(!disableForm && speedRegionGroupFormChanges);
      this.hasChanges(!returnValue);
      return returnValue;
    }),
    speedRegionGroupFormChanges: Ember.computed('speedRegionGroupChanges', 'speedRegionGroup.{id,name}', function () {
      const speedRegionGroupChanges = this.get('speedRegionGroupChanges');
      return speedRegionGroupChanges;
    }),
    disableSavebtn: Ember.computed('speedRegionGroupChanges', 'speedRegionGroup.{id,name}', function () {
      const speedRegionGroupChanges = this.get('speedRegionGroupChanges');
      return !speedRegionGroupChanges;
    }),
    disableRegionChanges: Ember.computed('disableForm', 'selectedSpeedRegionRow', function () {
      const disableForm = this.get('disableForm');
      const selectedSpeedRegionRow = this.get('selectedSpeedRegionRow');
      return disableForm || Ember.isEmpty(selectedSpeedRegionRow);
    }),
    isBlockoutRegionSetType: Ember.computed('currentRegionSetType', function () {
      return this.get('currentRegionSetType') === 'blockout';
    }),

    updateViewLabels(isBlockoutRegionSetType) {
      if (isBlockoutRegionSetType) {
        this.set('srgConfig.title', 'Blockout Region Sets');
        this.set('srConfig.title', 'Blockout Regions');
        this.set('pageTitle', 'Blockout Regions');
      } else {
        this.set('srgConfig.title', 'Speed Region Sets');
        this.set('srConfig.title', 'Speed Regions');
        this.set('pageTitle', 'Speed Regions');
      }
    },

    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      if (this.get('loadingInProgress')) return;
      this.cleanupSpeedRegionGroup();

      if (Ember.isPresent(selectedRow) || this.get('isNewSpeedRegionGroup')) {
        this.cleanupPlaceModel();
        this.cleanupSpeedRegionModel();
        this.cleanupSpeedRegionAddressModel();
      } // user selected a group row while on creating a new group region


      if (Ember.isPresent(selectedRow) && this.get('isNewSpeedRegionGroup')) {
        this.setProperties({
          'disableForm': true,
          'isNewSpeedRegion': false,
          'speedRegionGroupChanges': false,
          'isNewSpeedRegionGroup': false
        });
      }

      if (Ember.isPresent(selectedRow)) {
        this.createSpeedRegionRecords(selectedRow.get('regions'));
        this.createSpeedAddressRecords(selectedRow.get('addresses'));
      }

      this.setDefaultProperties(selectedRow);
      this.buttonSettings();

      if (!selectedRow) {
        this.buttonSettings(false, true, true);
      }
    }),
    selectedSpeedRegionRowChange: Ember.observer('selectedSpeedRegionRow', function () {
      const selectedSpeedRegionRow = this.get('selectedSpeedRegionRow');
      this.setSpeedRegionDefaultProperties(selectedSpeedRegionRow);
    }),
    currentRegionSetTypeChange: Ember.observer('isBlockoutRegionSetType', function () {
      const selectedRow = this.get('selectedRow');
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      const tableRef = this.get('tableRef'); // user selected a group row while on creating a new group region

      if (this.get('isNewSpeedRegionGroup')) {
        this.setProperties({
          'disableForm': true,
          'isNewSpeedRegion': false,
          'speedRegionGroupChanges': false
        });
        tableRef.set('config.selectFirstRow', true);
      }

      this.updateViewLabels(isBlockoutRegionSetType);
      this.buttonSettings();

      if (!selectedRow) {
        this.buttonSettings(false, true, true);
      }

      if (Ember.isPresent(tableRef)) {
        Ember.run.later(() => {
          Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data
        }, WAIT_TIME);
      }
    }),

    cleanupSpeedRegionGroup() {
      const speedRegionGroupId = this.get('speedRegionGroup.id');

      if (Ember.isPresent(speedRegionGroupId)) {
        const speedRegionGroupModel = this.get('store').peekRecord('speed-region-group', speedRegionGroupId);

        if (Ember.isPresent(speedRegionGroupModel)) {
          speedRegionGroupModel.rollbackAttributes();
        }
      }
    },

    createSpeedRegionRecords(regions) {
      const store = this.get('store');
      const speedRegionTableRef = this.get('speedRegionTableRef');

      if (Ember.isPresent(regions)) {
        regions.forEach(region => {
          const originalName = Ember.isEmpty(region.originalName) ? (0, _guid.guid)() : region.originalName;
          store.createRecord('speed-region', {
            originalName: originalName,
            name: region.name,
            polygon: region.polygon,
            speedFactor: region.speedFactor
          });
          region.originalName = originalName;
        });
      }

      if (Ember.isPresent(speedRegionTableRef)) {
        Ember.run.scheduleOnce('afterRender', speedRegionTableRef, 'refreshData'); // refresh data
      }
    },

    createSpeedAddressRecords(addresses) {
      const speedRegionAddressTableRef = this.get('speedRegionAddressTableRef');

      if (Ember.isPresent(addresses)) {
        addresses.forEach(address => {
          this.createSingleAddressRecord(address);
        });
      }

      if (Ember.isPresent(speedRegionAddressTableRef)) {
        Ember.run.scheduleOnce('afterRender', speedRegionAddressTableRef, 'refreshData'); // refresh data
      }
    },

    createSingleAddressRecord(address) {
      const store = this.get('store');
      store.createRecord('speed-region-address', {
        streetNumber: address.streetNumber,
        streetAddress: address.streetAddress,
        locality: address.locality,
        subLocality: address.subLocality,
        region: address.region,
        postalCode: address.postalCode,
        country: address.country,
        lat: address.lat,
        lng: address.lng
      });
    },

    cleanupPlaceModel() {
      const place = this.get('place');
      const address = place.get('address');
      const location = place.get('location');
      address.set('streetNumber');
      address.set('streetAddress');
      address.set('locality');
      address.set('subLocality');
      address.set('region');
      address.set('postalCode');
      address.set('freeformAddress');
      address.set('subRegion');
      address.set('country');
      location.set('lat');
      location.set('lng');
      location.set('geoNode');
      Ember.$('html,body,.tomtom-map,#speed-region').removeClass('custom-cursor');
      this.get('geocode').activateGeocode(false, this.get('place'), 'speed-region');
    },

    cleanupSpeedRegionModel() {
      const selectedRow = this.get('selectedRow');
      const speedRegionTableRef = this.get('speedRegionTableRef');
      let regions = [];

      if (Ember.isPresent(selectedRow)) {
        regions = selectedRow.regions.filter(region => !Ember.isEmpty(region.name));
        selectedRow.set('regions', regions);
      }

      if (Ember.isPresent(speedRegionTableRef)) speedRegionTableRef.dataJoin.clear();
      const store = this.get('store');
      store.peekAll('speed-region').forEach(region => {
        store.deleteRecord(region);
      });
    },

    cleanupSpeedRegionAddressModel() {
      const selectedRow = this.get('selectedRow');
      const speedRegionAddressTableRef = this.get('speedRegionAddressTableRef');
      let addresses = [];

      if (Ember.isPresent(selectedRow) && Ember.isPresent(selectedRow.addresses)) {
        addresses = selectedRow.addresses.filter(address => !Ember.isEmpty(address.streetAddress));
        selectedRow.set('addresses', addresses);
      }

      if (Ember.isPresent(speedRegionAddressTableRef)) speedRegionAddressTableRef.dataJoin.clear();
      const store = this.get('store');
      store.peekAll('speed-region-address').forEach(address => {
        store.deleteRecord(address);
      });
    },

    hasChanges(value) {
      this.set('systemConfig.hasChanges', value);
    },

    init() {
      this._super(...arguments);

      this.hasChanges = this.get('hasChanges').bind(this);
      this.set('minSpeedPercentage', MIN_SPEED_PERCENTAGE);
      this.set('maxSpeedPercentage', MAX_SPEED_PERCENTAGE);
      this.setupTimeIntervalMap();
      this.setDefaultProperties();
      this.setSpeedRegionDefaultProperties();
      this.updateViewLabels(this.get('isBlockoutRegionSetType'));
      this.setupSpeedRegionTableActions();
      this.setupPlaceModel();
    },

    didInsertElement() {
      this._super(...arguments);

      this._onKeyDown = event => {
        Ember.run.schedule('afterRender', this, 'onKeyDown', event);
      };

      this.$().on('keydown', this._onKeyDown);
    },

    willDestroyElement() {
      const speedRegionGroupChanges = this.get('speedRegionGroupChanges');

      if (speedRegionGroupChanges) {
        this.undoSpeedRegionGroup();
      }

      this._super(...arguments);

      this.cleanupPlaceModel();
      this.cleanupSpeedRegionModel();
      this.cleanupSpeedRegionAddressModel();
    },

    /*eslint-disable */
    onKeyDown(event) {
      switch (event.key) {
        case 'Enter':
          {
            event.preventDefault();
          }

        default:
          break;
      }
    },

    /*eslint-enable */
    setupPlaceModel() {
      const store = this.get('store');
      const address = store.createRecord('address');
      const location = store.createRecord('location');
      const place = store.createRecord('place', {
        address,
        location
      });
      this.set('place', place);
    },

    setupSpeedRegionTableActions() {
      const newConfigurationActions = [{
        id: 'newRegion',
        name: 'New Region',
        action: () => {
          this.addNewSpeedRegion();
        }
      }];
      this.set('additionalTableActions', newConfigurationActions);
    },

    setupTimeIntervalMap() {
      const timeInverval = 30;
      const startMoment = (0, _moment.default)('00:00:00', 'hh:mm:ss');
      const endMoment = startMoment.clone().add(timeInverval - 1, 'minutes');
      const timeIntervalMap = [];
      const maxIndex = 48;

      for (let i = 0; i < maxIndex; i++) {
        timeIntervalMap.push({
          label: `${startMoment.format('hh:mm A')} - ${endMoment.format('hh:mm A')}`,
          index: i
        });
        startMoment.add(timeInverval, 'minutes');
        endMoment.add(timeInverval, 'minutes');
      }

      this.set('timeIntervalMap', timeIntervalMap);
      this.set('daysOfWeekMap', daysOfWeekMap);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let skipDisableForm = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      const fields = {
        id: null,
        name: '',
        start: null,
        end: null,
        startTime: null,
        endTime: null,
        message: null,
        recurrence: _speedRegionGroup.CONTINUOUS_RECUR_TYPE,
        blockout: isBlockoutRegionSetType,
        regions: [],
        addresses: []
      };

      if (Ember.isPresent(record)) {
        Object.entries(fields).forEach(key => {
          if (key[0] === 'startTime' || key[0] === 'endTime') {
            fields[key[0]] = Ember.isEmpty(record.get(key[0])) ? null : record.get(key[0]);
          } else if (key[0] === 'regions') {
            const regions = record.get(key[0]) || [];
            regions.forEach(region => {
              if (Ember.isEmpty(region.originalName)) {
                region.originalName = (0, _guid.guid)();
              }
            });
            fields[key[0]] = regions;
          } else {
            fields[key[0]] = record.get(key[0]);
          }
        });

        if (!skipDisableForm) {
          this.set('disableForm', true);
        }
      }

      this.setProperties({
        'speedRegionGroup': Ember.Object.create(fields),
        'mandatoryError': false,
        'dateRangeError': false,
        'speedRegionGroupNameDupError': false,
        'speedRegionGroupChanges': false
      });
      this.hasChanges(false);
    },

    addNewSpeedRegion() {
      if (this.get('disableForm')) return;
      const selectedSpeedRegionRow = this.get('selectedSpeedRegionRow');
      const speedRegionTableRef = this.get('speedRegionTableRef');
      speedRegionTableRef.onUncheckAll();

      if (selectedSpeedRegionRow) {
        selectedSpeedRegionRow.set('selected', false);
      }

      const newRecord = this.get('store').createRecord('speed-region', {
        originalName: (0, _guid.guid)(),
        name: '',
        polygon: [],
        speedFactor: defaultSpeedFactor,
        isNewRow: true
      });
      this.setSpeedRegionDefaultProperties(newRecord);
      this.setProperties({
        isNewSpeedRegion: true,
        disableForm: false,
        speedRegionGroupChanges: true
      });
      this.updateSpeedRegionGroup();

      if (Ember.isPresent(speedRegionTableRef)) {
        Ember.run.scheduleOnce('afterRender', speedRegionTableRef, 'refreshData'); // refresh data

        Ember.run.next(() => {
          speedRegionTableRef.get('firstRowSelectedTask').perform();
        });
      }

      this.buttonSettings(true, true, true);
      Ember.$('.region-name-input').focus().select();
    },

    speedRegionTableRefChange: Ember.observer('speedRegionTableRef.table.rows.[]', function () {
      const speedRegionTableRef = this.get('speedRegionTableRef');
      const isNewSpeedRegion = this.get('isNewSpeedRegion');

      if (isNewSpeedRegion) {
        speedRegionTableRef.onUncheckAll();
        speedRegionTableRef.get('firstRowSelectedTask').perform();
      }
    }),

    removeSpeedRegion() {
      if (this.get('disableForm')) return;
      const speedRegionTableRef = this.get('speedRegionTableRef');
      const selectedSpeedRegionRow = this.get('selectedSpeedRegionRow');

      if (selectedSpeedRegionRow) {
        selectedSpeedRegionRow.set('selected', false);
        selectedSpeedRegionRow.set('isSoftDeletedRow', true);
        speedRegionTableRef.set('config.selectFirstRow', true);
      }

      this.set('speedRegionGroupChanges', true);

      if (Ember.isPresent(speedRegionTableRef)) {
        Ember.run.scheduleOnce('afterRender', speedRegionTableRef, 'refreshData'); // refresh data
      }
    },

    removeSpeedAddress() {
      if (this.get('disableForm')) return;
      const speedRegionAddressTableRef = this.get('speedRegionAddressTableRef');
      const selectedSpeedRegionAddressRow = this.get('selectedSpeedRegionAddressRow');

      if (selectedSpeedRegionAddressRow) {
        selectedSpeedRegionAddressRow.set('selected', false);
        selectedSpeedRegionAddressRow.set('isSoftDeletedRow', true);
        speedRegionAddressTableRef.set('config.selectFirstRow', true);
      }

      this.set('speedRegionGroupChanges', true);

      if (Ember.isPresent(speedRegionAddressTableRef)) {
        Ember.run.scheduleOnce('afterRender', speedRegionAddressTableRef, 'refreshData'); // refresh data
      }
    },

    speedFactorToPercentage(speedFactor) {
      const speedFactorFloat = parseFloat(speedFactor).toFixed(2);
      return parseInt(Math.round(speedFactorFloat * 100) - 100, 10);
    },

    percentageToSpeedFactor(percentage) {
      const percentageInt = typeof percentage === 'string' && percentage.length === 0 ? 0 : parseFloat(percentage);
      return (percentageInt + 100) / 100;
    },

    convertAllSpeedFactorToPercentage(speedFactorDays) {
      if (Ember.isEmpty(speedFactorDays)) return [];
      return speedFactorDays.map(speedFactorDay => {
        return speedFactorDay.map(speedFactor => {
          return this.speedFactorToPercentage(speedFactor);
        });
      });
    },

    convertAllPercentageToSpeedFactor(percentageDays) {
      if (Ember.isEmpty(percentageDays)) return [];
      return percentageDays.map(percentageDay => {
        return percentageDay.map(percentage => {
          return this.percentageToSpeedFactor(percentage);
        });
      });
    },

    setSpeedRegionDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const originalName = record.get('originalName');
      const fields = {
        originalName: originalName ? originalName : (0, _guid.guid)(),
        name: '',
        polygon: [],
        speedFactor: defaultSpeedFactor,
        isNewRow: false
      };

      if (Ember.isPresent(record)) {
        Object.entries(fields).forEach(key => {
          fields[key[0]] = record.get(key[0]);
        });
      }

      fields.speedFactor = this.convertAllSpeedFactorToPercentage(fields.speedFactor);
      this.setProperties({
        'speedRegion': Ember.Object.create(fields)
      });
    },

    buttonSettings() {
      let newbtn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let editbtn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let deletebtn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      const disableBtns = {
        new: newbtn,
        edit: editbtn,
        delete: deletebtn
      };
      this.set('disableBtns', disableBtns);
    },

    mandatoryValidation() {
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      const typeName = isBlockoutRegionSetType ? 'blockout' : 'speed';
      const speedRegionsModels = this.get('store').peekAll('speed-region') || [];
      const speedRegionGroupModels = this.get('store').peekAll('speed-region-group').filter(data => {
        if (isBlockoutRegionSetType) {
          return data.get('isBlockoutRegionType');
        }

        return !data.get('isBlockoutRegionType');
      }) || [];
      const speedRegionGroup = this.get('speedRegionGroup');
      const name = speedRegionGroup.get('name') || '';
      const startDate = (0, _moment.default)(speedRegionGroup.get('start'));
      const endDate = (0, _moment.default)(speedRegionGroup.get('end'));
      const startTime = (0, _moment.default)(speedRegionGroup.get('startTime'), 'HH:mm');
      const endTime = (0, _moment.default)(speedRegionGroup.get('endTime'), 'HH:mm');
      const newSpeedRegions = speedRegionsModels.filterBy('isNewRow', true);
      const oldSpeedRegions = speedRegionsModels.filterBy('isNewRow', false);
      const errors = [];
      let speedRegionCount = 0;
      let speedRegionNameCount = 0;
      let speedRegionGroupNameCount = 0;
      let speedFactorOutOfRangeCount = 0;
      let mainMessage = `${_lodash.default.capitalize(typeName)} region set cannot be saved.`;
      this.setProperties({
        'mandatoryError': false,
        'dateRangeError': false,
        'speedRegionGroupNameDupError': false
      });

      if (isBlockoutRegionSetType && speedRegionGroup.get('recurrence') === _speedRegionGroup.CONTINUOUS_RECUR_TYPE) {
        startDate.set('hour', startTime.get('hour'));
        startDate.set('minute', startTime.get('minute'));
        endDate.set('hour', endTime.get('hour'));
        endDate.set('minute', endTime.get('minute'));
      }

      const overlapDateSR = speedRegionGroupModels.find(speedRegionGroupModel => {
        const currStartDate = (0, _moment.default)(speedRegionGroupModel.get('start'));
        const currEndDate = (0, _moment.default)(speedRegionGroupModel.get('end'));
        if (speedRegionGroupModel.get('id') === speedRegionGroup.get('id')) return false;

        if (startDate.isSameOrAfter(currStartDate) && startDate.isSameOrBefore(currEndDate) || endDate.isSameOrAfter(currStartDate) && endDate.isSameOrBefore(currEndDate) || currStartDate.isSameOrAfter(startDate) && currStartDate.isSameOrBefore(endDate) || currEndDate.isSameOrAfter(startDate) && currEndDate.isSameOrBefore(endDate)) {
          return true;
        }

        return false;
      });
      speedRegionGroupModels.forEach(speedRegionGroupModel => {
        if (speedRegionGroupModel.get('name').trim().toUpperCase() === name.trim().toUpperCase() && speedRegionGroupModel.get('id') !== speedRegionGroup.get('id')) speedRegionGroupNameCount++;
      });
      speedRegionsModels.forEach(speedRegionsModel => {
        const speedFactor = speedRegionsModel.get('speedFactor');
        const minPercentage = (MIN_SPEED_PERCENTAGE + 100) / 100;
        const maxPercentage = (MAX_SPEED_PERCENTAGE + 100) / 100;

        if (!isBlockoutRegionSetType) {
          for (let i = 0; i < 7; i++) {
            for (let j = 0; j < 48; j++) {
              if (speedFactor[i][j] < minPercentage || speedFactor[i][j] > maxPercentage) speedFactorOutOfRangeCount++;
            }
          }
        }

        if (!speedRegionsModel.get('isSoftDeletedRow')) speedRegionCount++;
        if (newSpeedRegions.find(record => record.get('name').trim().toUpperCase() === speedRegionsModel.get('name').trim().toUpperCase() && speedRegionsModel.get('originalName') !== record.get('originalName'))) speedRegionNameCount++;
        if (oldSpeedRegions.find(record => record.get('name').trim().toUpperCase() === speedRegionsModel.get('name').trim().toUpperCase() && speedRegionsModel.get('originalName') !== record.get('originalName'))) speedRegionNameCount++;
      });
      if (Ember.isEmpty(name.trim())) errors.pushObject({
        validation: `${_lodash.default.capitalize(typeName)} region set name is required.`
      });
      if (Ember.isPresent(speedRegionsModels.find(record => Ember.isEmpty(record.get('name')) && !record.get('isSoftDeletedRow')))) errors.pushObject({
        validation: `One or more ${typeName} region names are blank.`
      });
      if (!startDate.isValid()) errors.pushObject({
        validation: 'Start date is required.'
      });
      if (!endDate.isValid()) errors.pushObject({
        validation: 'End date is required.'
      });

      if (isBlockoutRegionSetType) {
        if (!startTime.isValid()) errors.pushObject({
          validation: 'Start time is required.'
        });
        if (!endTime.isValid()) errors.pushObject({
          validation: 'End time is required.'
        });
      }

      if (speedRegionCount === 0) errors.pushObject({
        validation: `Must have at least 1 ${typeName} region.`
      });

      if (speedRegionNameCount > 0) {
        errors.pushObject({
          validation: `Its name duplicates an existing ${typeName} region's name.`
        });
        mainMessage = `${_lodash.default.capitalize(typeName)} region cannot be saved.`;
      }

      if (speedRegionGroupNameCount > 0) {
        errors.pushObject({
          validation: `Its name duplicates an existing ${typeName} region set's name.`
        });
        this.set('speedRegionGroupNameDupError', true);
      }

      if (speedFactorOutOfRangeCount > 0) {
        errors.pushObject({
          validation: `${_lodash.default.capitalize(typeName)} percentage must be between ${MIN_SPEED_PERCENTAGE} and ${MAX_SPEED_PERCENTAGE}`
        });
      }

      if (startDate.isSameOrAfter(endDate)) {
        errors.pushObject({
          validation: 'End date must be greater than start date.'
        });
        this.set('dateRangeError', true);
      }

      if (isBlockoutRegionSetType && speedRegionGroup.get('recurrence') === _speedRegionGroup.DAILY_RECUR_TYPE) {
        if (startTime.isSameOrAfter(endTime)) {
          errors.pushObject({
            validation: 'End time must be greater than start time for daily recurrence.'
          });
          this.set('dateRangeError', true);
        }
      }

      if (!Ember.isEmpty(overlapDateSR)) {
        errors.pushObject({
          validation: `Its timeframe overlaps an existing ${typeName} region set's timeframe.`
        });
        this.set('dateRangeError', true);
      }

      if (!errors.length) return true;
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: `${_lodash.default.capitalize(typeName)} Regions`,
        hasoverlay: true,
        errors,
        errorMainMessage: mainMessage,
        primaryActionText: 'OK',
        primaryAction: () => {
          this.set('mandatoryError', true);
          tooltip.reset();
        }
      });
      return false;
    },

    save: (0, _emberConcurrency.task)(function* () {
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      const typeName = isBlockoutRegionSetType ? 'blockout' : 'speed';
      const record = this.buildSpeedRegionGroupData();
      const notifications = this.get('notifications');
      let isSaveSuccessful = true;

      try {
        return yield record.save();
      } catch (error) {
        isSaveSuccessful = false;
        this.set('speedRegionGroupChanges', true); // eslint-disable-next-line no-console

        console.error(error);
        notifications.warning(`${typeName} REGION SET FAILED TO SAVED`);
      } finally {
        if (isSaveSuccessful) {
          const tableRef = this.get('tableRef');

          if (tableRef) {
            Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data

            tableRef.set('config.selectFirstRow', true);
            tableRef.get('firstRowSelectedTask').perform();
          }

          this.buttonSettings();
          this.setProperties({
            'disableForm': true,
            'isNewSpeedRegion': false,
            'speedRegionGroupChanges': false,
            'isNewSpeedRegionGroup': false
          });
        } else {
          throw new Error(`${typeName} REGION SET FAILED TO SAVED`); // eslint-disable-line
        }
      }
    }).drop(),

    buildSpeedRegionGroupData() {
      let record = null;
      const store = this.get('store');
      const value = this.get('speedRegionGroup');
      const displayName = value.get('name');
      const name = value.get('id');
      const isNewSpeedRegionGroup = this.get('isNewSpeedRegionGroup');
      const prefix = this.get('isBlockoutRegionSetType') ? 'BLOCK_' : 'SPEED_'; // remove delete regions from value

      if (Ember.isPresent(value)) {
        const speedRegions = store.peekAll('speed-region') || [];
        speedRegions.forEach(speedRegion => {
          if (speedRegion.get('isSoftDeletedRow')) {
            const removeIndex = value.regions.findIndex(data => data.originalName === speedRegion.originalName);

            if (removeIndex > -1) {
              value.regions.splice(removeIndex, 1);
            }
          }
        });
      }

      if (isNewSpeedRegionGroup) {
        const data = {
          category: 'config-System_Configuration-speed_region_groups',
          type: 'object',
          displayName,
          name: prefix + Ember.String.camelize(displayName),
          value
        };
        record = store.createRecord('cs-config-item', data);
      } else {
        const id = `config-System_Configuration-speed_region_groups/${name}`;
        record = store.peekRecord('cs-config-item', id);
        record.set('value', value);
      }

      return record;
    },

    delete: (0, _emberConcurrency.task)(function* () {
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      const typeName = isBlockoutRegionSetType ? 'blockout' : 'speed';
      const record = this.get('selectedRow');
      const notifications = this.get('notifications');
      const tableRef = this.get('tableRef');
      const store = this.get('store');
      const value = this.get('speedRegionGroup');
      const name = value.get('id');
      const checkedRow = tableRef.get('checkedRows.firstObject');
      const id = `config-System_Configuration-speed_region_groups/${name}`;
      const configRecord = store.peekRecord('cs-config-item', id);

      try {
        if (checkedRow.isDeleted) {
          yield configRecord.destroyRecord();
        } else {
          yield record.destroyRecord();
        } // completely remove id from store so it can be reused


        store._removeFromIdMap(configRecord._internalModel);
      } catch (error) {
        console.error(error); // eslint-disable-line

        notifications.warning(`${typeName} REGION SET FAILED TO DELETE`);
      } finally {
        if (tableRef) {
          Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data
        }
      }
    }).drop(),

    /**
     * First, validate the mandatory fields.
     * @returns {boolean}
     */
    async validateSpeedRegionGroup() {
      const valid = this.mandatoryValidation();
      return valid;
    },

    updateSpeedRegionGroup() {
      const speedRegionGroup = this.get('speedRegionGroup');
      const speedRegionModels = this.get('store').peekAll('speed-region');
      const speedRegionAddressModels = this.get('store').peekAll('speed-region-address');
      const addressesArray = [];
      const regionsArray = [];
      speedRegionAddressModels.map(addressModel => {
        if (!addressModel.get('isSoftDeletedRow')) {
          addressesArray.push({
            streetNumber: addressModel.get('streetNumber'),
            streetAddress: addressModel.get('streetAddress'),
            locality: addressModel.get('locality'),
            region: addressModel.get('region'),
            postalCode: addressModel.get('postalCode'),
            country: addressModel.get('country'),
            subLocality: addressModel.get('subLocality'),
            lat: addressModel.get('lat'),
            lng: addressModel.get('lng')
          });
        }
      });
      speedRegionGroup.set('addresses', addressesArray);
      this.updateCurrentSpeedRegionGroupModel('addresses');
      speedRegionModels.forEach(speedRegionModel => {
        if (!speedRegionModel.get('isSoftDeletedRow')) {
          regionsArray.push({
            originalName: speedRegionModel.get('originalName'),
            name: speedRegionModel.get('name'),
            blockout: speedRegionModel.get('blockout'),
            polygon: speedRegionModel.get('polygon'),
            speedFactor: speedRegionModel.get('speedFactor')
          });
        }
      });
      speedRegionGroup.set('regions', regionsArray);
      this.updateCurrentSpeedRegionGroupModel('regions');
    },

    onSaveSpeedRegionGroup() {
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      const typeName = isBlockoutRegionSetType ? 'blockout' : 'speed';
      const notifications = this.get('notifications');
      const speedRegionGroupName = this.get('speedRegionGroup.name');
      const isNewSpeedRegionGroup = this.get('isNewSpeedRegionGroup');
      const activity = isNewSpeedRegionGroup ? `Create ${_lodash.default.capitalize(typeName)} Region` : `Edit ${_lodash.default.capitalize(typeName)} Region`;
      const details = isNewSpeedRegionGroup ? `Created ${typeName} region set ${speedRegionGroupName}.` : `Edited ${typeName} region set ${speedRegionGroupName}.`;
      this.updateSpeedRegionGroup();
      this.validateSpeedRegionGroup().then(isValid => {
        if (isValid) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'System Configuration',
            hasOverlay: true,
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              this.set('speedRegionGroupChanges', false);
              tooltip.reset();
              return this.get('save').perform().then(async () => {
                await this.createSpeedRegionGroupActivityLog(activity, details);
                notifications.success(`${typeName} REGION SET SUCCESSFULLY SAVED`);
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }
      });
    },

    undoSpeedRegionGroup() {
      const selectedRow = this.get('selectedRow');
      const selectedSpeedRegionRow = this.get('selectedSpeedRegionRow');
      const isNewSpeedRegionGroup = this.get('isNewSpeedRegionGroup');
      const isNewSpeedRegion = this.get('isNewSpeedRegion');

      if (isNewSpeedRegionGroup) {
        this.cleanupPlaceModel();
        this.cleanupSpeedRegionModel();
        this.cleanupSpeedRegionAddressModel();
        this.setDefaultProperties();
        this.setSpeedRegionDefaultProperties();
      }

      if (Ember.isPresent(selectedRow)) {
        selectedRow.rollbackAttributes();
        this.cleanupPlaceModel();
        this.cleanupSpeedRegionModel();
        this.cleanupSpeedRegionAddressModel();

        if (Ember.isPresent(selectedRow)) {
          this.createSpeedRegionRecords(selectedRow.get('regions'));
          this.createSpeedAddressRecords(selectedRow.get('addresses'));
        }

        this.setDefaultProperties(selectedRow, true);
      } else {
        this.setDefaultProperties(selectedRow, true);
      }

      if (isNewSpeedRegion && Ember.isPresent(selectedSpeedRegionRow) && !selectedSpeedRegionRow.isNewRow) {
        this.setSpeedRegionDefaultProperties(selectedSpeedRegionRow);
      }
    },

    updateCurrentSpeedRegionModel(propertyName) {
      const speedRegion = this.get('speedRegion');
      const speedRegionModel = this.get('store').peekAll('speed-region').find(speedRegionRec => speedRegionRec.get('originalName') === speedRegion.get('originalName'));

      if (propertyName === 'speedFactor') {
        speedRegionModel.set(propertyName, this.convertAllPercentageToSpeedFactor(speedRegion.get(propertyName)));
      } else if (propertyName === 'name') {
        const newName = speedRegion.get(propertyName).trimStart();
        speedRegion.set(propertyName, newName);
        speedRegionModel.set(propertyName, newName.trimEnd());
      } else {
        speedRegionModel.set(propertyName, speedRegion.get(propertyName));
      }

      this.updateSpeedRegionGroup();
    },

    updateCurrentSpeedRegionGroupModel(propertyName) {
      const speedRegionGroup = this.get('speedRegionGroup');
      const id = speedRegionGroup.get('id');
      if (Ember.isEmpty(id)) return;
      const speedRegionGroupModel = this.get('store').peekRecord('speed-region-group', id);
      speedRegionGroupModel.set(propertyName, speedRegionGroup.get(propertyName));
    },

    validateSpeedFactor(dayOfWeek, timeInterval, value) {
      const isNewSpeedRegionGroup = this.get('isNewSpeedRegionGroup');
      const speedRegion = this.get('speedRegion');
      const speedRegionModel = this.get('store').peekAll('speed-region').find(speedRegionRec => speedRegionRec.get('originalName') === speedRegion.get('originalName'));
      const currSpeedFactor = isNewSpeedRegionGroup ? null : speedRegionModel.get('speedFactor');
      const newSpeedFactor = speedRegion.get('speedFactor');
      const newValue = parseInt(value, 10);

      if (newValue < MIN_SPEED_PERCENTAGE || newValue > MAX_SPEED_PERCENTAGE) {
        if (Ember.isPresent(currSpeedFactor)) {
          newSpeedFactor[dayOfWeek].set(timeInterval, this.speedFactorToPercentage(currSpeedFactor[dayOfWeek][timeInterval]));
        } else {
          newSpeedFactor[dayOfWeek].set(timeInterval, this.speedFactorToPercentage(defaultSpeedFactor[dayOfWeek][timeInterval]));
        }

        return false;
      }

      return true;
    },

    validateLocationInBlockoutRegion(place) {
      const location = Ember.isPresent(place) ? place.get('location') : null;
      return this.isLocationInsidePolygons(location);
    },

    isLocationInsidePolygons(location) {
      const speedRegionModels = this.get('store').peekAll('speed-region');

      const point = _turfHelpers.default.point([location.get('lng'), location.get('lat')]);

      let isInsidePolygons = false;
      speedRegionModels.forEach(speedRegionModel => {
        if (speedRegionModel.polygon.length <= 0) return;
        const lnglat = speedRegionModel.polygon.map(latlng => {
          return [latlng[1], latlng[0]];
        });

        const polygon = _turfHelpers.default.polygon([lnglat]);

        if ((0, _booleanPointInPolygon.default)(point, polygon)) {
          isInsidePolygons = true;
        }
      });
      return isInsidePolygons;
    },

    validateAddress(place) {
      const address = Ember.isPresent(place) ? place.get('address') : null;
      const location = Ember.isPresent(place) ? place.get('location') : null;
      const speedAddressModels = this.get('store').peekAll('speed-region-address').filter(currAddress => !currAddress.isSoftDeletedRow);
      let valid = true;
      if (Ember.isEmpty(address.get('streetAddress')) || Ember.isEmpty(address.get('locality')) || Ember.isEmpty(location.get('lat'))) return false;
      if (speedAddressModels.length >= MAX_ADDRESS) return false;

      if (Ember.isPresent(address)) {
        const result = speedAddressModels.find(speedAddressModel => {
          if (speedAddressModel.get('streetNumber') === address.get('streetNumber') && speedAddressModel.get('streetAddress') === address.get('streetAddress') && speedAddressModel.get('locality') === address.get('locality') && speedAddressModel.get('region') === address.get('region')) {
            return true;
          }

          return false;
        });

        if (Ember.isPresent(result)) {
          this.showErrorPopupMessage('This address is already on the list.');
          valid = false;
        }
      }

      if (valid && Ember.isPresent(location)) {
        valid = !this.isLocationInsidePolygons(location);

        if (!valid) {
          this.showErrorPopupMessage('Address cannot be inside a blocked polygon.');
        }
      }

      return valid;
    },

    showErrorPopupMessage(message) {
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      const typeName = isBlockoutRegionSetType ? 'blockout' : 'speed';
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: `${_lodash.default.capitalize(typeName)} Regions`,
        hasoverlay: true,
        errors: [{
          validation: message
        }],
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        }
      });
    },

    addNewAddress(place) {
      const speedRegionAddressTableRef = this.get('speedRegionAddressTableRef');
      const address = place.get('address');
      const location = place.get('location');
      const addressObject = {
        streetNumber: address.get('streetNumber'),
        streetAddress: address.get('streetAddress'),
        locality: address.get('locality'),
        subLocality: address.get('subLocality'),
        region: address.get('region'),
        postalCode: address.get('postalCode'),
        country: address.get('country'),
        lat: location.get('lat'),
        lng: location.get('lng')
      };
      this.set('speedRegionGroupChanges', true);
      this.createSingleAddressRecord(addressObject);

      if (Ember.isPresent(speedRegionAddressTableRef)) {
        Ember.run.scheduleOnce('afterRender', speedRegionAddressTableRef, 'refreshData'); // refresh data
      }
    },

    //NYAAR-19947,NYAAR-19948-for creating activity log ,while creating, editing and deleting a speed and blockout region group
    async createSpeedRegionGroupActivityLog(activity, details) {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const eventLog = {
        type: 'event',
        attributes: {
          source: 'webApp',
          eventType: 'UE',
          actionType: 'genericLogging',
          userId: this.get('session.data.authenticated.userId'),
          receivedAt: (0, _moment.default)().toDate(),
          eventLog: {
            genericLogging: [{
              activity: activity,
              loggingType: 'user',
              details: details
            }]
          }
        }
      };
      await activityLogAdapter.createEventActivity(eventLog);
    },

    actions: {
      onAddAddress(place) {
        if (!this.validateAddress(place)) return false;
        this.addNewAddress(place);
      },

      onCellValueChange(record, value, options) {
        if (options.valuePath === 'location.geoNode' && Ember.isEmpty(value)) {
          this.cleanupPlaceModel();
          return;
        }

        record.set(`${options.valuePath}`, value);

        if (options.valuePath === 'location.lng' && Ember.isPresent(value) && this.isLocationInsidePolygons(record.get('location'))) {
          this.cleanupPlaceModel();
          return;
        }
      },

      validateLocationInBlockoutRegion(position) {
        const location = Ember.Object.create({
          lat: position.lat,
          lng: position.lon
        });
        return this.isLocationInsidePolygons(location);
      },

      onSpeedFactorChange(dayOfWeek, timeInterval, value) {
        if (!this.validateSpeedFactor(dayOfWeek, timeInterval, value)) return false;
        this.updateCurrentSpeedRegionModel('speedFactor');
        this.set('speedRegionGroupChanges', true);
      },

      onSpeedRegionNameChanges() {
        this.updateCurrentSpeedRegionModel('name');
        this.set('speedRegionGroupChanges', true);
      },

      onChangeDateTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set('speedRegionGroupChanges', true);
        this.set(valuePath, momentTime.toDate());
      },

      onChangeDate(valuePath, value) {
        const momentDate = (0, _moment.default)(value[0]);
        this.set('speedRegionGroupChanges', true);

        if (valuePath === 'speedRegionGroup.end') {
          momentDate.endOf('day');
        }

        this.set(valuePath, momentDate.toDate());
      },

      onChangeTime(valuePath, value) {
        const momentDate = (0, _moment.default)(value[0]);
        this.set('speedRegionGroupChanges', true);
        this.set(valuePath, momentDate.format('HH:mm'));
      },

      toggleRecurrence(value) {
        this.set('speedRegionGroupChanges', true);
        this.set('speedRegionGroup.recurrence', value);
      },

      onSpeedRegionGroupFormChanges() {
        this.set('speedRegionGroupChanges', true);
      },

      newRegionGroup() {
        const selectedRow = this.get('selectedRow');
        const selectedSpeedRegionRow = this.get('selectedSpeedRegionRow');
        const speedRegionTableRef = this.get('speedRegionTableRef');
        const speedRegionAddressTableRef = this.get('speedRegionAddressTableRef');

        if (selectedRow) {
          selectedRow.set('selected', false);
          this.set('tableRef.config.selectFirstRow', false);
        }

        if (selectedSpeedRegionRow) {
          selectedSpeedRegionRow.set('selected', false);
        }

        this.setDefaultProperties();
        this.setSpeedRegionDefaultProperties();
        this.setProperties({
          isNewSpeedRegionGroup: true,
          disableForm: false,
          speedRegionGroupChanges: false
        });
        this.get('tableRef').onUncheckAll();
        speedRegionTableRef.onUncheckAll();

        if (Ember.isPresent(speedRegionTableRef)) {
          Ember.run.scheduleOnce('afterRender', speedRegionTableRef, 'refreshData'); // refresh data
        }

        if (Ember.isPresent(speedRegionAddressTableRef)) {
          Ember.run.scheduleOnce('afterRender', speedRegionAddressTableRef, 'refreshData'); // refresh data
        }

        this.buttonSettings(true, true, true);
      },

      editRegionGroup() {
        const selectedRow = this.get('selectedRow');
        this.setDefaultProperties(selectedRow);
        this.setProperties({
          isNewSpeedRegionGroup: false,
          disableForm: false
        });
        this.buttonSettings(true, true, true);
      },

      async deleteRegionGroup() {
        const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
        const typeName = isBlockoutRegionSetType ? 'blockout' : 'speed';
        const notifications = this.get('notifications');
        const speedRegionName = this.get('speedRegionGroup.name');
        const activity = `Delete ${_lodash.default.capitalize(typeName)} Region`;
        const details = `Deleted ${typeName} region set ${speedRegionName}.`;
        this.set('isDeleteSpeedRegionGroup', true);
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: ' Blockout Regions',
          hasoverlay: true,
          tip: `Are you sure you want to delete the ${typeName} region set? Once deleted, it cannot be recovered.`,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.cleanupPlaceModel();
            this.cleanupSpeedRegionModel();
            this.cleanupSpeedRegionAddressModel();
            return this.get('delete').perform().then(async () => {
              await this.createSpeedRegionGroupActivityLog(activity, details);
              notifications.success(`${typeName} REGION SET SUCCESSFULLY DELETED`);
              tooltip.reset();
              this.set('isDeleteSpeedRegionGroup', false);
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
      },

      async saveRegionGroup() {
        this.onSaveSpeedRegionGroup();
      },

      undoRegionGroup() {
        this.undoSpeedRegionGroup();
      },

      removeSpeedRegion() {
        this.removeSpeedRegion();
      },

      removeSpeedAddress() {
        this.removeSpeedAddress();
      }

    }
  });

  _exports.default = _default;
});