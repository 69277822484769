define("adept-iq/utils/filter-functions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringStartsWithIFn = _exports.stringNeIFn = _exports.stringIncludesIFn = _exports.stringEqIFn = _exports.strictlyNotBetweenFn = _exports.strictlyBetweenFn = _exports.startsWithFn = _exports.nullFn = _exports.neFn = _exports.lteFn = _exports.ltFn = _exports.includesFn = _exports.gteFn = _exports.gtFn = _exports.eqFn = _exports.enumStNtEqIFn = _exports.enumStEqIFn = _exports.enumIncludesIFn = _exports.emptyFn = _exports.dateStrictlyBetweenFn = _exports.dateStrNeFn = _exports.dateNotBetweenFn = _exports.dateLteFn = _exports.dateIncludesLteFn = _exports.dateIncludesIFn = _exports.dateIncludesGteFn = _exports.dateGtFn = _exports.dateBetweenFn = _exports.dateAndTimeStrEqFn = _exports.dateAndTimeNeFn = _exports.dateAndTimeLteFn = _exports.dateAndTimeLtFn = _exports.dateAndTimeGteFn = _exports.dateAndTimeGtFn = _exports.dateAndTimeEqFn = _exports.betweenFn = void 0;

  const nullFn = () => true;

  _exports.nullFn = nullFn;
  const emptyFn = Ember.isBlank;
  _exports.emptyFn = emptyFn;

  const eqFn = (a, _ref) => {
    let [b] = _ref;
    return a === b;
  };

  _exports.eqFn = eqFn;

  const neFn = (a, _ref2) => {
    let [b] = _ref2;
    return a !== b;
  };

  _exports.neFn = neFn;

  const includesFn = (a, _ref3) => {
    let [b] = _ref3;

    if (!a || !a.includes || !b) {
      return false;
    }

    return a.includes(b);
  };

  _exports.includesFn = includesFn;

  const startsWithFn = (a, _ref4) => {
    let [b] = _ref4;

    if (!a || !a.startsWith || !b) {
      return false;
    }

    return a.startsWtih(b);
  };

  _exports.startsWithFn = startsWithFn;

  const ltFn = (a, _ref5) => {
    let [b] = _ref5;
    return a < b;
  };

  _exports.ltFn = ltFn;

  const lteFn = (a, _ref6) => {
    let [b] = _ref6;
    return a <= b;
  };

  _exports.lteFn = lteFn;

  const gtFn = (a, _ref7) => {
    let [b] = _ref7;
    return a > b;
  };

  _exports.gtFn = gtFn;

  const gteFn = (a, _ref8) => {
    let [b] = _ref8;
    return a >= b;
  };

  _exports.gteFn = gteFn;

  const betweenFn = (a, _ref9) => {
    let [b, c] = _ref9;
    return b <= a && a <= c;
  };

  _exports.betweenFn = betweenFn;

  const strictlyBetweenFn = (a, _ref10) => {
    let [b, c] = _ref10;
    return b < a && a < c;
  };

  _exports.strictlyBetweenFn = strictlyBetweenFn;

  const strictlyNotBetweenFn = (a, _ref11) => {
    let [b, c] = _ref11;
    return b > a || a > c;
  };

  _exports.strictlyNotBetweenFn = strictlyNotBetweenFn;

  function isValidDate(d1, d2) {
    const momentA = (0, _moment.default)(d1);
    const momentB = (0, _moment.default)(d2);
    if (!momentA.isValid() && !momentB.isValid()) return false;
    return true;
  }

  function toDate(a, b) {
    const dateA = Ember.typeOf(a) === 'date' ? a : new Date(a);
    const dateB = Ember.typeOf(b) === 'date' ? b : new Date(b);
    return {
      dateA,
      dateB
    };
  }

  const isCorrectDateFormat = (dateString, format) => {
    return (0, _moment.default)(dateString, format, true).isValid();
  };

  const dateAndTimeEqFn = (a, _ref12) => {
    let [b] = _ref12;
    if (!isValidDate(a, b)) return false;
    const {
      dateA,
      dateB
    } = toDate(a, b);
    return dateA && dateB && dateA.getTime() === dateB.getTime();
  };

  _exports.dateAndTimeEqFn = dateAndTimeEqFn;

  const dateAndTimeStrEqFn = (a, _ref13, format) => {
    let [b] = _ref13;

    if (Ember.isBlank(a) || Ember.isBlank(b)) {
      return false;
    }

    if (Ember.typeOf(a) !== 'date' && !(0, _moment.default)(a).isValid()) {
      return false;
    }

    const aStr = (0, _moment.default)(a).format(format);

    if (!isCorrectDateFormat(b, format)) {
      // eslint-disable-next-line no-use-before-define
      return stringIncludesIFn(aStr, [b]);
    }

    const bStr = (0, _moment.default)(b, format).format(format); // handle any date inputs

    return includesFn(aStr, [bStr]);
  };

  _exports.dateAndTimeStrEqFn = dateAndTimeStrEqFn;

  const dateAndTimeNeFn = (a, _ref14) => {
    let [b] = _ref14;
    if (!isValidDate(a, b)) return false;
    const {
      dateA,
      dateB
    } = toDate(a, b);
    return dateA && dateB && dateA.getTime() !== dateB.getTime();
  };

  _exports.dateAndTimeNeFn = dateAndTimeNeFn;

  const dateStrNeFn = (a, _ref15, format) => {
    let [b] = _ref15;

    if (Ember.isBlank(a) || Ember.isBlank(b)) {
      return false;
    }

    if (Ember.typeOf(a) !== 'date') {
      return false;
    }

    const aStr = (0, _moment.default)(a).format(format);

    if (!isCorrectDateFormat(b, format)) {
      return aStr !== b;
    }

    const bStr = (0, _moment.default)(b).format(format); // handle any date inputs

    return aStr !== bStr;
  };

  _exports.dateStrNeFn = dateStrNeFn;

  const dateAndTimeLtFn = (a, _ref16) => {
    let [b] = _ref16;
    if (!isValidDate(a, b)) return false;
    const {
      dateA,
      dateB
    } = toDate(a, b);
    return dateA && dateB && dateA.getTime() < dateB.getTime();
  };

  _exports.dateAndTimeLtFn = dateAndTimeLtFn;

  const dateLteFn = (a, _ref17) => {
    let [b] = _ref17;
    if (!isValidDate(a, b)) return false;
    const {
      dateA,
      dateB
    } = toDate(a, b);
    return dateA && dateB && dateA.getTime() <= dateB.getTime();
  };

  _exports.dateLteFn = dateLteFn;

  const dateGtFn = (a, _ref18) => {
    let [b] = _ref18;
    if (!isValidDate(a, b)) return false;
    const {
      dateA,
      dateB
    } = toDate(a, b);
    return dateA && dateB && dateA.getTime() > dateB.getTime();
  };

  _exports.dateGtFn = dateGtFn;

  const dateAndTimeLteFn = (a, _ref19) => {
    let [b] = _ref19;
    if (!isValidDate(a, b)) return false;
    const {
      dateA,
      dateB
    } = toDate(a, b);
    return dateA && dateB && dateA.getTime() <= dateB.getTime();
  };

  _exports.dateAndTimeLteFn = dateAndTimeLteFn;

  const dateAndTimeGtFn = (a, _ref20) => {
    let [b] = _ref20;
    if (!isValidDate(a, b)) return false;
    const {
      dateA,
      dateB
    } = toDate(a, b);
    return dateA && dateB && dateA.getTime() > dateB.getTime();
  };

  _exports.dateAndTimeGtFn = dateAndTimeGtFn;

  const dateAndTimeGteFn = (a, _ref21) => {
    let [b] = _ref21;
    if (!isValidDate(a, b)) return false;
    const {
      dateA,
      dateB
    } = toDate(a, b);
    return dateA && dateB && dateA.getTime() >= dateB.getTime();
  };

  _exports.dateAndTimeGteFn = dateAndTimeGteFn;

  const dateBetweenFn = (t, _ref22) => {
    let [t0, t1] = _ref22;
    return dateAndTimeGteFn(t, [t0]) && dateAndTimeLteFn(t, [t1]);
  };

  _exports.dateBetweenFn = dateBetweenFn;

  const dateStrictlyBetweenFn = (t, _ref23) => {
    let [t0, t1] = _ref23;
    return dateAndTimeGtFn(t, [t0]) && dateAndTimeLtFn(t, [t1]);
  };

  _exports.dateStrictlyBetweenFn = dateStrictlyBetweenFn;

  const dateNotBetweenFn = (t, _ref24) => {
    let [t0, t1] = _ref24;
    return dateAndTimeLtFn(t, [t0]) || dateAndTimeGtFn(t, [t1]);
  }; // case-insensitive string functions


  _exports.dateNotBetweenFn = dateNotBetweenFn;

  const stringEqIFn = (a, _ref25) => {
    let [b] = _ref25;

    if (Ember.typeOf(a) !== 'string' || Ember.typeOf(b) !== 'string') {
      return false;
    }

    return eqFn(a.toLowerCase(), [b.toLowerCase()]);
  };

  _exports.stringEqIFn = stringEqIFn;

  const stringNeIFn = (a, _ref26) => {
    let [b] = _ref26;

    if (Ember.typeOf(a) !== 'string' || Ember.typeOf(b) !== 'string') {
      return false;
    }

    return neFn(a.toLowerCase(), [b.toLowerCase()]);
  };

  _exports.stringNeIFn = stringNeIFn;

  const stringIncludesIFn = (a, _ref27) => {
    let [b] = _ref27;

    if (Ember.typeOf(a) !== 'string' || Ember.typeOf(b) !== 'string') {
      return false;
    }

    return includesFn(a.toLowerCase(), [b.toLowerCase()]);
  };

  _exports.stringIncludesIFn = stringIncludesIFn;

  const enumIncludesIFn = (a, _ref28) => {
    let [b] = _ref28;

    if (typeof a !== 'object' || typeof b !== 'string') {
      return false;
    }

    return a.values.some(obj => includesFn(obj.value.toLowerCase(), [b.toLowerCase()]));
  };

  _exports.enumIncludesIFn = enumIncludesIFn;

  const stringStartsWithIFn = (a, _ref29) => {
    let [b] = _ref29;

    if (Ember.typeOf(a) !== 'string' || Ember.typeOf(b) !== 'string') {
      return false;
    }

    return startsWithFn(a.toLowerCase(), [b.toLowerCase()]);
  };

  _exports.stringStartsWithIFn = stringStartsWithIFn;

  const enumStEqIFn = (a, _ref30) => {
    let [b] = _ref30;

    if (typeof a !== 'object' || typeof b !== 'string') {
      return false;
    }

    return a.values.every(obj => eqFn(obj.value.toLowerCase(), [b.toLowerCase()]));
  };

  _exports.enumStEqIFn = enumStEqIFn;

  const enumStNtEqIFn = (a, _ref31) => {
    let [b] = _ref31;

    if (typeof a !== 'object' || typeof b !== 'string') {
      return false;
    }

    return a.values.every(obj => neFn(obj.value.toLowerCase(), [b.toLowerCase()]));
  };

  _exports.enumStNtEqIFn = enumStNtEqIFn;

  const dateIncludesIFn = (a, _ref32, format) => {
    let [b] = _ref32;

    if (typeof a !== 'object' || typeof b !== 'string') {
      return false;
    }

    const header = a.header || '';
    return a.values.some(obj => dateAndTimeStrEqFn(obj.value, [b], format)) || dateAndTimeStrEqFn(header, [b], format);
  };

  _exports.dateIncludesIFn = dateIncludesIFn;

  const dateIncludesGteFn = (a, _ref33) => {
    let [b] = _ref33;

    if (typeof a !== 'object' || typeof b !== 'string') {
      return false;
    }

    const header = a.header || '';
    return a.values.some(obj => dateAndTimeGteFn(obj.value, [b])) || dateAndTimeGteFn(header, [b]);
  };

  _exports.dateIncludesGteFn = dateIncludesGteFn;

  const dateIncludesLteFn = (a, _ref34) => {
    let [b] = _ref34;

    if (typeof a !== 'object' || typeof b !== 'string') {
      return false;
    }

    const header = a.header || '';
    return a.values.some(obj => dateAndTimeLteFn(obj.value, [b])) || dateAndTimeLteFn(header, [b]);
  };

  _exports.dateIncludesLteFn = dateIncludesLteFn;
});