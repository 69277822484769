define("adept-iq/pods/components/side-drawer/scheduling-bulk-trip-transfer/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.solutionImpactColumns = _exports.solutionColumns = void 0;
  const solutionImpactColumns = [{
    label: 'Trip ID',
    valuePath: 'id',
    cellComponent: 'table/cells/base-cell',
    resizable: true,
    minResizeWidth: 50,
    width: '80px'
  }, {
    label: 'Last Name',
    valuePath: 'lastName',
    cellComponent: 'table/cells/base-cell',
    resizable: true,
    minResizeWidth: 50,
    width: '80px'
  }, {
    label: 'Address',
    valuePath: 'simpleAddress',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: 50,
    resizable: true,
    width: '80px'
  }, {
    label: 'City',
    valuePath: 'city',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: 50,
    resizable: true,
    width: '60px'
  }, {
    label: 'Stop',
    valuePath: 'stop',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: 50,
    resizable: true,
    width: '60px'
  }, {
    cellClassNames: 'ETA',
    label: 'ETA',
    valuePath: 'plannedEta',
    cellComponent: 'table/cells/trip-eta',
    minResizeWidth: 50,
    resizable: true,
    width: '60px'
  }, {
    label: 'Promise Time',
    valuePath: 'computedPromiseTime',
    cellComponent: 'table/cells/date-cell',
    minResizeWidth: 50,
    resizable: true,
    format: 'HH:mm',
    width: '80px'
  }, {
    label: 'Appt Time',
    valuePath: 'computedApptTime',
    cellComponent: 'table/cells/date-cell',
    resizable: true,
    minResizeWidth: 50,
    format: 'HH:mm',
    width: '80px'
  }, {
    label: 'Stop Break Ord',
    valuePath: 'plannedRouteOrdinal',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: 60,
    resizable: true,
    width: '80px'
  }];
  _exports.solutionImpactColumns = solutionImpactColumns;
  const MIN_COLUMN_WIDTH = 50;
  const solutionColumns = [{
    width: '40px',
    resizable: false,
    cellComponent: 'side-drawer/trip-transfer/radio-button'
  }, {
    label: 'Options',
    valuePath: 'routeName',
    cellComponent: 'side-drawer/trip-transfer/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH
  }, {
    label: 'Provider',
    valuePath: 'providerName',
    cellComponent: 'side-drawer/trip-transfer/base-cell',
    resizable: true,
    minResizeWidth: MIN_COLUMN_WIDTH
  }];
  _exports.solutionColumns = solutionColumns;
});