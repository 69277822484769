define("adept-iq/pods/components/user-profile/api-call-record-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/user-profile/api-call-record-table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['api-call-record-table'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    config: _config.default,
    isModelLoading: true,
    disableRowSelection: true,

    refreshTable() {
      this.refreshData();
    },

    unloadAllRecords(modelName) {
      this.store.peekAll(modelName).forEach(record => record.unloadRecord());
    },

    init() {
      this._super(...arguments);

      const store = this.get('store');
      const modelName = this.get('config.modelName');
      this.refreshTable = this.refreshTable.bind(this);

      if (!this.isTableInitialized) {
        this.unloadAllRecords(modelName);
        store.findAll(modelName).catch(error => {
          // eslint-disable-next-line no-console
          console.error('User Profile failed to load model:', error);
        }).finally(() => {
          this.set('isModelLoading', false);
          this.refreshTable();
        });
      }
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    }

  });

  _exports.default = _default;
});