define("adept-iq/pods/components/rider-management-widget/rider-funding/funding-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'rider-management-widget/rider-suspension/suspension-table',
    rowComponent: 'table/rows/text-formatted-row',
    rowSelectionType: 'radio',
    modelName: 'rider-funding',
    defaultSortAsc: false,
    checkBoxColumn: false,
    isFormControls: true,
    fetchModelData: true,
    title: 'Funding Agencies',
    columns: [{
      id: 'name',
      index: 0,
      type: 'text',
      isKey: true,
      label: 'Name',
      valuePath: 'fundingAgencyDisplayName',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 250
    }, {
      id: 'startDate',
      index: 1,
      type: 'date',
      label: 'Start Date',
      valuePath: 'startDate',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'endDate',
      index: 2,
      type: 'date',
      label: 'End Date',
      valuePath: 'endDate',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      format: 'MM/DD/YYYY',
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'notes',
      index: 3,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 600
    }]
  };
  _exports.default = _default;
});