define("adept-iq/pods/components/iq-widgets/history-routes-widget/config", ["exports", "adept-iq/config/mapped-permIds", "adept-iq/pods/components/reconcile-column-editor/stubs", "moment"], function (_exports, _mappedPermIds, _stubs, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const headerComponent = 'iq-widgets/history-routes-widget/table-header';
  const editableCellComponent = 'iq-widgets/history-routes-widget/cells/editable';
  const cellComponent = 'iq-widgets/history-routes-widget/cells/radio-button';
  const coloredCellComponent = 'iq-widgets/history-routes-widget/cells/base-cell';
  const hoverCellComponent = 'iq-widgets/history-routes-widget/cells/hover-cell';

  const editColumnLabels = _stubs.default.editColumnTableDate.map(column => {
    return column.fieldId;
  });

  var _default = {
    component: 'iq-widgets/history-routes-widget',
    rowComponent: 'table/rows/action-row',
    title: 'Reconcile',
    editColumnLabels,
    defaultSortId: 'eta',
    defaultSortAsc: true,
    contextMenuOptionsList: [{
      id: 'reconcileTripTransferToRoute',
      caption: 'Transfer Trip to Another Route',
      dialogId: _mappedPermIds.default.transferTrip,
      dialogTitle: 'Transfer Trip to Another Route'
    }, {
      id: 'reconcileTripTransferToTaxi',
      caption: 'Transfer Trip to Taxi',
      dialogId: _mappedPermIds.default.transferTrip,
      dialogTitle: 'Transfer Trip to Taxi'
    }, {
      id: 'reconcileCancelTrip',
      caption: 'Cancel Trip',
      dialogId: _mappedPermIds.default.cancelTrip,
      dialogTitle: 'Cancel Trip'
    }, {
      id: 'reconcileNoShowTrip',
      caption: 'No show Trip',
      dialogId: _mappedPermIds.default.noShowTrip,
      dialogTitle: 'No show Trip'
    }, {
      id: 'reconcileUnPerformNoShow',
      caption: 'Unperform No Show Trip',
      dialogId: _mappedPermIds.default.unperformStop,
      dialogTitle: 'Unperform No Show'
    }, {
      id: 'reconcileEditAdditionalTripInfo',
      caption: 'Edit Additional Trip Information',
      dialogId: _mappedPermIds.default.additionalTrip,
      dialogTitle: 'Edit Additional Trip Information'
    }, {
      id: 'reconcileTripLog',
      caption: 'Show Trip Log',
      dialogId: _mappedPermIds.default.activityLogTrip,
      dialogTitle: 'Show Trip Log'
    }, {
      id: 'line',
      caption: '',
      dialogId: null,
      dialogTitle: ''
    }, {
      id: 'reconcileAddNewTrip',
      caption: 'Add New Trip to Route',
      dialogId: 'Add New Trip to Route',
      dialogTitle: _mappedPermIds.default.newTrip
    }, {
      id: 'reconcileAddBreak',
      caption: 'Insert Driver Break',
      dialogId: _mappedPermIds.default.addBreak,
      dialogTitle: 'Insert Driver Break'
    }, {
      id: 'reconcileUnscheduledTrips',
      caption: 'View Unscheduled Trips',
      dialogId: _mappedPermIds.default.unscheduledTrips,
      dialogTitle: 'View Unscheduled Trips'
    }, {
      id: 'reconcileShowRouteLog',
      caption: 'Show Route Log',
      dialogId: _mappedPermIds.default.activityLog,
      dialogTitle: 'Show Route Log'
    }, {
      id: 'reconcileColumnEditor',
      caption: 'Column Editor Settings',
      dialogId: _mappedPermIds.default.activityLog,
      dialogTitle: 'Column Editor Settings'
    }],
    historyRoutesColumns: [{
      id: 'id',
      width: '30px',
      valuePath: 'id',
      resizable: false,
      cellComponent: cellComponent,
      unAvailable: true
    }, {
      id: 'tripId',
      label: 'Trip ID',
      valuePath: 'tripId',
      dataType: 'number',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false,
      cellComponent: coloredCellComponent
    }, {
      id: 'groupId',
      label: 'Group ID',
      valuePath: 'groupId',
      dataType: 'number',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'lastName',
      label: 'Passenger Last Name',
      valuePath: 'riderDetails.lastname',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'firstName',
      label: 'Passenger First Name',
      valuePath: 'riderDetails.firstname',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'status',
      label: 'Status',
      valuePath: 'status',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'type',
      label: 'Type',
      valuePath: 'type',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'eta',
      label: 'ETA',
      valuePath: 'eta',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'promisedTime',
      label: 'Promise Time',
      valuePath: 'promisedTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'appointmentTime',
      label: 'AppointmentTime',
      valuePath: 'appointmentTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'address',
      label: 'Address',
      valuePath: 'address.formattedaddress',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'city',
      label: 'City',
      valuePath: 'address.locality',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }, {
      id: 'actualArrivalTime',
      label: editColumnLabels[0],
      valuePath: 'arriveEvent.receivedat',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false,
      cellComponent: editableCellComponent
    }, {
      id: 'actualDepartTime',
      label: editColumnLabels[1],
      valuePath: 'departEvent.receivedat',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false,
      cellComponent: editableCellComponent
    }, {
      id: 'odometerOnArrival',
      label: editColumnLabels[3],
      valuePath: 'arriveEvent.odo',
      dataType: 'number',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false,
      cellComponent: editableCellComponent
    }, {
      id: 'travelNeeds',
      label: 'Travel Needs',
      valuePath: 'travelNeedsCount',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false,
      cellComponent: hoverCellComponent
    }, {
      id: 'fare',
      label: 'Fare',
      valuePath: 'tripPayment.fare',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      component: headerComponent,
      hidden: false
    }],
    mapConfig: {
      modelName: 'reconcile-trip',
      trips: {
        columns: [{
          id: 'name',
          index: 3,
          type: 'uuid',
          label: 'ID',
          valuePath: 'tripId',
          valuePreview: '1',
          hidden: false,
          isMapLabelVisible: true,
          searchable: true,
          defaultWidth: 60
        }, {
          id: 'riderFirstName',
          index: 0,
          type: 'text',
          label: 'Passenger First Name',
          valuePath: 'passenger.firstName',
          hidden: false,
          searchable: true,
          defaultWidth: 75
        }, {
          id: 'riderLastName',
          index: 1,
          type: 'text',
          label: 'Passenger Last Name',
          valuePath: 'passenger.lastName',
          hidden: false,
          searchable: true,
          defaultWidth: 75
        }, {
          id: 'riderPhone',
          type: 'text',
          index: 2,
          label: 'Passenger Phone Number',
          valuePath: 'passenger.phoneNumber',
          hidden: true,
          defaultWidth: 70
        }, {
          id: 'externalId',
          index: 11,
          type: 'uuid',
          label: 'Passenger ID',
          valuePath: 'passenger.id',
          editable: true,
          hidden: true,
          defaultWidth: 60
        }, {
          id: 'tripType',
          index: 9,
          type: 'text',
          label: 'Type',
          valuePath: 'type',
          editable: true,
          hidden: true,
          defaultWidth: 60
        }, {
          id: 'routeName',
          index: 4,
          type: 'text',
          label: 'Route Name',
          valuePath: 'routeId',
          hidden: false,
          searchable: true,
          defaultWidth: 70
        }, {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'status',
          hidden: false,
          searchable: true,
          isMapLabelVisible: true,
          defaultWidth: 75
        }, {
          id: 'statusReason',
          index: 6,
          type: 'text',
          label: 'Status Reason',
          valuePath: 'statusReason',
          hidden: false,
          defaultWidth: 75
        }, {
          id: 'statusNotes',
          index: 27,
          type: 'text',
          label: 'Status Notes',
          valuePath: 'statusNotes',
          hidden: true,
          defaultWidth: 75
        }, {
          id: 'otpStatus',
          index: 7,
          type: 'text',
          label: 'OTP Status',
          valuePath: 'otpStatus',
          valuePreview: 'On Time',
          hidden: false,
          searchable: true,
          highlightable: true
        }, {
          id: 'otp',
          index: 28,
          type: 'number',
          label: 'OTP',
          valuePath: 'otpValue',
          hidden: true,
          highlightable: true
        }, {
          id: 'promisedTime',
          index: 8,
          type: 'datetime',
          label: 'Promise Time',
          valuePath: 'formattedPromiseTime',
          valuePreview: '09:00',
          editable: true,
          format: 'HH:mm',
          defaultWidth: 50
        }, {
          id: 'requestedTime',
          index: 10,
          type: 'time',
          label: 'Request Time',
          valuePath: 'formattedRequestTime',
          valuePreview: '08:00',
          editable: true,
          hidden: false,
          format: 'HH:mm',
          defaultWidth: 50
        }, {
          id: 'anchorETA',
          index: 12,
          type: 'time',
          label: 'Anchor ETA',
          valuePath: 'anchorStop.stopPoint.etaNavStopPoint.eta',
          valuePreview: '16',
          hidden: true,
          format: 'HH:mm',
          defaultWidth: 50
        }, {
          id: 'serviceStartTime',
          index: 13,
          type: 'date',
          label: 'Service Start Time',
          valuePath: 'formattedServiceStartTime',
          valuePreview: '?',
          editable: true,
          hidden: true,
          format: 'HH:mm',
          defaultWidth: 50
        }, {
          id: 'serviceEndTime',
          index: 14,
          type: 'date',
          label: 'Service End Time',
          valuePath: 'formattedServiceEndTime',
          valuePreview: '?',
          editable: true,
          hidden: true,
          format: 'HH:mm',
          defaultWidth: 50
        }, {
          id: 'noShowTime',
          index: 40,
          type: 'time',
          label: 'No Show Time',
          valuePath: 'noshowTime',
          valuePreview: '08:00',
          editable: true,
          hidden: false,
          format: 'HH:mm',
          defaultWidth: 50
        }, {
          id: 'anchor',
          index: 15,
          type: 'text',
          label: 'Anchor',
          valuePath: 'anchor',
          valuePreview: 'Pick',
          searchable: true,
          editable: true,
          hidden: false,
          defaultWidth: 60
        }, {
          id: 'pickupaddress',
          index: 16,
          type: 'text',
          label: 'Pickup Address',
          valuePath: 'stopDetails.pick.address.formattedAddress',
          editable: true,
          hidden: true,
          searchable: true,
          defaultWidth: 80
        }, {
          id: 'pickupCity',
          index: 17,
          type: 'text',
          label: 'Pickup City',
          valuePath: 'pickStopFormattedCity',
          editable: true,
          hidden: true,
          searchable: true,
          defaultWidth: 80
        }, {
          id: 'dropaddress',
          index: 18,
          type: 'text',
          label: 'Drop off Address',
          valuePath: 'stopDetails.drop.address.formattedAddress',
          editable: true,
          hidden: true,
          searchable: true,
          defaultWidth: 80
        }, {
          id: 'dropCity',
          index: 19,
          type: 'text',
          label: 'Drop off City',
          valuePath: 'dropStopFormattedCity',
          editable: true,
          hidden: true,
          searchable: true,
          defaultWidth: 80
        }, {
          id: 'passengerNotes',
          index: 21,
          type: 'text',
          label: 'Passenger Notes',
          valuePath: 'passengerNotes',
          editable: true,
          hidden: true,
          searchable: true,
          defaultWidth: 80
        }, {
          id: 'notes',
          index: 20,
          type: 'text',
          label: 'Driver Notes',
          valuePath: 'driverNotes',
          editable: true,
          hidden: true,
          searchable: true,
          defaultWidth: 50
        }, {
          id: 'travelNeeds',
          index: 22,
          type: 'enum',
          label: 'Travel Needs',
          valuePath: 'formattedTravelNeeds',
          cellDesc: 'Travel Needs',
          editable: false,
          hidden: true
        }, // STUB FOR AVA DEMO
        {
          id: 'plannedMiles',
          index: 23,
          type: 'number',
          label: 'Planned Trip Miles',
          valuePath: 'plannedTripMiles',
          editable: true,
          hidden: true,
          defaultWidth: 50
        }, // STUB FOR AVA DEMO
        {
          id: 'actualMiles',
          index: 24,
          type: 'number',
          label: 'Actual Trip Miles',
          valuePath: 'actualTripMiles',
          editable: true,
          hidden: true,
          defaultWidth: 50
        }, {
          id: 'fare',
          index: 25,
          type: 'number',
          label: 'Fare',
          valuePath: 'price.fare',
          editable: true,
          hidden: true,
          defaultWidth: 50
        }, {
          id: 'paymentType',
          index: 26,
          type: 'text',
          label: 'Payment Type',
          valuePath: 'price.type',
          valuePreview: 'Single',
          editable: true,
          hidden: true,
          defaultWidth: 50
        }, {
          id: 'provider',
          index: 29,
          type: 'text',
          label: 'Provider',
          valuePath: 'providerName',
          valuePreview: 'MAG',
          hidden: true,
          searchable: true,
          highlightable: true
        }, {
          id: 'serviceMode',
          index: 30,
          type: 'string',
          label: 'Service Mode',
          valuePath: 'serviceMode',
          hidden: true,
          highlightable: true
        }, {
          id: 'previousRouteName',
          index: 41,
          type: 'text',
          label: 'Previous Route Name',
          valuePath: 'previousRouteName',
          hidden: true,
          highlightable: false,
          searchable: true,
          defaultWidth: 70
        }, {
          id: 'mode',
          type: 'text',
          label: 'Scheduling Mode',
          valuePath: 'schedulingMode',
          hidden: true,
          searchable: false,
          highlightable: false
        }, {
          id: 'brokerEligible',
          type: 'boolean',
          label: 'Broker Eligible',
          valuePath: 'booking.legs.firstObject.brokerEligible',
          searchable: true,
          hidden: true,
          defaultWidth: 60
        }]
      },
      stops: {
        columns: [{
          id: 'type',
          index: 0,
          type: 'text',
          label: 'Type',
          valuePath: 'stopType',
          valuePreview: 'Pick',
          searchable: true,
          isMapLabelVisible: true,
          defaultWidth: 50
        }, {
          id: 'tripId',
          index: 1,
          type: 'text',
          label: 'Trip ID',
          valuePath: 'tripId',
          searchable: true,
          defaultWidth: 60,
          isMapLabelVisible: true
        }, {
          id: 'riderLastName',
          index: 2,
          type: 'text',
          label: 'Passenger Last Name',
          valuePath: 'passenger.lastName',
          searchable: true,
          defaultWidth: 75
        }, {
          id: 'riderFirstName',
          index: 3,
          type: 'text',
          label: 'Passenger First Name',
          valuePath: 'passenger.firstName',
          searchable: true,
          defaultWidth: 60
        }, {
          id: 'riderId',
          index: 17,
          type: 'text',
          label: 'Passenger ID',
          valuePath: 'passenger.passengerId',
          searchable: true,
          defaultWidth: 60,
          hidden: true
        }, {
          id: 'status',
          index: 7,
          type: 'text',
          label: 'Status',
          valuePath: 'status',
          valuePreview: 'Planned',
          editable: false,
          defaultWidth: 60
        }, {
          id: 'OTPstatus',
          index: 4,
          type: 'text',
          label: 'OTP Status',
          valuePath: 'otpStatus',
          defaultWidth: 40,
          highlightable: true
        }, {
          id: 'city',
          type: 'text',
          label: 'City',
          valuePath: 'address.formattedCity',
          editable: true,
          hidden: true,
          defaultWidth: 80
        }, {
          id: 'serviceWindow',
          index: 15,
          type: 'text',
          label: 'Service Window',
          valuePath: 'trip.serviceStartTime',
          editable: true,
          hidden: true,
          defaultWidth: 80
        }, {
          id: 'stopBreakOrd',
          type: 'number',
          label: 'Stop Break Ord',
          valuePath: 'ordinal',
          searchable: false,
          editable: false,
          defaultWidth: 50,
          hidden: true
        }, {
          id: 'riderPhone',
          index: 16,
          type: 'text',
          label: 'Passenger Phone Number',
          valuePath: 'passenger.phoneNumber',
          searchable: true,
          defaultWidth: 60,
          hidden: true
        }, {
          id: 'routeName',
          index: 10,
          type: 'text',
          label: 'Route Name',
          valuePath: 'routeId',
          valuePreview: '40',
          defaultWidth: 70,
          hidden: true
        }, {
          id: 'ETA',
          index: 5,
          type: 'date',
          label: 'ETA',
          valuePath: 'formattedActualArriveTime',
          editable: true,
          defaultWidth: 40,
          highlightable: true,
          searchable: true
        }, {
          id: 'promisedTime',
          index: 13,
          type: 'datetime',
          label: 'Promise Time',
          valuePath: 'formattedPromiseTime',
          editable: true,
          format: 'MM-DD-YYYY hh:mm A',
          defaultWidth: 50,
          hidden: true
        }, {
          id: 'requestedTime',
          index: 6,
          type: 'time',
          label: 'Request Time',
          valuePath: 'formattedPromiseTime',
          editable: true,
          format: 'hh:mm A',
          defaultWidth: 70
        }, {
          id: 'actualArrivalTime',
          index: 8,
          type: 'time',
          label: 'Actual Arrive Time',
          valuePath: 'formattedActualArriveTime',
          editable: true,
          format: 'hh:mm A',
          defaultWidth: 50
        }, {
          id: 'actualDepartTime',
          index: 9,
          type: 'time',
          label: 'Actual Depart Time',
          valuePath: 'formattedActualDepartTime',
          editable: true,
          format: 'hh:mm A',
          defaultWidth: 50
        }, {
          id: 'plannedDepartTime',
          index: 14,
          type: 'datetime',
          label: 'Planned Depart Time',
          valuePath: 'formattedEta',
          editable: true,
          format: 'MM-DD-YYYY hh:mm A',
          defaultWidth: 50,
          hidden: true
        }, {
          id: 'notes',
          index: 25,
          type: 'text',
          label: 'Notes',
          valuePath: 'address.notes',
          editable: true,
          searchable: true,
          defaultWidth: 100,
          hidden: true
        }, {
          id: 'address',
          index: 11,
          type: 'text',
          label: 'Address ',
          valuePath: 'address.formattedAddress',
          searchable: true,
          editable: true,
          hidden: true,
          defaultWidth: 80
        }, {
          id: 'date',
          index: 12,
          type: 'date',
          label: 'Date',
          valuePath: 'formattedDate',
          format: 'MM-DD-YYYY',
          defaultWidth: 70,
          hidden: true
        }, {
          id: 'odometer',
          index: 26,
          type: 'number',
          label: 'Odometer on Arrival',
          valuePath: 'odometerArrival',
          editable: true,
          searchable: true,
          hidden: true
        }, {
          id: 'BreakStartTime',
          index: 19,
          type: 'time',
          label: 'Break Start Time',
          valuePath: 'routeBreak.plannedStartTime',
          editable: true,
          format: 'hh:mm A',
          defaultWidth: 50,
          hidden: true,
          unAvailable: true
        }, {
          id: 'BreakEndTime',
          index: 20,
          type: 'time',
          label: 'Break End Time',
          valuePath: 'routeBreak.plannedEndTime',
          editable: true,
          format: 'hh:mm A',
          defaultWidth: 50,
          hidden: true,
          unAvailable: true
        }, {
          id: 'BreakType',
          index: 21,
          type: 'text',
          label: 'Break Type',
          valuePath: 'routeBreak.breakType.name',
          valuePreview: 'Floating',
          defaultWidth: 70,
          hidden: true,
          unAvailable: true
        }, {
          id: 'otp',
          index: 27,
          type: 'number',
          label: 'OTP',
          valuePath: 'otpValue',
          hidden: true,
          highlightable: true
        }, {
          id: 'arriveLat',
          index: 28,
          type: 'string',
          label: 'Actual Arrive Lat',
          valuePath: 'address.lat',
          hidden: true,
          highlightable: false
        }, {
          id: 'arriveLng',
          index: 29,
          type: 'string',
          label: 'Actual Arrive Lng',
          valuePath: 'address.lng',
          hidden: true,
          highlightable: false
        }, {
          id: 'departLat',
          index: 30,
          type: 'string',
          label: 'Actual Depart Lat',
          valuePath: 'address.lat',
          hidden: true,
          highlightable: false
        }, {
          id: 'departLng',
          index: 31,
          type: 'string',
          label: 'Actual Depart Lng',
          valuePath: 'address.lng',
          hidden: true,
          highlightable: false
        }]
      }
    },
    filters: {
      text: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase() === values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && Ember.isEmpty(values[0]) || Ember.isEmpty(item) && !Ember.isEmpty(values[0]) || item.toString().trim().toLowerCase() !== values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (!Ember.isEmpty(item)) return false;
          return true;
        }
      }],
      uuid: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase() === values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && Ember.isEmpty(values[0]) || Ember.isEmpty(item) && !Ember.isEmpty(values[0]) || item.toString().trim().toLowerCase() !== values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (!Ember.isEmpty(item)) return false;
          return true;
        }
      }],
      number: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && parseFloat(item) === parseFloat(values[0]);
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && Ember.isEmpty(values[0]) || Ember.isEmpty(item) && !Ember.isEmpty(values[0]) || item.toString().trim().toLowerCase() !== values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'greaterThan',
        label: '>',
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && parseFloat(item) > parseFloat(values[0]);
        }
      }, {
        id: 'lessThan',
        label: '<',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && parseFloat(item) < parseFloat(values[0]);
        }
      }, {
        id: 'between',
        label: 'Between',
        arity: 2,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && !Ember.isEmpty(values[1]) && values[1] > values[0] && item <= values[1] && item >= values[0];
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (!Ember.isEmpty(item)) return false;
          return true;
        }
      }],
      date: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item === values[0];
          return false;
        }
      }, {
        id: 'after',
        label: 'After',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item > values[0];
          return false;
        }
      }, {
        id: 'before',
        label: 'Before',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item < values[0];
          return false;
        }
      }, {
        id: 'atOrBefore',
        label: 'At or Before',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item <= values[0];
          return false;
        }
      }, {
        id: 'atOrAfter',
        label: 'At or After',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item >= values[0];
          return false;
        }
      }, {
        id: 'between',
        label: 'Between',
        arity: 2,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && values[1] && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[1], 'hh:mm A', true).isValid()) return values[1] > values[0] && item <= values[1] && item >= values[0];
          return false;
        }
      }, {
        id: 'strictlyBetween',
        label: 'Strictly Between',
        arity: 2,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && values[1] && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[1], 'hh:mm A', true).isValid()) return values[1] > values[0] && item < values[1] && item > values[0];
          return false;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (!Ember.isEmpty(item)) return false;
          return true;
        }
      }]
    }
  };
  _exports.default = _default;
});