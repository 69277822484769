define("adept-iq/services/routeexecevent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AMBULATORY_TRAVELNEED_TYPE = 'ambulatory';
  const WHEELCHAIR_TRAVELNEED_TYPE = 'wheelchair';
  const SERVICEANIMAL_TRAVELNEED_TYPE = 'serviceAnimal';
  const PCA_TRAVELNEED_TYPE = 'pca';
  const COMPANION_TRAVELNEED_TYPE = 'companion'; // @TODO service seems unnecessary and should be converted into a util file as its only preparing JSON Objects

  var _default = Ember.Service.extend({
    routeStartData() {
      const routeStartData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'routeId': '',
            'content': {
              'type': 'pullOut',
              'actualTime': ''
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              }
            }
          }
        }
      };
      return routeStartData;
    },

    undoRouteStartData() {
      const undoRouteStartData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'routeId': '',
            'content': {
              'type': 'accept',
              'actualTime': '',
              'stopId': ''
            },
            'driver': {
              'id': '',
              'badgeNr': ''
            },
            'vehicle': {
              'id': '',
              'callsign': ''
            },
            'source': {
              'type': 'webApp'
            }
          }
        }
      };
      return undoRouteStartData;
    },

    routeEndData() {
      const routeEndData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'routeId': '',
            'content': {
              'type': 'pullIn',
              'actualTime': ''
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              }
            }
          }
        }
      };
      return routeEndData;
    },

    undoRouteEndData() {
      const undoRouteEndData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'routeId': '',
            'content': {
              'type': 'accept',
              'actualTime': '',
              'stopId': ''
            },
            'driver': {
              'id': '',
              'badgeNr': ''
            },
            'vehicle': {
              'id': '',
              'callsign': ''
            },
            'source': {
              'type': 'webApp'
            }
          }
        }
      };
      return undoRouteEndData;
    },

    routeCompleteData() {
      const routeCompleteData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'routeId': '',
            'content': {
              'type': 'complete',
              'actualTime': ''
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              }
            }
          }
        }
      };
      return routeCompleteData;
    },

    undoNoShowData() {
      const undoNoShowData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'source': {
              'type': 'webApp'
            },
            'routeId': '',
            'driver': {
              'name': '',
              'badgeNr': '',
              'id': ''
            },
            'content': {
              'actualTime': '',
              'stopId': '',
              'type': 'undoNoShow',
              'passengerEvents': [{
                'passengerId': '',
                'tripId': '',
                'type': 'pickup'
              }],
              'message': ''
            },
            'vehicle': {
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              },
              'id': '',
              'provider': {
                'name': ''
              }
            }
          }
        }
      };
      return undoNoShowData;
    },

    stopArriveData() {
      const stopArriveData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '',
            'routeVersion': '1',
            'content': {
              'type': 'arrive',
              'stopIndex': 0,
              'stopId': '',
              'actualTime': '',
              'passengerEvents': [{
                'passengerId': '',
                'passengerType': 'client',
                'type': 'pickup',
                'tripId': '',
                'externalTripId': ''
              }]
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              }
            },
            'source': {
              'type': 'webApp'
            }
          }
        }
      };
      return stopArriveData;
    },

    stopDepartData() {
      const stopDepartData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '',
            'routeId': '',
            'content': {
              'type': 'depart',
              'stopIndex': 0,
              'stopId': '',
              'actualTime': '',
              'source': {
                'type': 'webApp'
              },
              'passengerEvents': [{
                'passengerId': '',
                'passengerType': 'client',
                'type': 'pickup',
                'tripId': '',
                'externalTripId': ''
              }]
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              }
            }
          }
        }
      };
      return stopDepartData;
    },

    stopAcceptData() {
      return {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '',
            'routeVersion': '1',
            'content': {
              'type': 'accept',
              'stopIndex': 0,
              'stopId': '',
              'actualTime': '',
              'passengerEvents': [{
                'passengerId': '',
                'passengerType': '',
                'type': 'noShow',
                'details': {
                  'message': ''
                }
              }]
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              }
            },
            'source': {
              'type': 'webApp'
            }
          }
        }
      };
    },

    onBreakData() {
      const onBreakData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '2',
            'source': {
              'type': 'webApp'
            },
            'content': {
              'type': 'onBreak',
              'actualTime': '2018-07-13T16:52:08.777Z',
              'breakId': '13'
            },
            'driver': {
              'id': '19031',
              'badgeNr': '19031'
            },
            'vehicle': {
              'id': '214',
              'callsign': '214',
              'location': {
                'coord': {
                  'lat': 46.05991,
                  'lng': -118.36189
                },
                'time': '2018-07-13T15:52:08.777Z'
              },
              'mov': {
                'speed': 25,
                'heading': 15
              }
            }
          }
        }
      };
      return onBreakData;
    },

    offBreakData() {
      const offBreakData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '2',
            'source': {
              'type': 'webApp'
            },
            'content': {
              'type': 'offBreak',
              'actualTime': '2018-07-13T16:52:08.777Z',
              'breakId': '13'
            },
            'driver': {
              'id': '19031',
              'badgeNr': '19031',
              'name': 'DRVR'
            },
            'vehicle': {
              'id': '214',
              'callsign': '214',
              'location': {
                'coord': {
                  'lat': 46.05991,
                  'lng': -118.36189
                },
                'time': '2018-07-13T15:52:08.777Z'
              },
              'mov': {
                'speed': 25,
                'heading': 15
              }
            }
          }
        }
      };
      return offBreakData;
    },

    breakAcceptData() {
      const breakAcceptData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '1',
            'routeId': '2',
            'source': {
              'type': 'webApp'
            },
            'content': {
              'type': 'accept',
              'actualTime': '2018-07-13T16:52:08.777Z',
              'breakId': '13'
            },
            'driver': {
              'id': '19031',
              'badgeNr': '19031'
            },
            'vehicle': {
              'id': '214',
              'callsign': '214',
              'location': {
                'coord': {
                  'lat': 46.05991,
                  'lng': -118.36189
                },
                'time': '2018-07-13T15:52:08.777Z'
              },
              'mov': {
                'speed': 25,
                'heading': 15
              }
            }
          }
        }
      };
      return breakAcceptData;
    },

    noShowData() {
      const noShowData = {
        'data': {
          'type': 'routeExecEvent',
          'attributes': {
            'trackingId': '',
            'routeId': '',
            'content': {
              'type': 'arrive',
              'stopIndex': 1,
              'stopId': '',
              'actualTime': '',
              'source': {
                'type': 'webApp'
              },
              'passengerEvents': [{
                'passengerId': '',
                'passengerType': '',
                'type': 'noShow',
                'details': {
                  'code': '',
                  'message': ''
                },
                'tripId': '',
                'externalTripId': ''
              }],
              'message': ''
            },
            'source': {
              'type': 'webApp'
            },
            'driver': {
              'id': '',
              'badgeNr': '',
              'name': ''
            },
            'vehicle': {
              'id': '',
              'callsign': '',
              'location': {
                'coord': {
                  'lat': 0.0,
                  'lng': 0.0
                },
                'time': ''
              }
            }
          }
        }
      };
      return noShowData;
    },

    tripPaymentData() {
      const tripPaymentData = {
        'data': {
          'type': 'tripPayment',
          'attributes': {
            'status': 'completed',
            'trackingId': '',
            'externalBookingId': '155',
            'tripId': '1',
            'passengerId': '1',
            'fare': 2,
            'components': [{
              'name': 'cash',
              'amount': 2
            }],
            'total': 1,
            'currencyCode': 'CAD'
          }
        }
      };
      return tripPaymentData;
    },

    cancelData() {
      const cancelData = {
        'data': {
          'type': 'cancellation',
          'attributes': {
            'notes': 'client is on vacation',
            'lateCancel': false
          },
          'relationships': {
            'cancelTypeName': {
              'data': {
                'id': 'CX_L',
                'type': 'cancelTypeName'
              }
            }
          }
        }
      };
      return cancelData;
    },

    prepareRouteStartData(routeStartData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      routeStartData.data.attributes.routeId = record.get('id');
      routeStartData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      routeStartData.data.attributes.content.actualTime = record.get('timestamp');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      routeStartData.data.attributes.driver.id = '' + assignedDriver.get('id');
      routeStartData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      routeStartData.data.attributes.driver.name = assignedDriver.get('fullName');
      routeStartData.data.attributes.vehicle.id = assignedVehicle.get('id');
      routeStartData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      routeStartData.data.attributes.vehicle.location.time = record.get('timestamp');

      if (Ember.isNone(record.get('dispatchRoute.startGarage.location.lat')) || Ember.isNone(record.get('dispatchRoute.startGarage.location.lng'))) {
        delete routeStartData.data.attributes.vehicle.location;
      } else {
        routeStartData.data.attributes.vehicle.location.coord.lat = record.get('dispatchRoute.startGarage.location.lat');
        routeStartData.data.attributes.vehicle.location.coord.lng = record.get('dispatchRoute.startGarage.location.lng');
      }

      return routeStartData;
    },

    prepareUndoRouteStartData(undoRouteStartData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      undoRouteStartData.data.attributes.routeId = record.get('id');
      undoRouteStartData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      undoRouteStartData.data.attributes.content.actualTime = record.get('timestamp');
      undoRouteStartData.data.attributes.content.stopId = record.get('dispatchRoute.pulloutGarageStop.id');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      undoRouteStartData.data.attributes.driver.id = '' + assignedDriver.get('id');
      undoRouteStartData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      undoRouteStartData.data.attributes.driver.name = assignedDriver.get('fullName');
      undoRouteStartData.data.attributes.vehicle.id = assignedVehicle.get('id');
      undoRouteStartData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      return undoRouteStartData;
    },

    prepareRouteEndData(routeEndData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      routeEndData.data.attributes.routeId = record.get('id');
      routeEndData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      routeEndData.data.attributes.content.actualTime = record.get('timestamp');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      routeEndData.data.attributes.driver.id = '' + assignedDriver.get('id');
      routeEndData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      routeEndData.data.attributes.driver.name = assignedDriver.get('fullName');
      routeEndData.data.attributes.vehicle.id = assignedVehicle.get('id');
      routeEndData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      routeEndData.data.attributes.vehicle.location.time = record.get('timestamp');

      if (Ember.isNone(record.get('dispatchRoute.endGarage.location.lat')) || Ember.isNone(record.get('dispatchRoute.endGarage.location.lng'))) {
        delete routeEndData.data.attributes.vehicle.location;
      } else {
        routeEndData.data.attributes.vehicle.location.coord.lat = record.get('dispatchRoute.endGarage.location.lat');
        routeEndData.data.attributes.vehicle.location.coord.lng = record.get('dispatchRoute.endGarage.location.lng');
      }

      return routeEndData;
    },

    prepareUndoRouteEndData(undoRouteEndData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      undoRouteEndData.data.attributes.routeId = record.get('id');
      undoRouteEndData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      undoRouteEndData.data.attributes.content.actualTime = record.get('timestamp');
      undoRouteEndData.data.attributes.content.stopId = record.get('dispatchRoute.pullinGarageStop.id');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      undoRouteEndData.data.attributes.driver.id = '' + assignedDriver.get('id');
      undoRouteEndData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      undoRouteEndData.data.attributes.driver.name = assignedDriver.get('fullName');
      undoRouteEndData.data.attributes.vehicle.id = assignedVehicle.get('id');
      undoRouteEndData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      return undoRouteEndData;
    },

    prepareRouteCompleteData(routeCompleteData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      routeCompleteData.data.attributes.routeId = record.get('id');
      routeCompleteData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      routeCompleteData.data.attributes.content.actualTime = record.get('timestamp');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      routeCompleteData.data.attributes.driver.id = '' + assignedDriver.get('id');
      routeCompleteData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      routeCompleteData.data.attributes.driver.name = assignedDriver.get('fullName');
      routeCompleteData.data.attributes.vehicle.id = assignedVehicle.get('id');
      routeCompleteData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      routeCompleteData.data.attributes.vehicle.location.time = record.get('timestamp');

      if (Ember.isNone(record.get('dispatchRoute.endGarage.location.lat')) || Ember.isNone(record.get('dispatchRoute.endGarage.location.lng'))) {
        delete routeCompleteData.data.attributes.vehicle.location;
      } else {
        routeCompleteData.data.attributes.vehicle.location.coord.lat = record.get('dispatchRoute.endGarage.location.lat');
        routeCompleteData.data.attributes.vehicle.location.coord.lng = record.get('dispatchRoute.endGarage.location.lng');
      }

      return routeCompleteData;
    },

    prepareStopArriveData(stopArriveData, record, activityLog) {
      let avlmVersion = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      stopArriveData.data.attributes.trackingId = '1';
      stopArriveData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      stopArriveData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      stopArriveData.data.attributes.content.actualTime = record.get('actualArriveTime');
      stopArriveData.data.attributes.content.eta = record.get('actualArriveTime');
      stopArriveData.data.attributes.content.stopId = record.get('id');
      stopArriveData.data.attributes.content.stopIndex = record.get('clusterOrdinal');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      stopArriveData.data.attributes.content.passengerEvents[0].passengerId = record.get('trip.rider.id');
      stopArriveData.data.attributes.content.passengerEvents[0].passengerType = 'client';
      stopArriveData.data.attributes.content.passengerEvents[0].tripId = record.get('trip.id');
      stopArriveData.data.attributes.content.passengerEvents[0].externalTripId = record.get('trip.tripId');

      if (activityLog) {
        stopArriveData.data.attributes.content.loggingActivity = activityLog;
      }

      if (assignedDriver) {
        stopArriveData.data.attributes.driver.id = '' + assignedDriver.get('id');
        stopArriveData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
        stopArriveData.data.attributes.driver.name = assignedDriver.get('fullName');
      }

      if (record.get('isDrop')) {
        stopArriveData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      if (assignedVehicle) {
        stopArriveData.data.attributes.vehicle.id = assignedVehicle.get('id');
        stopArriveData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
        stopArriveData.data.attributes.vehicle.location.time = record.get('actualArriveTime');
      }

      if (Ember.isNone(record.get('place.location.lat')) || Ember.isNone(record.get('place.location.lng'))) {
        delete stopArriveData.data.attributes.vehicle.location;
      } else {
        stopArriveData.data.attributes.vehicle.location.coord.lat = record.get('place.location.lat');
        stopArriveData.data.attributes.vehicle.location.coord.lng = record.get('place.location.lng');
      }

      return stopArriveData;
    },

    divideTravelNeedTypeCounts(needCount, availableCounts) {
      let workingCount = needCount;
      let ambCount = 0;
      let wcCount = 0;

      while (workingCount > 0 && (availableCounts.ambCount > 0 || availableCounts.wcCount > 0)) {
        if (availableCounts.ambCount > 0) {
          ambCount++;
          availableCounts.ambCount--;
          workingCount--;
        } else if (availableCounts.wcCount) {
          wcCount++;
          availableCounts.wcCount--;
          workingCount--;
        }
      }

      return {
        ambCount,
        wcCount
      };
    },

    // "companion" travel needs have passenger-type "companion" and travel-need-type "ambulatory" or "wheelchair"
    // "PCA" travel needs have passenger-type "pca" and travel-need-type "ambulatory" or "wheelchair"
    // "service animal" travel needs have passenger-type "client" and travel-need-type "serviceAnimal"
    // match pca/companion to ambulatory or wheelchair. Does not matter which. The rest goes to clinet.
    arrangePassengerEvents(record) {
      const passengerEvents = [];
      const travelNeeds = record.get('travelNeeds');
      const passengerId = record.get('trip.rider.id');
      const tripId = record.get('trip.id');
      const externalTripId = record.get('trip.tripId');
      const type = record.get('isDrop') ? 'dropoff' : 'pickup';
      const ambulatoryTravelNeed = Ember.isPresent(travelNeeds) ? travelNeeds.find(item => item.type === AMBULATORY_TRAVELNEED_TYPE) : null;
      const wheelchairTravelNeed = Ember.isPresent(travelNeeds) ? travelNeeds.find(item => item.type === WHEELCHAIR_TRAVELNEED_TYPE) : null;
      const availableCounts = {
        ambCount: ambulatoryTravelNeed ? ambulatoryTravelNeed.cnt : 0,
        wcCount: wheelchairTravelNeed ? wheelchairTravelNeed.cnt : 0
      };
      const serviceAnimalCount = record.get('serviceAnimalCount') ? record.get('serviceAnimalCount') : 0;
      const pcaCount = record.get('pcaCount') ? record.get('pcaCount') : 0;
      const companionCount = record.get('companionCount') ? record.get('companionCount') : 0;
      const usedAttributes = [];

      if (availableCounts.ambCount >= 0) {
        usedAttributes.pushObject({
          type: AMBULATORY_TRAVELNEED_TYPE,
          cnt: availableCounts.ambCount
        });
      }

      if (availableCounts.wcCount >= 0) {
        usedAttributes.pushObject({
          type: WHEELCHAIR_TRAVELNEED_TYPE,
          cnt: availableCounts.wcCount
        });
      }

      if (pcaCount >= 0) {
        usedAttributes.pushObject({
          type: PCA_TRAVELNEED_TYPE,
          cnt: pcaCount
        });
      }

      if (companionCount >= 0) {
        usedAttributes.pushObject({
          type: COMPANION_TRAVELNEED_TYPE,
          cnt: companionCount
        });
      }

      if (serviceAnimalCount > 0) {
        usedAttributes.pushObject({
          type: SERVICEANIMAL_TRAVELNEED_TYPE,
          cnt: 1
        });
      }

      passengerEvents.pushObject({
        passengerId: passengerId,
        type,
        tripId: tripId,
        externalTripId,
        usedAttributes: usedAttributes
      });
      return passengerEvents;
    },

    prepareStopDepartData(stopDepartData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      stopDepartData.data.attributes.trackingId = '1';
      stopDepartData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      stopDepartData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      stopDepartData.data.attributes.content.actualTime = record.get('actualDepartTime');
      stopDepartData.data.attributes.content.eta = record.get('actualDepartTime');
      stopDepartData.data.attributes.content.stopId = record.get('id');
      stopDepartData.data.attributes.content.stopIndex = record.get('clusterOrdinal');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      stopDepartData.data.attributes.content.passengerEvents = this.arrangePassengerEvents(record);

      if (assignedDriver) {
        stopDepartData.data.attributes.driver.id = '' + assignedDriver.get('id');
        stopDepartData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
        stopDepartData.data.attributes.driver.name = assignedDriver.get('fullName');
      }

      if (assignedVehicle) {
        stopDepartData.data.attributes.vehicle.id = assignedVehicle.get('id');
        stopDepartData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
        stopDepartData.data.attributes.vehicle.location.time = record.get('actualDepartTime');
      }

      if (Ember.isNone(record.get('place.location.lat')) || Ember.isNone(record.get('place.location.lng'))) {
        delete stopDepartData.data.attributes.vehicle.location;
      } else {
        stopDepartData.data.attributes.vehicle.location.coord.lat = record.get('place.location.lat');
        stopDepartData.data.attributes.vehicle.location.coord.lng = record.get('place.location.lng');
      }

      return stopDepartData;
    },

    prepareStopAcceptData(acceptData, record, noShowTime, noShowNotes, activityLog) {
      let avlmVersion = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
      acceptData.data.attributes.trackingId = '1';
      acceptData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      acceptData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      acceptData.data.attributes.content.actualTime = new Date();
      acceptData.data.attributes.content.eta = new Date();
      acceptData.data.attributes.content.stopId = record.get('id');
      acceptData.data.attributes.content.stopIndex = record.get('clusterOrdinal');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      acceptData.data.attributes.content.passengerEvents[0].passengerId = record.get('trip.rider.id');
      acceptData.data.attributes.content.passengerEvents[0].passengerType = 'client';
      acceptData.data.attributes.content.passengerEvents[0].tripId = record.get('trip.id');
      acceptData.data.attributes.content.passengerEvents[0].externalTripId = record.get('trip.tripId');

      if (record.get('isPick')) {
        acceptData.data.attributes.content.passengerEvents[0].type = 'pickup';
      }

      if (record.get('isDrop')) {
        acceptData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      if (noShowNotes) {
        acceptData.data.attributes.content.passengerEvents[0].details.message = noShowNotes;
      } else {
        delete acceptData.data.attributes.content.passengerEvents[0].details;
      }

      if (activityLog) {
        acceptData.data.attributes.content.loggingActivity = activityLog;
      }

      if (assignedDriver) {
        acceptData.data.attributes.driver.id = '' + assignedDriver.get('id');
        acceptData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
        acceptData.data.attributes.driver.name = assignedDriver.get('fullName');
      }

      if (assignedVehicle) {
        acceptData.data.attributes.vehicle.id = assignedVehicle.get('id');
        acceptData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
        acceptData.data.attributes.vehicle.location.time = record.get('actualDepartTime') || new Date();

        if (Ember.isNone(assignedVehicle.get('lat')) || Ember.isNone(assignedVehicle.get('lng'))) {
          delete acceptData.data.attributes.vehicle.location;
        } else {
          acceptData.data.attributes.vehicle.location.coord.lat = assignedVehicle.get('lat');
          acceptData.data.attributes.vehicle.location.coord.lng = assignedVehicle.get('lng');
        }
      }

      return acceptData;
    },

    prepareStopNoShowData(noShowData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      const date = new Date();
      noShowData.data.attributes.trackingId = '1';
      noShowData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      noShowData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      noShowData.data.attributes.content.actualTime = record.get('noShow.timestamp') || date;
      noShowData.data.attributes.content.stopId = record.get('id');
      const assignedDriver = record.get('route.dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('route.dispatchRoute.assignedVehicle');
      noShowData.data.attributes.content.passengerEvents[0].passengerId = record.get('trip.rider.id');
      noShowData.data.attributes.content.passengerEvents[0].passengerType = 'client';

      if (record.get('isDrop')) {
        noShowData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      noShowData.data.attributes.content.passengerEvents[0].tripId = record.get('trip.id');
      const noShow = record.get('noShow');
      const noshowDetails = record.get('trip.noshowDetails'); // noShowReasonCode is model no-show-reason-category

      noShowData.data.attributes.content.passengerEvents[0].details.code = noShow.get('noShowReasonCode.name');

      if (!Ember.isEmpty(noshowDetails)) {
        noShowData.data.attributes.content.message = noshowDetails;
        noShowData.data.attributes.content.passengerEvents[0].details.message = noshowDetails;
      } else {
        delete noShowData.data.attributes.content.message;
        delete noShowData.data.attributes.content.passengerEvents[0].details.message;
      }

      noShowData.data.attributes.driver.id = '' + assignedDriver.get('id');
      noShowData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      noShowData.data.attributes.driver.name = assignedDriver.get('fullName');
      noShowData.data.attributes.vehicle.id = assignedVehicle.get('id');
      noShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      noShowData.data.attributes.vehicle.location.time = record.timestamp || date;

      if (Ember.isNone(record.get('lat')) || Ember.isNone(record.get('lng'))) {
        delete noShowData.data.attributes.vehicle.location;
      } else {
        noShowData.data.attributes.vehicle.location.coord.lat = record.get('lat');
        noShowData.data.attributes.vehicle.location.coord.lng = record.get('lng');
      }

      return noShowData;
    },

    prepareTripNoShowRequestData(noShowData, record, noShowTypeDecision) {
      let avlmVersion = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      const pickStopPoint = record.get('pickStopPoint');
      const date = new Date();
      delete noShowData.data.attributes.trackingId;
      noShowData.data.attributes.routeId = pickStopPoint.get('dispatchRoute.route.id');
      noShowData.data.attributes.routeVersion = avlmVersion || pickStopPoint.get('dispatchRoute.avlmVersion');
      noShowData.data.attributes.content.actualTime = record.get('noShow.timestamp') || date;
      noShowData.data.attributes.content.stopId = pickStopPoint.get('id');
      noShowData.data.attributes.content.type = noShowTypeDecision;
      const assignedDriver = pickStopPoint.get('dispatchRoute.assignedDriver');
      const assignedVehicle = pickStopPoint.get('dispatchRoute.assignedVehicle');
      noShowData.data.attributes.content.passengerEvents[0].passengerId = record.get('rider.id');
      noShowData.data.attributes.content.passengerEvents[0].passengerType = 'client';
      noShowData.data.attributes.content.passengerEvents[0].tripId = record.get('id');
      noShowData.data.attributes.content.passengerEvents[0].externalTripId = record.get('tripId');
      delete noShowData.data.attributes.content.source;
      delete noShowData.data.attributes.content.passengerEvents[0].details.code;
      delete noShowData.data.attributes.content.stopIndex;
      noShowData.data.attributes.content.passengerEvents[0].type = 'pickup';
      const noShowReason = record.get('noshowReason');

      if (!Ember.isEmpty(noShowReason)) {
        // noShowReason is model no-show-reason-category
        noShowData.data.attributes.content.passengerEvents[0].details.code = noShowReason.get('name');
        noShowData.data.attributes.content.passengerEvents[0].details.message = noShowReason.get('displayName');
      } else {
        delete noShowData.data.attributes.content.passengerEvents[0].details.code;
        delete noShowData.data.attributes.content.passengerEvents[0].details.message;
      }

      if (!Ember.isEmpty(record.get('noshowDetails'))) {
        noShowData.data.attributes.content.message = record.get('noshowDetails');

        if (Ember.isEmpty(noShowReason)) {
          noShowData.data.attributes.content.passengerEvents[0].details.message = record.get('noshowDetails');
        }
      } else {
        delete noShowData.data.attributes.content.message;
      } //driver data


      noShowData.data.attributes.driver.id = '' + assignedDriver.get('id');
      noShowData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      noShowData.data.attributes.driver.name = assignedDriver.get('fullName'); ///vehicle data

      noShowData.data.attributes.vehicle.id = assignedVehicle.get('id');
      noShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      noShowData.data.attributes.vehicle.provider = {
        name: assignedVehicle.get('provider.name')
      };
      noShowData.data.attributes.vehicle.location.time = date;

      if (Ember.isNone(pickStopPoint.get('lat')) || Ember.isNone(pickStopPoint.get('lng'))) {
        delete noShowData.data.attributes.vehicle.location;
      } else {
        noShowData.data.attributes.vehicle.location.coord.lat = pickStopPoint.get('lat');
        noShowData.data.attributes.vehicle.location.coord.lng = pickStopPoint.get('lng');
      }

      return noShowData;
    },

    prepareTripNoShowData(noShowData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      noShowData.data.attributes.trackingId = '1';
      noShowData.data.attributes.routeId = record.get('pickStopPoint.dispatchRoute.route.id');
      noShowData.data.attributes.routeVersion = avlmVersion || record.get('pickStopPoint.dispatchRoute.avlmVersion');
      noShowData.data.attributes.content.actualTime = record.timestamp;
      noShowData.data.attributes.content.stopId = record.get('pickStopPoint.id');
      const assignedDriver = record.get('pickStopPoint.dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('pickStopPoint.dispatchRoute.assignedVehicle');
      noShowData.data.attributes.content.passengerEvents[0].passengerId = record.get('rider.id');
      noShowData.data.attributes.content.passengerEvents[0].passengerType = 'client';

      if (record.get('type') === 'drop') {
        noShowData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      noShowData.data.attributes.content.passengerEvents[0].tripId = record.get('id');
      noShowData.data.attributes.content.passengerEvents[0].externalTripId = record.get('tripId');
      const noShowReasonCode = record.get('noShowReason');

      if (!Ember.isEmpty(noShowReasonCode)) {
        noShowData.data.attributes.content.passengerEvents[0].details.code = noShowReasonCode.get('externalCode');
      } else {
        delete noShowData.data.attributes.content.passengerEvents[0].details.code;
      }

      if (!Ember.isEmpty(record.get('noShowNotes'))) {
        noShowData.data.attributes.content.message = record.get('noShowNotes');
      } else {
        delete noShowData.data.attributes.content.message;
      } //driver data


      noShowData.data.attributes.driver.id = '' + assignedDriver.get('id');
      noShowData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      noShowData.data.attributes.driver.name = assignedDriver.get('fullName'); ///vehicle data

      noShowData.data.attributes.vehicle.id = assignedVehicle.get('id');
      noShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      noShowData.data.attributes.vehicle.location.time = record.timestamp;

      if (Ember.isNone(record.get('lat')) || Ember.isNone(record.get('lng'))) {
        delete noShowData.data.attributes.vehicle.location;
      } else {
        noShowData.data.attributes.vehicle.location.coord.lat = record.get('lat');
        noShowData.data.attributes.vehicle.location.coord.lng = record.get('lng');
      }

      return noShowData;
    },

    prepareUndoNoShowData(undoNoShowData, record, noShowTime, noShowNotes) {
      let avlmVersion = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      undoNoShowData.data.attributes.trackingId = '1';
      undoNoShowData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      undoNoShowData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      undoNoShowData.data.attributes.content.actualTime = new Date();
      undoNoShowData.data.attributes.content.eta = new Date();
      undoNoShowData.data.attributes.content.stopId = record.get('id');
      undoNoShowData.data.attributes.content.stopIndex = record.get('clusterOrdinal');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      undoNoShowData.data.attributes.content.passengerEvents[0].passengerId = record.get('trip.rider.id');
      undoNoShowData.data.attributes.content.passengerEvents[0].passengerType = 'client';
      undoNoShowData.data.attributes.content.passengerEvents[0].tripId = record.get('trip.id');
      undoNoShowData.data.attributes.content.passengerEvents[0].externalTripId = record.get('trip.tripId');

      if (record.get('isPick')) {
        undoNoShowData.data.attributes.content.passengerEvents[0].type = 'pickup';
      }

      if (record.get('isDrop')) {
        undoNoShowData.data.attributes.content.passengerEvents[0].type = 'dropoff';
      }

      if (noShowNotes) {
        undoNoShowData.data.attributes.content.message = noShowNotes;
      } else {
        delete undoNoShowData.data.attributes.content.message;
      }

      if (assignedDriver) {
        undoNoShowData.data.attributes.driver.id = '' + assignedDriver.get('id');
        undoNoShowData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
        undoNoShowData.data.attributes.driver.name = assignedDriver.get('fullName');
      }

      if (assignedVehicle) {
        undoNoShowData.data.attributes.vehicle.id = assignedVehicle.get('id');
        undoNoShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
        undoNoShowData.data.attributes.vehicle.location.time = record.get('actualDepartTime') || new Date();
        undoNoShowData.data.attributes.vehicle.provider = {
          name: assignedVehicle.get('provider.id')
        };

        if (Ember.isNone(assignedVehicle.get('lat')) || Ember.isNone(assignedVehicle.get('lng'))) {
          delete undoNoShowData.data.attributes.vehicle.location;
        } else {
          undoNoShowData.data.attributes.vehicle.location.coord.lat = assignedVehicle.get('lat');
          undoNoShowData.data.attributes.vehicle.location.coord.lng = assignedVehicle.get('lng');
        }
      }

      return undoNoShowData;
    },

    prepareTripPaymentData(tripPaymentData, record) {
      tripPaymentData.data.attributes.trackingId = '1';
      tripPaymentData.data.attributes.externalBookingId = record.get('externalId');
      tripPaymentData.data.attributes.tripId = record.get('id');
      tripPaymentData.data.attributes.passengerId = record.get('rider.id');
      tripPaymentData.data.attributes.fare = record.get('fare');
      tripPaymentData.data.attributes.components[0].name = record.get('fareName');
      tripPaymentData.data.attributes.components[0].amount = record.get('fare');
      return tripPaymentData;
    },

    prepareTripCancelData(cancelData, record) {
      let lateCancel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      cancelData.data.attributes.notes = record.get('cancelNotes');
      cancelData.data.attributes.lateCancel = lateCancel;
      cancelData.data.relationships.cancelTypeName.data.id = record.get('cancelType.id');
      return cancelData;
    },

    prepareStartBreakData(onBreakData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      const actualArriveTime = record.get('actualArriveTime') ? record.get('actualArriveTime') : new Date();
      onBreakData.data.attributes.trackingId = '1';
      onBreakData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      onBreakData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      onBreakData.data.attributes.content.actualTime = record.get('actualArriveTime');
      onBreakData.data.attributes.content.breakId = record.get('routeBreak.id');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      onBreakData.data.attributes.driver.id = '' + assignedDriver.get('id');
      onBreakData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      onBreakData.data.attributes.vehicle.id = assignedVehicle.get('id');
      onBreakData.data.attributes.vehicle.callsign = assignedVehicle.get('name');

      if (Ember.isNone(record.get('routeBreak.actualArriveTime'))) {
        onBreakData.data.attributes.vehicle.location.time = actualArriveTime;
      } else {
        onBreakData.data.attributes.vehicle.location.time = record.get('routeBreak.actualArriveTime');
      }

      if (Ember.isNone(record.get('routeBreak.startPlace.location.lat')) || Ember.isNone(record.get('routeBreak.startPlace.location.lng'))) {
        if (Ember.isNone(record.get('lat')) || Ember.isNone(record.get('lng'))) {
          delete onBreakData.data.attributes.vehicle.location;
        } else {
          onBreakData.data.attributes.vehicle.location.coord.lat = record.get('lat');
          onBreakData.data.attributes.vehicle.location.coord.lng = record.get('lng');
        }
      } else {
        onBreakData.data.attributes.vehicle.location.coord.lat = record.get('routeBreak.startPlace.location.lat');
        onBreakData.data.attributes.vehicle.location.coord.lng = record.get('routeBreak.startPlace.location.lng');
      }

      return onBreakData;
    },

    prepareEndBreakData(offBreakData, record, avlmVersion) {
      offBreakData.data.attributes.trackingId = '1';
      offBreakData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      offBreakData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      offBreakData.data.attributes.content.actualTime = record.get('actualDepartTime');
      offBreakData.data.attributes.content.breakId = record.get('routeBreak.id');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      offBreakData.data.attributes.driver.id = '' + assignedDriver.get('id');
      offBreakData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      offBreakData.data.attributes.vehicle.id = assignedVehicle.get('id');
      offBreakData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      offBreakData.data.attributes.vehicle.location.time = record.get('routeBreak.actualDepartTime');

      if (Ember.isNone(record.get('routeBreak.endPlace.location.lat')) || Ember.isNone(record.get('routeBreak.endPlace.location.lng'))) {
        delete offBreakData.data.attributes.vehicle.location;
      } else {
        offBreakData.data.attributes.vehicle.location.coord.lat = record.get('routeBreak.endPlace.location.lat');
        offBreakData.data.attributes.vehicle.location.coord.lng = record.get('routeBreak.endPlace.location.lng');
      }

      return offBreakData;
    },

    prepareBreakAcceptData(onBreakData, record) {
      let avlmVersion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      const actualArriveTime = record.get('actualArriveTime') ? record.get('actualArriveTime') : new Date();
      onBreakData.data.attributes.trackingId = '1';
      onBreakData.data.attributes.routeId = record.get('dispatchRoute.route.id');
      onBreakData.data.attributes.routeVersion = avlmVersion || record.get('dispatchRoute.avlmVersion');
      onBreakData.data.attributes.content.actualTime = actualArriveTime;
      onBreakData.data.attributes.content.breakId = record.get('routeBreak.id');
      const assignedDriver = record.get('dispatchRoute.assignedDriver');
      const assignedVehicle = record.get('dispatchRoute.assignedVehicle');
      onBreakData.data.attributes.driver.id = '' + assignedDriver.get('id');
      onBreakData.data.attributes.driver.badgeNr = '' + assignedDriver.get('driverId');
      onBreakData.data.attributes.vehicle.id = assignedVehicle.get('id');
      onBreakData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      onBreakData.data.attributes.vehicle.location.time = actualArriveTime;

      if (Ember.isNone(record.get('place.location.lat')) || Ember.isNone(record.get('place.location.lng'))) {
        if (Ember.isNone(record.get('lat')) || Ember.isNone(record.get('lng'))) {
          delete onBreakData.data.attributes.vehicle.location;
        } else {
          onBreakData.data.attributes.vehicle.location.coord.lat = record.get('lat');
          onBreakData.data.attributes.vehicle.location.coord.lng = record.get('lng');
        }
      } else {
        onBreakData.data.attributes.vehicle.location.coord.lat = record.get('place.location.lat');
        onBreakData.data.attributes.vehicle.location.coord.lng = record.get('place.location.lng');
      }

      return onBreakData;
    },

    prepareCancelDataForTrips(cancelData, record) {
      let lateCancel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let tripIds = arguments.length > 3 ? arguments[3] : undefined;
      cancelData.data.attributes.notes = record.get('cancelNotes');
      cancelData.data.attributes.lateCancel = lateCancel;
      cancelData.data.attributes.tripIds = tripIds;
      cancelData.data.relationships.cancelTypeName.data.id = record.get('cancelType.id');
      return cancelData;
    }

  });

  _exports.default = _default;
});