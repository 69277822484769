define("adept-iq/models/rider-phone-number", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAIN_PHONE_TYPE = _exports.HOME_PHONE_TYPE = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  const MAIN_PHONE_TYPE = 'main';
  _exports.MAIN_PHONE_TYPE = MAIN_PHONE_TYPE;
  const HOME_PHONE_TYPE = 'home';
  _exports.HOME_PHONE_TYPE = HOME_PHONE_TYPE;

  var _default = Model.extend({
    areaCode: attr('string'),
    phoneNumber: attr('string'),
    extension: attr('string'),
    type: attr('string', {
      defaultValue: 'default'
    }),
    description: attr('string', {
      defaultValue: 'default'
    }),
    mobileCarrier: attr('string', {
      defaultValue: 'default'
    }),
    useForSMSNotifications: attr('string', {
      defaultValue: false
    }),
    useForVoiceNotifications: attr('string', {
      defaultValue: false
    }),
    isSoftDeleted: attr('boolean', {
      defaultValue: false
    }),
    rider: belongsTo('rider'),
    formattedPhoneNumber: Ember.computed('areaCode', 'phoneNumber', 'extension', function () {
      const phone = this.get('phoneNumber').match(/\d{3}(?=\d{2,3})|\d+/g).join('-');
      const extension = this.get('extension');
      const areaCode = this.get('areaCode');

      if (extension) {
        return `(${areaCode}) ${phone}-#${extension}`;
      }

      return `(${areaCode}) ${phone}`;
    }),
    formattedPhoneNumberNoExt: Ember.computed('areaCode', 'phoneNumber', function () {
      const phone = this.get('phoneNumber').match(/\d{3}(?=\d{2,3})|\d+/g).join('-');
      const areaCode = this.get('areaCode');
      return `(${areaCode}) ${phone}`;
    }),
    unformattedPhoneNumber: Ember.computed('areaCode', 'phoneNumber', function () {
      const phone = this.get('phoneNumber');
      const areaCode = this.get('areaCode');
      return `${areaCode}${phone}`;
    }),
    typeDisplayName: Ember.computed('type', function () {
      const typeName = this.get('type');
      const record = `config-System_Configuration-rider_phone_types/${typeName}`;
      const riderPhoneType = this.get('store').peekRecord('cs-config-item', record);
      const displayName = riderPhoneType.get('value.displayName') || riderPhoneType.get('value')['Display Name'];
      return displayName;
    })
  });

  _exports.default = _default;
});