define("adept-iq/pods/components/secondary-window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jYYKldmR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"secondary-window-modal\"],[9],[0,\"\\n   \"],[7,\"div\"],[11,\"class\",\"secondary-window-navbar\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"close-button\"],[12,\"disabled\",[21,\"disableXButtonClick\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onXButtonClick\"],null]],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/icons/secondary-window-close-icon.svg\"],[11,\"alt\",\"Close\"],[11,\"class\",\"close-icon\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"secondary-window-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"secondary-window-header\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isCenterTitleAlign\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"spacer\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[21,\"title\"],false],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"spacer\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"secondary-window-body\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"loading-spinner\"],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"fa fa-circle-o-notch fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/secondary-window/template.hbs"
    }
  });

  _exports.default = _default;
});