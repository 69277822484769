define("adept-iq/models/dashboard", ["exports", "ember-data", "adept-iq/classes/dashboard-instance", "lodash", "moment", "adept-iq/config/mapped-permIds", "adept-iq/adapters/dashboard"], function (_exports, _emberData, _dashboardInstance, _lodash, _moment, _mappedPermIds, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SUBSCRIPTION_ROLENAME = _exports.SCHEDULE_WORSPACE_RESTRICT_WIDGETS = _exports.SCHEDULE_ROLENAME = _exports.ROAD_SUPERVISOR_ROLENAME = _exports.REPORTS_ROLENAME = _exports.RECONCILE_ROLENAME = _exports.PLAYBACK_ROLENAME = _exports.PERMISSIONS_ROLENAME = _exports.MANAGE_VEHICLE_DRIVER_ROLENAME = _exports.DISPATCH_ROLENAME = _exports.DASHBOARD_ROLES = _exports.BOOK_ROLENAME = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const DISPATCH_ROLENAME = 'Dispatch';
  _exports.DISPATCH_ROLENAME = DISPATCH_ROLENAME;
  const SCHEDULE_ROLENAME = 'Schedule';
  _exports.SCHEDULE_ROLENAME = SCHEDULE_ROLENAME;
  const SUBSCRIPTION_ROLENAME = 'Subscription';
  _exports.SUBSCRIPTION_ROLENAME = SUBSCRIPTION_ROLENAME;
  const MANAGE_VEHICLE_DRIVER_ROLENAME = 'Driver & Vehicle Management';
  _exports.MANAGE_VEHICLE_DRIVER_ROLENAME = MANAGE_VEHICLE_DRIVER_ROLENAME;
  const BOOK_ROLENAME = 'Booking';
  _exports.BOOK_ROLENAME = BOOK_ROLENAME;
  const RECONCILE_ROLENAME = 'Reconcile';
  _exports.RECONCILE_ROLENAME = RECONCILE_ROLENAME;
  const ROAD_SUPERVISOR_ROLENAME = 'Road Supervisor';
  _exports.ROAD_SUPERVISOR_ROLENAME = ROAD_SUPERVISOR_ROLENAME;
  const PLAYBACK_ROLENAME = 'Playback';
  _exports.PLAYBACK_ROLENAME = PLAYBACK_ROLENAME;
  const PERMISSIONS_ROLENAME = 'Permissions';
  _exports.PERMISSIONS_ROLENAME = PERMISSIONS_ROLENAME;
  const REPORTS_ROLENAME = 'Reports';
  _exports.REPORTS_ROLENAME = REPORTS_ROLENAME;
  const DASHBOARD_ROLES = [SCHEDULE_ROLENAME, MANAGE_VEHICLE_DRIVER_ROLENAME, PERMISSIONS_ROLENAME];
  _exports.DASHBOARD_ROLES = DASHBOARD_ROLES;
  const CLONEABLE_PROPERTIES = ['name', 'userId', 'role', 'startDate', 'endDate', 'referenceDate', 'mapState', 'widgets', 'widgetStates'];
  const SCHEDULE_WORSPACE_RESTRICT_WIDGETS = ['messages', 'alerts'];
  _exports.SCHEDULE_WORSPACE_RESTRICT_WIDGETS = SCHEDULE_WORSPACE_RESTRICT_WIDGETS;

  var _default = Model.extend({
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    name: attr('string', {
      defaultValue: 'Untitled'
    }),
    role: attr('string', {
      defaultValue: SCHEDULE_ROLENAME
    }),
    userId: attr('string', {
      defaultValue: ''
    }),
    // 'workspaces-{users,default,admin}'; can't be changed once saved
    category: attr('string', {
      defaultValue: 'workspaces-users'
    }),
    // absolute timeframe as selected by user
    startDate: attr('date', {
      defaultValue: () => {
        return (0, _moment.default)().startOf('day').toDate();
      }
    }),
    endDate: attr('date', {
      defaultValue: () => {
        return (0, _moment.default)().startOf('day').add(1, 'day').add(-1, 'ms').toDate();
      }
    }),
    // when `startDate` or `endDate` is modified, store the user's current date
    // so that the relative timeframe can be calculated from a later date
    referenceDate: attr('date', {
      defaultValue: () => {
        return (0, _moment.default)().startOf('day').toDate();
      }
    }),
    mapState: attr('object', {
      defaultValue: () => {}
    }),
    widgets: attr('object', {
      defaultValue: () => []
    }),
    widgetStates: attr('object', {
      defaultValue: () => []
    }),
    isScheduleDashboard: Ember.computed.equal('role', SCHEDULE_ROLENAME),
    isDispatchDashboard: Ember.computed.equal('role', DISPATCH_ROLENAME),
    isReconcileDashboard: Ember.computed.equal('role', RECONCILE_ROLENAME),
    isRoadSupervisorDashboard: Ember.computed.equal('role', ROAD_SUPERVISOR_ROLENAME),
    isReportsDashboard: Ember.computed.equal('role', REPORTS_ROLENAME),
    isVehicleDriverDashboard: Ember.computed.equal('role', MANAGE_VEHICLE_DRIVER_ROLENAME),
    // needs a role name (not sure what to use), consider using category instead
    isRiderManagementDashboard: true,
    //NYAAR-10198 For now, all users can view passenger data in the Passenger Search tables. Like profile, bookings, trips, subscriptions.
    isBookingDashboard: Ember.computed.equal('role', BOOK_ROLENAME),
    //NYAAR-10198 For now, all users can view passenger data in the Passenger Search tables. Like profile, bookings, trips, subscriptions.
    isPermissionsDashboard: Ember.computed.equal('role', PERMISSIONS_ROLENAME),
    isPlaybackDashboard: Ember.computed.equal('role', PLAYBACK_ROLENAME),
    isPlaybackWorkspace: false,
    isReconcileWorkspace: false,
    isReportsWorkspace: false,
    isBookingWorkspace: false,
    isRiderManagementWorkspace: false,
    isVehicleDriverWorkspace: Ember.computed.equal('category', 'workspaces-vehicleDriver'),
    isScheduleWorkspace: Ember.computed.equal('category', 'workspaces-schedule'),
    isPermissionsWorkspace: Ember.computed.equal('category', 'workspaces-permissions'),
    isDefaultWorkspace: Ember.computed('category', function () {
      const category = this.get('category');
      return _dashboard.DEFAULT_WORKSPACE_CATEGORIES.includes(category);
    }),
    isPrivateWorkspace: Ember.computed('category', function () {
      const userId = this.get('session').data.authenticated.userId.replace(/-/g, '_');
      const category = this.get('category');
      return category === `workspaces-${userId}`;
    }),
    isPublicWorkspace: Ember.computed('isDefaultWorkspace', 'isPrivateWorkspace', function () {
      const isPrivateWorkspace = this.get('isPrivateWorkspace');
      const isDefaultWorkspace = this.get('isDefaultWorkspace');
      return !isPrivateWorkspace && !isDefaultWorkspace;
    }),

    configureWidgetsOnPermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const widgets = [];

      if (!Ember.isEmpty(this.get('widgets'))) {
        this.get('widgets').forEach(widget => {
          let configPermission, widgetPermType;
          let permWidgetGranted = false;

          if (!Ember.isNone(configPermissions)) {
            widgetPermType = widget.typeId;
            configPermission = configPermissions[widget.typeId];
          }

          switch (widgetPermType) {
            case 'trips':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showTrips, null);
              break;

            case 'stops':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showStops, null);
              break;

            case 'routes':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showRoutes, null);
              break;

            case 'map':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showMaps, null);
              break;

            case 'alerts':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showAlerts, null);
              break;

            case 'drivers':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showDrivers, null);
              break;

            case 'vehicles':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showVehicles, null);
              break;

            case 'passengers':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showPassengers, null);
              break;

            case 'messages':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showMessages, null);
              break;

            case 'reports':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showReports, null);
              break;

            case 'subscriptions':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showSubscriptions, null);
              break;

            case 'users':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showUsers, null);
              break;

            case 'zones':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showZones, null);
              break;

            case 'booking':
              {
                permWidgetGranted = true;
                break;
              }

            case 'riderManagement':
              {
                permWidgetGranted = true;
                break;
              }

            case 'schedule-metrics':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showSchedule, null);
              break;

            case 'route-information':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.accessReconcileWorkspace, null);
              break;

            case 'history-routes':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.accessReconcileWorkspace, null);
              break;

            case 'playback':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.playbackVehicle, null);
              break;

            case 'playback-new':
              permWidgetGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.playbackVehicle, null);
              break;

            case 'reconcile':
              permWidgetGranted = true;
              break;

            case 'perm-users':
              permWidgetGranted = true;
              break;

            case 'perm-roles':
              permWidgetGranted = true;
              break;

            default:
              permWidgetGranted = false;
          }

          if (!Ember.isNone(configPermission) && configPermission.show && permWidgetGranted) {
            widgets.push(widget);
          }
        });
      }

      return widgets;
    },

    configureWidgetsOnWorkspace(widgets) {
      const role = this.get('role');

      if (!Ember.isEmpty(this.get('widgets'))) {
        return widgets.filter(widget => {
          if (widget.blackListedWorkspaces) {
            return widget.blackListedWorkspaces[role];
          }

          return true;
        });
      }

      return widgets;
    },

    makeInstance() {
      let widgets = this.configureWidgetsOnPermission();
      const widgetStates = this.get('widgetStates');
      widgets = this.configureWidgetsOnWorkspace(widgets);

      const instance = _dashboardInstance.default.deserialize({
        schemaVersion: 1,
        attributes: {
          id: this.get('id'),
          name: this.get('name'),
          role: this.get('role'),
          startDate: this.get('startDate'),
          endDate: this.get('endDate'),
          referenceDate: this.get('referenceDate'),
          mapState: this.get('mapState'),
          widgets: widgets,
          widgetStates: widgetStates
        }
      });

      instance.set('model', this);
      return instance;
    },

    commitInstance(instance) {
      let includeWidgetStates = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const {
        attributes
      } = instance.serialize();
      const {
        name,
        role,
        startDate,
        endDate,
        referenceDate,
        mapState,
        widgets,
        widgetStates
      } = attributes;
      this.setProperties({
        name,
        role,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        referenceDate: new Date(referenceDate),
        mapState,
        widgets
      });

      if (includeWidgetStates) {
        this.set('widgetStates', widgetStates);
      }
    },

    getOptionsForClone() {
      const cloneProperties = _lodash.default.cloneDeep(this.getProperties(CLONEABLE_PROPERTIES));

      return cloneProperties;
    }

  });

  _exports.default = _default;
});