define("adept-iq/pods/components/optimize-schedule/component", ["exports", "adept-iq/config/icon-paths", "lodash", "ember-concurrency", "adept-iq/config/api-urls", "adept-iq/utils/translator", "adept-iq/mixins/async-schedule-operation"], function (_exports, _iconPaths, _lodash, _emberConcurrency, _apiUrls, _translator, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const scheduleHost = _apiUrls.API.schedulingService.host;
  const TIMEOUT_DELAY = 1000; // 1 seconds tries

  const RETRY_LIMIT = 3; // Maximum number of retries

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['optimize-schedule'],
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    widget: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    selectedOption: '',
    jsonData: '',
    availableStrategies: null,
    isLoading: true,
    currentSchedule: null,
    updatedSchedule: null,
    isUploaded: false,
    isJSONUploaded: false,
    isXLSXFleetUploaded: false,
    isXLSXJobsUploaded: false,
    XLSXFleetMetaData: null,
    XLSXJobsMetaData: null,
    timeout: TIMEOUT_DELAY,
    // Computed property to determine if the upload button should be disabled
    isXLSXFleetUploadDisabled: Ember.computed('isXLSXFleetUploaded', function () {
      return this.isXLSXFleetUploaded;
    }),
    // Computed property to determine if the upload button should be disabled
    isXLSXJobsUploadDisabled: Ember.computed('isXLSXJobsUploaded', function () {
      return this.isXLSXJobsUploaded;
    }),
    isOptimizeDisabled: Ember.computed('jsonData', 'selectedOption', function () {
      return Ember.isEmpty(this.get('jsonData')) || Ember.isEmpty(this.get('selectedOption'));
    }),
    isJSONUploadDisabled: Ember.computed('isJSONUploaded', function () {
      return this.isJSONUploaded;
    }),

    init() {
      this._super(...arguments);

      this.loadOptimizationStrategies();
      this.defaultGif = '/optimize-animations/first_map_point.gif';
      this.stateToGifs = {
        'in-progress': ['/optimize-animations/first_line_segment.gif'],
        'raas-call-initiated': ['/optimize-animations/second_line_segment.gif'],
        'db-import-completed': ['/optimize-animations/second_line_segment.gif'],
        'raas-job-finished': ['/optimize-animations/third_line_segment.gif'],
        'success': ['/optimize-animations/fifth_map_point.gif']
      };
    },

    async loadOptimizationStrategies() {
      try {
        let strategies = await this.store.findAll('engine-strategy');
        strategies = strategies.filter(strategy => strategy.id !== 'default');
        strategies = (0, _lodash.orderBy)(strategies, ['id'], ['desc']);
        this.set('availableStrategies', strategies);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading strategies:', error);
        this.notifications.error('Failed to load optimization strategies.');
      } finally {
        this.set('isLoading', false);
      }
    },

    unloadAllRecords(modelName) {
      this.store.peekAll(modelName).forEach(record => record.unloadRecord());
    },

    asyncOperationState: (0, _emberConcurrency.task)(function* (job, outputResult) {
      const session = this.get('session');
      const workspace = this.get('workspace');
      let retryCount = 0;

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure') {
        const delay = this.get('timeout');
        yield (0, _emberConcurrency.timeout)(delay);

        try {
          // eslint-disable-next-line no-param-reassign
          job = yield this.get('ajax').request(scheduleHost + '/schedule-async-operation/' + job.data.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
          this.get('asyncOperationState').perform(job, outputResult);

          if (this.get('lastProgressState') !== job.data.attributes.state) {
            const currentState = job.data.attributes.state;
            const gifs = this.stateToGifs[currentState] || [];
            workspace.set('spinnerImageUpdate', gifs.join(', '));
            this.set('lastProgressState', job.data.attributes.state);
          }
        } catch (e) {
          retryCount += 1; // Retry in case of Timeout from API

          if (retryCount > this.get('retryLimit')) {
            throw new Error(`Failed after ${RETRY_LIMIT} attempts: ${e.message}`);
          }
        }
      }

      const isJobSuccess = job.data.attributes.state === 'success';

      if (isJobSuccess) {
        const parsedResults = JSON.parse(job.data.attributes.results || '{}');
        const jobId = parsedResults.job;
        this.store.findRecord('raas-api-call-record', jobId).then(record => {
          workspace.set('spinnerTextUpdate', `${Math.round(record.runTime)}s`);
        }).catch(error => {
          // eslint-disable-next-line no-console
          console.error(`Error fetching record with id ${jobId}:`, error);
        });
      }

      const jsonData = job.data;

      if (outputResult) {
        return { ...job.data.attributes,
          jsonData,
          isJobSuccess
        };
      }

      return isJobSuccess;
    }).drop(),

    async deleteSchedule() {
      const workspace = this.get('workspace');

      try {
        const vehicleAdapter = this.store.adapterFor('vehicle');
        const routeTemplateAdapter = this.store.adapterFor('route-template');
        await Promise.all([vehicleAdapter.bulkDelete(), routeTemplateAdapter.bulkDelete()]);
        const schedules = await this.store.findAll('schedule', {
          reload: true
        });

        if (schedules.length === 0) {
          return;
        }

        await Promise.all(schedules.map(schedule => schedule.destroyRecord()));
        workspace.set('currentSchedule', null);
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.error('Error deleting schedules:', error);
      }
    },

    async updateScheduleOnSuccess() {
      const workspace = this.get('workspace');
      const updatedSchedule = this.get('updatedSchedule');
      this.unloadAllRecords('route');
      this.unloadAllRecords('trip-stop');
      this.unloadAllRecords('trip');
      this.unloadAllRecords('schedule-polyline');
      this.unloadAllRecords('vehicle'); // Vehicles & Routes are reloaded before proceeding.

      await this.store.findAll('vehicle');
      await this.store.findAll('route');
      workspace.set('currentSchedule', updatedSchedule);
      await workspace.refreshWidgets();
    },

    didInsertElement() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    get iconPaths() {
      return {
        sandbox: _iconPaths.default.actionMenu.sandbox,
        upload: _iconPaths.default.actionMenu.upload,
        download: _iconPaths.default.actionMenu.download,
        checkmark: _iconPaths.default.actionMenu.checkmark
      };
    },

    updateProblemObjective() {
      const selectedOption = this.get('selectedOption');
      const jsonFile = this.get('jsonData') || {};

      if (!jsonFile || !jsonFile.problem) {
        jsonFile.problem = {
          objective: null
        };
      }

      const problem = jsonFile.problem;
      const availableStrategies = this.get('availableStrategies');
      const index = availableStrategies.findIndex(strategy => {
        return strategy.id === selectedOption;
      });

      if (index >= 0) {
        problem.objective = index + 1;
      }

      this.set('jsonData', jsonFile);
    },

    async generateJsonFromXLSXUploads() {
      const notifications = this.get('notifications');

      try {
        if (this.get('isXLSXJobsUploaded') && this.get('isXLSXFleetUploaded')) {
          const jsonData = await this.getJsonPayload(this.get('XLSXFleetMetaData'), this.get('XLSXJobsMetaData'));
          this.set('jsonData', jsonData);
          this.updateProblemObjective();
        }
      } catch (e) {
        notifications.warning('PLEASE TRY AGAIN. INVALID FILES');
        this.set('isXLSXJobsUploaded', false);
        this.set('isXLSXFleetUploaded', false);
      }
    },

    async getJsonPayload(fleetFileWithMetaData, jobFileWithMetaDta) {
      const problemTimezone = localStorage.getItem('userTimezone');
      const objective = 1;
      const vehicleCapacityConfigs = await this.get('store').findAll('vehicle-capacity-config');
      const vehicleTypeGroupCount = vehicleCapacityConfigs.reduce((agg, vcc) => {
        if (vcc.count) {
          if (!agg[vcc.vehicleTypeName]) {
            agg[vcc.vehicleTypeName] = {};
          }

          if (!agg[vcc.vehicleTypeName][vcc.group]) {
            agg[vcc.vehicleTypeName][vcc.group] = 0;
          }

          agg[vcc.vehicleTypeName][vcc.group] += vcc.count;
        }

        return agg;
      }, {});
      const vehicleCapacities = Object.entries(vehicleTypeGroupCount).reduce((agg, _ref) => {
        let [vehicleTypeName, groupCount] = _ref;
        const minGroup = Math.min(...Object.keys(groupCount).map(g => Number(g)));
        agg[vehicleTypeName] = groupCount[minGroup];
        return agg;
      }, {});
      return _translator.translator.createRaasPayload(fleetFileWithMetaData, jobFileWithMetaDta, objective, problemTimezone, vehicleCapacities);
    },

    actions: {
      async onOptimizeBtnClick() {
        const notifications = this.get('notifications');
        const workspace = this.get('workspace');

        try {
          workspace.set('spinnerImageUpdate', this.defaultGif);
          workspace.set('isGlobalSpinnerVisible', true);
          let currentSchedule = workspace.get('currentSchedule');
          this.set('isOptimizeDisabled', true);
          this.set('currentSchedule', currentSchedule);
          const operationData = this.get('jsonData');
          const options = {
            operationData,
            outputResult: true,
            manualOperationState: true
          }; //Delete schedule only when user uploads : JSON/CSV (Jobs + Fleet)

          if (this.isJSONUploaded || this.isXLSXFleetUploaded && this.isXLSXJobsUploaded) {
            await this.deleteSchedule();
            currentSchedule = null;
          }

          const result = await this.createScheduleAsyncOperation.perform(currentSchedule, 'raasGenerate', options);
          const job = await this.get('asyncOperationState').perform(result.job, true);

          if (job.isJobSuccess) {
            const scheduleId = job.jsonData.relationships.schedule.data.id;
            const schedule = await this.get('store').findRecord('schedule', scheduleId);
            this.set('updatedSchedule', schedule); // Run updateScheduleOnSuccess and delay in parallel

            await Promise.all([this.updateScheduleOnSuccess(), new Promise(resolve => setTimeout(resolve, 5000)) // Delay for animation
            ]);
            workspace.set('isGlobalSpinnerVisible', false);
            workspace.set('spinnerImageUpdate');
            workspace.set('spinnerTextUpdate');
          } else {
            this.set('isOptimizeDisabled', false);
            workspace.set('isGlobalSpinnerVisible', false);
            workspace.set('spinnerImageUpdate');
            workspace.set('spinnerTextUpdate');
            workspace.set('currentSchedule', currentSchedule);
            notifications.warning('SCHEDULE FAILED TO OPTIMIZE');
          }
        } catch (error) {
          workspace.set('isGlobalSpinnerVisible', false);
          workspace.set('spinnerImageUpdate');
          workspace.set('spinnerTextUpdate'); // eslint-disable-next-line no-console

          console.error('Error during optimize operation:', error);
          notifications.warning('SCHEDULE FAILED TO OPTIMIZE');
        }

        this.send('onXButtonClick');
      },

      onXButtonClick() {
        const workspace = this.get('workspace');
        workspace.set('isOptimizeScheduleOpen', false);
      },

      async handleXLSXFleetUploadSuccess(fileData) {
        const XLSXFleetMetaData = {
          fileBuffer: fileData,
          fileExtension: 'xlsx',
          model: 'vehicle'
        };
        this.set('XLSXFleetMetaData', XLSXFleetMetaData);
        this.set('isXLSXFleetUploaded', true);
        await this.generateJsonFromXLSXUploads();
      },

      async handleXLSXJobsUploadSuccess(fileData) {
        const XLSXJobsMetaData = {
          fileBuffer: fileData,
          fileExtension: 'xlsx',
          model: 'job'
        };
        this.set('XLSXJobsMetaData', XLSXJobsMetaData);
        this.set('isXLSXJobsUploaded', true);
        await this.generateJsonFromXLSXUploads();
      },

      updateOption(selectedOption) {
        // Handle the option change
        this.set('selectedOption', selectedOption);
        this.updateProblemObjective();
      },

      handleJSONUploadSuccess(fileData) {
        const text = new TextDecoder().decode(fileData);
        const jsonData = JSON.parse(text);
        this.set('jsonData', jsonData);
        this.updateProblemObjective();
        this.set('isJSONUploaded', true);
      }

    }
  });

  _exports.default = _default;
});