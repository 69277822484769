define("adept-iq/services/stops-reorder", ["exports", "adept-iq/mixins/version-check"], function (_exports, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_versionCheck.default, {
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    reorderInProgress: false,
    isHidden: false,
    widget: null,
    currentState: null,
    sortByStr: null,
    dispatchRoute: null,
    confirmReorderInProgress: false,

    init() {
      this._super(...arguments);

      Ember.set(this, 'currentState', {});
    },

    // called when a user starts a reorder in stops widget
    async startReorder(widget, sortByStr, dispatchRoute, stopBreakOrdinals) {
      const lockRouteAction = this.get('lockRouteAction').bind(this);

      if (dispatchRoute) {
        this.set('dispatchRoute', dispatchRoute);
        this.set('dispatchRouteToCheck', dispatchRoute);
        this.set('dispatchRouteVersion', dispatchRoute.version);
        this.set('stopBreakOrdinals', stopBreakOrdinals);

        try {
          await lockRouteAction(true);
        } catch (error) {
          const tooltip = this.get('tooltip');
          return tooltip.pushConfirmation({
            tip: 'Unable to require lock, please try again.',
            title: 'Unable to require lock',
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              tooltip.reset();
              return this.cancelConfirmReorder();
            }
          });
        }
      } // used for disabling the hover "Locked by" while reordering


      widget.reordering = true;
      Ember.set(this, 'sortByStr', sortByStr);
      Ember.set(this, 'widget', widget);
      this.sortByETA();
      Ember.set(this, 'reorderInProgress', true);
    },

    toggleHiddenFlag() {
      this.toggleProperty('isHidden');
    },

    // called when a user stops a reorder in stops widget
    stopReorder() {
      const unlockRouteAction = this.get('unlockRouteAction').bind(this);
      const dispatchRoute = this.get('dispatchRoute');
      let currentState = this.get('currentState');
      Ember.set(this, 'widget.state', {
        sortId: 'id',
        sortAsc: true,
        reordering: false
      });

      if (Object.keys(currentState).length === 0 && currentState.constructor === Object) {
        currentState = {
          sortId: 'id',
          sortAsc: true
        };
      }

      setTimeout(async function () {
        Ember.set(this, 'widget.state', currentState);
        Ember.set(this, 'currentState', null);
        Ember.set(this, 'reorderInProgress', false);
        Ember.set(this, 'confirmReorderInProgress', false);

        if (dispatchRoute) {
          await unlockRouteAction(0);
          this.set('dispatchRoute');
          this.set('stopBreakOrdinals');
          this.set('dispatchRouteToCheck');
        }
      }.bind(this), 1000);
    },

    // called when a user confirms a reorder in stops widget
    confirmReorder() {
      Ember.set(this, 'confirmReorderInProgress', true);
      this.get('workspace').requestWootricSurvey('on_reorderStops');
    },

    cancelConfirmReorder() {
      Ember.set(this, 'confirmReorderInProgress', false);
    },

    sortByETA() {
      const sortByStr = this.get('sortByStr');
      const oldState = this.get('widget.state') || {};
      Ember.set(this, 'widget.state', {
        sortId: sortByStr,
        sortAsc: true,
        reordering: true
      });
      Ember.set(this, 'currentState', oldState);
    }

  });

  _exports.default = _default;
});