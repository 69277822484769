define("adept-iq/pods/components/reoptimize-inprogress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal,
    readOnly
  } = Ember.computed;
  const TIMEOUT_DELAY = 1000; // 10 second tries

  var _default = Ember.Component.extend({
    classNames: ['reoptimize-in-progress'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // Passed In Attributes
    title: null,
    description: null,
    timeout: TIMEOUT_DELAY,
    selectedVal: null,
    isExported: readOnly('workspace.isExported'),
    operationType: readOnly('workspace.operationType'),
    isExporting: equal('operationType', 'export'),
    isBrokerExporting: equal('operationType', 'brokerExport'),
    inProgressTitle: Ember.computed('reoptimizeInProgress', 'isExported', 'isExporting', 'isBrokerExporting', function () {
      const isExported = this.get('isExported');
      const isExporting = this.get('isExporting');
      const isBrokerExporting = this.get('isBrokerExporting');
      if (isExported || isExporting || isBrokerExporting) return 'Release in Progress';
      return 'Reoptimization in Progress';
    }),
    inProgressTip: Ember.computed('reoptimizeInProgress', 'isExported', 'isExporting', 'isBrokerExporting', function () {
      const isExported = this.get('isExported');
      const isExporting = this.get('isExporting');
      const isBrokerExporting = this.get('isBrokerExporting');
      if (isExported || isExporting || isBrokerExporting) return 'Another scheduler is currently releasing. Information is ready-only at this time.';
      return 'Another scheduler is currently reoptimizing. Information is ready-only at this time.';
    }),

    didInsertElement() {
      this._super(...arguments);

      const tooltip = this.get('tooltip');
      const workspace = this.get('workspace');
      const editModal = this.get('editModal');

      if (!workspace.get('triggeredReoptimize')) {
        const inProgressTip = this.get('inProgressTip');
        const inProgressTitle = this.get('inProgressTitle');
        tooltip.pushConfirmation({
          title: inProgressTitle,
          hasOverlay: true,
          tip: inProgressTip,
          primaryActionText: 'OK',

          primaryAction() {
            workspace.reset();
            tooltip.reset();

            if (editModal.get('isOpen')) {
              editModal.close();
            }
          },

          closeAction: () => {
            workspace.reset();
          }
        });
      }
    }

  });

  _exports.default = _default;
});