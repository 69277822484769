define("adept-iq/pods/components/sandbox-setup/step3/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RVlGK2Jg",
    "block": "{\"symbols\":[\"strategy\"],\"statements\":[[7,\"div\"],[11,\"id\",\"sandbox-setup\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row w-100 h-100\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-6 mb-3\"],[9],[0,\"\\n      \"],[5,\"card-item\",[],[[\"@iconPath\",\"@altText\",\"@title\",\"@customClasses\"],[[22,0,[\"iconPaths\",\"sandbox\"]],\"Sandbox\",\"Sandbox\",\"m-0\"]]],[0,\"\\n    \"],[10],[0,\"\\n\\n      \"],[2,\" Optimization Options\"],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[11,\"style\",\"padding: 0;\"],[9],[0,\"\\n     \"],[7,\"div\"],[11,\"class\",\"optimize-strategy-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"optimize-strategy-heading\"],[9],[0,\"Select an Optimization Strategy\"],[10],[0,\"\\n\\n        \"],[2,\" Dynamically generate radio buttons based on availableStrategies \"],[0,\"\\n\"],[4,\"each\",[[22,0,[\"availableStrategies\"]]],null,{\"statements\":[[4,\"radio-button\",null,[[\"value\",\"groupValue\",\"changed\",\"classNames\"],[[22,1,[\"id\"]],[22,0,[\"selectedOption\"]],[27,\"action\",[[22,0,[]],\"updateOption\",[22,1,[\"id\"]]],null],\"radio-button-input\"]],{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"radio-button-label\"],[9],[1,[22,1,[\"displayName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n         \"],[2,\" Optimize Button \"],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"optimize-btn-container\"],[9],[0,\"\\n          \"],[7,\"button\"],[12,\"class\",[28,[\"btn optimize-btn \",[27,\"if\",[[22,0,[\"selectedOption\"]],\"optimize-btn-selected\",\"optimize-btn-no-selection\"],null]]]],[12,\"disabled\",[22,0,[\"isOptimizeDisabled\"]]],[9],[0,\"\\n            Optimize\\n          \"],[3,\"action\",[[22,0,[]],\"onOptimizeBtnClick\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/sandbox-setup/step3/template.hbs"
    }
  });

  _exports.default = _default;
});