define("adept-iq/models/rider-travel-need", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    count: attr('number'),
    loadTime: attr('number'),
    unloadTime: attr('number'),
    rider: belongsTo('rider'),
    travelNeedType: belongsTo('travel-need-type'),
    passengerType: belongsTo('passenger-type'),
    // used for `@each`-type computed property dependent keys
    passengerTypeName: Ember.computed.readOnly('passengerType.name'),
    travelNeedTypeName: Ember.computed.readOnly('travelNeedType.name'),
    isExtraPassenger: Ember.computed('travelNeedType.isServiceAnimal', 'passengerType.{isPca,isCompanion}', function () {
      return this.get('travelNeedType.isServiceAnimal') || this.get('passengerType.isPca') || this.get('passengerType.isCompanion');
    }),
    isEquipment: Ember.computed.not('isExtraPassenger')
  });

  _exports.default = _default;
});