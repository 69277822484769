define("adept-iq/pods/components/side-drawer/scheduling-auto-reschedule/component", ["exports", "ember-light-table", "ember-concurrency", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/pods/components/side-drawer/scheduling-auto-reschedule/config", "adept-iq/mixins/async-schedule-operation", "adept-iq/models/stop-point"], function (_exports, _emberLightTable, _emberConcurrency, _unwrapProxy, _lodash, _config, _asyncScheduleOperation, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROUTE_INCLUDES = ['trips', 'trips.pick', 'trips.pick.segmentStop', 'trips.pick.segmentStop.place', 'trips.pick.segmentStop.place.address', 'trips.drop', 'trips.drop.segmentStop', 'trips.drop.segmentStop.place', 'trips.drop.segmentStop.place.address', 'trips.segment', 'trips.segment.leg', 'trips.segment.leg.rider', 'routeBreaks', 'trips.pick.trip', 'trips.drop.trip', 'vehicle'];
  const HALF_SEC_DELAY = 500;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // passed by the side drawer container component
    onCloseClick: null,
    solutionTable: null,
    rowComponent: 'table/rows/colored-row',
    title: 'Trip Rescheduled',
    previouslyTriedRoutes: null,
    //passed  by the workspace service
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    status: Ember.computed.readOnly('stackFrame.options.status'),
    isWaitlisted: Ember.computed.readOnly('stackFrame.options.isWaitlistedTripScheduled'),
    isUnScheduledTrip: Ember.computed.readOnly('stackFrame.options.isUnscheduledTripScheduled'),
    isTaxiTravelMode: Ember.computed.readOnly('stackFrame.options.isTaxiTravelMode'),
    originalRouteId: Ember.computed.readOnly('stackFrame.options.originalRouteId'),
    originalRouteName: Ember.computed.readOnly('stackFrame.options.originalRouteName'),
    isFailure: Ember.computed.equal('status', 'failure'),
    isSuccess: Ember.computed.equal('status', 'success'),
    rescheduleTrip: null,
    timeout: 1000,
    isWaitListedTrip: Ember.computed.readOnly('trip.isWaitlisted'),
    isOriginalRouteRunning: Ember.computed.readOnly('originalRouteTrip.isRunning'),
    isRetryRouteRunning: Ember.computed.readOnly('retryTrip.isRunning'),
    isWaitListTripRunning: Ember.computed.readOnly('waitListTrip.isRunning'),
    isOriginalRoutePerformed: false,
    isDisabledOriginalRouteBtn: Ember.computed('isWaitlisted', 'isUnScheduledTrip', 'isOriginalRouteRunning', 'isFailure', 'isOriginalRoutePerformed', 'isTaxiTravelMode', function () {
      return this.get('isUnScheduledTrip') || this.get('isWaitlisted') || this.get('isOriginalRouteRunning') || this.get('isFailure') || this.get('isOriginalRoutePerformed') || this.get('isTaxiTravelMode');
    }),
    isDisabledRetryBtn: Ember.computed('isFailure', 'isRetryRouteRunning', function () {
      return this.get('isFailure') || this.get('isRetryRouteRunning');
    }),
    tripId: Ember.computed('isFailure', 'rescheduleTrip', function () {
      if (this.get('isFailure')) {
        return this.get('trip.tripId');
      }

      return this.get('rescheduleTrip.tripId');
    }),
    routeName: Ember.computed('isFailure', 'rescheduleTrip.currentRouteName', function () {
      if (this.get('isFailure')) {
        return this.get('originalRouteName');
      }

      return this.get('rescheduleTrip.currentRouteName');
    }),

    init() {
      this._super(...arguments);

      const table = new _emberLightTable.default(_config.solutionColumns);
      this.set('solutionTable', table);
      this.set('previouslyTriedRoutes', []);

      if (this.get('isSuccess')) {
        const originalRouteId = parseInt(this.get('originalRouteId'), 10);
        this.set('previouslyTriedRoutes', [originalRouteId]);
        this.get('fetchRescheduleTrip').perform().then(() => {
          this.renderSuccessMessage();
        });
      }
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for trip transfer


      this.setSideDrawerWidth('630px');
    },

    setupSolutionPreview(routeRecord) {
      const tripId = this.get('tripId');
      const routeTrips = routeRecord.get('trips').toArray();
      let tripList = [];
      routeTrips.forEach(trip => {
        const stops = trip.get('stops');
        stops.forEach(tripStop => {
          const rider = (0, _unwrapProxy.unwrapProxy)(tripStop.get('trip.rider'));
          const leg = tripStop.get('trip.segment.leg');
          const anchor = leg.get('anchor'); //NYAAR-20354-Appt Time should display only for Drop anchored trips if the stop type is drop

          const apptTime = tripStop.get('type') === _stopPoint.ANCHOR_DROP_TYPE && anchor === _stopPoint.ANCHOR_DROP_TYPE ? leg.get('requestTime') : '';
          const computedPromiseTime = tripStop.get('isPick') ? tripStop.get('trip.promiseTime') : '';
          const data = {
            tripId: tripStop.get('trip.tripId'),
            plannedEta: tripStop.get('plannedEta'),
            stopType: tripStop.get('type'),
            computedPromiseTime,
            lastName: rider ? rider.get('lastName') : '',
            simpleAddress: tripStop.get(`trip.${tripStop.get('type')}.segmentStop.place.address.streetNumberAndAddress`),
            locality: tripStop.get(`trip.${tripStop.get('type')}.segmentStop.place.address.locality`),
            apptTime: apptTime
          };

          if (data.tripId === tripId) {
            data.selectedOrdinalRow = true;
          }

          tripList.push(data);
        });
      });
      tripList = (0, _lodash.orderBy)(tripList, tripInfo => {
        return tripInfo.plannedEta;
      }, ['asc']);
      this.get('solutionTable').setRows(tripList);
    },

    fetchRescheduleTrip: (0, _emberConcurrency.task)(function* () {
      const tripId = this.get('trip.id');
      const tripByTripId = `in(id,(${tripId}))`;
      const previouslyTriedRoutes = this.get('previouslyTriedRoutes');
      yield this.get('store').query('trip', {
        filter: tripByTripId
      });
      const rescheduleTrip = this.get('store').peekRecord('trip', tripId);
      this.set('rescheduleTrip', rescheduleTrip);
      const routeId = parseInt(rescheduleTrip.get('currentRouteId'), 10);
      const triedRouteIds = (0, _lodash.uniq)(previouslyTriedRoutes.concat(routeId));
      this.set('previouslyTriedRoutes', (0, _lodash.compact)(triedRouteIds));
      const routeRecord = yield this.get('store').findRecord('route', routeId, {
        include: ROUTE_INCLUDES.join(',')
      }); // give enough time to load to ember store

      yield (0, _emberConcurrency.timeout)(HALF_SEC_DELAY);
      const placeQuery = this.getPlaceIdForSegmentStops(routeRecord);
      const routeQuery = `eq(id,${routeId})&include=trips,trips.pick,trips.drop`;
      yield this.get('store').query('place', {
        filter: placeQuery
      });
      const routeQueryRecord = yield this.get('store').query('route', {
        filter: routeQuery
      }); // give enough time to load to ember store

      yield (0, _emberConcurrency.timeout)(HALF_SEC_DELAY);
      this.resetSolutionTableData();
      this.setupSolutionPreview(routeQueryRecord.firstObject);
    }).drop(),
    waitListTrip: (0, _emberConcurrency.task)(function* () {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('trip.route.schedule'));
      const tripId = parseInt(this.get('trip.id'), 10);
      let result;

      try {
        const operationData = {
          tripId
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripWaitlist', options);

        if (!result) {
          throw new Error('Unable to place trip on waitlist.');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),
    retryTrip: (0, _emberConcurrency.task)(function* () {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('trip.route.schedule'));
      const tripId = parseInt(this.get('trip.id'), 10);
      const previouslyTriedRoutes = this.get('previouslyTriedRoutes');
      let result;

      try {
        const operationData = {
          tripId,
          routes: (0, _lodash.compact)(previouslyTriedRoutes),
          routeUsage: 1
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripReschedule', options);

        if (!result) {
          throw new Error('Unable to retry a different route');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),
    originalRouteTrip: (0, _emberConcurrency.task)(function* (routeId) {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('trip.route.schedule'));
      const tripId = parseInt(this.get('trip.id'), 10);
      let result;

      try {
        const operationData = {
          tripId,
          routes: [routeId],
          routeUsage: 2
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripReschedule', options);

        if (!result) {
          throw new Error('Unable to return trip to original route');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    renderSuccessMessage() {
      const delay = this.get('timeout');
      setTimeout(() => {
        const successMsg = `Trip ${this.get('rescheduleTrip.tripId')} successfully rescheduled to route ${this.get('routeName')}`;
        this.get('notifications').success(successMsg);
      }, delay);
    },

    getPlaceIdForSegmentStops(routeRecord) {
      const placeIds = routeRecord.get('trips').map(tripData => {
        const stops = tripData.get('stops');
        return stops.map(tripStop => {
          const place = (0, _unwrapProxy.unwrapProxy)(tripStop.get(`trip.${tripStop.get('type')}.segmentStop.place`));
          return place.get('id');
        });
      });
      const placeIdsInClause = (0, _lodash.flattenDeep)(placeIds).map(p => `'${p}'`).join(',');
      return `in(id,(${placeIdsInClause}))&include=address,location`;
    },

    resetSolutionTableData() {
      // reset stops table data
      this.set('solutionTable.rows', []);
    },

    actions: {
      onAcceptButtonClick() {
        this.onCloseClick();
      },

      onWaitListButtonClick() {
        const tripId = this.get('trip.tripId');
        const successMsg = `Trip ${tripId} successfully placed on waitlist`;
        const errorMsg = 'Unable to place trip on waitlist.';
        this.get('waitListTrip').perform().then(() => {
          this.get('notifications').success(successMsg);
          this.onCloseClick();
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
        });
      },

      onRetryButtonClick() {
        const errorMsg = 'Unable to retry a different route';
        this.get('retryTrip').perform().then(async () => {
          await this.get('fetchRescheduleTrip').perform();
          this.renderSuccessMessage();
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
          this.get('fetchRescheduleTrip').perform();
        });
      },

      onOriginalRouteButtonClick() {
        const tripId = this.get('trip.tripId');
        const originalRouteId = this.get('originalRouteId');
        const originalRouteName = this.get('originalRouteName');
        const routeAssignments = this.get('trip.routeAssignments');
        const originalRouteAssignment = routeAssignments.find(routeAssignment => {
          return routeAssignment.get('route.id') === originalRouteId;
        });
        const routeName = originalRouteAssignment ? originalRouteAssignment.get('route.name') : originalRouteName;
        const routeId = originalRouteAssignment ? parseInt(originalRouteAssignment.get('route.id'), 10) : originalRouteId;
        const successMsg = `Trip ${tripId} successfully placed back to original route ${routeName}`;
        const errorMsg = 'Unable to return trip to original route';
        this.get('originalRouteTrip').perform(routeId).then(async () => {
          await this.get('fetchRescheduleTrip').perform();
          this.get('notifications').success(successMsg);
          this.set('isOriginalRoutePerformed', true);
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});