define("adept-iq/pods/components/side-drawer/perform-stop/pick/ui/component", ["exports", "adept-iq/utils/unwrapProxy", "moment"], function (_exports, _unwrapProxy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INTERVAL_DELAY = 60000;
  const TravelNeedItem = Ember.Object.extend({
    type: null,
    count: null
  });

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    timeFormat: 'm-d-Y G:i K',
    // pass in data to component
    pcaCount: null,
    serviceAnimalCount: null,
    companionCount: null,
    shouldArrive: true,
    amount: 0,
    // pass in data to component
    stopPoint: null,
    travelNeedOptions: null,
    travelNeedItems: null,
    errorText: null,
    paymentTypeOptions: null,
    selectedPaymentType: null,
    minCompanionCount: 0,
    maxCompanionCount: 20,
    arrive: false,
    depart: false,
    isArrived: null,
    isNextDisabled: null,
    maxDate: null,
    origAmount: 0.0,
    origCompanionCount: 0,
    // pass in perform-all panel
    previousStop: null,
    editAPI: null,
    currentState: null,
    // progress interval every minute
    _interval: null,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    vehicle: Ember.computed.readOnly('routeVehicleDriver.vehicle'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    routeVehicleDriver: Ember.computed.readOnly('dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    lastPerformedStop: Ember.computed.readOnly('dispatchRoute.lastPerformedStop'),
    // routeVehicleDriver: computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    minTime: Ember.computed('lastPerformedStop.{arrive,depart,actualArriveTime,actualDepartTime,routeBreak.actualArriveTime,routeBreak.actualDepartTime}', 'previousStop.actualDepartTime', function () {
      const prevDepartTime = this.get('previousStop.actualDepartTime');
      if (prevDepartTime) return prevDepartTime;
      const prevStop = this.get('lastPerformedStop');
      let minTime = this.get('dispatchRoute.computedStartTime') || this.get('dispatchRoute.plannedStartTime');
      if (Ember.isEmpty(prevStop)) return minTime;
      const actualArriveTime = prevStop.get('actualArriveTime'); // takes into account break stops

      const actualDepartTime = prevStop.get('actualDepartTime'); // takes into account break stops

      const stopArriveTime = this.get('actualArriveTime');
      if (actualArriveTime) minTime = actualArriveTime;
      if (actualDepartTime) minTime = actualDepartTime;
      if (minTime > stopArriveTime) minTime = stopArriveTime;
      return minTime ? (0, _moment.default)(minTime).startOf('minute').toDate() : null; // we need something to separate stops
    }),
    unusedTravelNeedOptions: Ember.computed('travelNeedOptions', 'travelNeedItems.@each.type', function () {
      const travelNeedOptions = this.get('travelNeedOptions') || [];
      const travelNeedItems = this.get('travelNeedItems') || [];

      if (travelNeedItems.length > 0) {
        const arr = this.get('travelNeedItems')[0];
        const type = this.get('travelNeedItems')[0].type;
        const objectCount = (0, _unwrapProxy.unwrapProxy)(this.get('vehicle').get('capacityCounts')[type]);
        arr.set('maxCount', objectCount);
      }

      const usedTravelNeedTypes = travelNeedItems.mapBy('type');
      return travelNeedOptions.reject(_ref => {
        let {
          type
        } = _ref;
        return usedTravelNeedTypes.includes(type);
      });
    }),

    setMinMaxTravelNeedCount(travelNeedItem) {
      const type = travelNeedItem.get('type'); // ambulatory AP

      const objectCount = (0, _unwrapProxy.unwrapProxy)(this.get('vehicle').get('capacityCounts')[type]); // create min count for each travel need

      travelNeedItem.set('minCount', 0); // else travelNeedItem.set('minCount', count - 1);
      // create max count for each travel need

      travelNeedItem.set('maxCount', objectCount);
    },

    init() {
      this._super(...arguments);

      const max = new Date();
      max.setMinutes(max.getMinutes() + 1);
      this.set('maxDate', max); // create a maxDate ticker that progresses by a minute

      this._interval = setInterval(() => {
        Ember.run.schedule('afterRender', () => {
          if (this.get('isDestroyed')) return;
          this.set('maxDate', max);
        });
      }, INTERVAL_DELAY);
      const setStopUIReference = this.get('setStopUIReference');

      if (setStopUIReference) {
        setStopUIReference(this);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.loadCurrentState();
      const editAPI = this.get('editAPI');
      const refreshTravelNeedTask = this.get('refreshTravelNeedTask');
      Ember.run.next(() => {
        if (editAPI) {
          editAPI.setProperties({
            refreshTravelNeedTask: refreshTravelNeedTask
          });
        }
      });
    },

    destroy() {
      clearInterval(this._interval);
      this._interval = null;
    },

    backUpCurrentState() {
      const {
        stopPoint,
        driverBreak,
        travelNeedOptions,
        travelNeedItems,
        arrive,
        depart,
        isArrived,
        selectedPaymentType,
        amount,
        pcaCount,
        serviceAnimalCount,
        companionCount,
        isNextDisabled,
        actualArriveTime,
        actualDepartTime
      } = this;
      const odometerInMiles = stopPoint.get('odometerInMiles');
      const currentState = {
        driverBreak,
        travelNeedOptions,
        travelNeedItems,
        arrive,
        depart,
        isArrived,
        odometerInMiles,
        selectedPaymentType,
        amount,
        pcaCount,
        serviceAnimalCount,
        companionCount,
        isNextDisabled,
        actualArriveTime,
        actualDepartTime
      };
      this.set('currentState', currentState);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');
      const stopPoint = this.get('stopPoint');

      if (currentState) {
        const {
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived,
          odometerInMiles,
          selectedPaymentType,
          amount,
          pcaCount,
          serviceAnimalCount,
          companionCount,
          isNextDisabled,
          actualArriveTime,
          actualDepartTime
        } = currentState;
        stopPoint.set('odometerInMiles', odometerInMiles);
        this.setProperties({
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived,
          odometerInMiles,
          selectedPaymentType,
          amount,
          pcaCount,
          serviceAnimalCount,
          companionCount,
          isNextDisabled,
          actualArriveTime,
          actualDepartTime
        });
        this.set('currentState', null);
      }
    },

    actions: {
      deleteTravelNeedItem(travelNeedItem) {
        this.get('travelNeedItems').removeObject(travelNeedItem);
      },

      addTravelNeedItem() {
        const travelNeedItems = this.get('travelNeedItems');
        const unusedTravelNeedOptions = this.get('unusedTravelNeedOptions');
        if (Ember.isEmpty(unusedTravelNeedOptions)) return;
        const travelNeedItem = TravelNeedItem.create({
          type: unusedTravelNeedOptions[0].type,
          count: 0
        });
        travelNeedItems.pushObject(travelNeedItem);
        this.setMinMaxTravelNeedCount(travelNeedItem);
        this.getValueFromchildTravelNeeds(travelNeedItems);
      },

      onTravelNeedChange(travelNeed, event) {
        const value = event.currentTarget.value ? parseInt(event.currentTarget.value, 10) : null;
        const tavelNeedType = travelNeed.get('type');
        const objectCount = (0, _unwrapProxy.unwrapProxy)(this.get('vehicle').get('capacityCounts'))[tavelNeedType];
        const minCount = travelNeed.get('minCount');
        let maxCount;
        maxCount = objectCount;

        if (!objectCount) {
          maxCount = 0;
        }

        if (Ember.isNone(value)) {
          travelNeed.set('count', objectCount);
        } else if (value > maxCount) {
          travelNeed.set('count', objectCount);
        } else if (value < minCount) {
          travelNeed.set('count', minCount);
        } else travelNeed.set('count', value);

        if (!objectCount) {
          travelNeed.set('count', 0);
          travelNeed.set('maxCount', 0);
          event.currentTarget.value = 0;
        }

        event.currentTarget.value = objectCount;
      },

      onCompanionChange(event) {
        const value = event.currentTarget.value ? parseInt(event.currentTarget.value, 10) : null;
        const minCount = this.get('minCompanionCount');
        const objectCount = (0, _unwrapProxy.unwrapProxy)(this.get('vehicle').get('capacityCounts').ambulatory);
        const maxCount = objectCount;

        if (Ember.isNone(value)) {
          this.set('companionCount', value);
        } else if (value > maxCount) {
          this.set('companionCount', maxCount);
        } else if (value < minCount) {
          this.set('companionCount', minCount);
        } else this.set('companionCount', value);

        const companionCount = this.get('companionCount');
        event.currentTarget.value = companionCount;
      },

      selectTravelNeedTypeOption(travelNeedItem, option) {
        travelNeedItem.set('type', option.type);
        const objectCount = (0, _unwrapProxy.unwrapProxy)(this.get('vehicle').get('capacityCounts')[option.type]);
        travelNeedItem.set('count', 0);
        travelNeedItem.set('maxCount', objectCount);
      },

      onClickDepart(depart) {
        if (depart) {
          this.set('arrive', depart);
          this.set('depart', depart);
          this.set('isNextDisabled', false);
          return;
        }

        this.set('depart', depart);
        this.set('isNextDisabled', true);
      },

      onActualPickTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('actualDepartTime', momentTime.startOf('minute').toISOString());
      },

      onActualArriveTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        const pickTime = this.get('actualDepartTime');
        this.set('actualArriveTime', momentTime.startOf('minute').toISOString());

        if ((0, _moment.default)(pickTime).isBefore(momentTime, 'minutes')) {
          this.send('onActualPickTimeChange', dateValues);
        }
      },

      onAmountChange(amount) {
        this.set('amount', amount);
      },

      companionChanged(companionCount) {
        const origAmount = this.get('origAmount') ? this.get('origAmount') : 0.0;
        const diffCompanionCount = companionCount - this.get('origCompanionCount');
        this.set('amount', origAmount + diffCompanionCount * 2.75);
      }

    }
  });

  _exports.default = _default;
});