define("adept-iq/pods/components/iq-widgets/driver-breaks-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Breaks',
      fields: [{
        id: 'type',
        type: 'enum',
        label: 'Type',
        valuePath: 'breakType',
        editable: true,
        extra: {
          optionModelName: 'break-type',
          optionSearchPath: 'name',
          optionSelectedPath: '',
          optionIncludes: '',
          optionSearchEnabled: false
        }
      }, {
        id: 'location',
        type: 'addresses',
        label: 'Address',
        valuePath: 'place',
        modelName: 'place',
        addressType: 'originlocations',
        isMultiType: true,
        extra: {
          aliasPath: 'address.alias',
          notesPath: 'address.notes',
          displayPath: 'address.address',
          premisePath: 'address.premise',
          streetNumberPath: 'address.streetNumber',
          streetNamePath: 'address.streetName',
          localityPath: 'address.locality',
          regionPath: 'address.region',
          subRegionPath: 'address.county',
          postalCodePath: 'address.postalCode',
          countryPath: 'address.country',
          freeformAddressPath: 'address.freeformAddress',
          tomtomFormattedAddressPath: 'address.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng',
          lat: 'address.locations.content.firstObject.location.lat',
          lng: 'address.locations.content.firstObject.location.lng',
          geoNode: 'address.location.geoNode'
        }
      }, {
        id: 'estimatedStart',
        type: 'datetimeflatpickr',
        label: 'Start Time',
        editable: true,
        format: 'Y-m-d h:i K',
        valuePath: 'requestTime',
        extra: {
          minTimePath: 'route.plannedStartTime',
          maxTimePath: 'route.actualEndTimestamp'
        }
      }, {
        id: 'estimatedDuration',
        type: 'number',
        label: 'Duration (mins)',
        valuePath: 'plannedDuration',
        extra: {
          min: 0
        }
      }, {
        id: 'estimatedEnd',
        type: 'date',
        label: 'End Time',
        editable: true,
        readonlyCell: true,
        format: 'Y-m-d h:i K',
        // date flatpickr uses a different formating than moment
        valuePath: 'projectedEndDate'
      }, {
        id: 'notes',
        type: 'text',
        label: 'Description',
        valuePath: 'notes'
      }]
    }, {
      title: 'Information',
      fields: [{
        id: 'routeName',
        type: 'text',
        label: 'Route Name',
        valuePath: 'route.name',
        readonlyCell: true,
        editable: true
      }, {
        id: 'vehicleId',
        type: 'number',
        label: 'Vehicle ID',
        valuePath: 'route.dispatchRoute.assignedVehicle.name',
        readonlyCell: true,
        editable: true
      }, {
        id: 'driverLastName',
        type: 'text',
        label: 'Driver Last Name',
        valuePath: 'route.dispatchRoute.assignedDriver.lastName',
        readonlyCell: true,
        editable: true
      }, {
        id: 'driverFirstName',
        type: 'text',
        label: 'Driver First Name',
        valuePath: 'route.dispatchRoute.assignedDriver.firstName',
        readonlyCell: true,
        editable: true
      }, {
        id: 'routePlannedStartTime',
        type: 'date',
        label: 'Route Planned Start Time',
        editable: true,
        readonlyCell: true,
        format: 'Y-m-d h:i K',
        // date flatpickr uses a different formating than moment
        valuePath: 'route.computedPlannedStartTime'
      }, {
        id: 'routeActualStartTime',
        type: 'date',
        label: 'Route Actual Start Time',
        editable: true,
        readonlyCell: true,
        format: 'Y-m-d h:i K',
        // date flatpickr uses a different formating than moment
        valuePath: 'route.actualStartTimestamp'
      }, {
        id: 'routePlannedEndTime',
        type: 'date',
        label: 'Route Planned End Time',
        editable: true,
        readonlyCell: true,
        format: 'Y-m-d h:i K',
        // date flatpickr uses a different formating than moment
        valuePath: 'route.computedPlannedEndTime'
      }, {
        id: 'routeActualEndTime',
        type: 'date',
        label: 'Route Actual End Time',
        editable: true,
        readonlyCell: true,
        format: 'Y-m-d h:i K',
        // date flatpickr uses a different formating than moment
        valuePath: 'route.actualEndTimestamp'
      }]
    }]
  };
  _exports.default = _default;
});