define("adept-iq/pods/components/side-drawer/system-config/route-master/table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'route-template',
    title: 'Route Master',
    tooltipTitle: 'System Configuration',
    defaultSortId: 'routeName',
    defaultSortAsc: true,
    queryModelData: true,
    selectFirstRow: true,
    columns: [{
      id: 'routeName',
      index: 0,
      type: 'text',
      label: 'Route Name',
      valuePath: 'name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'active',
      index: 1,
      type: 'boolean',
      label: 'Active',
      valuePath: 'active',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'manualTripsOnly',
      index: 2,
      type: 'text',
      label: 'Manual Trips Only',
      valuePath: 'schedulingMode',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'monVehicleName',
      index: 3,
      type: 'uuid',
      label: 'Mon Vehicle Name',
      valuePath: 'mondayAvailability.vehicle.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'monShiftStart',
      index: 4,
      type: 'date',
      label: 'Mon Shift Start',
      valuePath: 'mondayAvailability.shiftStart',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'monShiftEnd',
      index: 5,
      type: 'date',
      label: 'Mon Shift End',
      valuePath: 'mondayAvailability.shiftEnd',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'monShiftLength',
      index: 6,
      type: 'text',
      label: 'Mon Shift Length',
      valuePath: 'mondayAvailability.routeLength',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'tuesVehicleName',
      index: 7,
      type: 'uuid',
      label: 'Tues Vehicle Name',
      valuePath: 'tuesdayAvailability.vehicle.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'tuesShiftStart',
      index: 8,
      type: 'date',
      label: 'Tues Shift Start',
      valuePath: 'tuesdayAvailability.shiftStart',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'tuesShiftEnd',
      index: 9,
      type: 'date',
      label: 'Tues Shift End',
      valuePath: 'tuesdayAvailability.shiftEnd',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'tuesShiftLength',
      index: 10,
      type: 'text',
      label: 'Tues Shift Length',
      valuePath: 'tuesdayAvailability.routeLength',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'wedVehicleName',
      index: 11,
      type: 'uuid',
      label: 'Wed Vehicle Name',
      valuePath: 'wednesdayAvailability.vehicle.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'wedShiftStart',
      index: 12,
      type: 'date',
      label: 'Wed Shift Start',
      valuePath: 'wednesdayAvailability.shiftStart',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: false
    }, {
      id: 'wedShiftEnd',
      index: 13,
      type: 'date',
      label: 'Wed Shift End',
      valuePath: 'wednesdayAvailability.shiftEnd',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'wedShiftLength',
      index: 14,
      type: 'text',
      label: 'Wed Shift Length',
      valuePath: 'wednesdayAvailability.routeLength',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'thurVehicleName',
      index: 15,
      type: 'uuid',
      label: 'Thur Vehicle Name',
      valuePath: 'thursdayAvailability.vehicle.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'thurShiftStart',
      index: 16,
      type: 'date',
      label: 'Thur Shift Start',
      valuePath: 'thursdayAvailability.shiftStart',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: false
    }, {
      id: 'thurShiftEnd',
      index: 17,
      type: 'date',
      label: 'Thur Shift End',
      valuePath: 'thursdayAvailability.shiftEnd',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'thurShiftLength',
      index: 18,
      type: 'text',
      label: 'Thur Shift Length',
      valuePath: 'thursdayAvailability.routeLength',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'friVehicleName',
      index: 19,
      type: 'uuid',
      label: 'Fri Vehicle Name',
      valuePath: 'fridayAvailability.vehicle.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'friShiftStart',
      index: 20,
      type: 'date',
      label: 'Fri Shift Start',
      valuePath: 'fridayAvailability.shiftStart',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'friShiftEnd',
      index: 21,
      type: 'date',
      label: 'Fri Shift End',
      valuePath: 'fridayAvailability.shiftEnd',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'friShiftLength',
      index: 22,
      type: 'text',
      label: 'Fri Shift Length',
      valuePath: 'fridayAvailability.routeLength',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'satVehicleName',
      index: 23,
      type: 'uuid',
      label: 'Sat Vehicle Name',
      valuePath: 'saturdayAvailability.vehicle.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'satShiftStart',
      index: 24,
      type: 'date',
      label: 'Sat Shift Start',
      valuePath: 'saturdayAvailability.shiftStart',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'satShiftEnd',
      index: 25,
      type: 'date',
      label: 'Sat Shift End',
      valuePath: 'saturdayAvailability.shiftEnd',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'satShiftLength',
      index: 26,
      type: 'text',
      label: 'Sat Shift Length',
      valuePath: 'saturdayAvailability.routeLength',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'sunVehicleName',
      index: 27,
      type: 'uuid',
      label: 'Sun Vehicle Name',
      valuePath: 'sundayAvailability.vehicle.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'sunShiftStart',
      index: 28,
      type: 'date',
      label: 'Sun Shift Start',
      valuePath: 'sundayAvailability.shiftStart',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: false
    }, {
      id: 'sunShiftEnd',
      index: 29,
      type: 'date',
      label: 'Sun Shift End',
      valuePath: 'sundayAvailability.shiftEnd',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'sunShiftLength',
      index: 30,
      type: 'text',
      label: 'Sun Shift Length',
      valuePath: 'sundayAvailability.routeLength',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'holVehicleName',
      index: 31,
      type: 'uuid',
      label: 'Holiday Vehicle Name',
      valuePath: 'holidayAvailability.vehicle.name',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'holShiftStart',
      index: 32,
      type: 'date',
      label: 'Holiday Shift Start',
      valuePath: 'holidayAvailability.shiftStart',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'holShiftEnd',
      index: 33,
      type: 'date',
      label: 'Holiday Shift End',
      valuePath: 'holidayAvailability.shiftEnd',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }, {
      id: 'holShiftLength',
      index: 34,
      type: 'text',
      label: 'Holiday Shift Length',
      valuePath: 'holidayAvailability.routeLength',
      defaultWidth: 100,
      format: 'hh:mm',
      searchable: true
    }]
  };
  _exports.default = _default;
});