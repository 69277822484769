define("adept-iq/pods/components/rider-management-widget/rider-modal-search/component", ["exports", "adept-iq/utils/rql-generators", "adept-iq/models/rider-phone-number", "moment"], function (_exports, _rqlGenerators, _riderPhoneNumber, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^\d*$/;
  const REMOVE_TEXT_FORMAT = /[^0-9]+/g;
  const REMOVE_SPECIAL_FORMAT = /[^a-zA-Z0-9]/g;

  var _default = Ember.Component.extend({
    classNames: ['form-components-time'],
    rider: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    riderId: null,
    selectableRiders: null,
    riderFirstName: null,
    riderLastName: null,
    riderPhoneNumber: null,
    riderDateOfBirth: null,
    includeArchivedRecords: false,
    selectedRider: null,
    enableSearch: Ember.computed('riderId', 'riderFirstName', 'riderLastName', 'riderPhoneNumber', 'riderDateOfBirth', function () {
      const riderId = this.get('riderId');
      const riderFirstName = this.get('riderFirstName');
      const riderLastName = this.get('riderLastName');
      const riderPhoneNumber = this.get('riderPhoneNumber');
      const riderDateOfBirth = this.get('riderDateOfBirth');

      if (Ember.isPresent(riderId) || Ember.isPresent(riderFirstName) || Ember.isPresent(riderLastName) || Ember.isPresent(riderPhoneNumber) || Ember.isPresent(riderDateOfBirth)) {
        return true;
      }

      return false;
    }),

    init() {
      this._super(...arguments);

      this.set('selectableRiders', []);
      this.onRiderSelect = this.onRiderSelect.bind(this);
    },

    async onSearchRiderRecord() {
      try {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const queryString = this.buildSearchQuery();
        const store = this.get('store');
        const ridersResults = await store.query('rider', {
          filter: queryString
        });
        const riders = ridersResults.toArray();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.set('selectedRider', null);
        this.set('selectableRiders', riders);
      } catch (e) {
        console.log(e);
      }
    },

    buildSearchQuery() {
      const riderId = this.get('riderId');
      const riderFirstName = this.get('riderFirstName');
      const riderLastName = this.get('riderLastName');
      const riderPhoneNumber = this.get('riderPhoneNumber');
      const riderDateOfBirth = this.get('riderDateOfBirth');
      const includeArchivedRecords = this.get('includeArchivedRecords');
      const searchTerms = [];

      if (Ember.isPresent(riderId)) {
        const rqlQuery = (0, _rqlGenerators.stringLikeRQL)('riderId', [riderId]);
        searchTerms.push(rqlQuery);
      }

      if (Ember.isPresent(riderFirstName)) {
        const rqlQuery = (0, _rqlGenerators.stringLikeRQL)('firstName', [riderFirstName]);
        searchTerms.push(rqlQuery);
      }

      if (Ember.isPresent(riderLastName)) {
        const rqlQuery = (0, _rqlGenerators.stringLikeRQL)('lastName', [riderLastName]);
        searchTerms.push(rqlQuery);
      }

      if (Ember.isPresent(riderPhoneNumber)) {
        const regex = new RegExp('^\\d{10}$');

        if (riderPhoneNumber !== null && riderPhoneNumber !== '' && !regex.test(riderPhoneNumber)) {
          this.get('notifications').warning('A full 10-digit phone number is required in order to search.');
        }

        const areaCode = riderPhoneNumber.substring(0, 3);
        const phone = riderPhoneNumber.substring(3, 10);
        const rqlAreaCodeQuery = (0, _rqlGenerators.stringEqRQL)('phoneNumbers.areaCode', [`${areaCode}`]);
        const rqlPhoneQuery = (0, _rqlGenerators.stringEqRQL)('phoneNumbers.phoneNumber', [`${phone}`]);
        const rqlPhoneTypeQuery = (0, _rqlGenerators.stringEqRQL)('phoneNumbers.type', [`${_riderPhoneNumber.MAIN_PHONE_TYPE}`]);
        searchTerms.push(rqlAreaCodeQuery, rqlPhoneQuery, rqlPhoneTypeQuery);
      }

      if (Ember.isPresent(riderDateOfBirth)) {
        const rqlQuery = `eq(dateOfBirth,${(0, _moment.default)(riderDateOfBirth).format('L')})`;
        searchTerms.push(rqlQuery);
      }

      if (!includeArchivedRecords) {
        const rqlQuery = (0, _rqlGenerators.stringNeRQL)('status', ['Archived']);
        searchTerms.push(rqlQuery);
      }

      const queryString = searchTerms.reduce((prev, current) => {
        return (0, _rqlGenerators.andRQL)(current, prev);
      });
      return queryString;
    },

    onRiderSelect(selectedRider) {
      this.set('selectedRider', selectedRider);
    },

    actions: {
      closeDialog() {
        this.set('showRiderModal', false);
      },

      onDateValueChange(value) {
        this.set('riderDateOfBirth', value);

        if (Ember.isPresent(value)) {
          if (!this.get('disabledId')) this.set('disabledId', true);
        } else if (this.get('disabledId')) this.set('disabledId', false);
      },

      async onSearch() {
        await this.onSearchRiderRecord();
      },

      onClearAll() {
        this.set('riderId', null);
        this.set('riderFirstName', null);
        this.set('riderLastName', null);
        this.set('riderPhoneNumber', null);
        this.set('riderDateOfBirth', null);
        this.set('disabledId', false);
        this.set('disabledFirstName', false);
        this.set('disabledLastName', false);
        this.set('disabledLastName', false);
        this.set('disabledPhoneNumber', false);
        this.set('disabledDateOfBirth', false);
        this.set('includeArchivedRecords', false);
        this.set('selectableRiders', []);
        this.set('selectedRider', null);
      },

      onCancel() {
        this.set('selectableRiders', []);
        this.set('showRiderModal', false);
      },

      onSelectRider() {
        this.set('showRiderModal', false);
        this.get('setSelectedRider')(this.get('selectedRider'));
      },

      handleKeyUp() {
        const riderId = this.get('riderId');
        const riderFirstName = this.get('riderFirstName');
        const riderLastName = this.get('riderLastName');
        const riderPhoneNumber = this.get('riderPhoneNumber');
        const fieldNames = ['disabledFirstName', 'disabledLastName', 'disabledPhoneNumber', 'disabledDateOfBirth'];

        if (!Ember.isEmpty(riderId)) {
          fieldNames.forEach(fieldName => {
            this.set(fieldName, true);
          });
        } else {
          fieldNames.forEach(fieldName => {
            this.set(fieldName, false);
          });
        }

        const riderFields = [riderFirstName, riderLastName, riderPhoneNumber];
        const blankResult = riderFields.some(fieldName => Ember.isPresent(fieldName));

        if (blankResult) {
          this.set('disabledId', true);
        } else {
          this.set('disabledId', false);
        }
      },

      onInputChange(value, valuePath) {
        let finalValue;

        if (!NUMBER_FORMAT.test(value)) {
          finalValue = value.replace(REMOVE_SPECIAL_FORMAT, '');
          finalValue = value.replace(REMOVE_TEXT_FORMAT, '');
          this.set(valuePath, finalValue);
        }
      }

    }
  });

  _exports.default = _default;
});