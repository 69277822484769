define("adept-iq/pods/components/iq-widgets/map-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gx+J5lsx",
    "block": "{\"symbols\":[\"dropdown\",\"option\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"map-widget-body\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[21,\"mapElementId\"]],[12,\"class\",[28,[\"tomtom-map \",[27,\"if\",[[23,[\"isComputingDistance\"]],\"pinpoint-cursor\"],null]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"basic-dropdown\",null,[[\"calculatePosition\",\"onInit\"],[[23,[\"contextMenuPosition\"]],[27,\"action\",[[22,0,[]],\"setContextMenu\"],null]]],{\"statements\":[[0,\"  \"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('adept-iq/pods/components/iq-widgets/map-widget/template.hbs' @ L105:C5) \"],null]],[[\"class\"],[\"this-trigger\"]],{\"statements\":[[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"content\"]],\"expected `dropdown.content` to be a contextual component but found a string. Did you mean `(component dropdown.content)`? ('adept-iq/pods/components/iq-widgets/map-widget/template.hbs' @ L106:C5) \"],null]],[[\"class\"],[\"widget-dropdown-content\"]],{\"statements\":[[4,\"each\",[[23,[\"contextMenuOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[22,2,[\"name\"]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"onContextMenuOptionClick\",[22,2,[]]]],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/map-widget/template.hbs"
    }
  });

  _exports.default = _default;
});